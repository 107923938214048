import React from 'react';
import { trans, ucfirst } from 'utilities';
import { APIListField, CheckboxField, TextField } from 'forms';

export const ActesSpecifiques = (props: any) => {
  const { t, control, onFieldChange, errorMessage, getValues, globalDisabled } = props;

  let hasSpecificClauseOther = false;
  (getValues()['clauses'] ?? []).forEach((item: any) => {
    if (item === 'other') {
      hasSpecificClauseOther = true;
    }
  });

  return <div className={'flex col-12 flex-wrap'}>
    <CheckboxField fieldKey={'isSpecificActes'} control={control} onFieldChange={onFieldChange} errorMessage={errorMessage} label={ucfirst(trans(t, 'specificActes'))} help={ucfirst(trans(t, 'specificActesHelp'))} disabled={globalDisabled}/>

    {true === getValues()['isSpecificActes'] && (
      <>
        <APIListField classes={'w-4 p-2'} listKey={'specificClauses'} fieldKey={'clauses'} control={control} onFieldChange={onFieldChange} errorMessage={errorMessage}
                      label={trans(t, 'ent.specificClauses')} multiple/>

        {hasSpecificClauseOther && (
          <TextField fieldKey={'specificClauses'} control={control} onFieldChange={onFieldChange} errorMessage={errorMessage} label={trans(t, 'ent.specificClauseOther')} disabled={globalDisabled}/>
        )}
      </>
    )}
  </div>;
};
