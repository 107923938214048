import React, { useEffect, useState } from 'react';
import { Controller } from 'react-hook-form';

import { classNames } from 'primereact/utils';
import { Dropdown } from 'primereact/dropdown';
import { Skeleton } from 'primereact/skeleton';
import { MultiSelect } from 'primereact/multiselect';

import { trans } from 'utilities';
import { IRequestParams, useApim } from 'services';

export const APIListField = (props: any) => {
  const { listKey, fieldKey, control, onFieldChange, errorMessage, rules, multiple, defaultValue,
    label, placeholder, classes, optionLabel, optionValue, showClear, disabled, filter, help } = props;

  const apim = useApim();
  const { t } = apim.di();

  const [options, setOptions] = useState<any[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  useEffect(() => {
    apim.getList(listKey, {setter: setOptions, setLoading: setLoading} as IRequestParams).then();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  // A field key can join multiple possibilities using '||' separator.
  const split = fieldKey.split('||');

  return (<div className={classNames('field', classes || 'col-12 md:col-6 lg:col-4')}>
    {loading ? <Skeleton height={'2.8rem'} /> :
      <Controller
        name={split[0]}
        control={control}
        rules={rules}
        render={({ field, fieldState }) => {

          return (
            <>
              <span className={'p-float-label'}>
                {multiple ? (
                  <MultiSelect
                    id={field.name} className={classNames('w-full', {'p-invalid': fieldState.error})} placeholder={placeholder || trans(t, 'form|select')} value={field.value ?? defaultValue}
                    options={options} optionLabel={optionLabel || 'translated'} optionValue={optionValue || 'value'} disabled={disabled}
                    onChange={(e) => onFieldChange(field, fieldState, e.value, 'join')} showClear={showClear} filter={filter}
                  />
                ) : (
                  <Dropdown
                    id={field.name} className={classNames('w-full', {'p-invalid': fieldState.error})} placeholder={placeholder || trans(t, 'form|select')} value={field.value ?? defaultValue}
                    options={options} optionLabel={optionLabel || 'translated'} optionValue={optionValue || 'value'} disabled={disabled}
                    onChange={(e) => onFieldChange(field, fieldState, e.value)} showClear={showClear} filter={filter}
                  />
                )}
                {(label || field.name) && (
                  <label className={'text-ucfirst p-float-label-label'} htmlFor={field.name}>
                    <span>{label ?? trans(t, field.name)}</span>
                  </label>
                )}
              </span>

              {help && (
                <span className={'help text-xs pt-2 block'}>{help}</span>
              )}

              {errorMessage(field.name)}
            </>
          )
        }}
      />
    }
    </div>
  );
};
