import React, { useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { TabMenu } from 'primereact/tabmenu';

import { StatusPage } from 'components';
import { useApim } from 'services';
import { useAppState } from 'states';
import { trans, ucfirst } from 'utilities';
import { forEach } from 'lodash';
import appUri from 'config/appUri.json';

export const StatusRouter = () => {
  const { tab, year } = useParams();
  const apim = useApim();
  const { t, navigate } = apim.di();
  const appState = useAppState();

  // Handle tabs.
  const baseUri = appUri.status.general;
  const tabs: any[] = [];
  forEach(appUri.status.tabs, (ta) => {
    tabs.push({label: trans(t, 'menu|tab.status.' + ta), command: () => navigate(baseUri + '/' + ta)});
  });

  // Validate URL & build tabs.
  const [activeIndex, setActiveIndex] = useState<number>(-1);
  useEffect(() => {
    let ai = 0;
    forEach(appUri.status.tabs, (ta, index) => {
      if (tab === ta) ai = index;
    });

    if (ai !== activeIndex) setActiveIndex(ai);
  }, [tab]); // eslint-disable-line react-hooks/exhaustive-deps

  // Update Page Header.
  useEffect(() => {
    appState.state().pageTitle.set(ucfirst(trans(t, 'systemStatus')));
    appState.setBreadcrumb([{ label: trans(t, 'systemStatus') }]);
  }, [tab]); // eslint-disable-line react-hooks/exhaustive-deps

  const buildTabContent = useCallback(() => {
    return <StatusPage appState={appState} apim={apim} resource={tab ?? 'all'} />
  }, [tab, activeIndex, year]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
      <div className={'card height-100 fadein animation-duration-500 flex flex-column'}>
        <div className={'a8-page-header flex flex-wrap'}>
          <div className={'flex-auto flex p-2 justify-content-end pt-0'}>
            {tabs.length > 1 && activeIndex !== -1 && <TabMenu className={'pb-2'} model={tabs} activeIndex={activeIndex} onTabChange={(e) => setActiveIndex(e.index)}/>}
          </div>
        </div>
        {buildTabContent()}
      </div>
  );
};

