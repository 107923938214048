import React, { useCallback, useEffect, useState } from 'react';
import { TFunction } from 'i18next';
import { TabMenu } from 'primereact/tabmenu';
import { Message } from 'primereact/message';

import { isValidUUID, trans, ucfirst } from 'utilities';
import { Error, NotFound, Unauthorized } from 'pages';
import { PageLoader, UsersExpertDatatable } from 'components';
import { IRequestParam, IRequestParams, isAdmin, isClient, isExpertAdmin } from 'services';
import { SocieteExpertsProfile, MissionTypesSettings, SocieteExpertsDossiers } from './tabs';
import { forEach, includes } from 'lodash';
import appUri from 'config/appUri.json';

export const SocieteExpertsFormRouter = (props: any) => {
  const { id, tab, appState, apim, action } = props;
  const { t, navigate } = apim.di();

  const [loading, setLoading] = useState<boolean>(false);
  const [data, setData] = useState<any>(null);
  const [notFound, setNotFound] = useState<boolean>(false);
  const [errored, setErrored] = useState<boolean>(false);

  // Let's detect if we are in "edit/add" mode
  // then render the appropriate display (form or module dashboard).
  const editMode = appUri.reservedKeywords.edit === action;
  const addMode = appUri.reservedKeywords.add === action;

  const [activeIndex, setActiveIndex] = useState<number>(0);
  const tabs: any[] = [];
  if (id) {
    const baseUri = appUri.cie.exp.edit.replace(':id', id || '_');
    tabs.push({label: trans(t, 'menu|tab.general'), command: () => navigate(baseUri)});

    forEach(appUri.cie.exp.tabs, (ta) => {
      tabs.push({label: trans(t, 'menu|tab.' + ta), command: () => navigate(baseUri + '/' + ta)});
    });

    if (isExpertAdmin() || isAdmin()){
      tabs.push({ label: trans(t, 'menu|tab.parametres'), command: () => navigate(baseUri + '/parametres') });
    }
  }

  // Validate URL & build tabs.
  const checks = useCallback(() => {
    if (addMode) return isAdmin();

    return isUrlValid(t, id, tab);
  }, [id, tab]); // eslint-disable-line react-hooks/exhaustive-deps
  useEffect(() => {
    if (!checks()) setNotFound(true);

    let ai = 0;
    forEach(appUri.cie.exp.tabs, (ta, index) => {
      if (tab === ta) ai = index + 1;
    });
    if (tab === 'parametres') ai = 3;

    if (ai !== activeIndex) setActiveIndex(ai);
  }, [id, tab]); // eslint-disable-line react-hooks/exhaustive-deps

  const buildTabContent = useCallback(() => {
    switch (activeIndex) {
      case 1:
        // Users management.
        const params: IRequestParam[] = [{label: 'societeExpertId', value: data.id}];
        ['ROLE_USER'].map((r: string) => {
          return params.push({label: 'roles[]', value: r})
        });

        return <UsersExpertDatatable tableKey={'users-experts'} title={trans(t, 'menu|pages.title.user.experts')}
                                     params={params} noFilters apim={apim} />;

      case 2:
        // Dossiers management.
        return <SocieteExpertsDossiers appState={appState} tableKey={'societes-experts-dossiers'} title={trans(t, 'dossier', 2)}
                                       noFilters apim={apim} company={data} />;

      case 3:
        // Missions settings.
        if (isExpertAdmin() || isAdmin()) {
          return <MissionTypesSettings appState={appState} tableKey={'users-experts-dossiers'} title={trans(t, 'settings')} data={data} noFilters apim={apim}/>;
        } else setNotFound(true);
        return null;

      case 4:
        // Plan management.
        return <div className={'flex justify-content-center'}>
          <Message severity={'warn'} className={'p-5 rounded-md'} text={ucfirst(trans(t, 'in_progress_text'))} />
        </div>;

      default:
        // Profile.
        return <SocieteExpertsProfile company={data} apim={apim} />;
    }
  }, [id, tab, activeIndex, data]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (addMode) return;

    setLoading(true);
    apim.fetchEntity({
      resourceType: 'societesExperts',
      id: id,
      cache: false,
      setLoading: setLoading,
      setter: setData,
      setErrored: setErrored,
      setNotFound: setNotFound
    } as IRequestParams).then();
  }, [id]); // eslint-disable-line react-hooks/exhaustive-deps

  // Update Page Header.
  useEffect(() => {
    appState.setPageTitle((data ? trans(t, 'societeExpert') + ' ' + data?.raisonSociale : '') + ' | ' + trans(t, editMode ? 'editing' : 'adding'));
    appState.setPageActions([]);
    appState.setBreadcrumb([
      { label: trans(t, 'societeExpert', 2), to: appUri.cie.exp.list },
      { label: data ? data.raisonSociale : trans(t, 'company'), to: appUri.cie.exp.page.replace(':id', data?.id || '_') + '?maj=' + Date.now() },
      { label: trans(t, editMode ? 'editing' : 'adding') }
    ]);
  }, [data]); // eslint-disable-line react-hooks/exhaustive-deps

  if (isClient()) return <Unauthorized asBlock/>;
  if (notFound) return <NotFound asBlock/>;
  if (errored) return <Error asBlock/>;
  if (loading) return <PageLoader/>;

  return (
    <div className={'card height-100 fadein animation-duration-500 flex flex-column'}>
      <div className={'a8-page-header flex flex-wrap'}>
        <div className={'flex-auto flex p-2 justify-content-end'}>
          {tabs.length > 1 && <TabMenu model={tabs} activeIndex={activeIndex} onTabChange={(e) => setActiveIndex(e.index)}/>}
        </div>
      </div>
      {buildTabContent()}
    </div>
  );
};

const isUrlValid = (t: TFunction, id: string | undefined, tab: string | undefined) => {
  // Ensure valid entity UUID & existing tab.
  return isValidUUID(id) && (includes([...[undefined], ...appUri.cie.exp.tabs], tab) || (tab === 'parametres'));
};
