import React, { useEffect, useState } from 'react';
import { BreadCrumb } from 'primereact/breadcrumb';
import { MenuItem } from 'primereact/menuitem';

import { trans, ucfirst } from 'utilities';

export const AppBreadcrumb = (props: any) => {
  const { apim, appState } = props;
  const { t, navigate } = apim.di();

  const defaultModel = [{ label: trans(t, 'menu|menu.home') }];
  const [model, setModel] = useState<any>([]);

  const format = (items: any[]) => {
    const newItems: any[] = [];
    items.map((item) => {
      let newItem: MenuItem = {label: ucfirst(item?.label)};
      if (item?.to) {
        newItem.command = () => navigate(item.to);
      }
      return newItems.push(newItem);
    });

    return newItems;
  };

  // Update breadcrumb.
  const currentBreadcrumb = appState.breadcrumb();
  useEffect(() => {
    setModel(currentBreadcrumb.length < 1 ? defaultModel : format(currentBreadcrumb));
  }, [currentBreadcrumb]); // eslint-disable-line react-hooks/exhaustive-deps

  const home = { icon: 'pi pi-home', url: '/', label: trans(t, 'menu|menu.home') };

  return (
    <BreadCrumb model={model} home={home} className={'layout-breadcrumb p-pl-4 p-py-2'} />
  );
};
