export const unState = (data: any) => {
  return null === data ? null : JSON.parse(JSON.stringify(data));
};

export { useAppState } from './AppState';
export { useFormState, formKeyPrefix as formStateKeyPrefix } from './FormState';
export { useNotificationState } from './NotificationState';
export { useSearchState } from './SearchState';
export { useThemeState } from './ThemeState';
export { useUserState } from './UserState';
