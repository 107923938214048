import React, { useCallback, useEffect, useState } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';
import { TabMenu } from 'primereact/tabmenu';

import { IRequestParams, isClient, useApim } from 'services';
import { useAppState, useFormState, useUserState } from 'states';
import { PageLoader, } from 'components';
import { Error, NotFound, Document, DocumentActivityLog } from 'pages';
import { addShortcut, isValidUUID, trans, ucfirst } from 'utilities';

import { forEach, replace } from 'lodash';
import appUri from 'config/appUri.json';

export const DocumentRouter = (props: any) => {
  const { id, tab } = useParams();

  const apim = useApim();
  const { t, navigate } = apim.di();
  const appState = useAppState();
  const formState = useFormState();
  const userState = useUserState();

  const [loading, setLoading] = useState<boolean>(false);
  const [document, setDocument] = useState<any>();
  const [notFound, setNotFound] = useState<boolean>(false);
  const [errored, setErrored] = useState<boolean>(false);

  // Handle tabs.
  const [activeIndex, setActiveIndex] = useState<number>(0);
  const baseUri = replace(appUri.doc.page, ':id', id || '_');
  const tabs: any[] = [];
  if (id) {
    tabs.push({label: trans(t, 'menu|tab.document'), command: () => navigate(baseUri)});

    forEach(appUri.doc.tabs, (ta) => {
      tabs.push({label: trans(t, 'menu|tab.' + ta), command: () => navigate(baseUri + '/' + ta)});
    });
  }

  // Validate URL & build tabs.
  useEffect(() => {
    let ai = 0;
    forEach(appUri.doc.tabs, (ta, index) => {
      if (tab === ta) ai = index + 1;
    });

    if (ai !== activeIndex) setActiveIndex(ai);
  }, [id, tab]); // eslint-disable-line react-hooks/exhaustive-deps

  // Let's detect if API calls from now have to be refreshed (cache expiration).
  const [searchParams] = useSearchParams();
  const lastUpdate = searchParams.get('maj');
  const fetchDocument = useCallback(() => {
    if (!id) return;
    setLoading(true);

    apim.fetchEntity({
      resourceType: 'documents',
      id: id,
      cache: false,
      setLoading: setLoading,
      setter: setDocument,
      setErrored: setErrored,
      setNotFound: setNotFound
    } as IRequestParams).then();
  }, [id, lastUpdate]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (!document || !id || (document.id !== id) || lastUpdate) {
      fetchDocument();
    } else {
      setLoading(false);
    }
  }, [id, lastUpdate]); // eslint-disable-line react-hooks/exhaustive-deps

  // Update Page Header.
  useEffect(() => {
    let pageTitle: string = ucfirst(trans(t, 'document'));
    let documentTitle: string = document ? document?.id : '';
    if (isValidUUID(document?.id)) {
      if (document.title !== null && document.title !== undefined) {
        documentTitle = document.title;
      } else if (document.type !== null && document.type !== undefined) {
        documentTitle = document.type.name;
      } else if (document.filename !== null && document.filename !== undefined) {
        documentTitle = document.filename;
      }
    }

    const newPA: any[] = [];
    if (!isClient()) {
      newPA.push({ label: ucfirst(trans(t, 'short.add')), icon: 'bookmark', command: () => addShortcut(t, formState, appState) });
    }

    appState.setPageActions(newPA);
    appState.setPageTitle(pageTitle + (documentTitle ? ' : ' + documentTitle : ''));
    appState.setBreadcrumb([
      { label: trans(t, 'ged'), to: appUri.ged },
      { label: documentTitle }
    ]);
  }, [document, lastUpdate, userState.id()]); // eslint-disable-line react-hooks/exhaustive-deps

  const buildTabContent = useCallback(() => {
    switch (activeIndex) {
      case 1:
        return <DocumentActivityLog apim={apim} document={document} />;

      default:
        return <Document apim={apim} document={document} />;
    }
  }, [id, tab, activeIndex, document]); // eslint-disable-line react-hooks/exhaustive-deps

  if (notFound) return <NotFound asBlock/>;
  if (errored) return <Error asBlock/>;
  if (loading) return <PageLoader/>;

  return (
    <div className={'card height-100 fadein animation-duration-500 flex flex-column'}>
      <div className={'a8-page-header flex flex-wrap'}>
        <div className={'flex-auto flex p-2 justify-content-end pt-0'}>
          {tabs.length > 1 && <TabMenu className={'pb-2'} model={tabs} activeIndex={activeIndex} onTabChange={(e) => setActiveIndex(e.index)}/>}
        </div>
      </div>
      {buildTabContent()}
    </div>
  );
};
