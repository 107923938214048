import React, { useEffect, useState } from 'react';

import { Skeleton } from 'primereact/skeleton';
import { Fieldset } from 'primereact/fieldset';

import { IRequestParams, useApim } from 'services';
import { UncontrolledCheckboxField } from 'forms';
import { isValidUUID, trans, ucfirst } from 'utilities';
import { useFormState } from 'states';

export const PermissionsField = (props: any) => {
  const { fieldKey, getValues, setValue, label, classes, formKey, parentKey } = props;

  const apim = useApim();
  const { t } = apim.di();
  const formState = useFormState();

  const [loading, setLoading] = useState<boolean>(false);
  const [permissions, setPermissions] = useState<any>([]);

  useEffect(() => {
    setLoading(true);
    apim.fetchEntities({
      resourceType: 'permissions',
      params: [
        {label: 'expand[]', value: 'permission:read_group'},
        {label: 'expand[]', value: 'permission_group:read'}
      ],
      setter: setPermissions,
      setLoading,
    } as IRequestParams).then();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const getPermissionsByGroup = (): any[] => {
    if (permissions.length === 0) {
      return [];
    }

    let permissionsWithoutGroup: any = [];
    let permissionsByGroup: any = [];

    permissions.map((permission: any) => {
      // Check if the permission is in a group.
      if (isValidUUID(permission?.permissionGroup?.id)) {
        let found = false;
        permissionsByGroup.map((item: any) => {
          if (item.permissionGroup?.id === permission?.permissionGroup?.id) {
            found = true;
            item.permissions.push(permission);
          }

          return null;
        });

        if (!found) {
          const item = {
            permissionGroup: permission?.permissionGroup,
            permissions: [permission],
          }

          permissionsByGroup.push(item);
        }
      } else {
        // Add in the list which have no group.
        permissionsWithoutGroup.push(permission);
      }

      return null;
    })

    if (permissionsWithoutGroup.length > 0) {
      permissionsByGroup.push({permissionGroup: null, permissions: permissionsWithoutGroup})
    }

    return permissionsByGroup;
  }

  const getPermissionValue = (permission: any): boolean => {
    const value: any = (getValues() ?? {})[fieldKey] ?? [];
    if (value === null || value === undefined || value.length === 0) {
      return false;
    }

    let found = false;
    value.map((v: string) => {
      if (v === permission.id) {
        found = true;
      }

      return null;
    });

    return found;
  }

  const changePermissionValue = (permission: any, permissionValue: boolean) => {
    let _value = [];
    const value: any = (getValues() ?? {})[fieldKey] ?? [];

    if (permissionValue) {
      // Add permission to list.
      let found = false;
      if (value.length > 0) {
        value.map((v: string) => {
          if (v === permission.id) {
            found = true;
          }

          _value.push(v);
          return null;
        });
      }

      if (!found) {
        _value.push(permission.id);
      }

    } else {
      // Remove permission from list.
      if (value.length > 0) {
        value.map((v: string) => {
          if (v !== permission.id) {
            _value.push(v);
          }

          return null;
        });
      }
    }

    formState.setFieldData(formKey, fieldKey, parentKey, _value);
    setValue(fieldKey, _value);
  }

  return <div className={classes}>
      {label !== null && (
        <h2 className={'text-base m-0'}>{label}</h2>
      )}

      {loading ? (
        <Skeleton height={'2.8rem'} />
      ) : (
        <>
          {getPermissionsByGroup().map((item: any, index: number) => {
            return <Fieldset key={index} legend={item.permissionGroup !== null ? item.permissionGroup.label ?? item.permissionGroup.name : ucfirst(trans(t, 'permission', 2))}>
              {item.permissions.map((permission: any) => {
                return <UncontrolledCheckboxField key={permission.id} classes={''}
                                                  value={getPermissionValue(permission)}
                                                  onFieldChange={(v: any) => changePermissionValue(permission, v)}
                                                  label={permission.label ?? permission.name}
                                                  tooltip={permission.description}/>
                })
              }
            </Fieldset>;
          })}
        </>
      )}
    </div>;
};
