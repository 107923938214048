import React, { useEffect, useState } from 'react';

import { AxiosResponse } from 'axios';

import { escapeValue, isValidUUID, trans } from 'utilities';
import { NumberField, CheckboxField, TextField, APIListField, MaskField } from 'forms';
import { useAppState, useFormState, useThemeState } from 'states';
import { IRequestParams, useApim } from 'services';

export const DonneesFiscalesForm = (props: any) => {
  const { t, control, onFieldChange, errorMessage, formKey, parentKey, getValues, setValue } = props;
  const themeState = useThemeState();
  const formState = useFormState();
  const appState = useAppState();
  const apim = useApim();

  const {
    company = null,
    fiscalYear = null,
    valeurRemunerationNette = 0,
    valeurCotisationsSocialesObligatoires = 0,
    valeurCotisationsSocialesFacultatives = 0,
    valeurPrelevementsSociaux = 0,
    valeurAvantagesDivers = 0,
    valeurImpotsSociete = 0,
    valeurResultatNet = 0,
    reductionImpotMadelin = false,
  } = getValues();

  // Load previous year's data
  const [previousYearData, setPreviousYearData] = useState<any>(null);
  useEffect(() => {
    apim.fetchEntities({
      resourceType: 'fiscalData',
      params: [{label: 'company', value: company}, {label: 'fiscalYear', value: fiscalYear - 1}],
      cache: false,
      success: (res: AxiosResponse) => {
        if (res?.data && (res?.data['hydra:member'] || []).length > 0) {
          setPreviousYearData(res.data['hydra:member'][0]);
        } else {
          setPreviousYearData(null);
        }
      }
    } as IRequestParams).then();
  }, [appState.company()?.id, appState.exercise()]); // eslint-disable-line react-hooks/exhaustive-deps

  const numberFieldsRates: any[] = [
    {key: 'tauxRemunerationNette', addon: 'percentage', mode: 'decimal'},
    {key: 'tauxCotisationsSocialesObligatoires', addon: 'percentage', mode: 'decimal'},
    {key: 'tauxCotisationsSocialesFacultatives', addon: 'percentage', mode: 'decimal'},
    {key: 'tauxPrelevementsSociaux', addon: 'percentage', mode: 'decimal'},
    {key: 'tauxAvantagesDivers', addon: 'percentage', mode: 'decimal'},
    {key: 'tauxImpotsSociete', addon: 'percentage', mode: 'decimal'},
    {key: 'tauxResultatNet', addon: 'percentage', mode: 'decimal'},
  ];

  const numberFieldsValues: any[] = [
    {key: 'valeurRemunerationNette'},
    {key: 'valeurCotisationsSocialesObligatoires'},
    {key: 'valeurCotisationsSocialesFacultatives'},
    {key: 'valeurPrelevementsSociaux'},
    {key: 'valeurAvantagesDivers'},
    {key: 'valeurImpotsSociete'},
    {key: 'valeurResultatNet', disabled: true, tooltip: trans(t, 'form|tooltip.donneesFiscales.valeurResultatNet')},
  ];

  const numberFields: any[] = [
    {key: 'valeurRemunerationNette'},
    {key: 'tauxRemunerationNette'},
    {key: 'valeurCotisationsSocialesObligatoires'},
    {key: 'tauxCotisationsSocialesObligatoires'},
    {key: 'valeurCotisationsSocialesFacultatives'},
    {key: 'tauxCotisationsSocialesFacultatives'},
    {key: 'valeurPrelevementsSociaux'},
    {key: 'tauxPrelevementsSociaux'},
    {key: 'valeurAvantagesDivers'},
    {key: 'tauxAvantagesDivers'},
    {key: 'valeurImpotsSociete'},
    {key: 'tauxImpotsSociete'},
    {key: 'valeurResultatNet', disabled: true, tooltip: trans(t, 'form|tooltip.donneesFiscales.valeurResultatNet')},
    {key: 'tauxResultatNet'},
    {key: 'valeurRessourcesTotales'},
    {key: 'nscsSurDiv'},
    {key: 'regimeImpositionReel'},
  ];

  // Update related calculated fields.
  const calculateFields = () => {
    let newTotal: number = 0;
    numberFieldsValues.map(((f: any) => {
      const currentValue: number = getValues()?.[f?.key] || 0;
      newTotal += currentValue;
      return null;
    }));

    formState.setFieldData(formKey, 'valeurRessourcesTotales', parentKey, escapeValue(newTotal, 'number'));
    setValue('valeurRessourcesTotales', newTotal ?? 0);

    numberFieldsValues.map(((f: any) => {
      const currentValue: number = getValues()?.[f?.key] || 0;
      formState.setFieldData(formKey, f?.key.replace('valeur', 'taux'), parentKey, escapeValue(100 * currentValue / newTotal, 'number'));
      setValue(f?.key.replace('valeur', 'taux'), newTotal > 0 ? 100 * currentValue / newTotal : 0);
      return null;
    }));
  };

  // Triggering calculations everytime related values are updated
  useEffect(() => {
    calculateFields();
  }, [valeurRemunerationNette, valeurCotisationsSocialesObligatoires, valeurCotisationsSocialesFacultatives, valeurPrelevementsSociaux, valeurAvantagesDivers, valeurImpotsSociete, valeurResultatNet]); // eslint-disable-line react-hooks/exhaustive-deps

  // Triggering default value when last year's data is loaded
  useEffect(() => {
    if (!isValidUUID(previousYearData?.id)) {
      return;
    }

    const defaultFields = [
      'categorieRevenus',
      'regimeImposition',
      'regimeImpositionReel',
      'regimeTva',
      'operationTemporaireIr',
      'reductionImpotMadelin',
      'regimeSocietesMeresFiliales'
    ];

    defaultFields.map((defaultField: string) => {
      const previousYearFieldValue = previousYearData[defaultField];
      const currentFieldValue = getValues()[defaultField];

      // Override the value only if it's not set yet.
      if (previousYearFieldValue !== null && previousYearFieldValue !== undefined
        && (currentFieldValue === null || currentFieldValue === undefined || currentFieldValue.length === 0)) {
        formState.setFieldData(formKey, defaultField, parentKey, previousYearData[defaultField]);
        setValue(defaultField, previousYearData[defaultField]);
      }

      return null;
    });
  }, [previousYearData]); // eslint-disable-line react-hooks/exhaustive-deps

  return <>
    <div className={'flex flex-column lg:flex-row col-12 '}>
      {themeState.isMobile() ? (
        <div className={'flex flex-column col-12 mx-2 pb-0'}>
          {numberFields.map((field: any, index: number) => (
            <NumberField key={index} fieldKey={field.key} mode={field.mode || 'currency'} disabled={field.disabled || false}
                         addon={field.addon || 'euro'} addonPosition={'after'} control={control} onFieldChange={onFieldChange}
                         errorMessage={errorMessage} label={trans(t, 'chart|fiscalData.' + field.key)} tooltip={field.tooltip} classes={'w-12 y-2'} />
          ))}
        </div>
      ):(
        <>
          <div className={'flex flex-column col-12 lg:col-8 pt-0'}>
            <div className={'flex flex-row justify-content-center'}>
              <div className={'flex flex-column col-6 p-0 pr-2'}>
                {numberFieldsValues.map((field: any, index: number) => (
                  <NumberField key={index} fieldKey={field.key} mode={field.mode || 'currency'} disabled={field.disabled || false}
                               addon={field.addon || 'euro'} addonPosition={'after'} control={control} onFieldChange={onFieldChange}
                               errorMessage={errorMessage} label={trans(t, 'chart|fiscalData.' + field.key)} tooltip={field.tooltip} classes={'w-12 py-2'} />
                ))}
              </div>
              <div className={'flex flex-column col-6 p-0 pl-2'}>
                {numberFieldsRates.map((field: any, index: number) => (
                  <NumberField key={index} fieldKey={field.key} mode={field.mode || 'currency'} disabled
                               addon={field.addon || 'euro'} addonPosition={'after'} control={control} onFieldChange={onFieldChange} suffix={'%'}
                               errorMessage={errorMessage} label={trans(t, 'chart|fiscalData.' + field.key)} tooltip={field.tooltip} classes={'w-12 py-2'} />
                ))}
              </div>
            </div>
            <div className={'flex flex-column w-12'}>
              <NumberField fieldKey={'valeurRessourcesTotales'} mode={'currency'} disabled addon={'euro'} addonPosition={'after'} control={control} onFieldChange={onFieldChange}
                           errorMessage={errorMessage} label={trans(t, 'chart|fiscalData.valeurRessourcesTotales')} classes={'w-12 py-2'} />
              <NumberField fieldKey={'nscsSurDiv'} mode={'currency'} addon={'euro'} addonPosition={'after'} control={control} onFieldChange={onFieldChange}
                           errorMessage={errorMessage} label={trans(t, 'chart|fiscalData.nscsSurDiv')} classes={'w-12 py-2'} />
            </div>
          </div>
        </>
      )}
      <div className={'flex flex-column col-12 lg:col-4 pt-0'}>
        <APIListField listKey={'categoriesRevenus'} fieldKey={'categorieRevenus'} control={control} onFieldChange={onFieldChange} errorMessage={errorMessage} classes={'w-12 py-2'}
                      label={trans(t, 'chart|fiscalData.categorieRevenus')} showClear/>

        <APIListField listKey={'regimesImposition'} fieldKey={'regimeImposition'} control={control} onFieldChange={onFieldChange} errorMessage={errorMessage} classes={'w-12 py-2'}
                      label={trans(t, 'chart|fiscalData.regimeImposition')} showClear/>

        <TextField fieldKey={'regimeImpositionReel'} control={control} onFieldChange={onFieldChange} errorMessage={errorMessage} label={trans(t, 'chart|fiscalData.regimeImpositionReel')} classes={'w-12 py-2'} />

        <APIListField listKey={'regimesTVA'} fieldKey={'regimeTva'} control={control} onFieldChange={onFieldChange} errorMessage={errorMessage} classes={'w-12 py-2'}
                      label={trans(t, 'chart|fiscalData.regimesTVA')} showClear />

        <CheckboxField fieldKey={'operationTemporaireIr'} control={control} onFieldChange={onFieldChange} errorMessage={errorMessage} label={trans(t, 'chart|fiscalData.operationTemporaireIr')} classes={'w-12 py-2'}/>

        <CheckboxField fieldKey={'reductionImpotMadelin'} control={control} onFieldChange={onFieldChange} errorMessage={errorMessage} label={trans(t, 'chart|fiscalData.reductionImpotMadelin')} classes={'w-12 py-2'}
                       tooltip={trans(t, 'form|tooltip.donneesFiscales.reductionImpotMadelin')} />

        {reductionImpotMadelin && (
          <MaskField fieldKey={'finEngagement'} control={control} onFieldChange={onFieldChange} classes={'w-full'} errorMessage={errorMessage} isDate
                     rules={{ required: trans(t, 'form|requiredField') }} label={trans(t, 'chart|fiscalData.finEngagement')}/>
        )}

        <CheckboxField fieldKey={'regimeSocietesMeresFiliales'} control={control} onFieldChange={onFieldChange} errorMessage={errorMessage} label={trans(t, 'chart|fiscalData.regimeSocietesMeresFiliales')} classes={'w-12 py-2'}/>
      </div>
    </div>
  </>;
};
