import React, { useCallback, useEffect, useState } from 'react';

import { Column } from 'primereact/column';

import { IRequestParams, isAdmin, isExpert } from 'services';
import { dialog, iri, trans, ucfirst, triggerFormReset, triggerFormSubmit } from 'utilities';
import {
  DatatableWrapper,
  validCell,
  textEditor,
  handlePersonnePhysiqueAddress, handlePersonnePhysiqueRelatedEntity, onPersonnePhysiqueAddSubmit,
  addressBody, birthDateBody, ssnBody, simpleCheckboxEditor, personCell, maskEditor, apiListEditor, dateTimeCell
} from 'components';
import { FormWrapper } from 'forms';

import { forEach, omit } from 'lodash';
import appUri from 'config/appUri.json';

export const EmployeesPersonnesPhysiquesDatatable = (props: any) => {
  const { rows, setRows, tableKey, title, editMode, params, context, apim, header } = props;
  const { t } = apim.di();

  const [loading, setLoading] = useState<boolean>(false);
  const [prevoyanceRetraiteRegimes, setPrevoyanceRetraiteRegimes] = useState<any[]>([]);

  // /!\ WARNING /!\ Let's keep 'personnesPhysiques' instead of 'employeesPersonnesPhysiques' as resourceType on purpose
  // because (believe it or not but) this way is simpler during edit process :)
  const resourceType = 'personnesPhysiques';

  useEffect(() => {
    apim.getList('prevoyanceRetraiteRegimes', {setter: setPrevoyanceRetraiteRegimes} as IRequestParams).then();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const activeBodyTemplate = (rowData: any) => validCell(rowData?.active);
  const functionStartDateBodyTemplate = (rowData: any) => dateTimeCell(rowData?.date);
  const fullNameBodyTemplate = (rowData: any) => personCell(rowData?.personnePhysique, {
    url: appUri.cie.per.phy.page.replace(':id', rowData?.personnePhysique?.id),
    label: trans(t, 'seeDetails')
  });
  const prevoyanceRetraiteRegimeBodyTemplate = (rowData: any) => {
    const match: any = (prevoyanceRetraiteRegimes || []).filter((m: any) => rowData?.prevoyanceRetraiteRegime?.toLowerCase() === m?.value);
    return match.length > 0 ? match[0].translated : rowData?.status ?? '';
  };

  const onRowEditCallback = (patched: any) => {
    if (!patched || !patched[resourceType] || !patched[resourceType].id) return;

    // Handle personnesPhysiquesData (personne_physique_datas).
    handlePersonnePhysiqueRelatedEntity(apim, context, 'personnesPhysiquesData', patched, setLoading, '', header);

    // Handle employeesPersonnesPhysiques (personne_physique_employees).
    handlePersonnePhysiqueRelatedEntity(apim, context, 'employeesPersonnesPhysiques', patched, setLoading, '', header);

    // Handle address.
    handlePersonnePhysiqueAddress(patched[resourceType], apim, header);
  };

  const hookRowEdit = (_rows: any[], editedRow: any) => {
    if (!editedRow) return;
    const original: any = rows.filter((r: any) => r.id === editedRow.id)[0];
    const personnePhysique: any = editedRow.personnePhysique;
    let tmp: any = omit(editedRow, ['personnePhysique']);
    const patched: any = {
      personnesPhysiques: { id: original?.personnePhysique?.id },
      personnesPhysiquesData: { id: original?.personnePhysique?.latestVersion?.id },
      employeesPersonnesPhysiques: { id: original?.id }
    };

    // Let's pick only patched data.
    // 1. "personne_physiques" fields.
    forEach(['lastName', 'firstName', 'birthDate', 'socialSecurityNumber', 'email', 'phone1'], ((field: string) => {
      if (undefined === tmp['personnePhysique.' + field] || tmp['personnePhysique.' + field] === original?.personnePhysique[field]) {
        tmp = omit(tmp, 'personnePhysique.' + field);
        return;
        }
      patched.personnesPhysiques[field] = tmp['personnePhysique.' + field];
      tmp = omit(tmp, 'personnePhysique.' + field);
      if ('lastName' === field || 'firstName' === field) {
        // isPersonnesPhysiquesModified = true;
        patched.personnesPhysiquesData[field] = patched.personnesPhysiques[field];
      }
    }));

    // 2. "personne_physique_employees" (root fields).
    forEach(['function', 'date', 'prevoyanceRetraiteRegime', 'active'], ((field: string) => {
      if (undefined === tmp[field] || tmp[field] === original[field]) {
        tmp = omit(tmp, field);
        return;
      }
      patched.employeesPersonnesPhysiques[field] = tmp[field];
      tmp = omit(tmp, field);
    }));

    // Then update rows using a proper rows array.
    // return formatPersonPhysiquePatched(_rows, personnePhysique, tmp, patched, 'employeesPersonnesPhysiques');
    return { formattedRows: _rows, patched: patched, id: personnePhysique?.id };
  };

  const onDelete = (row: any) => {
    if (row?.id) {
      apim.deleteEntity({
        headers: header,
        resourceType: 'employeesPersonnesPhysiques',
        id: row?.id
      } as IRequestParams).then();
    }
  };

  const onNew = () => {
    dialog(t, {
      header: trans(t,'form|company.personnePhysiqueEmployeeAdd'),
      icon: 'none',
      message: renderAddForm(),
      accept: () => triggerFormSubmit('a8-form-company-epp-add'),
      reject: () => triggerFormReset('a8-form-company-epp-reset')
    });
  };

  // Handle submission then refresh table rows.
  const onAddSubmit = useCallback(
    (formData: any) => onPersonnePhysiqueAddSubmit(apim, context, formData, rows, setRows, 'employeesPersonnesPhysiques', setLoading, '', header)
  , [rows]); // eslint-disable-line react-hooks/exhaustive-deps

  // Wrap form render into a useCallback to avoid multiple FormWrapper recalls du to form fields updates.
  const renderAddForm = useCallback(() =>
      <FormWrapper classes={'grid p-fluid w-12'} resourceType={resourceType} formKeyPrefix={'add_pp_dialog'} cancelLink multiple context={context}
                   resetClass={'a8-form-company-epp-reset'} submitClass={'a8-form-company-epp-add'} onSubmit={onAddSubmit} hideReload />
    , [context?.companyId, rows]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <DatatableWrapper resourceType={resourceType} tableKey={tableKey || 'employees-personnes-physiques'} noFilters noGlobalFilter params={params} isLoading={loading} onRefresh={setRows}
                      addTitle={trans(t, 'table|add.employee')} editMode={(editMode && (isAdmin() || isExpert())) || false} hookRowEdit={hookRowEdit} onRowEditCallback={onRowEditCallback}
                      rows={(rows || []).sort((a: any, b: any) => a.personnePhysique?.lastName?.localeCompare(b.personnePhysique?.lastName) || a.personnePhysique?.firstName?.localeCompare(b.personnePhysique?.firstName))}
                      onDelete={onDelete} onNew={onNew} title={title || (ucfirst(trans(t, 'employeePersonnePhysique', rows.length)) + ' - ' + ucfirst(trans(t, 'personnePhysique', rows.length)))}
                      additionalData={{ companyData: iri('dossierCompanyData', context?.id) }} paginator={false}>
      <Column field={'personnePhysique.fullName'} header={trans(t, 'fullName')} body={fullNameBodyTemplate}
              style={{ minWidth: '250px', width: '250px' }} />
      <Column field={'personnePhysique.birthDate'} header={trans(t, 'birthDate')} align={'center'} alignHeader={'center'} body={birthDateBody}
              style={{ minWidth: '100px', width: '100px' }} />
      <Column field={'personnePhysique.socialSecurityNumber'} header={trans(t, 'socialSecurityNumber')} align={'center'} alignHeader={'center'} body={ssnBody} />
      <Column field={'personnePhysique.address'} header={trans(t, 'address')} body={addressBody} />
      <Column field={'date'} header={trans(t, 'functionStartDate')} align={'center'} alignHeader={'center'} body={functionStartDateBodyTemplate}
              editor={(options) => maskEditor(options, {label: trans(t, 'functionStartDate'), isDate: true})}
              style={{ minWidth: '150px', width: '150px', maxWidth: '150px' }} />
      <Column field={'function'} header={trans(t, 'function')} align={'center'} alignHeader={'center'}
              editor={(options) => textEditor(options, {label: trans(t, 'function')})}
              style={{ minWidth: '200px', width: '200px', maxWidth: '200px' }} />
      <Column field={'prevoyanceRetraiteRegime'} header={trans(t, 'prevoyanceRetraiteRegime')} align={'center'} alignHeader={'center'} body={prevoyanceRetraiteRegimeBodyTemplate}
              editor={(options) => apiListEditor(options, {label: trans(t, 'prevoyanceRetraiteRegime'), listKey: 'prevoyanceRetraiteRegimes', showClear: true})}
              style={{ minWidth: '250px', width: '250px', maxWidth: '250px' }} />
      {isAdmin() && <Column field={'active'} header={trans(t, 'active')} body={activeBodyTemplate} align={'center'} alignHeader={'center'}
                            editor={(options) => simpleCheckboxEditor(options, {label: trans(t, 'table|active')})}
                            style={{ minWidth: '100px', width: '100px' }} />}
    </DatatableWrapper>
  );
};
