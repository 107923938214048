import React from 'react';
import { Card } from 'primereact/card';
import { classNames } from 'primereact/utils';

import { dateString, trans, ucfirst } from 'utilities';
import user from 'assets/images/illustrations/search/user.webp';

import appUri from 'config/appUri.json';

export const SearchResultUser = (props: any) => {
  const { data, apim, setVisibleFullScreen } = props;
  const { t, navigate } = apim.di();
  const redirectUri = appUri.usr.dir.page.replace(':id', data.id || '_');
  const header = () => <img alt="Card" src={user} className={'border-round border-noround-bottom'} />;

  return <Card header={header} title={ucfirst(trans(t, 'user'))} subTitle={data.firstName + ' ' + data.lastName} className={classNames('a8-search-item mb-4 mx-1')} onClick={() => {
    navigate(redirectUri);
    setVisibleFullScreen(false);
  }}>
    <ul className={'m-0 pl-4'}>
      <li>{ucfirst(trans(t, 'email'))}: {data.email}</li>
      <li>{ucfirst(trans(t, 'phone'))}: {data.phone}</li>
      <li>{ucfirst(trans(t, 'lastLogin'))}: {dateString(data.lastLogin)}</li>
    </ul>
  </Card>
    ;
};
