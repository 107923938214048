import React from 'react';
import { trans, ucfirst } from 'utilities';
import { CheckboxField, EditorField, TextField } from 'forms';

export const Notifications = (props: any) => {
  const { t, control, onFieldChange, errorMessage } = props;

  return <div className={'pt-2'}>
    <TextField fieldKey={'title'} control={control} onFieldChange={onFieldChange} errorMessage={errorMessage}
               classes={'col'}
               rules={{ required: trans(t, 'form|requiredField') }} label={trans(t, 'title')} />
    <EditorField classes={'col'} fieldKey={'content'} control={control} onFieldChange={onFieldChange}
                 errorMessage={errorMessage} label={ucfirst(trans(t, 'form|notificationContent'))}
                 rules={{ required: trans(t, 'form|requiredField') }} />
    <CheckboxField classes={'col'} fieldKey={'sendEmail'} control={control} onFieldChange={onFieldChange} errorMessage={errorMessage}
                   label={trans(t, 'form|emailSend')} />
  </div>

}
