import React from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from 'primereact/button';

import { handleCopy, trans, ucfirst } from 'utilities';
import { addressCell } from 'components';

export const AddressField = (props: any) => {
  const { t } = useTranslation();
  const { address, title } = props;

  const addressText = address ? (address.address1 + ' ' + (address.address2 ? address.address2 + ', ' : '') +
    address.postalCode + ', ' + address.city + (address.country ? (' - ' + address.country) : '')) : '';

  return (
    <div>
      <div className={'flex flex-row align-items-end'}>
        <img src='/assets/images/avatar/company.png' alt={trans(t, 'entreprise')} style={{height: '1.8em'}} className={'mr-2'} />
        <strong className={'titre'} style={{color: '#5B6276'}}>{ucfirst(title || trans(t, 'address'))}</strong>
      </div>

      <div className={'informations flex flex-row'}>
        <div className={'flex flex-1 valeur pt-2'}>
          {'' !== addressText ? (
            <>
              <a target={'_blank'} rel={'noreferrer'} href={`https://maps.google.com/maps?q=${addressText}`} className={'link-address'}>{addressCell(address)}</a>
              <Button onClick={() => handleCopy(addressText)} icon='pi pi-copy' size={'large'} rounded text className={'ml-2'} />
            </>
          ) : (
            <span>{trans(t,'nr')}</span>
          )}
        </div>
      </div>
    </div>
  );
};
