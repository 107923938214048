import React, { useCallback, useEffect, useState } from 'react';
import { useParams, useLocation, useSearchParams } from 'react-router-dom';
import { TFunction } from 'i18next';
import { AxiosResponse } from 'axios';
import { TabMenu } from 'primereact/tabmenu';

import { IRequestParams, isAdmin, isSuperAdmin, useApim } from 'services';
import { useAppState, useFormState, useUserState } from 'states';
import { PageLoader } from 'components';
import { Error, NotFound } from 'pages';
import { PersonnePhysiqueFormRouter } from 'forms';
import { addShortcut, dialog, isValidUUID, trans, ucfirst } from 'utilities';

import { PersonnePhysiqueCompanies } from './PersonnePhysiqueCompanies';
import { PersonnePhysiqueFoyer } from './PersonnePhysiqueFoyer';
import { PersonnePhysiqueProfile } from './PersonnePhysiqueProfile';

import { includes, isEmpty, forEach } from 'lodash';
import appUri from 'config/appUri.json';

export const PersonnePhysiqueRouter = (props: any) => {
  const { action } = props;
  const { id, tab } = useParams();

  const apim = useApim();
  const { t, navigate } = apim.di();
  const location = useLocation();
  const appState = useAppState();
  const formState = useFormState();
  const userState = useUserState();

  const [loading, setLoading] = useState<boolean>(false);
  const [data, setData] = useState<any>();
  const [notFound, setNotFound] = useState<boolean>(false);
  const [errored, setErrored] = useState<boolean>(false);

  // Let's detect if we are in "edit mode"
  // then render the appropriate display (edit form or module dashboard).
  const editMode = appUri.reservedKeywords.edit === action;

  // Handle tabs.
  const [activeIndex, setActiveIndex] = useState<number>(0);
  const baseUri = appUri.cie.per.phy.page.replace(':id', id || '_');
  const tabs: any[] = [];
  if (id) {
    tabs.push({label: trans(t, 'menu|tab.general'), command: () => navigate(baseUri)});

    forEach(appUri.cie.per.phy.tabs, (ta) => {
      tabs.push({label: trans(t, 'menu|tab.' + ta), command: () => navigate(baseUri + '/' + ta)});
    });
  }

  // Validate URL & build tabs.
  const checks = useCallback(() => {
    return isUrlValid(t, id, tab);
  }, [id, tab]); // eslint-disable-line react-hooks/exhaustive-deps
  useEffect(() => {
    if (!checks()) setNotFound(true);

    let ai = 0;
    forEach(appUri.cie.per.phy.tabs, (ta, index) => {
      if (tab === ta) ai = index + 1;
    });

    if (ai !== activeIndex) setActiveIndex(ai);
  }, [id, tab]); // eslint-disable-line react-hooks/exhaustive-deps

  // Let's detect if API calls from now have to be refreshed (cache expiration).
  const [searchParams] = useSearchParams();
  const lastUpdate = searchParams.get('maj');
  const fetchData = useCallback(() => {
    if (!id || editMode) return;
    setLoading(true);

    apim.fetchEntity({
      resourceType: 'personnesPhysiques',
      id: id,
      cacheMode: isEmpty(lastUpdate) ? 'default' : 'replace',
      setErrored: setErrored,
      setNotFound: setNotFound,
      error: () => setLoading(false),
      success: (res: AxiosResponse) => {
        apim.fetchEntities({
          resourceType: 'dossiers',
          params: [{ label: 'personnePhysique', value: res.data?.id }],
          setErrored: setErrored,
          setNotFound: setNotFound,
          setLoading: setLoading,
          success: (resDir: AxiosResponse) => {
            setData(res?.data);

            if (
              !resDir?.data ||
              !resDir.data['hydra:member'] ||
              resDir.data['hydra:member'].length === 0 ||
              !resDir.data['hydra:member'][0].user
            ) return;

            // Redirect on "dirigeant" profile page if this Personne Physique also is a user.
            navigate(appUri.usr.dir.page.replace(':id', resDir.data['hydra:member'][0].user || '_'));
          }
        } as IRequestParams).then();
      }
    } as IRequestParams).then();
  }, [id, lastUpdate]); // eslint-disable-line react-hooks/exhaustive-deps



  useEffect(() => {
    if (!data || !id || (data.id !== id) || lastUpdate) {
      fetchData();
    } else {
      setLoading(false);
    }
  }, [id, lastUpdate]); // eslint-disable-line react-hooks/exhaustive-deps

  // Update Page Header.
  useEffect(() => {
    let pageTitle: string = ucfirst(trans(t, 'profile') + ' ' + (data ? data.firstName + ' ' + data.lastName : ''));
    if (editMode) {
      pageTitle += ' | ' + trans(t, 'edit');
      appState.setPageActions([]);
    } else {
      const newPA: any[] = [
        { label: trans(t, 'short.add'), icon: 'bookmark', command: () => addShortcut(t, formState, appState) },
        { label: trans(t, 'pers.edit'), icon: 'pencil', to: appUri.cie.per.phy.edit.replace(':id', id || '_') }
      ];
      if (isAdmin()) {
        newPA.push({ label: trans(t, 'pers.add'), icon: 'plus', to: appUri.cie.per.phy.add, className:'bg-green-500' });
      }
      if (isSuperAdmin()) {
        newPA.push({
          label: trans(t, 'pers.delete'), icon: 'trash', className:'bg-red-500', command: () =>
            dialog(t, {
              message: ucfirst(trans(t, 'system|confirmations.personnePhysique.delete')),
              accept: () => {
                if (data?.id) {
                  apim.deleteEntity({
                    resourceType: 'personnesPhysiques',
                    id: data?.id,
                    success: () => {
                      navigate(appUri.cie.per.phy.list);
                    },
                  } as IRequestParams);
                }
              },
              acceptClassName: 'p-button-danger',
              rejectClassName: 'p-button-text p-button-primary'
            })
        });
      }
      appState.setPageActions(newPA);
    }
    appState.setPageTitle(pageTitle);
    appState.setBreadcrumb([{ label: trans(t, 'personnePhysique', 2) }, { label: ((data ? data.firstName + ' ' + data.lastName : trans(t, 'personnePhysique'))) }]);
  }, [data, lastUpdate, userState.id()]); // eslint-disable-line react-hooks/exhaustive-deps

  const buildTabContent = useCallback(() => {
    switch (activeIndex) {
      case 2: return <PersonnePhysiqueCompanies apim={apim} data={data} t={t}/>;
      case 1: return <PersonnePhysiqueFoyer apim={apim} data={data} t={t}/>;
      default: return <PersonnePhysiqueProfile data={data} t={t}/>;
    }
  }, [id, tab, activeIndex, data]); // eslint-disable-line react-hooks/exhaustive-deps

  if (editMode) return <PersonnePhysiqueFormRouter id={id} tab={tab} appState={appState} apim={apim} location={location}/>
  if (notFound) return <NotFound asBlock/>;
  if (errored) return <Error asBlock/>;
  if (loading) return <PageLoader/>;

  return (
    <div className={'card height-100 fadein animation-duration-500 flex flex-column'}>
      <div className={'a8-page-header flex flex-wrap'}>
        <div className={'flex-auto flex p-2 justify-content-end pt-0'}>
          {tabs.length > 1 && <TabMenu className={'pb-2'} model={tabs} activeIndex={activeIndex} onTabChange={(e) => setActiveIndex(e.index)}/>}
        </div>
      </div>
      {buildTabContent()}
    </div>
  );
};

const isUrlValid = (t: TFunction, id: string | undefined, tab: string | undefined) => isValidUUID(id) && includes([...[undefined], ...appUri.cie.per.phy.tabs], tab);
