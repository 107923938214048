import React, {useEffect, useState} from 'react';
import { Controller } from 'react-hook-form';

import { classNames } from 'primereact/utils';
import { Dropdown } from 'primereact/dropdown';
import { Skeleton } from 'primereact/skeleton';

import { trans } from 'utilities';
import { IRequestParams, isAdmin, useApim } from 'services';

export const RolesField = (props: any) => {
  const { fieldKey, control, onFieldChange, errorMessage, rules, rolesAvailables, enforceRolesAvailables,
    label, placeholder, filter, classes, showClear, editable } = props;

  const apim = useApim();
  const { t } = apim.di();

  const [loading, setLoading] = useState<boolean>(false);
  const [roles, setRoles] = useState<any>(null);

  const restRoles: string[] = rolesAvailables ?? ['ROLE_EXPERT', 'ROLE_EXPERT_ADMIN', 'ROLE_CLIENT'];
  const restRolesEnforce: boolean = (enforceRolesAvailables !== null && enforceRolesAvailables !== undefined) ? enforceRolesAvailables : false;

  useEffect(() => {
    setLoading(true);
    apim.fetchEntities({
      resourceType: 'roles',
      setter: setRoles,
      setLoading,
      formatter: (_roles: any[]) => {
        let _entities = _roles.filter((_role: any) => restRoles.includes(_role.name));

        // Only display all roles if not enforced & admin.
        if (isAdmin() && !restRolesEnforce) {
          _entities = _roles;
        }

        _entities = _entities.map((_role: any) => {
          return ({..._role, ...{
              translated: trans(t, 'system|role.' + _role.name.toLowerCase()),
              order: trans(t, 'system|role_ordered.' + _role.name.toLowerCase())
            }})
        });

        _entities.sort((_a: any, _b: any) => _a.order > _b.order ? 1 : -1);

        return _entities;
      }
    } as IRequestParams).then();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  // A field key can join multiple possibilities using '||' separator.
  const split = fieldKey.split('||');

  return (<div className={classNames('field', classes || 'col-12 md:col-6 lg:col-4')}>
    {loading ?
      <Skeleton height={'2.8rem'} /> :
      <Controller
        name={split[0]}
        control={control}
        rules={rules}
        render={({ field, fieldState }) => (
          <>
            <span className={'p-float-label'}>
              <Dropdown
                inputId={field.name + 'Input'} id={field.name} className={'w-full'} placeholder={placeholder || trans(t, 'form|select')} value={field.value}
                options={roles ?? []} optionLabel={'translated'} optionValue={'id'}
                onChange={(e) => onFieldChange(field, fieldState, e.value)} showClear={showClear} filter={filter} editable={editable}
              />
              {(label || field.name) && (
                <label className={'text-ucfirst p-float-label-label'} htmlFor={field.name}>
                  <span>{label ?? trans(t, field.name)}</span>
                </label>
              )}
            </span>
            {errorMessage(field.name)}
          </>
        )}
      />
    }
    </div>
  );
};
