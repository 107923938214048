import { trans } from 'utilities';
import { forEach, omit } from 'lodash';

import appUri from 'config/appUri.json';

export const ModulesMenu = (props: any) => {
  const { t, modules, modulesPermissions } = props;
  const id = props.id ?? '-';
  const url = appUri.dos.page.replace(':id', id) + '/';

  const getAPIMId = (key: string) => {
    if (!modules) return null;
    const check = (modules ?? []).filter((m: any) => m.name === key);

    return check.length > 0 ? check[0].id : null;
  };

  const getPermissions = (apimId: string): any => {
    if (!modulesPermissions) return false;

    const check = (modulesPermissions ?? []).filter((m: any) => m.id === apimId);

    return check.length > 0 ? omit(check[0], 'name', 'id') : {};
  };

  const mm: any[] = [
    {
      key: 'entreprise',
      id: getAPIMId('ENTREPRISE'),
      disabled: id === '-',
      icon: 'building',
      label: trans(t, 'menu|module.entreprise'),
      to: url + 'entreprise',
      color: {
        background: 'pink'
      },
      items: [
        {label: trans(t, 'menu|menu.schema_flux.entreprise_dj'), to: url + 'entreprise/donnees-juridiques', key: 'donnees.juridiques', id: getAPIMId('DONNEES_JURIDIQUES')},
        {label: trans(t, 'menu|menu.schema_flux.entreprise_de'), to: url + 'entreprise/donnees-economiques', key: 'donnees.economiques', id: getAPIMId('DONNEES_ECONOMIQUES')},
        {label: trans(t, 'menu|menu.schema_flux.entreprise_df'), to: url + 'entreprise/donnees-fiscales', key: 'donnees.fiscales', id: getAPIMId('DONNEES_FISCALES')},
        {label: trans(t, 'menu|menu.schema_flux.entreprise_dsd'), to: url + 'entreprise/donnees-sociales-dirigeant', key: 'donnees.sociales.dirigeant', id: getAPIMId('DONNEES_SOCIALES_DU_DIRIGEANT')},
        {label: trans(t, 'menu|menu.schema_flux.entreprise_dsc'), to: url + 'entreprise/donnees-sociales-collectives', key: 'donnees.sociales.collectives', id: getAPIMId('DONNEES_SOCIALES_COLLECTIVES')},
      ]
    },
    {
      key: 'foyer',
      id: getAPIMId('FOYER'),
      disabled: id === '-',
      icon: 'home',
      label: trans(t, 'menu|module.foyer'),
      to: url + 'foyer',
      color: {
        background: 'purple'
      }
    },
    {
      key: 'revenus',
      id: getAPIMId('REVENUS_ET_IMPOSITION'),
      disabled: id === '-',
      icon: 'chart-line',
      label: trans(t, 'menu|module.revenus'),
      to: url + 'revenu-et-imposition',
      color: {
        background: 'orange'
      }
    },
    {
      key: 'tresorerie',
      id: getAPIMId('TRESORERIE'),
      disabled: id === '-',
      icon: 'euro',
      label: trans(t, 'menu|module.tresorerie'),
      to: url + 'tresorerie',
      color: {
        background: 'lime'
      },
      items: [
        {label: trans(t, 'menu|menu.schema_flux.tresorerie_generale'), to: url + 'tresorerie/generale', key: 'tresorerie.generale', id: getAPIMId('TRESORERIE_GENERALE')},
        {label: trans(t, 'menu|menu.schema_flux.tresorerie_emprunt'), to: url + 'tresorerie/emprunt', key: 'tresorerie.emprunt', id: getAPIMId('TRESORERIE_EMPRUNT')},
        {label: trans(t, 'menu|menu.schema_flux.tresorerie_negative'), to: url + 'tresorerie/negative', key: 'tresorerie.negative', id: getAPIMId('TRESORERIE_NEGATIVE')},
      ]
    },
    {
      key: 'reserves',
      id: getAPIMId('RESERVES'),
      disabled: id === '-',
      icon: 'shopping-cart',
      label: trans(t, 'menu|module.reserves'),
      to: url + 'reserves',
      color: {
        background: 'cyan'
      }
    },
    {
      key: 'prevoyance',
      disabled: id === '-',
      icon: 'shield',
      label: trans(t, 'menu|module.prevoyance'),
      to: url + 'prevoyance',
      color: {
        background: 'teal'
      },
      items: [
        {label: trans(t, 'menu|menu.schema_flux.prevoyance_generale'), to: url + 'prevoyance/generale', key: 'prevoyance.generale', id: getAPIMId('PREVOYANCE_GENERALE')},
        // {label: trans(t, 'menu|menu.schema_flux.prevoyance_arret'), to: url + 'prevoyance/arret', key: 'prevoyance.arret', id: getAPIMId('PREVOYANCE_ARRET')},
        {label: trans(t, 'menu|menu.schema_flux.prevoyance_deces'), to: url + 'prevoyance/deces', key: 'prevoyance.deces', id: getAPIMId('PREVOYANCE_DECES')},
        {label: trans(t, 'menu|menu.schema_flux.prevoyance_contrats'), to: url + 'prevoyance/contrats', key: 'prevoyance.contrats', id: getAPIMId('PREVOYANCE_GENERALE')},
      ]
    },
    {
      key: 'patrimoine',
      id: getAPIMId('PATRIMOINE'),
      disabled: id === '-',
      icon: 'key',
      label: trans(t, 'menu|module.patrimoine'),
      to: url + 'patrimoine',
      color: {
        background: 'brown'
      },
      items: [
        {label: trans(t, 'menu|menu.schema_flux.patrimoine_general'), to: url + 'patrimoine/vue-generale', key: 'patrimoine.general', id: getAPIMId('PATRIMOINE_GENERAL')},
        {label: trans(t, 'menu|menu.schema_flux.patrimoine_succession'), to: url + 'patrimoine/succession', key: 'patrimoine.succession', id: getAPIMId('PATRIMOINE_SUCCESSION')},
        {label: trans(t, 'menu|menu.schema_flux.patrimoine_biens'), to: url + 'patrimoine/biens', key: 'patrimoine.biens', id: getAPIMId('PATRIMOINE_BIENS')},
      ]
    },
    {
      key: 'retraite',
      id: getAPIMId('RETRAITE'),
      disabled: id === '-',
      icon: 'sun',
      label: trans(t, 'menu|module.retraite'),
      to: url + 'retraite',
      color: {
        background: 'indigo'
      },
      items: [
        {label: trans(t, 'menu|menu.schema_flux.retraite_obligatoire'), to: url + 'retraite/obligatoire', key: 'retraite.obligatoire', id: getAPIMId('RETRAITE_OBLIGATOIRE')},
        {label: trans(t, 'menu|menu.schema_flux.retraite_facultative'), to: url + 'retraite/facultative', key: 'retraite.facultative', id: getAPIMId('RETRAITE_FACULTATIVE')},
      ]
    },
    {
      key: 'ged',
      id: getAPIMId('GED'),
      disabled: id === '-',
      icon: 'briefcase',
      label: trans(t, 'menu|module.ged'),
      to: url + 'ged',
      color: {
        background: 'blue'
      }
    },
    {
      key: 'missions',
      id: getAPIMId('MISSIONS'),
      disabled: id === '-',
      icon: 'chart-bar',
      label: trans(t, 'menu|module.missions'),
      to: url + 'missions',
      color: {
        background: 'yellow'
      },
      items: [
        {label: trans(t, 'menu|menu.schema_flux.missions_request'), to: url + 'missions/nouvelle-demande', key: 'missions.requete', id: getAPIMId('MISSIONS_REQUETE')},
        {label: trans(t, 'menu|menu.schema_flux.missions_history'), to: url + 'missions/historique', key: 'missions.historique', id: getAPIMId('MISSIONS')},
      ]
    }
  ];

  // Early exit if we don't want the "permissions" part.
  if (!modulesPermissions) return mm;

  forEach(mm ?? [], (module: any, index: number) => {
    mm[index].permissions = getPermissions(module.id);
    forEach(module.items ?? [], (subModule: any, i: number) => {
      mm[index].items[i].permissions = getPermissions(subModule.id);
    });
  });

  return mm;
};

export const getModule = (modulesMenu: any[], key: string): any => {
  let module: any = null;
  forEach(modulesMenu ?? [], (m: any) => {
    if (m.key === key) module = m;

    forEach(m.items ?? [], (mi: any) => {
      if (mi.key === key) module = mi;
    });
  });

  return module;
};

export const getModuleId = (modulesMenu: any[], key: string) => {
  let moduleId = '';
  forEach(modulesMenu ?? [], (m: any) => {
    if (m.key === key) moduleId = m.id;
    forEach(m.items ?? [], (mi: any) => {
      if (mi.key === key) moduleId = mi.id;
    });
  });

  return moduleId;
};

export const checkAccess = (modulesMenu: any[], id: string, permission: string = 'read'): boolean => {
  let _access: boolean = false;
  forEach(modulesMenu ?? [], (m: any) => {
    if (m.id === id) _access = (m.permissions[permission] === true);
    forEach(m.items ?? [], (mi: any) => {
      if (mi.id === id) _access = (mi.permissions[permission] === true);
    });
  });

  return _access;
};

export const checkAccessByKey = (modulesMenu: any[], key: string = 'entreprise', permission: string = 'read'): boolean => {
  let _access: boolean = false;
  forEach(modulesMenu ?? [], (m: any) => {
    if (m.key === key) _access = (m.permissions[permission] === true);
    forEach(m.items ?? [], (mi: any) => {
      if (mi.key === key) _access = (mi.permissions[permission] === true);
    });
  });

  return _access;
};
