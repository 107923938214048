import React from 'react';
import { trans } from 'utilities';
import { TextField  } from 'forms';

export const Shortcuts = (props: any) => {
  const { t, control, onFieldChange, errorMessage } = props;

  return <div className={'pt-4'}>
    <TextField fieldKey={'name'} control={control} onFieldChange={onFieldChange} errorMessage={errorMessage} classes={'pb-3 w-full'}
               rules={{ required: trans(t, 'form|requiredField') }} label={trans(t, 'short.name')} />

    <TextField fieldKey={'uri'} control={control} onFieldChange={onFieldChange} errorMessage={errorMessage} classes={'w-full'}
               disabled label={trans(t, 'short.uri')} />
  </div>

}
