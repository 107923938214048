import React from 'react';

import { DocumentsList } from 'pages';
import { isClient } from 'services';

import { trimStart } from 'lodash';
import appUri from 'config/appUri.json';

export const RoutesGED = () => {
  return isClient() ? [] : [
    {
      path: trimStart(appUri.ged),
      element: <DocumentsList/>
    }
  ];
};
