import React, { useEffect } from 'react';
import { trans, triggerFormSubmit, ucfirst } from 'utilities';

import { Fieldset } from 'primereact/fieldset';

import { NumberField } from 'forms/index';
import { useFormState } from 'states';
import { IRequestParams, useApim } from'services';

export const RemunerationDirigeantForm = (props: any) => {
  const { t, control, onFieldChange, errorMessage, context, setValue, formKey, parentKey, getValues } = props;
  const formState = useFormState();
  const apim = useApim();

  useEffect(() => {
    if (context?.missionYearTotal) {
      formState.setFieldData(formKey, 'remunerationTotaleActuelle', parentKey, context.missionYearTotal)
      setValue('remunerationTotaleActuelle', context.missionYearTotal);
      triggerFormSubmit('a8-remuneration_dirigeant-form');
    }
  }, [context?.missionYearTotal]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (context?.nextYearTotal)  {
      formState.setFieldData(formKey, 'remunerationTotaleOptimisee', parentKey, context.nextYearTotal)
      setValue('remunerationTotaleOptimisee', context.nextYearTotal);
      triggerFormSubmit('a8-remuneration_dirigeant-form');
    }
  }, [context?.nextYearTotal]); // eslint-disable-line react-hooks/exhaustive-deps

const saveValue = (fieldKey: string) => {
  formState.setFieldData(formKey, fieldKey, parentKey, getValues()[fieldKey])
  setValue(fieldKey, getValues()[fieldKey]);
  apim.patchEntity({
    resourceType: 'mission_remuneration_dirigeant',
    id: context?.missionCategory,
    notif: false,
    data: {
      [fieldKey]: getValues()[fieldKey],
      missionHypothesis: context?.missionHypothesis
    }
  } as IRequestParams).then();
}

  return (
    <div className={'flex flex-column col-12'}>
      <Fieldset legend={`${ucfirst(trans(t, 'initialSituation'))} (${context.missionYear})`}>
        <div className={'flex flex-column md:flex-row flex-wrap'}>
          <NumberField classes={'flex flex-grow-1 py-4 px-3'} fieldKey={'remunerationTotaleActuelle'} mode={'currency'} disabled
                       addon={'euro'} addonPosition={'after'} control={control} onFieldChange={onFieldChange} maxFractionDigits={0}
                       errorMessage={errorMessage} label={trans(t, 'form|mission_remuneration.remunerationTotaleActuelle')}
                       value={context?.missionYearTotal} />
          <NumberField classes={'flex flex-grow-1 py-4 px-3'} fieldKey={'cotisationsSocialesActuelles'} mode={'currency'}
                       addon={'euro'} addonPosition={'after'} control={control} onFieldChange={onFieldChange} maxFractionDigits={0}
                       errorMessage={errorMessage} label={trans(t, 'form|mission_remuneration.cotisationsSocialesActuelles')}
                       rules={{ required: trans(t, 'form|requiredField') }} onBlur={() => saveValue('cotisationsSocialesActuelles')}/>
          <NumberField classes={'flex flex-grow-1 py-4 px-3'} fieldKey={'impotsSurSocietesActuels'} mode={'currency'}
                       addon={'euro'} addonPosition={'after'} control={control} onFieldChange={onFieldChange} maxFractionDigits={0}
                       errorMessage={errorMessage} label={trans(t, 'form|mission_remuneration.impotsSurSocietesActuels')}
                       rules={{ required: trans(t, 'form|requiredField') }} onBlur={() => saveValue('impotsSurSocietesActuels')}/>
          <NumberField classes={'flex flex-grow-1 py-4 px-3'} fieldKey={'impotsSurRevenusActuels'} mode={'currency'}
                       addon={'euro'} addonPosition={'after'} control={control} onFieldChange={onFieldChange} maxFractionDigits={0}
                       errorMessage={errorMessage} label={trans(t, 'form|mission_remuneration.impotsSurRevenusActuels')}
                       rules={{ required: trans(t, 'form|requiredField') }} onBlur={() => saveValue('impotsSurRevenusActuels')}/>
        </div>
      </Fieldset>

      <Fieldset legend={`${ucfirst(trans(t, 'optimizedSituation'))} (${parseInt(context.missionYear) + 1})`}>
        <div className={'flex flex-column md:flex-row flex-wrap'}>
          <NumberField classes={'flex flex-grow-1 py-4 px-3'} fieldKey={'remunerationTotaleOptimisee'} mode={'currency'} disabled
                       addon={'euro'} addonPosition={'after'} control={control} onFieldChange={onFieldChange} maxFractionDigits={0}
                       errorMessage={errorMessage} label={trans(t, 'form|mission_remuneration.remunerationTotaleOptimisee')}
                       value={context?.nextYearTotal} />
          <NumberField classes={'flex flex-grow-1 py-4 px-3'} fieldKey={'cotisationsSocialesOptimisees'} mode={'currency'}
                       addon={'euro'} addonPosition={'after'} control={control} onFieldChange={onFieldChange} maxFractionDigits={0}
                       errorMessage={errorMessage} label={trans(t, 'form|mission_remuneration.cotisationsSocialesOptimisees')}
                       rules={{ required: trans(t, 'form|requiredField') }} onBlur={() => saveValue('cotisationsSocialesOptimisees')}/>
          <NumberField classes={'flex flex-grow-1 py-4 px-3'} fieldKey={'impotsSurSocietesOptimises'} mode={'currency'}
                       addon={'euro'} addonPosition={'after'} control={control} onFieldChange={onFieldChange} maxFractionDigits={0}
                       errorMessage={errorMessage} label={trans(t, 'form|mission_remuneration.impotsSurSocietesOptimises')}
                       rules={{ required: trans(t, 'form|requiredField') }} onBlur={() => saveValue('impotsSurSocietesOptimises')}/>
          <NumberField classes={'flex flex-grow-1 py-4 px-3'} fieldKey={'impotsSurRevenusOptimises'} mode={'currency'}
                       addon={'euro'} addonPosition={'after'} control={control} onFieldChange={onFieldChange} maxFractionDigits={0}
                       errorMessage={errorMessage} label={trans(t, 'form|mission_remuneration.impotsSurRevenusOptimises')}
                       rules={{ required: trans(t, 'form|requiredField') }} onBlur={() => saveValue('impotsSurRevenusOptimises')}/>
        </div>
      </Fieldset>

    </div>
  )
};
