import { isString } from 'lodash';

export const isValidUUID = (id: string | undefined | null) => {
  if (id === null || id === undefined || id === '') return false;
  if (!isString(id)) return false;

  // Do not validate IRIs as valid UUID.
  if (id.includes('/')) return false;

  return !(!id || !id.match(/[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}/));
}

export const isAGE = (event: any) => event?.type === 'ag_extraordinaire';
