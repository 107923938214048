import React from 'react';
import { trans, ucfirst} from 'utilities';
import { useApim } from 'services';

import { classNames } from 'primereact/utils';
import { Password } from 'primereact/password';
import { Divider } from 'primereact/divider';

import { BaseTextField } from './BaseTextField';
import { trim } from 'lodash';

export const PasswordField = (props: any) => {
  const { fieldKey, control, onFieldChange, errorMessage, rules, label, tooltip, tooltipOptions,
    classes, addon, addonPosition, format, disabled, description, setError, clearErrors, textAddon } = props;
  const apim = useApim();
  const { t } = apim.di();
  const header = <div className={'font-bold mb-3'}>{ucfirst(trans(t, 'pwd.pick'))}</div>;
  const footer = (
    <>
      <Divider />
      <p>Suggestions</p>
      <ul className={'pl-2 ml-2 mt-0 line-height-3'}>
        <li>{ucfirst(trans(t, 'pwd.lowercase'))}</li>
        <li>{ucfirst(trans(t, 'pwd.uppercase'))}</li>
        <li>{ucfirst(trans(t, 'pwd.numeric'))}</li>
        <li>{ucfirst(trans(t, 'pwd.characters'))}</li>
      </ul>
    </>
  );

  const handlePasswordFieldChange = (field: any, fieldState: any, value: string) => {
    const matches = trim(value).match(/(?=.*[A-Z])(?=.*[0-9])(?=.*[a-z]).{8}/);
    if (!matches) setError(fieldKey, {message: trans(t, 'pwd.tooWeakPassword')});
    else clearErrors(fieldKey);

    onFieldChange(field, fieldState, value, format || 'default');
  };

  const renderInput = (field: any, fieldState: any) =>
    <span className={'p-float-label'}>
      <Password id={field.name} className={classNames('text-ucfirst w-full', {'p-invalid': fieldState?.error?.message})}
                value={field.value || ''} disabled={disabled} aria-invalid={fieldState.error}
                onChange={(e) => handlePasswordFieldChange(field, fieldState, e.target.value)}
                tooltip={tooltip} tooltipOptions={tooltipOptions || {position: 'top'}} toggleMask header={header} footer={footer}/>
      {(label || field.name) && (
        <label className={'text-ucfirst p-float-label-label'} htmlFor={field.name}>
          <span>{label ?? trans(t, field.name)}</span>
        </label>
      )}
    </span>
  ;

  return <BaseTextField fieldKey={fieldKey} control={control} errorMessage={errorMessage} rules={rules} classes={classes}
                        addon={addon} addonPosition={addonPosition} textAddon={textAddon} renderInput={renderInput} description={description} />
};
