import React, { useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { Button } from 'primereact/button';
import { Toast } from 'primereact/toast';

import { login, useApim } from 'services';
import { trans } from 'utilities';
import { useUserState } from 'states';

import appUri from 'config/appUri.json';

export const Login = () => {
  const toast = useRef<any>();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const userState = useUserState();
  const apim = useApim();

  // Early Dependency injection !
  useEffect(() => {
    if (!apim.navigate()) {
      apim.setToast(toast, 'error');
      apim.setNavigate(() => navigate);
      apim.setT(() => t);
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    userState.checkAuth(() => navigate(appUri.home), userState);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div className={'pages-body login-page flex flex-column lg:flex-row'}>
      <Toast content={''} ref={toast} position={'bottom-center'} />
      <div className={'a8-tbg flex justify-content-center align-items-center lg:hidden'}>
        <img id={'app-logo'} src={'/assets/images/logos/logo.svg'} alt={'Atome 8 logo clair'} style={{ height: '4vh' }} />
      </div>
      <div className={'a8-mbg flex flex-row align-items-center'}>
        <div className={'flex flex-column align-items-center w-full'}>
          <div className={'a8-sub-header mb-5 pb-5'}>
            <span className={'a8-logo'}></span>
            <h1 className={'fw-400 text-primary text-4xl md:text-6xl text-center'}>{trans(t, 'system|app.baseline')}</h1>
          </div>
          <div className={'login-form ml-auto mr-auto'}>
            <div className={'pages-panel card'}>
              <div className={'pages-header px-3 py-1'}>
                <div className={'text-4xl'}>{trans(t, 'system|app.name').toUpperCase()}</div>
              </div>
              <h4 className={'fw-400'}>{trans(t, 'system|welcome')}</h4>
              <div className={'pages-detail mb-6 px-6'}>{trans(t, 'system|welcome.login')}</div>
              <Button className={'login-button mb-3 px-3 text-ucfirst'} label={trans(t, 'login')} icon={'pi pi-lock'} onClick={() => login(apim)} />
            </div>
          </div>
        </div>
      </div>
      <div className={'a8-lbg hidden lg:flex'}></div>
    </div>
  );
};
