import React from 'react';
import { trans, ucfirst } from 'utilities';
import { MaskField, StaticListField, TextAreaField, TextField } from 'forms';

export const SocieteClientEvent = (props: any) => {
  const { t, control, onFieldChange, errorMessage, listsOptions } = props;
  
  return <div style={{minWidth: '400px'}}>
    <StaticListField listKey={'societeClientEventTypes'} listsOptions={listsOptions} fieldKey={'type'} control={control}
                     classes={'col-12'} onFieldChange={onFieldChange} errorMessage={errorMessage} label={trans(t, 'ent.agType')}/>

    <MaskField fieldKey={'date'} control={control} onFieldChange={onFieldChange} classes={'col-6'} errorMessage={errorMessage} isDate
               rules={{ required: trans(t, 'form|requiredField') }} label={ucfirst(trans(t, 'ent.date'))}/>

    <TextField fieldKey={'label'} control={control} onFieldChange={onFieldChange} errorMessage={errorMessage}
               classes={'col-12'} label={trans(t, 'form|label')} rules={{ required: trans(t, 'form|requiredField') }}/>

    <TextAreaField fieldKey={'description'} control={control} onFieldChange={onFieldChange} errorMessage={errorMessage}
                   classes={'col-12'} label={trans(t, 'form|description')}/>
  </div>;
};
