import React from 'react';
import { Button } from 'primereact/button';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { trans } from 'utilities';

import appUri from 'config/appUri.json';

export const Maintenance = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const goDashboard = () => navigate(appUri.home);

  return (
    <div className={'pages-body accessdenied-page flex flex-column'}>
      <div className={'align-self-center mt-auto mb-auto'}>
        <div className={'pages-panel card flex flex-column'}>
          <div className={'pages-header px-3 py-1'}>
            <h2>{trans(t, 'system|error.pages.maintenance.title')}</h2>
          </div>
          <img src={'/assets/layout/images/pages/maintenance.png'} alt={'error'} className={'mt-3 px-6'} />
          <div className={'pages-detail'}><h4>{trans(t, 'system|error.pages.maintenance.intro')}</h4></div>
          <div className={'pages-detail pb-4'}>{trans(t, 'system|error.pages.maintenance.message')}</div>
          <Button onClick={goDashboard} type={'button'} label={trans(t, 'retry')} className={'p-button-text text-ucfirst'} icon={'pi pi-refresh'} />
        </div>
      </div>
    </div>
  )
};
