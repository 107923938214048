import React from 'react';
import { ProgressSpinner } from 'primereact/progressspinner';

export const PageLoader = () => {
  return (
    <div className={'flex flex-column flex-1'}>
      <div className={'align-self-center mt-8'}>
        <ProgressSpinner />
      </div>
    </div>
  );
};
