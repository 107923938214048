import React from 'react';
import { trans } from 'utilities';
import { MaskField, TextField } from 'forms';

export const Step1 = (props: any) => {
  const { formKey, t, control, onFieldChange, errorMessage } = props;
  const fields: any[] = [
    {key: 'lastName', addon: 'user', rules: { required: trans(t, 'form|requiredField') }},
    {key: 'firstName', rules: { required: trans(t, 'form|requiredField') }},
  ];

  return <>
    {fields.map((field: any, index: number) => (
      <TextField key={index} fieldKey={field.key} control={control} onFieldChange={onFieldChange} addon={field.addon}
                 errorMessage={errorMessage} label={trans(t, field.tradKey || field.key)} rules={field.rules} />
    ))}

    <MaskField fieldKey={'birthDate'} formKey={formKey} control={control} onFieldChange={onFieldChange}
               errorMessage={errorMessage} label={trans(t, 'birthDate')} />

  </>
};
