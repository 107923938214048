import React, { useCallback, useEffect, useState } from 'react';
import { TFunction } from 'i18next';
import { AxiosResponse } from 'axios';
import { TabMenu } from 'primereact/tabmenu';

import { iri, isValidUUID, trans, ucfirst } from 'utilities';
import { Error, NotFound } from 'pages';
import { PageLoader } from 'components';
import { useAppState } from 'states';
import { IRequestParams, isClient } from 'services';
import { Patrimoine, PatrimoineFoyer } from './tabs';
import { PatrimoineHeader } from './PatrimoineHeader';

import { includes, forEach, replace } from 'lodash';
import appUri from 'config/appUri.json';

export const PatrimoineFormRouter = (props: any) => {
  const { apim, dossier, tab } = props;
  const { t, navigate } = apim.di();
  const appState = useAppState();

  const [loading, setLoading] = useState<boolean>(false);
  const [data, setData] = useState<any>(null);
  const [notFound, setNotFound] = useState<boolean>(false);
  const [errored, setErrored] = useState<boolean>(false);
  const [patrimoineLoading, setPatrimoineLoading] = useState<boolean>(false);

  const [activeIndex, setActiveIndex] = useState<number>(0);
  const tabs: any[] = [];
  forEach(appUri.dos.edit.patrimoine.tabs, (ta: any) => {
    tabs.push({label: trans(t, 'menu|tab.patrimoine.' + ta), command: () => navigate(replace(appUri.dos.edit.patrimoine.uri, ':id', dossier?.id || '_') + '/' + ta)});
  });

  // Validate URL & build tabs.
  const checks = useCallback(() => {
    return isUrlValid(t, dossier?.id, tab);
  }, [dossier?.id, tab]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (!checks()) setNotFound(true);

    let ai = 0;
    forEach(appUri.dos.edit.patrimoine.tabs, ((ta: string, index: number) => {
      if (tab === ta) ai = index;
    }));

    if (ai !== activeIndex) setActiveIndex(ai);
  }, [dossier?.id, tab]); // eslint-disable-line react-hooks/exhaustive-deps

  const buildTabContent = useCallback(() => {
    switch (activeIndex) {
      case 1:
        return <PatrimoineFoyer apim={apim} data={data} dossier={dossier}/>;
      default:
        return <Patrimoine apim={apim} data={data} dossier={dossier}/>;
    }
  }, [dossier?.id, tab, activeIndex, data?.id]); // eslint-disable-line react-hooks/exhaustive-deps

  const resourceType: string = 'patrimoines';
  useEffect(() => {
    setLoading(true);

    apim.fetchEntities({
      resourceType,
      params: [
        {label: 'dossier', value: dossier?.id},
        {label: 'expand[]', value: 'dossier:read_minimal'},
        {label: 'expand[]', value: 'dossier:read_personne_physique'},
      ],
      cache: false,
      setErrored,
      success: (res: AxiosResponse) => {
        if (!res?.data || !res?.data['hydra:member'] || res?.data['hydra:member'].length === 0) {
          // Let's create data for this dossier.
          apim.postEntity({
            resourceType,
            data: {dossier: iri('dossiers', dossier?.id), date: new Date().toISOString()},
            notif: false,
            setErrored,
            setLoading,
            success: (resP: AxiosResponse) => {
              setData(resP?.data);
              if (!isValidUUID(appState.patrimoine()) && isValidUUID(resP?.data?.id)) appState.setPatrimoines(resP?.data?.id);
              appState.setPatrimoines([resP?.data]);
            }
          } as IRequestParams).then();
        } else {
          appState.setPatrimoines(res?.data['hydra:member']);
          if (!isValidUUID(appState.patrimoine())) appState.setPatrimoine(res.data['hydra:member'][0].id);

          apim.fetchEntity({
            resourceType,
            id: appState.patrimoine() ?? res.data['hydra:member'][0].id,
            cache: false,
            setErrored,
            success: (res: AxiosResponse) => {
              if (isValidUUID(res?.data?.id) && !res?.data?.date) {
                apim.patchEntity({
                  resourceType,
                  id: res?.data?.id,
                  data: {date: new Date().toISOString()},
                  notif: false,
                  setErrored,
                  setLoading,
                  success: () => {
                    setLoading(false);
                    setData(res?.data);
                  }
                } as IRequestParams).then();
              } else {
                setLoading(false);
                setData(res?.data);
              }
            }
          } as IRequestParams).then();
        }
      }
    } as IRequestParams).then();
  }, [dossier?.id, tab, appState.patrimoine()]); // eslint-disable-line react-hooks/exhaustive-deps

  // Update active index & Page Header.
  useEffect(() => {
    appState.setBreadcrumb([
      isClient()
       ? { label: trans(t, 'dossier', 2) }
       : { label: trans(t, 'dossier', 2), to: appUri.dos.list },
      { label: dossier?.title, to: appUri.dos.page.replace(':id', dossier?.id) },
      { label: trans(t, 'editing'), to: appUri.dos.edit.landing.replace(':id', dossier?.id) },
      { label: ucfirst(trans(t, 'menu|module.patrimoine')), to: appUri.dos.edit.patrimoine.uri.replace(':id', dossier?.id) },
      { label: ucfirst(trans(t, 'menu|tab.' + tab)) }
    ]);
  }, [dossier?.id, tab]); // eslint-disable-line react-hooks/exhaustive-deps

  if (notFound) return <NotFound asBlock/>;
  if (errored) return <Error asBlock/>;
  if (loading) return <PageLoader/>;

  return (
    <div className={'card height-100 fadein animation-duration-500 flex flex-column'}>
      <div className={'a8-page-header flex flex-wrap'}>
        <PatrimoineHeader apim={apim} appState={appState} editMode={true} patrimoineLoading={patrimoineLoading} setPatrimoineLoading={setPatrimoineLoading}/>

        <div className={'flex-auto flex p-2 justify-content-end'}>
          {tabs.length > 1 && <TabMenu model={tabs} activeIndex={activeIndex} onTabChange={(e) => setActiveIndex(e.index)}/>}
        </div>
      </div>

      {buildTabContent()}
    </div>
  );
};

const isUrlValid = (t: TFunction, id: string | undefined, tab: string | undefined) => {
  // Ensure valid entity UUID & existing tab.
  return isValidUUID(id) && includes([...[undefined], ...appUri.dos.edit.patrimoine.tabs], tab);
};
