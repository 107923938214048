import React from 'react';
import { hookstate, useHookstate, State } from '@hookstate/core';
import { subscribe } from 'services';

interface INotificationState {
  hasNew: boolean;
  count: number;
  countUnread: number;
}

const emptyState: INotificationState = {
  hasNew: false,
  count: 0,
  countUnread: 0,
} as unknown as INotificationState;

const state: State<INotificationState> = hookstate(Object.assign({}, emptyState));

const iGetCount = (s: State<INotificationState>): number => Math.max(0, s.count.get() ?? 0)
const iGetCountUnread = (s: State<INotificationState>): number => Math.max(0, s.countUnread.get() ?? 0)

const inc = (s: State<INotificationState>) => s.count.set((iGetCount(s) || 0) + 1);
const incU = (s: State<INotificationState>) => s.countUnread.set((iGetCountUnread(s) || 0) + 1);
const dec = (s: State<INotificationState>) => s.count.set((s.count.get() || 0) - 1);
const decU = (s: State<INotificationState>) => s.countUnread.set((s.countUnread.get() || 0) - 1);

const wrapper = (s: State<INotificationState>) => ({
  getCount: () => iGetCount(s),
  setCount: (newCount: number) => s.count.set(newCount),
  incCount: () => inc(s),
  decCount: () => dec(s),
  getCountUnread: () => iGetCountUnread(s),
  setCountUnread: (newCountUnread: number) => s.countUnread.set(newCountUnread),
  incCountUnread: () => incU(s),
  decCountUnread: () => decU(s),
  subscribe: (userState: any, themeState: any) => {
    const userTopic = '/users/' + userState.id();
    subscribe([userTopic], (m: any) => {
      if (!m.data) return;

      const data = JSON.parse(m.data);
      if (data['@type'] !== 'Notification') return;

      if (data.done !== true) inc(s);
      if (data.read !== true) incU(s);

      if (!data.content) return;

      // Display as a Toast message.
      const toast = themeState.toast()

      if (toast && toast.current) {
        toast.current.show({
          severity: 'info',
          summary: data.title ?? '',
          content: () => <div dangerouslySetInnerHTML={{__html: data.content ?? ''}}/>,
          life: 4000
        });
      }
    })
  },
  state: () => s,
});

export const useNotificationState = () => wrapper(useHookstate(state));
