import React from 'react';

import { AccountLinkCallback, Error, Login, LoginCallback, Maintenance, NotFound } from 'pages';
import appUri from 'config/appUri.json';
import { trimStart } from 'lodash';

export const RoutesSystem = () => {
  return [
    {
      path: trimStart(appUri.sys.error),
      element: <Error asBlock={false} />
    },
    {
      path: trimStart(appUri.sys.notFound),
      element: <NotFound asBlock={false} />
    },
    {
      path: trimStart(appUri.sys.maintenance),
      element: <Maintenance />
    },
    {
      path: trimStart(appUri.sys.loginCallback),
      element: <LoginCallback />
    },
    {
      path: trimStart(appUri.sys.login),
      element: <Login />
    },
    {
      path: trimStart(appUri.sys.fid.msgraph),
      element: <AccountLinkCallback type={'msgraph'} />
    },
    {
      path: trimStart(appUri.sys.fid.google),
      element: <AccountLinkCallback type={'google'} />
    },
  ];
};
