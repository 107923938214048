import React from 'react';
import { Fieldset } from 'primereact/fieldset';

import { trans, ucfirst } from 'utilities';
import { CheckboxField, NumberField } from 'forms';

export const PrevoyanceForm = (props: any) => {
  const { t, control, onFieldChange, errorMessage } = props;

  return <div className={'grid px-3'}>
    <Fieldset legend={ucfirst(trans(t, 'form|prevoyance.general'))} toggleable className={'w-full'}>
      <div className={'grid'}>
        <div className={'col-2'}>
          <NumberField classes={'w-full'} fieldKey={'indiceCouverture'} mode={'decimal'} min={0} max={10} showButtons minFractionDigits={0}
                       control={control} onFieldChange={onFieldChange} maxFractionDigits={0}
                       errorMessage={errorMessage} label={trans(t, 'form|prevoyance.indiceCouverture')}/>
        </div>

        <div className={'col-2'}>
          <NumberField classes={'w-full'} fieldKey={'indiceProtection'} mode={'decimal'} min={0} max={10} showButtons minFractionDigits={0}
                       control={control} onFieldChange={onFieldChange} maxFractionDigits={0}
                       errorMessage={errorMessage} label={trans(t, 'form|prevoyance.indiceProtection')}/>
        </div>

        <div className={'col-12'}>
          <CheckboxField classes={'w-full'} fieldKey={'souscriptionATMP'} control={control} onFieldChange={onFieldChange} errorMessage={errorMessage} label={trans(t, 'form|prevoyance.souscriptionATMP')}/>
        </div>
      </div>
    </Fieldset>

    <Fieldset legend={ucfirst(trans(t, 'form|prevoyance.besoin'))} toggleable className={'w-full my-3'}>
      <Fieldset legend={ucfirst(trans(t, 'form|prevoyance.besoinPerso'))} className={'w-full'}>
        <div className={'grid'}>
          <div className={'col-3'}>
            <NumberField classes={'w-full'} fieldKey={'besoinPersoArretTemporaire'} mode={'currency'}
                         control={control} onFieldChange={onFieldChange} maxFractionDigits={0} showButtons
                         errorMessage={errorMessage} label={trans(t, 'form|prevoyance.besoinPersoArretTemporaire')} suffix={' ' + trans(t, 'daily')}/>
          </div>

          <div className={'col-3'}>
            <NumberField classes={'w-full'} fieldKey={'besoinPersoArretPermanent'} mode={'currency'}
                         addon={'euro'} addonPosition={'after'} control={control} onFieldChange={onFieldChange} maxFractionDigits={0}
                         errorMessage={errorMessage} label={trans(t, 'form|prevoyance.besoinPersoArretPermanent')} suffix={' ' + trans(t, 'yearly')} />
          </div>

          <div className={'col-3'}>
            <NumberField classes={'w-full'} fieldKey={'besoinPersoDeces'} mode={'currency'}
                         addon={'euro'} addonPosition={'after'} control={control} onFieldChange={onFieldChange} maxFractionDigits={0}
                         errorMessage={errorMessage} label={trans(t, 'form|prevoyance.besoinPersoDeces')}/>
          </div>
        </div>
      </Fieldset>

      <Fieldset legend={ucfirst(trans(t, 'form|prevoyance.besoinPro'))} className={'w-full my-3'}>
        <div className={'grid'}>
          <div className={'col-3'}>
            <NumberField classes={'w-full'} fieldKey={'besoinProArretTemporaire'} mode={'currency'}
                         control={control} onFieldChange={onFieldChange} maxFractionDigits={0} showButtons
                         errorMessage={errorMessage} label={trans(t, 'form|prevoyance.besoinProArretTemporaire')} suffix={' ' + trans(t, 'daily')} />
          </div>

          <div className={'col-3'}>
            <NumberField classes={'w-full'} fieldKey={'besoinProArretPermanent'} mode={'currency'}
                         addon={'euro'} addonPosition={'after'} control={control} onFieldChange={onFieldChange} maxFractionDigits={0}
                         errorMessage={errorMessage} label={trans(t, 'form|prevoyance.besoinProArretPermanent')} suffix={' ' + trans(t, 'yearly')} />
          </div>

          <div className={'col-3'}>
            <NumberField classes={'w-full'} fieldKey={'besoinProDeces'} mode={'currency'}
                         addon={'euro'} addonPosition={'after'} control={control} onFieldChange={onFieldChange} maxFractionDigits={0}
                         errorMessage={errorMessage} label={trans(t, 'form|prevoyance.besoinProDeces')}/>
          </div>
        </div>
      </Fieldset>
    </Fieldset>

    <Fieldset legend={ucfirst(trans(t, 'form|prevoyance.ressources'))} toggleable className={'w-full my-3'}>
      <div className={'grid'}>
        <div className={'col-3'}>
          <NumberField classes={'w-full'} fieldKey={'chargesCourantes'} mode={'currency'}
                       addon={'euro'} addonPosition={'after'} control={control} onFieldChange={onFieldChange} maxFractionDigits={0}
                       errorMessage={errorMessage} label={trans(t, 'form|prevoyance.chargesCourantes')} suffix={' ' + trans(t, 'yearly')} />
        </div>

        <div className={'col-3'}>
          <NumberField classes={'w-full'} fieldKey={'chargesFixes'} mode={'currency'}
                       addon={'euro'} addonPosition={'after'} control={control} onFieldChange={onFieldChange} maxFractionDigits={0}
                       errorMessage={errorMessage} label={trans(t, 'form|prevoyance.chargesFixes')} suffix={' ' + trans(t, 'yearly')} />
        </div>

        <div className={'col-3'}>
          <NumberField classes={'w-full'} fieldKey={'ressourcesTravailActif'} mode={'currency'}
                       addon={'euro'} addonPosition={'after'} control={control} onFieldChange={onFieldChange} maxFractionDigits={0}
                       errorMessage={errorMessage} label={trans(t, 'form|prevoyance.ressourcesTravailActif')} suffix={' ' + trans(t, 'yearly')} />
        </div>

        <div className={'col-3'}>
          <NumberField classes={'w-full'} fieldKey={'ressourcesCapitalTravailPassif'} mode={'currency'}
                       addon={'euro'} addonPosition={'after'} control={control} onFieldChange={onFieldChange} maxFractionDigits={0}
                       errorMessage={errorMessage} label={trans(t, 'form|prevoyance.ressourcesCapitalTravailPassif')} suffix={' ' + trans(t, 'yearly')} />
        </div>
      </div>
    </Fieldset>
  </div>
;
};
