import React, { useCallback, useEffect, useState } from 'react';
import { AxiosResponse } from 'axios';

import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Checkbox } from 'primereact/checkbox';
import { InputText } from 'primereact/inputtext';

import { trans } from 'utilities';
import { IRequestParams, isClient } from 'services';
import { formatTable, formatXYChart, AMChartXY, getModuleId, IndicatorSimple, PageLoader } from 'components';
import { getIndicator } from 'pages';

import { isEmpty, replace } from 'lodash';
import appUri from 'config/appUri.json';
import appConfig from 'config/appConfig.json';

export const DonneesEconomiques = (props: any) => {
  const { id, appState, apim, modulesMenu, lastUpdate } = props;
  const { t } = apim.di();

  const dossier = appState.dossier();
  const [loading, setLoading] = useState<boolean>(false);
  const [data, setData] = useState<any>(null);

  // Override Page Header (defaults into ModuleWrapper, then EntrepriseRouter).
  useEffect(() => {
    appState.setBreadcrumb([
     isClient()
      ? { label: trans(t, 'dossier', 2) }
      : { label: trans(t, 'dossier', 2), to: appUri.dos.list },
     { label: dossier?.title, to: replace(appUri.dos.page, ':id', id)},
     { label: trans(t, 'menu|menu.schema_flux.entreprise_de') }
    ]);

    appState.setModule(getModuleId(modulesMenu, 'donnees.economiques'));
  }, [id]); // eslint-disable-line react-hooks/exhaustive-deps

  const fetchData = useCallback(async () => {
    if (!appState.exercise()) return null;
    setLoading(true);

    apim.call({
      resourceType: 'dossiers',
      action: 'module',
      id: id + '|' + getModuleId(modulesMenu, 'donnees.economiques'),
      method: 'get',
      params: [{label: 'company', value: appState.company()?.id}, {label: 'year', value: appState.exercise()}],
      cacheMode: isEmpty(lastUpdate) ? 'default' : 'replace',
      setLoading: setLoading,
      success: (res: AxiosResponse) => setData({...res.data, ...{
          chart1: formatXYChart(res.data, 'economicData.chart.historyEbeMargesResultatsNets', t, {method: 'percentage'}),
          table1: formatTable(res.data, 'economicData.table.economicDataHistory', t),
          table2: formatTable(res.data, 'economicData.table.economicDataSecondaryHistory', t)
      }})
    } as IRequestParams);
  }, [appState.company()?.id, appState.exercise(), lastUpdate]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    fetchData().then();
  }, [appState.company()?.id, appState.exercise(), lastUpdate]); // eslint-disable-line react-hooks/exhaustive-deps

  if (loading) return <PageLoader />;

  return (
    <div className={'flex flex-column md:flex-row flex-wrap fadein animation-duration-500'}>
      <div className={'flex xl:w-3 py-4 pl-2 pr-3'}>
        <IndicatorSimple color={'primary'} dark bgImage title={trans(t, 'chart|economicData.chiffreAffaire')} value={getIndicator(data, 'economicData.chiffreAffaire').value} precision={getIndicator(data, 'economicData.chiffreAffaire').options?.precision ?? 1} />
      </div>

      {getIndicator(data, 'economicData.masseSalariale').value !== undefined ? (
        <div className={'flex md:w-3 py-4 px-3'}>
          <IndicatorSimple color={'accent2'} bgImage title={trans(t, 'chart|economicData.masseSalariale')} value={getIndicator(data, 'economicData.masseSalariale').value} percent={getIndicator(data, 'economicData.masseSalariale').badge} precision={getIndicator(data, 'economicData.masseSalariale').options?.precision ?? 1} />
        </div>
      ) : (
        <div className={'flex md:w-3 py-4 px-3'}>
          <IndicatorSimple color={'accent2'} bgImage title={trans(t, 'chart|economicData.margeBrute')} value={getIndicator(data, 'economicData.margeBrute').value} percent={getIndicator(data, 'economicData.margeBrute').badge} precision={getIndicator(data, 'economicData.margeBrute').options?.precision ?? 1} />
        </div>
      )}

      <div className={'flex md:w-3 py-4 px-3'}>
        <IndicatorSimple color={'primary'} bgImage title={trans(t, 'chart|economicData.valeurAjoutee')} value={getIndicator(data, 'economicData.valeurAjoutee').value} percent={getIndicator(data, 'economicData.valeurAjoutee').badge} precision={getIndicator(data, 'economicData.valeurAjoutee').options?.precision ?? 1} />
      </div>

      <div className={'flex md:w-3 py-4 pl-3 pr-2'}>
        <IndicatorSimple color={'accent3'} bgImage title={trans(t, 'chart|economicData.ebe')} value={getIndicator(data, 'economicData.ebe').value} percent={getIndicator(data, 'economicData.ebe').badge} precision={getIndicator(data, 'economicData.ebe').options?.precision ?? 1} />
      </div>

      <div className={'flex flex-column md:flex-row xl:flex-column  md:w-12 xl:w-3'}>
        <div className={'flex py-4 pl-2 pr-3 w-12 md:w-6 xl:w-12'}>
          <IndicatorSimple color={'primary'} bgImage title={trans(t, 'chart|economicData.resultatExploitation')} value={getIndicator(data, 'economicData.resultatExploitation').value} percent={getIndicator(data, 'economicData.resultatExploitation').badge} precision={getIndicator(data, 'economicData.resultatExploitation').options?.precision ?? 1} />
        </div>

        <div className={'flex py-4 px-3 w-12 md:w-6 xl:w-12'}>
          <IndicatorSimple color={'accent'} bgImage title={trans(t, 'chart|economicData.resultatNet')} value={getIndicator(data, 'economicData.resultatNet').value} percent={getIndicator(data, 'economicData.resultatNet').badge} precision={getIndicator(data, 'economicData.resultatNet').options?.precision ?? 1} />
        </div>
      </div>

      <div className={'flex md:w-12 xl:w-9 py-4 px-3'}>
        {window.innerWidth < appConfig.breakpoints.tablet ? (
          <div className={'flex h-30rem flex-row md:flex-column flex-grow-1'}>
            <AMChartXY chartData={data?.chart1} showCursor verticalLegend />
          </div>
        ) : ( <div className={'flex flex-row md:flex-column flex-grow-1'}>
            <AMChartXY chartData={data?.chart1} showCursor />
          </div>
        )}
      </div>

      <div className={'flex md:w-6 xl:w-3 py-4 px-3'}>
        <IndicatorSimple color={'primary'} bgImage title={trans(t, 'chart|economicData.capitauxPropres')} value={getIndicator(data, 'economicData.capitauxPropres').value} precision={getIndicator(data, 'economicData.capitauxPropres').options?.precision ?? 1} />
      </div>

      <div className={'flex md:w-6 xl:w-3 py-4 pl-3 pr-2'}>
        <IndicatorSimple color={'accent'} dark bgImage title={trans(t, 'chart|economicData.compteCourantAssocie')} value={getIndicator(data, 'economicData.compteCourantAssocie').value} precision={getIndicator(data, 'economicData.compteCourantAssocie').options?.precision ?? 1} />
      </div>

      <div className={'grid p-fluid mt-3 w-full'}>
        <div className={'col-12'}>
          <DataTable value={data?.table1?.data} className={'datatable-responsive'} stripedRows={true}>
            {data?.table1?.headers.map((h: string, i: number) => (
              <Column key={i} header={h} field={'column-' + (i + 1)} align={0 === i ? 'left' : 'center'} bodyStyle={0 === i ? {} : {fontWeight: 600}} />
            ))}
          </DataTable>
        </div>
      </div>

      <div className={'p-inputgroup'}>
        <span className={'p-inputgroup-addon p-inputgroup-addon-checkbox'}>
          <Checkbox checked={getIndicator(data, 'economicData.locationGerance').value === true} disabled={true} />
        </span>
        <InputText placeholder={trans(t, 'chart|locationGerance')} disabled className={'a8-fake-dis'} />
      </div>

      <div className={'grid p-fluid mt-3 w-full'}>
        <div className={'col-12'}>
          <DataTable value={data?.table2?.data} className={'datatable-responsive'} stripedRows={true}>
            {(data?.table2?.headers || []).map((h: any, i: number) => (
              <Column key={i} header={h} field={'column-' + (i + 1)} align={0 === i ? 'left' : 'center'} bodyStyle={0 === i ? {} : {fontWeight: 600}} />
            ))}
          </DataTable>
        </div>
      </div>
    </div>
  );
};
