import React, { useEffect, useRef, useState } from 'react';
import { AxiosResponse } from 'axios';
import { CSSTransition } from 'react-transition-group';

import { ModulesMenu } from 'components';
import { IRequestParams, userId } from 'services';
import { isValidUUID } from 'utilities';

export const AppTopbarApps = (props: any) => {
  const { appState, apim, themeState } = props;
  const { t, navigate } = apim.di();
  const topbarRef = useRef(null);

  const [modules, setModules] = useState<any[]>([]);
  useEffect(() => {
    if (!isValidUUID(appState.dossier()?.id)) return;

    apim.call({
      resourceType: 'dossiers',
      action: 'dossierUserModules',
      id: appState.dossier()?.id + '|' + userId(),
      method: 'get',
      success: (resModulesPermissions: AxiosResponse) => setModules(ModulesMenu({
        t,
        id: appState.dossier()?.id,
        modules: appState.modules(),
        modulesPermissions: resModulesPermissions?.data['hydra:member']
      }))
    } as IRequestParams);
  }, [appState.dossier()?.id]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      <button
        className={'layout-topbar-action rounded-circle p-link'}
        onClick={(event) =>
          themeState.onTopbarItemClick({
            originalEvent: event,
            item: 'apps'
          })
        }
      >
        <i className={'pi pi-table fs-large'}></i>
      </button>

      <CSSTransition nodeRef={topbarRef} classNames={'p-toggleable'} timeout={{ enter: 1000, exit: 450 }} in={themeState.activeTopbarItem() === 'apps'} unmountOnExit>
        <div ref={topbarRef} className={'layout-topbar-action-panel shadow-6'}>
          <div className={'grid grid-nogutter'}>
            {modules.filter((_m: any) => !_m?.disabled && _m?.permissions?.read !== false).map((item: any, index: number) => (
              <div key={index} className={'layout-topbar-action-item col-4'}>
                <button
                  onClick={() => {
                    if ((item.items ?? []).length > 0 && item.items[0].to) return navigate(item.items[0].to);
                    if (item.to) return navigate(item.to);
                  }}
                  className={'flex align-items-center flex-column text-color p-link'}>
                  <i className={'pi pi-' + item.icon + ' action ' + item.color.background + '-bgcolor white-color'}></i>
                  <span>{item.label}</span>
                </button>
              </div>
            ))}
          </div>
        </div>
      </CSSTransition>
    </>
  );
};
