import React, { useCallback, useState } from 'react';
import { AxiosResponse } from 'axios';

import { Button } from 'primereact/button';
import { Dropdown } from 'primereact/dropdown';
import { Tooltip } from 'primereact/tooltip';

import { dateString, dialog, iri, isValidUUID, trans, triggerFormReset, triggerFormSubmit, ucfirst } from 'utilities';
import { IRequestParams } from 'services';
import { FormWrapper } from 'forms';
import { suspendPatrimoineData, validatePatrimoineData } from '../patrimoine';

export const PatrimoineHeader = (props: any) => {
  const { apim, appState, editMode, patrimoineLoading, setPatrimoineLoading } = props;
  const { t } = apim.di();
  const resourceType = 'patrimoines';
  const [currentPatrimoineData, setCurrentPatrimoineData] = useState<any>(appState.patrimoines().find((patrimoine: any) => patrimoine.id === appState.patrimoine()));

  const getPatrimoines = useCallback(() => (appState.patrimoines() ?? [])
      .sort((a: any, b: any) => a.date > b.date ? -1 : 1)
      .map((p: any) => ({
        ...p,
        ...{ label: dateString(p.date, 'DD/MM/YYYY') }
      }))
    , [appState.patrimoines()]); // eslint-disable-line react-hooks/exhaustive-deps

  const updatePatrimoines = (newP: any | null = null, removedId: string | null = null) => {
    let _p: any[] = getPatrimoines();
    if (newP) _p.push(newP);
    if (removedId) _p = _p.filter((p: any) => p?.id && (p.id !== removedId));
    _p = _p.sort((a: any, b: any) => a.date > b.date ? -1 : 1);

    appState.setPatrimoines(_p);
    appState.setPatrimoine(_p.length > 0 ? (newP?.id ?? _p[0].id) : null);
  };

  const onSubmit = (submitted: any) => {
    if (!submitted) return;

    const data: any = {
      date: submitted?.date ?? new Date().toUTCString(),
      dossier: iri('dossiers', appState.dossier()?.id)
    }
    if (isValidUUID(submitted?.previous)) {
      data.previous = iri('patrimoines', submitted.previous);
    }

    apim.postEntity({
      resourceType,
      data,
      success: (res: AxiosResponse) => updatePatrimoines(res?.data)
    } as IRequestParams);
  };
  const onPatrimoineChange = (e: any) => {
    setCurrentPatrimoineData(appState.patrimoines().find((patrimoine: any) => patrimoine.id === e.value));
    appState.setPatrimoine(e.value);
  }

  // Wrap form render into a useCallback to avoid multiple FormWrapper recalls du to form fields updates.
  const renderForm = useCallback(() =>
      <FormWrapper classes={'grid'} formKeyPrefix={'add_patrimoine_dialog'} resourceType={resourceType} onSubmit={onSubmit} hideReload cancelLink multiple
                   resetClass={'a8-form-patrimoine-min-reset'} submitClass={'a8-form-patrimoine-min-add'} listsOptions={{ patrimoines: getPatrimoines() }} />
    , [appState.patrimoine()]); // eslint-disable-line react-hooks/exhaustive-deps

  const buildSelect = useCallback(() => (
    <div className={'flex py-2 px-3 justify-content-center mb-4'}>
      <div className={'flex field p-inputgroup'}>
        <span className={'p-inputgroup-addon'}>
          <i className={'pi pi-calendar'}></i>
        </span>

        <span className={'p-float-label'}>
          <Dropdown id={'a8-civilYears-select'} value={appState.patrimoine()} options={getPatrimoines()} placeholder={trans(t, 'form|select')}
                    optionValue={'id'} optionLabel={'label'} onChange={onPatrimoineChange}/>
          <label htmlFor={'a8-civilYear-select'} className={'text-ucfirst'}>
            {trans(t, 'situationAu')}
          </label>
        </span>
      </div>
      <div className={'pl-5'}>
        {currentPatrimoineData && currentPatrimoineData.validated ? (
          <span>
              <Tooltip target={'.a8-dossier-suspend-patrimoine-data'} position={'top'} mouseTrack/>
              <Button label={ucfirst(trans(t, 'form|company.patrimoineData.suspendShort'))}
                      icon={'pi pi-' + (patrimoineLoading ? 'spinner pi-spin' : 'ban')}
                      severity={'warning'} style={{paddingTop: '12px'}}
                      onClick={() => {
                        suspendPatrimoineData(apim, appState.patrimoine(), setPatrimoineLoading).then(() => {
                          setCurrentPatrimoineData((prevData: any) => ({
                            ...prevData,
                            validated: false,
                            suspended: true
                          }));
                        });
                      }}
                      data-pr-tooltip={ucfirst(trans(t, 'form|company.patrimoineData.suspend'))}
                      data-pr-position={'top'} data-pr-at={'top'} data-pr-my={'top'}
                      className={'a8-dossier-suspend-patrimoine-data'}/>
            </span>
        ) : (
          <span>
              <Tooltip target={'.a8-dossier-validate-patrimoine-data'} position={'top'} mouseTrack/>
              <Button label={ucfirst(trans(t, 'form|company.patrimoineData.validateShort'))}
                      icon={'pi pi-' + (patrimoineLoading ? 'spinner pi-spin' : 'check')}
                      severity={'success'} style={{paddingTop: '12px'}}
                      onClick={() => {
                        validatePatrimoineData(apim, appState.patrimoine(), setPatrimoineLoading).then(() => {
                          setCurrentPatrimoineData((prevData: any) => ({
                            ...prevData,
                            validated: true,
                            suspended: false
                          }));
                        });
                      }}
                      data-pr-tooltip={ucfirst(trans(t, 'form|company.patrimoineData.validate'))}
                      data-pr-position={'top'} data-pr-at={'top'} data-pr-my={'top'}
                      className={'a8-dossier-validate-patrimoine-data'}
                      disabled={appState.patrimoine() === null}/>
            </span>
        )}
      </div>

      {editMode && (
        <>
          <span className={'pl-4'}>
            <Button disabled={!isValidUUID(appState?.patrimoine()) || (appState?.patrimoines() ?? []).length < 2} className={'a8-pat-delete'} icon={'pi pi-trash'} rounded outlined
                    severity={'danger'} aria-label={trans(t, 'delete')} onClick={() => dialog(t, {
              message: ucfirst(trans(t, 'system|confirmations.dossier.delete-patrimoine')),
              icon: 'pi pi-trash',
              accept: () => {
                const id = appState.patrimoine();
                apim.deleteEntity({
                  resourceType,
                  id,
                  success: () => updatePatrimoines(null, id)
                } as IRequestParams);
              }
            })}
            />
            <Tooltip target={'.a8-pat-delete'} position={'top'} content={ucfirst(trans(t, 'system|actions.delete-patrimoine'))}/>
          </span>

          <span className={'pl-2'}>
            <Button className={'a8-pat-new'} icon={'pi pi-plus'} rounded outlined aria-label={trans(t, 'add')} onClick={() => dialog(t, {
              header: trans(t, 'system|confirmations.patrimoine.add'),
              icon: 'none',
              message: renderForm(),
              accept: () => triggerFormSubmit('a8-form-patrimoine-min-add'),
              reject: () => triggerFormReset('a8-form-patrimoine-min-reset')
            })}
            />
            <Tooltip target={'.a8-pat-new'} position={'top'} content={ucfirst(trans(t, 'system|actions.add-patrimoine'))}/>
          </span>
        </>
      )}
    </div>
  ), [appState.patrimoine(), patrimoineLoading]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      {getPatrimoines().length > 0 && (
        buildSelect()
      )}
    </>
  );
};
