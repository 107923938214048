import React, { useEffect, useState } from 'react';
import { AxiosResponse } from 'axios';

import { Fieldset } from 'primereact/fieldset';
import { Skeleton } from 'primereact/skeleton';

import { IRequestParams, isClient } from 'services';
import { isValidUUID, trans, ucfirst } from 'utilities';
import { IndicatorShort } from 'components';

import appUri from 'config/appUri.json';
import { replace } from 'lodash';

export const StatutDirigeant = (props: any) => {
  const { appState, apim, id, t } = props;
  const [hypotheses, setHypotheses ]  =  useState<any[]>([]);
  const [validHypothesis, setValidHypothesis ]  = useState<any>(null);
  const [missionStatutDirigeant, setMissionStatutDirigeant ]  = useState<any>(null);
  const dossier = appState.dossier();
  const [loading, setLoading] = useState<boolean>(false);

  // Override Page Header (defaults into ModuleWrapper, then MissionsRouter).
  useEffect(() => {
    appState.setBreadcrumb([
      isClient()
        ? { label: trans(t, 'dossier', 2) }
        : { label: trans(t, 'dossier', 2), to: appUri.dos.list },
      { label: dossier.title, to: replace(appUri.dos.page, ':id', id)},
      { label: trans(t, 'mission', 2), to: replace(appUri.dos.missions.history, ':id', id)},
      { label: trans(t, 'statut_dirigeant') }
    ]);
  }, [id]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    setLoading(true);
    apim.fetchEntity({
      resourceType: 'missions',
      id: appState.mission()?.id,
      params: [
        { label: 'expand[]', value: 'mission_type:read' },
        { label: 'expand[]', value: 'mission:read_hypothesis' },
        { label: 'expand[]', value: 'mission_hypothesis:read' },
        { label: 'expand[]', value: 'mission_hypothesis:read_missions_status_clients' },
        { label: 'expand[]', value: 'mission_status_client:read' },
      ],
      setLoading,
      success: (res: AxiosResponse) => {
        setHypotheses(res?.data?.hypothesis);
        if (hypotheses.length > 0) {
          const chosenHypothesis = hypotheses.find((hypothesis: any) => hypothesis.valid === true);
          setValidHypothesis(chosenHypothesis);

          if (isValidUUID(validHypothesis?.id)) {
            setMissionStatutDirigeant(validHypothesis?.missionsStatusClients[validHypothesis?.missionsStatusClients.length - 1]);
          }
        }
      }
    } as IRequestParams).then();
  }, [hypotheses[0]?.id, validHypothesis?.id, missionStatutDirigeant?.id, appState.mission()?.id]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
    {(loading) ? (
      <>
        <Skeleton width={'100%'} height={'20rem'} className={'mb-5'}/>
      </>
    ) : (
      <>
      <div className={'mx-4 flex flex-wrap flex-row py-5 '}>
        <div className={'flex w-12 md:w-6 pr-3'}>
          <IndicatorShort title={trans(t, 'statutPreconise')} IndicatorSimple value={missionStatutDirigeant?.statutPreconise} method={'list'} methodParams={{ listKey: 'clientStatus' }}/>
        </div>
        <div className={'flex w-12 md:w-6 pl-3'}>
          <IndicatorShort title={trans(t, 'caisseRetraitePrevoyance')} text={missionStatutDirigeant?.caisseRetraitePrevoyance}/>
        </div>
      </div>

      <div className={'mx-4 flex'}>
        <Fieldset className={'w-full'} legend={ucfirst(trans(t, 'comment'))}>
          <div className={'flex flex-column'} dangerouslySetInnerHTML={{ __html: missionStatutDirigeant?.comment }}></div>
        </Fieldset>
      </div>
      </>
      )}
    </>
  )
}
