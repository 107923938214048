import React, { useCallback, useState } from 'react';

import { IRequestParams } from 'services';
import { FormWrapper } from 'forms';
import { iri, isValidUUID, trans } from 'utilities';
import { PageLoader } from 'components';

import appUri from 'config/appUri.json';

export const ExpertProfile = (props: any) => {
  const { data, societeExpert, apim } = props;
  const { navigate, t} = apim.di();

  const [loading, setLoading] = useState<boolean>(false);

  const redirectUri = isValidUUID(data?.id) ?
    appUri.usr.exp.page.replace(':id', data.id) + '?maj=' + Date.now() :
    appUri.usr.exp.list;

  const onCreateUserCallback = (_user: any, created: boolean) => {
    if (!created || !isValidUUID(_user?.id)) return;

    setLoading(true);

    let _expertAddData: any = {
      user: _user.id,
      societeExpert: null,
    };

    if (isValidUUID(societeExpert)) {
      _expertAddData.societeExpert = iri('societesExperts', societeExpert);
    }

    apim.postEntity({
      resourceType: 'societesExperts',
      action: 'expertAdd',
      data: _expertAddData,
      notif: false,
      success: () => {
        setLoading(false);

        if (isValidUUID(societeExpert)) {
          apim.toast('success')?.current?.show({
            severity: 'success',
            summary: trans(t, 'system|success.summary.default'),
            detail: trans(t, 'system|actions.societeExpert.addExpertSuccess'),
            life: 4000
          });
        } else {
          apim.toast('success')?.current?.show({
            severity: 'success',
            summary: trans(t, 'system|success.summary.default'),
            detail: trans(t, 'system|actions.user.addExpertSuccess'),
            life: 4000
          });
        }

        navigate(appUri.usr.exp.page.replace(':id', _user.id) + '?maj=' + Date.now());
      }
    } as IRequestParams).then();
  };

  // Wrap form render into a useCallback to avoid multiple FormWrapper recalls due to form fields updates.
  const renderExpertForm = useCallback(() =>
    <FormWrapper data={data ?? {active: true}} formKeyPrefix={'expert'} resourceType={'users'} cancelLink redirectUri={redirectUri} callback={data?.id ? null : onCreateUserCallback}/>
  , [data?.id]); // eslint-disable-line react-hooks/exhaustive-deps

  if (loading) return <PageLoader/>;

  return (
    <div className={'a8-form expert'}>
      <div className={'col-12'}>
        {renderExpertForm()}
      </div>
    </div>
  );
}
