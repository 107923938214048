import { LicencedOrgChart as OrgChart } from 'components';

export const buildA8LinkTemplate = (t: any): OrgChart.template => {
  const color1: string = '#EFF2FE';
  const color2: string = '#656565';
  const template: OrgChart.template = Object.assign({}, OrgChart.templates.ula);

  template.defs =
    `<style>
        #a8_link_gradient {
            --color-stop-1: #ffffff;
            --color-stop-2: ${color1};
            --opacity-stop: 1;
        }
        .a8_link_name {
            font-size: 16px;
            fill: ${color2};
        }
        .a8_link_title {
            font-size: 14px;
            fill: #757575;
        }
    </style>
    '<linearGradient id="a8_link_gradient" x1="0%" y1="0%" x2="0%" y2="100%">
        <stop offset="0%" stop-color="var(--color-stop-1)" stop-opacity="var(--opacity-stop)" />
        <stop offset="100%" stop-color="var(--color-stop-2)" stop-opacity="var(--opacity-stop)" />
    </linearGradient>`;
  template.size = [300, 80];
  template.field_0 = '<text class="a8_link_name" x="150" y="35" text-anchor="middle">{val}</text>';
  template.field_1 = '<text class="a8_link_title" x="150" y="55" text-anchor="middle">{val}</text>';
  template.node = '<rect x="0" y="0" height="{h}" width="{w}" fill="url(#a8_link_gradient)" stroke-width="1" stroke="#aeaeae"></rect><line x1="0" y1="0" x2="{w}" y2="0" stroke-width="2" stroke="' + color2 + '"></line>';
  template.nodeMenuButton = '';

  return template;
};
