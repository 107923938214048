import React from 'react';
import { trans, ucfirst } from 'utilities';
import { CheckboxField, NumberField } from 'forms';
import { Fieldset } from 'primereact/fieldset';

export const DonneesSocialesDirigeantForm = (props: any) => {
  const { t, control, onFieldChange, errorMessage, getValues } = props;

  const {
    fraisReels = null,
  } = getValues();

  const taxFields: any[] = [
    {key: 'salairesSalarie'},
    {key: 'salairesAssimileSalarie', tooltip: trans(t, 'form|tooltip.dirigeantSocialData.salairesAssimileSalarie')},
    {key: 'pensions'},
    {key: 'rentesViageres'},
    {key: 'remunerationsDirigeants'},
    {key: 'bic'},
    {key: 'bnc'},
    {key: 'ba'},
    {key: 'revenusFonciers'},
    {key: 'revenusMobiliers'},
    {key: 'plusValuesImmo', tooltip: trans(t, 'form|tooltip.dirigeantSocialData.plusValuesImmo')},
  ];

  return <>
    <Fieldset legend={ucfirst(trans(t, 'general'))} className={'w-full mb-3'} toggleable>
      <div className={'grid'}>
        <NumberField key={'tauxMoyenImposition'} fieldKey={'tauxMoyenImposition'} mode={'decimal'} suffix={'%'}
                     control={control} onFieldChange={onFieldChange} showButtons
                     errorMessage={errorMessage} label={trans(t, 'chart|dirigeantSocialData.tauxMoyenImposition')}/>

        <NumberField key={'tauxMarginalImposition'} fieldKey={'tauxMarginalImposition'} mode={'decimal'} suffix={'%'}
                     control={control} onFieldChange={onFieldChange} showButtons
                     errorMessage={errorMessage} label={trans(t, 'chart|dirigeantSocialData.tauxMarginalImposition')}/>
      </div>

      <div className={'grid'}>
        <NumberField key={'cotisationsSocialesObligatoires'} fieldKey={'cotisationsSocialesObligatoires'} mode={'currency'}
                     addon={'euro'} addonPosition={'after'} control={control} onFieldChange={onFieldChange}
                     errorMessage={errorMessage} label={trans(t, 'chart|dirigeantSocialData.cotisationsSocialesObligatoires')}/>

        <NumberField key={'versementsContratsFacultatifs'} fieldKey={'versementsContratsFacultatifs'} mode={'currency'}
                     addon={'euro'} addonPosition={'after'} control={control} onFieldChange={onFieldChange}
                     errorMessage={errorMessage} label={trans(t, 'chart|dirigeantSocialData.versementsContratsFacultatifs')}/>

        <NumberField key={'assietteCotisationsSociales'} fieldKey={'assietteCotisationsSociales'} mode={'currency'}
                     addon={'euro'} addonPosition={'after'} control={control} onFieldChange={onFieldChange}
                     errorMessage={errorMessage} label={trans(t, 'chart|dirigeantSocialData.assietteCotisationsSociales')}/>

        <NumberField key={'montantDisponibleMadelinEtPer'} fieldKey={'montantDisponibleMadelinEtPer'} mode={'currency'}
                     addon={'euro'} addonPosition={'after'} control={control} onFieldChange={onFieldChange}
                     errorMessage={errorMessage} label={trans(t, 'chart|dirigeantSocialData.montantDisponibleMadelinEtPer')}/>

        <NumberField key={'montantUtilisable'} fieldKey={'montantUtilisable'} mode={'currency'}
                     addon={'euro'} addonPosition={'after'} control={control} onFieldChange={onFieldChange}
                     errorMessage={errorMessage} label={trans(t, 'chart|dirigeantSocialData.montantUtilisable')}/>

        <NumberField key={'cotisationsObligatoiresRetraite'} fieldKey={'cotisationsObligatoiresRetraite'} mode={'currency'}
                     addon={'euro'} addonPosition={'after'} control={control} onFieldChange={onFieldChange}
                     errorMessage={errorMessage} label={trans(t, 'chart|dirigeantSocialData.cotisationsObligatoiresRetraite')}/>

        <NumberField key={'prelevementTotalNetReel'} fieldKey={'prelevementTotalNetReel'} mode={'currency'}
                     addon={'euro'} addonPosition={'after'} control={control} onFieldChange={onFieldChange}
                     errorMessage={errorMessage} label={trans(t, 'chart|dirigeantSocialData.prelevementTotalNetReel')}/>

        <NumberField key={'volumeDisponible'} fieldKey={'volumeDisponible'} mode={'currency'} tooltip={trans(t, 'form|tooltip.dirigeantSocialData.volumeDisponible')}
                     addon={'euro'} addonPosition={'after'} control={control} onFieldChange={onFieldChange}
                     errorMessage={errorMessage} label={trans(t, 'chart|dirigeantSocialData.volumeDisponible')}/>
      </div>

      <hr className={'mb-6'} />

      <div className={'grid'}>
        <div className={'col-4'}>
          <NumberField key={'deductionsSurRevenu'} fieldKey={'deductionsSurRevenu'} mode={'currency'} classes={'mb-5'}
                       addon={'euro'} addonPosition={'after'} control={control} onFieldChange={onFieldChange}
                       errorMessage={errorMessage} label={trans(t, 'chart|dirigeantSocialData.deductionsSurRevenu')}/>

          <NumberField key={'reductionsImpot'} fieldKey={'reductionsImpot'} mode={'currency'} classes={'mb-5'}
                       addon={'euro'} addonPosition={'after'} control={control} onFieldChange={onFieldChange}
                       errorMessage={errorMessage} label={trans(t, 'chart|dirigeantSocialData.reductionsImpot')}/>

          <NumberField key={'majorationRevenuFiscalDeReference'} fieldKey={'majorationRevenuFiscalDeReference'} mode={'currency'} classes={'mb-5'}
                       addon={'euro'} addonPosition={'after'} control={control} onFieldChange={onFieldChange}
                       errorMessage={errorMessage} label={trans(t, 'chart|dirigeantSocialData.majorationRevenuFiscalDeReference')}/>

          <NumberField key={'revenuFiscalDeReference'} fieldKey={'revenuFiscalDeReference'} mode={'currency'} tooltip={trans(t, 'form|tooltip.dirigeantSocialData.revenuFiscalDeReference')}
                       addon={'euro'} addonPosition={'after'} control={control} onFieldChange={onFieldChange} classes={'mb-5'}
                       errorMessage={errorMessage} label={trans(t, 'chart|dirigeantSocialData.revenuFiscalDeReference')}/>
        </div>

        <div className={'col-4'}>
          <NumberField key={'deductionsAReintegrer'} fieldKey={'deductionsAReintegrer'} mode={'currency'} classes={'mb-5'}
                       addon={'euro'} addonPosition={'after'} control={control} onFieldChange={onFieldChange}
                       errorMessage={errorMessage} label={trans(t, 'chart|dirigeantSocialData.deductionsAReintegrer')}/>

          <NumberField key={'reductionsAReintegrer'} fieldKey={'reductionsAReintegrer'} mode={'currency'} classes={'mb-5'}
                       addon={'euro'} addonPosition={'after'} control={control} onFieldChange={onFieldChange}
                       errorMessage={errorMessage} label={trans(t, 'chart|dirigeantSocialData.reductionsAReintegrer')}/>

          <NumberField key={'prelevementForfaitaireNonLiberatoire'} fieldKey={'prelevementForfaitaireNonLiberatoire'} mode={'currency'} classes={'mb-5'}
                       addon={'euro'} addonPosition={'after'} control={control} onFieldChange={onFieldChange}
                       errorMessage={errorMessage} label={trans(t, 'chart|dirigeantSocialData.prelevementForfaitaireNonLiberatoire')}/>

          <NumberField key={'creditImpot'} fieldKey={'creditImpot'} mode={'currency'}
                       addon={'euro'} addonPosition={'after'} control={control} onFieldChange={onFieldChange} classes={'mb-5'}
                       errorMessage={errorMessage} label={trans(t, 'chart|dirigeantSocialData.creditImpot')}/>
        </div>

        <div className={'col-4'}>
          <NumberField key={'contributionsSociales'} fieldKey={'contributionsSociales'} mode={'currency'}
                       addon={'euro'} addonPosition={'after'} control={control} onFieldChange={onFieldChange} classes={'mb-5'}
                       errorMessage={errorMessage} label={trans(t, 'chart|dirigeantSocialData.contributionsSociales')}/>

          <NumberField key={'prelevementsForfaitaires'} fieldKey={'prelevementsForfaitaires'} mode={'currency'}
                       addon={'euro'} addonPosition={'after'} control={control} onFieldChange={onFieldChange} classes={'mb-5'}
                       errorMessage={errorMessage} label={trans(t, 'chart|dirigeantSocialData.prelevementsForfaitaires')}/>

          <NumberField key={'contributionExceptionnelle'} fieldKey={'contributionExceptionnelle'} mode={'currency'}
                       addon={'euro'} addonPosition={'after'} control={control} onFieldChange={onFieldChange} classes={'mb-5'}
                       errorMessage={errorMessage} label={trans(t, 'chart|dirigeantSocialData.contributionExceptionnelle')}/>

          <NumberField key={'ir'} fieldKey={'ir'} mode={'currency'} classes={'mb-5'}
                       addon={'euro'} addonPosition={'after'} control={control} onFieldChange={onFieldChange}
                       errorMessage={errorMessage} label={trans(t, 'chart|dirigeantSocialData.ir')}/>
        </div>
      </div>
    </Fieldset>

    <Fieldset legend={ucfirst(trans(t, 'form|title.dirigeantSocialData.taxBaseDistribution'))} className={'w-full mb-3'} toggleable>
      <div className={'grid'}>
        {taxFields.map((field: any, index: number) => (
          <NumberField key={index} fieldKey={field.key} mode={field.mode || 'currency'} disabled={field.disabled ?? false}
                       addon={field.addon || 'euro'} addonPosition={'after'} control={control} onFieldChange={onFieldChange}
                       errorMessage={errorMessage} label={trans(t, 'chart|dirigeantSocialData.' + field.key)} tooltip={field.tooltip}/>
        ))}
      </div>

      <div className={'grid'}>
        <CheckboxField fieldKey={'fraisReels'} control={control} onFieldChange={onFieldChange} errorMessage={errorMessage} label={trans(t, 'chart|dirigeantSocialData.fraisReels')}
                       help={trans(t, 'form|tooltip.dirigeantSocialData.fraisReels')}/>

        {fraisReels && (
          <NumberField key={'montantFraisReels'} fieldKey={'montantFraisReels'} mode={'currency'}
                       addon={'euro'} addonPosition={'after'} control={control} onFieldChange={onFieldChange}
                       errorMessage={errorMessage} label={trans(t, 'chart|dirigeantSocialData.montantFraisReels')}/>
        )}
      </div>
    </Fieldset>
  </>;
};
