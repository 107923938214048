import React, { useCallback, useEffect, useState } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';
import { AxiosResponse } from 'axios';
import { TFunction } from 'i18next';

import { Dropdown } from 'primereact/dropdown';
import { TabMenu } from 'primereact/tabmenu';

import { IRequestParams, isAdmin, isClient, isExpertAdmin, isSuperAdmin, useApim } from 'services';
import { useAppState, useFormState, useUserState } from 'states';
import { PageLoader, DossiersDatatable } from 'components';
import { DocumentsList, Error, NotFound, Unauthorized } from 'pages';
import { addShortcut, dateString, isValidUUID, trans, ucfirst } from 'utilities';
import { SocieteClient } from './SocieteClient';
import { SocieteClientAddForm, SocieteClientDeleteForm } from 'forms';

import { find, forEach, includes, isEmpty, omit, replace } from 'lodash';
import appUri from 'config/appUri.json';


export const SocieteClientRouter = (props: any) => {
  const { action } = props;
  const { id, tab } = useParams();

  const apim = useApim();
  const { t, navigate } = apim.di();
  const appState = useAppState();
  const formState = useFormState();
  const userState = useUserState();

  const [loading, setLoading] = useState<boolean>(false);
  const [data, setData] = useState<any>();
  const [eventId, setEventId] = useState<string>();
  const [notFound, setNotFound] = useState<boolean>(false);
  const [errored, setErrored] = useState<boolean>(false);
  const [unauthorized, setUnauthorized] = useState<boolean>(false);

  // Let's detect if we are in "edit/add/delete" mode
  // then render the appropriate display (edit form or module dashboard).
  const addMode = appUri.reservedKeywords.add === action;
  const deleteMode = appUri.reservedKeywords.delete === action;

  // Handle tabs.
  const [activeIndex, setActiveIndex] = useState<number>(0);
  const baseUri = replace(appUri.cie.cli.page, ':id', id || '_');
  const tabs: any[] = [];
  if (id) {
    tabs.push({label: trans(t, 'menu|tab.general'), command: () => navigate(baseUri)});

    forEach(appUri.cie.cli.tabs, (ta) => {
      if (isClient() && 'dossiers' === ta) return;

      tabs.push({label: trans(t, 'menu|tab.' + ta), command: () => navigate(baseUri + '/' + ta)});
    });
  }

  // Validate URL & build tabs.
  const checks = useCallback(() => {
    return isUrlValid(t, id, tab);
  }, [id, tab]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (!checks()) setNotFound(true);

    if (isClient()) {
      setActiveIndex(tab !== 'documents' ? 0 : 1);
      return ;
    }

    let ai = 0;
    forEach(appUri.cie.cli.tabs, (ta, index) => {
      if (tab === ta) ai = index + 1;
    });

    if (ai !== activeIndex) setActiveIndex(ai);
  }, [id, tab]); // eslint-disable-line react-hooks/exhaustive-deps

  // Let's detect if API calls from now have to be refreshed (cache expiration).
  const [searchParams] = useSearchParams();
  const lastUpdate = searchParams.get('maj');
  const fetchData = useCallback(() => {
    if (!isValidUUID(id)) return;

    setLoading(true);
    apim.fetchEntities({
      resourceType: 'dossierCompanies',
      id,
      cacheMode: isEmpty(lastUpdate) ? 'default' : 'replace',
      setErrored,
      setUnauthorized,
      setNotFound,
      success: (res: AxiosResponse) => {
        if (res?.data) {
          const params: any[] = [
            {label: 'company', value: id},
            {label: 'order[event.date]', value: 'desc'},
            {label: 'itemsPerPage', value: 1},
            {label: 'expand[]', value: 'dossier_company:read_list'},
            {label: 'expand[]', value: 'dossier_company:read_latest_version'}
          ];

          apim.fetchEntities({
            resourceType: 'dossierCompanyData',
            params,
            cacheMode: isEmpty(lastUpdate) ? 'default' : 'replace',
            setLoading,
            setErrored,
            setUnauthorized,
            success: (resData: AxiosResponse) => {
              if (resData?.data && resData?.data['hydra:member'] && resData?.data['hydra:member'].length > 0) {
                setData({
                  ...res.data,
                  ...resData?.data['hydra:member'][0],
                });
                if (res.data.events?.length > 0) setEventId(res.data.events[0].id);
              } else setData(res.data);
            }
          } as IRequestParams).then();
        } else {
          setData(null);
          setEventId(undefined);
          setNotFound(true);
          setLoading(false);
        }
      },
      error: () => {
        setData(null);
        setEventId(undefined);
        setLoading(false);
      }
    } as IRequestParams).then();
  }, [id, lastUpdate]); // eslint-disable-line react-hooks/exhaustive-deps

  // Refresh data according to selected event.
  useEffect(() => {
    if (!isValidUUID(eventId)) return;

    setLoading(true);
    apim.fetchEntities({
      resourceType: 'dossierCompanyData',
      params: [
        {label: 'company', value: id},
        {label: 'event', value: eventId},
        {label: 'itemsPerPage', value: 1},
        {label: 'expand[]', value: 'dossier_company:read_list'},
        {label: 'expand[]', value: 'dossier_company:read_latest_version'}
      ],
      cacheMode: isEmpty(lastUpdate) ? 'default' : 'replace',
      setLoading,
      setErrored,
      setUnauthorized,
      success: (resData: AxiosResponse) => {
        if (resData?.data && resData?.data['hydra:member'] && resData?.data['hydra:member'].length > 0) {
          setData({
            // keeps "deleted" attribute from being passed to a version where it doesn't exist
            ...omit(data, 'deleted'),
            ...omit(resData?.data['hydra:member'][0], 'events'),
          });
        }
      }
    } as IRequestParams).then();
  }, [eventId]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (!data || !id || (data.id !== id) || lastUpdate) {
      fetchData();
    } else setLoading(false);
  }, [id, lastUpdate]); // eslint-disable-line react-hooks/exhaustive-deps

  // Update Page Header.
  useEffect(() => {
    let pageTitle: string = ucfirst(trans(t, 'company')) + ' ' + (data ? data.latestVersion?.raisonSociale : '');
    const newPA: any[] = isClient() ? [] : [
      { label: trans(t, 'short.add'), icon: 'bookmark', command: () => addShortcut(t, formState, appState) },
    ];

    const breadcrumbs: any[] = [
      { label: trans(t, 'company', 2), to: appUri.cie.cli.list },
    ];

    if (!addMode) {
      breadcrumbs.push({ label: data ? data.latestVersion?.raisonSociale : trans(t, 'company') });

      if (!isClient()) {
        newPA.push({ label: trans(t, 'cie.edit'), icon: 'pencil', to: replace(appUri.cie.cli.edit, ':id', id || '_') + '/version' });
      }

      if (isAdmin() || isExpertAdmin()) {
        newPA.push({ label: trans(t, 'cie.add'), icon: 'plus', to: appUri.cie.cli.add, className:'bg-green-500' });
      }

      if (isSuperAdmin()) {
        newPA.push({ label: trans(t, 'cie.delete'), icon: 'trash', to: appUri.cie.cli.delete.replace(':id', id || '_'), className:'bg-red-500' });
      }
    } else {
      pageTitle = ucfirst(trans(t, 'company')) + ' | ' + trans(t, 'adding');
      breadcrumbs.push({ label: trans(t, 'adding') });
    }

    appState.setPageActions(newPA);
    appState.setPageTitle(pageTitle);
    appState.setBreadcrumb(breadcrumbs);
  }, [data, lastUpdate, userState.id()]); // eslint-disable-line react-hooks/exhaustive-deps

  const donneesJuridiquesModule = appState?.modules()?.find(module => module.name === 'DONNEES_JURIDIQUES');

  const buildTabContent = useCallback(() => {
    //by default, filter documents list on module "données juridiques"
    switch (activeIndex) {
      case 1: return isClient() ? <DocumentsList company={data?.company} module={donneesJuridiquesModule?.id}/> :
          <DossiersDatatable tableKey={'company-dossiers'} title={trans(t, 'menu|pages.title.company.dossiers')}
                             params={[{label: 'companies.id', value: data.company.id}]} noFilters apim={apim} context={{company: data}}/>;

      case 2: return isClient() ? <></> : <DocumentsList company={data?.company} module={donneesJuridiquesModule?.id}/>;

      default: return <SocieteClient apim={apim} company={data} event={find(data?.events ?? [], (_e: any) => _e.id === eventId)}/>;
    }
  }, [id, tab, activeIndex, data, donneesJuridiquesModule]); // eslint-disable-line react-hooks/exhaustive-deps

  if (addMode) return <SocieteClientAddForm appState={appState} apim={apim} />
  if (notFound) return <NotFound asBlock/>;
  if (unauthorized) return <Unauthorized asBlock/>;
  if (errored) return <Error asBlock/>;
  if (loading) return <PageLoader/>;
  if (deleteMode) return <SocieteClientDeleteForm appState={appState} apim={apim} company={data} />

  return (
    <div className={'card height-100 fadein animation-duration-500 flex flex-column'}>
      <div className={'a8-page-header flex flex-wrap'}>
        {!(activeIndex > 0) && (
          <div className={'flex-none flex p-2'}>
            <div className={'field p-inputgroup'}>
              <span className={'p-inputgroup-addon'}><i className={'pi pi-calendar'}></i></span>
              <span className={'p-float-label'}>
                <Dropdown id={'a8-event-select'} value={eventId} placeholder={trans(t, 'form|select')} filter
                  options={(data?.events ?? [])
                    .map((_e: any) => ({
                      value: _e.id,
                      date: _e.date,
                      label: dateString(_e.date) + (_e.label ? ' - ' + _e.label : '')
                    }))
                    .sort((a: any, b: any) => a.date > b.date ? -1 : 1)
                  } optionLabel={'label'} onChange={(e) => setEventId(e.value)}/>
            <label htmlFor={'a8-company-select'} className={'text-ucfirst'}>{trans(t, 'dossierPermanent')}</label>
          </span>
            </div>
          </div>
        )}
        <div className={'flex-auto flex p-2 justify-content-end pt-0'}>
          {tabs.length > 1 && <TabMenu className={'pb-2'} model={tabs} activeIndex={activeIndex} onTabChange={(e) => setActiveIndex(e.index)}/>}
        </div>
      </div>
      {buildTabContent()}
    </div>
  );
};

const isUrlValid = (t: TFunction, id: string | undefined, tab: string | undefined) => {
  // Ensure valid entity UUID & existing tab.
  return isValidUUID(id) && includes([...[undefined], ...appUri.cie.cli.tabs], tab);
};
