import React, { useCallback, useEffect, useState } from 'react';
import { AxiosResponse } from 'axios';
import { Button } from 'primereact/button';

import { IRequestParams } from 'services';
import { PageLoader } from 'components';
import { FormWrapper } from 'forms';
import { iri, trans, triggerFormSubmit } from 'utilities';
import { Error, NotFound } from 'pages';

import appUri from 'config/appUri.json';

export const DonneesEconomiques = (props: any) => {
  const { appState, apim, additionalData, urls } = props;
  const { t, navigate } = apim.di();
  const cancelUri: string = urls?.landing + '/entreprise/donnees-economiques?maj=' + Date.now();

  const [loading, setLoading] = useState<boolean>(false);
  const [notFound, setNotFound] = useState<boolean>(false);
  const [errored, setErrored] = useState<boolean>(false);

  const [economicData, setEconomicData] = useState<any>(null);
  const [keyTmp, setKeyTmp] = useState<string>('');

  useEffect(() => {
    setLoading(true);

    apim.fetchEntities({
      resourceType: 'economicData',
      params: [{label: 'company', value: appState.company()?.id}, {label: 'fiscalYear', value: appState.exercise()}],
      cache: false,
      setLoading,
      setErrored,
      setNotFound,
      success: (res: AxiosResponse) => {
        if (res?.data && (res?.data['hydra:member'] || []).length > 0) {
          setEconomicData(res.data['hydra:member'][0]);
          setKeyTmp('');
        } else {
          // Let's create an empty set.
          setLoading(true);
          apim.postEntity({
            resourceType: 'economicData',
            data: {
              company: iri('dossierCompanies', appState.company()?.id),
              fiscalYear: appState.exercise(true)
            },
            setLoading,
            setErrored,
            success: (res: AxiosResponse) => {
              if (!res?.data?.id) {
                setEconomicData(null);
                setKeyTmp('_' + appState.company()?.id + '_' + appState.exercise());
                setNotFound(true);
                return;
              }

              setEconomicData(res.data);
              setKeyTmp('');
            }
          } as IRequestParams).then();
        }
      }
    } as IRequestParams).then();
  }, [appState.company()?.id, appState.exercise()]); // eslint-disable-line react-hooks/exhaustive-deps

  const formatter = (patchedData: any) => (patchedData?.startExonerationZRR) ? {...patchedData, ...{ startExonerationZRR: '01/' + patchedData.startExonerationZRR }} : patchedData;

  // Wrap form render into a useCallback to avoid multiple FormWrapper recalls du to form fields updates.
  const renderForm = useCallback(() =>
      <FormWrapper classes={'grid p-fluid w-12'} formKeyPrefix={'donnees_economiques'} resourceType={'economicData'} keyTmp={keyTmp} keepAlive multiple
                   data={economicData} context={{company: appState.company(), exercice: appState.exercise()}} additionalData={additionalData} hideReload
                   cancelLink redirectUri={urls?.form + appUri.dos.edit.fiscalYear.steps.step2} formatter={formatter} />
  , [appState.company()?.id, appState.exercise(), economicData?.id, keyTmp]); // eslint-disable-line react-hooks/exhaustive-deps

  if (loading) return <PageLoader/>;
  if (notFound) return <NotFound asBlock/>;
  if (errored) return <Error asBlock/>;

  return (
    <div className={'flex flex-column xl:flex-row flex-wrap fadein animation-duration-500'}>
      {renderForm()}

      <div className={'w-full text-right m-2 mr-3 pt-4'}>
        <Button type={'reset'} className={'text-ucfirst p-button-text p-button-danger mr-5'} label={trans(t, 'close')} onClick={() => navigate(cancelUri)}/>
        <Button type={'submit'} className={'text-ucfirst'} label={trans(t, 'save')} icon={'pi pi-arrow-right'} iconPos={'right'} onClick={() => triggerFormSubmit(null)}/>
      </div>
    </div>
  );
};
