import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

import { Fieldset } from 'primereact/fieldset';
import { Skeleton } from 'primereact/skeleton';

import { isValidUUID, trans, ucfirst } from 'utilities';
import { ContactField, IndicatorSimple, SimpleText } from 'components';

import { IRequestParams, isAdmin, isExpertAdmin, useApim } from 'services';

import { forEach } from 'lodash';
import appUri from 'config/appUri.json';

export const Expert = (props: any) => {
  const { t, data } = props;
  const apim = useApim();

  const [userGroupsLoading, setUserGroupsLoading] = useState<boolean>(false);
  const [userGroups, setUserGroups] = useState<any[]>([]);

  const identity = [{ key: 'firstName' }, { key: 'lastName' }];
  const contact = [{ key: 'phone1' }, { key: 'phone2' }, { key: 'email' }];
  const dates = [{ key: 'created' }, { key: 'updated' }];

  // Load the expert cabinets.
  useEffect(() => {
    setUserGroupsLoading(true);

    if (isValidUUID(data?.id)) {
      apim.fetchEntity({
        resourceType: 'userGroups',
        params: [
          { label: 'user', value: data?.id},
          { label: 'group.name', value: 'SOCIETE_EXPERT_'},
          { label: 'role.name[]', value: 'ROLE_EXPERT'},
          { label: 'role.name[]', value: 'ROLE_EXPERT_ADMIN'},
          { label: 'expand[]', value: 'user_group:read_group'},
          { label: 'expand[]', value: 'group:read'},
          { label: 'expand[]', value: 'user_group:read_role'},
          { label: 'expand[]', value: 'role:read_list'}
        ],
        setLoading: setUserGroupsLoading,
        setter: setUserGroups
      } as IRequestParams).then();
    }
  }, [data]); // eslint-disable-line react-hooks/exhaustive-deps

  return <>
    <div className={'flex flex-wrap flex-row'}>
      <div className={'flex py-4 px-3 flex-grow-1'}>
        <IndicatorSimple color={'accent4'} bgImage title={trans(t, 'civility')} value={data?.civility} method={'list'} methodParams={{ listKey: 'civilities' }} />
      </div>
      {identity.map((identity, index) => (
        <div key={index} className={'flex py-4 px-3 flex-grow-1'}>
          <IndicatorSimple color={'accent4'} bgImage title={trans(t, identity.key)} text={data ? data[identity.key] : ''} />
        </div>
      ))}
    </div>

    <Fieldset className={'my-3'} legend={ucfirst(trans(t, 'contact'))}>
      <div className={'flex flex-column md:flex-row px-3'}>
        {contact.map((contact, index) => (
          <div key={index} className={'flex flex-grow-1 py-4 px-3'}>
            <ContactField title={trans(t, contact.key)} text={data ? data[contact.key] : ''} />
          </div>
        ))}
        {(isAdmin() || isExpertAdmin()) && (
          <>
            <div className={'flex px-3 py-5'}>
              <SimpleText title={trans(t, 'em.verified')} value={data?.emailVerified} method={'bool'} />
            </div>

            <div className={'flex px-3 py-5'}>
              <SimpleText title={trans(t, 'supportAccessAgreement')} value={data?.supportAccessAgreement} method={'bool'} />
            </div>

            <div className={'flex px-3 py-5'}>
              <SimpleText title={trans(t, 'active')} value={data?.active} method={'bool'} />
            </div>
          </>
        )}
      </div>
    </Fieldset>

    <Fieldset className={'my-3'} legend={ucfirst(trans(t, 'userInfo'))}>
      <div className={'flex flex-column md:flex-row px-3'}>
        <div className={'flex flex-grow-1 py-4 px-3'}>
          <SimpleText title={trans(t, 'role')} value={data?.roles} method={(roles: []) => {
            let isExpertAdmin = false;

            forEach(roles, (role: any) => {
              if (role === 'ROLE_EXPERT_ADMIN') {
                isExpertAdmin = true;
                return false;
              }
            });

            if (isExpertAdmin) {
              return ucfirst(trans(t, 'system|role.role_expert_admin'));
            } else {
              return ucfirst(trans(t, 'system|role.role_expert'));
            }
          }} />
        </div>
        <div className={'flex flex-grow-1 py-4 px-3'}>
          <SimpleText title={trans(t, 'userName')} text={data?.username} />
        </div>
        <div className={'flex flex-grow-1 py-4 px-3'}>
          <SimpleText title={trans(t, 'lastLogin')} value={data?.lastLogin} method={'datetime'} emptyValue={ucfirst(trans(t, 'never'))} />
        </div>
      </div>
    </Fieldset>

    <Fieldset className={'my-3'} legend={ucfirst(trans(t, 'societeExpert', 2))}>
      {userGroupsLoading ? (
        <Skeleton width={'100%'} height={'4rem'}></Skeleton>
      ) : (
        <ul>
          {userGroups.map(function (userGroup: any) {
            const redirectUri = appUri.cie.exp.page.replace(':id', userGroup.group.context.entity.id || '_');
            return <li key={userGroup.id}><Link to={redirectUri}>{userGroup.group.label ?? userGroup.group.name}</Link> ({ucfirst(trans(t, 'system|role.' + userGroup.role.name.toLowerCase()))})</li>
          })}
        </ul>
      )}
    </Fieldset>

    <div className={'flex flex-row justify-content-end'}>
      {dates.map((dates, index) => (
        data &&
          <div key={index} className={'flex px-3 py-5'}>
            <SimpleText title={trans(t, dates.key + '.at')} value={data[dates.key]} method={'datetime'} />
          </div>
      ))}
    </div>
  </>;
};
