import React, { useState } from 'react';
import { useIdleTimer } from 'react-idle-timer';
import { Dialog } from 'primereact/dialog';

import { trans, ucfirst } from 'utilities';
import { cleanLocalStorage, resetSession } from 'services';

import appUri from 'config/appUri.json';

const timeout = 1800_000;
const promptBeforeIdle = 300_000;

export const IdleManager = (props: any) => {
  const { apim } = props;
  const { t } = apim.di();

  const [open, setOpen] = useState<boolean>(false);

  const onIdle = () => {
    cleanLocalStorage();
    resetSession();
    return window.location.replace(appUri.sys.login);
  }
  const onActive = () => setOpen(false);
  const onPrompt = () => setOpen(true);

  useIdleTimer({
    crossTab : true,
    syncTimers: 200,
    onIdle,
    onActive,
    onPrompt,
    timeout,
    promptBeforeIdle,
    throttle: 500
  });

  return t && (
    <Dialog content={''} header={ucfirst(trans(t, 'idle.title'))} visible={open} /*style={{width: '80vw', height: '100%'}}*/ onHide={() => setOpen(false)}>
      <h3>{trans(t, 'idle.subtitle')}</h3>
      <p>{trans(t, 'idle.description')}</p>
    </Dialog>
  )
};
