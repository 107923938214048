import React, { useEffect, useState } from 'react';

import { Fieldset } from 'primereact/fieldset';
import { AxiosResponse } from 'axios';

import { escapeValue, isValidUUID, trans, ucfirst } from 'utilities';
import { NumberField, CheckboxField, TextField } from 'forms';
import { useAppState, useFormState } from 'states';
import { IRequestParams, useApim } from 'services';

export const DonneesSocialesCollectivesForm = (props: any) => {
  const { t, control, onFieldChange, errorMessage, formKey, parentKey, getValues, setValue } = props;
  const formState = useFormState();
  const appState = useAppState();
  const apim = useApim();

  const {
    company = null,
    fiscalYear = null,
    nbCadres = 0,
    nbNonCadres = 0,
    coutHoraireMoyenNonCadres = 0,
    coutHoraireMoyenCadres = 0,
    nbHeuresPayees = 0,
    nbHeuresSupplementaires = 0,
    stockConges = 0,
    stockRtt = 0,
    masseSalarialeTotaleHorsDirigeant = 0,
    ccnNumero
  } = getValues();

  // Load the economic data to get the chiffre d'affaire.
  const [economicData, setEconomicData] = useState<any>(null);
  useEffect(() => {
    apim.fetchEntities({
      resourceType: 'economicData',
      params: [{label: 'company', value: appState.company()?.id}, {label: 'fiscalYear', value: appState.exercise()}],
      cache: false,
      success: (res: AxiosResponse) => {
        if (res?.data && (res?.data['hydra:member'] || []).length > 0) {
          setEconomicData(res.data['hydra:member'][0]);
        } else {
          setEconomicData(null);
        }
      }
    } as IRequestParams).then();
  }, [appState.company()?.id, appState.exercise()]); // eslint-disable-line react-hooks/exhaustive-deps

  // Load previous year's data
  const [previousYearData, setPreviousYearData] = useState<any>(null);
  useEffect(() => {
    apim.fetchEntities({
      resourceType: 'socialCollectiveData',
      params: [{label: 'company', value: company}, {label: 'fiscalYear', value: fiscalYear - 1}],
      cache: false,
      success: (res: AxiosResponse) => {
        if (res?.data && (res?.data['hydra:member'] || []).length > 0) {
          setPreviousYearData(res.data['hydra:member'][0]);
        } else {
          setPreviousYearData(null);
        }
      }
    } as IRequestParams).then();
  }, [appState.company()?.id, appState.exercise()]); // eslint-disable-line react-hooks/exhaustive-deps

  // Checking if fields are filled before calculating result
  const calculateAverageHourlyCost = () => {
    if (nbCadres + nbNonCadres !== 0) {
      const coutHoraireMoyen = (nbNonCadres * coutHoraireMoyenNonCadres + nbCadres * coutHoraireMoyenCadres) / (nbCadres + nbNonCadres);
      formState.setFieldData(formKey, 'coutHoraireMoyen', parentKey, escapeValue(coutHoraireMoyen, 'number'));
      setValue('coutHoraireMoyen', coutHoraireMoyen);
    }
  };
  const calculateOvertimeRate = () => {
    if (nbHeuresSupplementaires + nbHeuresPayees !== 0) {
      const tauxHeuresSupplementaires = nbHeuresSupplementaires / nbHeuresPayees * 100;
      formState.setFieldData(formKey, 'tauxHeuresSupplementaires', parentKey, escapeValue(tauxHeuresSupplementaires, 'number'));
      setValue('tauxHeuresSupplementaires', tauxHeuresSupplementaires);
    }
  };

  const calculateMasseSalarialeSurCa = () => {
    if (masseSalarialeTotaleHorsDirigeant !== 0
      && economicData !== undefined && economicData !== null
      && economicData.chiffreAffaires !== 0) {
      const masseSalarialeTotaleHorsDirigeantSurCa = masseSalarialeTotaleHorsDirigeant / economicData.chiffreAffaires * 100;
      formState.setFieldData(formKey, 'masseSalarialeTotaleSurCa', parentKey, escapeValue(masseSalarialeTotaleHorsDirigeantSurCa, 'number'));
      setValue('masseSalarialeTotaleSurCa', masseSalarialeTotaleHorsDirigeantSurCa);
    }
  };

  // Triggering calculations everytime related values are updated
  useEffect(() => {
    calculateAverageHourlyCost();
  }, [nbCadres, nbNonCadres, coutHoraireMoyenNonCadres, coutHoraireMoyenCadres]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    calculateOvertimeRate();
  }, [nbHeuresPayees, nbHeuresSupplementaires]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    calculateMasseSalarialeSurCa();
  }, [masseSalarialeTotaleHorsDirigeant]); // eslint-disable-line react-hooks/exhaustive-deps

  // Triggering default value when last year's data is loaded
  useEffect(() => {
    if (!isValidUUID(previousYearData?.id)) {
      return;
    }

    const defaultFields = [
      'prevoyanceComplementaire',
      'santeComplementaire',
      'ccnNumero',
      'ccnNom',
    ];

    defaultFields.map((defaultField: string) => {
      const previousYearFieldValue = previousYearData[defaultField];
      const currentFieldValue = getValues()[defaultField];

      // Override the value only if it's not set yet.
      if (previousYearFieldValue !== null && previousYearFieldValue !== undefined
        && (currentFieldValue === null || currentFieldValue === undefined || currentFieldValue.length === 0)) {
        formState.setFieldData(formKey, defaultField, parentKey, previousYearData[defaultField]);
        setValue(defaultField, previousYearData[defaultField]);
      }

      return null;
    });
  }, [previousYearData]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (ccnNumero) {
      apim.fetchEntities({
        resourceType: 'collectiveAgreements',
        params: [{ label: 'number', value: ccnNumero }],
        success: (res: AxiosResponse) => {
          if (res.data && res.data['hydra:member'] && res.data['hydra:member'].length > 0) {
            formState.setFieldData(formKey, 'ccnNom', parentKey, escapeValue(res.data['hydra:member'][0]['name'], 'string'));
            setValue('ccnNom', res.data['hydra:member'][0]['name']);
          }
        },
      } as IRequestParams).then();
    }
  }, [ccnNumero]); // eslint-disable-line react-hooks/exhaustive-deps

  return <>
    <Fieldset legend={ucfirst(trans(t, 'masseSalariale'))} className={'w-full mb-3'} toggleable>
      <div className={'grid'}>
        <NumberField key={'masseSalarialeTotaleHorsDirigeant'} fieldKey={'masseSalarialeTotaleHorsDirigeant'} mode={'currency'}
                     addon={'euro'} addonPosition={'after'} control={control} onFieldChange={onFieldChange} maxFractionDigits={0}
                     errorMessage={errorMessage} label={trans(t, 'chart|socialCollectiveData.masseSalarialeTotaleHorsDirigeant')} />

        <NumberField key={'masseSalarialeTotaleSurCa'} fieldKey={'masseSalarialeTotaleSurCa'} mode={'decimal'} disabled={true}
                     addon={'percentage'} addonPosition={'after'} control={control} onFieldChange={onFieldChange} maxFractionDigits={2}
                     errorMessage={errorMessage} label={trans(t, 'chart|socialCollectiveData.masseSalarialeTotaleSurCa')} min={0} suffix={'%'} />
      </div>

      <div className={'grid'}>
        <NumberField key={'nbCadres'} fieldKey={'nbCadres'} mode={'decimal'}
                     control={control} onFieldChange={onFieldChange} showButtons min={0}
                     errorMessage={errorMessage} label={trans(t, 'chart|socialCollectiveData.nbCadres')} suffix={' ' + trans(t, 'person', nbCadres > 0 ? nbCadres : 1)} />

        <NumberField key={'coutHoraireMoyenCadres'} fieldKey={'coutHoraireMoyenCadres'} mode={'currency'}
                     control={control} onFieldChange={onFieldChange} addon={'euro'} addonPosition={'after'}
                     errorMessage={errorMessage} label={trans(t, 'chart|socialCollectiveData.coutHoraireMoyenCadres')} />
      </div>

      <div className={'grid'}>
        <NumberField key={'nbNonCadres'} fieldKey={'nbNonCadres'} mode={'decimal'}
                     control={control} onFieldChange={onFieldChange} showButtons min={0}
                     errorMessage={errorMessage} label={trans(t, 'chart|socialCollectiveData.nbNonCadres')} suffix={' ' + trans(t, 'person', nbNonCadres > 0 ? nbNonCadres : 1)} />

        <NumberField key={'coutHoraireMoyenNonCadres'} fieldKey={'coutHoraireMoyenNonCadres'} mode={'currency'}
                     control={control} onFieldChange={onFieldChange} addon={'euro'} addonPosition={'after'}
                     errorMessage={errorMessage} label={trans(t, 'chart|socialCollectiveData.coutHoraireMoyenNonCadres')} />
      </div>

      <div className={'grid'}>
        <NumberField key={'coutHoraireMoyen'} fieldKey={'coutHoraireMoyen'} mode={'currency'}
                     control={control} onFieldChange={onFieldChange} disabled={true} addon={'euro'} addonPosition={'after'}
                     errorMessage={errorMessage} label={trans(t, 'chart|socialCollectiveData.coutHoraireMoyen')} classes={'md:col-offset-4 md:col-4 col'} />
      </div>
    </Fieldset>

    <Fieldset legend={ucfirst(trans(t, 'heure_supplementaire', 2))} className={'w-full mb-3'} toggleable>
      <div className={'grid'}>
        <NumberField key={'nbHeuresPayees'} fieldKey={'nbHeuresPayees'} mode={'decimal'} showButtons maxFractionDigits={0} minFractionDigits={0}
                     control={control} onFieldChange={onFieldChange} suffix={' ' + trans(t, 'heure', nbHeuresPayees > 0 ? nbHeuresPayees : 1)}
                     errorMessage={errorMessage} label={trans(t, 'chart|socialCollectiveData.nbHeuresPayees')} />

        <NumberField fieldKey={'tauxAbsenteisme'} mode={'decimal'} min={0} max={100} suffix={'%'}
                     addon={'percentage'} addonPosition={'after'} control={control} onFieldChange={onFieldChange}
                     errorMessage={errorMessage} label={trans(t, 'chart|socialCollectiveData.tauxAbsenteisme')} />
      </div>

      <div className={'grid'}>
        <NumberField key={'nbHeuresSupplementaires'} fieldKey={'nbHeuresSupplementaires'} mode={'decimal'} showButtons maxFractionDigits={0} minFractionDigits={0}
                     control={control} onFieldChange={onFieldChange} suffix={' ' + trans(t, 'heure', nbHeuresSupplementaires > 0 ? nbHeuresSupplementaires : 1)}
                     errorMessage={errorMessage} label={trans(t, 'chart|socialCollectiveData.nbHeuresSupplementaires')} />

        <NumberField fieldKey={'tauxHeuresSupplementaires'} mode={'decimal'} min={0} max={100} suffix={'%'}
                     addon={'percentage'} addonPosition={'after'} control={control} onFieldChange={onFieldChange} disabled={true}
                     errorMessage={errorMessage} label={trans(t, 'chart|socialCollectiveData.tauxHeuresSupplementaires')} />
      </div>

      <div className={'grid'}>
        <NumberField fieldKey={'stockConges'} mode={'decimal'} min={0} showButtons maxFractionDigits={0} minFractionDigits={0}
                     control={control} onFieldChange={onFieldChange} suffix={' ' + trans(t, 'day', stockConges > 0 ? stockConges : 1)}
                     errorMessage={errorMessage} label={trans(t, 'chart|socialCollectiveData.stockConges')} />

        <NumberField fieldKey={'stockRtt'} mode={'decimal'} min={0} showButtons maxFractionDigits={0} minFractionDigits={0}
                     control={control} onFieldChange={onFieldChange} suffix={' ' + trans(t, 'day', stockRtt > 0 ? stockRtt : 1)}
                     errorMessage={errorMessage} label={trans(t, 'chart|socialCollectiveData.stockRtt')} />
      </div>

      <div className={'grid'}>
        <CheckboxField fieldKey={'actesSpecifiques'}
                       control={control} onFieldChange={onFieldChange} errorMessage={errorMessage}
                       label={trans(t, 'chart|socialCollectiveData.actesSpecifiques')} />
      </div>
    </Fieldset>

    <Fieldset legend={ucfirst(trans(t, 'complementaire', 2))} className={'w-full mb-3'} toggleable>
        <TextField key={'prevoyanceComplementaire'} fieldKey={'prevoyanceComplementaire'} control={control} onFieldChange={onFieldChange}
                   errorMessage={errorMessage} label={trans(t, 'chart|socialCollectiveData.prevoyanceComplementaire')} />

        <TextField key={'santeComplementaire'} fieldKey={'santeComplementaire'} control={control} onFieldChange={onFieldChange}
                   errorMessage={errorMessage} label={trans(t, 'chart|socialCollectiveData.santeComplementaire')} />
    </Fieldset>

    <Fieldset legend={ucfirst(trans(t, 'convention_collective'))} className={'w-full'} toggleable>
      <TextField key={'ccnNumero'}  fieldKey={'ccnNumero'} control={control} onFieldChange={onFieldChange} tooltip={trans(t, 'form|tooltip.donneesSocialesCollectives.numeroCCN')}
                  errorMessage={errorMessage} label={trans(t, 'numero_convention_collective')} />

      <TextField key={'ccnNom'} classes={ 'col-12 md:col-8'} fieldKey={'ccnNom'} control={control} onFieldChange={onFieldChange} tooltip={trans(t, 'form|tooltip.donneesSocialesCollectives.nomCCN')}
                  errorMessage={errorMessage} label={trans(t, 'nom_convention_collective')} />
    </Fieldset>
  </>;
};
