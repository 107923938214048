import React, { useEffect } from 'react';
import { Button } from 'primereact/button';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { trans, ucfirst } from 'utilities';
import { useAppState } from 'states';

import appUri from 'config/appUri.json';

export const NotFound = (props: any) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const appState = useAppState();

  // Update Page Header.
  useEffect(() => {
    appState.setPageTitle(trans(t, 'menu|pages.title.notFound'));
    appState.setBreadcrumb([{ label: trans(t, 'system|error.pages.default.title') }]);
    appState.setPageActions([]);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const goDashboard = () => navigate(appUri.home);

  const block = (
    <div className={'align-self-center mt-auto mb-auto'}>
      <div className={'pages-panel card flex flex-column'}>
        <div className={'pages-header px-3 py-1 text-center'}>
          <h2>{trans(t, 'system|error.pages.404.title')}</h2>
        </div>

        <img src={'/assets/layout/images/pages/404.png'} alt={'error'} className={'my-5 px-6'} />

        <div className={'pages-detail'}>
          <h4>{trans(t, 'system|error.pages.404.intro')}</h4>
        </div>

        <div className={'pages-detail pb-4'}>
          {trans(t, 'system|error.pages.404.message')}
        </div>

        <Button onClick={goDashboard} type={'button'} size={"large"} label={ucfirst(trans(t, props.asBlock && false !== props.asBlock ? 'system|backToHome' : 'system|backToApp'))} />
      </div>
    </div>
  );

  return props.asBlock && false !== props.asBlock ? (
    <div className={'flex flex-column'}>{block}</div>
  ) : (
    <div className={'pages-body notfound-page flex flex-column'}>{block}</div>
  );
};
