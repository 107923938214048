export const getQueryParam = (name: string, defaultValue: string | null = null, existing: string | null = null) => {
  const params = existing ?? getQueryParams();

  for (let i = 0; i < params.length; i++) {
    if (params[i].label === name) {
      return decodeURIComponent(params[i].value);
    }
  }

  if ('' === defaultValue) return defaultValue;

  return defaultValue ? decodeURIComponent(defaultValue) : null;
};

export const getQueryParams = (asString = false): any => {
  const query = window.location.search.substring(1);
  const stringParams = query.split('&');
  const params = [];

  for (let i = 0; i < stringParams.length; i++) {
    const pair = stringParams[i].split('=');

    if ('' !== pair[0] && undefined !== pair[1])
      params.push({ label: pair[0], value: pair[1] });
  }

  if (!asString) return params;

  let newStringParams = '';
  params.map((param) => {
    return (newStringParams += '&' + param.label + '=' + param.value);
  });

  return newStringParams.substring(1);
};

export const mergeQueryParams = (
  name: string,
  value: string,
  removeOthers = false,
  replace = true,
  string = true
) => {
  const params = getQueryParams();
  const newParams = [];
  let found = false;

  params.map((param: any) => {
    if (param.label === name) {
      found = true;
      newParams.push(
        replace
          ? { label: name, value: value }
          : { label: name, value: param.value + ',' + value }
      );
    } else {
      if (!removeOthers)
        newParams.push({ label: param.label, value: param.value });
    }

    return true;
  });

  if (!found) {
    newParams.push({ label: name, value: value });
  }

  if (!string) return newParams;

  let stringParams = '';
  newParams.map((param) => {
    return (stringParams += '&' + param.label + '=' + param.value);
  });

  return stringParams.substring(1);
};

export const removeQueryParams = (name: string, string = true) => {
  const params = getQueryParams();
  const newParams = params.filter((p: any) => p.label !== name);

  if (!string) return newParams;

  let stringParams = '';
  newParams.map((param: any) => {
    return (stringParams += '&' + param.label + '=' + param.value);
  });

  return newParams.length > 0 ? '?' + stringParams.substring(1) : '';
};

export const isParamSelected = (target: any, treasureOfTheDay = false) => {
  if (treasureOfTheDay) return isTreasureOfTheDaySelected();

  const queryParams = getQueryParams();
  let check = false;

  if (0 === queryParams.length) return check;

  queryParams.forEach((param: any) => {
    if (
      //param.label === target['@resourceType'] &&
      param.value === target['@uuid']
    ) {
      check = true;
    } else {
      if (
        param.label === 'themes' &&
        //target['@resourceType'] === 'themes' &&
        (target.children ?? []).length > 0
      ) {
        target.children.forEach((subtheme: any) => {
          if (param.value === subtheme['@uuid']) {
            check = true;
          }
        });
      }
    }
  });

  return check;
};

const isTreasureOfTheDaySelected = () => {
  const queryParams = getQueryParams();
  let check = false;

  if (0 === queryParams.length) return check;

  queryParams.forEach((param: any) => {
    if (
      param.label === 'treasureOfTheDay[after]' &&
      param.value > '1900-00-00'
    ) {
      check = true;
    }
  });

  return check;
};

export const mergeParams = (a: any, b: any, replace = true) => {
  const merged: any[] = [];

  if (replace) {
    a.map((param: any) => {
      if (b.filter((p: any) => p.label === param.label).length === 0)
        merged.push(param);

      return true;
    });

    return [...merged, ...b];
  }

  b.map((param: any) => {
    if (a.filter((p: any) => p.label === param.label).length === 0) a.push(param);

    return true;
  });

  return a;
};

export const getParam = (params: any, label: string) => {
  const matches = params.filter((p: any) => label === p.label);

  return matches.length > 0 ? matches[0].value ?? null : null;
};
