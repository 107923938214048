import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { AxiosResponse } from 'axios';

import { Badge } from 'primereact/badge';
import { Divider } from 'primereact/divider';

import { IRequestParams, isClient, useApim } from 'services';
import { useAppState } from 'states';
import { iri, isValidUUID, trans, ucfirst } from 'utilities';
import { Error, NotFound, Unauthorized } from 'pages';
import { PageLoader, PatrimoineDonationsDatatable } from 'components';
import { PatrimoineHeader } from './PatrimoineHeader';

import appUri from 'config/appUri.json';

export const DonationsLanding = () => {
  const { id } = useParams();
  const apim = useApim();
  const { t } = apim.di();
  const appState = useAppState();
  const dossier: any = appState.dossier();

  const [context, setContext] = useState<any>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [notFound, setNotFound] = useState<boolean>(false);
  const [errored, setErrored] = useState<boolean>(false);

  const resourceType: string = 'patrimoines';
  useEffect(() => {
    setLoading(true);

    apim.fetchEntity({
      resourceType: 'dossiers',
      id,
      setErrored,
      setNotFound,
      success: (resDossier: AxiosResponse) => {
        if (!isValidUUID(resDossier?.data?.id)) return setLoading(false);
        if (!isValidUUID(appState.dossier()?.id)) appState.setDossier(resDossier?.data);

        // Ensure "patrimoine" entity.
        apim.fetchEntities({
          resourceType,
          params: [{label: 'dossier', value: id}],
          cache: false,
          setErrored,
          success: (res: AxiosResponse) => {
            if (!res?.data || !res?.data['hydra:member'] || res?.data['hydra:member'].length === 0) {
              // Let's create data for this dossier.
              apim.postEntity({
                resourceType,
                data: {dossier: iri('dossiers', dossier?.id)},
                notif: false,
                setErrored,
                setLoading,
                success: (resP: AxiosResponse) => {
                  setContext(resP?.data);
                  if (!isValidUUID(appState.patrimoine()) && isValidUUID(resP?.data?.id)) appState.setPatrimoines(resP?.data?.id);
                  appState.setPatrimoines([resP?.data]);
                }
              } as IRequestParams).then();
            } else {
              appState.setPatrimoines(res?.data['hydra:member']);
              if (!isValidUUID(appState.patrimoine())) appState.setPatrimoine(res.data['hydra:member'][0].id);

              apim.fetchEntity({
                resourceType,
                id: appState.patrimoine() ?? res.data['hydra:member'][0].id,
                cache: false,
                setErrored,
                setLoading,
                setter: setContext
              } as IRequestParams).then();
            }
          }
        } as IRequestParams).then();
      },
    } as IRequestParams).then();
  }, [id, appState.patrimoine()]); // eslint-disable-line react-hooks/exhaustive-deps

  // Update active index & Page Header.
  useEffect(() => {
    appState.setBreadcrumb([
      isClient()
        ? { label: trans(t, 'dossier', 2) }
        : { label: trans(t, 'dossier', 2), to: appUri.dos.list },
      { label: dossier?.title, to: appUri.dos.page.replace(':id', dossier?.id) },
      { label: trans(t, 'editing'), to: appUri.dos.edit.landing.replace(':id', dossier?.id) },
      { label: ucfirst(trans(t, 'menu|module.patrimoine')), to: appUri.dos.edit.patrimoine.uri.replace(':id', dossier?.id) },
      { label: ucfirst(trans(t, 'menu|tab.donations')) }
    ]);
  }, [dossier?.id]); // eslint-disable-line react-hooks/exhaustive-deps

  if (isClient()) return <Unauthorized asBlock/>;
  if (notFound) return <NotFound asBlock/>;
  if (errored) return <Error asBlock/>;
  if (!dossier?.id || !context?.id || loading) return <PageLoader/>;

  return (
    <div className={'card height-100 fadein animation-duration-500 flex flex-column'}>
      <div className={'a8-page-header flex flex-wrap'}>
        <PatrimoineHeader apim={apim} appState={appState}/>
      </div>

      <div className={'grid'}>
        <Divider align={'center'} type={'dashed'}><Badge severity={'info'} value={trans(t, 'donation', 2)}/></Divider>

        <div className={'col-12'}>
          <PatrimoineDonationsDatatable apim={apim} editMode appState={appState} context={context}/>
        </div>
      </div>
    </div>
  );
};
