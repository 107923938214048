import React, { useEffect } from 'react';
import { escapeValue, trans } from 'utilities';
import { APIListField, MaskField, NumberField, TextField } from 'forms';
import { useFormState } from 'states';

export const ImmatriculationSocieteExperts = (props: any) => {
  const { t, control, onFieldChange, errorMessage, formKey, parentKey, getValues, setValue } = props;
  const formState = useFormState();
  const { siren, siret } = getValues();

  // Sync. siret & siren.
  useEffect(() => {
    if (siret && !siren) {
      if (siret.split('_').length === 1) {
        const _siren: string = escapeValue(siret, 'removeSpaces').substring(0, 9);
        formState.setFieldData(formKey, 'siren', parentKey, _siren);
        setValue('siren', _siren);
      }
    } else {
      if (!siret && siren) {
        if (siren.split('_').length === 1) {
          const _siret: string = escapeValue(siren, 'removeSpaces') + '00000';
          formState.setFieldData(formKey, 'siret', parentKey, _siret);
          setValue('siret', _siret);
        }
      }
    }
  }, [siren, siret]); // eslint-disable-line react-hooks/exhaustive-deps

  return <>
    <TextField fieldKey={'raisonSociale'} control={control} onFieldChange={onFieldChange} errorMessage={errorMessage}
               rules={{ required: trans(t, 'form|requiredField') }}
               label={trans(t, 'ent.raisonSociale')} addon={'building'} />

    <TextField fieldKey={'nomCommercial'} control={control} onFieldChange={onFieldChange} errorMessage={errorMessage}
               label={trans(t, 'ent.nomCommercial')} addon={'shopping-bag'} />

    <MaskField fieldKey={'siren'} control={control} onFieldChange={onFieldChange} errorMessage={errorMessage}
               rules={{ required: trans(t, 'form|requiredField') }} mask={'999 999 999'}
               label={trans(t, 'ent.siren').toUpperCase()} />

    <MaskField fieldKey={'siret'} control={control} onFieldChange={onFieldChange} errorMessage={errorMessage}
               mask={'999 999 999 99999'} label={trans(t, 'ent.siret').toUpperCase()} />

    <APIListField listKey={'societeTypes'} fieldKey={'type||societeTypes'} control={control} onFieldChange={onFieldChange} errorMessage={errorMessage}
                  label={trans(t, 'ent.formeJuridique')} />

    <NumberField fieldKey={'capital'} addon={'euro'} addonPosition={'after'} control={control} onFieldChange={onFieldChange} errorMessage={errorMessage}
                 label={trans(t, 'ent.capital')} />

    <TextField fieldKey={'villeRCS'} control={control} onFieldChange={onFieldChange} errorMessage={errorMessage} label={trans(t, 'ent.rcsCity')} />
  </>;
};
