import React from 'react';
import { Link } from 'react-router-dom';

import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { RadioButton } from 'primereact/radiobutton';

import { trans } from 'utilities';
import { Personne } from 'interfaces';
import { birthDateBody } from 'components';

import appUri from 'config/appUri.json';

export const MergePersonnesPhysiquesForm = (props: any) => {
  const { apim, persons, setMain, main } = props;
  const { t } = apim.di();

  const dossierBodyTemplate = (rowData: any) => {
    const dossier = rowData.dossier;
    if (dossier !== undefined && dossier !== null) {
      return <Link className={'block mt-1 text-sm'} to={appUri.dos.page.replace(':id', dossier.id)}>{dossier.name}</Link>;
    }

    return null;
  };

  const renderRadioButton = (personne: Personne) => (
    <RadioButton
      inputId={`main-person-${personne.id}`}
      name={'main'}
      value={personne.id}
      checked={personne.id === main}
      onChange={() => setMain(personne.id)}
    />
  );

  return (
    <div className={'p-fluid'}>
      <DataTable value={persons}>
        <Column field={'firstName'} header={trans(t, 'firstName')} style={{ width: '200px' }}/>
        <Column field={'lastName'} header={trans(t, 'lastName')} style={{ width: '200px' }}/>
        <Column field={'birthDate'} header={trans(t, 'birthDate')} style={{ width: '100px' }} align={'center'} body={birthDateBody}/>
        <Column field={'dossier.title'} header={trans(t, 'dossier')} sortable style={{ width: '200px' }} body={dossierBodyTemplate}/>
        <Column field={'email'} header={trans(t, 'email')} sortable style={{ width: '300px' }}/>
        <Column header={trans(t, 'mainPerson')} body={renderRadioButton} style={{ width: '150px' }} align={'center'} alignHeader={'center'}/>
      </DataTable>
    </div>
  );
};
