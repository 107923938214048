import React, { useEffect, useState } from 'react';

import { trans, ucfirst } from 'utilities';
import { MaskField, StaticListField, UncontrolledCheckboxField } from 'forms';
import { useFormState } from 'states';

export const AddPatrimoineForm = (props: any) => {
  const { t, control, onFieldChange, errorMessage, setValue, formKeyPrefix, listsOptions } = props;
  const formState = useFormState();
  const [displayPrevious, setDisplayPrevious] = useState<boolean>(false);

  // Cancel "previous" selection if user unchecks.
  useEffect(() => {
    if (displayPrevious) return;

    formState.setFieldData(formKeyPrefix, 'previous', null, null);
    setValue('previous', null);
  }, [displayPrevious]); // eslint-disable-line react-hooks/exhaustive-deps

  return <div className={'grid pt-3 pr-3'} style={{width: '400px'}}>
    <MaskField fieldKey={'date'} control={control} onFieldChange={onFieldChange} errorMessage={errorMessage} label={trans(t, 'date')} classes={'col-12'} isDate/>

    <UncontrolledCheckboxField classes={'col-12'} fieldKey={'displayPrevious'} value={displayPrevious}
                               onFieldChange={(value: any) => setDisplayPrevious(value)} label={ucfirst(trans(t, 'form|createNew.useTemplate'))}/>

    {displayPrevious && (
      <div className={'pl-2 pt-4 grid w-full'}>
        <StaticListField fieldKey={'previous'} listKey={'patrimoines'} listsOptions={listsOptions} control={control} label={ucfirst(trans(t, 'situationAu'))}
                         optionLabel={'label'} optionValue={'id'} errorMessage={errorMessage} onFieldChange={onFieldChange} classes={'col-12'}/>
      </div>
    )}
  </div>;
};
