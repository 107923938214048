import React from 'react';
import { InputMask } from 'primereact/inputmask';
import { Nullable } from 'primereact/ts-helpers';
import { classNames } from 'primereact/utils';

import { UncontrolledBaseField } from './UncontrolledBaseField';
import { asDate, dateString, trans } from 'utilities';
import { useApim } from 'services';

export const UncontrolledMaskField = (props: any) => {
  const { fieldKey, mask, value, onFieldChange, onBlur, label, tooltip, tooltipOptions, description, classes, addon, addonPosition, disabled, isDate } = props;
  const apim = useApim();
  const { t } = apim.di();

  const regex_pattern: string = '^(?:(?:31(\\/|-|\\.)(?:0?[13578]|1[02]))\\1|(?:(?:29|30)(\\/|-|\\.)(?:0?[13-9]|1[0-2])\\2))(?:(?:1[6-9]|[2-9]\\d)?\\d{2})$|^(?:29(\\/|-|\\.)0?2\\3(?:(?:(?:1[6-9]|[2-9]\\d)?(?:0[48]|[2468][048]|[13579][26])|(?:(?:16|[2468][048]|[3579][26])00))))$|^(?:0?[1-9]|1\\d|2[0-8])(\\/|-|\\.)(?:(?:0?[1-9])|(?:1[0-2]))\\4(?:(?:1[6-9]|[2-9]\\d)?\\d{2})$';
  const isValidInput = (text: Nullable<string>) => text ? text.match(regex_pattern) : false; // check the string with regex
  const isEmptyInput = (text: Nullable<string>) => text !== null && text !== undefined;

  const currentValue = (value && isDate && ['000Z', '00:00'].includes(value.substring(20))) ? dateString(value, mask === '99/9999' ? 'MM/YYYY' : undefined) : value ?? '';

  const getValueInternal = (e: any) => {
    if (!isDate) {
      return e.target.value === '' ? null : e.target.value;
    }

    const matches: false | RegExpMatchArray | null = isValidInput(e.target.value);
    if (matches) {
      const newDate: Date = asDate(matches[0], [mask === '99/9999' ? 'MM/YYYY' : 'DD/MM/YYYY']);
      newDate.setHours(newDate.getHours() + 12);
      return newDate.toISOString();
    }

    return e.target.value === '' ? null : e.target.value;
  }

  const onChangeInternal = (e: any) => {
    if (onFieldChange !== null && onFieldChange !== undefined) {
      onFieldChange(getValueInternal(e));
    }
  }

  const onBlurInternal = (e: any) => {
    if (onBlur !== null && onBlur !== undefined) {
      onBlur(getValueInternal(e));
    }
  }

  return (
    <UncontrolledBaseField classes={classes} addon={addon} addonPosition={addonPosition} description={description}>
      <span className={'p-float-label'}>
        <InputMask id={fieldKey} className={classNames('text-ucfirst w-full', {'p-invalid': isDate && (!isEmptyInput(currentValue ?? null) && !isValidInput(currentValue ?? null))})} value={currentValue ?? ''} disabled={disabled}
                   mask={mask || '99/99/9999'} autoClear={false}
                   onChange={(e) => onChangeInternal(e)}
                   onBlur={(e) => onBlurInternal(e)}
                   tooltip={tooltip} tooltipOptions={tooltipOptions || {position: 'top'}} />
        {(label || fieldKey) && (
          <label className={'text-ucfirst p-float-label-label'} htmlFor={fieldKey}>
            <span>{label ?? trans(t, fieldKey)}</span>
          </label>
        )}
      </span>
    </UncontrolledBaseField>
  )
};
