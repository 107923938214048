import React, { useCallback, useEffect, useState } from 'react';
import { AxiosResponse } from 'axios';

import { isValidUUID, trans } from 'utilities';
import { ModuleWrapper, Unauthorized } from 'pages';
import { useThemeState } from 'states';
import { checkAccessByKey, PageLoader } from 'components';
import { IRequestParams } from 'services';

import { PrevoyanceVueGenerale } from './PrevoyanceVueGenerale';
// import { PrevoyanceArret } from './PrevoyanceArret';
import { PrevoyanceDeces } from './PrevoyanceDeces';
import { PrevoyanceContrats } from './PrevoyanceContrats';
import { PrevoyanceContrat } from './PrevoyanceContrat';

export const PrevoyanceRouter = (props: any) => {
  const { appState, apim, id, module, tab, modulesMenu, lastUpdate, subResource, subResourceId } = props;
  const { t } = apim.di();
  const themeState = useThemeState();

  const [loading, setLoading] = useState<boolean>(false);

  // Override Page Header (defaults into ModuleWrapper).
  useEffect(() => {
    appState.setPageTitle(trans(t, 'menu|pages.title.dossier.prevoyance', 1, appState.dossier()?.title));
  }, [id, module, tab]); // eslint-disable-line react-hooks/exhaustive-deps

  // Get prevoyances collection for this dossier.
  useEffect(() => {
    if (isValidUUID(appState.prevoyance())) return;

    setLoading(true);
    apim.fetchEntities({
      resourceType: 'prevoyances',
      params: [{ label: 'dossier', value: appState?.dossier()?.id}],
      setLoading,
      success: (res: AxiosResponse) => {
        if (!res?.data || !res.data['hydra:member']) return;

        const _p: any[] = (res?.data['hydra:member'] ?? []).sort((a: any, b: any) => a.year > b.year ? -1 : 1);
        if (_p.length < 1) return;

        appState.setPrevoyances(_p);
        appState.setPrevoyance(_p[0].id);
      }
    } as IRequestParams).then();
  }, [appState.prevoyance(), lastUpdate]); // eslint-disable-line react-hooks/exhaustive-deps

  const buildTabContent = useCallback(() => {
    switch (tab) {
      case 'contrats':
        if (!checkAccessByKey(modulesMenu, 'prevoyance.generale', 'read')) return <Unauthorized asBlock/>;

        return ('version' === subResource && isValidUUID(subResourceId)) ?
          <PrevoyanceContrat id={id} appState={appState} apim={apim} version={subResourceId} themeState={themeState}/> :
          <PrevoyanceContrats id={id} appState={appState} apim={apim}/>;
      case 'deces':
        return !checkAccessByKey(modulesMenu, 'prevoyance.deces', 'read') ? <Unauthorized asBlock/> : <PrevoyanceDeces id={id} appState={appState} apim={apim} modulesMenu={modulesMenu} lastUpdate={lastUpdate}/>;
      // case 'arret':
      //   return !checkAccessByKey(modulesMenu, 'prevoyance.arret', 'read') ? <Unauthorized asBlock/> : <PrevoyanceArret id={id} appState={appState} apim={apim} modulesMenu={modulesMenu} lastUpdate={lastUpdate}/>;
      default:
        return !checkAccessByKey(modulesMenu, 'prevoyance.generale', 'read') ? <Unauthorized asBlock/> : <PrevoyanceVueGenerale id={id} appState={appState} apim={apim} modulesMenu={modulesMenu} lastUpdate={lastUpdate} themeState={themeState} module={module} tab={tab} subResource={subResource}/>;
    }
  }, [id, module, tab, subResource, subResourceId]); // eslint-disable-line react-hooks/exhaustive-deps

  if (loading) return <PageLoader/>;

  return <ModuleWrapper appState={appState} apim={apim} modulesMenu={modulesMenu}  buildTabContent={buildTabContent} subResource={subResource} subResourceId={subResourceId}/>;
};
