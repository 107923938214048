import React, { useCallback, useEffect, useState } from 'react';

import { trans } from 'utilities';
import { IRequestParams, isClient } from 'services';
import { getModuleId, IndicatorSimple, PageLoader } from 'components';
import { getIndicator } from 'pages';

import { isEmpty, replace } from 'lodash';
import appUri from 'config/appUri.json';

export const TresorerieNegative = (props: any) => {
  const { id, appState, apim, modulesMenu, lastUpdate } = props;
  const { t } = apim.di();

  const [loading, setLoading] = useState<boolean>(false);
  const [data, setData] = useState<any>(null);

  // Override Page Header (defaults into DossierRouter, then TresorerieRouter).
  useEffect(() => {
    // Update Breadcrumb.
    appState.setBreadcrumb([
      isClient()
       ? { label: trans(t, 'dossier', 2) }
       : { label: trans(t, 'dossier', 2), to: appUri.dos.list },
      { label: appState.dossier()?.title, to: replace(appUri.dos.page, ':id', id!)},
      { label: trans(t, 'menu|menu.schema_flux.tresorerie_negative') }
    ]);

    appState.setModule(getModuleId(modulesMenu, 'tresorerie.negative'));
  }, [id]); // eslint-disable-line react-hooks/exhaustive-deps

  const fetchData = useCallback(async () => {
    if (!appState.exercise()) return null;
    setLoading(true);

    apim.call({
      resourceType: 'dossiers',
      action: 'module',
      id: id + '|' + getModuleId(modulesMenu, 'tresorerie.negative'),
      method: 'get',
      params: [{label: 'company', value: appState.company()?.id}, {label: 'year', value: appState.exercise()}],
      cacheMode: isEmpty(lastUpdate) ? 'default' : 'replace',
      setLoading: setLoading,
      setter: setData
    } as IRequestParams);
  }, [appState.company()?.id, appState.exercise(), lastUpdate]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    fetchData().then();
  }, [appState.company()?.id, appState.exercise(), lastUpdate]); // eslint-disable-line react-hooks/exhaustive-deps

  if (loading) return <PageLoader />;

  return (
    <div className={'flex flex-colum md:flex-row flex-wrap fadein animation-duration-500'}>
      <div className={'flex w-12 md:w-4 py-4 pl-2 pr-3'}>
        <IndicatorSimple color={'accent6'} dark bgImage title={trans(t, 'chart|tresorerie.compteBancaireNegatif')} value={getIndicator(data, 'tresorerie.compteBancaireNegatif').value} />
      </div>

      <div className={'flex w-12 md:w-4 py-4 px-3'}>
        <IndicatorSimple color={'accent6'} bgImage title={trans(t, 'chart|tresorerie.moyenneCbSurExercice')} value={getIndicator(data, 'tresorerie.moyenneCbSurExercice').value} />
      </div>

      <div className={'flex w-12 md:w-4 py-4 pl-3 pr-2'}>
        <IndicatorSimple color={'accent5'} bgImage title={trans(t, 'chart|tresorerie.coutReelAgios')} value={getIndicator(data, 'tresorerie.coutReelAgios').value} />
      </div>
    </div>
  );
};
