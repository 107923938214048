import React, { useCallback, useEffect } from 'react';
import { ModuleWrapper, Unauthorized } from 'pages';
import { trans } from 'utilities';
import { checkAccessByKey } from 'components';

import { DonneesEconomiques } from './DonneesEconomiques';
import { DonneesFiscales } from './DonneesFiscales';
import { DonneesJuridiques } from './DonneesJuridiques';
import { DonneesSocialesCollectives } from './DonneesSocialesCollectives';
import { DonneesSocialesDirigeant } from './DonneesSocialesDirigeant';

export const EntrepriseRouter = (props: any) => {
  const { appState, apim, id, module, tab, modulesMenu, lastUpdate } = props;
  const { t } = apim.di();

  // Override Page Header (defaults into ModuleWrapper).
  useEffect(() => {
    appState.setPageTitle(trans(t, 'menu|pages.title.dossier.entreprise', 1, appState.dossier().title));
  }, [id, module, tab]); // eslint-disable-line react-hooks/exhaustive-deps

  const buildTabContent = useCallback(() => {
    switch (tab) {
      case 'donnees-sociales-collectives':
        return !checkAccessByKey(modulesMenu, 'donnees.sociales.collectives', 'read') ? <Unauthorized asBlock/> : <DonneesSocialesCollectives id={id} appState={appState} apim={apim} modulesMenu={modulesMenu} lastUpdate={lastUpdate}/>;
      case 'donnees-sociales-dirigeant':
        return !checkAccessByKey(modulesMenu, 'donnees.sociales.dirigeant', 'read') ? <Unauthorized asBlock/> : <DonneesSocialesDirigeant id={id} appState={appState} apim={apim} modulesMenu={modulesMenu} lastUpdate={lastUpdate} />;
      case 'donnees-economiques':
        return !checkAccessByKey(modulesMenu, 'donnees.economiques', 'read') ? <Unauthorized asBlock/> : <DonneesEconomiques id={id} appState={appState} apim={apim} modulesMenu={modulesMenu} lastUpdate={lastUpdate} />;
      case 'donnees-fiscales':
        return !checkAccessByKey(modulesMenu, 'donnees.fiscales', 'read') ? <Unauthorized asBlock/> : <DonneesFiscales id={id} appState={appState} apim={apim} modulesMenu={modulesMenu} lastUpdate={lastUpdate} />;
      default:
        return !checkAccessByKey(modulesMenu, 'donnees.juridiques', 'read') ? <Unauthorized asBlock/> : <DonneesJuridiques id={id} appState={appState} apim={apim} modulesMenu={modulesMenu} lastUpdate={lastUpdate} />;
    }
  }, [id, module, tab]); // eslint-disable-line react-hooks/exhaustive-deps

  return <ModuleWrapper appState={appState} apim={apim} modulesMenu={modulesMenu} buildTabContent={buildTabContent} />;
};
