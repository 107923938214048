import React from 'react';
import { Fieldset } from 'primereact/fieldset';

import { trans, ucfirst } from 'utilities';
import { AddressField, ContactField, IndicatorSimple, SimpleText } from 'components';

export const SocieteExperts = (props: any) => {
  const { t, company } = props;

  const representantLegal = [
    { key: 'firstName' },
    { key: 'lastName' },
    { key: 'email' },
    { key: 'phone' },
  ];
  const dates = [{ key: 'created' }, { key: 'updated' }];

  return (
    <>
      <div className={'flex flex-wrap flex-row'}>
        <div className={'flex flex-grow-1 py-2 px-3'}>
          <IndicatorSimple color={'accent4'} bgImage title={trans(t, 'ent.raisonSociale')} text={company?.raisonSociale} />
        </div>
        <div className={'flex flex-grow-1 py-2 px-3'}>
          <IndicatorSimple color={'accent2'} bgImage title={trans(t, `ent.capital`)} value={company?.capital} method={'montant'} />
        </div>
      </div>

      <Fieldset legend={ucfirst(trans(t, 'contact'))}>
        <div className={'flex flex-column md:flex-row py-2 px-3'}>
          <div className={'flex flex-grow-1 px-3'}>
            <AddressField address={company?.address} />
          </div>
        </div>
      </Fieldset>

      <Fieldset legend={ucfirst(trans(t, 'ent.representantLegal'))}>
        <div className={'flex flex-wrap flex-row'}>
          <div className={'flex py-4 px-3 flex-grow-1'}>
            <SimpleText title={trans(t, 'pers.civility')} value={company?.representantLegal?.civility} method={'list'} methodParams={{ listKey: 'civilities' }} />
          </div>
          {representantLegal.map((representantLegal, index) => (
            <div key={index} className={'flex py-4 px-3 flex-grow-1'}>
              <ContactField title={trans(t, representantLegal.key)} text={company?.representantLegal ? company?.representantLegal[representantLegal.key] : ''} />
            </div>
          ))}
          <div className={'flex py-4 pl-3 pr-2'}>
            <SimpleText title={trans(t, 'function')} value={company?.representantLegal?.function} method={'list'} methodParams={{ listKey: 'dirigeantStatus' }} />
          </div>
        </div>
      </Fieldset>

      <div className={'flex flex-row justify-content-end'}>
        {dates.map((date, index) => (
          company &&
          <div key={index} className={'flex px-3 py-5'}>
            <SimpleText title={trans(t, date.key + '.at')} value={company[date.key]} method={'datetime'} />
          </div>
        ))}
      </div>
    </>
  );
};
