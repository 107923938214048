import React, { useCallback, useEffect, useState } from 'react';
import { AxiosResponse } from 'axios';

import { iri, isValidUUID, trans} from 'utilities';
import { ModuleWrapper, Unauthorized } from 'pages';
import { checkAccessByKey, PageLoader } from 'components';
import { RetraiteObligatoire } from './RetraiteObligatoire';
import { RetraiteFacultative } from './RetraiteFacultative';
import { IRequestParams } from 'services';

export const RetraiteRouter = (props: any) => {
  const { appState, apim, id, module, tab, modulesMenu, lastUpdate } = props;
  const { t } = apim.di();

  const [missionType, setMissionType] = useState<any>();
  const [loading, setLoading] = useState<boolean>(false);

  // Override Page Header (defaults into ModuleWrapper).
  useEffect(() => {
    appState.setPageTitle(trans(t, 'menu|pages.title.dossier.retraite', 1, appState.dossier().title));
  }, [id, module, tab]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    setLoading(true)
    apim.fetchEntities({
      resourceType: 'missionTypes',
      params: [{ label: 'code', value: 'RETRAITE'}],
      success:(res : AxiosResponse) => {
        if (res?.data && (res.data['hydra:member'] ?? []).length > 0 && isValidUUID(res.data['hydra:member'][0].id)) {
          setMissionType(res.data['hydra:member'][0])
        }
      }
    } as IRequestParams).then();
    if (iri('missionTypes', missionType?.id) === appState?.mission()?.missionType) {
      setLoading(false);
    } else if (missionType) {
      apim.fetchEntities({
        resourceType: 'missions',
        params: [
          { label: 'dossier', value: id },
          { label: 'missionType', value: missionType?.id },
        ],
        cache: false,
        success:(resMissions : AxiosResponse) => {
          if (resMissions?.data['hydra:member'][0])  {
            appState.setMission(resMissions?.data['hydra:member'][0].id);
          }
          setLoading(false);
        }
      } as IRequestParams).then();
    }
  }, [id, missionType?.id ]);  // eslint-disable-line react-hooks/exhaustive-deps

  const buildTabContent = useCallback(() => {
    switch (tab) {
      case 'facultative':
        return !checkAccessByKey(modulesMenu, 'retraite.facultative', 'read') ? <Unauthorized asBlock/> : <RetraiteFacultative id={id} appState={appState} apim={apim} modulesMenu={modulesMenu} lastUpdate={lastUpdate} />;
      default:
        return !checkAccessByKey(modulesMenu, 'retraite.obligatoire', 'read') ? <Unauthorized asBlock/> : <RetraiteObligatoire id={id} appState={appState} apim={apim} modulesMenu={modulesMenu} lastUpdate={lastUpdate} />;
    }
  }, [id, module, tab, appState?.mission()]); // eslint-disable-line react-hooks/exhaustive-deps

  if (loading) return <PageLoader/>;

  return (
    <ModuleWrapper appState={appState} apim={apim} modulesMenu={modulesMenu} buildTabContent={buildTabContent} />
  );

};
