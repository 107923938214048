import { trans, ucfirst } from 'utilities';

import appUri from 'config/appUri.json';

export const getContractContext = (t: any, appState: any, context: any) => {
  const { id, version, step } = context;
  const dossier: any = appState.dossier();

  let activeIndex: number = -1;
  const urls: any = {
    formBase: appUri.dos.edit.contracts.uri
      .replace(':id', id || '_')
      .replace(':module', 'prevoyance'),
    form: appUri.dos.edit.contracts.uri
      .replace(':id', id || '_')
      .replace(':module', 'prevoyance')
      .replace(':version', version!),
    landing: appUri.dos.page.replace(':id', id || '_')
  };

  const defaultBC = [
    { label: trans(t, 'dossier', 2), to: appUri.dos.list },
    { label: dossier?.title, to: appUri.dos.page.replace(':id', dossier?.id) },
    { label: trans(t, 'editing') + ' | ' + ucfirst(trans(t, 'contract_prevoyance')), to: appUri.dos.edit.landing.replace(':id', dossier?.id) }
  ];

  switch (step) {
    default:
      activeIndex = 0;
      defaultBC.push({
        label: trans(t, 'menu|wizard.dossier.contracts.prevoyance.step11'),
        to: urls.form
      });
      break;
    case 'cg-contrat':
      activeIndex = 1;
      defaultBC.push({
        label: trans(t, 'menu|wizard.dossier.contracts.prevoyance.step21'),
        to: urls.form + appUri.dos.edit.contracts.prevoyance.step21
      });
      break;
    case 'cg-incapacite':
      activeIndex = 2;
      defaultBC.push({
        label: trans(t, 'menu|wizard.dossier.contracts.prevoyance.step22'),
        to: urls.form + appUri.dos.edit.contracts.prevoyance.step22
      });
      break;
    case 'cg-invalidite':
      activeIndex = 3;
      defaultBC.push({
        label: trans(t, 'menu|wizard.dossier.contracts.prevoyance.step23'),
        to: urls.form + appUri.dos.edit.contracts.prevoyance.step23
      });
      break;
    case 'cp-contrat':
      activeIndex = 4;
      defaultBC.push({
        label: trans(t, 'menu|wizard.dossier.contracts.prevoyance.step31'),
        to: urls.form + appUri.dos.edit.contracts.prevoyance.step31
      });
      break;
    case 'cp-incapacite':
      activeIndex = 5;
      defaultBC.push({
        label: trans(t, 'menu|wizard.dossier.contracts.prevoyance.step32'),
        to: urls.form + appUri.dos.edit.contracts.prevoyance.step32
      });
      break;
    case 'cp-invalidite':
      activeIndex = 6;
      defaultBC.push({
        label: trans(t, 'menu|wizard.dossier.contracts.prevoyance.step33'),
        to: urls.form + appUri.dos.edit.contracts.prevoyance.step33
      });
      break;
    case 'cp-deces':
      activeIndex = 7;
      defaultBC.push({
        label: trans(t, 'menu|wizard.dossier.contracts.prevoyance.step34'),
        to: urls.form + appUri.dos.edit.contracts.prevoyance.step34
      });
      break;
    case 'validation':
      activeIndex = 8;
      defaultBC.push({
        label: trans(t, 'menu|wizard.dossier.contracts.prevoyance.step4'),
        to: urls.form + appUri.dos.edit.contracts.prevoyance.step41
      });
      break;
  }

  return { urls, defaultBC, index: activeIndex };
};
