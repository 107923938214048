import React, { useCallback } from 'react';
import { AxiosResponse } from 'axios';
import { Dropdown } from 'primereact/dropdown';

import { iri, trans } from 'utilities';
import { IRequestParams } from 'services';

export const PrevoyanceHeader = (props: any) => {
  const { apim, appState, setLoading, setErrored } = props;
  const { t } = apim.di();

  const getCivilYears = useCallback(() => (appState.dossier()?.civilYears ?? [])
    .sort((a: number, b: number) => a > b ? -1 : 1)
    .map((c: number) => {
      const match: any[] = (appState.prevoyances() ?? []).filter((_p: any) => _p.year === c);

      return match.length > 0 ? {
        year: c,
        label: c.toString()
      } : {
        year: c,
        label: c.toString() + ' (' + trans(t, 'missing') + ')'
      };
    })
  , [appState.prevoyances()]); // eslint-disable-line react-hooks/exhaustive-deps

  const buildSelect = useCallback(() => (
    <div className={'flex py-2 px-3 justify-content-center mb-4'}>
      <div className={'flex field p-inputgroup'}>
        <span className={'p-inputgroup-addon'}>
          <i className={'pi pi-calendar'}></i>
        </span>

        <span className={'p-float-label'}>
          <Dropdown id={'a8-civilYears-select'} value={appState.prevoyance(true)?.year} options={getCivilYears()} placeholder={trans(t, 'form|select')}
                    optionValue={'year'} optionLabel={'label'}
                    onChange={(e) => {
                      const match: any[] = (appState.prevoyances() ?? []).filter((_p: any) => _p.year === e.value);
                      if (match.length > 0) return appState.setPrevoyance(match[0].id);

                      // Let's create a prevoyance data for this year.
                      setLoading(true);
                      apim.postEntity({
                        resourceType: 'prevoyances',
                        data: { dossier: iri('dossiers', appState?.dossier()?.id), year: e.value },
                        notif: false,
                        setErrored,
                        success: (res: AxiosResponse) => {
                          // Then create a prevoyance obligatoire.
                          apim.postEntity({
                            resourceType: 'prevoyancesObligatoires',
                            data: { prevoyance: iri('prevoyances', res?.data?.id) },
                            notif: false,
                            setErrored,
                            setLoading,
                            success: (resD: AxiosResponse) => appState.setPrevoyance(res?.data?.id)
                          } as IRequestParams).then();
                        }
                      } as IRequestParams).then();
                    }}/>
          <label htmlFor={'a8-civilYear-select'} className={'text-ucfirst'}>
            {trans(t, 'civilYear')}
          </label>
        </span>
      </div>
    </div>
  ), [appState.prevoyance()]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      {getCivilYears().length > 0 && (
        buildSelect()
      )}
    </>
  );
};
