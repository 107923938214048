import React, { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { useNotificationState } from 'states';
import { PageLoader } from 'components';

import appUri from 'config/appUri.json';

export const NotificationReceiver = () => {
  const notificationState = useNotificationState();
  const navigate = useNavigate();

  const { notificationType } = useParams();

  useEffect(() => {
    switch (notificationType) {
      case 'test':
        notificationState.incCountUnread();
        navigate(appUri.home);
    }
  }, [notificationType]); // eslint-disable-line react-hooks/exhaustive-deps

  return <PageLoader />
};
