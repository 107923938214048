import React, { useCallback, useState } from 'react';

import { Button } from 'primereact/button';

import { FormWrapper } from 'forms/index';
import { iri, trans, triggerFormSubmit } from 'utilities';
import { PageLoader } from 'components';

import appUri from 'config/appUri.json';

export const DatesDepart = (props: any) => {
  const { apim, urls, hypotheseId, missionCategory, setMissionCategory, cancelUri, missionId } = props;
  const { t, navigate } = apim.di();
  const [loading] = useState<boolean>(false);

  const renderForm = useCallback(() =>
      <>
        <FormWrapper classes={'grid p-fluid'} formKeyPrefix={'dates_depart_retraite'} resourceType={'missionRetraite'} setData={setMissionCategory}
                     data={missionCategory} additionalData={{missionHypothesis : iri('missionHypothesis', hypotheseId)}}
                     hideReload cancelLink multiple redirectUri={urls?.form + '/' + missionId + '/hypothese/' + hypotheseId + appUri.dos.edit.missions.steps.retraite.step3}/>
      </>
    , [missionCategory?.id,hypotheseId]); // eslint-disable-line react-hooks/exhaustive-deps

  if (loading) return <PageLoader/>;

  return (
    <>
      {renderForm()}
      <div className={'text-right m-2 pt-4'}>
        <>
          <Button type={'reset'} className={'text-ucfirst p-button-text p-button-danger mr-5'} label={trans(t, 'close')}
                  onClick={() => navigate(cancelUri)} />
          <Button type={'button'} className={'text-ucfirst p-button-text'} label={trans(t, 'back')} icon={'pi pi-arrow-left'} onClick={() => navigate(urls?.form + '/' +  missionId + '/hypothese/' + hypotheseId + appUri.dos.edit.missions.steps.retraite.step1)}/>
          <Button type={'button'} className={'text-ucfirst p-button-text'} label={trans(t, 'next')} icon={'pi pi-arrow-right'} iconPos={'right'} onClick={() => triggerFormSubmit(null)}/>
        </>
      </div>
    </>
  )
}
