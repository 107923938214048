import React, { useCallback, useEffect, useState } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';
import { AxiosResponse } from 'axios';

import { classNames } from 'primereact/utils';
import { ProgressBar } from 'primereact/progressbar';
import { Steps } from 'primereact/steps';

import { flatPrevoyanceContract, isValidUUID, trans, ucfirst } from 'utilities';
import { Error, NotFound } from 'pages';
import { Loader, PageLoader } from 'components';
import { IRequestParams, useApim } from 'services';
import { useAppState } from 'states';
import { getContractContext } from './ModuleHelper';
import {
  Contexte, CGContrat, CGIncapacite, CGInvalidite,
  CPContrat, CPIncapacite, CPInvalidite, CPDeces, Validation
} from './tabs';
import { ContractFormRouterFG } from './ContractFormRouterFG';
import { ContractFormRouterTD } from './ContractFormRouterTD';

import { omit } from 'lodash';
import appUri from 'config/appUri.json';

export const ContractsFormRouter = () => {
  const { id, version, step } = useParams();
  const apim = useApim();
  const { t, navigate } = apim.di();
  const appState = useAppState();
  const dossier: any = appState.dossier();

  const [loading, setLoading] = useState<boolean>(false);
  const [notFound, setNotFound] = useState<boolean>(false);
  const [errored, setErrored] = useState<boolean>(false);

  const [contract, setContract] = useState<any>(null);

  useEffect(() => {
    if (dossier?.id === id) return;

    // Fetch dossier.
    apim.fetchEntity({
      resourceType: 'dossiers',
      id,
      setErrored,
      setNotFound,
      setter: appState.setDossier,
    } as IRequestParams).then();
  }, [id]); // eslint-disable-line react-hooks/exhaustive-deps

  const [activeIndex, setActiveIndex] = useState<number>(-1);
  const { urls, defaultBC, index } = getContractContext(t, appState, { id, version, step });

  // Update active index & Page Header.
  useEffect(() => {
    setActiveIndex(index);

    appState.setBreadcrumb(defaultBC);
    appState.setPageActions([]);
    appState.setPageTitle(ucfirst(trans(t, 'dossier')) + ' ' + appState.dossier()?.title + ' | ' + trans(t, 'editing'));
  }, [dossier?.id, step]); // eslint-disable-line react-hooks/exhaustive-deps

  const wizardItems = [
    { label: ucfirst(trans(t, 'menu|wizard.dossier.contracts.prevoyance.step11')), command: () => navigate(urls.form) },
    { label: ucfirst(trans(t, 'menu|wizard.dossier.contracts.prevoyance.step21')), command: () => navigate(urls.form + appUri.dos.edit.contracts.prevoyance.step21) },
    { label: ucfirst(trans(t, 'menu|wizard.dossier.contracts.prevoyance.step22')), command: () => navigate(urls.form + appUri.dos.edit.contracts.prevoyance.step22) },
    { label: ucfirst(trans(t, 'menu|wizard.dossier.contracts.prevoyance.step23')), command: () => navigate(urls.form + appUri.dos.edit.contracts.prevoyance.step23) },
    { label: ucfirst(trans(t, 'menu|wizard.dossier.contracts.prevoyance.step31')), command: () => navigate(urls.form + appUri.dos.edit.contracts.prevoyance.step31) },
    { label: ucfirst(trans(t, 'menu|wizard.dossier.contracts.prevoyance.step32')), command: () => navigate(urls.form + appUri.dos.edit.contracts.prevoyance.step32) },
    { label: ucfirst(trans(t, 'menu|wizard.dossier.contracts.prevoyance.step33')), command: () => navigate(urls.form + appUri.dos.edit.contracts.prevoyance.step33) },
    { label: ucfirst(trans(t, 'menu|wizard.dossier.contracts.prevoyance.step34')), command: () => navigate(urls.form + appUri.dos.edit.contracts.prevoyance.step34) },
    { label: ucfirst(trans(t, 'menu|wizard.dossier.contracts.prevoyance.step4')), command: () => navigate(urls.form + appUri.dos.edit.contracts.prevoyance.step41) },
  ];
  const parentItems = [
    { id: '0', label: ucfirst(trans(t, 'menu|wizard.dossier.contracts.prevoyance.step1')), command: () => navigate(urls.form) },
    { id: '1', label: ucfirst(trans(t, 'menu|wizard.dossier.contracts.prevoyance.step2')), command: () => navigate(urls.form + appUri.dos.edit.contracts.prevoyance.step21) },
    { id: '4', label: ucfirst(trans(t, 'menu|wizard.dossier.contracts.prevoyance.step3')), command: () => navigate(urls.form + appUri.dos.edit.contracts.prevoyance.step31) },
    { id: '8', label: ucfirst(trans(t, 'menu|wizard.dossier.contracts.prevoyance.step4')), command: () => navigate(urls.form + appUri.dos.edit.contracts.prevoyance.step41) },
  ];
  const subItems20 = [
    { id: '1', label: ucfirst(trans(t, 'menu|wizard.dossier.contracts.prevoyance.step21')), command: () => navigate(urls.form + appUri.dos.edit.contracts.prevoyance.step21) },
    { id: '2', label: ucfirst(trans(t, 'menu|wizard.dossier.contracts.prevoyance.step22')), command: () => navigate(urls.form + appUri.dos.edit.contracts.prevoyance.step22) },
    { id: '3', label: ucfirst(trans(t, 'menu|wizard.dossier.contracts.prevoyance.step23')), command: () => navigate(urls.form + appUri.dos.edit.contracts.prevoyance.step23) },
  ];
  const subItems30 = [
    { id: '4', label: ucfirst(trans(t, 'menu|wizard.dossier.contracts.prevoyance.step31')), command: () => navigate(urls.form + appUri.dos.edit.contracts.prevoyance.step31) },
    { id: '5', label: ucfirst(trans(t, 'menu|wizard.dossier.contracts.prevoyance.step32')), command: () => navigate(urls.form + appUri.dos.edit.contracts.prevoyance.step32) },
    { id: '6', label: ucfirst(trans(t, 'menu|wizard.dossier.contracts.prevoyance.step33')), command: () => navigate(urls.form + appUri.dos.edit.contracts.prevoyance.step33) },
    { id: '7', label: ucfirst(trans(t, 'menu|wizard.dossier.contracts.prevoyance.step34')), command: () => navigate(urls.form + appUri.dos.edit.contracts.prevoyance.step34) },
  ];

  const [searchParams] = useSearchParams();
  const lastUpdate = searchParams.get('maj');

  const updateData = (newData: any) => setContract({
    ...omit(contract, ['data']),
    ...{
      data: newData
    }
  });

  const buildTabContent = useCallback(() => {
    switch (activeIndex) {
      case -1: return <Loader />
      case 0: return <Contexte apim={apim} urls={urls} data={contract} setData={setContract} appState={appState}/>;
      case 1: return <CGContrat apim={apim} urls={urls} data={contract} updateData={updateData}/>;
      case 2: return <CGIncapacite apim={apim} urls={urls} data={contract} updateData={updateData}/>;
      case 3: return <CGInvalidite apim={apim} urls={urls} data={contract} updateData={updateData}/>;
      case 4: return <CPContrat apim={apim} urls={urls} data={contract} updateData={updateData}/>;
      case 5: return <CPIncapacite apim={apim} urls={urls} data={contract} updateData={updateData}/>;
      case 6: return <CPInvalidite apim={apim} urls={urls} data={contract} updateData={updateData}/>;
      case 7: return <CPDeces apim={apim} urls={urls} data={contract} updateData={updateData}/>;
      case 8: return <Validation apim={apim} urls={urls} dossier={dossier} contract={contract} appState={appState}/>;
      default: return <NotFound asBlock/>
    }
  }, [dossier?.id, contract, version, step, activeIndex, lastUpdate]); // eslint-disable-line react-hooks/exhaustive-deps

  // Fetch contract [+ version].
  useEffect(() => {
    if (!isValidUUID(version)) {
      setContract(null);
      return setLoading(false);
    }

    setLoading(true);
    apim.fetchEntity({
      resourceType: 'prevoyanceContractsData',
      id: version,
      cache: false,
      notif: false,
      setLoading,
      setErrored,
      setNotFound,
      error: () => setLoading(false),
      success: (res: AxiosResponse) => {
        if (!res?.data || !isValidUUID(res?.data?.contract?.id)) {
          setNotFound(true);
          setLoading(false);

          return;
        }

        setContract(flatPrevoyanceContract(res?.data?.contract, res?.data))
      }
    } as IRequestParams).then();
  }, [version, lastUpdate]); // eslint-disable-line react-hooks/exhaustive-deps

  if (!dossier?.id || loading) return <PageLoader/>;
  if (notFound || -1 === activeIndex) return <NotFound asBlock/>;
  if (errored) return <Error asBlock/>;

  if (contract?.type === 'frais_generaux' && activeIndex > 0) return <ContractFormRouterFG activeIndex={activeIndex} setActiveIndex={setActiveIndex} urls={urls} contract={contract} updateData={updateData} step={step} apim={apim} appState={appState} dossier={dossier}/>;
  if (contract?.type === 'tempo_deces' && activeIndex > 0) return <ContractFormRouterTD activeIndex={activeIndex} setActiveIndex={setActiveIndex} urls={urls} contract={contract} updateData={updateData} step={step} apim={apim} appState={appState} dossier={dossier}/>;

  const stepProgressSize = 100 / wizardItems.length;

  return (
    <>
      <div className={'grid'}>
        <div className={'col'}>
          <ProgressBar value={stepProgressSize + activeIndex * stepProgressSize} showValue={false}/>
        </div>
      </div>
      <Steps className={'stacked-steps'} model={parentItems} activeIndex={activeIndex < 1 ? 0 : (activeIndex > 3 ? (activeIndex > 7 ? 3 : 2) : 1)}
             onSelect={(e) => setActiveIndex(parseInt(e.item.id!))} readOnly={false}/>

      {activeIndex < 4 && activeIndex > 0 && (
        <Steps model={subItems20} activeIndex={(activeIndex - 1)} onSelect={(e) => setActiveIndex(parseInt(e.item.id!))} readOnly={false}/>
      )}
      {activeIndex > 3 && activeIndex < 8 && (
        <Steps model={subItems30} activeIndex={(activeIndex - 4)} onSelect={(e) => setActiveIndex(parseInt(e.item.id!))} readOnly={false}/>
      )}

      <div className={classNames('card height-100 fadein animation-duration-500 flex flex-column', {
        'mt-6': !((activeIndex < 4 && activeIndex > 0) || (activeIndex > 3 && activeIndex < 8))
      })}>
        {buildTabContent()}
      </div>
    </>
  );
};
