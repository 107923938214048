import React, { useEffect, useState } from 'react';

import { Tooltip } from 'primereact/tooltip';
import { SpeedDial } from 'primereact/speeddial';

export const AppPageActions = (props: any) => {
  const { apim, appState } = props;
  const { navigate } = apim.di();

  const defaultPageActions: any[] = [];
  const [pageActions, setPageActions] = useState<any>(defaultPageActions);

  // Update page actions.
  const currentPageActions = appState.pageActions();
  useEffect(() => {
    setPageActions(currentPageActions.length < 1 ? defaultPageActions : currentPageActions);
  }, [currentPageActions]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div className={'layout-breadcrumb-buttons flex align-items-center pr-3'}>
      {(pageActions || []).length > 0 && (
        <>
          <Tooltip target={'.a8-speedial-main .p-speeddial-list .p-speeddial-action'} position={'top'} mouseTrack />
          <SpeedDial className={'a8-speedial-main'} model={(pageActions || []).map((pa: any) => ({
            icon: 'pi pi-' + (pa?.icon || 'bookmark'),
            label: pa?.label,
            className: pa?.className || 'bg-indigo-500',
            command: pa?.command ? () => pa.command() : () => {
              if (pa?.to) {
                navigate(pa?.to);
              }
            }
          }))} direction={'left'} showIcon={'pi pi-bars'} hideIcon={'pi pi-times'} buttonClassName={'p-button a8-speedial'} style={{ right: 20, top: 10 }} />
        </>
      )}
    </div>
  );
};
