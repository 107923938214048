import React from 'react';
import { Controller } from 'react-hook-form';

import { classNames } from 'primereact/utils';
import { Dropdown } from 'primereact/dropdown';

import { trans, ucfirst } from 'utilities';
import { useApim } from 'services';

import 'assets/flags/flags.css';
import 'assets/flags/flags_responsive.png';

export const LocaleField = (props: any) => {
  const { fieldKey, control, onFieldChange, errorMessage, rules,
    label, placeholder, classes, disabled } = props;

  const apim = useApim();
  const { t } = apim.di();

  const options = [
    {code: 'fr', codeShort: 'fr', name: ucfirst(trans(t, 'locale.fr'))},
    {code: 'en', codeShort: 'uk', name: ucfirst(trans(t, 'locale.en'))}
  ];

  // A field key can join multiple possibilities using '||' separator.
  const split = fieldKey.split('||');

  const selectedLocaleTemplate = (option: any, props: any) => {
    if (option) {
      return (
        <div className={'flex align-items-center'}>
          <img alt={option.name} src={'https://primefaces.org/cdn/primereact/images/flag/flag_placeholder.png'} className={`mr-2 flag flag-${option.codeShort.toLowerCase()}`} style={{ width: '18px' }} />
          <div>{option.name}</div>
        </div>
      );
    }

    return <span>{props.placeholder}</span>;
  };

  const localeOptionTemplate = (option: any) => {
    return (
      <div className={'flex align-items-center'}>
        <img alt={option.name} src={'https://primefaces.org/cdn/primereact/images/flag/flag_placeholder.png'} className={`mr-2 flag flag-${option.codeShort.toLowerCase()}`} style={{ width: '18px' }} />
        <div>{option.name}</div>
      </div>
    );
  };

  return (<div className={classNames('field', classes || 'col-12 md:col-6 lg:col-4')}>
    <Controller
      name={split[0]}
      control={control}
      rules={rules}
      render={({ field, fieldState }) => (
        <>
          <span className={'p-float-label'}>
            <Dropdown
              id={field.name} className={'w-full'} placeholder={placeholder || ucfirst(trans(t, 'form|select_locale'))} value={field.value}
              options={options} optionLabel={'name'} optionValue={'code'} filter showClear disabled={disabled}
              onChange={(e) => onFieldChange(field, fieldState, e.value)}
              valueTemplate={selectedLocaleTemplate} itemTemplate={localeOptionTemplate}/>
            <label className={'text-ucfirst p-float-label-label'} htmlFor={field.name}>
              <span>{label || trans(t, 'locale.title')}</span>
            </label>
          </span>
          {errorMessage(field.name)}
        </>
      )}
    />
    </div>
  );
};
