import React, { useEffect, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { AxiosResponse } from 'axios';

import { Button } from 'primereact/button';
import { TabMenu } from 'primereact/tabmenu';
import { ProgressBar } from 'primereact/progressbar';

import { trans } from 'utilities';
import { Loader } from 'components';
import { UploadedFiles } from './steps/UploadedFiles';
import { IRequestParams } from 'services';
import { getCompanies, getCurrentCompany, updateCompany } from 'forms/dossier';

import { assign, includes, merge, forEach } from 'lodash';
import cloudFile from 'assets/images/baseline-cloud_upload-24px.svg';
import apimConfig from 'config/apimConfig.json';
import appUri from 'config/appUri.json';

export const Step3 = (props: any) => {
  const { formState, formKey, apim, previous, next } = props;
  const { t, navigate, toastError } = apim.di();

  const [tabs, setTabs] = useState<any[]>([]);
  const [activeIndex, setActiveIndex] = useState<number>(-1);

  const baseUrl = appUri.dos.add;

  useEffect(() => {
    const companies = getCompanies(formState);
    const currentCompany = getCurrentCompany(formState);

    const tabItems: any[] = [];
    let tabIndex: number = 0;
    const selectedCompanies = formState.getFieldData(formKey, 'selected', 'step2');

    forEach(companies, (c: any) => {
      const isCompanySelected = includes(selectedCompanies, c.siren);
      if (isCompanySelected) {
        // Add the tab.
        tabItems.push({
          index: tabIndex,
          label: c.name,
          siren: c.siren,
          command: () => navigate(baseUrl + appUri.dos.wizard.step3 + '/' + c.siren)
        });

        // Define the active index.
        if (c.siren === currentCompany.siren) {
          setActiveIndex(tabIndex)
        }

        tabIndex++;
      }
    });

    setTabs(tabItems);
    formState.setLoading(formKey, false);

    // Poll for document's finishing status.
    // If some error/deployment happen, the mercure channel might cut.
    if (companies.length > 0) {
      companies.map((company: any) => {
        const companyFiles = (company.files || []);
        if (companyFiles.length > 0) {
          companyFiles.map((file: any) => {
            if (file.status === 'uploading') {
              checkFileUploadStatus(company, file);
            }

            return null;
          });
        }

        return null;
      });
    }

  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const checkFileUploadStatus = (company: any, file: any) => {
    const interval = setInterval(function() {
      const companies: any[] = getCompanies(formState, false).filter((c: any) => c.id === company.id);
      if (companies.length === 0) {
        clearInterval(interval);
        return;
      }

      const currentCompany: any = companies.pop();
      if (currentCompany.files.length === 0) {
        clearInterval(interval);
        return;
      }

      const currentCompanyFiles: any[] = (currentCompany.files || []).filter((f: any) => f.path === file.path);
      if (currentCompanyFiles.length === 0) {
        clearInterval(interval);
        return;
      }

      const currentCompanyFile: any = currentCompanyFiles.pop();
      if (currentCompanyFile.status === 'processed') {
        clearInterval(interval);
        return;
      }

      apim.fetchEntity({
        resourceType: 'fileUploads',
        id: file.id,
        notif: false,
        success: (res: AxiosResponse) => {
          if (res.data.status === 'processed') {
            currentCompanyFile.active = true;
            currentCompanyFile.status = 'processed';
            currentCompany.files = (currentCompany.files || []).filter((f: any) => f.id !== currentCompanyFile.id);

            // Switch from fileUpload ID to DocumentID.
            // WARNING: before it was a fileUpload entity, now a Document/Image entity.
            currentCompanyFile.id = res.data.fileUuid;
            currentCompanyFile.url = res.data.fileUrl;

            // Load document type & date.
            if (res.data.fileType === 'Document') {
              if (res.data.fileData?.type !== null && res.data.fileData?.type !== undefined) {
                currentCompanyFile.type = res.data.fileData?.type;
              }

              if (res.data.fileData?.date !== null && res.data.fileData?.date !== undefined) {
                currentCompanyFile.date = res.data.fileData?.date;
              }
            }

            currentCompany.files.push(currentCompanyFile);
            currentCompany.selectedFiles.push(currentCompanyFile.id);

            updateCompany(formState, currentCompany);
            clearInterval(interval);
          }
        }
      } as IRequestParams).then();
    }, 2000);
  }

  const filled = () => {
    let selectedFilesCount = 0;
    let filledFiles = 0;

    forEach(getCompanies(formState), (c: any) => {
      selectedFilesCount += (c.selectedFiles || []).length;
      forEach(c.files, (f: any) => {
        if (includes(c.selectedFiles, f.id)) {
          if ((f.type && f.type !== '') && (f.date && f.date !== '')) {
            filledFiles++;
          }
        }
      });
    });

    return selectedFilesCount > 0 ? filledFiles * 100 / selectedFilesCount : 100;
  };

  const submitAllPendingFiles = () => {
    forEach(getCompanies(formState), (c: any) => {
      forEach(c.files, (f: any) => {
        if (includes(c.selectedFiles, f.id)) {
          if ((f.type && f.type !== '') && (f.date && f.date !== '')) {
            if (f.pendingUpdate) {
              apim.patchEntity({
                resourceType: 'documents',
                id: f.id,
                data: {
                  type: f.type,
                  date: f.date,
                  description: f.comment
                },
                notif: false,
                success: () => {
                  const updatedCompany = assign(c);
                  updatedCompany.files = updatedCompany.files.filter((cf: any) => cf.id !== f.id);
                  updatedCompany.files.push({...f, ...{pendingUpdate: false, lastUpdate: Date.now()}});
                  updateCompany(formState, updatedCompany);
                }
              } as IRequestParams).then();
            }
          }
        }
      });
    });
  };

  const onSubmit = () => {
    submitAllPendingFiles();

    if (100 !== filled()) return toastError?.current.show({
      severity: 'error',
      summary: trans(t, 'form|wrongForm'),
      detail: trans(t, 'form|missingFiles'),
      life: 4000
    });

    formState.setLoading(formKey, true);

    // Return selected companies & their documents.
    const dossierCreationId = formState.getFieldData(formKey, 'dossierCreationId');
    const selectedCompaniesSiren = formState.getFieldData(formKey, 'selected', 'step2');
    const selectedCompanies = getCompanies(formState).filter((c: any) => selectedCompaniesSiren.includes(c.siren));
    const companiesSelectedDocuments = selectedCompanies.map((c: any) => { return {siren: c.siren, documents: (c.selectedFiles || [])}; });

    apim.call({
      resourceType: 'wizardFormDossier',
      action: 'step3',
      notif: false,
      data: {
        id: dossierCreationId,
        companiesSelectedDocuments: companiesSelectedDocuments
      },
      error: () => formState.setLoading(formKey, false),
      success: (res: AxiosResponse) => {
        if (!res?.data) return;

        // Update companies data.
        forEach((res?.data?.entrepreneurCompanies || []), (company: any) => {
          const formCompany = (getCompanies(formState,false).filter((c: any) => c.siren === company.siren) || [])[0];
          updateCompany(formState, merge(company, formCompany, {timeline: res?.data?.companiesTimelines[company.siren]}));
        });

        formState.setLoading(formKey, false);
        navigate(next);
      }
    } as IRequestParams).then();
  };

  const [uploadProgress, setUploadProgress] = useState<any[]>([]);
  const onDrop = (droppedFiles: any) => {
    formState.setLoading(formKey, true);
    let filesCount = 0;

    const company = getCurrentCompany(formState);
    droppedFiles.map((file: any) => {
      filesCount++;

      if (company.files.filter((f: any) => f.name === file.name).length === 0) {
        // Post (async) the file to APIM.
        const formData = new FormData();
        formData.append('file[]', file, file.name);
        formData.append('type', 'document');
        apim.postEntity({
          resourceType: 'fileUploads',
          data: formData,
          notif: false,
          paramType: 'postParams',
          headers: {'A8-Context': JSON.stringify({
            type: 'DossierCreation',
            id: formState.getFieldData(formKey, 'dossierCreationId'),
            company: company.siren
          })},
          progress: (event: any) => {
            const _uploadProgress = uploadProgress;
            _uploadProgress[file.name] = (event.loaded / event.total) * 100;
            setUploadProgress(_uploadProgress);
          },
          success: (res: AxiosResponse) => {
            if (!res?.data?.id) return;

            file.id = res.data.id;
            company.files.push({
              id: file.id,
              path: file.path,
              name: file.name,
              source: 'local',
              date: new Date().toUTCString(),
              dateSort: new Date().toUTCString(),
              active: false,
              status: 'uploading'
            });

            updateCompany(formState, company);
            checkFileUploadStatus(company, file);
          }
        } as IRequestParams).then();
      }

      if (filesCount === droppedFiles.length) {
        formState.setLoading(formKey, false);
        // Tricky DT refresh :p
        setUploadProgress(uploadProgress);
      }
      return true;
    });
  };

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    minSize: 0,
    maxSize: apimConfig.default.maxUploadSize * 1000000
  });

  const droppedFiles = () => {
    const company = getCurrentCompany(formState);
    if (!company) {
      return <></>
    }

    return company && (
      <div className={'mb-4 col-12 d-flex justify-content-center align-items-center'}>
        {(company.files || []).length > 0 && (
          <div>
            <div className={'font-weight-bold text-center'}>
              {trans(t, 'system|dropzoneFilesToHandleTitle')}
            </div>

            <div className={'text-center px-3'}>
              <div className={'progress-wrapper pt-2 pb-2'}>
                <ProgressBar value={filled()} showValue={false} />
              </div>
            </div>

            <UploadedFiles formKey={formKey} formState={formState} apim={apim} company={company} t={t} uploadProgress={uploadProgress} />
          </div>
        )}
      </div>
    );
  };

  return (
    <div className={'a8-wizard wizard-dossier card fadein animation-duration-500'}>
      <h5 className={'mx-2 my-3'}>{trans(t, 'menu|wizard.dossier.title.step3')}</h5>
      <p className={'mx-2 my-3'}><i className={'pi pi-info-circle mr-2 mt-1 text-primary'}></i> {trans(t, 'menu|wizard.dossier.title.step3Help')}</p>

      <div className={'grid p-fluid'}>
        {!formState.isLoading(formKey) ? (
          (tabs.length > 0) ? (
            <>
              {tabs.length > 1 && (
                <div className={'col-12'}><TabMenu model={tabs} activeIndex={activeIndex} onTabChange={(e) => setActiveIndex(e.index)}/></div>
              )}

              <div className={'col-12'}>
                <div className={'dropzone mb-5'}>
                  <div {...getRootProps()}>
                    <input {...getInputProps()} />

                    <div className={'dz-message bgh-primary-lightest animated-non-stop'}>
                      <img alt={'upload'} className={'dz-teaser-img'} src={cloudFile}/>

                      <div className={'dx-text'}>
                        {!isDragActive && trans(t, 'system|dropzoneMessageDefault')}
                        {isDragActive && trans(t, 'system|dropzoneMessageActive')}
                      </div>
                    </div>
                  </div>
                </div>

                {droppedFiles()}
              </div>
            </>
          ) : (
            <p className={'mx-3 mt-2'}><i className={'pi pi-info-circle mr-2 mt-1 text-primary'}></i> {trans(t, 'dossier_creation_no_company')}</p>
          )
        ) : (
          <Loader text={trans(t, 'system|loading')}/>
        )}
      </div>

      <div className={'text-right m-2 mr-3'}>
        <Button className={'text-ucfirst p-button-text mr-5'} label={trans(t, 'previous')} onClick={() => navigate(previous)}/>
        <Button className={'text-ucfirst'} label={trans(t, 'next')} icon={'pi pi-angle-right'} onClick={onSubmit} disabled={filled() !== 100}/>
      </div>
    </div>
  );
};
