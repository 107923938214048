import React, { useEffect, useState } from 'react';
import { Card } from 'primereact/card';
import { Button } from 'primereact/button';

import { trans, ucfirst } from 'utilities';
import { IRequestParams } from 'services';

import remunerationDirigeantImage from 'assets/images/illustrations/landing/mission_remuneration_dirigeant.jpg';
import statutDirigeantImage from 'assets/images/illustrations/landing/mission_statut_dirigeant.jpg';
import retraiteImage from 'assets/images/illustrations/landing/mission_retraite.jpg';

import appUri from 'config/appUri.json';

export const MissionsFormLanding = (props: any) => {
  const { apim, dossier } = props;
  const { t, navigate } = apim.di();
  const [data, setData] = useState<any[]>([]);

  const header = (imageKey: string) => {
    let image;
    switch (imageKey) {
      case 'remuneration_dirigeant':
        image = remunerationDirigeantImage;
        break;
      case 'statut_dirigeant':
        image = statutDirigeantImage;
        break;
      case 'retraite':
        image = retraiteImage;
        break;
    }
    return <img alt={'form-landing-header'} src={image} className={'border-round border-noround-bottom'} />;
  };

  useEffect(() => {
    apim.fetchEntity({
      resourceType: 'missionTypes',
      setter: setData,
    } as IRequestParams).then();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div className={'p-2 flex justify-content-center'}>
      <div>
        <h2 className={'mb-3 text-center'}>{trans(t, 'menu|landing.missions.title')}</h2>
        <p className={'mb-5 text-center'}>{trans(t, 'menu|landing.missions.subtitle')}</p>

        <div className={'a8-dossier-card-wrapper'}>
          <div className={'flex gap-5 justify-content-center'}>
            {data.map((missionType: any) => (
              <Card
                key={missionType.id}
                title={missionType.description}
                subTitle={trans(t, `mis.${missionType.code.toLowerCase().replaceAll('é', 'e')}`)}
                header={header(missionType.code.toLowerCase().replaceAll('é', 'e'))}
                className={'a8-dossier-card-item col-4'}
                onClick={() => navigate(appUri.dos.edit.missions.uri
                    .replace(':id', dossier?.id)
                    .replace(':module', 'missions')
                    .replace(':type',missionType.code.toLowerCase().replaceAll('é', 'e')) +'/new')}
              >
              </Card>
            ))}
          </div>
        </div>
        <div className={'flex align-items-center justify-content-center mt-6'}>
          <Button
            icon={'pi pi-chevron-left'}
            link
            label={ucfirst(trans(t, 'back'))}
            onClick={() => navigate(appUri.dos.edit.landing.replace(':id', dossier?.id))} />
        </div>
      </div>
    </div>
  );
};
