import React from 'react';

import {
  SimpleUncontrolledCheckboxField,
  UncontrolledAPIAutocompleteField,
  UncontrolledAPIListField,
  UncontrolledCheckboxField,
  UncontrolledDateTimeField,
  UncontrolledMaskField,
  UncontrolledNumberField,
  UncontrolledRolesField,
  UncontrolledPermissionsField,
  UncontrolledStaticListField,
  UncontrolledTextField,
  UncontrolledTriStateField,
  UncontrolledEditorField,
  UncontrolledTextAreaField,
} from 'forms';

export const addressEditor = (options: any, config: any | null = null) =>
  <div className={'input-group'}>
    <UncontrolledTextField
      fieldKey={'address1'} value={options.value?.address1} classes={'mb-2'}
      onFieldChange={(value: any) => options.editorCallback({...options.value, ...{address1: value}})}
      label={config?.label.address1 || 'Adresse'} />

    <UncontrolledTextField
      fieldKey={'address2'} value={options.value?.address2} classes={'mb-2'}
      onFieldChange={(value: any) => options.editorCallback({...options.value, ...{address2: value}})}
      label={config?.label.address2 || 'Complément'} />

    <UncontrolledTextField
      fieldKey={'postalCode'} value={options.value?.postalCode} classes={'mb-2'}
      onFieldChange={(value: any) => options.editorCallback({...options.value, ...{postalCode: value}})}
      label={config?.label.postalCode || 'Code Postal'} />

    <UncontrolledTextField
      fieldKey={'city'} value={options.value?.city}
      onFieldChange={(value: any) => options.editorCallback({...options.value, ...{city: value}})}
      label={config?.label.city || 'Ville'} />
  </div>
;

export const amountEditor = (options: any, config: any | null = null) =>
  <UncontrolledNumberField
    mode={'currency'} currency={config?.currency || 'EUR'} locale={config?.locale || 'fr-FR'}
    maxFractionDigits={config?.maxFractionDigits || 2} min={config?.min} max={config?.max}
    showButtons={config?.showButtons === true} disabled={config?.disabled === true} classes={config?.className}
    fieldKey={config?.fieldKey || options.field} value={options.value} prefix={config?.prefix}
    onFieldChange={(value: any) => {
      if (config?.setLastEdit) config.setLastEdit(Date.now);
      options.editorCallback(value);
    }}
    tooltip={config?.tooltip} tooltipOptions={config?.tooltipOptions}
    label={config?.label || options.field} description={config?.description} />
;

export const apiListEditor = (options: any, config: any | null = null) =>
  <UncontrolledAPIListField data={config?.data} multiple={config?.multiple}
    listKey={config?.listKey} placeholder={config?.placeholder}
    optionLabel={config?.optionLabel} optionValue={config?.optionValue}
    showClear={config?.showClear} disabled={config?.disabled === true}
    fieldKey={config?.fieldKey || options.field} value={options.value}
    onFieldChange={(value: any) => {
      if (config?.setLastEdit) config.setLastEdit(Date.now);
      options.editorCallback(value);
    }}
    tooltip={config?.tooltip} tooltipOptions={config?.tooltipOptions}
    label={config?.label || options.field} description={config?.description} />
;

export const apiListAutocompleteEditor = (options: any, config: any | null = null) =>
  <UncontrolledAPIAutocompleteField itemTemplate={config?.itemTemplate}
    resourceType={config?.resourceType} multiple={config?.multiple}
    placeholder={config?.placeholder} optionKey={config?.optionKey} optionValue={config?.optionValue}
    showClear={config?.showClear} disabled={config?.disabled === true}
    fieldKey={config?.fieldKey ?? options.field} value={config?.value ?? options.value}
    onFieldChange={(value: any) => {
      if (config?.setLastEdit) config.setLastEdit(Date.now);
      options.editorCallback(value);
    }}
    tooltip={config?.tooltip} tooltipOptions={config?.tooltipOptions}
    label={config?.label ?? options.field} description={config?.description}
    formatter={config?.formatter} params={config?.params} action={config?.action}
    displayMaxChar={config?.displayMaxChar} forceSelection={config?.forceSelection !== false ? true : undefined} />
;

export const rolesEditor = (options: any, config: any | null = null) =>
  <UncontrolledRolesField data={config?.data} multiple={config?.multiple} placeholder={config?.placeholder}
    rolesAvailables={config?.rolesAvailables} enforceRolesAvailables={config?.enforceRolesAvailables}
    showClear={config?.showClear} disabled={config?.disabled === true}
    fieldKey={config?.fieldKey || options.field} value={options.value}
    onFieldChange={(value: any) => {
      if (config?.setLastEdit) config.setLastEdit(Date.now);
      options.editorCallback(value);
    }}
    tooltip={config?.tooltip} tooltipOptions={config?.tooltipOptions}
    label={config?.label || options.field} description={config?.description}/>
;

export const permissionsEditor = (options: any, config: any | null = null) =>
  <UncontrolledPermissionsField data={config?.data} multiple={config?.multiple}
                          fieldKey={config?.fieldKey || options.field} value={options.value}
                          onFieldChange={(value: any) => {
                            if (config?.setLastEdit) config.setLastEdit(Date.now);
                            options.editorCallback(value);
                          }} />
;

export const staticListEditor = (options: any, config: any | null = null) =>
  <UncontrolledStaticListField data={config?.data} multiple={config?.multiple} itemTemplate={config?.itemTemplate}
    listKey={config?.listKey} listsOptions={config?.listsOptions} placeholder={config?.placeholder}
    optionLabel={config?.optionLabel} optionValue={config?.optionValue} uniqKeys={config?.uniqKeys ?? true}
    showClear={config?.showClear} disabled={config?.disabled === true} filter={config?.filter}
    fieldKey={config?.fieldKey ?? options.field} value={config?.value ?? options.value}
    onFieldChange={(value: any) => {
      if (config?.setLastEdit) config.setLastEdit(Date.now);
      options.editorCallback(value);
    }}
    tooltip={config?.tooltip} tooltipOptions={config?.tooltipOptions}
    label={config?.label ?? options.field} description={config?.description}
    classes={config?.classes} />
;

export const simpleCheckboxEditor = (options: any, config: any | null = null) =>
  <SimpleUncontrolledCheckboxField
    disabled={config?.disabled === true}
    fieldKey={config?.fieldKey ?? options.field} value={options.value}
    onFieldChange={(value: any) => {
      if (config?.setLastEdit) config.setLastEdit(Date.now);
      options.editorCallback(value);
    }}
    tooltip={config?.tooltip} tooltipOptions={config?.tooltipOptions}
    label={config?.label ?? options.field} description={config?.description}/>
;

export const checkboxEditor = (options: any, config: any | null = null) =>
  <UncontrolledCheckboxField
    disabled={config?.disabled === true}
    fieldKey={config?.fieldKey || options.field} value={options.value}
    onFieldChange={(value: any) => {
      if (config?.setLastEdit) config.setLastEdit(Date.now);
      options.editorCallback(value);
    }}
    tooltip={config?.tooltip} tooltipOptions={config?.tooltipOptions}
    label={config?.label || options.field} description={config?.description} />
;

export const dateEditor = (options: any, config: any | null = null) =>
  <UncontrolledDateTimeField
    showButtonBar={config?.showButtonBar !== false}
    dateFormat={config?.dateFormat || 'dd/mm/yy'} parseFormat={config?.parseFormat}
    hourFormat={config?.hourFormat || '24'} disabled={config?.disabled === true}
    fieldKey={config?.fieldKey || options.field} value={options.value}
    onFieldChange={(value: any) => {
      if (config?.setLastEdit) config.setLastEdit(Date.now);
      options.editorCallback(value);
    }}
    tooltip={config?.tooltip} tooltipOptions={config?.tooltipOptions} view={config?.view || 'date'}
    label={config?.label || options.field} description={config?.description} />
;

export const maskEditor = (options: any, config: any | null = null) =>
  <UncontrolledMaskField mask={config?.mask ?? '99/99/9999'}
    isDate={config?.isDate} disabled={config?.disabled === true}
    fieldKey={config?.fieldKey ?? options.field} value={options.value}
    onFieldChange={(value: any) => {
      if (config?.setLastEdit) config.setLastEdit(Date.now);
      options.editorCallback(value);
    }}
    tooltip={config?.tooltip} tooltipOptions={config?.tooltipOptions}
    label={config?.label ?? options.field} description={config?.description}/>
;

export const numberEditor = (options: any, config: any | null = null) =>
  <UncontrolledNumberField
    mode={'decimal'} showButtons={config?.showButtons === true} disabled={config?.disabled === true}
    maxFractionDigits={config?.maxFractionDigits || 2} min={config?.min} max={config?.max}
    fieldKey={config?.fieldKey || options.field} value={options.value} classes={config?.className}
    onFieldChange={(value: any) => {
      if (config?.setLastEdit) config.setLastEdit(Date.now);
      options.editorCallback(value);
    }}
    tooltip={config?.tooltip} tooltipOptions={config?.tooltipOptions}
    prefix={config?.prefix} suffix={config?.suffix}
    label={config?.label || options.field} description={config?.description} />
;

export const percentEditor = (options: any, config: any | null = null) =>
  <UncontrolledNumberField
    mode={'decimal'} suffix={'%'} maxFractionDigits={config?.maxFractionDigits || 2} min={config?.min || 0}
    max={config?.max || 100} disabled={config?.disabled === true} prefix={config?.prefix}
    fieldKey={config?.fieldKey || options.field} value={options.value}
    onFieldChange={(value: any) => {
      if (config?.setLastEdit) config.setLastEdit(Date.now);
      options.editorCallback(value);
    }}
    tooltip={config?.tooltip} tooltipOptions={config?.tooltipOptions}
    label={config?.label || options.field} description={config?.description} />
;

export const textEditor = (options: any, config: any | null = null) =>
  <UncontrolledTextField
    disabled={config?.disabled === true}
    fieldKey={config?.fieldKey || options.field} value={options.value}
    onFieldChange={(value: any) => {
      if (config?.setLastEdit) config.setLastEdit(Date.now);
      options.editorCallback(value);
    }}
    tooltip={config?.tooltip} tooltipOptions={config?.tooltipOptions}
    label={config?.label || options.field} description={config?.description} />
;

export const textAreaEditor = (options: any, config: any | null = null) =>
  <UncontrolledTextAreaField
    disabled={config?.disabled === true} rows={config?.rows}
    fieldKey={config?.fieldKey || options.field} value={options.value}
    onFieldChange={(value: any) => {
      if (config?.setLastEdit) config.setLastEdit(Date.now);
      options.editorCallback(value);
    }}
    tooltip={config?.tooltip} tooltipOptions={config?.tooltipOptions}
    label={config?.label || options.field} description={config?.description} />
;

export const wysiwygEditor = (options: any, config: any | null = null) =>
  <UncontrolledEditorField
    disabled={config?.disabled === true}
    fieldKey={config?.fieldKey || options.field} value={options.value}
    onFieldChange={(value: any) => {
      if (config?.setLastEdit) config.setLastEdit(Date.now);
      options.editorCallback(value);
    }}
    label={config?.label || options.field} description={config?.description}
    hideLabel={config?.hideLabel} height={config?.height} />
;

export const triStateEditor = (options: any, config: any | null = null) =>
  <UncontrolledTriStateField
    disabled={config?.disabled === true}
    fieldKey={config?.fieldKey || options.field} value={options.value}
    onFieldChange={(value: any) => {
      if (config?.setLastEdit) config.setLastEdit(Date.now);
      options.editorCallback(value);
    }}
    tooltip={config?.tooltip} tooltipOptions={config?.tooltipOptions}
    label={config?.label || options.field} description={config?.description} />
;
