import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

import { Skeleton } from 'primereact/skeleton';
import { AxiosResponse } from 'axios';
import { Avatar } from 'primereact/avatar';

import { isValidUUID, trans, uuidFromIri } from 'utilities';
import { IRequestParams } from 'services';

import appUri from 'config/appUri.json';

export const Userbadge = (props: any) => {
  const { user, userId, userRoles, userPictureUrl, userInitials, userFirstName, userLastName, apim, size, shape, style } = props;
  const { t } = apim.di();

  let userIdentifier = userId;
  if (user !== null && user !== undefined) {
    userIdentifier = uuidFromIri(user);
  }

  let processedUserName = trans(t, 'author_system');
  if (userFirstName !== undefined && userFirstName !== null
    && userLastName !== undefined && userLastName !== null) {
    processedUserName = userFirstName + ' ' + userLastName;
  }

  let processedUserInitials = 'A8';
  if (userInitials !== undefined || userInitials !== null) {
    processedUserInitials = userInitials;
  } else if (userFirstName !== undefined && userFirstName !== null
      && userLastName !== undefined && userLastName !== null) {
    processedUserInitials = userFirstName.substring(0, 1) + userLastName.substring(0, 1);
  }

  const getRedirectUri = (userIdentifier: any, userRoles: any) => {
    if (userIdentifier === null || userIdentifier === undefined) {
      return null;
    }

    let value = appUri.usr.page.replace(':id', userIdentifier);
    if (userRoles !== null && userRoles !== undefined) {
      if (userRoles.includes('ROLE_ADMIN') || userRoles.includes('ROLE_EXPERT')) {
        value = appUri.usr.exp.page.replace(':id', userIdentifier);
      }
    }

    return value;
  }

  const [redirectUri, setRedirectUri] = useState<string|null>(getRedirectUri(userIdentifier, userRoles));
  const [initials, setInitials] = useState<string>(processedUserInitials);
  const [name, setName] = useState<string>(processedUserName);
  const [picture, setPicture] = useState<string>(userPictureUrl);
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    if (picture === undefined) {
      if (isValidUUID(userIdentifier)) {
        setLoading(true);

        apim.fetchEntity({
          resourceType: 'users',
          id: userIdentifier,
          setLoading: setLoading,
          notif: false,
          error: () => setInitials('?'),
          success: (res: AxiosResponse) => {
            if (!res?.data) return;

            if (res.data['picture'] !== undefined) {
              setPicture(res.data['picture']);
            }

            if (res.data['fullName'] !== undefined) {
              setName(res.data['fullName']);
            }

            if (res.data['initials'] !== undefined) {
              setInitials(res.data['initials']);
            } else if (res.data['firstName'] !== undefined && res.data['lastName'] !== undefined) {
              setInitials(res.data['firstName'].substring(0, 1) + res.data['lastName'].substring(0, 1));
            } else {
              setInitials('?')
            }

            if (res.data['roles'] !== null && res.data['roles'] !== undefined) {
              if (res.data['roles'].includes('ROLE_ADMIN') || res.data['roles'].includes('ROLE_EXPERT')) {
                setRedirectUri(getRedirectUri(userIdentifier, res.data['roles']));
              }
            }
          }
        } as IRequestParams).then();
      } else {
        setName(trans(t, 'author_system'));
        setInitials('A8')
      }
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const innerTemplate = () => {
    return <span style={style} className={'flex flex-row gap-1 align-items-center'}>
        <Avatar label={initials} size={size ?? 'large'} shape={shape ?? 'circle'} image={picture} />
        <span className={'user-badge--username font-bold'}>{name}</span>
      </span>;
  }

  return <>
    {loading ? (
      <Skeleton shape="circle" size={size === 'large' ? '3rem' : '1rem'}></Skeleton>
    ) : (
      redirectUri !== null ? (
        <Link to={redirectUri}>
          {innerTemplate()}
        </Link>
      ) : (
        innerTemplate()
      )
    )}
  </>;
};
