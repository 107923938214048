import React, { useCallback, useEffect, useState } from 'react';

import { useParams, useSearchParams } from 'react-router-dom';
import { TFunction } from 'i18next';
import { AxiosResponse } from 'axios';

import { IRequestParams, subscribe, useApim, userId } from 'services';
import { useAppState } from 'states';
import { checkAccessByKey, ModulesMenu, PageLoader } from 'components';
import { DocumentsList, Error, NotFound, Unauthorized } from 'pages';
import { iri, isValidUUID } from 'utilities';

import { DossierSchema } from './DossierSchema';
import {
  EntrepriseRouter, FoyerRouter, MissionsRouter, PatrimoineRouter, PrevoyanceRouter,
  ReservesRouter, RetraiteRouter, RevenuEtImpositionRouter, TresorerieRouter
} from './modules';

import appUri from 'config/appUri.json';
import { isEmpty } from 'lodash';

export const DossierRouter = () => {
  const { id, module, tab, company, year,
    event, subResource, subResourceId, type } = useParams();
  const apim = useApim();
  const { t } = apim.di();
  const appState = useAppState();
  const dossier = appState.dossier();
  // Update AppState "currents".
  useEffect(() => {
    if (year) {
      appState.setCivilYear(year);
      appState.setExercise(year);
    }
    if (company) appState.setCompany(company);
    if (event) appState.setEvent(event);
  }, [year, company, event]); // eslint-disable-line react-hooks/exhaustive-deps

  const [loading, setLoading] = useState<boolean>(false);
  const [notFound, setNotFound] = useState<boolean>(false);
  const [errored, setErrored] = useState<boolean>(false);
  const [modules, setModules] = useState<any[]>([]);

  // Validate URL & update current dossier.
  const checks = useCallback(() => {
    return isUrlValid(t, id, module, tab , ModulesMenu({t, id, modules: appState.modules()}));
  }, [id, module, tab]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (!checks()) setNotFound(true);
  }, [id]); // eslint-disable-line react-hooks/exhaustive-deps

  // Let's detect if API calls from now have to be refreshed (cache expiration).
  const [searchParams] = useSearchParams();
  const lastUpdate = searchParams.get('maj');
  const fetchDossier = useCallback(() => {
    if (!id || (dossier && dossier.id === id && (modules || []).length > 0)) return;
    setLoading(true);

    apim.fetchEntities({
      resourceType: 'dossiers',
      id,
      cacheMode: isEmpty(lastUpdate) ? 'default' : 'replace',
      setErrored,
      setNotFound,
      setter: appState.setDossier,
      success: (res: AxiosResponse) => {
        if (!res?.data?.id) return;
        // If the dossier is not fully processed,
        // then we need to listen to its topic to refresh data when it's done.
        const isProcessing = res.data.status === 'processing';
        if (isProcessing) {
          subscribe([iri('dossiers', res.data.id, true)], ((m: any) => {
            if (!m.data) return;
            const pushedDossierData = JSON.parse(m.data);
            appState.setDossier(pushedDossierData);
          }));
        }

        if (!appState.dossier()?.id) appState.setDossier(res.data);

        apim.call({
          resourceType: 'dossiers',
          action: 'dossierUserModules',
          id: id + '|' + userId(),
          method: 'get',
          setLoading: setLoading,
          success: (resModulesPermissions: AxiosResponse) => setModules(ModulesMenu({
            t,
            id,
            modules: appState.modules(),
            modulesPermissions: resModulesPermissions?.data['hydra:member']
          }))
        } as IRequestParams);
      },
      error: () => setLoading(false)
    } as IRequestParams).then();
  }, [id, lastUpdate, appState.modules()]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (!dossier || !id || (dossier.id !== id) || (modules ?? []).length === 0) {
      fetchDossier();
    } else {
      setLoading(false);
    }
  }, [id, lastUpdate, modules]); // eslint-disable-line react-hooks/exhaustive-deps

  // Reset Module during navigation.
  useEffect(() => {
    if (!module) appState.setModule(null);
  }, [module]); // eslint-disable-line react-hooks/exhaustive-deps

  if (loading || (modules ?? []).length === 0) return <PageLoader/>;
  if (notFound) return <NotFound asBlock/>;
  if (errored) return <Error asBlock/>;

  switch (module) {
    case 'ged':
      return !checkAccessByKey(modules, 'ged', 'read') ? <Unauthorized asBlock/> : <DocumentsList dossier={dossier} module={appState?.module()}/>;

    case 'entreprise':
      return <EntrepriseRouter appState={appState} apim={apim} id={id} module={module} tab={tab}
                               modulesMenu={modules} lastUpdate={lastUpdate}/>;
    case 'reserves':
      return !checkAccessByKey(modules, 'reserves', 'read') ? <Unauthorized asBlock/> : <ReservesRouter appState={appState} apim={apim} id={id} module={module} tab={tab}
                             modulesMenu={modules} lastUpdate={lastUpdate}/>;
    case 'retraite':
      return <RetraiteRouter appState={appState} apim={apim} id={id} module={module} tab={tab}
                             modulesMenu={modules} lastUpdate={lastUpdate}/>;
    case 'revenu-et-imposition':
      return !checkAccessByKey(modules, 'revenus', 'read') ? <Unauthorized asBlock/> : <RevenuEtImpositionRouter appState={appState} apim={apim} id={id} module={module} tab={tab}
                                       modulesMenu={modules} lastUpdate={lastUpdate}/>;
    case 'tresorerie':
      return <TresorerieRouter appState={appState} apim={apim} id={id} module={module} tab={tab}
                               modulesMenu={modules} lastUpdate={lastUpdate}/>;
    case 'foyer':
      return !checkAccessByKey(modules, 'foyer', 'read') ? <Unauthorized asBlock/> : <FoyerRouter appState={appState} apim={apim} id={id} module={module} tab={tab}
                          modulesMenu={modules} lastUpdate={lastUpdate}/>;
    case 'prevoyance':
      return <PrevoyanceRouter appState={appState} apim={apim} id={id} module={module} tab={tab}
                               modulesMenu={modules} lastUpdate={lastUpdate} subResource={subResource} subResourceId={subResourceId} />;
    case 'patrimoine':
      return <PatrimoineRouter appState={appState} apim={apim} id={id} module={module} tab={tab}
                               modulesMenu={modules} lastUpdate={lastUpdate} subResource={subResource} subResourceId={subResourceId} />;
    case 'missions':
      return !checkAccessByKey(modules, 'missions', 'read') ? <Unauthorized asBlock/> : <MissionsRouter appState={appState} apim={apim} id={id} module={module} tab={tab}
                             modulesMenu={modules} lastUpdate={lastUpdate} type={type}/>;
    default:
      return dossier ?
        <DossierSchema appState={appState} apim={apim} id={id} modulesMenu={modules}/> :
        <NotFound asBlock/>;
  }
};

const isUrlValid = (t: TFunction, id: string | undefined, module: string | undefined, tab: string | undefined, menu: any[]) => {
  // UUID of the dossier.
  if (!isValidUUID(id)) return false;

  // Module list + "edit" & "settings" as module-like URIs.
  const validMenu = menu.filter((m: any) => m.to === appUri.dos.page.replace(':id', id!) + '/' + module);
  if (module && 0 === validMenu.length) return false;

  // Tab list.
  return !(tab && 0 === (validMenu[0].items ?? []).filter((t: any) => t.to === appUri.dos.page.replace(':id', id!) + '/' + module + '/' + tab).length);
}
