import React, { useCallback } from 'react';

import { FormWrapper } from 'forms/index';
import { uuidFromIri } from 'utilities';

import appUri from 'config/appUri.json';

export const UserProfile = (props: any) => {
  const { data } = props;

  const userId = uuidFromIri(data['@id']);
  const redirectUri = appUri.usr.page.replace(':id', userId) + '?maj=' + Date.now()

  // Wrap form render into a useCallback to avoid multiple FormWrapper recalls due to form fields updates.
  const renderUserForm = useCallback(() =>
    <FormWrapper data={data} formKeyPrefix={'full'} resourceType={'users'} cancelLink redirectUri={redirectUri} />
  , [data?.id]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div className={'a8-form-user'}>
      {renderUserForm()}
    </div>
  );
}
