import React from 'react';
import { CheckboxField } from 'forms/index';

export const MissionTypes = (props: any) => {
  const { control, onFieldChange, errorMessage } = props;

  return (
    <>
      <div className={'flex flex-row w-12 pt-2'}>
        <div className={'flex flex-column align-items-center w-6'}>
          <CheckboxField classes={''} fieldKey={'capable'} control={control} onFieldChange={onFieldChange} errorMessage={errorMessage} />
        </div>
        <div className={'flex flex-column align-items-center w-6'}>
          <CheckboxField classes={''} fieldKey={'available'} control={control} onFieldChange={onFieldChange} errorMessage={errorMessage} />
        </div>
      </div>
    </>
  );
};
