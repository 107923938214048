import React, { useEffect, useState } from 'react';
import { AxiosResponse } from 'axios';
import { Link } from 'react-router-dom';

import { useTranslation } from 'react-i18next';
import { Fieldset } from 'primereact/fieldset';
import { Skeleton } from 'primereact/skeleton';

import { age, formatListItem, isValidUUID, uuidFromIri, trans, ucfirst, escapeValue, dateString } from 'utilities';
import { APIListField, CheckboxField, NumberField, StaticListField } from 'forms';
import { IRequestParams, useApim } from 'services';
import { Error } from 'pages';
import { useFormState } from 'states';

import appUri from 'config/appUri.json';

export const PatrimoineFoyerForm = (props: any) => {
  const { t, control, onFieldChange, errorMessage, getValues, setValue, context, formKey, parentKey } = props;
  const apim = useApim();
  const formState = useFormState();
  const { i18n } = useTranslation();
  const date = context?.patrimoine?.date;

  const {
    personnePhysique = null,
    donationEntreConjoints = null,
    successionDonationOptions = null,
    successionOptionSurvivant = null,
    budgetTotalAnnuel = null,
    budgetLoyerResidencePrincipaleAnnuel = null,
    budgetEnergieAnnuel = null,
    budgetEnergieResidencePrincipaleChauffageElecAnnuel = null,
    budgetEnergieVoitureCarburantAnnuel = null,
    budgetAssurancesAnnuel = null,
    budgetTransportsAnnuel = null,
    budgetAlimentationAnnuel = null,
    budgetLoisirsAnnuel = null,
    budgetEtudesChargesEnfantsTiersAnnuel = null,
    budgetDiversAnnuel = null,
  } = getValues();

  const [loading, setLoading] = useState<boolean>(false);
  const [foyer, setFoyer] = useState<any>(null);
  const [errored, setErrored] = useState<boolean>(false);

  const [civilities, setCivilities] = useState<any[]>([]);
  useEffect(() => {
    apim.getList('civilities', {setter: setCivilities} as IRequestParams).then();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (personnePhysique === null || personnePhysique === undefined) {
      return;
    }

    const params: any = [];
    if (date !== null && date !== undefined) {
      params.push({label: 'date', value: date});
    }

    setLoading(true);
    apim.fetchEntities({
      resourceType: 'personnesPhysiques',
      action: 'foyer',
      id: uuidFromIri(personnePhysique),
      params: params,
      setLoading: setLoading,
      setErrored: setErrored,
      setter: setFoyer,
    } as IRequestParams).then();
  }, [personnePhysique]); // eslint-disable-line react-hooks/exhaustive-deps

  const [hasDetails, setHasDetails] = useState<boolean>((budgetLoyerResidencePrincipaleAnnuel +
    budgetEnergieAnnuel +
    budgetEnergieResidencePrincipaleChauffageElecAnnuel +
    budgetEnergieVoitureCarburantAnnuel +
    budgetAssurancesAnnuel +
    budgetTransportsAnnuel +
    budgetAlimentationAnnuel +
    budgetLoisirsAnnuel +
    budgetEtudesChargesEnfantsTiersAnnuel +
    budgetDiversAnnuel) > 0);
  const [hasTotal, setHasTotal] = useState<boolean>(budgetTotalAnnuel > 0);

  // Calcule total budget annuel
  const calculateBudgetTotalAnnuel = () => {
    const budgetTotalAnnuelCalc = budgetLoyerResidencePrincipaleAnnuel +
      budgetEnergieAnnuel +
      budgetEnergieResidencePrincipaleChauffageElecAnnuel +
      budgetEnergieVoitureCarburantAnnuel +
      budgetAssurancesAnnuel +
      budgetTransportsAnnuel +
      budgetAlimentationAnnuel +
      budgetLoisirsAnnuel +
      budgetEtudesChargesEnfantsTiersAnnuel +
      budgetDiversAnnuel;

    formState.setFieldData(formKey, 'budgetTotalAnnuel', parentKey, escapeValue(budgetTotalAnnuelCalc, 'number'));
    setValue('budgetTotalAnnuel', budgetTotalAnnuelCalc);

    if (budgetTotalAnnuelCalc > 0) {
      setHasDetails(true);
    } else {
      setHasDetails(false);
    }
  };

  // Triggering calculations everytime related values are updated
  useEffect(() => {
    calculateBudgetTotalAnnuel();
  }, [budgetLoyerResidencePrincipaleAnnuel, budgetEnergieAnnuel, budgetEnergieResidencePrincipaleChauffageElecAnnuel, budgetEnergieVoitureCarburantAnnuel, budgetAssurancesAnnuel, budgetTransportsAnnuel, budgetAlimentationAnnuel, budgetLoisirsAnnuel, budgetEtudesChargesEnfantsTiersAnnuel, budgetDiversAnnuel]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (budgetTotalAnnuel > 0) {
      setHasTotal(true);
    } else {
      setHasTotal(false);
    }
  }, [budgetTotalAnnuel]); // eslint-disable-line react-hooks/exhaustive-deps

  const [successionDonationOptionsFiltered, setSuccessionDonationOptionsFiltered] = useState<any[]>([]);
  useEffect(() => {
    if (donationEntreConjoints) {
      apim.getList('successionDonationOptions', {
        success: (res: AxiosResponse) => {
          if (!res?.data || (res?.data['hydra:member'] || []).length > 0) {
            const options: any = (res?.data['hydra:member'] || []).filter((s: any) => {
              return successionDonationOptions.includes(s.value);
            });

            const optionsValues: string[] = options.map((o: any) => o.value);
            setSuccessionDonationOptionsFiltered(options);

            // Reset the succession option survivant field value.
            if (!optionsValues.includes(successionOptionSurvivant)) {
              formState.setFieldData(formKey, 'successionOptionSurvivant', parentKey, null);
              setValue('successionOptionSurvivant', null);
            }

          } else {
            setSuccessionDonationOptionsFiltered([]);
          }
        }
      } as IRequestParams).then();
    } else {
      apim.getList('successionCodeCivilOptions', {
        success: (res: AxiosResponse) => {
          if (!res?.data || (res?.data['hydra:member'] || []).length > 0) {
            const options: any = (res?.data['hydra:member'] || []);
            const optionsValues: string[] = options.map((o: any) => o.value);
            setSuccessionDonationOptionsFiltered(options);

            // Reset the succession option survivant field value.
            if (!optionsValues.includes(successionOptionSurvivant)) {
              formState.setFieldData(formKey, 'successionOptionSurvivant', parentKey, null);
              setValue('successionOptionSurvivant', null);
            }

          } else {
            setSuccessionDonationOptionsFiltered([]);
          }
        }
      } as IRequestParams).then();
    }
  }, [donationEntreConjoints, successionDonationOptions]); // eslint-disable-line react-hooks/exhaustive-deps

  const renderFoyer = () => {
    if (foyer === null) {
      return;
    }

    return <div className={'foyer mb-5'}>
      {date !== null && date !== undefined && (
        <p>{trans(t, 'form|patrimoine.foyerComposition')} {dateString(date)}</p>
      )}

      {isValidUUID(foyer?.main?.id) && (
        <div className={'foyer-main flex flex-row align-items-center'}>
          {foyer.main.version?.sex === 'male' && (
            <img src='/assets/images/avatar/husband.gif' style={{height: '1.6em'}} className={'mr-2'} alt={''} />
          )}

          {foyer.main.version?.sex === 'female' && (
            <img src='/assets/images/avatar/woman.gif' style={{height: '1.6em'}} className={'mr-2'} alt={''} />
          )}

          {foyer.main.version?.sex !== 'female' && foyer.main.version?.sex !== 'male' && (
            <img src='/assets/images/avatar/profile.gif' style={{height: '1.6em'}} className={'mr-2'} alt={''} />
          )}

          <Link to={appUri.cie.per.phy.page.replace(':id', foyer.main.id)}>
            {formatListItem(foyer.main.version?.civility, civilities)} {foyer.main.fullName} ({age(foyer.main.birthDate, 'an', '', {referenceDate: date})})
          </Link>
        </div>
      )}

      {isValidUUID(foyer?.spouse?.id) && (
        <div className={'foyer-spouse flex flex-row align-items-center'}>
          {foyer.spouse.version?.sex === 'male' && (
            <img src='/assets/images/avatar/husband.gif' style={{height: '1.6em'}} className={'mr-2'} alt={''} />
          )}

          {foyer.spouse.version?.sex === 'female' && (
            <img src='/assets/images/avatar/woman.gif' style={{height: '1.6em'}} className={'mr-2'} alt={''} />
          )}

          {foyer.spouse.version?.sex !== 'female' && foyer.spouse.version?.sex !== 'male' && (
            <img src='/assets/images/avatar/profile.gif' style={{height: '1.6em'}} className={'mr-2'} alt={''} />
          )}

          <Link to={appUri.cie.per.phy.page.replace(':id', foyer.spouse.id)}>
            {formatListItem(foyer.spouse.version?.civility, civilities)} {foyer.spouse.fullName} ({age(foyer.spouse.birthDate, 'an', '', {referenceDate: date})})
          </Link>
        </div>
      )}

      {foyer?.children?.length > 0 && (
        <div className={'foyer-children m-2'}>
          {foyer.children.map((child: any) => {
            return <div key={child.id} className={'foyer-child flex flex-row align-items-center'}>
              {child?.version?.sex === 'male' && (
                <img src='/assets/images/avatar/baby-boy.gif' style={{height: '1.6em'}} className={'mr-2'} alt={''} />
              )}

              {child?.version?.sex === 'female' && (
                <img src='/assets/images/avatar/baby-girl.gif' style={{height: '1.6em'}} className={'mr-2'} alt={''} />
              )}

              {child?.version?.sex !== 'female' && child?.version?.sex !== 'male' && (
                <img src='/assets/images/avatar/baby.gif' style={{height: '1.6em'}} className={'mr-2'} alt={''} />
              )}

              <Link to={appUri.cie.per.phy.page.replace(':id', child.id)}>
                {formatListItem(child.version?.civility, civilities)} {child.fullName} ({age(child.birthDate, 'an', '', {referenceDate: date})})
              </Link>
            </div>
          })}
        </div>
      )}

      <div>
        <Link to={appUri.dos.settings.uri.replace(':id', context.dossier.id) + '/foyer'} className={'p-button mt-4'}>{trans(t, 'foyer.edit')}</Link>
      </div>
    </div>
  }

  return <>
    <Fieldset legend={ucfirst(trans(t, 'form|patrimoine.foyer'))} toggleable className={'w-full mb-3'}>
      <div className={'grid'}>
        <div className={'col-2'}>
          {loading ? (
            <Skeleton width={'100%'} height={'12rem'} className={'mb-4'}></Skeleton>
          ) : (
            (errored ? (
              <Error asBlock/>
            ) : (
              renderFoyer()
            ))
          )}
        </div>

        <div className={'col-10'}>
          <div className={'grid'}>
            <div className={'col-3'}>
              <NumberField classes={'w-full'} fieldKey={'partsFiscales'} mode={'decimal'}
                           control={control} onFieldChange={onFieldChange} showButtons min={0} step={0.25}
                           errorMessage={errorMessage} label={trans(t, 'form|patrimoine.partsFiscales')}/>

            </div>

            <div className={'col-12'}>
              <CheckboxField classes={'w-full'} fieldKey={'donationEntreConjoints'}
                             control={control} onFieldChange={onFieldChange} errorMessage={errorMessage}
                             label={trans(t, 'form|patrimoine.donationEntreConjoints')} help={trans(t, 'form|patrimoine.donationEntreConjointsHelp')}/>

              {i18n.language === 'fr-FR' && (
                <ul className={'m-0 ml-4 p-0 text-sm'}>
                  <li>Si non coché, le conjoint survivant a le choix entre 2 options prévues par le Code civil :</li>
                  <ul>
                    <li>Si enfants communs alors 100% en usufruit ou 1/4 en pleine propriété.</li>
                    <li>Si enfants d'une précédente union alors 1/4 en pleine propriété.</li>
                  </ul>

                  <li className={'pt-2'}>Si coché, le conjoint survivant a le choix entre 3 options :</li>
                  <ul>
                    <li>100% en usufruit (quel que soit le nombre d'enfants non communs).</li>
                    <li>Quotité Disponible en pleine propriété (1/4, 1/3, 1/2 ou la totalité, selon le nombre d'enfants du conjoint).</li>
                    <li>1/4 en pleine propriété + 3/4 en usufruit.</li>
                  </ul>
                </ul>
              )}
            </div>

            <h2 className={'w-full text-base mx-2'}>Succession</h2>

            {donationEntreConjoints === true && (
              <div className={'col-3'}>
                <APIListField classes={'w-full'} listKey={'successionDonationOptions'} fieldKey={'successionDonationOptions'}
                              control={control} onFieldChange={onFieldChange} errorMessage={errorMessage}
                              label={trans(t, 'form|patrimoine.successionDonationOptions')} rules={{required: trans(t, 'form|requiredField')}} multiple/>
              </div>
            )}

            <div className={'col-3'}>
              <StaticListField classes={'w-full'} listKey={'successionDonationOptions'} fieldKey={'successionOptionSurvivant'} optionLabel={'translated'}
                               label={trans(t, 'form|patrimoine.successionOptionSurvivant')} rules={{required: trans(t, 'form|requiredField')}} editable={true}
                               control={control} errorMessage={errorMessage} onFieldChange={onFieldChange} listsOptions={{successionDonationOptions: successionDonationOptionsFiltered}}/>
            </div>
          </div>
        </div>
      </div>
    </Fieldset>

    <Fieldset legend={ucfirst(trans(t, 'form|patrimoine.foyerBudget'))} toggleable className={'w-full mb-3'}>
      <div className={'grid'}>
        <div className={'col-3'}>
          <NumberField classes={'w-full'} fieldKey={'budgetTotalAnnuel'} mode={'currency'} control={control} onFieldChange={onFieldChange} disabled={hasDetails}
                       errorMessage={errorMessage} addon={'euro'} addonPosition={'after'} label={trans(t, 'form|patrimoine.budgetTotalAnnuel')}/>
        </div>

        <Fieldset legend={ucfirst(trans(t, 'form|patrimoine.foyerBudgetAnnuelDetails'))} toggleable className={'w-full mb-3'}>
          <div className={'grid'}>
            <div className={'col-3'}>
              <NumberField classes={'w-full'} fieldKey={'budgetLoyerResidencePrincipaleAnnuel'} mode={'currency'} control={control} onFieldChange={onFieldChange} disabled={!hasDetails && hasTotal}
                           errorMessage={errorMessage} addon={'euro'} addonPosition={'after'} label={trans(t, 'form|patrimoine.budgetLoyerResidencePrincipaleAnnuel')}/>
            </div>

            <div className={'col-3'}>
              <NumberField classes={'w-full'} fieldKey={'budgetEnergieAnnuel'} mode={'currency'} control={control} onFieldChange={onFieldChange} disabled={!hasDetails && hasTotal}
                           errorMessage={errorMessage} addon={'euro'} addonPosition={'after'} label={trans(t, 'form|patrimoine.budgetEnergieAnnuel')}/>
            </div>

            <div className={'col-3'}>
              <NumberField classes={'w-full'} fieldKey={'budgetEnergieResidencePrincipaleChauffageElecAnnuel'} mode={'currency'} control={control} onFieldChange={onFieldChange} disabled={!hasDetails && hasTotal}
                           errorMessage={errorMessage} addon={'euro'} addonPosition={'after'} label={trans(t, 'form|patrimoine.budgetEnergieResidencePrincipaleChauffageElecAnnuel')}/>
            </div>

            <div className={'col-3'}>
              <NumberField classes={'w-full'} fieldKey={'budgetEnergieVoitureCarburantAnnuel'} mode={'currency'} control={control} onFieldChange={onFieldChange} disabled={!hasDetails && hasTotal}
                           errorMessage={errorMessage} addon={'euro'} addonPosition={'after'} label={trans(t, 'form|patrimoine.budgetEnergieVoitureCarburantAnnuel')}/>
            </div>

            <div className={'col-3'}>
              <NumberField classes={'w-full'} fieldKey={'budgetAssurancesAnnuel'} mode={'currency'} control={control} onFieldChange={onFieldChange} disabled={!hasDetails && hasTotal}
                           errorMessage={errorMessage} addon={'euro'} addonPosition={'after'} label={trans(t, 'form|patrimoine.budgetAssurancesAnnuel')}/>
            </div>

            <div className={'col-3'}>
              <NumberField classes={'w-full'} fieldKey={'budgetTransportsAnnuel'} mode={'currency'} control={control} onFieldChange={onFieldChange} disabled={!hasDetails && hasTotal}
                           errorMessage={errorMessage} addon={'euro'} addonPosition={'after'} label={trans(t, 'form|patrimoine.budgetTransportsAnnuel')}/>
            </div>

            <div className={'col-3'}>
              <NumberField classes={'w-full'} fieldKey={'budgetAlimentationAnnuel'} mode={'currency'} control={control} onFieldChange={onFieldChange} disabled={!hasDetails && hasTotal}
                           errorMessage={errorMessage} addon={'euro'} addonPosition={'after'} label={trans(t, 'form|patrimoine.budgetAlimentationAnnuel')}/>
            </div>

            <div className={'col-3'}>
              <NumberField classes={'w-full'} fieldKey={'budgetLoisirsAnnuel'} mode={'currency'} control={control} onFieldChange={onFieldChange} disabled={!hasDetails && hasTotal}
                           errorMessage={errorMessage} addon={'euro'} addonPosition={'after'} label={trans(t, 'form|patrimoine.budgetLoisirsAnnuel')}/>
            </div>

            <div className={'col-3'}>
              <NumberField classes={'w-full'} fieldKey={'budgetEtudesChargesEnfantsTiersAnnuel'} mode={'currency'} control={control} onFieldChange={onFieldChange} disabled={!hasDetails && hasTotal}
                           errorMessage={errorMessage} addon={'euro'} addonPosition={'after'} label={trans(t, 'form|patrimoine.budgetEtudesChargesEnfantsTiersAnnuel')}/>
            </div>

            <div className={'col-3'}>
              <NumberField classes={'w-full'} fieldKey={'budgetDiversAnnuel'} mode={'currency'} control={control} onFieldChange={onFieldChange} disabled={!hasDetails && hasTotal}
                           errorMessage={errorMessage} addon={'euro'} addonPosition={'after'} label={trans(t, 'form|patrimoine.budgetDiversAnnuel')}/>
            </div>
          </div>
        </Fieldset>
      </div>
    </Fieldset>
  </>
;
};
