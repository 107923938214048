import React from 'react';
import { Card } from 'primereact/card';
import { classNames } from 'primereact/utils';

import { formatSiren, shortNumber, trans, ucfirst } from 'utilities';
import company from 'assets/images/illustrations/search/company.webp';

import { replace } from 'lodash';
import appUri from 'config/appUri.json';

export const SearchResultCompany = (props: any) => {
  const { data, apim, setVisibleFullScreen } = props;
  const { t, navigate } = apim.di();
  const redirectUri = replace(appUri.cie.cli.page, ':id', data.id || '_');
  const header = () => <img alt="Card" src={company} className={'border-round border-noround-bottom'} />;

  return <Card header={header} title={ucfirst(trans(t, 'company'))} subTitle={data.raisonSociale ?? data.nomCommercial ?? null} className={classNames('a8-search-item mb-4 mx-1')} onClick={() => {
    navigate(redirectUri);
    setVisibleFullScreen(false);
  }}>
      <ul className={'m-0 pl-4'}>
        <li>{ucfirst(trans(t, 'type'))}: {data.type}</li>
        <li>{ucfirst(trans(t, 'ent.siren'))}: {formatSiren(data.siren)}</li>
        <li>{ucfirst(trans(t, 'ent.capital'))}: {shortNumber(data.capital)}</li>
        <li>{ucfirst(trans(t, 'ent.rcsNumber'))}: {data.rcsCity}</li>
      </ul>
    </Card>
  ;
};
