import React, { useEffect, useState } from 'react';
import { Skeleton } from 'primereact/skeleton';
import { AxiosResponse } from 'axios';

import { IRequestParams } from 'services';
import { iri } from 'utilities';

export const MissionHypothesisLabel = (props: any) => {
  const { missionId, apim } = props;

  const [missionHypothesisLabel, setMissionHypothesisLabel] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    setLoading(true);

    apim.fetchEntities({
      resourceType: 'missionHypothesis',
      params: [{label: 'mission', value: iri('missions', missionId)},  {label: 'valid', value: true}],
      notif: false,
      success: (res : AxiosResponse) => {
        if (res?.data['hydra:member'][0] !== undefined && res?.data['hydra:member'][0].label !== undefined) {
          setMissionHypothesisLabel(res.data['hydra:member'][0].label)
        } else {
          setMissionHypothesisLabel('N/A');
        }
      },
      setLoading: setLoading,
      error: () => setMissionHypothesisLabel('N/A')
    } as IRequestParams).then();
  }, [missionId]); // eslint-disable-line react-hooks/exhaustive-deps

  return loading ? <Skeleton width={'8rem'} height={'1rem'}></Skeleton> : <span className={'titre'}>{missionHypothesisLabel}</span>;
};
