import React, { useEffect, useState } from 'react';
import { AxiosResponse } from 'axios';
import { Link } from 'react-router-dom';

import { Button } from 'primereact/button';
import { InputSwitch} from 'primereact/inputswitch';
import { Panel } from 'primereact/panel';
import { Toolbar} from 'primereact/toolbar';
import { classNames } from 'primereact/utils';

import { dateAgoString, trans, ucfirst, uuidFromIri } from 'utilities';
import { useAppState } from 'states';
import { IRequestParams, isAdmin, isClient, isExpert, isExpertAdmin, useApim, userId }  from 'services';
import { PageLoader, Userbadge } from 'components';
import { INotification, INotificationsGroup } from 'interfaces';
import { UncontrolledStaticListField } from 'forms';

import moment from 'moment';
import appUri from 'config/appUri.json';

export const NotificationsSentList = () => {
  const apim = useApim();
  const appState = useAppState();

  const { t } = apim.di();

  const itemsPerPage = 20;
  const [loading, setLoading] = useState<boolean>(false);
  const [groupTotal, setGroupTotal] = useState<number>(5);
  const [notificationTotal, setNotificationTotal] = useState<number>(itemsPerPage);

  const [notificationsGroups, setNotificationsGroups] = useState<INotificationsGroup[] | null>(null);
  const [page, setPage] = useState<number>(1);

  // Filter by read/done statuses.
  const [showOnlyUnread, setShowOnlyUnread] = useState<boolean>(false);
  const [showOnlyUndone, setShowOnlyUndone] = useState<boolean>(true);

  // Filter by dossier.
  const [fDossier, setFDossier] = useState<string>('all');
  const [fDossierOptionsLoading, setFDossierOptionsLoading] = useState<boolean>(false);
  const [fDossierOptions, setFDossierOptions] = useState<any[]>([{label: ucfirst(trans(t, 'allDossiers')), id: 'all', dossier: null}]);
  const [dossiers, setDossiers] = useState<any[]>([]);

  // Filter by period.
  const [fPeriod, setFPeriod] = useState<string>('all');
  const fPeriodOptions = [
    {code: 'all', label: trans(t, 'period_all')},
    {code: 'last_12_months', label: trans(t, 'period_last_12_months')},
    {code: 'last_6_months', label: trans(t, 'period_last_6_months')},
    {code: 'last_30_days', label: trans(t, 'period_last_30_days')},
    {code: 'last_trimester', label: trans(t, 'period_last_trimester')},
    {code: 'last_month', label: trans(t, 'period_last_month')},
    {code: 'last_week', label: trans(t, 'period_last_week')},
    {code: 'last_year', label: trans(t, 'period_last_year')},
    {code: 'this_year', label: trans(t, 'period_this_year')},
    {code: 'this_month', label: trans(t, 'period_this_month')},
    {code: 'this_trimester', label: trans(t, 'period_this_trimester')}
  ];

  // Update Page Header.
  useEffect(() => {
    appState.setPageTitle(trans(t, 'menu|pages.title.notifications_sent'));
    appState.setBreadcrumb([{label: trans(t, 'notification_sent', 2)}]);
    appState.setPageActions([]);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  // Load dossiers filter.
  useEffect(() => {
    setFDossierOptionsLoading(true);

    apim.fetchEntities({
      resourceType: 'notificationDossiers',
      notif: false,
      success: (res: AxiosResponse) => {
        const _dossierOptions: any[] = [{label: ucfirst(trans(t, 'allDossiers')), id: 'all'}];
        const _dossiers: any[] = [];

        res?.data?.['hydra:member']?.map((dossier: any) => {
          _dossierOptions.push({
            label: dossier.identifier,
            id: dossier.id,
          });

          _dossiers.push(dossier);

          return null;
        });

        setFDossierOptions(_dossierOptions);
        setDossiers(_dossiers);
        setFDossierOptionsLoading(false);
      }
    } as IRequestParams).then();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  // Load initial notifications.
  useEffect(() => {
    if (!fDossierOptionsLoading) {
      loadMore(page, true);
    }
  }, [fDossierOptionsLoading, showOnlyUnread, showOnlyUndone, fPeriod, fDossier]); // eslint-disable-line react-hooks/exhaustive-deps

  const loadMore = (pageNumber: number, initialCall: boolean) => {
    if ((notificationsGroups && notificationsGroups?.length < groupTotal) || initialCall) {
      setLoading(true);

      const params = [
        {label: 'author', value: userId()},
        {label: 'order[created]', value: 'DESC'},
        {label: 'active', value: 'true'},
        {label: 'itemsPerPage', value: itemsPerPage},
        {label: 'page', value: pageNumber},
        {label: 'expand[]', value: 'notification_context:read_notifications'},
        {label: 'expand[]', value: 'notification:read'}
      ];

      if (showOnlyUnread) {
        params.push({label: 'read', value: 'false'});
      }

      if (showOnlyUndone) {
        params.push({label: 'done', value: 'false'});
      }

      if (fDossier !== 'all') {
        params.push({label: 'dossier', value: fDossier});
      }

      if (fPeriod !== 'all') {
        const now = moment();

        switch (fPeriod) {
          case 'last_12_months':
            params.push({label: 'created[before]', value: now.toISOString()});
            params.push({label: 'created[after]', value: now.subtract(12, 'M').toISOString()});
            break;

          case 'last_6_months':
            params.push({label: 'created[before]', value: now.toISOString()});
            params.push({label: 'created[after]', value: now.subtract(6, 'M').toISOString()});
            break;

          case 'last_30_days':
            params.push({label: 'created[before]', value: now.toISOString()});
            params.push({label: 'created[after]', value: now.subtract(30, 'd').toISOString()});
            break;

          case 'last_trimester':
            params.push({label: 'created[before]', value: now.toISOString()});
            params.push({label: 'created[after]', value: now.subtract(3, 'M').toISOString()});
            break;

          case 'last_month':
            params.push({label: 'created[before]', value: now.toISOString()});
            params.push({label: 'created[after]', value: now.subtract(1, 'M').toISOString()});
            break;

          case 'last_week':
            params.push({label: 'created[before]', value: now.toISOString()});
            params.push({label: 'created[after]', value: now.subtract(1, 'w').toISOString()});
            break;

          case 'last_year':
            params.push({label: 'created[before]', value: now.toISOString()});
            params.push({label: 'created[after]', value: now.subtract(1, 'y').toISOString()});
            break;

          case 'this_year':
            params.push({label: 'created[before]', value: now.toISOString()});
            params.push({label: 'created[after]', value: moment().startOf('year').toISOString()});
            break;

          case 'this_month':
            params.push({label: 'created[before]', value: now.toISOString()});
            params.push({label: 'created[after]', value: moment().startOf('month').toISOString()});
            break;

          case 'this_trimester':
            params.push({label: 'created[before]', value: now.toISOString()});
            params.push({label: 'created[after]', value: moment().startOf('quarter').toISOString()});
            break;
        }
      }

      apim.fetchEntities({
        cache: false,
        notif: false,
        params,
        resourceType: 'notificationContexts',
        success: (res: AxiosResponse) => {
          if (!res?.data || !res.data?.['hydra:member']) return;

          setGroupTotal(res.data?.['hydra:totalItems']);

          const filterNotificationsGroups = (filter: (notification: INotification) => boolean) => {
            if (initialCall || notificationsGroups) {
              const n = initialCall ? (res.data['hydra:member'] ?? []) : notificationsGroups ? [...notificationsGroups, ...(res.data['hydra:member'] ?? [])] : [];
              return n.reduce((acc: INotificationsGroup[], group: INotificationsGroup) => {
                const filteredNotifications = group.notifications?.filter(filter);
                if (filteredNotifications.length > 0) {
                  acc.push({ ...group, notifications: filteredNotifications });
                }
                return acc;
              }, []);
            }
            return  []

          };

          if (showOnlyUndone && showOnlyUnread) {
            setNotificationsGroups(filterNotificationsGroups((notification: INotification) => notification.done === false && notification.read === false));
          } else if (showOnlyUnread) {
            setNotificationsGroups(filterNotificationsGroups((notification: INotification) => notification.read === false));
          } else if (showOnlyUndone) {
            setNotificationsGroups(filterNotificationsGroups((notification: INotification) =>  notification.done === false));
          } else {
            setNotificationsGroups(initialCall ? (res.data['hydra:member'] ?? []) : notificationsGroups ? [...notificationsGroups, ...(res.data['hydra:member'] ?? [])] : []);
          }

          apim.fetchEntities({
            resourceType: 'notifications',
            params,
            notif: false,
            cache: false,
            success: (res: AxiosResponse) => {
              if (!res?.data) return;
              setNotificationTotal(res.data?.['hydra:totalItems']);
              setLoading(false);
            }
          } as IRequestParams).then();
        }
      } as IRequestParams).then();
    }
  }

  const itemTemplate = (notificationsGroup: INotificationsGroup) => {
    const expandDossier = (dossier: string | null): any => {
      let notificationDossier: any = null;

      dossiers.forEach((d: any) => {
        if (uuidFromIri(d['@id']) === uuidFromIri(dossier)) {
          notificationDossier = d;
        }
      });

      return notificationDossier;
    };

    const notificationHeaderTemplate = (options: any, notification: INotification) => {
      const className = `${options.className} justify-content-space-between h-3rem`;

      return (
        <div className={className}>
          <div className={'flex align-items-center gap-3 flex-auto'}>
            {notification.title && (
              <span className={classNames('a8-notification-title mt-1 mb-1 font-bold')} style={notification.read ? {color: 'grey'} : {}}>{notification.title}</span>
            )}
            <small className={`${options.className} flex flex-wrap flex-auto align-items-center justify-content-end gap-1`}>
              <span style={notification.read ? {color: 'grey'} : {}}>{ucfirst(trans(t, 'sent'))} {dateAgoString(notification.created)}</span>
            </small>
          </div>
        </div>
      );
    };

    const groupHeaderTemplate = (options: any, notificationsGroup: INotificationsGroup) => {
      const className = `${options.className} justify-content-space-between h-3rem`;

      return (
        <div className={className}>
          <div className={'flex align-items-center gap-3 flex-auto'}>
            {notificationsGroup?.dossierUnique === false && !isClient() && (
              <Button size={'small'} label={ucfirst(trans(t, 'multipleDossiers'))}/>
            )}
            {notificationsGroup.title && (
              <span className={classNames('a8-notification-title text-lg mt-1 mb-1 text-primary font-bold')} style={notificationsGroup.read ? {color: 'grey'} : {}}>{notificationsGroup.title}</span>
            )}
            <small className={`${options.className} flex flex-wrap flex-auto align-items-center justify-content-end gap-1`}>
              <span>{ucfirst(trans(t, 'sent'))} {dateAgoString(notificationsGroup.created)}</span>
            </small>
          </div>
        </div>
      );
    };

    const footerTemplate = (options: any, notification: INotification | INotificationsGroup) => {
      const detailedDossier = expandDossier(notification?.dossier ?? null);

      return <div className={`${options.className} p-0 pt-3`}>
        <div className={`flex flex-wrap align-items-center justify-content-start gap-1 border-top-1 border-200 text-sm px-3 py-2`}>
          <span style={notification.read ? {color: 'grey'} : {}} className={'pr-1'}>{ucfirst(trans(t, 'sent_to'))}</span>
          <Userbadge apim={apim} user={notification.user} size={'normal'} shape={'circle'} style={notification.read ? {color: 'grey'} : {color: 'black'}}/>
          {detailedDossier !== undefined && detailedDossier !== null && (
            <>
              <span style={notification.read ? {color: 'grey'} : {}}>{trans(t, 'on_dossier')}</span>
              <Link className={'font-bold'} to={appUri.dos.page.replace(':id', uuidFromIri(notificationsGroup?.dossier))}>
                {detailedDossier.name}
              </Link>
            </>
          )}

          <div className={'flex flex-row flex-auto justify-content-end gap-2'}>
            {notification.read ? (
              <span className={'flex flex-row align-items-center justify-content-end gap-1'}>
                <i className={'pi pi-check text-xs text-green-600'}/> <span style={{color: 'grey'}}>{ucfirst(trans(t, 'read'))}</span>
              </span>
            ) : (
              <span className={'flex flex-row align-items-center justify-content-end gap-1'}>
                <i className={'pi pi-times text-xs text-red-600'}/> <span style={{color: 'black'}}>{ucfirst(trans(t, 'not_read'))}</span>
              </span>
            )}

            {notification.done ? (
              <span className={'flex flex-row align-items-center justify-content-end gap-1'}>
                <i className={'pi pi-check text-xs text-green-600'}/> <span style={{color: 'grey'}}>{ucfirst(trans(t, 'done'))}</span>
              </span>
            ) : (
              <span className={'flex flex-row align-items-center justify-content-end gap-1'}>
                <i className={'pi pi-times text-xs text-red-600'}/> <span style={{color: 'black'}}>{ucfirst(trans(t, 'not_done'))}</span>
              </span>
            )}
          </div>
        </div>
      </div>;
    }

    const renderNotification = (notification: INotification, single: boolean) => (
      <Panel
        key={notification.id} className={`mb-3 a8-notification-${notification.id}`}
        headerTemplate={(options) => notificationHeaderTemplate({...options, dossierUnique: notificationsGroup.dossierUnique, single}, notification)}
        footerTemplate={(options) => footerTemplate(options, notification)}>
        {notification.content && (
          <div
            style={notification.read ? {color: 'grey'} : {}}
            className={'a8-notification-content text-sm pt-2'}
            dangerouslySetInnerHTML={{__html: notification.content}}
          />
        )}
      </Panel>
    );

    return (
      <>
        {(notificationsGroup?.notifications?.length > 1 || (notificationsGroup?.title !== null && notificationsGroup?.title?.length > 0)) ? (
          <Panel key={notificationsGroup.id} className={'mb-3 a8-notification-' + notificationsGroup.id}
                 headerTemplate={(options) => groupHeaderTemplate(options, notificationsGroup)}
                 toggleable collapsed={notificationsGroup.done || notificationsGroup.read || false}>
            {notificationsGroup.content && (
              <div
                style={notificationsGroup.read ? {color: 'grey'} : {}}
                className={'a8-notification-content text-sm pt-2'}
                dangerouslySetInnerHTML={{ __html: notificationsGroup.content }}
              />
            )}
            {notificationsGroup?.notifications?.map((notification: INotification)=> (
              renderNotification(notification, false)
            ))}
          </Panel>
        ) : (
          notificationsGroup?.notifications?.map((notification: INotification) => (
            renderNotification(notification, true)
          ))
        )}
      </>
    );
  };

  const startContent = () => {
    return <div className={'flex flex-row gap-3 align-items-center'}>
      {(isExpert() || isExpertAdmin() || isAdmin()) && (
        <UncontrolledStaticListField fieldKey={'dossier'} onFieldChange={(value: any) => setFDossier(value)} label={trans(t, 'dossier')}
                                     placeholder={ucfirst(trans(t, 'form|select_dossier'))} value={fDossier} listsOptions={{dossiers: fDossierOptions}} listKey={'dossiers'} optionLabel={'label'} optionValue={'id'}/>
      )}
      <UncontrolledStaticListField fieldKey={'period'} onFieldChange={(value: any) => setFPeriod(value || 'all')} label={trans(t, 'period')}
                                   placeholder={ucfirst(trans(t, 'form|select_period'))} value={fPeriod} listsOptions={{periods: fPeriodOptions}} listKey={'periods'} optionLabel={'label'} optionValue={'code'}/>
    </div>;
  }

  const endContent = () => {
    return <div className={'flex flex-row gap-5 align-items-center'}>
      <div className={'flex flex-row gap-3 align-items-end'}>
        <div className={'flex flex-row gap-3 align-items-center'}>
          <label htmlFor={'a8-notifications-toggle-unread'}>{ucfirst(trans(t, 'displayOnlyUnreadNotifications'))}</label>
          <InputSwitch checked={showOnlyUnread} inputId={'a8-notifications-toggle-unread'}
                       onChange={(e) => {
                         setPage(1);
                         setShowOnlyUnread(e.value);
                       }}/>

        </div>
        <div className={'flex flex-row gap-3 align-items-center'}>
          <label htmlFor={'a8-notifications-toggle-undone'}>{ucfirst(trans(t, 'displayOnlyUndoneNotifications'))}</label>
          <InputSwitch checked={showOnlyUndone} inputId={'a8-notifications-toggle-undone'}
                       onChange={(e) => {
                         setPage(1);
                         setShowOnlyUndone(e.value);
                       }}/>
        </div>
      </div>
    </div>;
  }

  const loadNextPage = () => {
    setPage(prevPage => {
      const newPage = prevPage + 1;
      loadMore(newPage, false);
      return newPage;
    });
  };

  return <>
    <Toolbar start={startContent} end={endContent} className={'mb-3 border-round'} />
    {(notificationsGroups?.length === 0 && loading) || notificationsGroups === null || loading ? (
      <PageLoader />
    ) : (
      notificationsGroups && notificationsGroups?.length > 0 ? (
        <>
          <p className={'text-sm'}>{ucfirst(trans(t, 'youHaveNotificationSent', notificationTotal))}</p>
          {notificationsGroups.sort((a: INotificationsGroup, b: INotificationsGroup) => (a?.created ?? '') > (b?.created ?? '') ? -1 : 1).map((notificationGroup: INotificationsGroup) =>(
            <div key={notificationGroup.id}>
              {itemTemplate(notificationGroup)}
            </div>))
          }
          {notificationsGroups?.length < groupTotal && (
            loading ? (
              <PageLoader />
            ) : (
              <div className={'flex flex-row gap-3 justify-content-center p-5'}>
                <Button label={ucfirst(trans(t, 'seeMoreNotifications'))} onClick={loadNextPage} />
              </div>
            )
          )}
        </>
      ) : (
        <div className={'flex flex-row gap-3 justify-content-center p-8'}>
          <p className={'font-bold text-xl'}>{ucfirst(trans(t, 'emptyNotifications'))}</p>
        </div>
      )
    )}
  </>
};
