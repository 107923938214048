import React from 'react';
import { isValidUUID, trans, ucfirst } from 'utilities';
import { APIAutocompleteField, CheckboxField, EditorField, MaskField, StaticListField, TextField } from 'forms';
import { isAdmin } from 'services';

export const Documents = (props: any) => {
  const { t, control, onFieldChange, errorMessage, context, getValues } = props;

  return <div className={'pt-4 flex flex-row flex-wrap px-6 gap-2'}>
    <StaticListField fieldKey={'type'} listKey={'types'} listsOptions={{types: context?.types}} control={control} label={ucfirst(trans(t, 'menu|tag.filetype'))}
                     optionLabel={'name'} optionValue={'id'} errorMessage={errorMessage} onFieldChange={onFieldChange} classes={'w-8 flex-grow-1'} multiples={false} filter={true}/>
    <MaskField fieldKey={'date'} control={control} onFieldChange={onFieldChange} errorMessage={errorMessage} isDate label={ucfirst(trans(t, 'date'))} classes={'flex-grow-1'}/>
    <TextField fieldKey={'title'} control={control} onFieldChange={onFieldChange} errorMessage={errorMessage} isDate label={ucfirst(trans(t, 'title'))} classes={'w-full'}/>
    <APIAutocompleteField fieldKey={'dossiers'} control={control} onFieldChange={onFieldChange} errorMessage={errorMessage} getValues={getValues} label={trans(t, 'dossier', 2)} resourceType={'dossiersIndex'}
                          placeholder={ucfirst(trans(t, 'form|select_dossier'))} optionKey={'name'} itemTemplate={context?.dossiersEditorItemTemplate} multiple classes={'w-full'} params={isValidUUID(context?.company?.id) ? [{label: 'companies.id', value: context?.company?.id}] : []} />
    <APIAutocompleteField fieldKey={'companies'} control={control} onFieldChange={onFieldChange} multiple errorMessage={errorMessage} getValues={getValues} classes={'w-full'} placeholder={ucfirst(trans(t, 'form|select_company'))}
                          optionKey={'name'} itemTemplate={context?.companiesEditorItemTemplate} label={trans(t, 'company', 2)} resourceType={'dossierCompaniesIndex'} params={isValidUUID(context?.dossier?.id) ? [{label: 'dossiers.id', value: context?.dossier?.id}] : []} />
    <EditorField fieldKey={'description'} control={control} onFieldChange={onFieldChange} errorMessage={errorMessage} label={trans(t, 'description')} classes={'w-full'}/>
    {isAdmin() && (
        <CheckboxField fieldKey={'active'} control={control} onFieldChange={onFieldChange} errorMessage={errorMessage} label={ucfirst(trans(t, 'active'))}/>
    )}
  </div>;
}
