import React from 'react';
import { Column } from 'primereact/column';

import { trans } from 'utilities';
import { DatatableWrapper, textEditor } from 'components';
import { useUserState } from 'states';

export const ShortcutsDatatable = (props: any) => {
  const { tableKey, title, lazyConfig, apim } = props;
  const userState = useUserState();
  const { t, navigate } = apim.di();

  const lazyC = {...{sortField: 'name', sortOrder: 1}, ...lazyConfig};
  const onRowClick = (row: any) => navigate(row?.uri);

  return (
    <DatatableWrapper resourceType={'shortcuts'} tableKey={tableKey || 'shortcuts'} lazyConfig={lazyC} noFilters noAdd
                      title={title || trans(t, 'menu|pages.title.shortcuts')} editMode onRowClick={onRowClick} params={[{label: 'user', value: userState.id()}]}>
      <Column field={'name'} header={trans(t, 'shortcut')} editor={(options) => textEditor(options, {label: trans(t, 'shortcut')})} />
      <Column field={'uri'} header={trans(t, 'url')} editor={(options) => textEditor(options, {label: trans(t, 'url')})} />
    </DatatableWrapper>
  );
};
