import React, { useEffect, useState } from 'react';

import { Skeleton } from 'primereact/skeleton';
import { AxiosResponse } from 'axios';
import { Avatar } from 'primereact/avatar';

import { uuidFromIri } from 'utilities';

import { IRequestParams } from 'services';

import appUri from 'config/appUri.json';

export const Userpicture = (props: any) => {
  const { user, userId, userRoles, userPictureUrl, userInitials, userFirstName, userLastName, apim, size, shape } = props;
  const { navigate } = apim.di();

  let userIdentifier = userId;
  if (user !== null && user !== undefined) {
    userIdentifier = uuidFromIri(user);
  }

  let processedUserInitials = userInitials;
  if (userInitials === undefined || userInitials === null) {
    if (userFirstName !== undefined && userFirstName !== null
      && userLastName !== undefined && userLastName !== null) {
      processedUserInitials = userFirstName.substring(0, 1) + userLastName.substring(0, 1);
    }
  }

  const getRedirectUri = (userIdentifier: any, userRoles: any) => {
    let value = appUri.usr.page.replace(':id', userIdentifier);

    if (userRoles !== null && userRoles !== undefined) {
      if (userRoles.includes('ROLE_ADMIN') || userRoles.includes('ROLE_EXPERT')) {
        value = appUri.usr.exp.page.replace(':id', userIdentifier);
      }
    }

    return value;
  }

  const [redirectUri, setRedirectUri] = useState<string>(getRedirectUri(userIdentifier, userRoles));
  const [initials, setInitials] = useState<string>(processedUserInitials);
  const [picture, setPicture] = useState<string>(userPictureUrl);
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    if (picture === undefined) {
      setLoading(true);

      apim.fetchEntity({
        resourceType: 'users',
        id: userIdentifier,
        setLoading: setLoading,
        notif: false,
        error: () => setInitials('?'),
        success: (res: AxiosResponse) => {
          if (!res?.data) return;

          if (res.data['picture'] !== undefined) {
            setPicture(res.data['picture']);
          }

          if (res.data['initials'] !== undefined) {
            setInitials(res.data['initials']);
          } else if (res.data['firstName'] !== undefined && res.data['lastName'] !== undefined) {
            setInitials(res.data['firstName'].substring(0, 1) + res.data['lastName'].substring(0, 1));
          } else {
            setInitials('?')
          }

          if (res.data['roles'] !== null && res.data['roles'] !== undefined) {
            if (res.data['roles'].includes('ROLE_ADMIN') || res.data['roles'].includes('ROLE_EXPERT')) {
              setRedirectUri(getRedirectUri(userIdentifier, res.data['roles']));
            }
          }
        }
      } as IRequestParams).then();
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return <>
    {loading ? (
      <Skeleton shape="circle" size="3rem"></Skeleton>
    ) : (
      picture !== undefined && picture !== null && picture.length > 0 ? (
        <Avatar label={initials} size={size ?? 'large'} shape={shape ?? 'circle'} image={picture} onClick={() => navigate(redirectUri) } />
      ) : (
        <Avatar label={initials} size={size ?? 'large'} shape={shape ?? 'circle'} onClick={() => navigate(redirectUri) } />
      )
    )}
  </>;
};
