import React from 'react';
import { Fieldset } from 'primereact/fieldset';

import { EditorField } from 'forms';
import { trans, ucfirst } from 'utilities';

export const ZoneDeNoteForm = (props: any) => {
  const { t, control, onFieldChange, errorMessage } = props;

  return <>
    <Fieldset legend={ucfirst(trans(t, 'form|zone_note'))} className={'col-12 m-2 mt-5'} toggleable>
      <EditorField fieldKey={'description'} control={control} onFieldChange={onFieldChange} errorMessage={errorMessage}
                   label={trans(t, 'form|description')} classes={'w-full mb-0'} height={'100px'} hideLabel={true}/>
    </Fieldset>
  </>;
};
