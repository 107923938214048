import React from 'react';
import { trans } from 'utilities';
import { NumberField } from 'forms';

export const TresorerieGeneraleForm = (props: any) => {
  const { t, control, onFieldChange, errorMessage } = props;
  const fields: any[] = [
    {key: 'tresorerie'},
    {key: 'excedentStructurel'},
    {key: 'montantPlace'},
    {key: 'duClient'},
    {key: 'dontHorsDelai', tooltip: trans(t, 'form|tooltip.tresorerie.dontHorsDelai')},
    {key: 'perteInduite'}
  ];

  return <>
    {fields.map((field: any, index: number) => (
      <NumberField key={index} fieldKey={field.key} mode={field.mode || 'currency'} control={control}
                   addon={field.addon || 'euro'} addonPosition={'after'} onFieldChange={onFieldChange}
                   errorMessage={errorMessage} label={trans(t, 'chart|tresorerie.' + field.key)}
                   tooltip={field.tooltip} />
    ))}
  </>;
};
