import React from 'react';
import { AppTopbarApps, AppTopbarNotifications, AppTopbarSearch, AppTopbarUser } from '.';

export const AppTopbarRight = (props: any) => {
  const { appState, apim, userState, searchState, themeState } = props;

  return (
    <ul className={'layout-topbar-items'}>
      <li className={'layout-topbar-item layout-search-item'}>
        <AppTopbarSearch searchState={searchState} apim={apim} />
      </li>

      <li className={'layout-topbar-item notifications'}>
        <AppTopbarNotifications />
      </li>

      <li className={'layout-topbar-item app'}>
        <AppTopbarApps apim={apim} appState={appState} themeState={themeState} />
      </li>
      <li className={'layout-topbar-item profile'}>
        <AppTopbarUser apim={apim} userState={userState} themeState={themeState} />
      </li>

      <li className={'layout-topbar-item menu-right'}>
        <button type={'button'} className={'layout-topbar-action rounded-circle p-link'} onClick={() => themeState.toggleRightMenu()}>
          <i className={'pi fs-large pi-arrow-right'}></i>
        </button>
      </li>
    </ul>
  );
};
