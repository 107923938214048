import React, { useEffect } from 'react';

import { trans, ucfirst } from 'utilities';
import { useAppState, useUserState } from 'states';
import { isSuperAdmin, useApim } from 'services';
import { PersonnesPhysiquesDatatable } from 'components';
import { Unauthorized } from 'pages';

import appUri from 'config/appUri.json';

export const PersonnesPhysiquesList = () => {
  const apim = useApim();
  const { t } = apim.di();
  const appState = useAppState();
  const userState = useUserState();

  // Update Page Header.
  useEffect(() => {
    appState.setPageTitle(trans(t, 'menu|pages.title.user.personnes'));
    appState.setBreadcrumb([{ label: ucfirst(trans(t, 'personnePhysique', 2)) }]);

    const newPA: any[] = [];
      newPA.push({ label: trans(t, 'pers.add'), icon: 'plus', to: appUri.cie.per.phy.add, className:'bg-green-500'});
    appState.setPageActions(newPA);
  }, [userState.id()]); // eslint-disable-line react-hooks/exhaustive-deps

  if (!isSuperAdmin()) return <Unauthorized asBlock/>;

  return <PersonnesPhysiquesDatatable apim={apim} />;
};
