import React, { useEffect, useState } from 'react';
import { escapeValue, trans, ucfirst } from 'utilities';
import { CountryField, StaticListField, TextField } from 'forms';
import { useFormState } from 'states';

const codesPostaux = require('codes-postaux');

export const Address = (props: any) => {
  const { t, control, onFieldChange, errorMessage, parentKey, getValues, setValue, formKey } = props;
  const fields: any[] = [{key: 'address1', tradKey: 'address'}, {key: 'address2', tradKey: 'complement'}, {key: 'postalCode'}];
  const { postalCode, city } = getValues();
  const [citiesArray, setCitiesArray] = useState<any[]>([]);
  const formState = useFormState();

  useEffect(() => setCitiesArray(codesPostaux.find(postalCode) ?? []), [postalCode]); // eslint-disable-line react-hooks/exhaustive-deps
  useEffect(() => {
    if (citiesArray.length === 1 && city?.upperCase !== citiesArray[0].libelleAcheminement?.upperCase) {
      formState.setFieldData(formKey, 'city', parentKey, escapeValue(citiesArray[0].libelleAcheminement, 'string'));
      setValue('city', citiesArray[0].libelleAcheminement);
    }
  }, [citiesArray]); // eslint-disable-line react-hooks/exhaustive-deps

  const cityOptions = citiesArray.map((option: any) => ({
    label: option.libelleAcheminement,
    value: option.libelleAcheminement
  }));

  return <>
    {fields.map((field: any, index: number) => (
      <TextField key={index} fieldKey={field.key} control={control} onFieldChange={onFieldChange}
                 errorMessage={errorMessage} label={trans(t, field.tradKey || field.key)} tooltip={field.tooltip}/>
    ))}
    <StaticListField listKey={'cities'} listsOptions={{cities:cityOptions}} fieldKey={'city'} control={control} label={ucfirst(trans(t, 'city'))} optionLabel={'label'} errorMessage={errorMessage} onFieldChange={onFieldChange} editable={true}/>
    <CountryField fieldKey={'country'} control={control} onFieldChange={onFieldChange} errorMessage={errorMessage} label={trans(t, 'country')}/>
  </>
};
