import React from 'react';
import { Divider } from 'primereact/divider';
import { Fieldset } from 'primereact/fieldset';

import { APIListField, CheckboxField, MaskField, NumberField, StaticListAutocompleteField, TextField } from 'forms';
import { trans, ucfirst } from 'utilities';

export const RetraiteForm = (props: any) => {
  const { t, control, onFieldChange, errorMessage, getValues, listsOptions } = props;
  const { denouementType } = getValues();

  return <>
    <Fieldset legend={ucfirst(trans(t, 'general'))} className={'col-12 m-2'} toggleable>
      <div className={'grid w-full'}>
        <div className={'col-3'}>
          <TextField fieldKey={'label'} control={control} onFieldChange={onFieldChange} errorMessage={errorMessage}
                     label={trans(t, 'form|patrimoine.contracts.default.label')} classes={'w-full'}/>
        </div>
        <div className={'col-3'}>
          <TextField fieldKey={'number'} control={control} onFieldChange={onFieldChange} errorMessage={errorMessage}
                     label={trans(t, 'form|patrimoine.contracts.default.number')} classes={'w-full'}/>
        </div>
        <div className={'col-3'}>
          <APIListField listKey={'contractRetraiteTypes'} fieldKey={'type'} control={control} onFieldChange={onFieldChange} errorMessage={errorMessage}
                        label={trans(t, 'form|patrimoine.contracts.default.type')} classes={'w-full'}/>
        </div>
      </div>

      <div className={'grid w-full'}>
        <div className={'col-3'}>
          <StaticListAutocompleteField classes={'w-full'} listKey={'banks'} listsOptions={listsOptions} fieldKey={'bank'} control={control} optionLabel={'name'} optionValue={'id'}
                                       onFieldChange={onFieldChange} errorMessage={errorMessage} label={trans(t, 'form|patrimoine.contracts.default.bank')}
                                       rules={{required: trans(t, 'form|requiredField')}}/>
        </div>
        <div className={'col-3'}>
          <MaskField fieldKey={'openingDate'} label={trans(t, 'form|patrimoine.contracts.default.openingDate')}
                     control={control} onFieldChange={onFieldChange} errorMessage={errorMessage} isDate classes={'w-full'}/>
        </div>
      </div>

      <div className={'grid w-full'}>
        <div className={'col-3'}>
          <CheckboxField fieldKey={'avantageMatrimonialPreciput'} control={control} onFieldChange={onFieldChange} errorMessage={errorMessage} classes={'w-full'}
                         label={trans(t, 'form|patrimoine.contracts.default.avantageMatrimonialPreciput')}/>
        </div>
        <div className={'col-3'}>
          <CheckboxField fieldKey={'denouementSecondDeces'} control={control} onFieldChange={onFieldChange} errorMessage={errorMessage} classes={'w-full'}
                         label={trans(t, 'form|patrimoine.contracts.default.denouementSecondDeces')}/>
        </div>
      </div>
    </Fieldset>

    <Fieldset legend={ucfirst(trans(t, 'economic'))} className={'col-12 m-2'} toggleable>
      <div className={'grid w-full'}>
        <div className={'col-3'}>
          <NumberField fieldKey={'montantFondEuro'} control={control} onFieldChange={onFieldChange} errorMessage={errorMessage}
                       label={trans(t, 'form|patrimoine.contracts.default.montantFondEuro')} addon={'euro'} addonPosition={'after'} classes={'w-full'}/>
        </div>
        <div className={'col-3'}>
          <NumberField fieldKey={'montantFondUniteCompte'} control={control} onFieldChange={onFieldChange} errorMessage={errorMessage}
                       label={trans(t, 'form|patrimoine.contracts.default.montantFondUniteCompte')} addon={'euro'} addonPosition={'after'} classes={'w-full'}/>
        </div>
        <div className={'col-3'}>
          <NumberField fieldKey={'montantFondUniteCompteImmobilier'} control={control} onFieldChange={onFieldChange} errorMessage={errorMessage}
                       label={trans(t, 'form|patrimoine.contracts.default.montantFondUniteCompteImmobilier')} addon={'euro'} addonPosition={'after'} classes={'w-full'}/>
        </div>
      </div>

      <div className={'grid w-full mt-5'}>

        <div className={'col-5'}>
          <h6>{trans(t, 'form|patrimoine.contracts.default.phaseEpargne')}</h6>
          <div className={'grid w-full'}>
            <div className={'col-6'}>
              <NumberField fieldKey={'montantVersementsAnnuelsPerso'} control={control} onFieldChange={onFieldChange} errorMessage={errorMessage}
                           label={trans(t, 'form|patrimoine.contracts.default.montantVersementsAnnuelsPerso')} addon={'euro'} addonPosition={'after'} classes={'w-full'}/>

            </div>
            <div className={'col-6'}>
              <NumberField fieldKey={'montantVersementsAnnuelsPro'} control={control} onFieldChange={onFieldChange} errorMessage={errorMessage}
                           label={trans(t, 'form|patrimoine.contracts.default.montantVersementsAnnuelsPro')} addon={'euro'} addonPosition={'after'} classes={'w-full'}/>
            </div>
          </div>
        </div>

        <div className={'col-2'}>
          <Divider layout={'vertical'}>
            <i className={'pi pi-arrow-right'} style={{color: 'var(--primary-color)', fontSize: '2rem'}}/>
          </Divider>
        </div>

        <div className={'col-5'}>
          <h6>{trans(t, 'form|patrimoine.contracts.default.phaseRetraite')}</h6>
          <div className={'grid w-full'}>
            <div className={'col-6'}>
              <MaskField fieldKey={'closingDate'} label={trans(t, 'form|patrimoine.contracts.default.denouementDate')}
                         control={control} onFieldChange={onFieldChange} errorMessage={errorMessage} isDate classes={'w-full'}/>
            </div>
            <div className={'col-6'}>
              <APIListField listKey={'contractDenouementTypes'} fieldKey={'denouementType'} control={control} onFieldChange={onFieldChange} errorMessage={errorMessage}
                            label={trans(t, 'form|patrimoine.contracts.default.denouementType')} classes={'w-full'}/>
            </div>
            <div className={'col-6'}>
              <NumberField fieldKey={'montantRenteAnnuelle'} control={control} onFieldChange={onFieldChange} errorMessage={errorMessage}
                           addon={'euro'} addonPosition={'after'} classes={'w-full'}
                           label={trans(t,  denouementType !== 'capital' ? 'form|patrimoine.contracts.default.montantRenteAnnuelle' : 'form|patrimoine.contracts.default.capitalDisponible')}/>
            </div>
          </div>
        </div>
      </div>
    </Fieldset>
  </>;
};
