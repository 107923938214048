import React, { useCallback, useEffect, useState } from 'react';

import { Button } from 'primereact/button';
import { Column } from 'primereact/column';
import { Dropdown } from 'primereact/dropdown';
import { Tooltip } from 'primereact/tooltip';
import { AxiosResponse } from 'axios';

import { IRequestParams, isAdmin } from 'services';
import { FormWrapper } from 'forms';
import { iri, isValidUUID, trans, triggerFormSubmit, ucfirst } from 'utilities';
import { amountCell, amountEditor, dateTimeCell, percentCell, percentEditor, validCell, PageLoader, DatatableWrapper, simpleCheckboxEditor, maskEditor } from 'components';
import { Error, NotFound } from 'pages';

import { find, forEach } from 'lodash';
import appUri from 'config/appUri.json';

export const TresorerieEtEmprunt = (props: any) => {
  const { appState, apim, data, additionalData, urls, setData } = props;
  const { t, navigate } = apim.di();

  const [loading, setLoading] = useState<boolean>(false);
  const [notFound, setNotFound] = useState<boolean>(false);
  const [errored, setErrored] = useState<boolean>(false);

  const dossierCompany = appState.company();
  const fiscalYear = appState.exercise();

  const [tresorerie, setTresorerie] = useState<any>(null);
  const [keyTmp, setKeyTmp] = useState<string>('');
  const [banks, setBanks] = useState<any>([]);
  const cancelUri: string = urls?.landing + '/tresorerie/emprunt?maj=' + Date.now();

  // Load banks.
  useEffect(() => {
    apim.fetchEntities({resourceType: 'banks', params: [{ label: 'order[name]', value: 'asc' }, { label: 'limit', value: 250 }], cache: false, setter: setBanks, setLoading} as IRequestParams).then();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  // Load the tresorerie with emprunts for currently selected fiscal year.
  useEffect(() => {
    if (!dossierCompany?.id) return;

    setLoading(true);
    apim.fetchEntities({
      resourceType: 'tresoreries',
      params: [
        {label: 'company', value: dossierCompany?.id},
        {label: 'fiscalYear', value: fiscalYear},
        {label: 'expand[]', value: 'tresorerie:read_emprunts'},
        {label: 'expand[]', value: 'emprunt:read_list'},
      ],
      setLoading: setLoading,
      setErrored: setErrored,
      setNotFound: setNotFound,
      notif: false,
      cache: false,
      success: (res: AxiosResponse) => {
        if (res?.data && (res?.data['hydra:member'] || []).length > 0) {
          setKeyTmp('_' + dossierCompany?.id + '_' + fiscalYear);
          setTresorerie(res.data['hydra:member'][0]);
        } else {
          setTresorerie(null);
          setKeyTmp('');
        }
      }
    } as IRequestParams).then();
  }, [dossierCompany?.id, fiscalYear]); // eslint-disable-line react-hooks/exhaustive-deps

  const formatEmprunts = (loans: any[]) => {
    const _loans: any[] = [];
    forEach(loans || [], (loan: any, index: number) =>
      _loans.push({...loan, ...{name: trans(t, 'table|tresorerie.emprunt.table.emprunt') + ' ' + (index + 1)}})
    );

    return _loans;
  };
  const bankBodyTemplate = (rowData: any) => find((banks || []), { id: rowData.bank })?.name;
  const groupBodyTemplate = (rowData: any) => {
    const bank = find((banks || []), { id: rowData.bank });
    return bank ? trans(t, 'table|' + (bank?.groupe === false ? 'no' : 'yes')) : '';
  };
  const startDateBodyTemplate = (rowData: any) => dateTimeCell(rowData.start, {format: 'DD/MM/YYYY'});
  const endDateBodyTemplate = (rowData: any) => dateTimeCell(rowData.end, {format: 'DD/MM/YYYY'});
  const montantEmprunteBodyTemplate = (rowData: any) => amountCell(rowData.montantEmprunte);
  const tauxBodyTemplate = (rowData: any) => percentCell(rowData.taux);
  const mensualiteEmprunteBodyTemplate = (rowData: any) => amountCell(rowData.mensualite, null, ' ' + trans(t, 'perMonthShort'));
  const capitalRestantDuBodyTemplate = (rowData: any) => amountCell(rowData.capitalRestantDu);
  const iraBodyTemplate = (rowData: any) => amountCell(rowData.ira);
  const activeBodyTemplate = (rowData: any) => validCell(rowData?.active);
  const bankEditor = (options: any) => {
    return <Dropdown
      value={options.value}
      options={banks}
      onChange={(e) => options.editorCallback(e.value)}
      placeholder={trans(t, 'form|select')}
      optionLabel={'name'}
      optionValue={'id'}
      filter
    />;
  }

  const formatter = (formData: any) => {
    // Do not send field data if it will be calculated
    if (formData?.effetDeLevierManuelle === false) {
      delete formData?.effetdeLevier;
    }
    if (formData?.tauxEndettementManuelle === false) {
      delete formData?.tauxEndettement;
    }
    if (formData?.capaciteEmpruntManuelle === false) {
      delete formData?.capaciteEmprunt;
    }

    return formData;
  }

  // Wrap form render into a useCallback to avoid multiple FormWrapper recalls du to form fields updates.
  const renderForm = useCallback(() => {
      return <FormWrapper classes={'grid p-fluid w-12'} formKeyPrefix={'dossier_tresorerie_emprunt'} resourceType={'tresoreries'} keyTmp={keyTmp} hideReload
                   data={data} setData={setData} additionalData={additionalData} cancelLink multiple redirectUri={urls?.form + appUri.dos.edit.fiscalYear.steps.step6} formatter={formatter}/>
  }, [tresorerie?.id, keyTmp]); // eslint-disable-line react-hooks/exhaustive-deps

  if (loading) return <PageLoader/>;
  if (notFound) return <NotFound asBlock/>;
  if (errored) return <Error asBlock/>;

  /**
   * Load emprunts from the previous year.
   */
  const prefillContracts = () => {
    apim.postEntity({
      resourceType: 'tresoreriesPrepareEmprunts',
      data: {
        company: iri('dossierCompanies', dossierCompany?.id),
        fiscalYear: parseInt(fiscalYear),
      },
      notif: false,
      success: (res: AxiosResponse) => {
        if (res?.data) {
          setKeyTmp('_' + dossierCompany?.id + '_' + fiscalYear);
          setTresorerie(res.data);
        }
      }
    } as IRequestParams).then();
  }

  /**
   * Load emprunts from the previous year button template.
   */
  const prefillBtnTemplate = () => {
    if (!isValidUUID(data?.id)
      || (data?.emprunts === null || data?.emprunts === undefined || data?.emprunts?.length === 0)) {
      return <>
        <Button type={'button'} className={'a8-dossier-social-contracts-prefill-btn'} onClick={prefillContracts} icon={'pi pi-history'} aria-label={ucfirst(trans(t, 'loadPreviousYearInformation'))}/>
        <Tooltip target={'.a8-dossier-social-contracts-prefill-btn'} position={'left'} content={ucfirst(trans(t, 'loadPreviousYearInformation'))}/>
      </>;
    }

    return null;
  }

  return (
    <div className={'flex flex-column xl:flex-row flex-wrap fadein animation-duration-500'}>
      {renderForm()}

      {data?.id && (
        <div className={'grid p-fluid mt-3 w-full'}>
          <div className={'col-12'}>
            <DatatableWrapper resourceType={'emprunts'} tableKey={'loans'} editMode formatter={formatEmprunts} emptyMessage={trans(t, 'table|loan', 0)}
                              noFilters noGlobalFilter additionalData={{tresorerie: iri('tresoreries', data?.id)}} paginator={false} headerCreateBtn={prefillBtnTemplate}
                              params={isAdmin() ? [{label: 'tresorerie', value: data?.id}] : [{label: 'tresorerie', value: data?.id}, {label: 'active', value: true}]}>
              <Column field={'name'} header={trans(t, 'table|name')}/>
              <Column header={trans(t, 'table|tresorerie.emprunt.table.emprunt.header.groupe')} body={groupBodyTemplate}/>
              <Column field={'bank'} header={trans(t, 'table|tresorerie.emprunt.table.emprunt.header.banque')} body={bankBodyTemplate}
                      editor={(options) => bankEditor(options)}/>
              <Column field={'montantEmprunte'} header={trans(t, 'table|tresorerie.emprunt.table.emprunt.header.montantEmprunte')} alignHeader={'right'} body={montantEmprunteBodyTemplate} align={'right'}
                      editor={(options) => amountEditor(options, {label: trans(t, 'table|tresorerie.emprunt.table.emprunt.header.montantEmprunte')})}/>
              <Column field={'taux'} header={trans(t, 'table|tresorerie.emprunt.table.emprunt.header.taux')} align={'center'} alignHeader={'center'} body={tauxBodyTemplate}
                      editor={(options) => percentEditor(options, {label: trans(t, 'table|tresorerie.emprunt.table.emprunt.header.taux')})}/>
              <Column field={'start'} header={trans(t, 'table|startDate')} body={startDateBodyTemplate} align={'center'} alignHeader={'center'}
                      editor={(options) => maskEditor(options, {label: trans(t, 'table|startDate'), isDate: true})}/>
              <Column field={'end'} header={trans(t, 'table|endDate')} body={endDateBodyTemplate} align={'center'} alignHeader={'center'}
                      editor={(options) => maskEditor(options, {label: trans(t, 'table|endDate'), isDate: true})}/>
              <Column field={'mensualite'} header={trans(t, 'table|tresorerie.emprunt.table.emprunt.header.mensualite')} alignHeader={'right'} body={mensualiteEmprunteBodyTemplate} align={'right'}
                      editor={(options) => amountEditor(options, {label: trans(t, 'table|tresorerie.emprunt.table.emprunt.header.mensualite')})}/>
              <Column field={'capitalRestantDu'} header={trans(t, 'table|tresorerie.emprunt.table.emprunt.header.capitalRestantDu')} alignHeader={'right'} body={capitalRestantDuBodyTemplate} align={'right'}
                      editor={(options) => amountEditor(options, {label: trans(t, 'table|tresorerie.emprunt.table.emprunt.header.capitalRestantDu')})}/>
              <Column field={'ira'} header={trans(t, 'table|tresorerie.emprunt.table.emprunt.header.ira')} align={'center'} alignHeader={'center'} body={iraBodyTemplate}
                      editor={(options) => amountEditor(options, {label: trans(t, 'table|tresorerie.emprunt.table.emprunt.header.ira')})}/>
              {isAdmin() ? <Column field={'active'} header={trans(t, 'active')} dataType={'boolean'} align={'center'} body={activeBodyTemplate}
                                   editor={(options) => simpleCheckboxEditor(options, {label: null})}
                                   style={{width: '100px'}}/> : ''}
            </DatatableWrapper>
          </div>
        </div>
      )}

      <div className={'w-full text-right m-2 mr-3 pt-4'}>
        <>
          <Button type={'reset'} className={'text-ucfirst p-button-text p-button-danger mr-5'} label={trans(t, 'close')} onClick={() => navigate(cancelUri)}/>
          <span className={'p-buttonset'}>
            <Button type={'submit'} className={'text-ucfirst p-button-text'} label={trans(t, 'back')} icon={'pi pi-arrow-left'} onClick={() => navigate(urls?.form + appUri.dos.edit.fiscalYear.steps.step4)}/>
            <Button type={'submit'} className={'text-ucfirst'} label={trans(t, 'save')} icon={'pi pi-arrow-right'} iconPos={'right'} onClick={() => triggerFormSubmit(null)}/>
          </span>
        </>
      </div>
    </div>
  );
};
