import React, { useEffect, useState } from 'react';
import { escapeValue, trans, ucfirst } from 'utilities';
import { TextField, CountryField, StaticListField } from 'forms';
import { useFormState } from 'states';

const codesPostaux = require('codes-postaux');

export const AddressSociete = (props: any) => {
  const { t, control, onFieldChange, errorMessage, globalDisabled, getValues, setValue, formKey, parentKey } = props;
  const formState = useFormState();
  const { headquarterPostalCode, headquarterCity } = getValues();
  const [citiesArray, setCitiesArray] = useState<any[]>([]);

  useEffect(() => setCitiesArray(codesPostaux.find(headquarterPostalCode) ?? []), [headquarterPostalCode]); // eslint-disable-line react-hooks/exhaustive-deps
  useEffect(() => {
    if (citiesArray.length === 1 && headquarterCity?.upperCase !== citiesArray[0].libelleAcheminement?.upperCase) {
      formState.setFieldData(formKey, 'headquarterCity', parentKey, escapeValue(citiesArray[0].libelleAcheminement, 'string'));
      setValue('headquarterCity', citiesArray[0].libelleAcheminement);
    }
  }, [citiesArray]); // eslint-disable-line react-hooks/exhaustive-deps

  const cityOptions = citiesArray.map((option: any) => ({
    label: option.libelleAcheminement,
    value: option.libelleAcheminement
  }));

  return <div className={'flex col-12 flex-wrap'}>
    <TextField fieldKey={'headquarterAddress'} control={control} onFieldChange={onFieldChange} errorMessage={errorMessage} label={ucfirst(trans(t, 'address'))} disabled={globalDisabled}/>
    <TextField fieldKey={'headquarterPostalCode'} control={control} onFieldChange={onFieldChange} errorMessage={errorMessage} label={ucfirst(trans(t, 'postalCode'))} disabled={globalDisabled}/>
    <StaticListField listKey={'cities'} listsOptions={{cities:cityOptions}} fieldKey={'headquarterCity'} control={control} optionLabel={'label'} label={ucfirst(trans(t, 'city'))} errorMessage={errorMessage} onFieldChange={onFieldChange} editable={true}/>
    <CountryField fieldKey={'headquarterCountry'} control={control} onFieldChange={onFieldChange} errorMessage={errorMessage} label={ucfirst(trans(t, 'country'))} disabled={globalDisabled}/>
  </div>;
};
