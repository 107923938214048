import React, { useCallback } from 'react';
import { FormWrapper } from 'forms';
import { iri, trans } from 'utilities';

import appUri from 'config/appUri.json';

export const Prevoyance = (props: any) => {
  const { data, dossier, apim } = props;
  const { t } = apim.di();

  // Wrap form render into a useCallback to avoid multiple FormWrapper recalls du to form fields updates.
  const renderForm = useCallback(() =>
      <FormWrapper classes={'grid'} formKeyPrefix={'prevoyance'} resourceType={'prevoyances'} cancelLink
                   redirectUri={appUri.dos.page.replace(':id', dossier?.id || '_') + '/prevoyance?maj=' + Date.now()}
                   data={data} additionalData={{ dossier: iri('dossiers', dossier?.id) }} context={data} />
    , [data?.id]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div className={'a8-form prevoyance'}>
      <h5 className={'mx-3 pb-5 flex flex-row align-items-center'}>{trans(t, 'menu|menu.schema_flux.prevoyance_besoins_ressources')}</h5>

      {renderForm()}
    </div>
  );
};
