import React, { useState } from 'react';
import { AxiosResponse } from 'axios';
import { useSearchParams } from 'react-router-dom';

import { Column } from 'primereact/column';
import { SpeedDial } from 'primereact/speeddial';
import { Tooltip } from 'primereact/tooltip';
import { Button } from 'primereact/button';
import { Badge } from 'primereact/badge';

import { dialog, iri, isValidUUID, sendNotification, trans, ucfirst } from 'utilities';
import { DatatableWrapper, dateTimeCell, validCell } from 'components';
import { IRequestParams, isAdmin, isClient, isExpert, isExpertAdmin, isSuperAdmin } from 'services';
import { useUserState } from 'states';

import appUri from 'config/appUri.json';
import { union, forEach } from 'lodash';

export const DossiersDatatable = (props: any) => {
  const { tableKey, title, params, lazyConfig, rowUri, noFilters, additionalClassNames, context, apim } = props;
  const { t, navigate } = apim.di();

  const filtersEnabled = (!noFilters || false === noFilters);
  const userState = useUserState();
  const [disabledItems, setDisabledItems] = useState<any[]>([]);
  const [loadingItems, setLoadingItems] = useState<any[]>([]);
  const [selectedDossiers, setSelectedDossiers] = useState<any[]>([]);
  const [searchParams] = useSearchParams();

  const lazyC = {
    ...{
      sortField: searchParams.has('sortField') ? searchParams.get('sortField') : 'favorites',
      sortOrder: searchParams.has('sortOrder') ? (searchParams.get('sortOrder') === 'ASC' ? 1 : -1) : -1,
    }, ...lazyConfig
  };

  let newParams = params;
  if (tableKey === 'user-dirigeant-dossiers' && context.user) {
    // We are displaying the dossiers for a user entry.
    const user = context.user;
    newParams = union(params, [{label: 'entrepreneurId', value: user.id}]);

  } else if (tableKey === 'user-expert-dossiers' && context.expert) {
    // We are displaying the dossiers for an expert entry.
    const expert = context.expert;
    newParams = union(params, [{label: 'managerId', value: expert.id}]);
  }

  const favoritesBodyTemplate = (rowData: any) => {
    if (rowData.favorites === undefined) {
      return null;
    }

    const isFavorite = rowData.favorites.includes(userState.id());
    return <span>
        <Tooltip target={'.a8-dossier-favorite-toggle'} position={'top'} mouseTrack/>
        <Button data-pr-tooltip={isFavorite ? trans(t, 'dos.favorite.remove') : trans(t, 'dos.favorite.add')}
                data-pr-position={'top'} data-pr-at={'top'} data-pr-my={'left'} icon={'pi ' + (isFavorite ? 'pi-star-fill' : 'pi-star')}
                className={'a8-dossier-favorite-toggle'} rounded text severity={'warning'} aria-label={trans(t, 'favorite')}
                onClick={(e) => onFavoriteClick(e, rowData)}/>
      </span>;
  };

  const createBtnTemplate = () => {
    const isSelectionEmpty = selectedDossiers?.length === 0;

    return (
      <>
        <Button className={'a8-dossier-send-notifications-btn'} onClick={() => sendNotification(apim, dossiers)} rounded
                icon={'pi pi-bell'} disabled={isSelectionEmpty}/>
        <Tooltip target={'.a8-dossier-send-notifications-btn'} position={'top'} content={trans(t, 'sendNotification')}
                 mouseTrack/>
      </>
    )
  };

  const onFavoriteClick = (event: any, rowData: any) => {
    // Get current status of the dossier favorite.
    const dossierId = rowData.id;
    const userId = userState.id();

    // Mark the row as loading.
    const initialLoadingItems: any[] = loadingItems;
    const newLoading: any[] = loadingItems.filter((d: string) => d !== rowData.id);
    newLoading.push(rowData.id);
    setLoadingItems(newLoading);

    apim.fetchEntities({
      resourceType: 'dossierUserSettings',
      params: [
        {label: 'dossier', value: iri('dossiers', dossierId)},
        {label: 'user', value: userId},
      ],
      error: () => setLoadingItems(initialLoadingItems),
      success: (res: AxiosResponse) => {
        let favorite = null;
        if (res?.data) {
          const favorites = res.data['hydra:member'] || [];
          favorite = favorites[0] || null;
        }

        if (favorite === null) {
          // Create a new dossier user settings.
          apim.postEntity({
            resourceType: 'dossierUserSettings',
            data: {
              dossier: iri('dossiers', rowData.id),
              user: userId,
              favorite: true
            },
            error: () => setLoadingItems(initialLoadingItems),
            success: () => {
              setLoadingItems(initialLoadingItems)
              rowData.favorites.push(userId);
            }
          } as IRequestParams);

        } else {
          // Update existing dossier user settings.
          apim.patchEntity({
            resourceType: 'dossierUserSettings',
            id: favorite.id,
            data: {
              dossier: iri('dossiers', rowData.id),
              user: userId,
              favorite: !favorite.favorite
            },
            error: () => setLoadingItems(initialLoadingItems),
            success: (res: AxiosResponse) => {
              setLoadingItems(initialLoadingItems);
              if (res?.data.favorite) {
                rowData.favorites.push(userId);
              } else {
                const index = rowData.favorites.indexOf(userId);
                if (index > -1) {
                  rowData.favorites.splice(index, 1);
                }
              }
            }
          } as IRequestParams);
        }
      }
    } as IRequestParams);
  };

  const entrepreneurBodyTemplate = (rowData: any) => {
    return <div className={'flex flex-column'}>
      <div className={'text-primary'}>{rowData?.entrepreneurName?.toUpperCase()}</div>
      {rowData.entrepreneur && rowData.entrepreneur.email ? <div className={'text-sm mt-1'}>{rowData.entrepreneur.email}</div> : ''}
      {rowData.entrepreneur && rowData.entrepreneur.phone ? <div className={'text-sm mt-1'}>{rowData.entrepreneur.phone}</div> : ''}
    </div>;
  };

  const societeExpertBodyTemplate = (rowData: any) => rowData?.societeExpert?.toUpperCase() ?? null;

  const managerBodyTemplate = (rowData: any) => {
    const manager = rowData.manager;
    if (manager === undefined) return null;

    return (
      <>
        <img alt={manager?.name} src={manager?.picture || 'https://www.primefaces.org/wp-content/uploads/2020/05/placeholder.png'} width={32} style={{verticalAlign: 'middle'}}/>
        <span title={manager?.firstName + ' ' + manager?.lastName} style={{marginLeft: '.5em', verticalAlign: 'middle'}} className={'image-text'}>{manager?.firstName?.toUpperCase()} {manager?.lastName.substring(0, 1).toUpperCase() + '.'}</span>
      </>
    );
  };

  const companyBodyTemplate = (rowData: any) => {
    const companies = rowData.companies;
    if (companies !== undefined && companies.length > 0) {
      let companyNames: string[] = [];

      const mainCompany = rowData.mainCompany;
      if (mainCompany !== undefined) {
        companyNames.push(mainCompany?.toUpperCase());
      }

      forEach(companies, ((company: any) => {
        if (mainCompany === undefined || company.name !== mainCompany) {
          companyNames.push(company?.name?.toUpperCase());
        }
      }));

      return <ul className={'list-none p-0 m-0'}>
        {companyNames.map((item: string, index: number) =>
          <li key={index} className={'pb-1'}>{index === 0 ? <span className={'block pb-1'}>{item}</span> : <span className={'block text-sm'}>{item}</span>}</li>
        )}
      </ul>;
    }

    return null;
  };

  const maturityLevelBodyTemplate = (rowData: any) => {
    if (rowData.maturityLevel > 0) {
      let severity: any = null;
      let tooltip = ucfirst(trans(t, 'dos.maturityLevelTooltip' + rowData.maturityLevel));

      if (rowData.maturityLevel === 1) {
        severity = 'info';
      } else if (rowData.maturityLevel === 2) {
        severity = 'warning'
      } else if (rowData.maturityLevel === 3) {
        severity = 'danger'
      }

      return <div>
        <Tooltip target={'.a8-dossier-maturity-level--' + rowData.maturityLevel} position={'top'} mouseTrack/>
        <Badge className={'a8-dossier-maturity-level--' + rowData.maturityLevel} value={rowData.maturityLevel} severity={severity} data-pr-tooltip={tooltip}/>
      </div>;
    }

    return null;
  };

  const verifiedBodyTemplate = (rowData: any) => validCell(rowData?.valid);
  const activeBodyTemplate = (rowData: any) => validCell(rowData?.active);
  const updatedBodyTemplate = (rowData: any) => dateTimeCell(rowData?.updated);
  const deletedBodyTemplate = (rowData: any) => dateTimeCell(rowData?.deleted);

  const actionsBodyTemplate = (rowData: any) => {
    let items = [];

    if (isAdmin() || isExpert()) {
      items.push({
        label: ucfirst(trans(t, 'edit')),
        icon: 'pi pi-pencil',
        className: 'bg-indigo-500',
        command: () => {
          navigate(appUri.dos.edit.landing.replace(':id', rowData?.id || '_'));
        }
      });

      items.push({
        label: ucfirst(trans(t, 'system|actions.dossier.sendCredentials')),
        icon: 'pi pi-send',
        className: 'bg-indigo-500',
        command: () => {
          dialog(t, {
            message: ucfirst(trans(t, 'system|confirmations.dossier.sendCredentials')),
            icon: 'pi pi-send',
            accept: () => {
              if (rowData?.id) {
                apim.call({
                  resourceType: 'dossiers',
                  action: 'sendClientCrendentials',
                  method: 'get',
                  id: rowData?.id,
                  notifSuccess: {
                    summary: trans(t, 'notification|dossier.sendClientCrendentials.summary'),
                    details: trans(t, 'notification|dossier.sendClientCrendentials.details'),
                  }
                } as IRequestParams);
              }
            }
          });
        }
      });
    }

    if (isAdmin()) {
      items.push({
        label: rowData?.active ? ucfirst(trans(t, 'system|actions.disable')) : ucfirst(trans(t, 'system|actions.enable')),
        icon: rowData?.active ? 'pi pi-ban' : 'pi pi-check',
        className: 'bg-orange-500',
        command: () => {
          if (rowData?.id) {
            const active = !rowData.active;
            const action = active ? 'enable' : 'disable';

            apim.patchEntity({
              resourceType: 'dossiers',
              id: rowData.id,
              data: {active: active},
              notifSuccess: {
                summary: trans(t, 'notification|dossier.' + action + '.summary'),
                details: trans(t, 'notification|dossier.' + action + '.details'),
              },
              success: () => {
                rowData.active = active;
              },
            } as IRequestParams);
          }
        }
      });

      if (rowData.deleted === null || rowData.deleted === undefined) {
        items.push({
          label: ucfirst(trans(t, 'system|actions.archive')),
          icon: 'pi pi-folder',
          className:'bg-red-500',
          command: () => {
            dialog(t, {
              message: ucfirst(trans(t, 'system|confirmations.dossier.archive')),
              accept: () => {
                if (rowData?.id) {
                  const deleted = new Date();
                  apim.patchEntity({
                    resourceType: 'dossiers',
                    id: rowData?.id,
                    data: {
                      active: false,
                      deleted: deleted
                    },
                    notifSuccess: {
                      summary: trans(t, 'notification|dossier.archive.summary'),
                      details: trans(t, 'notification|dossier.archive.details'),
                    },
                    success: () => {
                      if (isSuperAdmin()) {
                        rowData.deleted = deleted;
                        rowData.active = false;
                      } else {
                        const _disabledItems: any[] = disabledItems.filter((d: string) => d !== rowData.id);
                        _disabledItems.push(rowData.id);
                        setDisabledItems(_disabledItems);
                      }
                    },
                  } as IRequestParams);
                }
              },
              acceptClassName: 'p-button-danger',
              rejectClassName: 'p-button-text p-button-primary'
            });
          }
        });
      }
    }

    if (isSuperAdmin()) {
      items.push({
        label: ucfirst(trans(t, 'system|actions.delete')),
        icon: 'pi pi-trash',
        className:'bg-red-500',
        command: () => {
          dialog(t, {
            message: ucfirst(trans(t, 'system|confirmations.dossier.delete')),
            accept: () => {
              if (rowData?.id) {
                apim.deleteEntity({
                  resourceType: 'dossiers',
                  id: rowData?.id,
                  success: () => {
                    const _disabledItems: any[] = disabledItems.filter((d: string) => d !== rowData.id);
                    _disabledItems.push(rowData.id);
                    setDisabledItems(_disabledItems);
                  },
                } as IRequestParams);
              }
            },
            acceptClassName: 'p-button-danger',
            rejectClassName: 'p-button-text p-button-primary'
          });
        }
      });

      if (rowData.deleted !== null && rowData.deleted !== undefined) {
        items.push({
          label: ucfirst(trans(t, 'system|actions.unarchive')),
          icon: 'pi pi-folder-open',
          className: 'bg-red-500',
          command: () => {
            dialog(t, {
              message: ucfirst(trans(t, 'system|confirmations.dossier.unarchive')),
              accept: () => {
                if (rowData?.id) {
                  apim.patchEntity({
                    resourceType: 'dossiers',
                    id: rowData?.id,
                    data: {
                      active: true,
                      deleted: null
                    },
                    notifSuccess: {
                      summary: trans(t, 'notification|dossier.unarchive.summary'),
                      details: trans(t, 'notification|dossier.unarchive.details'),
                    },
                    success: () => {
                      rowData.deleted = null;
                      rowData.active = true;
                    },
                  } as IRequestParams);
                }
              },
              acceptClassName: 'p-button-danger',
              rejectClassName: 'p-button-text p-button-primary'
            });
          }
        });
      }
    }

    return <>
      <Tooltip target={'.a8-speedial-datatable .p-speeddial-action'} position={'top'} mouseTrack/>
      <SpeedDial className={'a8-speedial-datatable relative z-5'} model={items} direction={'left'} transitionDelay={40} showIcon={'pi pi-ellipsis-v'} hideIcon={'pi pi-times'} buttonClassName={'p-button-text'}/>
    </>
  };

  const selectedDossiersWithUsers = selectedDossiers.filter(dossier => isValidUUID(dossier.entrepreneurId));
  const dossiers = selectedDossiersWithUsers.map(dossier => dossier.entrepreneurId);

  return (
    <>
      <DatatableWrapper resourceType={'dossiersIndex'} tableKey={tableKey || 'dossiers'} params={newParams} lazyConfig={lazyC} rowUri={rowUri || appUri.dos.page}
                        additionalClassNames={(additionalClassNames || '')} title={title || trans(t, 'menu|pages.title.societe.experts')} headerCreateBtn={createBtnTemplate}
                        noFilters={noFilters} disabledItems={disabledItems} selection={selectedDossiers} onSelectionChange={(e: any) => setSelectedDossiers(e.value)}>

        {!isClient() &&
          <Column selectionMode={'multiple'} headerStyle={{width: '3rem'}}/>
        }
        {!isClient() &&
          <Column field={'favorites'} header={trans(t, 'dos.favoriteShort')} sortable filter={filtersEnabled}
                  dataType={'boolean'} align={'center'} body={favoritesBodyTemplate} style={{width: '80px'}}/>
        }
        {!isClient() &&
          <Column field={'valid'} header={trans(t, 'validated')} sortable filter={filtersEnabled}
                  dataType={'boolean'} align={'center'} body={verifiedBodyTemplate} style={{width: '100px'}}/>
        }

        <Column field={'entrepreneurName'} sortField={'entrepreneurName'} header={trans(t, 'dirigeant')} sortable filter={filtersEnabled} body={entrepreneurBodyTemplate}/>
        <Column field={'identifier'} header={trans(t, 'numero_client')} sortable filter={filtersEnabled} style={{width: '150px'}}/>
        <Column field={'internalIdentifier'} header={trans(t, 'ent.reference')} sortable filter={filtersEnabled} style={{width: '100px'}}/>
        <Column field={'mainCompany'} header={trans(t, 'entreprise', 2)} sortable filter={filtersEnabled} body={companyBodyTemplate} style={{maxWidth: '250px'}}/>

        {'users-experts-dossiers' !== tableKey && (isAdmin() || (isExpertAdmin() && (userState.societesExperts() ?? []).length > 1)) &&
          <Column field={'societeExpert'} header={trans(t, 'societeExpert')} sortable filter={filtersEnabled} body={societeExpertBodyTemplate} style={{maxWidth: '250px'}}/>
        }

        <Column sortField={'manager.firstName'} header={trans(t, 'expert')} sortable filter={filtersEnabled} body={managerBodyTemplate} style={{maxWidth: '250px'}}/>

        {!isClient() &&
          <Column field={'maturityLevel'} header={trans(t, 'dos.maturityLevelShort')} sortable filter={filtersEnabled}
                  dataType={'boolean'} align={'center'} body={maturityLevelBodyTemplate} style={{width: '80px'}}/>
        }

        <Column field={'updated'} header={trans(t, 'updated')} sortable align={'center'} body={updatedBodyTemplate} style={{width: '225px'}}/>
        {isSuperAdmin() &&
          <Column field={'deleted'} header={trans(t, 'deleted')} sortable filter={filtersEnabled}
                  align={'center'} body={deletedBodyTemplate} style={{width: '100px'}}/>
        }
        {!isClient() &&
          <Column field={'active'} header={trans(t, 'active')} sortable filter={filtersEnabled}
                  dataType={'boolean'} align={'center'} body={activeBodyTemplate} style={{width: '100px'}}/>
        }

        {!isClient() && <Column header={trans(t, 'system|action', 2)} align={'right'} body={actionsBodyTemplate}
                                style={{width: '80px', maxWidth: '80px'}}/>}
      </DatatableWrapper>
    </>
  );
};
