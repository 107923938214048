import React from 'react';

import { Sidebar } from 'primereact/sidebar';

import { RecentConnexions, RecentActivity } from 'components';
import { isAdmin, isExpert } from 'services';

export const AppRightMenu = (props: any) => {
  const { apim, themeState, userState } = props;

  return (
    <Sidebar
      content={''}
      appendTo='self'
      visible={themeState.isRightMenuActive()}
      onHide={() => themeState.toggleRightMenu()}
      position='right'
      blockScroll={true}
      showCloseIcon={false}
      baseZIndex={1000}
      className='layout-rightmenu p-sidebar-sm fs-small'>
      {(isExpert() || isAdmin()) && (
        <RecentConnexions apim={apim} />
      )}

      <RecentActivity apim={apim} userUuid={userState.id()} />
    </Sidebar>
  );
};
