import React, { useCallback, useEffect } from 'react';
import { Button } from 'primereact/button';

import { FormWrapper } from 'forms';
import { isValidUUID, trans, triggerFormSubmit } from 'utilities';

import appUri from 'config/appUri.json';

export const CGIncapacite = (props: any) => {
  const { apim, urls, additionalData, data, updateData } = props;
  const { t, navigate } = apim.di();

  // Ensure contract data.
  useEffect(() => {
    if (!isValidUUID(data?.id)) return navigate(urls?.form + appUri.dos.edit.contracts.prevoyance.step11);
  }, [data?.id]); // eslint-disable-line react-hooks/exhaustive-deps

  const cancelUri: string = urls?.landing + '/prevoyance/contrats';

  // Wrap form render into a useCallback to avoid multiple FormWrapper recalls du to form fields updates.
  const renderForm = useCallback(() =>
      <FormWrapper classes={'grid pt-3'} formKeyPrefix={'contrat_cg_incapacite'} resourceType={'prevoyanceContractsData'}
                   data={{...data?.data, ...{type: data?.type}}} additionalData={additionalData} setData={updateData} hideReload cancelLink multiple
                   redirectUri={urls?.form + (data?.type === 'frais_generaux' ? appUri.dos.edit.contracts.prevoyance.step31 : appUri.dos.edit.contracts.prevoyance.step23)}/>
    , [data?.data?.id]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div className={'fadein animation-duration-500'}>
      <h5 className={'mx-3 pt-3 flex flex-row align-items-center'}>
        <span className={'mr-2 border-circle bg-primary flex align-items-center justify-content-center'} style={{width: '2rem', height: '2rem'}}><i className={'pi pi-stop-circle'}></i></span>
        {trans(t, 'form|title.contract.prevoyance.step22')}
      </h5>

      {renderForm()}

      <div className={'text-right m-2 pt-4'}>
        <Button type={'reset'} className={'text-ucfirst p-button-text p-button-danger mr-5'} label={trans(t, 'close')} onClick={() => navigate(cancelUri)}/>
        <span className={'p-buttonset'}>
          <Button type={'submit'} className={'text-ucfirst p-button-text'} label={trans(t, 'back')} icon={'pi pi-arrow-left'} onClick={() => navigate(urls?.form + appUri.dos.edit.contracts.prevoyance.step21)}/>
          <Button type={'submit'} className={'text-ucfirst'} label={trans(t, 'save')} icon={'pi pi-arrow-right'} iconPos={'right'} onClick={() => triggerFormSubmit(null)}/>
        </span>
      </div>
    </div>
  );
};
