import React, { useRef } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';

import { trans } from 'utilities';
import { classNames } from 'primereact/utils';

export const Etablissements = (props: any) => {
  const { data, apim } = props;
  const { t } = apim.di();
  const dt = useRef<any>(null);
  const siegeBodyTemplate = (rowData: any) => {
    return rowData.siege ? (<i className={'pi text-primary pi-building'}></i>) : (<></>);
  };

  const activeBodyTemplate = (rowData: any) => {
    return <i className={classNames('pi', {
      'text-green-500 pi-check-circle': !rowData.etablissement_cesse,
      'text-pink-500 pi-times-circle': rowData.etablissement_cesse
    })}></i>;
  };

  return (
    <>
      <DataTable ref={dt} value={data} className={'datatable-responsive'} dataKey={'siret'} emptyMessage={trans(t, 'institution_none')}
        stripedRows={true} sortField={'siege'} sortOrder={-1}>
        <Column field={'siege'} header={trans(t, 'ent.siegeShort')} body={siegeBodyTemplate} style={{ width: '100px' }} />
        <Column field={'siret_formate'} header={trans(t, 'ent.siret')} style={{ width: '200px' }} />
        <Column field={'adresse_ligne_1'} header={trans(t, 'address')} />
        <Column field={'code_postal'} header={trans(t, 'postalCode')} style={{ width: '150px' }} />
        <Column field={'ville'} header={trans(t, 'city')} style={{ width: '150px' }} />
        <Column field={'pays'} header={trans(t, 'country')} style={{ width: '150px' }} />
        <Column field={'etablissement_cesse'} header={trans(t, 'active')} dataType={'boolean'} align={'center'} body={activeBodyTemplate} style={{ width: '100px' }} />
      </DataTable>
    </>
  );
};
