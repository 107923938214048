import { montant, shortNumber, trans } from 'utilities';
import { forEach, isDate, isNumber, last } from 'lodash';

export const formatTable = (dataSource: any, key: string, t: any, opt: any = null) => {
  const options = {...{
      autoNr: true,
      method: 'shortNumber',
      disableNull: true,
      tradCells: true,
      tradOnlyFirstCell: true,
      tradOnlyFirstHeader: true
    }, ...opt};

  const tableFound: any = (dataSource.tables['hydra:member'] || []).filter((c: any) => c.key === key);
  if (tableFound.length === 0) return {
    title: trans(t, 'table|title'),
    headers: [],
    data: []
  };

  const handleValue = (cell: any, translate: boolean = false, meth: string|null = null) => {
    if (isDate(cell.value)) return cell.value;
    if (isNumber(cell.value)) {
      switch (meth || options.method || 'shortNumber') {
        case 'asIt': return cell.value;
        case 'montant': return montant(cell.value, cell.options);
        default: return shortNumber(cell.value, cell.options);
      }
    }
    const val: string = cell.translated || cell.value;

    return translate && val ? trans(t, 'table|' + val) : val;
  };

  const table = {
    title: trans(t, 'table|' + tableFound[0].key),
    headers: tableFound[0].headers?.map((h: any, i: number) => {
      if (options.tradOnlyFirstHeader) {
        return 0 === i ? (h.key ? trans(t, 'table|' + h.key) : h.translated || null) : h.translated || h.key || null;
      } else {
        return h.key ? trans(t, 'table|' + h.key) : h.translated || null;
      }
    }),
    data: []
  }



  if ('simpleRows' === (options.method || 'shortNumber')) {
    table.data = tableFound[0].rows?.map((r: any) => {
      const rowItem: any = {};
      forEach(tableFound[0].headers, (h: any, index: number) => {
        const subKey: string = last((h.translated || h.key || 'column' + index).split('.')) || '';
        rowItem[subKey] = handleValue(r.cells[index], false, 'montant');
      });

      if (r.subtable?.key) {
        rowItem.tables = {
          'hydra:member': [r.subtable]
        };
      }
      return rowItem;
    })

    return table;
  }

  table.data = tableFound[0].rows?.map((r: any) => {
    const rowData: any = {
      disabled: false,
    };

    forEach(r.cells || [], ((c: any, i: number) => {
      if (options.disableNull && !rowData.disabled) {
        rowData.disabled = (null === c.value);
      }
      if (options.autoNr) {
        if (null === c.value) {
          return rowData['column-' + (i + 1)] = trans(t, 'nr');
        }
      }
      rowData['column-' + (i + 1)] = handleValue(c, options.tradCells && (!options.tradOnlyFirstCell || (options.tradOnlyFirstCell && 0 === i)));
    }));
    return rowData;
  });

  return table;
};
