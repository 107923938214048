import React, { useEffect, useState } from 'react';
import { AxiosResponse } from 'axios';
import { Button } from 'primereact/button';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { InputNumber } from 'primereact/inputnumber';

import { IRequestParams } from 'services';
import { companyCell, PageLoader } from 'components';
import { asDate, dateString, trans, ucfirst } from 'utilities';
import { Error, NotFound } from 'pages';

import { forEach } from 'lodash';
import appUri from 'config/appUri.json';

export const RevenuFiscal = (props: any) => {
  const { appState, apim, urls } = props;
  const { t, navigate } = apim.di();

  const [loading, setLoading] = useState<boolean>(false);
  const [notFound, setNotFound] = useState<boolean>(false);
  const [errored, setErrored] = useState<boolean>(false);

  const dossier = appState.dossier();
  const year = appState.civilYear();

  const [dossierFiscalIncomeDatas, setDossierFiscalIncomeDatas] = useState<any>(null);
  const [datatableData, setDatatableData] = useState<any>(null);
  const cancelUri: string = urls?.landing + '/revenu-et-imposition?maj=' + Date.now();

  // Load form data.
  useEffect(() => {
    if (!dossier?.id || !year) return;

    setLoading(true);
    apim.fetchEntities({
      resourceType: 'dossiers',
      action: 'revenuFiscal',
      params: [{label: 'year', value: year}],
      id: dossier?.id,
      setLoading: setLoading,
      setErrored: setErrored,
      setNotFound: setNotFound,
      cache: false,
      success: (res: AxiosResponse) => {
        setDossierFiscalIncomeDatas(null);
        setDatatableData(null);

        if (res?.data && (res?.data?.fiscalIncomeData || []).length > 0) {
          setDossierFiscalIncomeDatas(res?.data?.fiscalIncomeData);

          let _datatableData: any[] = [];
          forEach(res.data.fiscalIncomeData, (dossierFiscalIncomeData: any, index: number) => {
            // Find the right exercice fiscal.
            let civilYearExerciceFiscal = null;
            let fiscalYearExerciceFiscal = null;
            const exercicesFiscaux = dossierFiscalIncomeData.company.exercicesFiscaux;
            forEach(exercicesFiscaux, (ex: any) => {
              if (ex.year === dossierFiscalIncomeData.civilYear) {
                civilYearExerciceFiscal = ex;
              }

              if (ex.year === dossierFiscalIncomeData.fiscalYear) {
                fiscalYearExerciceFiscal = ex;
              }
            });

            _datatableData.push({
              id: dossierFiscalIncomeData.id,
              company: dossierFiscalIncomeData.company,
              civilYear: dossierFiscalIncomeData.civilYear,
              civilYearExerciceFiscal: civilYearExerciceFiscal,
              fiscalYear: dossierFiscalIncomeData.fiscalYear,
              fiscalYearExerciceFiscal: fiscalYearExerciceFiscal,
              revenuFiscal: dossierFiscalIncomeData.revenuFiscal || null,
            });
          });

          setDatatableData(_datatableData);
        }
      }
    } as IRequestParams).then();
  }, [dossier?.id, year]); // eslint-disable-line react-hooks/exhaustive-deps

  if (!dossier?.id || loading) return <PageLoader/>;
  if (notFound) return <NotFound asBlock/>;
  if (errored) return <Error asBlock/>;

  const companyBodyTemplate = (rowData: any) => {
    return companyCell(rowData.company, t);
  }

  const exerciceFiscalBodyTemplate = (rowData: any) =>
    <span>
      {dateString(asDate(rowData.fiscalYearExerciceFiscal.start)) +
        ' - ' +
        dateString(asDate(rowData.fiscalYearExerciceFiscal.end))}
    </span>
  ;

  const exerciceFiscalPartTemplate = (rowData: any) => {
    let companyDossierFiscalIncomeDatas = [];
    let index = 0;
    let currentEntryIndex = 0;
    forEach(dossierFiscalIncomeDatas, (dossierFiscalIncomeData: any) => {
      if (rowData.company.id === dossierFiscalIncomeData.company.id) {
        companyDossierFiscalIncomeDatas.push(dossierFiscalIncomeData);
        index++;
      }

      if (rowData.id === dossierFiscalIncomeData.id) {
        currentEntryIndex = index;
      }
    });

    return (companyDossierFiscalIncomeDatas.length === 1) ?
      <span>{ucfirst(trans(t, ('totality')))}</span> :
      <span>{ucfirst(trans(t, ('part'))) + ' ' + currentEntryIndex}</span>;
  };

  const revenuFiscalBodyTemplate = (rowData: any) =>
    <InputNumber value={rowData.revenuFiscal} onValueChange={(e) => saveDossierFiscalIncomeData(rowData, e.value)}
                        minFractionDigits={0} maxFractionDigits={0} mode={'currency'} currency={'EUR'} locale={'fr-FR'} />
  ;

  const saveDossierFiscalIncomeData = (rowData: any, revenuFiscal: any) => {
    // Find the dossierFiscalIncomeData entity.
    let dossierFiscalIncomeData: any = null;
    forEach(dossierFiscalIncomeDatas, (entity: any) => {
      if (rowData.id === entity.id) {
        dossierFiscalIncomeData = entity;
      }
    });

    if (dossierFiscalIncomeData !== null) {
      apim.patchEntity({
        resourceType: 'dossierFiscalIncomeData',
        id: dossierFiscalIncomeData.id,
        data: {
          revenuFiscal: revenuFiscal,
        },
      } as IRequestParams).then();
    }
  };

  return (
    <div className={'flex flex-column xl:flex-row flex-wrap fadein animation-duration-500'}>
      <div className={'grid p-fluid mt-3 w-full'}>
        <div className={'col-12'}>
          <h4 className={'px-2 pb-3'}>{ucfirst(trans(t, 'revenuFiscal'))}</h4>
          <DataTable value={datatableData} rowGroupMode={'rowspan'} groupRowsBy={'company.id'} sortOrder={1} tableStyle={{ minWidth: '50rem' }} size={'large'} stripedRows>
            <Column field={'company'} header={trans(t, 'company')} body={companyBodyTemplate} style={{ minWidth: '200px' }} />
            <Column field={'exerciceFiscal'} header={trans(t, 'exerciceFiscal')} body={exerciceFiscalBodyTemplate} style={{ minWidth: '200px' }} />
            <Column field={'exerciceFiscalPart'} header={trans(t, 'decoupageExerciceFiscal')} body={exerciceFiscalPartTemplate} style={{ minWidth: '150px' }} />
            <Column field={'revenuFiscal'} header={trans(t, 'revenuFiscal')} body={revenuFiscalBodyTemplate} style={{ maxWidth: '200px', width: '200px' }} />
          </DataTable>
          <p className={'mt-5 mb-5'}>{ucfirst(trans(t, 'brutDataDisplayed'))}.</p>
        </div>
      </div>

      <div className={'w-full text-right m-2 mr-3 flex justify-content-end'}>
        <>
          <Button type={'reset'} className={'text-ucfirst p-button-text p-button-danger mr-5'} label={trans(t, 'close')} onClick={() => navigate(cancelUri)} />
          <span className={'p-buttonset'}>
            <Button type={'submit'} className={'text-ucfirst p-button-text'} label={trans(t, 'back')} icon={'pi pi-arrow-left'} onClick={() => navigate(urls?.form + appUri.dos.edit.civilYear.steps.step2)} />
            <Button type={'submit'} className={'text-ucfirst p-button-text'} label={trans(t, 'next')} icon={'pi pi-arrow-right'} iconPos={'right'} onClick={() => navigate(urls?.form + appUri.dos.edit.civilYear.steps.step4)} />
          </span>
        </>
      </div>
    </div>
  );
};

