import React from 'react';
import { Fieldset } from 'primereact/fieldset';

import { APIListField, CheckboxField, MaskField, NumberField, StaticListAutocompleteField, TextField } from 'forms';
import { trans, ucfirst } from 'utilities';

export const AssuranceVieForm = (props: any) => {
  const { t, control, onFieldChange, errorMessage, getValues, listsOptions } = props;
  const { type, transfert, transfertDate } = getValues();

  return <>
    <Fieldset legend={ucfirst(trans(t, 'general'))} className={'col-12 m-2'} toggleable>
      <div className={'grid w-full'}>
        <div className={'col-3'}>
          <TextField fieldKey={'label'} control={control} onFieldChange={onFieldChange} errorMessage={errorMessage}
                     label={trans(t, 'form|patrimoine.contracts.default.label')} classes={'w-full'}/>
        </div>
        <div className={'col-3'}>
          <TextField fieldKey={'number'} control={control} onFieldChange={onFieldChange} errorMessage={errorMessage}
                     label={trans(t, 'form|patrimoine.contracts.default.number')} classes={'w-full'}/>
        </div>
        <div className={'col-3'}>
          <APIListField listKey={'contractSupportTypes'} fieldKey={'type'} control={control} onFieldChange={onFieldChange} errorMessage={errorMessage}
                        label={trans(t, 'form|patrimoine.contracts.default.type')} classes={'w-full'} disabled/>
        </div>
        {transfertDate && 'mono_support' !== type && (
          <div className={'col-3'}>
            <MaskField fieldKey={'transfertDate'} label={trans(t, 'form|patrimoine.contracts.default.transfertDate')}
                       control={control} onFieldChange={onFieldChange} errorMessage={errorMessage} isDate classes={'w-full'}/>
          </div>
        )}
      </div>

      <div className={'grid w-full'}>
        <div className={'col-3'}>
          <StaticListAutocompleteField classes={'w-full'} listKey={'banks'} listsOptions={listsOptions} fieldKey={'bank'} control={control} optionLabel={'name'} optionValue={'id'}
                                       onFieldChange={onFieldChange} errorMessage={errorMessage} label={trans(t, 'form|patrimoine.contracts.default.bank')}
                                       rules={{required: trans(t, 'form|requiredField')}}/>
        </div>
        <div className={'col-3'}>
          <MaskField fieldKey={'openingDate'} label={trans(t, 'form|patrimoine.contracts.default.openingDate')}
                     control={control} onFieldChange={onFieldChange} errorMessage={errorMessage} isDate classes={'w-full'}/>
        </div>
      </div>

      {'mono_support' === type && (
        <div className={'grid w-full'}>
          <div className={'col-3'}>
            <CheckboxField fieldKey={'transfert'} control={control} onFieldChange={onFieldChange} errorMessage={errorMessage} classes={'w-full'}
                           label={trans(t, 'form|patrimoine.contracts.default.transfertFourgous')} help={trans(t, 'form|patrimoine.contracts.default.transfertFourgousHelp')}/>
          </div>

          {transfert && (
            <div className={'col-3'}>
              <MaskField fieldKey={'transfertDate'} label={trans(t, 'form|patrimoine.contracts.default.transfertDate')}
                         control={control} onFieldChange={onFieldChange} errorMessage={errorMessage} isDate classes={'w-full'}/>
            </div>
          )}
        </div>
      )}

      <div className={'grid w-full'}>
        <div className={'col-3'}>
          <CheckboxField fieldKey={'avantageMatrimonialPreciput'} control={control} onFieldChange={onFieldChange} errorMessage={errorMessage} classes={'w-full'}
                         label={trans(t, 'form|patrimoine.contracts.default.avantageMatrimonialPreciput')}/>
        </div>
        <div className={'col-3'}>
          <CheckboxField fieldKey={'denouementSecondDeces'} control={control} onFieldChange={onFieldChange} errorMessage={errorMessage} classes={'w-full'}
                         label={trans(t, 'form|patrimoine.contracts.default.denouementSecondDeces')}/>
        </div>
      </div>
    </Fieldset>

    <Fieldset legend={ucfirst(trans(t, 'economic'))} className={'col-12 m-2'} toggleable>
      <div className={'grid w-full'}>
        <div className={'col-3'}>
          <NumberField fieldKey={'montantFondEuro'} control={control} onFieldChange={onFieldChange} errorMessage={errorMessage}
                       label={trans(t, 'form|patrimoine.contracts.default.montantFondEuro')} addon={'euro'} addonPosition={'after'} classes={'w-full'}/>
        </div>
        <div className={'col-3'}>
          <NumberField fieldKey={'montantFondUniteCompte'} control={control} onFieldChange={onFieldChange} errorMessage={errorMessage}
                       label={trans(t, 'form|patrimoine.contracts.default.montantFondUniteCompte')} addon={'euro'} addonPosition={'after'} classes={'w-full'}/>
        </div>
        <div className={'col-3'}>
          <NumberField fieldKey={'montantFondUniteCompteImmobilier'} control={control} onFieldChange={onFieldChange} errorMessage={errorMessage}
                       label={trans(t, 'form|patrimoine.contracts.default.montantFondUniteCompteImmobilier')} addon={'euro'} addonPosition={'after'} classes={'w-full'}/>
        </div>
        <div className={'col-3'}>
          <NumberField fieldKey={'montantVersementsAnnuels'} control={control} onFieldChange={onFieldChange} errorMessage={errorMessage}
                       label={trans(t, 'form|patrimoine.contracts.default.montantVersementsAnnuels')} addon={'euro'} addonPosition={'after'} classes={'w-full'}/>
        </div>
      </div>
    </Fieldset>
  </>
;
};
