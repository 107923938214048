import React, { useCallback, useState }from 'react';
import { Message } from 'primereact/message';
import { Button } from 'primereact/button';

import { isValidUUID, trans } from 'utilities';
import { NotFound } from 'pages';
import { IRequestParams, isSuperAdmin} from 'services';
import { PageLoader } from 'components';

import appUri from 'config/appUri.json';

export const SocieteClientDeleteForm = (props: any) => {
  const { company, apim } = props;
  const { t, navigate } = apim.di();
  const redirectUri: string = appUri.cie.cli.list;

  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string|null>(null);

  // Handle submission.
  const submit = () => {
    setLoading(true);
    setError(null);

    apim.deleteEntity({
      resourceType: 'dossierCompanies',
      id: isValidUUID(company.company?.id) ? company.company.id : company.id,
      success: () => {
        setLoading(false);
        navigate(redirectUri);
      },
      error: () => {
        setLoading(false);
      }
    } as IRequestParams).then();
  }

  // Wrap error message in a callback to refresh when error & existing company changes.
  const renderErrorMessage = useCallback(() => {
    if (error === null) {
      return null;
    }

    let errorMessage: any = <>
      <span>{error}</span>
    </>;

    return <>
      <Message severity="error" text={errorMessage} className={'mr-3 mb-5'} />
    </>;
  } , [error, company]); // eslint-disable-line react-hooks/exhaustive-deps

  if (!(isSuperAdmin())) {
    return <NotFound asBlock/>;
  }

  return (
    <div className={'card height-100 fadein animation-duration-500 flex flex-column'}>
      <div className={'mx-3'}>
        <h5 className={'pt-3 flex flex-row align-items-center'}>
          {trans(t, 'form|title.company.delete')}
        </h5>

        <p>
          {trans(t, 'form|text.company.intro3', 1, company?.raisonSociale ?? null)}<br/>
          {trans(t, 'form|text.company.intro4')}
        </p>

        {loading ? (
          <PageLoader />
        ) : (
          <>
            {renderErrorMessage()}

            <div className={'pt-4'}>
              <Button type={'reset'} className={'text-ucfirst p-button-text mr-5'} label={trans(t, 'cancel')} onClick={() => navigate(redirectUri)}/>
              <Button type={'submit'} className={'text-ucfirst p-button-danger mr-5'} label={trans(t, 'cie.delete')} onClick={() => submit()}/>
            </div>
          </>
        )}
      </div>
    </div>
  );
};
