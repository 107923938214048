import React, { useCallback, useEffect, useState } from 'react';
import { AxiosResponse } from 'axios';

import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Checkbox } from 'primereact/checkbox';

import { trans } from 'utilities';
import { IRequestParams, isClient } from 'services';
import { formatPieChart, formatTable, AMChartPie, getModuleId, IndicatorSimple, PageLoader } from 'components';
import { getIndicator } from 'pages';

import { isEmpty, replace } from 'lodash';
import appUri from 'config/appUri.json';
import appConfig from 'config/appConfig.json';

export const DonneesFiscales = (props: any) => {
  const { id, appState, apim, modulesMenu, lastUpdate } = props;
  const { t } = apim.di();

  const dossier = appState.dossier();
  const [loading, setLoading] = useState<boolean>(false);
  const [data, setData] = useState<any>(null);
  const [chart1Detail, setChart1Detail] = useState<boolean>(false);

  // Override Page Header (defaults into ModuleWrapper, then EntrepriseRouter).
  useEffect(() => {
    appState.setBreadcrumb([
      isClient()
       ? { label: trans(t, 'dossier', 2) }
       : { label: trans(t, 'dossier', 2), to: appUri.dos.list },
      { label: dossier.title, to: replace(appUri.dos.page, ':id', id)},
      { label: trans(t, 'menu|menu.schema_flux.entreprise_df') }
    ]);

    appState.setModule(getModuleId(modulesMenu, 'donnees.fiscales'));
  }, [id]); // eslint-disable-line react-hooks/exhaustive-deps

  const fetchData = useCallback(async () => {
    if (!appState.exercise()) return null;
    setLoading(true);

    apim.call({
      resourceType: 'dossiers',
      action: 'module',
      id: id + '|' + getModuleId(modulesMenu, 'donnees.fiscales'),
      method: 'get',
      params: [{label: 'company', value: appState.company()?.id}, {label: 'year', value: appState.exercise()}],
      cacheMode: isEmpty(lastUpdate) ? 'default' : 'replace',
      setLoading: setLoading,
      success: (res: AxiosResponse) => setData({...res.data, ...{
          chart1: {
            light: formatPieChart(res.data, 'fiscalData.chart.repartitionDesRessources', t),
            full: formatPieChart(res.data, 'fiscalData.chart.detailsRepartitionDesRessources', t)
          },
          table1: formatTable(res.data, 'fiscalData.table.fiscalData', t, {method: 'montant', tradOnlyFirstHeader: false}),
          table2: formatTable(res.data, 'fiscalData.table.fiscalDataHistory', t, {method: 'asIt', disableNull: false})
      }})
    } as IRequestParams);
  }, [appState.company()?.id, appState.exercise(), lastUpdate]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    fetchData().then();
  }, [appState.company()?.id, appState.exercise(), lastUpdate]); // eslint-disable-line react-hooks/exhaustive-deps

  if (loading) return <PageLoader />;

  const onChart1DetailChange = (e: any) => setChart1Detail(e.checked || false);

  const isSelectable = (data: any) => !data.disabled;
  const isRowSelectable = (event: any) => (event.data ? isSelectable(event.data) : true);
  const rowClassName = (data: any) => (isSelectable(data) ? '' : 'p-disabled');

  const getCellAlign = (i: number) => {
    switch (i) {
      case 0: return 'left';
      case 1: return 'right';
      default: return 'center';
    }
  };

  return (
    <div className={'flex flex-column xl:flex-row flex-wrap fadein animation-duration-500'}>
      {data?.table1?.data?.length > 0 && (
        <div className={'flex xl:w-6 pb-4 pl-2 pr-3'}>
          <div className={'grid p-fluid w-full'}>
            <div className={'col-12'}>
              <DataTable
                value={data?.table1?.data} className={'datatable-responsive'} stripedRows={true}
                isDataSelectable={isRowSelectable} rowClassName={rowClassName}>
                {(data?.table1?.headers || []).map((h: string, i: number) => (
                  <Column key={i} header={h} field={'column-' + (i + 1)} align={getCellAlign(i)} bodyStyle={0 === i ? {} : {fontWeight: 600}} alignHeader={getCellAlign(i)} />
                ))}
              </DataTable>
            </div>
          </div>
        </div>
      )}

      {data?.chart1?.full?.data?.length > 0 && (
        <div className={'flex xl:w-6 py-4 px-3'}>
            <div className={'flex flex-column xl:flex-row flex-grow-1'}>
              <div className={'flex align-items-start'} >
                <Checkbox inputId={'chart1detail'} name={'chart1detail'} onChange={onChart1DetailChange} checked={chart1Detail} />
                <label htmlFor={'chart1detail'} className={'ml-2'}>{trans(t, 'chart|afficherDetail')}</label>
              </div>
              {window.innerWidth < appConfig.breakpoints.tablet ? (
                <AMChartPie chartData={chart1Detail ? data?.chart1?.full : data?.chart1?.light } verticalLegend />
              ) : (
                <AMChartPie chartData={chart1Detail ? data?.chart1?.full : data?.chart1?.light} hideLegend />)}
            </div>
        </div>
      )}

      <div className={'flex flex-column md:flex-row flex-wrap w-12 '}>
        <div className={'flex md:w-4 py-4 pl-2 px-3'}>
          <IndicatorSimple color={'accent2'} bgImage title={trans(t, 'chart|fiscalData.tauxMoyenImposition') + ' - ' + getIndicator(data, 'fiscalData.tauxMoyenImposition').badge} value={getIndicator(data, 'fiscalData.tauxMoyenImposition').value} options={{symbol: '%', precision: 1}} />
        </div>

        <div className={'flex md:w-4 py-4 px-3'}>
          <IndicatorSimple color={'accent2'} bgImage title={trans(t, 'chart|fiscalData.tauxPs')} value={getIndicator(data, 'fiscalData.tauxPs').value} options={{symbol: '%', precision: 1}} />
        </div>

        <div className={'flex md:w-4 py-4 px-3'}>
          <IndicatorSimple color={'primary'} bgImage title={trans(t, 'chart|fiscalData.tva')} value={trans(t, 'chart|' + getIndicator(data, 'fiscalData.tva').value)} method={'list'} methodParams={{listKey: 'regimesTVA'}}/>
        </div>

        <div className={'flex md:w-4 py-4 pl-3 px-3'}>
          <IndicatorSimple color={'accent'} bgImage title={trans(t, 'chart|fiscalData.regimeImposition')} value={trans(t, 'chart|' + getIndicator(data, 'fiscalData.regimeImposition').value)} method={'list'} methodParams={{listKey: 'regimesImposition'}} />
        </div>

        <div className={'flex md:w-4 py-4 pl-2 px-3'}>
          <IndicatorSimple color={'accent2'} bgImage title={trans(t, 'chart|fiscalData.tauxIsReel')} value={getIndicator(data, 'fiscalData.tauxIsReel').value} options={{symbol: '%', precision: 1}} />
        </div>

        <div className={'flex md:w-4 py-4 px-3'}>
          <IndicatorSimple color={'primary'} dark bgImage title={trans(t, 'chart|fiscalData.NsCsDiv')} value={getIndicator(data, 'fiscalData.NsCsDiv').value} />
        </div>
      </div>

      <div className={'grid p-fluid w-full'}>
        <div className={'col-12'}>
          <h5 className={'mt-0 text-right text-color-secondary'}>{trans(t, 'table|repartitionRessourcesTitle')}</h5>
          <DataTable
            value={data?.table2?.data} className={'datatable-responsive'} stripedRows={true}
            isDataSelectable={isRowSelectable} rowClassName={rowClassName}>
            {(data?.table2?.headers || []).map((h: string, i: number) => (
              <Column key={i} header={h} field={'column-' + (i + 1)} align={0 === i ? 'left' : 'right'} bodyStyle={0 === i ? {} : {fontWeight: 600}} alignHeader={0 === i ? 'left' : 'right'} />
            ))}
          </DataTable>
        </div>
      </div>
    </div>
  );
};
