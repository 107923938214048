import React, { useState } from 'react';
import { AxiosError, AxiosResponse } from 'axios';

import { Button } from 'primereact/button';
import { classNames } from 'primereact/utils';
import { Toolbar } from 'primereact/toolbar';
import { Tooltip } from 'primereact/tooltip';
import { Message } from 'primereact/message';
import { Dialog } from 'primereact/dialog';
import { AutoComplete } from 'primereact/autocomplete';
import { InputMask } from 'primereact/inputmask';
import { InputText } from 'primereact/inputtext';

import { dateString, onApiError, trans, ucfirst } from 'utilities';
import { unState } from 'states';
import { IRequestParams } from 'services';
import { getCompanies, updateCompany } from 'forms/dossier';
import { UncontrolledAPIListField } from 'forms';

import { forEach } from 'lodash';

export const Step2 = (props: any) => {
  const { formState, formKey, apim, previous, next } = props;
  const { t, navigate, toastError } = apim.di();

  const parentKey = 'step2';

  // Make sure the form data is properly set.
  const dossierCreationId = formState.getFieldData(formKey, 'dossierCreationId');
  if (dossierCreationId === null || dossierCreationId === undefined) {
    formState.reloadFormData(formKey);
  }

  const formDataCompanies = formState.getFieldData(formKey, 'companies') || [];
  const formDataSelectedCompanies = formState.getFieldData(formKey, 'selected', parentKey) || [];
  const defaultCompanyData: any = {name: null, siren: null, created: null, type: null, nafCode: null, nafCodeLabel: null};
  const defaultCompanyDataInvalid: any = {name: false, siren: false, created: false, type: false};
  const [selected, setSelected] = useState<string[]>(formDataSelectedCompanies);
  const [companies, setCompanies] = useState<string[]>(formDataCompanies);

  const [companyData, setCompanyData] = useState<any>(defaultCompanyData);
  const [companyDataInvalid, setCompanyDataInvalid] = useState<any>(defaultCompanyDataInvalid);
  const [companyDialogVisible, setCompanyDialogVisible] = useState<boolean>(false);

  const [suggestions, setSuggestions] = useState<any[]>([]);
  const [selectedSuggestion, setSelectedSuggestion] = useState<any>(null);
  const [searchDialogVisible, setSearchDialogVisible] = useState<boolean>(false);
  const [searchDialogInvalid, setSearchDialogInvalid] = useState<boolean>(false);

  const onSubmit = () => {
    if (formState.isLoading(formKey)) return;

    // Keep the backend up to date & get companies files.
    formState.setLoading(formKey, true);

    // Extract manually added companies.
    let _companiesDetails: any[] = [];
    forEach(companies, (company: any) => {
      if (company.manuallyAdded) {
        _companiesDetails.push(company);
      }
    });

    apim.call({
      resourceType: 'wizardFormDossier',
      action: 'step2',
      data: {
        id: formState.getFieldData(formKey, 'dossierCreationId'),
        companies: selected,
        companiesDetails: _companiesDetails,
      },
      notif: false,
      error: (error: AxiosError) => {
        onApiError(t, error, toastError, {});
        formState.setLoading(formKey, false)
      },
      success: (res: AxiosResponse) => {
        if (!res?.data) return;

        (getCompanies(formState, false) || []).map((c: any) => {
          const existing = (res?.data?.existingCompanies[c.siren] || false);
          const filesFetched = c.filesFetched;

          const companyFiles = (res?.data?.companiesDocuments[c.siren] || []).map((doc: any) => {
            return {
              id: doc.id,
              path: doc.filename,
              name: doc.title,
              source: doc.source !== null && doc.source !== undefined ? doc.source : 'local',
              created: doc.created,
              date: doc.date,
              dateSort: doc.date,
              comment: doc.description,
              active: doc.active || true,
              status: doc.fileStatus,
              extension: doc.extension,
              type: doc.type
            }
          });

          return updateCompany(formState, {
            ...c,
            ...{
              filesFetched: filesFetched,
              files: companyFiles,
              selectedFiles: companyFiles.map((csf: any) => csf.id),
              existing: existing,
            }});
        });

        formState.setLoading(formKey, false);
        const currentSiren = formState.getFieldData(formKey, 'currentCompany');
        navigate(currentSiren ? next + '/' + currentSiren : next);
      }
    } as IRequestParams).then();
  };

  const toggleSelected = (siren: string) => {
    let newSelected: string[] = unState(selected);
    if (selected.indexOf(siren) > -1) {
      newSelected = selected.filter((s: string) => s !== siren);
    } else {
      newSelected.push(siren);
      formState.setFieldData(formKey, 'currentCompany', null, siren);
    }

    setSelected(newSelected);
    formState.setFieldData(formKey, 'selected', parentKey, newSelected);
  };

  const clearToggleSelected = (siren: string) => {
    let _selected: string[] = [];
    selected.map((s: string) => {
      if (s !== siren) {
        _selected.push(s);
      }

      return null;
    });

    setSelected(_selected);
    formState.setFieldData(formKey, 'selected', parentKey, _selected);
  };

  const startContent = (
    <h5>{trans(t, 'menu|wizard.dossier.title.step2')}</h5>
  );

  const endContent = (
    <>
       <span className={'mr-2'}>
        <Tooltip target={'.a8-dossier-search-toggle'} position={'top'} mouseTrack />
         <Button icon={'pi pi-search'} onClick={(e) => showSearchDialog(e)} data-pr-tooltip={ucfirst(trans(t, 'company_searchBySiren'))} data-pr-position={'left'} data-pr-at={'left'} data-pr-my={'left'} className={'a8-dossier-search-toggle'} rounded />
       </span>
      <span>
        <Tooltip target={'.a8-dossier-add-toggle'} position={'top'} mouseTrack />
        <Button icon={'pi pi-plus'} onClick={(e) => showCompanyDialog(e)} data-pr-tooltip={ucfirst(trans(t, 'company_manuallyAdd'))} data-pr-position={'left'} data-pr-at={'left'} data-pr-my={'left'} className={'a8-dossier-add-toggle'} rounded />
      </span>
    </>
  );

  const showCompanyDialog = (e: any) => setCompanyDialogVisible(true);
  const showSearchDialog = (e: any) => setSearchDialogVisible(true);

  /**
   * Manually add a company.
   */
  const addCompany = () => {
    let _companyDataInvalid = defaultCompanyDataInvalid;
    let _companies : any[] = [];
    forEach(companies, (company: any) => {
      _companies.push(company);
    });

    let blockSubmit = false;
    if (companyData.siren === null) {
      _companyDataInvalid.siren = true;
      blockSubmit = true;
    } else {
      _companyDataInvalid.siren = false;
      forEach(_companies, (_company: any) => {
        if (companyData.siren === _company.siren) {
          _companyDataInvalid.siren = true; return false;
        }
      });
    }

    if (companyData.name === null) {
      _companyDataInvalid.name = true;
      blockSubmit = true;
    } else {
      _companyDataInvalid.name = false;
    }

    if (companyData.type === null) {
      _companyDataInvalid.type = true;
      blockSubmit = true;
    } else {
      _companyDataInvalid.type = false;
    }

    if (companyData.created === null) {
      _companyDataInvalid.created = true;
      blockSubmit = true;
    } else {
      _companyDataInvalid.created = false;
    }

    setCompanyDataInvalid(_companyDataInvalid);

    if (!blockSubmit) {
      // Remove spaces in siren number.
      companyData.siren = companyData.siren.replace(/ /g, '');
      companyData.manuallyAdded = true;

      _companies.push(companyData);
      setCompanies(_companies);

      toggleSelected(companyData.siren);
      setCompanyData(defaultCompanyData);
      setCompanyDialogVisible(false);
      formState.setFieldData(formKey, 'companies', null, _companies);
    }
  }

  /**
   * Remove a manually added company.
   */
  const deleteCompany = (company: any) => {
    let _companies : any[] = [];
    forEach(companies, (c: any) => {
      if (c.siren !== company.siren) {
        _companies.push(c);
      }
    });

    setCompanies(_companies);

    clearToggleSelected(companyData.siren);
    formState.setFieldData(formKey, 'companies', null, _companies);
  }

  /**
   * Search for a company (from search dialog).
   */
  const searchCompany = (e: any) => {
    const query = e.query;
    if (query.length > 0) {
      let params = [];
      if (!isNaN(parseFloat(query))) {
        params.push({ label: 'siren', value: query });
      } else {
        params.push({ label: 'name', value: query });
      }

      apim.call({
        resourceType: 'companies',
        action: 'autocomplete',
        method: 'get',
        params: params,
        notif: false,
        error: (error: AxiosError) => {
          formState.setLoading(formKey, false)
          setSuggestions([]);
        },
        setter: setSuggestions
      } as IRequestParams).then();
    }
  }

  /**
   * Add the selected company to the list (from search dialog).
   */
  const searchSelectCompany = () => {
    let _companies : any[] = [];
    forEach(companies, (company: any) => {
      _companies.push(company);
    });

    let found = false;
    forEach(_companies, (_company: any) => {
      if (selectedSuggestion !== null && selectedSuggestion !== undefined
        && selectedSuggestion.siren === _company.siren) {
        found = true; return false;
      }
    });

    if (!found) {
      if (selectedSuggestion !== null && selectedSuggestion !== undefined) {
        selectedSuggestion.manuallySearched = true;
        _companies.push(selectedSuggestion);

        setCompanies(_companies)
        toggleSelected(selectedSuggestion.siren);
        setSelectedSuggestion(null);
        setSearchDialogVisible(false);
        formState.setFieldData(formKey, 'companies', null, _companies);
      }
    } else {
      setSearchDialogInvalid(true);
    }
  }

  const companyTemplate = (company: any) => {
    return (
      <div className={'flex align-items-center'}>
        <div>{company.autocomplete}</div>
      </div>
    );
  };

  return (
    <div className={'a8-wizard wizard-dossier card fadein animation-duration-500'}>
      <Toolbar start={startContent} end={endContent}/>
      <p className={'mx-2 mt-0 mb-3'}><i className={'pi pi-info-circle mr-2 mt-1 text-primary'}></i> {trans(t, 'menu|wizard.dossier.title.step2Help')}</p>

      <div className={'grid m-2 mr-3 mb-5'}>
        {companies.map((company: any, index: number) => (
          <div key={index} onClick={(e) => toggleSelected(company.siren)} className={'col-12 md:col-6 lg:col-4'}>
            <div className={classNames('card company-card cursor-pointer bgh-primary-lightest', {
              'border-2 border-primary': selected.indexOf(company.siren) > -1
            })} style={{height: '100%'}}>
              <div className={'card-header mb-2'}>
                <span className={'company-avatar avatar-lg flex-initial'}>
                  <i className={'pi pi-fw pi-briefcase mr-2 text-4xl text-color-secondary'}/>
                </span>

                <h5 className={'card-title text-primary flex-initial'}>
                  {company.name !== null && company.name !== undefined && company.name.length > 0 ? (
                    company.name + ' (' + company.siren + ')'
                  ) : (
                    company.siren
                  )}
                </h5>

                <h6 className={'lg:hidden xl:block card-meta text-right min-w-max ml-5 flex-auto'}>
                  {dateString(company.created)}
                </h6>

                {company.manuallyAdded && (
                  <Button className={'ml-2'} size={'small'} type={'button'} severity={'danger'} icon={'pi pi-trash'} aria-label={ucfirst(trans(t, 'validate'))} onClick={(e) => deleteCompany(company)}/>
                )}
              </div>
              <div className={'card-footer text-color-secondary mt-4'}>
                {(company.type || company.nafCodeLabel || company.nafCode) && (
                  <>
                    {company.type && (
                      <span>{company.type}</span>
                    )}

                    {company.type && company.nafCodeLabel && company.nafCode && (
                      <span> - </span>
                    )}

                    {company.nafCodeLabel && company.nafCode && (
                      <span>{company.nafCodeLabel + ' (' + company.nafCode + ')'}</span>
                    )}

                    {company.stopped && (
                      <div className={'text-danger font-bold mt-2'}>Radiée le {dateString(company.stopped)}</div>
                    )}

                    {company.manuallyAdded && (
                      <div className={'text-color-secondary font-bold mt-2'}>Ajoutée manuellement</div>
                    )}
                  </>
                )}
              </div>
            </div>
          </div>
        ))}

        {companies.length === 0 && (
          <Message text={ucfirst(trans(t, 'company_empty')) + '. ' + ucfirst(trans(t, 'company_empty_add_more')) + '.'} className={'w-full'}/>
        )}
      </div>

      <div className={'text-right m-2 mr-3'}>
        <Button className={'text-ucfirst p-button-text mr-5'} label={trans(t, 'previous')} onClick={() => navigate(previous)}/>
        <Button className={'text-ucfirst'} label={trans(t, 'next')} icon={'pi pi-angle-right'} loading={formState.isLoading(formKey)} onClick={onSubmit}/>
      </div>

      <Dialog content={''} header={ucfirst(trans(t, 'form|company.searchToAdd'))} visible={searchDialogVisible} onHide={() => setSearchDialogVisible(false)} style={{width: '400px'}}>
        <div className={'flex pt-3 px-1'}>
          <div className={'flex w-full'}>
            <span className={'p-float-label w-full'}>
             <AutoComplete field={'autocomplete'} inputStyle={{width: '100%'}} className={classNames('w-full', searchDialogInvalid ? ' p-invalid' : '')}
                           inputId={'autocomplete-siren'} value={selectedSuggestion} suggestions={suggestions} completeMethod={searchCompany}
                           onChange={(e) => setSelectedSuggestion(e.value)} itemTemplate={companyTemplate} forceSelection={true}/>
             <label htmlFor={'autocomplete-siren'}>{ucfirst(trans(t, 'form|company.searchSirenOrName'))}</label>
            </span>

            <Button className={'ml-2'} type={'submit'} icon={'pi pi-check'} aria-label={ucfirst(trans(t, 'validate'))} onClick={(e) => searchSelectCompany()}/>
          </div>
        </div>
      </Dialog>

      <Dialog content={''} header={ucfirst(trans(t, 'form|company.create'))} visible={companyDialogVisible} onHide={() => setCompanyDialogVisible(false)} style={{width: '400px'}}>
        <div className={'pt-4 px-1'}>
          <div className={'field w-full'}>
            <span className={'p-float-label w-full'}>
              <InputMask id={'siren_input'} className={classNames('w-full', companyDataInvalid.siren ? ' p-invalid' : '')} value={companyData.siren ?? ''} onChange={(e) => setCompanyData({...companyData, siren: e.value})} mask={'999 999 999'} autoClear={false}/>
              <label htmlFor={'siren_input'}>{ucfirst(trans(t, 'form|company.siren'))}</label>
            </span>
          </div>

          <div className={'field w-full'}>
            <span className={'p-float-label w-full'}>
              <InputText id={'name_input'} className={classNames('w-full', companyDataInvalid.name ? ' p-invalid' : '')} value={companyData.name ?? ''} onChange={(e) => setCompanyData({...companyData, name: e.target.value})}/>
              <label htmlFor={'name_input'}>{ucfirst(trans(t, 'form|company.name'))}</label>
            </span>
          </div>

          <div className={'field w-full'}>
            <span className={'p-float-label w-full'}>
              <UncontrolledAPIListField listKey={'societeTypes'} fieldKey={'type'} className={classNames('w-full', companyDataInvalid.type ? ' p-invalid' : '')} value={companyData.type ?? ''} onFieldChange={(value: any) => setCompanyData({...companyData, type: value})}/>
            </span>
          </div>

          <div className={'field w-full'}>
            <span className={'p-float-label w-full'}>
              <InputMask id={'created_input'} className={classNames('w-full', companyDataInvalid.created ? ' p-invalid' : '')} value={companyData.created} onChange={(e) => setCompanyData({...companyData, created: e.target.value})} mask={'99/99/9999'} autoClear={false}/>
              <label htmlFor={'created_input'}>{ucfirst(trans(t, 'form|company.created'))}</label>
            </span>
          </div>

          <Button className={'w-full py-3 my-3'} type={'submit'} label={ucfirst(trans(t, 'validate'))} onClick={(e) => addCompany()}/>
        </div>
      </Dialog>
    </div>
  );
};
