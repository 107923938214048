import React, { useCallback, useEffect, useState } from 'react';
import { useParams, useLocation, useSearchParams } from 'react-router-dom';
import { TFunction } from 'i18next';
import { TabMenu } from 'primereact/tabmenu';

import { IRequestParams, isAdmin, isClient, useApim, isSuperAdmin } from 'services';
import { useAppState, useFormState, useUserState } from 'states';
import { DossiersDatatable, PageLoader, SocietesExpertDatatable } from 'components';
import { Error, NotFound } from 'pages';
import { ExpertFormRouter } from 'forms';
import { addShortcut, dialog, isValidUUID, trans, ucfirst, impersonateDialog } from 'utilities';
import { Expert } from './Expert';

import { includes, isEmpty, forEach } from 'lodash';
import appUri from 'config/appUri.json';

export const ExpertRouter = (props: any) => {
  const { action } = props;
  const { id, tab } = useParams();

  const apim = useApim();
  const userState = useUserState();

  const { t, navigate } = apim.di();
  const location = useLocation();
  const appState = useAppState();
  const formState = useFormState();

  const [loading, setLoading] = useState<boolean>(false);
  const [data, setData] = useState<any>();
  const [notFound, setNotFound] = useState<boolean>(false);
  const [errored, setErrored] = useState<boolean>(false);

  // Let's detect if we are in "edit/add" mode
  // then render the appropriate display (edit form or module dashboard).
  const editMode = appUri.reservedKeywords.edit === action;
  const addMode = appUri.reservedKeywords.add === action;

  // Handle tabs.
  const [activeIndex, setActiveIndex] = useState<number>(0);
  const tabs: any[] = [];
  if (id) {
    const baseUri = appUri.usr.exp.page.replace(':id', id || '_');
    tabs.push({label: trans(t, 'menu|tab.general'), command: () => navigate(baseUri)});

    forEach(appUri.usr.exp.tabs, (ta) => {
      tabs.push({label: trans(t, 'menu|tab.' + ta), command: () => navigate(baseUri + '/' + ta)});
    });
  }

  // Validate URL & build tabs.
  const checks = useCallback(() => {
    return isUrlValid(t, id, tab);
  }, [id, tab]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (!checks()) setNotFound(true);

    let ai = 0;
    forEach(appUri.usr.exp.tabs, (ta, index) => {
      if (tab === ta) ai = index + 1;
    });

    if (ai !== activeIndex) setActiveIndex(ai);
  }, [id, tab]); // eslint-disable-line react-hooks/exhaustive-deps

  // Let's detect if API calls from now have to be refreshed (cache expiration).
  const [searchParams] = useSearchParams();
  const lastUpdate = searchParams.get('maj');
  const fetchData = useCallback(() => {
    if (!id || editMode || addMode) return;

    setLoading(true);
    setNotFound(false);

    apim.fetchEntity({
      resourceType: 'users',
      id: id,
      cacheMode: isEmpty(lastUpdate) ? 'default' : 'replace',
      setLoading: setLoading,
      setter: setData,
      setErrored: setErrored,
      setNotFound: setNotFound
    } as IRequestParams).then();
  }, [id, lastUpdate]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (!data || !id || (data.id !== id) || lastUpdate) {
      fetchData();
    } else {
      setLoading(false);
    }
  }, [id, lastUpdate]); // eslint-disable-line react-hooks/exhaustive-deps

  // Update Page Header.
  useEffect(() => {
    let pageTitle: string = ucfirst(trans(t, 'expert')) + ' ' + (data ? data.firstName + ' ' + data.lastName : '');
    if (editMode) {
      pageTitle += ' | ' + trans(t, editMode ? 'edit' : 'adding');
      appState.setPageActions([]);
    } else if (!addMode) {
      const newPA: any[] = [
        { label: trans(t, 'short.add'), icon: 'bookmark', command: () => addShortcut(t, formState, appState) },
      ];
      if (isSuperAdmin()) {
        newPA.push({label: trans(t, 'exp.impersonate'), icon: 'users', command: () => impersonateDialog(t, apim, userState, data)});
      }
      if (!isClient()) {
        newPA.push({ label: trans(t, 'exp.edit'), icon: 'pencil', to: appUri.usr.exp.edit.replace(':id', id || '_') });
      }
      if (isAdmin()) {
        newPA.push({ label: trans(t, 'exp.add'), icon: 'plus', to: appUri.usr.exp.add, className:'bg-green-500' });
      }
      if (isSuperAdmin()) {
        newPA.push({ label: trans(t, 'exp.delete'), icon: 'trash', className:'bg-red-500', command: () =>
            dialog(t, {
              message: ucfirst(trans(t, 'system|confirmations.user.delete')),
              accept: () => {
                if (data?.id) {
                  apim.deleteEntity({
                    resourceType: 'users',
                    id: data?.id,
                    success: () => {
                      navigate(appUri.usr.exp.list);
                    },
                  } as IRequestParams);
                }
              },
              acceptClassName: 'p-button-danger',
              rejectClassName: 'p-button-text p-button-primary'
            })
        });
      }
      appState.setPageActions(newPA);
    }
    appState.setPageTitle(pageTitle);
    appState.setBreadcrumb([{ label: trans(t, 'expert', 2), to: appUri.usr.exp.list }, { label: data ? data.firstName + ' ' + data.lastName : trans(t, 'expert') }]);
  }, [data, lastUpdate, userState.id()]); // eslint-disable-line react-hooks/exhaustive-deps

  const buildTabContent = useCallback(() => {
    switch (activeIndex) {
      case 1:
        // Dossiers management.
        return <DossiersDatatable appState={appState} tableKey={'experts-dossiers'} title={trans(t, 'menu|pages.title.expertDossiersLists')}
                                  params={[{ label: 'experts.id', value: data?.id }]} noFilters apim={apim}/>;
      case 2:
        // Sociétés expert management.
        return <SocietesExpertDatatable appState={appState} tableKey={'expert-societes-expert'} title={trans(t, 'menu|pages.title.expertSocietesExpert')} noFilters apim={apim} context={{expert: data}} />;

      default:
        // Profile.
        return <Expert t={t} data={data}/>;
    }
  }, [id, tab, activeIndex, data]); // eslint-disable-line react-hooks/exhaustive-deps

  if (editMode || addMode) return <ExpertFormRouter id={id} tab={tab} appState={appState} apim={apim} location={location} action={action}/>
  if (notFound) return <NotFound asBlock/>;
  if (errored) return <Error asBlock/>;
  if (loading) return <PageLoader/>;

  return (
    <div className={'card height-100 fadein animation-duration-500 flex flex-column'}>
      <div className={'a8-page-header flex flex-wrap'}>
        <div className={'flex-auto flex p-2 justify-content-end pt-0'}>
          {tabs.length > 1 && <TabMenu className={'pb-2'} model={tabs} activeIndex={activeIndex} onTabChange={(e) => setActiveIndex(e.index)}/>}
        </div>
      </div>
      {buildTabContent()}
    </div>
  );
};

const isUrlValid = (t: TFunction, id: string | undefined, tab: string | undefined) => {
  // Ensure entity UUID.
  return isValidUUID(id) && includes([...[undefined], ...appUri.usr.exp.tabs], tab);
};
