import React from 'react';
import { trans } from 'utilities';
import { APIListField, CheckboxField } from 'forms';

export const CGOptionForm = (props: any) => {
  const { t, control, onFieldChange, errorMessage, getValues } = props;
  const { couvertureAffectionsPsy, couverturePathologiesDos, couvertureTempsPartielTherapeutique } = getValues();

  return <div className={'grid w-full px-3'}>
    <div className={'col-3'}>
      <CheckboxField classes={'w-full'} fieldKey={'couvertureAffectionsPsy'} control={control} onFieldChange={onFieldChange} errorMessage={errorMessage} label={trans(t, 'form|prevoyance_contrat.couvertureAffectionsPsy')}/>
      {true === couvertureAffectionsPsy && (
        <>
          <APIListField classes={'w-full'} listKey={'prevoyanceContractCarences'} fieldKey={'couvertureAffectionsPsyCarence'} control={control} onFieldChange={onFieldChange} errorMessage={errorMessage}
                        label={trans(t, 'form|prevoyance_contrat.couvertureAffectionsPsyCarence')} showClear rules={{required: trans(t, 'form|requiredField')}}/>
          <APIListField classes={'w-full'} listKey={'prevoyanceContractConditions'} fieldKey={'couvertureAffectionsPsyCondition'} control={control} onFieldChange={onFieldChange} errorMessage={errorMessage}
                        label={trans(t, 'form|prevoyance_contrat.couvertureAffectionsPsyCondition')} showClear rules={{required: trans(t, 'form|requiredField')}}/>
          <APIListField classes={'w-full'} listKey={'prevoyanceContractDurations'} fieldKey={'couvertureAffectionsPsyDuration'} control={control} onFieldChange={onFieldChange} errorMessage={errorMessage}
                        label={trans(t, 'form|prevoyance_contrat.couvertureAffectionsPsyDuration')} showClear rules={{required: trans(t, 'form|requiredField')}}/>
        </>
      )}
    </div>

    <div className={'col-3'}>
      <CheckboxField classes={'w-full'} fieldKey={'couverturePathologiesDos'} control={control} onFieldChange={onFieldChange} errorMessage={errorMessage} label={trans(t, 'form|prevoyance_contrat.couverturePathologiesDos')}/>
      {true === couverturePathologiesDos && (
        <>
          <APIListField classes={'w-full'} listKey={'prevoyanceContractCarences'} fieldKey={'couverturePathologiesDosCarence'} control={control} onFieldChange={onFieldChange} errorMessage={errorMessage}
                        label={trans(t, 'form|prevoyance_contrat.couvertureAffectionsDosCarence')} showClear rules={{required: trans(t, 'form|requiredField')}}/>
          <APIListField classes={'w-full'} listKey={'prevoyanceContractConditions'} fieldKey={'couverturePathologiesDosCondition'} control={control} onFieldChange={onFieldChange} errorMessage={errorMessage}
                        label={trans(t, 'form|prevoyance_contrat.couvertureAffectionsDosCondition')} showClear rules={{required: trans(t, 'form|requiredField')}}/>
          <APIListField classes={'w-full'} listKey={'prevoyanceContractDurations'} fieldKey={'couverturePathologiesDosDuration'} control={control} onFieldChange={onFieldChange} errorMessage={errorMessage}
                        label={trans(t, 'form|prevoyance_contrat.couvertureAffectionsDosDuration')} showClear rules={{required: trans(t, 'form|requiredField')}}/>
        </>
      )}
    </div>

    <div className={'col-3'}>
      <CheckboxField classes={'w-full'} fieldKey={'couvertureTempsPartielTherapeutique'} control={control} onFieldChange={onFieldChange} errorMessage={errorMessage} label={trans(t, 'form|prevoyance_contrat.couvertureTempsPartielTherapeutique')}/>
      {true === couvertureTempsPartielTherapeutique && (
        <>
          <APIListField classes={'w-full'} listKey={'prevoyanceContractCarences'} fieldKey={'couvertureTempsPartielTherapeutiqueCarence'} control={control} onFieldChange={onFieldChange} errorMessage={errorMessage}
                        label={trans(t, 'form|prevoyance_contrat.couvertureTempsPartielTherapeutiqueCarence')} showClear rules={{required: trans(t, 'form|requiredField')}}/>
          <APIListField classes={'w-full'} listKey={'prevoyanceContractDurationsBis'} fieldKey={'couvertureTempsPartielTherapeutiqueDuration'} control={control} onFieldChange={onFieldChange} errorMessage={errorMessage}
                        label={trans(t, 'form|prevoyance_contrat.couvertureTempsPartielTherapeutiqueDuration')} showClear rules={{required: trans(t, 'form|requiredField')}}/>
        </>
      )}
    </div>
  </div>;
};
