import React, { useRef } from 'react';
import { CSSTransition } from 'react-transition-group';

import { UserMenu } from 'components';
import { Avatar } from 'primereact/avatar';

export const AppTopbarUser = (props: any) => {
  const { userState, apim, themeState } = props;
  const { navigate } = apim.di();
  const topbarRef = useRef(null);
  const userMenu = UserMenu({userState: userState, apim: apim});

  return (
    <>
      <button
        className={'layout-topbar-action flex flex-row justify-content-center align-items-center px-0 rounded-circle p-link'}
        onClick={(event) =>
          themeState.onTopbarItemClick({
            originalEvent: event,
            item: 'profile'
          })
        }
      >
        <Avatar label={userState.initials()} size={'large'} shape={'circle'} image={userState.pictureUrl()} />
      </button>

      <CSSTransition nodeRef={topbarRef} classNames={'p-toggleable'} timeout={{ enter: 1000, exit: 450 }} in={themeState.activeTopbarItem() === 'profile'} unmountOnExit>
        <ul ref={topbarRef} className={'layout-topbar-action-panel shadow-6'}>
          {userMenu.map((item: any, index) => (
            <li key={index} className={'layout-topbar-action-item'}>
              <button className={'flex flex-row align-items-center p-link'} onClick={() => item.to ? navigate(item.to) : item.command()}>
                <i className={'pi mr-2 pi-' + item.icon}></i>
                <span>{item.label}</span>
              </button>
            </li>
          ))}
        </ul>
      </CSSTransition>
    </>
  );
};
