import { subscribe } from 'services';

const formKey = 'wf_dossier';

export const getCompanies = (formState: any, sort: boolean = true): any[] => {
  const companies: any[] = formState.getFieldData(formKey, 'companies') || [];
  return sort ? companies.sort((a: any, b: any) => a.raisonSociale > b.raisonSociale ? 1 : -1) : companies;
};

export const getCurrentCompany = (formState: any): null|any => {
  const current = formState.getFieldData(formKey, 'currentCompany');
  const companies = getCompanies(formState, false);
  if (current) {
    const comp = companies.filter((c: any) => c.siren === current);
    if (comp.length > 0) return comp[0];
  }

  return companies.length > 0 ? companies[0] : null;
};

export const updateCompany = (formState: any, company: any) => {
  const newCompanies = getCompanies(formState, false).filter((c: any) => c.siren !== company.siren);
  newCompanies.push(company);
  formState.setFieldData(formKey, 'companies', null, newCompanies);
};

export const subscribeToHub = (formState: any) => {
  const dossierCreationId = formState.getFieldData(formKey, 'dossierCreationId');
  if (!dossierCreationId) return;

  const subscribed = formState.hasSubscribed(formKey);
  if (subscribed) return;

  // Subscribe to the hub.
  subscribe(['/dossier_creations/' + dossierCreationId], ((m: any) => {
    if (!m.data) return;

    const data = JSON.parse(m.data);
    if (data?.status !== 'processed') return;

    const relatedCompany = data.context?.company ?
      getCompanies(formState,false).filter((c: any) => c.siren === data.context.company)[0] :
      getCurrentCompany(formState);
    if (!relatedCompany) return;

    const relatedFile = (relatedCompany.files || []).filter((f: any) => f.id === data.id)[0];
    if (!relatedFile) return;

    relatedFile.active = true;
    relatedFile.status = 'processed';
    relatedCompany.files = (relatedCompany.files || []).filter((f: any) => f.id !== data.id);

    // Switch from fileUpload ID to DocumentID.
    // WARNING: before it was a fileUpload entity, now a Document/Image entity.
    relatedFile.id = data.fileUuid;
    relatedFile.url = data.fileUrl;

    // Load document type & date.
    if (data.fileType === 'Document') {
      if (data.fileData?.type !== null && data.fileData?.type !== undefined) {
        relatedFile.type = data.fileData?.type;
      }

      if (data.fileData?.date !== null && data.fileData?.date !== undefined) {
        relatedFile.date = data.fileData?.date;
      }
    }

    relatedCompany.files.push(relatedFile);
    relatedCompany.selectedFiles.push(data.fileUuid);

    updateCompany(formState, relatedCompany);
  }));

  formState.setSubscribed(formKey, true);
};
