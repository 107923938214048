import React from 'react';

import { classNames } from 'primereact/utils';
import { Password } from 'primereact/password';

import { trans } from 'utilities';
import { useApim } from 'services';
import { BaseTextField } from './BaseTextField';

export const SimplePasswordField = (props: any) => {
  const { fieldKey, control, onFieldChange, errorMessage, rules, label, tooltip, tooltipOptions, description,
    classes, addon, addonPosition, format, disabled, compare, setError, clearErrors, textAddon } = props;
  const apim = useApim();
  const { t } = apim.di();

  const handlePasswordFieldChange = (field: any, fieldState: any, value: string) => {
    if (compare && (value !== compare)) setError(fieldKey, {message: trans(t, 'pwd.passwordDoesNotMatch')});
    else clearErrors(fieldKey);

    onFieldChange(field, fieldState, value, format || 'default');
  };

  const renderInput = (field: any, fieldState: any) =>
    <span className={'p-float-label'}>
      <Password id={field.name} className={classNames('text-ucfirst w-full', {'p-invalid': fieldState?.error?.message})}
                value={field.value || ''} disabled={disabled} aria-invalid={fieldState.error}
                onChange={(e) => handlePasswordFieldChange(field, fieldState, e.target.value)}
                tooltip={tooltip} tooltipOptions={tooltipOptions || {position: 'top'}} toggleMask feedback={false}/>
      {(label || field.name) && (
        <label className={'text-ucfirst p-float-label-label'} htmlFor={field.name}>
          <span>{label ?? trans(t, field.name)}</span>
        </label>
      )}
    </span>
  ;

  return <BaseTextField fieldKey={fieldKey} control={control} errorMessage={errorMessage} rules={rules} classes={classes}
                        addon={addon} addonPosition={addonPosition} textAddon={textAddon} renderInput={renderInput} description={description} />
};
