import React from 'react';

import { InputTextarea } from 'primereact/inputtextarea';
import { classNames } from 'primereact/utils';

import { UncontrolledBaseField } from './UncontrolledBaseField';
import { trans } from 'utilities';
import { useApim } from 'services';

export const UncontrolledTextAreaField = (props: any) => {
  const { fieldKey, value, onFieldChange, label, autoResize, rows, tooltip, tooltipOptions, description, classes, addon, addonPosition, disabled } = props;

  const apim = useApim();
  const { t } = apim.di();

  return (
    <UncontrolledBaseField classes={classes} addon={addon} addonPosition={addonPosition} description={description}>
      <span className={'p-float-label'}>
        <InputTextarea id={fieldKey} className={classNames('text-ucfirst w-full')}
                       value={value || ''} disabled={disabled} autoResize={autoResize || true} rows={rows || 3}
                       onChange={(e) => onFieldChange(e.target.value)}
                       tooltip={tooltip} tooltipOptions={tooltipOptions || {position: 'top'}}/>
        {(label || fieldKey) && (
          <label className={'text-ucfirst p-float-label-label'} htmlFor={fieldKey}>
            <span>{label ?? trans(t, fieldKey)}</span>
          </label>
        )}
      </span>
    </UncontrolledBaseField>
  );
};
