import { useMemo } from 'react';
import { trans } from 'utilities';
import { isClient, isExpert, isAdmin, userId } from 'services';

import appUri from 'config/appUri.json';

export const NotificationsMenu = (props: any) => {
  const { apim, notificationState } = props;
  const { t } = apim.di();

  const getBadgeCount = useMemo(() => {
    // !! order matters !!
    if (notificationState.getCountUnread() > 0) return notificationState.getCountUnread();
    if (notificationState.getCount() > 0) return notificationState.getCount();

    return 0;
  }, [notificationState.getCountUnread(), notificationState.getCount()]); // eslint-disable-line react-hooks/exhaustive-deps

  const getBadgeClass = useMemo(() => {
    // !! order matters !!
    if (notificationState.getCountUnread() > 0) return 'p-badge-danger';
    if (notificationState.getCount() > 0) return 'p-badge-warning';

    return '';
  }, [notificationState.getCountUnread(), notificationState.getCount()]); // eslint-disable-line react-hooks/exhaustive-deps

  const items: any[] = [
    {
      label: trans(t, 'menu|menu.mes_notifications'),
      icon: 'bell',
      to: appUri.not.list,
      badge: getBadgeCount,
      badgeClassName: getBadgeClass,
    }
  ];

  if (isClient()) {
    items.push({
      label: trans(t, 'menu|menu.mes_parametres'),
      icon: 'cog',
      to: appUri.usr.dir.edit.replace(':id', userId()) + '/notifications'
    });

  } else if (isExpert() || isAdmin()) {
    items.push({
      label: trans(t, 'menu|menu.notifications_envoyees'),
      icon: 'envelope',
      to: appUri.not.sent.list
    });
  }

  return [
    {
      label: trans(t, 'menu|menu.notifications'),
      items: items
    }
  ]
};
