import React, { useState } from 'react';

import { Column } from 'primereact/column';
import { Tooltip } from 'primereact/tooltip';
import { SpeedDial } from 'primereact/speeddial';

import { dialog, trans, ucfirst } from 'utilities';
import { DatatableWrapper, dateTimeCell, validCell, rolesCell } from 'components';
import { IRequestParams, isAdmin, isExpertAdmin, isSuperAdmin } from 'services';

import { replace, union } from 'lodash';
import appUri from 'config/appUri.json';

export const UsersExpertDatatable = (props: any) => {
  const { tableKey, title, params, lazyConfig, rowUri, noFilters, context, apim } = props;
  const { t, navigate } = apim.di();

  const filtersEnabled = (!noFilters || false === noFilters);
  const [disabledItems, setDisabledItems] = useState<any[]>([]);

  const lazyC = {...{
    sortField: 'lastName',
    sortOrder: 1,
    filters: {
      firstName: { value: '', matchMode: 'contains' },
      lastName: { value: '', matchMode: 'contains' },
      email: { value: '', matchMode: 'contains' },
      phone: { value: '', matchMode: 'contains' }
    }
  }, ...lazyConfig};

  let newParams = params;
  let displayCompanyColumn = true;

  if (!(isAdmin() || isExpertAdmin())) {
    // Only deal with active entities apart from admin & expert admin.
    newParams = union(params, [{label: 'active', value: true}]);
  }

  if (isSuperAdmin()) {
    newParams.push({label: 'archive', value: ''});
  }

  if (tableKey === 'users-experts' && context && context.company) {
    // We are displaying the experts for a company entry.
    const company = context.company;
    newParams = union(params, [{label: 'societeExpertId', value: company.id}]);
    displayCompanyColumn = false;
  }

  const lastLoginBodyTemplate = (rowData: any) => dateTimeCell(rowData?.lastLogin);
  const updatedBodyTemplate = (rowData: any) => dateTimeCell(rowData?.updated);
  const createdBodyTemplate = (rowData: any) => dateTimeCell(rowData?.created);
  const activeBodyTemplate = (rowData: any) => validCell(rowData?.active);
  const deletedBodyTemplate = (rowData: any) => dateTimeCell(rowData?.deleted);
  const rolesBodyTemplate = (rowData: any) => rolesCell(rowData?.roles, t);

  const actionsBodyTemplate = (rowData: any) => {
    let items = [];

    if (isAdmin() || isExpertAdmin()) {
      items.push({
        label: ucfirst(trans(t, 'system|actions.edit')),
        icon: 'pi pi-pencil',
        className: 'bg-indigo-500',
        command: () => {
          navigate(replace(appUri.usr.exp.edit, ':id', rowData?.id || '_'));
        }
      });

      items.push({
        label: ucfirst(trans(t, 'system|actions.user.sendCredentials')),
        icon: 'pi pi-send',
        className: 'bg-indigo-500',
        command: () => {
          dialog(t, {
            message: ucfirst(trans(t, 'system|confirmations.user.sendCredentials')),
            icon: 'pi pi-send',
            accept: () => {
              if (rowData?.id) {
                apim.call({
                  resourceType: 'users',
                  action: 'sendCrendentials',
                  method: 'get',
                  id: rowData?.id,
                  notifSuccess: {details: ucfirst(trans(t, 'system|actions.user.sendCredentialsSuccess'))}
                } as IRequestParams).then();
              }
            }
          });
        }
      });
    }

    if (isAdmin() || isExpertAdmin()) {
      items.push({
        label: rowData?.active ? ucfirst(trans(t, 'system|actions.disable')) : ucfirst(trans(t, 'system|actions.enable')),
        icon: rowData?.active ? 'pi pi-ban' : 'pi pi-check',
        className: 'bg-orange-500',
        command: () => {
          if (rowData?.id) {
            const active= !rowData.active;
            const action= active ? 'enable' : 'disable';

            apim.patchEntity({
              resourceType: 'users',
              id: rowData.id,
              data: { active: active },
              notifSuccess: {
                summary: trans(t, 'notification|user.'+action+'.summary'),
                details: trans(t, 'notification|user.'+action+'.details'),
              },
              success: () => {
                rowData.active = active;
              },
            } as IRequestParams);
          }
        }
      });

      if (rowData.deleted === null || rowData.deleted === undefined) {
        items.push({
          label: ucfirst(trans(t, 'system|actions.archive')),
          icon: 'pi pi-folder',
          className:'bg-red-500',
          command: () => {
            dialog(t, {
              message: ucfirst(trans(t, 'system|confirmations.user.archive')),
              accept: () => {
                if (rowData?.id) {
                  const deleted = new Date();
                  apim.patchEntity({
                    resourceType: 'users',
                    id: rowData?.id,
                    data: {
                      active: false,
                      deleted: deleted
                    },
                    notifSuccess: {
                      summary: trans(t, 'notification|user.archive.summary'),
                      details: trans(t, 'notification|user.archive.details'),
                    },
                    success: () => {
                      rowData.deleted = deleted;

                      const _disabledItems: any[] = disabledItems.filter((d: string) => d !== rowData.id);
                      _disabledItems.push(rowData.id);
                      setDisabledItems(_disabledItems);
                    },
                  } as IRequestParams);
                }
              },
              acceptClassName: 'p-button-danger',
              rejectClassName: 'p-button-text p-button-primary'
            });
          }
        });
      }
    }

    if (isSuperAdmin()) {
      items.push({
        label: ucfirst(trans(t, 'system|actions.delete')),
        icon: 'pi pi-trash',
        className:'bg-red-500',
        command: () => {
          dialog(t, {
            message: ucfirst(trans(t, 'system|confirmations.user.delete')),
            accept: () => {
              if (rowData?.id) {
                apim.deleteEntity({
                  resourceType: 'users',
                  id: rowData?.id,
                  success: () => {
                    const _disabledItems: any[] = disabledItems.filter((d: string) => d !== rowData.id);
                    _disabledItems.push(rowData.id);
                    setDisabledItems(_disabledItems);
                  },
                } as IRequestParams);
              }
            },
            acceptClassName: 'p-button-danger',
            rejectClassName: 'p-button-text p-button-primary'
          });
        }
      });

      if (rowData.deleted !== null && rowData.deleted !== undefined) {
        items.push({
          label: ucfirst(trans(t, 'system|actions.unarchive')),
          icon: 'pi pi-folder-open',
          className: 'bg-red-500',
          command: () => {
            dialog(t, {
              message: ucfirst(trans(t, 'system|confirmations.user.unarchive')),
              accept: () => {
                if (rowData?.id) {
                  apim.patchEntity({
                    resourceType: 'users',
                    id: rowData?.id,
                    data: {
                      active: true,
                      deleted: null
                    },
                    notifSuccess: {
                      summary: trans(t, 'notification|societeExpert.user.summary'),
                      details: trans(t, 'notification|societeExpert.user.details'),
                    },
                    success: () => {
                      rowData.deleted = null;
                      rowData.active = true;
                    },
                  } as IRequestParams);
                }
              },
              acceptClassName: 'p-button-danger',
              rejectClassName: 'p-button-text p-button-primary'
            });
          }
        });
      }
    }

    return <>
      <Tooltip target={'.a8-speedial-datatable .p-speeddial-action'} position={'top'} mouseTrack />
      <SpeedDial className={'a8-speedial-datatable relative z-5'} model={items} direction={'left'} transitionDelay={40} showIcon={'pi pi-ellipsis-v'} hideIcon={'pi pi-times'} buttonClassName={'p-button-text'} />
    </>
  };

  return (
    <DatatableWrapper
      resourceType={'usersIndex'} tableKey={tableKey || 'users-experts'} params={newParams} lazyConfig={lazyC} rowUri={rowUri || appUri.usr.exp.page} noFilters={noFilters}
      title={title || trans(t, 'menu|pages.title.user.experts')} disabledItems={disabledItems} onNew={isAdmin() || isExpertAdmin() ? () => navigate(appUri.usr.exp.add) : null} addTitle={trans(t, 'exp.add')}>
      <Column field={'lastName'} header={trans(t, 'lastName')} sortable filter={filtersEnabled} style={{ width: '200px' }} />
      <Column field={'firstName'} header={trans(t, 'firstName')} sortable filter={filtersEnabled} style={{ width: '200px' }} />
      <Column field={'email'} header={trans(t, 'email')} sortable filter={filtersEnabled} style={{ width: '300px' }} />
      <Column field={'phone'} header={trans(t, 'phone')} sortable filter={filtersEnabled} style={{ width: '200px' }} />
      {displayCompanyColumn ? <Column field={'societeExpertName'} header={trans(t, 'societeExpert')} sortable filter={filtersEnabled} /> : ''}
      <Column field={'roles'} header={trans(t, 'role')} sortable filter={filtersEnabled} body={rolesBodyTemplate} />
      <Column field={'lastLogin'} header={trans(t, 'lastLogin')} sortable body={lastLoginBodyTemplate} align={'center'} style={{ width: '220px' }} />
      <Column field={'updated'} header={trans(t, 'updated')} sortable align={'center'} body={updatedBodyTemplate} style={{ width: '225px' }} />
      <Column field={'created'} header={trans(t, 'created')} sortable align={'center'} body={createdBodyTemplate} style={{ width: '225px' }} />
      {isSuperAdmin()  &&
        <Column field={'deleted'} header={trans(t, 'deleted')} sortable filter={filtersEnabled}
                align={'center'} body={deletedBodyTemplate} style={{ width: '100px' }} />}
      {(isAdmin() || isExpertAdmin()) &&
        <Column field={'active'} header={trans(t, 'active')} sortable filter={filtersEnabled}
                dataType={'boolean'} align={'center'} body={activeBodyTemplate} style={{ width: '100px' }} />}
      {(isAdmin() || isExpertAdmin()) && <Column header={trans(t, 'system|action', 2)} align={'right'} body={actionsBodyTemplate} style={{ width: '80px', maxWidth: '80px' }} />}
    </DatatableWrapper>
  );
};
