import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useNotificationState } from 'states';

import appUri from 'config/appUri.json';

export const AppTopbarNotifications = () => {
  const notificationState = useNotificationState();
  const navigate = useNavigate();

  return (
    <button className={'layout-topbar-action rounded-circle p-link'} onClick={(event) => navigate(appUri.not.list)}>
      {notificationState.getCountUnread() ? (
        <span className={'p-overlay-badge'}>
          <i className={'pi pi-bell fs-large'}></i>
          <span className={'p-badge p-badge-danger p-badge-dot'}></span>
        </span>
      ) : (
        notificationState.getCount() ? (
          <span className={'p-overlay-badge'}>
          <i className={'pi pi-bell fs-large'}></i>
          <span className={'p-badge p-badge-warning p-badge-dot'}></span>
        </span>
        ) : (
          <i className={'pi pi-bell fs-large'}></i>
        )
      )}
    </button>
  );
};
