import React, { useEffect, useMemo, useState } from 'react';
import { useForm, SubmitHandler, SubmitErrorHandler } from 'react-hook-form';
import { useSearchParams } from 'react-router-dom';
import { AxiosResponse } from 'axios';

import { Button } from 'primereact/button';
import { classNames } from 'primereact/utils';

import { dialog, escapeValue, getLocalStorageItem, isValidUUID, trans } from 'utilities';
import { IRequestParams, useApim } from 'services';
import { renderForm } from 'forms';
import { formStateKeyPrefix, useFormState } from 'states';
import { get } from 'lodash';

export const FormWrapper = (props: any) => {
  const formStateA8 = useFormState();

  const {
    formKeyPrefix,    // string used to store clean key/value into browser cache (should be uniq)
    resourceType,     // has to map with src/config/apimConfig.json entity key

    loading,          // (optional) is the form loading ?
    keyTmp,           // (optional) a key suffix to add to the formKey string in particular cases (e.g. new entities)
    data,             // (optional) initial entity / data
    setData,          // (optional) setter callback
    additionalData,   // (optional) some data to always add during the POST/PATCH API call at the end of submit
    parentKey,        // (optional) a nested object of "data" to look into (instead of data root)
    multiple,         // (default false) is this form part of a multiple forms package ?
    noSubmit,         // (optional) do not call the following "onSubmit" process
    notif,            // (optional) specify the "notif" param during APIM calls.
    submitClass,      // (optional) class to add to submit btn
    cancelClass,      // (optional) class to add to cancel btn
    resetClass,       // (optional) class to add to reset btn
    onSubmit,         // (optional) custom logic to play instead of default submit (e.g. when using data not directly related (mapped) to an entity)
    onError,          // (optional) custom logic to play instead of default submit (e.g. when using data not directly related (mapped) to an entity)
    onFormCancel,     // (optional) custom logic to play on the cancellation of the form
    onReset,          // (optional) custom logic to play at the start of the reset process (e.g. Dossier Step1)
    formatter,        // (optional) custom formatter to be called just before submit
    callback,         // (optional) custom logic to play at the end of the submit process
    redirectUri,      // (optional) let's redirect to this after a success or if the user click on the cancel link
    cancelLink,       // (default false) display a link to "cancel" and go back to the "redirectUri" (if specified)
    keepAlive,        // (default false) do not reset the form on submit
    fieldSetLabel,    // (optional) fieldset label (if any)
    classes,          // (optional) classes of the parent div wrapper
    subClasses,       // (optional) classes of the 2nd div wrapper
    listsOptions,     // (optional) keyed object of lists of options used by some form field (e.g. StaticListField)
    hideReload,       // (default false) do not show toast indicator on success/error
    lockedKey,        // (optional) disallows this form wrapper to arrange a bit the form cache key
    context,          // (optional) some context (object) to be passed to the formMatcher
    globalDisabled    // (optional) marks all form fields as disabled
  } = props;
  const apim = useApim();
  const [searchParams] = useSearchParams();
  const destination = searchParams.get('destination');
  const { navigate, toast, toastError, t } = apim.di();
  const formKey: string = lockedKey ? formKeyPrefix : (data?.id ? formKeyPrefix + '#' + data.id : formKeyPrefix + (keyTmp || ''));
  const [loaded, setLoaded] = useState<boolean>(false);
  const [lastData, setLastData] = useState<any>({});

  // Display a message to inform the user.
  useEffect(() => {
    if (formStateA8.reloadFormData(formKey) > 0 && !hideReload && toast?.current) {
      toast?.current.show({
        severity: 'info',
        summary: trans(t, 'form|dataRefreshed.title'),
        detail: trans(t, 'form|dataRefreshed.detail'),
        life: 4000
      });
    }

    setLoaded(true);
  }, [formKey, parentKey, data?.id]); // eslint-disable-line react-hooks/exhaustive-deps

  // Build form values, merging given data (if any) & cached values (if any).
  const mergeValues = useMemo(() => {
    // To prevent collision between multiple objects data when using a shared form / switch,
    // we have to "reset" the last used data as an object filled of null properties.
    const _lastEmptyData: any = {};
    Object.keys(lastData).forEach((index: string) => {
      _lastEmptyData[index] = null;
    });

    // Populate form from previous local storage inputs (if any).
    const storedData = getLocalStorageItem(formStateKeyPrefix + formKey);
    const parsedData = storedData ? {...{ fields: {} }, ...storedData} : {fields: {}};

    const _ld: any = {
      ...(_lastEmptyData || {}), // /!\ Keep this as the first row
      ...(data || {}),
      ...(additionalData || {}),
      ...(parentKey ? get(parsedData.fields, parentKey) : parsedData.fields),
    };
    setLastData(_ld);

    return _ld;
  }, [formKey, parentKey, data?.id, loaded]); // eslint-disable-line react-hooks/exhaustive-deps
  const {
    reset, handleSubmit, control, formState: { errors }, getValues, setValue, setError, clearErrors
  } = useForm({ values: mergeValues });

  // Destroy react-hook-form instance on component unmount.
  useEffect(() => {
    return () => {
      reset({
      ...(data || {}),
      ...(additionalData || {}),
      });
    }
  }, [formKey, parentKey, data?.id]); // eslint-disable-line react-hooks/exhaustive-deps

  const errorMessage = (name: string) => {
    const em: any = (errors as any)[name];
    return em ? <small className={'p-error absolute text-ucfirst'}>{em.message}</small> : <small className={'p-error hidden'}></small>;
  };

  const onFieldChange = (field: any, fieldState: any, value: any, format: string = 'default') => {
    if (undefined === field?.value && null === value) return; // Prevent field value to be erased during form initialization.

    field.onChange(value);

    // Let's debounce to trigger update on the next event loop.
    setTimeout(() => {
      if (!fieldState.error) {
        formStateA8.setFieldData(formKey, field.name, parentKey, escapeValue(value, format));
      }
    });
  };

  const terminate = (withRedirect: boolean = true, finalData: any | null = null, created: boolean | null = false) => {
    formStateA8.resetFormData(formKey); // Reset A8 form state service.
    reset({                      // Reset react-hook-form service.
      ...(data ?? {}),
      ...(additionalData ?? {}),
    });

    if (callback) return callback(finalData, created);

    if (destination && ['/', 'h'].includes(destination?.substring(0, 1))) return navigate(destination);
    if (redirectUri && withRedirect) return navigate(redirectUri);
  };

  const onFormCancelInternal = (e: any = null) => {
    if (e) e.stopPropagation();

    if (onFormCancel) {
      terminate(false);
      return onFormCancel();
    }

    dialog(t, {
      accept: () => {
        if (onReset) {
          onReset();
        }

        terminate();
      },
    });
  };

  const onFormResetInternal = (e: any = null) => {
    if (e) e.stopPropagation();

    terminate(false);
  };

  /**
   * Handle form submission.
   */
  const onFormSubmit : SubmitHandler<FormData> = () => {
    if (noSubmit) return;

    // "onSubmit" is used to change the default behavior,
    // you could have a look at "callback" property, if you want the default behavior then override the process.
    if (onSubmit) {
      return formStateA8.submitForm(formKey, data, additionalData, null, (formData: any) => {
        if (!keepAlive) {
          onFormResetInternal();
        }

        if (setData) {
          setData({...data, ...formData});
        }

        onSubmit(formData);
      });
    }

    const fallback = () => {
      if (keepAlive) {
        if (callback) return callback(data, false);

        if (destination && ['/', 'h'].includes(destination?.substring(0, 1))) return navigate(destination);
        if (redirectUri) navigate(redirectUri);
      } else {
        terminate(true, data, false);
      }
    };

    // Choose over PATCH an existing entity or CREATE a new one.
    if (isValidUUID(data?.id)) {
      formStateA8.submitForm(formKey, data, additionalData, (patchedData: any) => apim.patchEntity({
        resourceType: resourceType,
        id: data.id,
        data: formatter ? formatter(patchedData) : patchedData,
        notif: notif ?? true,
        success: (res: AxiosResponse) => {
          const finalData: any = {...data, ...patchedData, ...res?.data};

          if (setData) setData(finalData);
          if (keepAlive) {
            if (callback) return callback(finalData, false);
            if (destination && ['/', 'h'].includes(destination?.substring(0, 1))) return navigate(destination);
            if (redirectUri) navigate(redirectUri);
          } else {
            terminate(true, finalData, false);
          }
        },
      } as IRequestParams).then(), fallback);
    } else {
      formStateA8.submitForm(formKey, data, additionalData, (formData: any) => apim.postEntity({
        resourceType: resourceType,
        data: formatter ? formatter(formData) : formData,
        notif: notif ?? true,
        success: (res: AxiosResponse) => {
          const finalData: any = {...data, ...formData, ...res?.data};

          if (setData) setData(finalData);
          if (keepAlive) {
            if (callback) return callback(finalData, true);
            if (destination && ['/', 'h'].includes(destination?.substring(0, 1))) return navigate(destination);
            if (redirectUri) navigate(redirectUri);
          } else {
            terminate(true, finalData, true);
          }
        },
      } as IRequestParams).then(), fallback);
    }
  };

  /**
   * Handle form errors.
   */
  const onFormError: SubmitErrorHandler<FormData> = (errors: Object) => {
    if (noSubmit || Object.keys(errors).length === 0) return;

    if (onError) {
      // "onError" is used to change the default behavior,
      onError(errors);

    } else {
      // Default behavior.
      // Show a toast message to warn the user that the data was not saved.
      toastError?.current.show({
        severity: 'error',
        summary: trans(t, 'form|genericFormError.title'),
        detail: trans(t, 'form|genericFormError.detail'),
        life: 4000
      });
    }
  };

  return <>
    {loaded &&
      <form className={classNames('a8-form', classes || 'grid')}>
        <div className={subClasses || 'col-12'}>
          {renderForm({
            resourceType,
            formKey,
            formKeyPrefix,
            parentKey,
            globalDisabled,
            // injections
            fieldSetLabel,
            apim,
            listsOptions,
            context,
            // custom logic
            onFieldChange,
            errorMessage,
            // react-hook-form logic
            control,
            getValues,
            setValue,
            setError,
            clearErrors
          })}
        </div>
        <div className={classNames({'hidden': multiple, 'col-12 flex md:justify-content-end justify-content-center': !multiple})}>
          <div className={'flex flex-row'}>
            <Button type={'button'} className={'hidden text-ucfirst mx-2 ' + (resetClass || 'a8-form-reset')} label={trans(t, 'reset')} severity={'danger'} text icon={'pi pi-cancel'} onClick={onFormResetInternal}/>
            {cancelLink && (
              <Button type={'button'} className={'text-ucfirst mx-2 ' + (cancelClass || 'a8-form-cancel')} label={trans(t, 'cancel')} severity={'danger'} text icon={'pi pi-cancel'} onClick={onFormCancelInternal}/>
            )}
            <Button type={'button'} className={'text-ucfirst mx-2 ' + (submitClass || 'a8-form-submit')} label={trans(t, 'save')} icon={'pi pi-check'} iconPos={'right'} onClick={handleSubmit(onFormSubmit, onFormError)} loading={loading}/>
          </div>
        </div>
      </form>
    }
  </>
};
