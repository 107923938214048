import React from 'react';
import { trans, ucfirst } from 'utilities';
import { APIListField, CheckboxField, NumberField, TextField } from 'forms/index';

export const ContratsFacultatifsForm = (props: any) => {
  const { t, control, onFieldChange, errorMessage } = props;

  return (
    <div className={'flex flex-column col-12'}>
      <div className={'flex flex-wrap flex-row col-12'}>
        <div className={'col-4'}>
          <TextField fieldKey={'nomReferenceContrat'} control={control} onFieldChange={onFieldChange}
                     errorMessage={errorMessage} label={ucfirst(trans(t, 'nomReferenceContrat'))} classes={'w-full'}/>
        </div>

        <div className={'col-4'}>
          <TextField fieldKey={'compagnie'} control={control} onFieldChange={onFieldChange}
                     errorMessage={errorMessage} label={ucfirst(trans(t, 'company'))} classes={'w-full'}/>
        </div>

        <div className={'col-4'}>
          <APIListField classes={'w-full'} listKey={'dossierFiscalRegimes'} fieldKey={'cadreFiscal'} control={control}
                        onFieldChange={onFieldChange} errorMessage={errorMessage}
                        label={trans(t, 'cadreFiscal')} showClear/>
        </div>
      </div>

      <div className={'flex flex-wrap flex-row col-12'}>
        <div className={'col-4'}>
          <NumberField fieldKey={'valeurEnStock'} mode={'currency'} addon={'euro'} addonPosition={'after'}
                       classes={'w-full'}
                       control={control} onFieldChange={onFieldChange} errorMessage={errorMessage}
                       label={trans(t, 'valeurEnStock')}/>
        </div>
        <div className={'col-4'}>
          <NumberField fieldKey={'versementAnnuel'} mode={'currency'} addon={'euro'} addonPosition={'after'}
                       classes={'w-full'}
                       control={control} onFieldChange={onFieldChange} errorMessage={errorMessage}
                       label={trans(t, 'versementAnnuel')}/>
        </div>
        <div className={'col-4'}>
          <TextField fieldKey={'tableMortalite'} control={control} onFieldChange={onFieldChange}
                     errorMessage={errorMessage} label={ucfirst(trans(t, 'tableMortalite'))} classes={'w-full'}/>
        </div>
      </div>
      <div className={'flex flex-wrap flex-row col-12'}>
        <div className={'col-4'}>
          <NumberField fieldKey={'tauxTechnique'} in={0} max={100} suffix={'%'} addon={'percentage'}
                       addonPosition={'after'} classes={'w-full'} mode={'decimal'}
                       control={control} onFieldChange={onFieldChange} errorMessage={errorMessage}
                       label={trans(t, 'tauxTechnique')}/>
        </div>
        <div className={'col-4'}>
          <NumberField fieldKey={'montantRetraiteFacultativeEstimee'} mode={'currency'} addon={'euro'}
                       addonPosition={'after'}
                       classes={'w-full'}
                       control={control} onFieldChange={onFieldChange} errorMessage={errorMessage}
                       label={trans(t, 'montantRetraiteFacultativeEstimee')}/>
        </div>
        <div className={'col-4'}>
          <NumberField fieldKey={'gainFiscalAnnee'} mode={'currency'} addon={'euro'} addonPosition={'after'}
                       classes={'w-full'}
                       control={control} onFieldChange={onFieldChange} errorMessage={errorMessage}
                       label={trans(t, 'gainFiscalAnnee')}/>
        </div>
      </div>
      <div className={'flex flex-wrap flex-row col-12'}>

        <div className={'col-4'}>
          <NumberField fieldKey={'gainFiscalCumule'} mode={'currency'} addon={'euro'} addonPosition={'after'}
                       classes={'w-full'}
                       control={control} onFieldChange={onFieldChange} errorMessage={errorMessage}
                       label={trans(t, 'gainFiscalCumule')}/>
        </div>
        <div className={'col-4'}>
          <NumberField fieldKey={'parametreRemuneration'} in={0} max={100} suffix={'%'} addon={'percentage'}
                       addonPosition={'after'} classes={'w-full'} mode={'decimal'}
                       control={control} onFieldChange={onFieldChange} errorMessage={errorMessage}
                       label={trans(t, 'parametreRemuneration')}/>
        </div>

      </div>
      <div className={'flex flex-wrap flex-row col-12'}>
        <div className={'col-4'}>
          <CheckboxField classes={'w-full'} fieldKey={'reversionAutomatique'} control={control}
                         onFieldChange={onFieldChange} errorMessage={errorMessage}
                         label={trans(t, 'reversionAutomatique')}/>
        </div>
        <div className={'col-4'}>
          <CheckboxField classes={'w-full'} fieldKey={'garantieBonneFin'} control={control}
                         onFieldChange={onFieldChange} errorMessage={errorMessage}
                         label={trans(t, 'garantieBonneFin')}/>
        </div>
        <div className={'col-4'}>
          <CheckboxField classes={'w-full'} fieldKey={'exonerationsCotisations'} control={control}
                         onFieldChange={onFieldChange} errorMessage={errorMessage}
                         label={trans(t, 'exonerationsCotisations')}/>
        </div>
      </div>

    </div>
  )
};
