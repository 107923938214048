import React from 'react';
import { Calendar } from 'primereact/calendar';
import { UncontrolledBaseField } from './UncontrolledBaseField';
import { asDate, trans } from 'utilities';
import { useApim } from 'services';

export const UncontrolledDateTimeField = (props: any) => {
  const { fieldKey, value, dateFormat, parseFormat, onFieldChange, label, tooltip, tooltipOptions, description, classes,
    addon, addonPosition, disabled, showButtonBar, showTime, hourFormat, maxDate, view } = props;
  const apim = useApim();
  const { t } = apim.di();

  return (
    <UncontrolledBaseField classes={classes} addon={addon} addonPosition={addonPosition} description={description}>
      <span className={'p-float-label'}>
        <Calendar id={fieldKey} className={'text-ucfirst w-full'} value={value ? asDate(value as string, parseFormat) : null}
                  onChange={(e) => {
                    const newDate: Date = asDate(e.target.value as unknown as string);
                    if (!showTime) newDate.setHours(newDate.getHours() + 12);
                    onFieldChange(newDate.toISOString());
                  }}
                  showButtonBar={showButtonBar} dateFormat={dateFormat || 'dd/mm/yy'}
                  disabled={disabled} showTime={showTime} hourFormat={hourFormat || '24'} maxDate={maxDate}
                  tooltip={tooltip} tooltipOptions={tooltipOptions || {position: 'top'}} view={view}/>
        {(label || fieldKey) && (
          <label className={'text-ucfirst p-float-label-label'} htmlFor={fieldKey}>
            <span>{label ?? trans(t, fieldKey)}</span>
          </label>
        )}
      </span>
    </UncontrolledBaseField>
  );
};
