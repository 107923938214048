import React, { useEffect, useState } from 'react';

import { isValidUUID, trans, ucfirst } from 'utilities';
import { APIAutocompleteField, MaskField, TextField, UncontrolledCheckboxField } from 'forms';
import { useFormState } from 'states';

import { trim } from 'lodash';

export const AddPersonnePhysiqueForm = (props: any) => {
  const { t, control, onFieldChange, errorMessage, getValues, setValue, formKeyPrefix, context } = props;
  const formState = useFormState();
  const [displayNew, setDisplayNew] = useState<boolean>(context.onlyNew || false);

  const formatter = (rows: any[]) => rows.map((r: any) => {
    return {...r, ...{ autocompleteLabel: trim(r.firstName + ' ' + r.lastName) }}
  });

  const params = [
    {label: 'order[firstName]', value: 'asc'},
    {label: 'order[lastName]', value: 'asc'}
  ];

  if (isValidUUID(context?.companyId)) {
    params.push({label: 'byCompany', value: context.companyId});
  }

  if (isValidUUID(context?.dossierId)) {
    params.push({label: 'byDossier', value: context.dossierId});
  }

  if (isValidUUID(context?.personnePhysiqueId)) {
    params.push({label: 'byPersonnePhysique', value: context.personnePhysiqueId});
  }

  // Cancel PP selection if user checks that he wants to create a new entity.
  useEffect(() => {
    if (!displayNew) return;

    formState.setFieldData(formKeyPrefix, 'personnePhysiqueId', null, null);
    setValue('personnePhysiqueId', null);
  }, [displayNew]); // eslint-disable-line react-hooks/exhaustive-deps

  return <div className={'grid pt-2'} style={{width: '400px'}}>
    {!displayNew && (
      <APIAutocompleteField fieldKey={'personnePhysiqueId'} control={control} onFieldChange={onFieldChange} errorMessage={errorMessage} label={trans(t, 'personnePhysique')}
                            getValues={getValues} placeholder={ucfirst(trans(t, 'form|select_personnePhysique'))} optionKey={'autocompleteLabel'} formatter={formatter}
                            resourceType={'personnesPhysiques'} params={params} classes={'col mb-0'} action={'autocomplete'}/>
    )}

    {!context?.onlyNew && (
    <UncontrolledCheckboxField classes={'col-12'} fieldKey={'createNew'} onFieldChange={(value: any) => setDisplayNew(value)} label={ucfirst(trans(t, 'form|createNew.personnePhysique'))} value={displayNew}/>
    )}

    {displayNew && (
      <div className={'px-2 pt-3 grid'}>
        <TextField fieldKey={'firstName'} control={control} onFieldChange={onFieldChange} errorMessage={errorMessage} label={trans(t, 'firstName')} classes={'col-12'}/>
        <TextField fieldKey={'lastName'} control={control} onFieldChange={onFieldChange} errorMessage={errorMessage} label={trans(t, 'lastName')} classes={'col-12'}/>
        <MaskField fieldKey={'birthDate'} control={control} onFieldChange={onFieldChange} errorMessage={errorMessage} label={trans(t, 'birthDate')} classes={'col-12'} isDate/>
      </div>
    )}
  </div>;
};
