import React, { useCallback, useEffect, useState } from 'react';
import { ProgressBar } from 'primereact/progressbar';
import { Steps } from 'primereact/steps';

import { Error, NotFound, Unauthorized } from 'pages';
import { Loader, PageLoader } from 'components';
import { IRequestParams, isClient } from 'services';
import { isValidUUID, trans, ucfirst } from 'utilities';

import { Step1 } from './Step1';
import { Step2 } from './Step2';

import appUri from 'config/appUri.json';

export const BienBancaireRouter = (props: any) => {
  const { apim, appState, bienId, context, step, dirigeant } = props;
  const { t} = apim.di();
  const dossier: any = appState.dossier();

  const [loading, setLoading] = useState<boolean>(false);
  const [data, setData] = useState<any>();
  const [notFound, setNotFound] = useState<boolean>(false);
  const [errored, setErrored] = useState<boolean>(false);
  const [activeIndex, setActiveIndex] = useState<number>(-1);

  useEffect(() => {
    fetchData();
  }, [bienId]); // eslint-disable-line react-hooks/exhaustive-deps

  const fetchData = () => {
    if ((data?.id === bienId) || !isValidUUID(bienId)) return;

    setLoading(true);
    apim.fetchEntity({
      resourceType: 'biensBancaires',
      id: bienId,
      cache: false,
      setLoading,
      setErrored,
      setNotFound,
      setter: setData,
    } as IRequestParams).then();
  }

  // Update active index & Page Header.
  useEffect(() => {
    setActiveIndex(('/' + step) === appUri.dos.edit.patrimoine.biens.steps.default.step2 ? 1 : 0);

    appState.setBreadcrumb([
      { label: trans(t, 'dossier', 2), to: appUri.dos.list },
      { label: dossier?.title, to: appUri.dos.page.replace(':id', dossier?.id) },
      { label: trans(t, 'editing'), to: appUri.dos.edit.landing.replace(':id', dossier?.id) },
      { label: ucfirst(trans(t, 'menu|module.patrimoine')), to: appUri.dos.edit.patrimoine.uri.replace(':id', dossier?.id) },
      { label: ucfirst(trans(t, 'menu|tab.biens')), to: appUri.dos.edit.patrimoine.biens.landing.replace(':id', dossier?.id) },
      { label: trans(t, 'editing') + ' | ' + ucfirst(trans(t, 'bien')) + ' ' + trans(t, 'table|patrimoine_bien.bancaire') }
    ]);
    appState.setPageActions([]);
    appState.setPageTitle(ucfirst(trans(t, 'dossier')) + ' ' + dossier?.title + ' | ' + trans(t, 'editing'));
  }, [dossier?.id, step]); // eslint-disable-line react-hooks/exhaustive-deps

  const wizardItems = [
    { label: ucfirst(trans(t, 'menu|wizard.patrimoine.biens.default.step1')) },
    { label: ucfirst(trans(t, 'menu|wizard.patrimoine.biens.default.step2')) },
  ];

  const buildTabContent = useCallback(() => {
    switch (activeIndex) {
      case -1:
        return <Loader/>
      case 0:
        return <Step1 context={context} data={data} apim={apim} appState={appState} dirigeant={dirigeant}/>;
      case 1:
        return <Step2 context={context} data={data} apim={apim} appState={appState} dirigeant={dirigeant}/>;
      default:
        return <NotFound asBlock/>
    }
  }, [bienId, step, activeIndex, context?.id, data?.id]); // eslint-disable-line react-hooks/exhaustive-deps

  if (isClient()) return <Unauthorized asBlock/>;
  if (notFound) return <NotFound asBlock/>;
  if (errored) return <Error asBlock/>;
  if (loading) return <PageLoader/>;

  return (
    <>
      <div className={'grid'}>
        <div className={'col'}>
          <ProgressBar value={50 + activeIndex * 50} showValue={false}/>
        </div>
      </div>
      <Steps model={wizardItems} activeIndex={activeIndex} readOnly={true}/>

      {buildTabContent()}
    </>
  );
};
