import React, { useEffect } from 'react';

import { trans} from 'utilities';
import { MissionsDatatable } from 'components';

import appUri from 'config/appUri.json';

export const MissionsHistory = (props: any) => {
  const { t, appState, apim } = props;
  const dossier: any = appState.dossier();

  // Update Page Header.
  useEffect(() => {
    appState.setPageTitle(trans(t, 'menu|pages.title.missions'));
    appState.setBreadcrumb([
      { label: trans(t, 'dossier', 2), to: appUri.dos.list },
      { label: dossier?.title, to: appUri.dos.page.replace(':id', dossier?.id) },
      { label: trans(t, 'mission', 2) }]);
  }, [dossier]); // eslint-disable-line react-hooks/exhaustive-deps

  return <MissionsDatatable appState={appState} tableKey={'missions'} title={trans(t, 'menu|pages.title.missions')} apim={apim}/>
    ;
};
