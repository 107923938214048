import React, {useEffect, useRef, useState} from 'react';

import { FileUpload, FileUploadHandlerEvent, FileUploadHeaderTemplateOptions, FileUploadSelectEvent, FileUploadUploadEvent, ItemTemplateOptions } from 'primereact/fileupload';
import { ProgressBar } from 'primereact/progressbar';
import { Tooltip } from 'primereact/tooltip';
import { Avatar } from 'primereact/avatar';
import { Image } from 'primereact/image';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { AxiosResponse } from 'axios';

import { trans } from 'utilities';
import { IRequestParams, subscribe, useApim } from 'services';
import { PageLoader } from 'components';
import { BaseTextField } from './BaseTextField';
import { useFormState } from 'states';

export const ImageField = (props: any) => {
  const { formKey, fieldKey, parentKey, control, onFieldChange, errorMessage, rules, label, classes, disabled, hideLabel, help, uploadContext, getValues, setValue } = props;

  const formState = useFormState();
  const apim = useApim();
  const { t } = apim.di();

  const [loading, setLoading] = useState<boolean>(false);
  const [imageLoading, setImageLoading] = useState<boolean>(false);
  const [imageUrl, setImageUrl] = useState<any>(null);
  const [edit, setEdit] = useState<boolean>(false);
  const [totalSize, setTotalSize] = useState(0);
  const fileUploadRef = useRef<FileUpload>(null);
  const [uploadProgress, setUploadProgress] = useState<number|null>(null);

  // Load initial image as object.
  // We have a UUID and we want a loaded object.
  useEffect(() => {
    const imageUuid: any = (getValues() || {})[fieldKey] || [];
    if (imageUuid.length > 0) {
      setImageLoading(true);
      apim.fetchEntity({
        resourceType: 'images',
        id: imageUuid,
        setLoading: setImageLoading,
        success: (res: AxiosResponse) => {
          if (!res?.data) return;

          if (res.data['url'] !== undefined && res.data['url'] !== null) {
            setImageUrl(res.data['url']);
          }
        }
      } as IRequestParams).then();
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const onTemplateSelect = (e: FileUploadSelectEvent) => {
    let _totalSize = totalSize;
    let files = e.files;

    for (let i = 0; i < files.length; i++) {
      _totalSize += files[i].size || 0;
    }

    setTotalSize(_totalSize);
  };

  const onTemplateUpload = (e: FileUploadUploadEvent) => {
    let _totalSize = 0;

    e.files.forEach((file) => {
      _totalSize += file.size || 0;
    });

    setTotalSize(_totalSize);
  };

  const onTemplateClear = () => {
    setTotalSize(0);
  };

  const editImage = () => {
    setEdit(true);
    onTemplateClear();
  }

  const removeImage = () => {
    formState.setFieldData(formKey, fieldKey, parentKey, null);
    setValue(fieldKey, null);
    setImageUrl(null);
    onTemplateClear();
  }

  const customUploader = async (event: FileUploadHandlerEvent) => {
    const file: any = event.files[0];
    const formData = new FormData();
    formData.append('file[]', file, file.name);
    formData.append('type', 'image');

    setLoading(true);
    apim.postEntity({
      resourceType: 'fileUploads',
      data: formData,
      notif: false,
      paramType: 'postParams',
      headers: {'A8-Context': JSON.stringify(uploadContext)},
      progress: (event: any) => {
        let _uploadProgress = uploadProgress;
        _uploadProgress = (event.loaded / event.total) * 100;
        setUploadProgress(_uploadProgress);
      },
      success: (res: AxiosResponse) => {
        if (!res?.data?.id) return;

        file.id = res.data.id;
        checkFileUploadStatus(file);
        checkFileUploadStatusFromHub(file);
      }
    } as IRequestParams).then();
  };

  const checkFileUploadStatus = (file: any) => {
    const interval = setInterval(function() {
      if (loading) {
        // Make sure the status was not already updated through hub.
        apim.fetchEntity({
          resourceType: 'fileUploads',
          id: file.id,
          notif: false,
          success: (res: AxiosResponse) => {
            if (res.data.status === 'processed') {
              const imageUuid = res.data.fileUuid;
              formState.setFieldData(formKey, fieldKey, parentKey, imageUuid);
              setValue(fieldKey, imageUuid);

              setImageUrl(res.data.fileUrl);
              clearInterval(interval);
              setLoading(false);
              setEdit(false);
            }
          }
        } as IRequestParams).then();
      } else {
        // Status was updated through the hub.
        clearInterval(interval);
      }
    }, 2000);
  }

  const checkFileUploadStatusFromHub = (file: any) => {
    const subscribed = formState.hasSubscribed(file.id);
    if (subscribed) return;

    // Subscribe to the hub.
    subscribe(['/file_upload/' + file.id], ((m: any) => {
      if (!m.data) return;

      const data = JSON.parse(m.data);
      if (data?.status !== 'processed') return;

      const imageUuid = data.fileUuid;
      formState.setFieldData(formKey, fieldKey, parentKey, imageUuid);
      setValue(fieldKey, imageUuid);

      setImageUrl(data.fileUrl);
      setLoading(false);
      setEdit(false);
    }));

    formState.setSubscribed(file.id, true);
  };

  const headerTemplate = (options: FileUploadHeaderTemplateOptions) => {
    const { className, chooseButton, uploadButton, cancelButton } = options;
    const value = totalSize / 10000;
    const formatedValue = fileUploadRef && fileUploadRef.current ? fileUploadRef.current.formatSize(totalSize) : '0 B';

    return (
      <div className={className} style={{ backgroundColor: 'transparent', display: 'flex', alignItems: 'center', justifyContent: 'center', flexFlow: 'row nowrap' }}>
        {chooseButton}
        {uploadButton}
        {cancelButton}
        <div className='flex align-items-center gap-3'>
          <span style={{ fontSize: '8px' }}>{formatedValue} / 1 MB</span>
          <ProgressBar value={value} showValue={false} style={{ width: 'auto', minWidth: '70px', height: '8px' }}></ProgressBar>
        </div>
      </div>
    );
  };

  const itemTemplate = (inFile: object, props: ItemTemplateOptions) => {
    const file: any = inFile as File;

    return (
      <div className='flex align-items-center flex-wrap justify-content-center'>
        <Avatar size='xlarge' image={file.objectURL} className={'a8-image-upload-avatar'} />
      </div>
    );
  };

  const emptyTemplate = () => {
    return (
      <div className='flex align-items-center flex-column'>
        <i className='pi pi-image mt-3 p-5' style={{ fontSize: '2em', borderRadius: '50%', backgroundColor: 'var(--surface-b)', color: 'var(--surface-d)' }}></i>
        <span style={{ fontSize: '1.2em', color: 'var(--text-color-secondary)' }} className='mt-5'>
            {trans(t, 'dragAndDropImageHere')}
        </span>
      </div>
    );
  };

  const chooseOptions = { icon: 'pi pi-fw pi-images', iconOnly: true, className: 'custom-choose-btn p-button-rounded p-button-outlined' };
  const uploadOptions = { icon: 'pi pi-fw pi-cloud-upload', iconOnly: true, className: 'custom-upload-btn p-button-success p-button-rounded p-button-outlined' };
  const cancelOptions = { icon: 'pi pi-fw pi-times', iconOnly: true, className: 'custom-cancel-btn p-button-danger p-button-rounded p-button-outlined' };

  const renderInput = (field: any, fieldState: any) => {
    return <InputText id={field.name} value={field.value || ''} onChange={(e) => onFieldChange(field, fieldState, e.target.value, 'default')}/>;
  }

  return <>
    {!(hideLabel || false) && (
      <label className={'block text-ucfirst mb-2'} htmlFor={fieldKey + '-image-widget'}>{label}</label>
    )}

    {loading || imageLoading ? (
      <>
        {loading && (<ProgressBar value={uploadProgress} />)}
        <PageLoader />
      </>
    ) : (
      edit || (imageUrl === null || imageUrl === undefined) ? (
        <div className={fieldKey + '-image-widget image-widget'}>
          <Tooltip target='.custom-choose-btn' content={trans(t, 'chooseImage')} position='bottom'/>
          <Tooltip target='.custom-upload-btn' content={trans(t, 'uploadImage')} position='bottom'/>
          <Tooltip target='.custom-cancel-btn' content={trans(t, 'clearImage')} position='bottom'/>

          <FileUpload ref={fileUploadRef} accept='image/*' maxFileSize={1000000} auto={true}
                      onUpload={onTemplateUpload} onSelect={onTemplateSelect} onError={onTemplateClear} onClear={onTemplateClear}
                      headerTemplate={headerTemplate} itemTemplate={itemTemplate} emptyTemplate={emptyTemplate}
                      chooseOptions={chooseOptions} uploadOptions={uploadOptions} cancelOptions={cancelOptions}
                      customUpload uploadHandler={customUploader}/>
        </div>
      ) : (
        <>
          <div className={fieldKey + '-image-viewer image-viewer mb-2'}>
            <Image src={imageUrl} />
          </div>

          {!disabled && (
            <div className={fieldKey + '-image-controls image-controls flex flex-row gap-1'}>
              <Button label={trans(t, 'form|image.edit')} onClick={() => editImage()}/>
              <Button label={trans(t, 'form|image.remove')} severity={'danger'} onClick={() => removeImage()}/>
            </div>
          )}
        </>
      )
    )}

    {
      help && (
        <span className={'help text-xs mb-2 block'}>{help}</span>
      )
    }

    <div className={'hidden'}>
      <BaseTextField fieldKey={fieldKey} control={control} errorMessage={errorMessage} rules={rules} classes={classes} renderInput={renderInput} disabled={disabled} />
    </div>
  </>
};
