import React, { useEffect, useState } from 'react';
import { AxiosResponse } from 'axios';

import { Column } from 'primereact/column';
import { Fieldset } from 'primereact/fieldset';
import { Message } from 'primereact/message';
import { Skeleton } from 'primereact/skeleton';
import { TabPanel, TabView } from 'primereact/tabview';

import { amountCell, companyCell, DatatableWrapper, SimpleText } from 'components';
import { IRequestParams, isClient } from 'services';
import { iri, isValidUUID, trans, ucfirst} from 'utilities';

import appUri from 'config/appUri.json';
import { replace } from 'lodash';

export const RemunerationDirigeant = (props: any) => {
  const { appState, apim, id, t } = props;
  const [activeIndex, setActiveIndex] = useState(0);
  const [missionRemunerationDirigeant, setMissionRemunerationDirigeant ]  = useState<any>(null);
  const [missionDate, setMissionDate ]  = useState<any>(null);
  const [societes, setSocietes] = useState<any[]>([]);
  const missionYear =  new Date(missionDate).getFullYear()
  const dossier = appState.dossier();
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    appState.setCivilYear(missionYear)
  }, [missionDate]); // eslint-disable-line react-hooks/exhaustive-deps

  // Override Page Header (defaults into ModuleWrapper, then MissionsRouter).
  useEffect(() => {
    appState.setBreadcrumb([
      isClient()
        ? { label: trans(t, 'dossier', 2) }
        : { label: trans(t, 'dossier', 2), to: appUri.dos.list },
      { label: dossier.title, to: replace(appUri.dos.page, ':id', id)},
      { label: trans(t, 'mission', 2), to: replace(appUri.dos.missions.history, ':id', id)},
      { label: trans(t, 'remuneration_dirigeant') }
    ]);
  }, [id]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    setLoading(true)

    apim.fetchEntity({
      resourceType: 'missions',
      id: appState.mission()?.id,
      params: [
        { label: 'expand[]', value: 'mission_type:read' },
        { label: 'expand[]', value: 'mission:read_hypothesis' },
        { label: 'expand[]', value: 'mission_hypothesis:read' },
        { label: 'expand[]', value: 'mission_hypothesis:read_missions_remunerations' },
        { label: 'expand[]', value: 'mission_remuneration:read' },
        { label: 'expand[]', value: 'mission_remuneration_civil_year:read' },
        { label: 'expand[]', value: 'mission_remuneration_company:read' },
        { label: 'expand[]', value: 'mission_remuneration_fiscal_year:read' },
        { label: 'expand[]', value: 'mission_remuneration_civil_year:read_company' },
        { label: 'expand[]', value: 'mission_remuneration_company:read_company' },
        { label: 'expand[]', value: 'mission_remuneration_fiscal_year:read_company' },
      ],
      cache: false,
      success: (res: AxiosResponse) => {
        setMissionDate(res?.data?.date);
        if (res?.data?.hypothesis?.length > 0) {
          const chosenHypothesis = res?.data?.hypothesis?.find((hypothesis: any) => hypothesis.valid === true);
          if (isValidUUID(chosenHypothesis?.id)) {
            setMissionRemunerationDirigeant(chosenHypothesis?.missionsRemunerations[chosenHypothesis?.missionsRemunerations.length - 1]);
            apim.fetchEntity({
              resourceType: 'dossiers',
              id: dossier?.id,
              params: [{label: 'date', value: res?.data?.date}],
              action: 'societes',
              setLoading,
              success: (res: AxiosResponse) => {
                if (res?.data?.companies && res.data.companies.length > 0) {
                  setSocietes(res?.data?.companies);
                }
              }
            } as IRequestParams).then();
          }
        }
      }
    } as IRequestParams).then();
  }, [missionRemunerationDirigeant?.id, appState.mission()?.id]); // eslint-disable-line react-hooks/exhaustive-deps

  const companyBodyTemplate = (rowData: any) => companyCell(rowData, t);

  const dividendesBodyTemplate = (rowData: any) => {
    const civilData = missionRemunerationDirigeant?.remunerationCivilYears?.filter((data: any) => data?.company === iri('dossierCompanies', rowData?.id) && data.civilYear === appState.civilYear() + activeIndex);
    return amountCell(civilData?.[0]?.dividendes ?? 0)
  }
  const remunerationBodyTemplate = (rowData: any) => {
    const fiscalData = missionRemunerationDirigeant?.remunerationFiscalYears?.filter((data: any) => data?.company === iri('dossierCompanies', rowData?.id) && data.fiscalYear === appState.civilYear() + activeIndex);
    const total = (fiscalData?.[0]?.remunerationP1 ?? 0) + (fiscalData?.[0]?.remunerationP2 ?? 0)
    return amountCell(total)
  }

  const salaryBodyTemplate = (rowData: any) => {
    const fiscalData = missionRemunerationDirigeant?.remunerationFiscalYears?.filter((data: any) => data?.company === iri('dossierCompanies', rowData?.id) && data.fiscalYear === appState.civilYear() + activeIndex);
    const total = (fiscalData?.[0]?.salairesP1 ?? 0) + (fiscalData?.[0]?.salairesP2 ?? 0)
    return amountCell(total)
  }
  const totalBodyTemplate = (rowData: any) => {
    const fiscalData = missionRemunerationDirigeant?.remunerationFiscalYears?.filter((data: any) => data?.company === iri('dossierCompanies', rowData?.id) && data.fiscalYear === appState.civilYear() + activeIndex);
    const civilData = missionRemunerationDirigeant?.remunerationCivilYears?.filter((data: any) => data?.company === iri('dossierCompanies', rowData?.id) && data.civilYear === appState.civilYear() + activeIndex);
    const total = (fiscalData?.[0]?.salairesP1 ?? 0) + (fiscalData?.[0]?.salairesP2 ?? 0) + (fiscalData?.[0]?.remunerationP1 ?? 0) + (fiscalData?.[0]?.remunerationP2 ?? 0) +(civilData?.[0]?.dividendes ?? 0)
    return amountCell(total)
  }
  const renderTabPanels = () => {
    const tabPanels = [];
    for (let year = missionYear; year <= missionYear + 4; year++) {
      tabPanels.push(
        <TabPanel key={year} header={year.toString()}>
          <DatatableWrapper rows={societes} additionalClassNames={'p-datatable-no-hover'} noGlobalFilter paginator={false} noFilters>
            <Column field='company' header={trans(t, 'company')} body={companyBodyTemplate} className={'w-3'}/>
            <Column field='dividendes' header={trans(t, 'dividendes')} body={dividendesBodyTemplate} className={'w-2'}/>
            <Column field='remuneration' header={trans(t, 'remuneration')} body={remunerationBodyTemplate} className={'w-2'}/>
            <Column field='salaires' header={trans(t, 'salary', 2)} body={salaryBodyTemplate} className={'w-2'}/>
            <Column field='total' header={trans(t, 'civilTotal')} body={totalBodyTemplate} className={'w-2'} align={'center'}/>
          </DatatableWrapper>
          <p className={'mt-5 mb-5'}>{ucfirst(trans(t, 'brutDataDisplayed'))}.</p>
        </TabPanel>
      );
    }
    return tabPanels;
  }

  return (
    <>
      {loading ? (
          <>
            <Skeleton width={'100%'} height={'20rem'} className={'mb-5'}/>
            <Skeleton width={'100%'} height={'10rem'} className={'mb-5'}/>
            <Skeleton width={'100%'} height={'10rem'} className={'mb-5'}/>
            <Skeleton width={'100%'} height={'10rem'} className={'mb-5'}/>
          </>
        ) :
        <>
          {societes?.length === 0 && (
            <Message text={ucfirst(trans(t, 'company_empty')) + '. '} className={'w-full mx-3 py-3'}/>
          )}
          {societes?.length > 0 && (
            <>
              <TabView activeIndex={activeIndex} onTabChange={(e) => setActiveIndex(e.index)}>
                {renderTabPanels()}
              </TabView>

              <Fieldset legend={`${ucfirst(trans(t, 'initialSituation'))} (${missionYear})`}>
                <div className={'flex flex-column md:flex-row px-3'}>

                  <div className={'flex flex-grow-1 py-4 px-3'}>
                    <SimpleText value={missionRemunerationDirigeant?.remunerationTotaleActuelle} title={trans(t, 'form|mission_remuneration.remunerationTotaleActuelle')}/>
                  </div>
                  <div className={'flex flex-grow-1 py-4 px-3'}>
                    <SimpleText value={missionRemunerationDirigeant?.cotisationsSocialesActuelles} title={trans(t, 'form|mission_remuneration.cotisationsSocialesActuelles')}/>
                  </div>
                  <div className={'flex flex-grow-1 py-4 px-3'}>
                    <SimpleText value={missionRemunerationDirigeant?.impotsSurSocietesActuels} title={trans(t, 'form|mission_remuneration.impotsSurSocietesActuels')}/>
                  </div>
                  <div className={'flex flex-grow-1 py-4 px-3'}>
                    <SimpleText value={missionRemunerationDirigeant?.impotsSurRevenusActuels} title={trans(t, 'form|mission_remuneration.impotsSurRevenusActuels')}/>
                  </div>
                </div>
              </Fieldset>
              <Fieldset legend={`${ucfirst(trans(t, 'optimizedSituation'))} (${missionYear + 1})`}>
                <div className={'flex flex-column md:flex-row px-3'}>
                  <div className={'flex flex-grow-1 py-4 px-3'}>
                    <SimpleText value={missionRemunerationDirigeant?.remunerationTotaleOptimisee} title={trans(t, 'form|mission_remuneration.remunerationTotaleOptimisee')}/>
                  </div>
                  <div className={'flex flex-grow-1 py-4 px-3'}>
                    <SimpleText value={missionRemunerationDirigeant?.cotisationsSocialesOptimisees} title={trans(t, 'form|mission_remuneration.cotisationsSocialesOptimisees')}/>
                  </div>
                  <div className={'flex flex-grow-1 py-4 px-3'}>
                    <SimpleText value={missionRemunerationDirigeant?.impotsSurSocietesOptimises} title={trans(t, 'form|mission_remuneration.impotsSurSocietesOptimises')}/>
                  </div>
                  <div className={'flex flex-grow-1 py-4 px-3'}>
                    <SimpleText value={missionRemunerationDirigeant?.impotsSurRevenusOptimises} title={trans(t, 'form|mission_remuneration.impotsSurRevenusOptimises')}/>
                  </div>
                </div>
              </Fieldset>
              <Fieldset legend={ucfirst(trans(t, 'recommendedStatus',missionRemunerationDirigeant?.remunerationCompanies?.length ))}>
                <div className={'flex flex-column md:flex-row px-3'}>
                  {missionRemunerationDirigeant?.remunerationCompanies?.map((data: any) => (
                    <div className={'flex flex-grow-1 py-4 px-3'} key={data.id}>
                      <SimpleText value={data.type} title={societes?.find((societe: any) => iri('dossierCompanies',societe.id) === data.company)?.version?.raisonSociale} method={'list'} methodParams={{listKey: 'societeTypes'}}/>
                    </div>
                  ))}
                </div>
              </Fieldset>
            </>
          )}
        </>
      }
    </>
  )
}
