import React, { useCallback, useEffect, useState } from 'react';
import { Column } from 'primereact/column';

import { dialog, isValidUUID, trans, triggerFormReset, triggerFormSubmit, ucfirst, uuidFromIri } from 'utilities';
import { amountCell, DatatableWrapper, onPersonnePhysiqueAddSubmit, amountEditor, personCell, dateTimeCell } from 'components';
import { IRequestParams } from 'services';
import { FormWrapper } from 'forms';

import appUri from 'config/appUri.json';

export const PatrimoineHeritiersDatatable = (props: any) => {
  const { apim, data, context, title, editMode } = props;
  const { t } = apim.di();
  const dossierId = isValidUUID(context?.dossier?.id) ? context?.dossier?.id : uuidFromIri(context?.dossier);
  const personnePhysiqueId = isValidUUID(context?.dossier?.personnePhysique?.id) ? context?.dossier?.personnePhysique?.id : uuidFromIri(context?.dossier?.personnePhysique);

  const [rows, setRows] = useState<any[]>([]);
  const [loading, setLoading] = useState<boolean>(false);

  const resourceType: string = 'patrimoineHeritiers';

  useEffect(() => {
    setLoading(true);
    apim.fetchEntities({
      resourceType,
      cache: false,
      params: [
        {label: 'patrimoine.dossier', value: dossierId},
        {label: 'expand[]', value: 'personne_physique:read'},
        {label: 'expand[]', value: 'personne_physique:read_latest_version'},
        {label: 'expand[]', value: 'personne_physique_data:read_list'},
        {label: 'expand[]', value: 'patrimoine_heritier:read_relation_type'},
      ],
      setLoading,
      setter: setRows
    } as IRequestParams).then();
  }, [data, context]); // eslint-disable-line react-hooks/exhaustive-deps

  const onNew = () => {
    dialog(t, {
      header: trans(t,'table|add.heritier'),
      icon: 'none',
      message: renderAddForm(),
      accept: () => triggerFormSubmit('a8-form-patrimoine-heritier-add'),
      reject: () => triggerFormReset('a8-form-patrimoine-heritier-reset')
    });
  };

  // Handle submission then refresh table rows.
  const onAddSubmit = useCallback((formData: any) => {
    // Avoid duplicates.
    if (rows.filter((_r: any) => _r?.personnePhysique?.id === formData?.personnePhysiqueId).length > 0) {
      setLoading(false);

      return apim.displayError(trans(t, 'form|errors.alreadyExists.summary'), trans(t, 'form|errors.alreadyExists.detail'));
    }

    return onPersonnePhysiqueAddSubmit(apim, {...context, ...{patrimoine: data, type: 'personne_physique'}}, formData, rows, setRows, resourceType, setLoading);
  }, [context?.id, data?.id, rows]); // eslint-disable-line react-hooks/exhaustive-deps

  const relationTypeBody = (rowData: any) => {
    const relationType = rowData?.personnePhysiqueRelation;
    if (relationType !== null && relationType !== undefined) {
      const parts = relationType.split(':');
      const type = parts[0];
      const depth = parseInt(parts[1]);

      if (type === 'child') {
        if (depth <= 1) {
          return ucfirst(trans(t, 'child'));
        } else {
          return ucfirst(trans(t, 'grandChild'));
        }
      } else if (type === 'parent') {
        if (depth <= 1) {
          return ucfirst(trans(t, 'parent'));
        } else {
          return ucfirst(trans(t, 'grandParent'));
        }
      } else if (type === 'spouse') {
        if (depth <= 1) {
          return ucfirst(trans(t, 'spouse'));
        } else {
          return ucfirst(trans(t, 'spouseOfSpouse'));
        }
      }
    }

    return ucfirst(trans(t, 'unknown'));
  };

  // Wrap form render into a useCallback to avoid multiple FormWrapper recalls du to form fields updates.
  const renderAddForm = useCallback(() =>
      <FormWrapper classes={'grid p-fluid w-12'} resourceType={'personnesPhysiques'} formKeyPrefix={'add_pp_dialog'} cancelLink multiple context={{ patrimoine: data, ...{personnePhysiqueId: personnePhysiqueId} }}
                   resetClass={'a8-form-patrimoine-heritier-reset'} submitClass={'a8-form-patrimoine-heritier-add'} onSubmit={onAddSubmit} hideReload/>
    , [context?.id, data?.id, rows]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <DatatableWrapper resourceType={resourceType} tableKey={'heritiers'} title={title ?? trans(t, 'heritier', 2)}
                      noFilters noGlobalFilter paginator={false} isLoading={loading} editMode={editMode} editFields={['montantDu', 'montantRecu']}
                      onNew={onNew} addTitle={trans(t, 'table|add.heritier')} onRefresh={setRows}
                      rows={(rows || [])}>
      <Column field={'personnePhysique'} header={ucfirst(trans(t, 'form|patrimoine.succession.heritier'))}
              align={'center'} alignHeader={'center'} body={(rowData: any) => personCell(rowData?.personnePhysique, {
                url: appUri.cie.per.phy.page.replace(':id', rowData?.personnePhysique?.id),
                label: trans(t, 'seeDetails')
              })} />
      <Column field={'personnePhysique.birthDate'} header={ucfirst(trans(t, 'birthDate'))}
              align={'center'} alignHeader={'center'} body={(rowData: any) => dateTimeCell(rowData?.personnePhysique?.birthDate)} />
      <Column field={'personnePhysiqueRelation'} header={ucfirst(trans(t, 'relationType'))} body={relationTypeBody}
              align={'center'} alignHeader={'center'} style={{ minWidth: '200px', width: '200px' }} />
      <Column field={'montantDu'} header={trans(t, 'table|patrimoine_headers.montantDu')} align={'center'} alignHeader={'center'}
              body={(rowData: any) => amountCell(rowData?.montantDu)}
              editor={(options) => amountEditor(options, {label: trans(t, 'form|patrimoine.succession.montantDu')})}
              style={{ minWidth: '200px', width: '200px' }} />
      <Column field={'montantRecu'} header={trans(t, 'table|patrimoine_headers.montantRecu')} align={'center'} alignHeader={'center'}
              body={(rowData: any) => amountCell(rowData?.montantRecu)}
              editor={(options) => amountEditor(options, {label: trans(t, 'form|patrimoine.succession.montantRecu')})}
              style={{ minWidth: '200px', width: '200px' }} />
    </DatatableWrapper>
  );
};
