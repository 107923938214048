import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { ProgressBar } from 'primereact/progressbar';
import { Steps } from 'primereact/steps';

import { useAppState, useFormState, useUserState } from 'states';
import { useApim } from 'services';
import { trans } from 'utilities';
import { NotFound } from 'pages';
import { Loader } from 'components';

import { Step0 } from './Step0';
import { Step1 } from './Step1';
import { Step2 } from './Step2';
import { Step3 } from './Step3';
import { Step4 } from './Step4';
import { subscribeToHub } from 'forms/dossier';

import appUri from 'config/appUri.json';

export const DossierWizardForm = () => {
  const { step, siren } = useParams();
  const formState = useFormState();
  const apim = useApim();
  const { t, navigate } = apim.di();
  const appState = useAppState();
  const userState = useUserState();
  const baseUrl = appUri.dos.add;
  const formKey = 'wf_dossier';

  // Update Page Header.
  useEffect(() => {
    appState.setPageTitle(trans(t, 'dos.add'));
    appState.setPageActions([]);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const [activeIndex, setActiveIndexState] = useState<number>(formState.getFormStep(formKey));
  const setActiveIndex = (step: number) => {
    formState.setFormStep(formKey, step);
    setActiveIndexState(step);
  };

  // Update active index, current company & breadcrumb.
  useEffect(() => {
    const defaultBC = [{
      label: trans(t, 'dos.add'),
      to: baseUrl
    }];

    switch (step) {
      default:
        setActiveIndex(0);
        defaultBC.push({
          label: trans(t, 'menu|wizard.dossier.title.step0'),
          to: baseUrl
        });
        break;
      case 'dirigeant':
        setActiveIndex(1);
        defaultBC.push({
          label: trans(t, 'menu|wizard.dossier.title.step1'),
          to: baseUrl + appUri.dos.wizard.step2
        });
        break;
      case 'entreprises':
        setActiveIndex(2);
        defaultBC.push({
          label: trans(t, 'menu|wizard.dossier.title.step2'),
          to: baseUrl + appUri.dos.wizard.step2
        });
        break;
      case 'documents':
        setActiveIndex(3);
        defaultBC.push({
          label: trans(t, 'menu|wizard.dossier.title.step3'),
          to: baseUrl + appUri.dos.wizard.step3
        });
        break;
      case 'confirmation':
        setActiveIndex(4);
        defaultBC.push({
          label: trans(t, 'confirmation'),
          to: baseUrl + appUri.dos.wizard.step4
        });
        break;
    }

    appState.setBreadcrumb(defaultBC);
    if (siren) formState.setFieldData(formKey, 'currentCompany', null, siren);
  }, [step, siren]); // eslint-disable-line react-hooks/exhaustive-deps

  const wizardItems = [
    { label: trans(t, 'dossierCreationSelection'), command: () => navigate(baseUrl) },
    { label: trans(t, 'dirigeant'), command: () => navigate(baseUrl) },
    { label: trans(t, 'entreprise', 2), command: () => navigate(baseUrl + appUri.dos.wizard.step2) },
    { label: trans(t, 'document', 2), command: () => navigate(baseUrl + appUri.dos.wizard.step3) },
    { label: trans(t, 'confirmation'), command: () => navigate(baseUrl + appUri.dos.wizard.step4) },
  ];

  // Check hub subscription.
  useEffect(() => {
    subscribeToHub(formState);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const currentStep = () => {
    switch (activeIndex) {
      case -1:
        return <Loader />
      case 0:
        return <Step0 formKey={formKey} apim={apim} formState={formState} next={baseUrl + appUri.dos.wizard.step1} user={userState.id()}/>;
      case 1:
        return <Step1 formKey={formKey} apim={apim} formState={formState} previous={baseUrl + appUri.dos.wizard.step0} next={baseUrl + appUri.dos.wizard.step2} user={userState.id()}/>;
      case 2:
        return <Step2 formKey={formKey} apim={apim} formState={formState} previous={baseUrl + appUri.dos.wizard.step1} next={baseUrl + appUri.dos.wizard.step3} user={userState.id()}/>;
      case 3:
        return <Step3 formKey={formKey} apim={apim} formState={formState} previous={baseUrl + appUri.dos.wizard.step2} next={baseUrl + appUri.dos.wizard.step4} user={userState.id()}/>;
      case 4:
        return <Step4 formKey={formKey} apim={apim} formState={formState} previous={baseUrl + appUri.dos.wizard.step3} user={userState.id()}/>;
      default:
        return <NotFound asBlock/>
    }
  };

  return -1 !== activeIndex ? (
    <>
      <div className={'grid'}>
        <div className={'col'}>
          <ProgressBar value={25 + activeIndex * 25} showValue={false} />
        </div>
      </div>
      <Steps className={'capitalize'} model={wizardItems} activeIndex={activeIndex} readOnly={true} />
      {currentStep()}
    </>
  ) : (
    <NotFound asBlock/>
  );
};
