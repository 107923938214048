import React, { useCallback, useEffect, useState } from 'react';

import { buildModuleTabs, ModuleWrapper } from 'pages';
import { addShortcut, sendNotification, trans } from 'utilities';
import { useFormState } from 'states';
import { isClient } from 'services';

import { MissionsHistory } from './MissionsHistory';
import { MissionRequest } from './MissionRequest';
import { PageLoader} from 'components';

import { RemunerationDirigeant } from './RemunerationDirigeant';
import { StatutDirigeant } from './StatutDirigeant';

import appUri from 'config/appUri.json';

export const MissionsRouter = (props: any) => {
  const { appState, apim, id, module, tab, modulesMenu, type } = props;
  const { t, navigate } = apim.di();
  const dossier = appState.dossier()

  const [tabs, setTabs] = useState<any[]>([]);

  const formState = useFormState();

  useEffect(() => {
    if (type) {
      setTabs([]);
    } else {
      const moduleTabs = buildModuleTabs(modulesMenu, module, navigate);
      if (isClient()) {
        setTabs(moduleTabs);
      }
    }
  }, [id, type, modulesMenu, module, navigate]); // eslint-disable-line react-hooks/exhaustive-deps

  // Override Page Header (defaults into ModuleWrapper), delete "edit" action on missions history datatable.
  useEffect(() => {
    appState.setPageTitle(trans(t, 'menu|pages.title.dossier.missions', 1, appState.dossier().title));
    if (!type) {
      appState.setPageActions(
        !isClient() ?
          [
            { label: trans(t, 'ged'), icon: 'briefcase', to: appUri.dos.page.replace(':id', id ?? '-') + '/ged' },
            { label: trans(t, 'short.add'), icon: 'bookmark', command: () => addShortcut(t, formState, appState) },
            { label: trans(t, 'form|dossier.notificationSend'), icon: 'pi pi-bell', command: () => sendNotification(apim, [dossier.user])}
          ] :
          tab === 'historique' ?
          [{ label: trans(t, 'missionRequest'), icon: 'plus', to: appUri.dos.missions.request.replace(':id', appState.dossier()?.id) }]
            : []
           );
    }
  }, [id, module, type, tab]); // eslint-disable-line react-hooks/exhaustive-deps

  const buildTabContent = useCallback(() => {
    if (type) {
      switch (type) {
        case 'statut_dirigeant' :
          return <StatutDirigeant t={t} id={id} appState={appState} apim={apim}/>;

        case 'remuneration_dirigeant' :
          return <RemunerationDirigeant t={t} id={id} appState={appState} apim={apim}/>;

        default:
          return <MissionsHistory t={t} appState={appState} apim={apim}/>;
      }
    }
    if (tab) {
      switch (tab) {
        case 'historique' :
          return <MissionsHistory t={t} appState={appState} apim={apim}/>;

        default:
          return <MissionRequest t={t} appState={appState} apim={apim}/>;
      }
    }

  }, [id, module, tab, type]); // eslint-disable-line react-hooks/exhaustive-deps

  if (!dossier?.id ) return <PageLoader/>;

  return <ModuleWrapper appState={appState} apim={apim} tabs={tabs} buildTabContent={buildTabContent}/>;
};
