import { trans } from 'utilities';
import { IRequestParams } from 'services';

export const suspendPatrimoineData = (apim: any, id: any, setLoading: any) => {
  const { t } = apim.di();

  setLoading(true);
  return apim.patchEntity({
    resourceType: 'patrimoines',
    id: id,
    setLoading: setLoading,
    notifSuccess: {
      summary: trans(t, 'notification|patrimoineData.suspended.summary'),
      details: trans(t, 'notification|patrimoineData.suspended.details'),
    },
    data: {
      validated: false,
      suspended: true,
    },
  } as IRequestParams);
};

export const validatePatrimoineData = (apim: any, id: any, setLoading: any) => {
  const { t } = apim.di();

  setLoading(true);
  return apim.patchEntity({
    resourceType: 'patrimoines',
    id: id,
    setLoading: setLoading,
    notifSuccess: {
      summary: trans(t, 'notification|patrimoineData.validated.summary'),
      details: trans(t, 'notification|patrimoineData.validated.details'),
    },
    data: {
      validated: true,
      suspended: false,
    },
  } as IRequestParams);
};
