import React, { useCallback } from 'react';

import { ModuleFormWrapper } from 'forms';
import { iri } from 'utilities';

import { DonneesEconomiques } from './DonneesEconomiques';
import { DonneesFiscales } from './DonneesFiscales';
import { DonneesSocialesCollectives } from './DonneesSocialesCollectives';

import { replace } from 'lodash';
import appUri from 'config/appUri.json';

export const EntrepriseFormRouter = (props: any) => {
  const { appState, apim, step, urls } = props;

  const buildTabContent = useCallback(() => {
    switch (step) {
      case 'donnees-fiscales':
        return <DonneesFiscales appState={appState} apim={apim} urls={urls}
                                additionalData={{company: iri('dossierCompanies', appState.company()?.id), fiscalYear: parseInt(appState.exercise())}}/>;

      case 'donnees-sociales-collectives':
        return <DonneesSocialesCollectives appState={appState} apim={apim} urls={urls}
                                          additionalData={{company: iri('dossierCompanies', appState.company()?.id), fiscalYear: parseInt(appState.exercise())}}/>;

      default:
        return <DonneesEconomiques appState={appState} apim={apim} urls={urls}
                                   additionalData={{company: iri('dossierCompanies', appState.company()?.id), fiscalYear: parseInt(appState.exercise())}}/>;
    }
  }, [step]); // eslint-disable-line react-hooks/exhaustive-deps

  return <ModuleFormWrapper appState={appState} apim={apim} mode={appUri.reservedKeywords.exerciceFiscal} buildTabContent={buildTabContent}
                            baseUri={replace(appUri.dos.edit.fiscalYear.uri, ':id', appState.dossier()?.id || '_')} step={step}/>
};
