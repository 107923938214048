import React, { useEffect, useState } from 'react';

import { Dropdown } from 'primereact/dropdown';
import { Skeleton } from 'primereact/skeleton';

import { trans } from 'utilities';
import { IRequestParams, isAdmin, useApim } from 'services';
import { UncontrolledBaseField } from './UncontrolledBaseField';

export const UncontrolledRolesField = (props: any) => {
  const { fieldKey, value, onFieldChange, label, placeholder, classes,
    description, showClear, rolesAvailables, enforceRolesAvailables } = props;

  const apim = useApim();
  const { t } = apim.di();

  const [loading, setLoading] = useState<boolean>(false);
  const [roles, setRoles] = useState<any>(null);
  const restRoles: string[] = rolesAvailables ?? ['ROLE_EXPERT', 'ROLE_EXPERT_ADMIN', 'ROLE_CLIENT'];
  const restRolesEnforce: boolean = (enforceRolesAvailables !== null && enforceRolesAvailables !== undefined) ? enforceRolesAvailables : false;

  useEffect(() => {
    setLoading(true);
    apim.fetchEntities({
      resourceType: 'roles',
      setter: setRoles,
      setLoading,
      formatter: (_roles: any[]) => {
        let _entities = _roles.filter((_role: any) => restRoles.includes(_role.name));

        // Only display all roles if not enforced & admin.
        if (isAdmin() && !restRolesEnforce) {
          _entities = _roles;
        }

        _entities = _entities.map((_role: any) => {
          return ({..._role, ...{
              translated: trans(t, 'system|role.' + _role.name.toLowerCase()),
              order: trans(t, 'system|role_ordered.' + _role.name.toLowerCase())
            }})
        });

        _entities.sort((_a: any, _b: any) => _a.order > _b.order ? 1 : -1);

        return _entities;
      }
    } as IRequestParams).then();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div>
      {loading ?
        <Skeleton height={'2.8rem'} /> :
        <UncontrolledBaseField classes={classes} description={description} >
          <span className={'p-float-label'}>
            <Dropdown
              id={fieldKey} className={'w-full'} placeholder={placeholder || trans(t, 'form|select')} value={value}
              options={roles ?? []} optionLabel={'translated'} optionValue={'id'}
              onChange={(e) => onFieldChange(e.value)} showClear={showClear}
            />
            {(label || fieldKey) && (
              <label className={'text-ucfirst p-float-label-label'} htmlFor={fieldKey}>
                <span>{label ?? trans(t, fieldKey)}</span>
              </label>
            )}
          </span>
        </UncontrolledBaseField>
      }
    </div>
  );
};
