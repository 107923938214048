import React from 'react';
import { trans, ucfirst } from 'utilities';
import { MaskField, NumberField } from 'forms/index';

export const DatesDepartForm = (props: any) => {
  const { t, control, onFieldChange, errorMessage } = props;

  return (
    <div className={'flex flex-column col-12'}>
      <div className={'flex flex-wrap flex-row col-12'}>
        <div className={'col-4'}>
          <MaskField fieldKey={'auPlusTot'} control={control} onFieldChange={onFieldChange}
                     errorMessage={errorMessage}
                     isDate label={ucfirst(trans(t, 'auPlusTot'))} classes={'w-full'}/>
        </div>
        <div className={'col-4'}>
          <MaskField fieldKey={'souhaitee'} control={control} onFieldChange={onFieldChange}
                     errorMessage={errorMessage}
                     isDate label={ucfirst(trans(t, 'souhaitee'))} classes={'w-full'}/>
        </div>
        <div className={'col-4'}>
          <MaskField fieldKey={'preconisee'} control={control} onFieldChange={onFieldChange}
                     errorMessage={errorMessage}
                     isDate label={ucfirst(trans(t, 'preconisee'))} classes={'w-full'}/>
        </div>
      </div>

      <div className={'flex flex-wrap flex-row col-12'}>
        <div className={'col-4'}>
          <MaskField fieldKey={'tauxPleinAcquis'} control={control} onFieldChange={onFieldChange}
                     errorMessage={errorMessage}
                     isDate label={ucfirst(trans(t, 'tauxPleinAcquis'))} classes={'w-full'}/>
        </div>
        <div className={'col-4'}>
          <MaskField fieldKey={'tauxPlein'} control={control} onFieldChange={onFieldChange}
                     errorMessage={errorMessage}
                     isDate label={ucfirst(trans(t, 'tauxPlein'))} classes={'w-full'}/>
        </div>
        <div className={'col-4'}>
          <NumberField fieldKey={'projectionDroits'} mode={'currency'} addon={'euro'} addonPosition={'after'}
                       classes={'w-full'}
                       control={control} onFieldChange={onFieldChange} errorMessage={errorMessage}
                       label={trans(t, 'projectionDroits')}/>
        </div>
      </div>

    </div>
  )
};
