import React from 'react';
import { classNames } from 'primereact/utils';
import { InputNumber } from 'primereact/inputnumber';
import { UncontrolledBaseField } from './UncontrolledBaseField';
import { trans } from 'utilities';
import { useApim } from 'services';

export const UncontrolledNumberField = (props: any) => {
  const { fieldKey, value, onFieldChange, onValueChange, label, tooltip, tooltipOptions, description, classes, addon, addonPosition, disabled, mode,
    currency, minFractionDigits, maxFractionDigits, showButtons, min, max, suffix, prefix } = props;
  const apim = useApim();
  const { t } = apim.di();

  return (
    <UncontrolledBaseField classes={classes} addon={addon} addonPosition={addonPosition} description={description}>
      <span className={'p-float-label'}>
        <InputNumber id={fieldKey} inputClassName={classNames('w-full', (addonPosition === null || addonPosition === undefined || addonPosition.length === 0 ? '' : addonPosition === 'before' ? 'border-noround-left' : 'border-noround-right'))}
                     className={'text-ucfirst w-full'} maxFractionDigits={maxFractionDigits || 2} minFractionDigits={minFractionDigits || 0}
                     value={value} disabled={disabled} mode={mode || 'currency'} currency={currency || 'EUR'}
                     onChange={(e) => {
                       if (onFieldChange !== null && onFieldChange !== undefined) {
                         onFieldChange(e.value)
                       }
                     }}
                     onValueChange={(e) => {
                       if (onValueChange !== null && onValueChange !== undefined) {
                         onValueChange(e.value)
                       }
                     }}
                     tooltip={tooltip} tooltipOptions={tooltipOptions || {position: 'top'}}
                     showButtons={showButtons} min={min} max={max} suffix={suffix} prefix={prefix}/>
        {(label || fieldKey) && (
          <label className={'text-ucfirst p-float-label-label'} htmlFor={fieldKey}>
            <span>{label ?? trans(t, fieldKey)}</span>
          </label>
        )}
      </span>
    </UncontrolledBaseField>
  );
};
