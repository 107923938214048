import React from 'react';
import { AxiosResponse } from 'axios';
import { Column } from 'primereact/column';

import { iri, isValidUUID, montant, trans, ucfirst } from 'utilities';
import { amountEditor, DatatableWrapper, numberEditor, PageLoader } from 'components';
import { IRequestParams } from 'services';

export const PrevoyancePaliersRenteEducationDatatable = (props: any) => {
  const { title, context, apim } = props;
  const { t } = apim.di();
  const resourceType: string = 'prevoyanceEducationAnnuities';

  const onNewRow = (_rows: any[], callback: any) => {
    if (_rows.length === 0) {
      if (isValidUUID(context?.id)) {
        apim.postEntity({
          resourceType: resourceType,
          data: {
            startDay: 0,
            endYear: 11,
            amount: 1500,
            prevoyanceObligatoire: iri('prevoyancesObligatoires', context?.id ?? '_')
          },
          success: (res: AxiosResponse) => callback([res.data])
        } as IRequestParams).then();
      }

      return;
    }

    if (isValidUUID(context?.id)) {
      apim.postEntity({
        resourceType: resourceType,
        data: {
          startYear: parseInt(_rows[_rows.length - 1].endYear) + 1,
          endYear: parseInt(_rows[_rows.length - 1].endYear) + 3,
          amount: _rows[_rows.length - 1].amount,
          prevoyanceObligatoire: iri('prevoyancesObligatoires', context?.id ?? '_')
        },
        success: (res: AxiosResponse) => callback([..._rows, ...[res.data]])
      } as IRequestParams).then();
    }
  };

  const amountBody = (rowData: any) => <span>{rowData?.amount ? montant(rowData?.amount) : 'N/A'}</span>;
  const startBody = (rowData: any) => {
    if (!rowData?.startYear) return 'N/A';

    if (rowData?.startYear === 0) {
      return ucfirst(trans(t, 'birth'));
    }

    return <span>{rowData?.startYear + ' ' + trans(t, 'year', rowData?.startYear && rowData?.startYear > 0 ? rowData?.startYear : 1)}</span>;
  };
  const endBody = (rowData: any) => {
    if (!rowData?.endYear) return 'N/A';

    return <span>{rowData?.endYear + ' ' + trans(t, 'year', rowData?.endYear ?? 1)}</span>;
  };

  if (!isValidUUID(context?.id)) {
    return <PageLoader />;
  }

  return (
    <DatatableWrapper
      resourceType={resourceType} tableKey={'prevoyance-tableau-annuites'} noFilters noGlobalFilter onNew={onNewRow}
      additionalData={{ prevoyanceObligatoire: iri('prevoyancesObligatoires', context?.id) }}
      params={[{label: 'prevoyanceObligatoire', value: context?.id}]}
      title={title ?? trans(t, 'table|prevoyanceEducationAnnuitiesTitle')} editMode paginator={false} parentClasses={[]}>
      <Column field={'startYear'} header={trans(t, 'table|prevoyance_age_start')} align={'center'} alignHeader={'center'} body={startBody}
              editor={(options) => numberEditor(options, {label: trans(t, 'table|prevoyance_age_start'), suffix: ' ' + trans(t, 'year', 2)})}/>
      <Column field={'endYear'} header={trans(t, 'table|prevoyance_age_end')} align={'center'} alignHeader={'center'} body={endBody}
              editor={(options) => numberEditor(options, {label: trans(t, 'table|prevoyance_age_end'), suffix: ' ' + trans(t, 'year', 2)})}/>
      <Column field={'amount'} header={trans(t, 'table|prevoyance_age')} align={'center'} alignHeader={'center'} body={amountBody}
              editor={(options) => amountEditor(options, {label: trans(t, 'table|prevoyance_age')})}/>
    </DatatableWrapper>
  );
};
