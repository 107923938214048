import React from 'react';
import { Fieldset } from 'primereact/fieldset';

import { trans, ucfirst } from 'utilities';
import { ContactField, SimpleText } from 'components';

import { isAdmin, isExpertAdmin } from 'services';

import { forEach, isEmpty } from 'lodash';

export const User = (props: any) => {
  const { t, data } = props;
  const dates = [{ key: 'created' }, { key: 'updated' }];
  const activeLinks: string[] | null = Object.values(data?.activeLinks ?? {});

  return <>
    <Fieldset legend={ucfirst(trans(t, 'userInfo'))} className={'mb-2'}>
      <div className={'flex flex-column md:flex-row px-3'}>
        <div className={'flex flex-grow-1 py-4 px-3'}>
          <SimpleText title={trans(t, 'role')} value={data?.roles} method={(roles: []) => {
            let isSuperAdmin = false;
            let isAdmin = false;
            let isExpertAdmin = false;
            let isExpert = false;
            let isClient = false;

            forEach(roles, (role: any) => {
              if (role === 'ROLE_SUPER_ADMIN') {
                isSuperAdmin = true;
                return false;
              } else if (role === 'ROLE_ADMIN') {
                isAdmin = true;
                return false;
              } else if (role === 'ROLE_EXPERT_ADMIN') {
                isExpertAdmin = true;
                return false;
              } else if (role === 'ROLE_EXPERT') {
                isExpert = true;
                return false;
              } else if (role === 'ROLE_CLIENT') {
                isClient = true;
                return false;
              }
            });

            if (isSuperAdmin) {
              return ucfirst(trans(t, 'system|role.role_super_admin'));
            } else if (isAdmin) {
              return ucfirst(trans(t, 'system|role.role_admin'));
            } else if (isExpertAdmin) {
              return ucfirst(trans(t, 'system|role.role_expert_admin'));
            } else if (isExpert) {
              return ucfirst(trans(t, 'system|role.role_expert'));
            } else if (isClient) {
              return ucfirst(trans(t, 'system|role.role_client'));
            } else {
              return ucfirst(trans(t, 'system|role.role_user'));
            }
          }} />
        </div>

        <div className={'flex flex-grow-1 py-4 px-3'}>
          <ContactField title={trans(t, 'email')} text={data ? data.email : ''} />
        </div>

        {(isAdmin() || isExpertAdmin()) && (
          <div className={'flex flex-grow-1 py-4 px-3'}>
            <SimpleText title={trans(t, 'em.verified')} value={data?.emailVerified} method={'bool'} />
          </div>
        )}

        <div className={'flex flex-grow-1 py-4 px-3'}>
          <SimpleText title={trans(t, 'userName')} text={data?.username} />
        </div>

        <div className={'flex flex-grow-1 py-4 px-3'}>
          <SimpleText title={trans(t, 'lastLogin')} value={data?.lastLogin} method={'datetime'} emptyValue={ucfirst(trans(t, 'never'))} />
        </div>
      </div>
    </Fieldset>


    <Fieldset legend={ucfirst(trans(t, 'privacy'))} className={'mb-2'}>
      <div className={'flex flex-grow-1 py-2 px-2 flex-column'}>
          {data?.supportAccessAgreement ? (
            <p><i className={'pi pi-check text-green-600 px-2'} /> {trans(t, 'supportAccessAgreementMessage')}</p>
          ) : (
            <p><i className={'pi pi-times text-red-600 px-2'} /> {trans(t, 'supportAccessAgreementDenyMessage')}</p>
          )}
          {data?.gdprAgreement ? (
            <p><i className={'pi pi-check text-green-600 px-2'} /> {trans(t, 'gdprAgreementMessage')}</p>
          ) : (
            <p><i className={'pi pi-times text-red-600 px-2'} /> {trans(t, 'gdprAgreementDenyMessage')}</p>
          )}
      </div>
    </Fieldset>

    {!isEmpty(activeLinks) && (
      <Fieldset legend={ucfirst(trans(t, 'link', 2))}>
        <div className={'flex flex-grow-1 py-2 px-2 flex-column'}>
          {activeLinks?.includes('msgraph') ? (
            <p><i className={'pi pi-microsoft text-blue-600 px-2'} /> {trans(t, 'msgraphAccountLinkedMessage')}</p>
          ) : (
            <p><i className={'pi pi-microsoft text-gray-600 px-2'} /><small>{trans(t, 'msgraphAccountNotLinkedMessage')}</small></p>
          )}
          {activeLinks?.includes('google') ? (
            <p><i className={'pi pi-google text-red-600 px-2'} />{trans(t, 'googleAccountLinkedMessage')}</p>
          ) : (
            <p><i className={'pi pi-google text-gray-600 px-2'} /><small>{trans(t, 'googleAccountNotLinkedMessage')}</small></p>
        )}
        </div>
      </Fieldset>
    )}


    <div className={'flex flex-row justify-content-end'}>
      {dates.map((dates, index) => (
        data &&
        <div key={index} className={'flex px-3 py-5'}>
          <SimpleText title={trans(t, dates.key + '.at')} value={data[dates.key]} method={'datetime'} />
        </div>
      ))}
    </div>
  </>;
};
