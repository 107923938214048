import React, { useEffect, useState } from 'react';
import { Skeleton } from 'primereact/skeleton';
import { Tooltip } from 'primereact/tooltip';

import { IRequestParams } from 'services';
import { Userpicture } from 'components';
import { dateAgoString, dateString, trans, ucfirst } from 'utilities';

export const RecentConnexions = (props: any) => {
  const { apim } = props;
  const { t} = apim.di();

  const [loading, setLoading] = useState<boolean>(false);
  const [users, setUsers] = useState<any[]>([]);

  useEffect(() => {
    setLoading(true);
    apim.fetchEntities({
      resourceType: 'users',
      setLoading: setLoading,
      cache: false,
      notif: false,
      params: [
        {label: 'order[lastLogin]', value: 'DESC'},
        {label: 'exists[lastLogin]', value: 'true'},
        {label: 'active', value: 'true'},
        {label: 'itemsPerPage', value: '15'},
      ],
      setter: setUsers
    } as IRequestParams).then();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return <div className={'online-members flex flex-column ml-3 mb-6'}>
    <h6 className={'header text-ucfirst'}>{trans(t, 'logins.latest')}</h6>
    <div className={'flex flex-row flex-wrap gap-1'}>
      {loading ? (
        [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12].map((i: number) => (
          <Skeleton key={i} shape={'circle'} size={'3rem'} className={'border-circle'}/>
        ))
      ) : (
        users.map((user: any, i: number) => (
          <React.Fragment key={i}>
            <div className={'online-member-item--' + user.id}><Userpicture key={i} userId={user.id} userRoles={user.roles} userPictureUrl={user.pictureUrl ?? null} userInitials={user.initials} userFirstName={user.firstName} userLastName={user.lastName} apim={apim}/></div>
            <Tooltip target={'.online-member-item--' + user.id} position={'top'} content={(user.fullName !== undefined ? user.fullName + "\n" : '') + ucfirst(trans(t, 'lastLogin')) + "\n" + dateString(user.lastLogin, 'DD/MM/YYYY HH:mm') + "\n" + dateAgoString(user.lastLogin)} mouseTrack/>
          </React.Fragment>
        ))
      )}
    </div>
  </div>;
};
