import React from 'react';
import { Card } from 'primereact/card';
import { Button } from 'primereact/button';

import { trans, ucfirst } from 'utilities';
import { PatrimoineFormRouter } from 'forms';

import biensImage from 'assets/images/illustrations/landing/patrimoine.jpg';
import patrimoineImage from 'assets/images/illustrations/landing/contracts.jpg';
import donationImage from 'assets/images/illustrations/landing/donation.jpg';

import appUri from 'config/appUri.json';

export const PatrimoineFormLanding = (props: any) => {
  const { apim, dossier, tab } = props;
  const { t, navigate } = apim.di();

  // Return tab's forms first.
  if (tab) return <PatrimoineFormRouter apim={apim} dossier={dossier} tab={tab} />;

  const header = (image: string) => <img alt={'form-landing-header'} src={image} className={'border-round border-noround-bottom'} />;

  return (
    <div className={'p-2 flex justify-content-center'}>
      <div>
        <h2 className={'mb-3 text-center'}>{trans(t, 'menu|landing.patrimoine.title')}</h2>
        <p className={'mb-5 text-center'}>{trans(t, 'menu|landing.patrimoine.subtitle')}</p>

        <div className={'a8-dossier-card-wrapper'}>
          <div className={'flex gap-5 justify-content-center'}>
            <Card title={trans(t, 'menu|landing.dossier.patrimoineG.title')}
                  subTitle={trans(t, 'menu|landing.dossier.patrimoineG.subtitle')}
                  header={header(patrimoineImage)} className={'a8-dossier-card-item col-4'}
                  onClick={() => navigate(appUri.dos.edit.patrimoine.uri.replace(':id', dossier?.id) + '/' + appUri.dos.edit.patrimoine.tabs[0])}>
              <p className={'m-0'}>{trans(t, 'menu|landing.dossier.patrimoineG.body')}</p>
            </Card>

            <Card title={trans(t, 'menu|landing.dossier.patrimoineBiens.title')}
                  subTitle={trans(t, 'menu|landing.dossier.patrimoineBiens.subtitle')}
                  header={header(biensImage)} className={'a8-dossier-card-item col-4'}
                  onClick={() => navigate(appUri.dos.edit.patrimoine.biens.landing.replace(':id', dossier?.id))}>
              <p className={'m-0'}>{trans(t, 'menu|landing.dossier.patrimoineBiens.body')}</p>
            </Card>

            <Card title={trans(t, 'menu|landing.dossier.patrimoineDonations.title')}
                  subTitle={trans(t, 'menu|landing.dossier.patrimoineDonations.subtitle')}
                  header={header(donationImage)} className={'a8-dossier-card-item col-4'}
                  onClick={() => navigate(appUri.dos.edit.patrimoine.donations.landing.replace(':id', dossier?.id))}>
              <p className={'m-0'}>{trans(t, 'menu|landing.dossier.patrimoineDonations.body')}</p>
            </Card>
          </div>
        </div>

        <div className={'flex align-items-center justify-content-center mt-6'}>
          <Button icon={'pi pi-chevron-left'} link label={ucfirst(trans(t, 'back'))} onClick={() => navigate(appUri.dos.edit.landing.replace(':id', dossier?.id))} />
        </div>
      </div>
    </div>
  )
};
