import React, { useEffect, useState } from 'react';
import { AxiosResponse } from 'axios';
import { Button } from 'primereact/button';
import { Column } from 'primereact/column';
import { ColumnGroup } from 'primereact/columngroup';
import { DataTable } from 'primereact/datatable';
import { InputNumber } from 'primereact/inputnumber';
import { Row } from 'primereact/row';

import { IRequestParams } from 'services';
import { companyCell, PageLoader } from 'components';
import { asDate, dateString, trans, ucfirst } from 'utilities';
import { Error, NotFound } from 'pages';

import { forEach } from 'lodash';
import appUri from 'config/appUri.json';

export const RevenuJuridic = (props: any) => {
  const { appState, apim, urls } = props;
  const { t, navigate } = apim.di();

  const [loading, setLoading] = useState<boolean>(false);
  const [notFound, setNotFound] = useState<boolean>(false);
  const [errored, setErrored] = useState<boolean>(false);

  const dossier = appState.dossier();
  const year = appState.civilYear();

  const [loadingRows, setLoadingRows] = useState<string[]>([]);
  const [dossierJuridicIncomeDatas, setDossierJuridicIncomeDatas] = useState<any>(null);
  const [datatableData, setDatatableData] = useState<any>(null);
  const cancelUri: string = urls?.landing + '/revenu-et-imposition?maj=' + Date.now();

  const formatDossierJuridicIncomeData = (dossierJuridicIncomeData: any) => {
    // Find the right exercice fiscal.

    let exerciceFiscal = null;
    const exercicesFiscaux = dossierJuridicIncomeData.company.exercicesFiscaux;
    forEach(exercicesFiscaux, (ex: any) => {
      if (ex.year === dossierJuridicIncomeData.fiscalYear) {
        exerciceFiscal = ex;
      }
    });

    return {
      id: dossierJuridicIncomeData.id,
      company: dossierJuridicIncomeData.company,
      fiscalYear: dossierJuridicIncomeData.fiscalYear,
      exerciceFiscal: exerciceFiscal,
      remunerationP1: dossierJuridicIncomeData.remunerationP1 || null,
      remunerationP2: dossierJuridicIncomeData.remunerationP2 || null,
      remunerationPrevue: dossierJuridicIncomeData.remunerationPrevue || null,
      dividendesBruts: dossierJuridicIncomeData.dividendesBruts || null,
      dividendesPrevus: dossierJuridicIncomeData.dividendesPrevus || null,
    }
  }

  // Load form data.
  useEffect(() => {
    if (!dossier?.id || !year) return;

    setLoading(true);
    apim.fetchEntities({
      resourceType: 'dossiers',
      action: 'revenuJuridique',
      params: [{label: 'year', value: year}],
      id: dossier?.id,
      setLoading: setLoading,
      setErrored: setErrored,
      setNotFound: setNotFound,
      cache: false,
      success: (res: AxiosResponse) => {
        setDossierJuridicIncomeDatas(null);
        setDatatableData(null);

        if (res?.data && (res?.data?.juridicIncomeData || []).length > 0) {
          setDossierJuridicIncomeDatas(res?.data?.juridicIncomeData);

          let _datatableData: any[] = [];
          forEach(res.data.juridicIncomeData, (dossierJuridicIncomeData: any) => {
            _datatableData.push(formatDossierJuridicIncomeData(dossierJuridicIncomeData));
          });

          setDatatableData(_datatableData);

        }
      }
    } as IRequestParams).then();
  }, [dossier?.id, year]); // eslint-disable-line react-hooks/exhaustive-deps
  if (!dossier?.id || loading) return <PageLoader />;
  if (notFound) return <NotFound asBlock/>;
  if (errored) return <Error asBlock/>;

  const companyBodyTemplate = (rowData: any) => {
    return companyCell(rowData.company, t);
  }

  const exerciceFiscalBodyTemplate = (rowData: any) =>
    <span>
      {dateString(asDate(rowData.exerciceFiscal.start)) +
        ' - ' +
        dateString(asDate(rowData.exerciceFiscal.end))}
    </span>
  ;

  const exerciceFiscalPart = (rowData: any) => {
    let index = 0;
    let currentEntryIndex = 0;
    forEach(dossierJuridicIncomeDatas, (dossierJuridicIncomeData: any) => {
      if (rowData.company.id === dossierJuridicIncomeData.company.id) {
        index++;
      }

      if (rowData.id === dossierJuridicIncomeData.id) {
        currentEntryIndex = index;
      }
    });

    return currentEntryIndex;
  }

  const exerciceFiscalPartDates = (rowData: any) => {
    let exerciceFiscalPartDates = null;

    const civilYearStart = asDate('01/01/' + year);
    const civilYearEnd = asDate('31/12/' + year);
    const fiscalYearStart = asDate(rowData.exerciceFiscal.start);
    const fiscalYearEnd = asDate(rowData.exerciceFiscal.end);

    const currentEntryIndex = exerciceFiscalPart(rowData);
    if (currentEntryIndex === 1) {
      // Display start of exercice fiscal to end of civil year.
      exerciceFiscalPartDates = dateString(fiscalYearStart) + ' - ' + dateString(civilYearEnd);
    } else {
      // Display start of civil year to end of exercice fiscal.
      exerciceFiscalPartDates = dateString(civilYearStart) + ' - ' + dateString(fiscalYearEnd);
    }

    return exerciceFiscalPartDates;
  }

  const exerciceFiscalPartTemplate = (rowData: any) => {
    let companyDossierJuridicIncomeDatas = [];
    forEach(dossierJuridicIncomeDatas, (dossierJuridicIncomeData: any) => {
      if (rowData.company.id === dossierJuridicIncomeData.company.id) {
        companyDossierJuridicIncomeDatas.push(dossierJuridicIncomeData);
      }
    });

    return (dossierJuridicIncomeDatas.length === 1) ?
      <span>{ucfirst(trans(t, ('totality')))}</span> :
      <span>{ucfirst(trans(t, ('part'))) + ' ' + exerciceFiscalPart(rowData)}</span>;
  };

  const exerciceFiscalPartDatesTemplate = (rowData: any) => {
    let companyDossierJuridicIncomeDatas = [];
    forEach(dossierJuridicIncomeDatas, (dossierJuridicIncomeData: any) => {
      if (rowData.company.id === dossierJuridicIncomeData.company.id) {
        companyDossierJuridicIncomeDatas.push(dossierJuridicIncomeData);
      }
    });

    return <span>{exerciceFiscalPartDates(rowData)}</span>;
  };

  const remunerationBodyTemplate = (rowData: any) => {
    const currentEntryIndex = exerciceFiscalPart(rowData);
    const value = (currentEntryIndex === 1) ? rowData.remunerationP1 || null : rowData.remunerationP2 || null;

    return <InputNumber key={rowData.id + '_remuneration'} value={value} minFractionDigits={0} maxFractionDigits={0} mode='currency' currency='EUR' locale='fr-FR' onValueChange={(e) => {
        const data: any = {};
        if (currentEntryIndex === 1) {
          data.remunerationP1 = e.value || null;
        } else {
          data.remunerationP2 = e.value || null;
        }

        saveDossierJuridicIncomeData(rowData, data)
      }} disabled={loadingRows.includes(rowData.id)} placeholder={ucfirst(trans(t, 'remuneration'))} />
  };

  const remunerationPrevueBodyTemplate = (rowData: any) =>
    <InputNumber key={rowData.id + '_remunerationPrevue'} value={rowData.remunerationPrevue} onValueChange={(e) => saveDossierJuridicIncomeData(rowData, {remunerationPrevue: e.value})}
                        minFractionDigits={0} maxFractionDigits={0} mode={'currency'} currency={'EUR'} locale={'fr-FR'} disabled={loadingRows.includes(rowData.id)} placeholder={ucfirst(trans(t, 'remunerationPrevueEn')) + ' ' + rowData.fiscalYear} />
  ;

  const dividendesBrutsBodyTemplate = (rowData: any) =>
    <InputNumber key={rowData.id + '_dividendesBruts'} value={rowData.dividendesBruts} onValueChange={(e) => saveDossierJuridicIncomeData(rowData, {dividendesBruts: e.value})}
                        minFractionDigits={0} maxFractionDigits={0} mode={'currency'} currency={'EUR'} locale={'fr-FR'} disabled={loadingRows.includes(rowData.id)} placeholder={ucfirst(trans(t, 'dividendesBrutsEn')) + ' ' + rowData.fiscalYear} />
  ;

  const dividendesPrevusBodyTemplate = (rowData: any) =>
    <InputNumber key={rowData.id + '_dividendesPrevus'} value={rowData.dividendesPrevus} onValueChange={(e) => saveDossierJuridicIncomeData(rowData, {dividendesPrevus: e.value})}
                          minFractionDigits={0} maxFractionDigits={0} mode={'currency'} currency={'EUR'} locale={'fr-FR'} disabled={loadingRows.includes(rowData.id)} placeholder={ucfirst(trans(t, 'dividendesPrevuEn')) + ' ' + rowData.fiscalYear} />
  ;

  const saveDossierJuridicIncomeData = (rowData: any, data: any) => {
    // Find the dossierJuridicIncomeData entity.
    let dossierJuridicIncomeData: any = null;
    forEach(dossierJuridicIncomeDatas, (entity: any) => {
      if (rowData.id === entity.id) {
        dossierJuridicIncomeData = entity;
      }
    });

    let _data: any = {};
    if (data.remunerationP1 !== undefined) {
      _data.remunerationP1 = data.remunerationP1;
    }

    if (data.remunerationP2 !== undefined) {
      _data.remunerationP2 = data.remunerationP2;
    }

    if (data.remunerationPrevue !== undefined) {
      _data.remunerationPrevue = data.remunerationPrevue;
    }

    if (data.dividendesBruts !== undefined) {
      _data.dividendesBruts = data.dividendesBruts;
    }

    if (data.dividendesPrevus !== undefined) {
      _data.dividendesPrevus = data.dividendesPrevus;
    }

    if (dossierJuridicIncomeData !== null && Object.keys(_data).length > 0) {
      let _loadingRows: string[] = [...loadingRows];
      _loadingRows.push(dossierJuridicIncomeData.id);
      setLoadingRows(_loadingRows);

      apim.patchEntity({
        resourceType: 'dossierJuridicIncomeData',
        id: dossierJuridicIncomeData.id,
        data: _data,
        success: (res: AxiosResponse) => {
          if (res?.data && res?.data.id) {
            // Refresh dossierJuridicIncomeData array.
            let _dossierJuridicIncomeDatas: any = [];
            forEach(dossierJuridicIncomeDatas, (dossierJuridicIncomeData: any) => {
              if (dossierJuridicIncomeData.id === res.data.id) {
                res.data.company = dossierJuridicIncomeData.company;
                _dossierJuridicIncomeDatas.push(res?.data);
              } else {
                _dossierJuridicIncomeDatas.push(dossierJuridicIncomeData);
              }
            });

            setDossierJuridicIncomeDatas(_dossierJuridicIncomeDatas);

            // Refresh datatable.
            let _datatableData: any[] = [];
            forEach(_dossierJuridicIncomeDatas, (_dossierJuridicIncomeData: any) => {
              _datatableData.push(formatDossierJuridicIncomeData(_dossierJuridicIncomeData));
            });

            setDatatableData(_datatableData);

            let _loadingRows: string[] = [];
            forEach(loadingRows, (loadingRow: any) => {
              if (loadingRow !== res.data.id) {
                _loadingRows.push(loadingRow);
              }
            });

            setLoadingRows(_loadingRows);
          }
        }
      } as IRequestParams).then();
    }
  };

  const headerGroup = (
    <ColumnGroup>
      <Row>
        <Column header={trans(t, 'company')} rowSpan={1} />
        <Column header={trans(t, 'exerciceFiscal')} colSpan={1} />
        <Column align={'center'} header={trans(t, 'decoupageExerciceFiscal')} colSpan={2} />
        <Column header={trans(t, 'remuneration')} colSpan={1} />
        <Column header={trans(t, 'remunerationPrevue')} colSpan={1} />
        <Column header={trans(t, 'dividendesBruts')} colSpan={1} />
        <Column header={trans(t, 'dividendesPrevus')} colSpan={1} />
      </Row>
    </ColumnGroup>
  );

  return (
    <div className={'flex flex-column xl:flex-row flex-wrap fadein animation-duration-500'}>
      <div className={'grid p-fluid mt-3 w-full'}>
        <div className={'col-12'}>
          <h4 className={'px-2 pb-3'}>{ucfirst(trans(t, 'revenuJuridique'))}</h4>
          <DataTable value={datatableData} headerColumnGroup={headerGroup} rowGroupMode='rowspan' groupRowsBy='company' sortOrder={1} tableStyle={{ minWidth: '50rem' }} size={'large'} stripedRows>
            <Column field='company.siren' body={companyBodyTemplate} style={{ minWidth: '200px' }} />
            <Column field='exerciceFiscal' body={exerciceFiscalBodyTemplate} style={{ minWidth: '200px' }} />
            <Column field='exerciceFiscalPart' body={exerciceFiscalPartTemplate} style={{ minWidth: '150px' }} align={'center'} />
            <Column field='exerciceFiscalPartDates' body={exerciceFiscalPartDatesTemplate} style={{ minWidth: '200px' }} align={'center'} />
            <Column field='remuneration' body={remunerationBodyTemplate} style={{ maxWidth: '200px', width: '200px' }} />
            <Column field='remunerationPrevue' body={remunerationPrevueBodyTemplate} style={{ maxWidth: '200px', width: '200px' }} />
            <Column field='dividendesBruts' body={dividendesBrutsBodyTemplate} style={{ maxWidth: '200px', width: '200px' }} />
            <Column field='dividendesPrevus' body={dividendesPrevusBodyTemplate} style={{ maxWidth: '200px', width: '200px' }} />
          </DataTable>
          <p className={'mt-5 mb-5'}>{ucfirst(trans(t, 'brutDataDisplayed'))}.</p>
        </div>
      </div>

      <div className={'w-full text-right m-2 mr-3 flex justify-content-end'}>
        <>
          <Button type={'reset'} className={'text-ucfirst p-button-text p-button-danger mr-5'} label={trans(t, 'close')} onClick={() => navigate(cancelUri)} />
          <span className={'p-buttonset'}>
            <Button type={'submit'} className={'text-ucfirst p-button-text'} label={trans(t, 'back')} icon={'pi pi-arrow-left'} onClick={() => navigate(urls?.form + appUri.dos.edit.civilYear.steps.step1)} />
            <Button type={'submit'} className={'text-ucfirst p-button-text'} label={trans(t, 'next')} icon={'pi pi-arrow-right'} iconPos={'right'} onClick={() => navigate(urls?.form + appUri.dos.edit.civilYear.steps.step3)} />
          </span>
        </>
      </div>
    </div>
  );
};
