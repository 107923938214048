import React from 'react';
import { Controller } from 'react-hook-form';
import { classNames } from 'primereact/utils';

export const BaseTextField = (props: any) => {
  const { fieldKey, control, errorMessage, rules, classes, addon, addonPosition, renderInput, description, textAddon } = props;
  // A field key can join multiple possibilities using '||' separator.
  const split = fieldKey.split('||');

  // Default addon position.
  let addonPositionDefault: any = addonPosition;
  if (addon && !addonPosition) {
    addonPositionDefault = 'before';
  }

  // Default field classes.
  let fieldClasses = 'field col-12 md:col-6 lg:col-4';
  if (classes !== null && classes !== undefined) {
    fieldClasses = 'field ' + classes;
  }

  return <>
    <Controller
      name={split[0]}
      control={control}
      rules={rules}
      render={({ field, fieldState }) => (
        <div className={classNames(fieldClasses)}>
          {addon ? (
            <div className={'p-inputgroup'}>
              {addonPositionDefault === 'before' && (
                textAddon ? (
                  <span className={'p-inputgroup-addon border-round-left w-5'}>{addon}</span>
                ) : (
                  <span className={'p-inputgroup-addon border-round-left'}><i className={'pi pi-' + addon}></i></span>
                )
              )}
              {renderInput(field, fieldState, addonPositionDefault)}
              {addonPositionDefault === 'after' && (
                textAddon ? (
                  <span className={'p-inputgroup-addon border-round-right w-5'}>{addon}</span>
                ) : (
                  <span className={'p-inputgroup-addon border-round-right'}><i className={'pi pi-' + addon}></i></span>
                )
              )}
            </div>
          ) : (
            renderInput(field, fieldState)
          )}
          {description && (<span className={'text-sm flex pt-1'}>{description}</span>)}
          {errorMessage(field.name)}
        </div>
      )}
    />
  </>
};
