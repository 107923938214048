import React, { useCallback, useState } from 'react';
import { AxiosResponse } from 'axios';

import { Column } from 'primereact/column';
import { Dialog } from 'primereact/dialog';

import { IRequestParams, isAdmin, isExpertAdmin } from 'services';
import { FormWrapper } from 'forms';
import { iri, isValidUUID, trans, triggerFormReset, triggerFormSubmit, ucfirst } from 'utilities';
import { DatatableWrapper, dateTimeCell, rolesEditor, permissionsEditor } from 'components';

import appUri from 'config/appUri.json';
export const DossierExperts = (props: any) => {
  const { dossier, apim } = props;
  const { t, navigate} = apim.di();

  const [visible, setVisible] = useState(false);

  const [refresher, setRefresher] = useState<number>(Date.now);
  const resourceType = 'userGroups';
  const lazyConfig = {sortField: 'user.lastName', sortOrder: 1};
  let params: any[] = [
    {label: 'order[user.firstName]', value: 'asc'},
    {label: 'role.name[]', value: 'ROLE_EXPERT'},
    {label: 'role.name[]', value: 'ROLE_EXPERT_ADMIN'},
    {label: 'group', value: dossier.group},
    {label: 'expand[]', value: 'user_group:read_role'},
    {label: 'expand[]', value: 'role:read_list'},
    {label: 'expand[]', value: 'user_group:read_user'},
    {label: 'expand[]', value: 'user:read'},
    {label: 'expand[]', value: 'user_group:read_permissions'},
    {label: 'expand[]', value: 'permission:read'}
  ];

  const roleBodyTemplate = (rowData: any) => {
    const role = rowData?.role ?? null;

    return <div className={'flex flex-row align-items-center'}>
      {role !== null ? (
        trans(t, 'system|role.' + rowData?.role?.name.toLowerCase())
      ) : (
        'N/R'
      )}
    </div>
  }

  const permissionsBodyTemplate = (rowData: any) => {
    const permissions = rowData.permissions ?? [];

    return <div className={'flex flex-row align-items-center'}>
      {permissions.length > 0 && (
        <ul className={'list-none p-0 m-0'}>
          {permissions.map((permission: any, index: number) => {
            return <li key={index}>{permission.description}</li>;
          })}
        </ul>
      )}
    </div>
  }
  const lastLoginBodyTemplate = (rowData: any) => dateTimeCell(rowData?.user?.lastLogin);

  const onCancel = () => {
    triggerFormReset('a8-form-ued-reset', () => {
      setVisible(false);
    });
  }

  const onSubmit = () => {
    triggerFormSubmit('a8-form-ued-add', () => {
      // @TODO form validation does not seems to work
      setVisible(false);
    });
  }

  const onAddSubmit = (formData: any) => {
    if (!dossier?.id || !isValidUUID(formData?.role) || !isValidUUID(formData?.user)) return;

    // Check if user already linked to this dossier.
    apim.fetchEntities({
      resourceType,
      params: [
        {label: 'group', value: dossier?.group},
        {label: 'user', value: formData?.user}
      ],
      success: (res: AxiosResponse) => {
        if (res?.data && res.data['hydra:member'] && res.data['hydra:member'].length > 0)
          return apim.displayError(trans(t, 'form|errors.alreadyExists.summary'), trans(t, 'form|errors.alreadyExists.detail'));

        apim.postEntity({
          resourceType,
          data: {
            group: iri('groups', dossier?.group),
            user: iri('users', formData?.user),
            role: iri('roles', formData?.role),
            permissions: formData?.permissions?.length > 0 ? formData.permissions.map((permission: any) => iri('permissions', permission)) : [],
          },
          success: () => setRefresher(Date.now())
        } as IRequestParams).then();
      }
    } as IRequestParams).then();
  };

  const hookRowEdit = (_rows: any[], _row: any) => ({
    formattedRows: _rows,
    patched: {
      userGroups: {
        role: _row?.role?.id ? iri('roles', _row.role.id) : null,
        permissions: _row?.permissions?.length > 0 ? _row?.permissions.map((permission: any) => iri('permissions', permission.id)) : [],
      },
    },
    id: _row?.id
  });

  // Wrap form render into a useCallback to avoid multiple FormWrapper recalls du to form fields updates.
  const renderAddForm = useCallback(() =>
      <FormWrapper classes={'grid p-fluid w-12'} resourceType={resourceType} formKeyPrefix={'add_ued_dialog'} cancelLink multiple
                   resetClass={'a8-form-ued-reset'} submitClass={'a8-form-ued-add'} onSubmit={onAddSubmit} hideReload
                   context={{
                     roles: ['ROLE_EXPERT', 'ROLE_EXPERT_ADMIN'],
                     displayUser: true,
                     displayRole: true,
                     rolesAvailables: ['ROLE_EXPERT', 'ROLE_EXPERT_ADMIN'],
                     enforceRolesAvailables: true,
                     defaultRole: 'ROLE_EXPERT',
                     displayPermissions: true,
                   }}/>
    , [dossier?.id]); // eslint-disable-line react-hooks/exhaustive-deps

  // Wrap DT render into a useCallback to enable refresher feature.
  const renderDataTable = useCallback(() =>
      <DatatableWrapper
        resourceType={resourceType} tableKey={'dossier-experts'} params={params} lazyConfig={lazyConfig} noFilters editFields={['role', 'permissions']}
        title={trans(t,'form|dossier.expertUserTitle')} addTitle={trans(t, 'table|add.dossierLinkExpert')} editMode={isAdmin() || isExpertAdmin()}
        onRowClick={(row: any) => navigate(appUri.usr.exp.page.replace(':id', row?.user?.id || '_'))} onNew={() => setVisible(true)} hookRowEdit={hookRowEdit}>
        <Column field={'user.lastName'} header={trans(t, 'lastName')} sortable style={{ width: '200px' }}/>
        <Column field={'user.firstName'} header={trans(t, 'firstName')} sortable style={{ width: '200px' }}/>
        <Column field={'role.id'} header={trans(t, 'role')} body={roleBodyTemplate} style={{ width: '200px' }}
                editor={(options) => rolesEditor(options, {
                  fieldKey: 'role',
                  label: trans(t, 'role'),
                  rolesAvailables: ['ROLE_EXPERT', 'ROLE_EXPERT_ADMIN'],
                  enforceRolesAvailables: true,
                })}/>
        <Column field={'permissions'} header={trans(t, 'permission', 2)} body={permissionsBodyTemplate}
                editor={(options) => permissionsEditor(options)} />
        <Column field={'user.email'} header={trans(t, 'email')} sortable style={{ width: '200px' }}/>
        <Column field={'user.phone1'} header={trans(t, 'phone')} sortable style={{ width: '200px' }}/>
        <Column field={'user.lastLogin'} header={trans(t, 'lastLogin')} sortable body={lastLoginBodyTemplate} align={'center'} style={{ width: '220px' }}/>
      </DatatableWrapper>
    , [refresher]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      {renderDataTable()}
      <Dialog visible={visible} onHide={() => setVisible(false)} header={trans(t, 'table|add.dossierLinkExpert')}
              footer={
                <div>
                  <button onClick={onCancel} className='p-button-text p-button-danger p-confirm-dialog-reject p-button p-component'>{ucfirst(trans(t, 'cancel'))}</button>
                  <button onClick={onSubmit} className='p-button-primary p-button p-component p-confirm-dialog-accept'>{ucfirst(trans(t, 'validate'))}</button>
                </div>
              }
      >
        {renderAddForm()}
      </Dialog>
    </>
  );
};
