import React, { useCallback, useEffect, useState } from 'react';
import { AxiosResponse } from 'axios';

import { ModuleWrapper, Unauthorized } from 'pages';
import { checkAccessByKey, PageLoader } from 'components';
import { isValidUUID, trans } from 'utilities';
import { IRequestParams } from 'services';

import { PatrimoineVueGenerale } from './PatrimoineVueGenerale';
import { PatrimoineSuccession } from './PatrimoineSuccession';
import { PatrimoineBiens } from './PatrimoineBiens';
import { PatrimoineBien } from './PatrimoineBien';
import { PatrimoineCredit } from './PatrimoineCredit';
import { PatrimoineContrat } from './PatrimoineContrat';

export const PatrimoineRouter = (props: any) => {
  const { appState, apim, id, module, tab, modulesMenu, lastUpdate, subResource, subResourceId } = props;
  const { t } = apim.di();

  const [loading, setLoading] = useState<boolean>(false);

  // Override Page Header (defaults into ModuleWrapper).
  useEffect(() => {
    appState.setPageTitle(trans(t, 'menu|pages.title.dossier.patrimoine', 1, appState.dossier().title));
  }, [id, module, tab]); // eslint-disable-line react-hooks/exhaustive-deps

  // Get patrimoines collection for this dossier.
  useEffect(() => {
    if (isValidUUID(appState.patrimoine())) return;

    setLoading(true);
    apim.fetchEntities({
      resourceType: 'patrimoines',
      params: [{ label: 'dossier', value: appState?.dossier()?.id}],
      setLoading,
      success: (res: AxiosResponse) => {
        if (!res?.data || !res.data['hydra:member']) return;

        const _p: any[] = (res?.data['hydra:member'] ?? []).sort((a: any, b: any) => a.date > b.date ? -1 : 1);
        if (_p.length < 1) return;

        appState.setPatrimoines(_p);
        appState.setPatrimoine(_p[0].id);
      }
    } as IRequestParams).then();
  }, [appState.patrimoine(), lastUpdate]); // eslint-disable-line react-hooks/exhaustive-deps

  const buildTabContent = useCallback(() => {
    if (subResource === 'credit') return (isValidUUID(subResourceId)) ?
      <PatrimoineCredit id={subResourceId} appState={appState} apim={apim} type={subResource}/> :
      <PatrimoineBiens id={id} appState={appState} apim={apim} type={subResource}/>;

    switch (tab) {
      case 'succession':
          return !checkAccessByKey(modulesMenu, 'patrimoine.succession', 'read') ? <Unauthorized asBlock/> : <PatrimoineSuccession id={id} appState={appState} apim={apim} modulesMenu={modulesMenu} lastUpdate={lastUpdate}/>;

      case 'biens':
        if (!checkAccessByKey(modulesMenu, 'patrimoine.biens', 'read')) return <Unauthorized asBlock/>;

        return (isValidUUID(subResourceId)) ?
          <PatrimoineBien id={subResourceId} appState={appState} apim={apim} type={subResource}/> :
          <PatrimoineBiens id={id} appState={appState} apim={apim} type={subResource}/>;

      case 'contrats':
        if (!checkAccessByKey(modulesMenu, 'patrimoine.biens', 'read')) return <Unauthorized asBlock/>;

        return (isValidUUID(subResourceId)) ?
          <PatrimoineContrat id={subResourceId} appState={appState} apim={apim} type={subResource}/> :
          <PatrimoineBiens id={id} appState={appState} apim={apim} type={subResource}/>;

      default:
        return !checkAccessByKey(modulesMenu, 'patrimoine.general', 'read') ? <Unauthorized asBlock/> : <PatrimoineVueGenerale id={id} appState={appState} apim={apim} modulesMenu={modulesMenu} lastUpdate={lastUpdate}/>;
    }
  }, [id, module, tab, subResource, subResourceId]); // eslint-disable-line react-hooks/exhaustive-deps

  if (loading) return <PageLoader/>;

  return <ModuleWrapper appState={appState} apim={apim} modulesMenu={modulesMenu} buildTabContent={buildTabContent} subResource={subResource} subResourceId={subResourceId}/>;
};
