import { trans, ucfirst } from 'utilities';
import { IRequestParams } from 'services';

import appUri from 'config/appUri.json';

export const getCivilYearEditContext = (t: any, appState: any, context: any) => {
  const { id, year, step } = context;
  const dossier: any = appState.dossier();

  let activeIndex: number = -1;
  const urls: any = {
    form: appUri.dos.edit.civilYear.uri.replace(':id', id || '_').replace(':year', year!),
    landing: appUri.dos.page.replace(':id', id || '_')
  };

  const defaultBC = [
    { label: trans(t, 'dossier', 2), to: appUri.dos.list },
    { label: dossier?.title, to: appUri.dos.page.replace(':id', dossier?.id) },
    { label: trans(t, 'editing') + ' | ' + ucfirst(trans(t, 'annualData')), to: appUri.dos.edit.landing.replace(':id', dossier?.id) }
  ];

  switch (step) {
    default:
      activeIndex = 0;
      defaultBC.push({
        label: trans(t, 'menu|wizard.dossier.annualData.step1'),
        to: urls.form
      });
      break;
    case 'revenu-juridique':
      activeIndex = 1;
      defaultBC.push({
        label: trans(t, 'menu|wizard.dossier.annualData.step4'),
        to: urls.form + appUri.dos.edit.civilYear.steps.step4
      });
      break;
    case 'revenu-fiscal':
      activeIndex = 2;
      defaultBC.push({
        label: trans(t, 'menu|wizard.dossier.annualData.step3'),
        to: urls.form + appUri.dos.edit.civilYear.steps.step3
      });
      break;
    case 'revenu-social':
      activeIndex = 3;
      defaultBC.push({
        label: trans(t, 'menu|wizard.dossier.annualData.step2'),
        to: urls.form + appUri.dos.edit.civilYear.steps.step2
      });
      break;
    case 'contrats':
      activeIndex = 4;
      defaultBC.push({
        label: trans(t, 'menu|wizard.dossier.annualData.step5'),
        to: urls.form + appUri.dos.edit.civilYear.steps.step5
      });
      break;
  }

  return { urls, defaultBC, index: activeIndex };
};

export const suspendDossierSocialData = (apim: any, data: any, setLoading: any, callback: any) => {
  const { t } = apim.di();
  if (data?.id === null || data?.id === undefined) {
    return;
  }

  setLoading(true);
  apim.patchEntity({
    resourceType: 'dossierSocialData',
    id: data?.id,
    setLoading: setLoading,
    notifSuccess: {
      summary: trans(t, 'notification|dossierSocialData.suspended.summary'),
      details: trans(t, 'notification|dossierSocialData.suspended.details'),
    },
    data: {
      validated: false,
      suspended: true
    },
    success: () => callback()
  } as IRequestParams).then();
};

export const validateDossierSocialData = (apim: any, data: any, setLoading: any, callback: any) => {
  const { t } = apim.di();
  if (data?.id === null || data?.id === undefined) {
    return;
  }

  setLoading(true);
  apim.patchEntity({
    resourceType: 'dossierSocialData',
    id: data?.id,
    setLoading: setLoading,
    notifSuccess: {
      summary: trans(t, 'notification|dossierSocialData.validated.summary'),
      details: trans(t, 'notification|dossierSocialData.validated.details'),
    },
    data: {
      validated: true,
      suspended: false
    },
    success: () => callback()
  } as IRequestParams).then();
};

export const suspendEconomicData = (apim: any, data: any, setLoading: any, callback: any) => {
  const { t } = apim.di();
  if (data?.id === null || data?.id === undefined) {
    return;
  }

  setLoading(true);
  apim.patchEntity({
    resourceType: 'economicData',
    id: data?.id,
    setLoading: setLoading,
    notifSuccess: {
      summary: trans(t, 'notification|economicData.suspended.summary'),
      details: trans(t, 'notification|economicData.suspended.details'),
    },
    data: {
      validated: false,
      suspended: true
    },
    success: () => callback()
  } as IRequestParams).then();
};

export const validateEconomicData = (apim: any, data: any, setLoading: any, callback: any) => {
  const { t } = apim.di();
  if (data?.id === null || data?.id === undefined) {
    return;
  }

  setLoading(true);
  apim.patchEntity({
    resourceType: 'economicData',
    id: data?.id,
    setLoading: setLoading,
    notifSuccess: {
      summary: trans(t, 'notification|economicData.validated.summary'),
      details: trans(t, 'notification|economicData.validated.details'),
    },
    data: {
      validated: true,
      suspended: false
    },
    success: () => callback()
  } as IRequestParams).then();
};
