import React, { useEffect, useState } from 'react';

import { Column } from 'primereact/column';

import { trans, ucfirst, uuidFromIri } from 'utilities';
import { simpleCheckboxEditor, DatatableWrapper, dateTimeCell, validCell, apiListCell, numberEditor, apiListEditor, maskEditor } from 'components';
import { IRequestParams } from 'services';

export const SettingsExonerationDatatable = (props: any) => {
  const { lazyConfig, apim, tablekey, title, resourceType } = props;
  const { t } = apim.di();
  const [types, setTypes] = useState<any[]>([]);

  useEffect(() => {
    apim.getList('exonerationTypes', { setter: setTypes } as IRequestParams).then();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const lazyC = { ...{ sortField: 'date', sortOrder: -1 }, ...lazyConfig };

  return (
    <DatatableWrapper resourceType={resourceType} tableKey={tablekey} lazyConfig={lazyC} title={title} editMode
                      editFields={['type','duration', 'date', 'active']} noFilters noGlobalFilter>
      <Column field={'type'} header={ucfirst(trans(t, 'type'))} sortable body={(rowData: any) => apiListCell(uuidFromIri(rowData?.type), types)}
              style={{ minWidth: '300px', width: '300px', maxWidth: '300px' }}
              editor={(options) => apiListEditor(options, {label: trans(t, 'type'), listKey: 'exonerationTypes',  optionValue:'@id'})} />
      <Column field={'duration'} header={ucfirst(trans(t, 'ent.duration'))} sortable align={'center'}
              editor={(options) => numberEditor(options, { label: trans(t, 'ent.duration') })}/>
      <Column field={'date'} header={trans(t, 'date')} sortable align={'center'} body={ (rowData: any) => dateTimeCell(rowData?.date, { format: 'DD/MM/YYYY' })}
              style={{ width: '15vw' }} editor={(options) => maskEditor(options, {label: trans(t, 'date'), isDate: true})}/>
      <Column field={'updated'} header={trans(t, 'updated')} sortable align={'center'} body={(rowData: any) => dateTimeCell(rowData?.updated)}
              style={{ width: '15vw' }} />
      <Column field={'created'} header={trans(t, 'created')} sortable align={'center'} body={(rowData: any) => dateTimeCell(rowData?.created)}
              style={{ width: '15vw' }} />
      <Column field={'active'} header={trans(t, 'active')} sortable align={'center'} body={(rowData: any) => validCell(rowData?.active)}
              editor={(options) => simpleCheckboxEditor(options, { label: trans(t, 'table|active') })}
              style={{ width: '5vw' }} />
    </DatatableWrapper>
  );
};
