import React, { useCallback, useEffect } from 'react';
import { trans } from 'utilities';
import { ModuleWrapper, Unauthorized } from 'pages';
import { checkAccessByKey } from 'components';

import { TresorerieGenerale } from './TresorerieGenerale';
import { TresorerieEtEmprunt } from './TresorerieEtEmprunt';
import { TresorerieNegative } from './TresorerieNegative';

export const TresorerieRouter = (props: any) => {
  const { appState, apim, id, module, tab, modulesMenu, lastUpdate } = props;

  const { t } = apim.di();

  // Override Page Header (defaults into ModuleWrapper).
  useEffect(() => {
    appState.setPageTitle(trans(t, 'menu|pages.title.dossier.tresorerie', 1, appState.dossier().title));
  }, [id, module, tab]); // eslint-disable-line react-hooks/exhaustive-deps

  const buildTabContent = useCallback(() => {
    switch (tab) {
      case 'emprunt':
        return !checkAccessByKey(modulesMenu, 'tresorerie.emprunt', 'read') ? <Unauthorized asBlock/> : <TresorerieEtEmprunt id={id} appState={appState} apim={apim} modulesMenu={modulesMenu} lastUpdate={lastUpdate} />;
      case 'negative':
        return !checkAccessByKey(modulesMenu, 'tresorerie.negative', 'read') ? <Unauthorized asBlock/> : <TresorerieNegative id={id} appState={appState} apim={apim} modulesMenu={modulesMenu} lastUpdate={lastUpdate} />;
      default:
        return !checkAccessByKey(modulesMenu, 'tresorerie.generale', 'read') ? <Unauthorized asBlock/> : <TresorerieGenerale id={id} appState={appState} apim={apim} modulesMenu={modulesMenu} lastUpdate={lastUpdate} />;
    }
  }, [id, module, tab]); // eslint-disable-line react-hooks/exhaustive-deps

  return <ModuleWrapper appState={appState} apim={apim} modulesMenu={modulesMenu} buildTabContent={buildTabContent} />;
};
