import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Button } from 'primereact/button';
import { Column } from 'primereact/column';
import { ColumnGroup } from 'primereact/columngroup';
import { Row } from 'primereact/row';
import { Tooltip } from 'primereact/tooltip';

import { dialog, iri, isValidUUID, trans, triggerFormReset, triggerFormSubmit, ucfirst, uuidFromIri } from 'utilities';
import {
  amountCell, percentCell, personCell,
  amountEditor, apiListEditor, percentEditor, staticListEditor,
  DatatableWrapper, flatPPI, onPersonnePhysiqueAddSubmit,
} from 'components';
import { IRequestParam, IRequestParams } from 'services';
import { FormWrapper } from 'forms';

import appUri from 'config/appUri.json';

export const ProprietairesDatatable = (props: any) => {
  const { apim, data, context, title, editMode, switchIndivision } = props;
  const { t } = apim.di();
  const dossier = isValidUUID(context?.dossier?.id) ? context?.dossier?.id : uuidFromIri(context?.dossier);

  const resourceType: string = 'proprietaires';
  const [lastEdit, setLastEdit] = useState<number>(Date.now);
  const [refresher, setRefresher] = useState<number>(Date.now);

  const [rows, setRows] = useState<any[]>([]);
  const [indivisions, setIndivisions] = useState<any[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [types, setTypes] = useState<any[]>([]);

  useEffect(() => {
    apim.getList('proprieteTypes', {setter: setTypes} as IRequestParams).then();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  // Map bien type / resource type between back <==> front.
  const getBienType = useMemo(() => {
    switch ((data ?? {})['@type']) {
      default: return 'bienDivers';
      case 'BienBancaire': return 'bienBancaire';
      case 'BienEntreprise': return 'bienEntreprise';
      case 'BienImmobilier': return 'bienImmobilier';
    }
  }, [data]);
  const getBienResourceType = useMemo(() => {
    switch ((data ?? {})['@type']) {
      default: return 'biensDivers';
      case 'BienBancaire': return 'biensBancaires';
      case 'BienEntreprise': return 'biensEntreprises';
      case 'BienImmobilier': return 'biensImmobiliers';
    }
  }, [data]);

  const params: IRequestParam[] = [{label: getBienType, value: data?.id}];
  useEffect(() => {
    setLoading(true);
    apim.fetchEntities({
      resourceType,
      cache: false,
      params,
      setter: setRows,
      formatter: flatPPI
    } as IRequestParams).then(() => {
      apim.fetchEntities({
        resourceType: 'indivisions',
        cache: false,
        params: [{label: 'byBien', value: data?.id}],
        setLoading,
        setter: setIndivisions
      } as IRequestParams).then();
    });
  }, [data?.id, context?.id, refresher]); // eslint-disable-line react-hooks/exhaustive-deps

  const _indivisionPercentEditor = (options: any) => percentEditor(options, {label: trans(t, 'table|patrimoine_headers.indivisionPercent')});

  const amountBody = (rowData: any) => {
    if (rowData?.type === 'pleine_propriete') return amountCell(data?.montantPleinePropriete) ?? '-';

    return rowData?.montant ? amountCell(rowData?.montant) : '-'
  };

  const amountImmoBody = (rowData: any) => {
    if (rowData?.type === 'pleine_propriete') return amountCell(data?.montantPleineProprieteImmobilier) ?? '-';

    return rowData?.montantImmobilier ? amountCell(rowData?.montantImmobilier) : '-'
  };

  const dividendesBody = (rowData: any) => rowData?.dividendes ? amountCell(rowData?.dividendes) : '-';

  const compteCourantBody = (rowData: any) => rowData?.compteCourant ? amountCell(rowData?.compteCourant) : '-';
  const indivisionBody = (rowData: any) => rowData?.indivision?.label ?? '-';

  // Conditional display for these fields.
  const _amountEditor = useCallback ((options: any) => {
    const config: any = {label: trans(t, 'form|patrimoine.pp.value_short')};

    if (options?.rowData?.type === 'pleine_propriete') {
      options.value = data?.montantPleinePropriete;
      config.disabled = true;
    } else {
      config.label = trans(t, options?.rowData?.type === 'nue_propriete' ? 'form|patrimoine.np.value_short' : 'form|patrimoine.usufruit.value_short');
    }

    return amountEditor(options, config);
  }, [lastEdit]); // eslint-disable-line react-hooks/exhaustive-deps

  const _amountImmoEditor = (options: any) => {
    const config: any = {label: trans(t, 'table|patrimoine_headers.montantImmobilier')};

    if (options?.rowData?.type === 'pleine_propriete') {
      options.value = data?.montantPleineProprieteImmobilier;
      config.disabled = true;
    }

    return amountEditor(options, config);
  };

  const _dividendesEditor = (options: any) => amountEditor(options, {label: trans(t, 'table|patrimoine_headers.dividendes')});
  const _compteCourantEditor = (options: any) => amountEditor(options, {label: trans(t, 'table|patrimoine_headers.compteCourant')});

  const labelBody = (rowData: any) => personCell({...(rowData?.personnePhysique ?? rowData?.indivisionPersonnePhysique), ...{email: null, phone: null}},{
    url: appUri.cie.per.phy.page.replace(':id', rowData?.personnePhysique?.id ?? rowData?.indivisionPersonnePhysique?.id),
    label: trans(t, 'seeDetails')
  });

  const typeBody = (rowData: any) => {
    const match: any = (types || []).filter((m: any) => rowData?.type?.toLowerCase() === m?.value);

    return match.length > 0 ? match[0].translated : rowData?.type;
  };

  const addData: any = {};
  addData[getBienType] = iri(getBienResourceType, data?.id);

  const onNew = () => {
    dialog(t, {
      header: trans(t,'table|add.proprietaire'),
      icon: 'none',
      message: renderAddForm(),
      accept: () => triggerFormSubmit('a8-form-bien-proprietaire-add'),
      reject: () => triggerFormReset('a8-form-bien-proprietaire-reset')
    });
  };

  // Handle submission then refresh table rows.
  const onAddSubmit = useCallback(
    (formData: any) => onPersonnePhysiqueAddSubmit(apim, context, formData, rows, setRows, resourceType, setLoading, (_pp: any) => {
      if (!isValidUUID(_pp?.id)) return setLoading(false);

      // Avoid duplicates.
      if (rows.filter((_r: any) => _r?.personnePhysique?.id === _pp?.id).length > 0) {
        setLoading(false);

        return apim.displayError(trans(t, 'form|errors.alreadyExists.summary'), trans(t, 'form|errors.alreadyExists.detail'));
      }

      // Create the new row then refresh rows.
      apim.postEntity({
        resourceType,
        notif: false,
        data: {
          ...addData,
          ...{
            personnePhysique: iri('personnesPhysiques', _pp?.id),
            // Try to get last row type as model.
            type: rows.length > 0 ? (rows[rows.length - 1].type ?? 'pleine_propriete') : 'pleine_propriete'
          }
        },
        setLoading,
        success: () => setRefresher(Date.now)
      } as IRequestParams).then();
    })
    , [context?.id, data?.id, rows]); // eslint-disable-line react-hooks/exhaustive-deps

  const onRowEditCallback = (_patched: any) => {
    const _proprietaire: any = _patched?.proprietaires;

    // Find the selected indivision then send updated data.
    const onIndivisionInclude = (_proprietaire: any) => {
      const _indivision: any = indivisions.filter((_i: any) => isValidUUID(_i?.id) && (_i.id === _proprietaire?.indivisionId));
      if (_indivision.length < 1 || !isValidUUID(_indivision[0].id)) return setRefresher(Date.now);

      const _data: any = {
        indivisionMembers: (_indivision[0].indivisionMembers ?? [])
          .filter((_im: any) => _im?.personnePhysique?.id !== (_proprietaire?.personnePhysique?.id ?? _proprietaire?.indivisionPersonnePhysique?.id))
          .map((_im: any) => ({
            personnePhysique: iri('personnesPhysiques', _im?.personnePhysique?.id),
            percentage: _im?.percentage ?? 0
          }))
      };

      _data.indivisionMembers.push({
        personnePhysique: iri('personnesPhysiques', _proprietaire?.personnePhysique?.id ?? _proprietaire?.indivisionPersonnePhysique?.id),
        percentage: _proprietaire?.indivisionPercent ?? 0
      });

      setLoading(true);
      apim.patchEntity({
        resourceType: 'indivisions',
        id: _indivision[0].id,
        notif: false,
        data: _data,
        success: () => {
          // - this person was previously linked directly as a Proprietaire
          if (isValidUUID(_proprietaire?.personnePhysique?.id)) {
            apim.deleteEntity({
              resourceType,
              id: _proprietaire.id,
              notif: false,
              setLoading,
              success: () => setRefresher(Date.now)
            } as IRequestParams).then();
          } else {
            // - this person was previously linked into another Indivision
            onDelete(_proprietaire);
          }
        }
      } as IRequestParams).then();
    };

    // Reverse logic : Find any indivision where this person was then send related removal.
    const onIndivisionExclude = (_proprietaire: any) => {
      // Simplest case first : this PP exists as a dedicated Proprietaire, and we didn't change Indivision : just skip.
      const _row: any = rows.filter((_i: any) => isValidUUID(_i?.id) && (_i?.personnePhysique?.id === _proprietaire?.personnePhysique?.id));
      if (_row.length === 1/* && !isValidUUID(_proprietaire?.indivisionId) <-- already checked */) return setRefresher(Date.now);

      // Else, remove any Indivision / Row related and update IndivisionMembers.
      const _indivision: any = indivisions.filter((_i: any) => isValidUUID(_i?.id) && (_i.id === _proprietaire?.indivision?.id));
      if (_indivision.length < 1 || !isValidUUID(_indivision[0].id)) return setRefresher(Date.now);

      const _data: any = {
        indivisionMembers: (_indivision[0].indivisionMembers ?? [])
          .filter((_im: any) => _im?.personnePhysique?.id !== (_proprietaire?.personnePhysique?.id ?? _proprietaire?.indivisionPersonnePhysique?.id))
          .map((_im: any) => ({
            personnePhysique: iri('personnesPhysiques', _im?.personnePhysique?.id),
            percentage: _im?.percentage ?? 0
          }))
      };

      setLoading(true);
      apim.patchEntity({
        resourceType: 'indivisions',
        id: _indivision[0].id,
        notif: false,
        data: _data,
        success: () => {
          // Finally add a new row (as a new Proprietaire)
          apim.postEntity({
            resourceType,
            notif: false,
            data: {
              ...addData,
              ...{
                personnePhysique: iri('personnesPhysiques', (_proprietaire?.personnePhysique?.id ?? _proprietaire?.indivisionPersonnePhysique?.id)),
                // Try to get last row type as model.
                type: rows.length > 0 ? (rows[rows.length - 1].type ?? 'pleine_propriete') : 'pleine_propriete'
              }
            },
            setLoading,
            success: () => setRefresher(Date.now)
          } as IRequestParams).then();
        }
      } as IRequestParams).then();
    };

    if (isValidUUID(_proprietaire?.indivisionId)) {
      return onIndivisionInclude(_proprietaire);
    } else {
      return onIndivisionExclude(_proprietaire);
    }
  };

  const onDelete = (_deleted: any) => {
    if (_deleted?.indivision?.indivisionMembers?.length > 0) {
      // Remove this person from the indivision members.
      const _data: any = {
        indivisionMembers: (_deleted.indivision.indivisionMembers)
          .filter((_im: any) => _im.personnePhysique?.id !== _deleted?.indivisionPersonnePhysique?.id)
          .map((_im: any) => ({
            personnePhysique: iri('personnesPhysiques', _im?.personnePhysique?.id),
            percentage: _im?.percentage ?? 0
          }))
      };
      apim.patchEntity({
        resourceType: 'indivisions',
        id: _deleted?.indivision?.id,
        notif: false,
        data: _data,
        setLoading,
        success: () => setRefresher(Date.now)
      } as IRequestParams).then();
    } else {
      // "Normal delete"
      apim.deleteEntity({
        resourceType,
        id: _deleted.id,
        notif: false,
        setLoading,
        success: () => setRefresher(Date.now)
      } as IRequestParams).then();
    }
  };

  const switchBtnTemplate = () => (
    <>
      <Button type={'button'} className={'a8-proprietaires-indivisions-btn'} onClick={switchIndivision} icon={'pi pi-chart-pie'} aria-label={trans(t, 'configureIndivisions')}/>
      <Tooltip target={'.a8-proprietaires-indivisions-btn'} position={'left'} content={trans(t, 'configureIndivisions')}/>
    </>
  );

  const headerGroup = (
    <ColumnGroup>
      <Row>
        <Column header={ucfirst(trans(t, 'table|patrimoine_headers.personnePhysique'))}/>

        {getBienResourceType === 'biensEntreprises' && (
          <Column header={ucfirst(trans(t, 'table|patrimoine_headers.compteCourant'))} alignHeader={'center'}/>
        )}
        {getBienResourceType === 'biensEntreprises' && (
          <Column header={ucfirst(trans(t, 'table|patrimoine_headers.dividendes'))} alignHeader={'center'}/>
        )}

        {indivisions.length > 0 && (
          <Column header={ucfirst(trans(t, 'table|patrimoine_headers.indivision'))} colSpan={2} alignHeader={'center'}/>
        )}

        <Column header={ucfirst(trans(t, 'table|patrimoine_headers.type'))} alignHeader={'center'}/>
        <Column header={ucfirst(trans(t, 'table|patrimoine_headers.valeur'))}  alignHeader={'center'}/>

        {(data?.type === 'participation' || data?.type === 'valeurs_mobilieres') && (
          <Column header={ucfirst(trans(t, 'table|patrimoine_headers.montantImmobilier'))} alignHeader={'center'}/>
        )}
      </Row>
    </ColumnGroup>
  );

  // Wrap form render into a useCallback to avoid multiple FormWrapper recalls du to form fields updates.
  const renderAddForm = useCallback(() =>
      <FormWrapper classes={'grid p-fluid w-12'} resourceType={'personnesPhysiques'} formKeyPrefix={'add_pp_dialog'} cancelLink multiple context={{ patrimoine: context, dossierId: dossier }}
                   resetClass={'a8-form-bien-proprietaire-reset'} submitClass={'a8-form-bien-proprietaire-add'} onSubmit={onAddSubmit} hideReload/>
    , [context?.id, data?.id, rows]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <DatatableWrapper resourceType={resourceType} tableKey={'proprietaires'} title={title ?? trans(t, 'proprietaire', 2)} onRowEditCallback={onRowEditCallback}
                      headerColumnGroup={headerGroup} noFilters noGlobalFilter paginator={false} params={params} isLoading={loading} editMode={editMode} onDelete={onDelete}
                      onNew={onNew} addTitle={trans(t, 'table|add.proprietaire')} onRefresh={setRows} headerCreateBtn={switchIndivision ? switchBtnTemplate : null}
                      rows={rows} editFields={['type', 'montant', 'montantImmobilier', 'dividendes', 'compteCourant']} autoEditOnNewRow={false} additionalData={addData}>
      <Column field={'personnePhysique'} body={labelBody}/>
      {indivisions.length > 0 && (
        <Column field={'indivisionId'} header={ucfirst(trans(t, 'table|patrimoine_headers.indivision'))} alignHeader={'center'}
                style={{ minWidth: '150px', width: '150px' }} body={indivisionBody} align={'center'} editor={(options) => staticListEditor(options, {
                  label: trans(t, 'indivision'),
                  placeholder: ucfirst(trans(t, 'form|select')),
                  resourceType: 'indivisions',
                  listsOptions: {indivisions},
                  listKey: 'indivisions',
                  optionLabel: 'label',
                  optionValue: 'id',
                  filter: false,
                  showClear: true,
                  setLastEdit
        })}/>
      )}
      {indivisions.length > 0 && (
        <Column field={'indivisionPercent'} style={{ minWidth: '150px', width: '150px' }} align={'center'}
                body={(rowData: any) => rowData?.indivisionPercent ? percentCell(rowData?.percentage ?? rowData?.indivisionPercent ?? 0) : '-'} editor={_indivisionPercentEditor}/>
      )}
      {getBienResourceType === 'biensEntreprises' && (
        <Column field={'compteCourant'} style={{ minWidth: '150px', width: '150px' }} body={compteCourantBody} align={'center'} editor={_compteCourantEditor}/>
      )}
      {getBienResourceType === 'biensEntreprises' && (
          <Column field={'dividendes'} style={{ minWidth: '150px', width: '150px' }} body={dividendesBody} align={'center'} editor={_dividendesEditor}/>
      )}
      <Column field={'type'} style={{ minWidth: '150px', width: '150px' }} body={typeBody} align={'center'}
              editor={(options) => apiListEditor(options, {
                label: trans(t, 'table|patrimoine_headers.type'),
                listKey: 'proprieteTypes',
                setLastEdit})}/>
      <Column field={'montant'} style={{ minWidth: '150px', width: '150px' }} body={amountBody} align={'center'} editor={_amountEditor}/>
      {(data?.type === 'participation' || data?.type === 'valeurs_mobilieres') && (
        <Column field={'montantImmobilier'} style={{ minWidth: '150px', width: '150px' }} body={amountImmoBody} align={'center'} editor={_amountImmoEditor}/>
      )}
    </DatatableWrapper>
  );
};
