import React from 'react';
import { classNames } from 'primereact/utils';
import { AppTopbarLeft } from './Left';
import { AppTopbarRight } from './Right';

export const AppTopbar = (props: any) => {
  const { appState, apim, userState, searchState, themeState } = props;
  const { navigate } = apim.di();

  return (
    <div className={'layout-topbar shadow-4'}>
      <div className={'layout-topbar-left'}>
        <button type={'button'} style={{ cursor: 'pointer' }} className={'layout-topbar-logo p-link'} onClick={() => navigate('/')}>
          <img id={'app-logo'} src={'/assets/images/logos/logo.svg'} alt={'Atome 8 logo clair'} style={{ height: '16px' }} />
        </button>
        <button type={'button'} className={'layout-menu-button shadow-6 p-link'} onClick={()  => navigate(-1)}>
          <i className={'pi pi-chevron-right'}></i>
        </button>
      </div>

      <div
        className={classNames('layout-topbar-right', {
          'layout-topbar-mobile-active': themeState.isMobileTopbarActive()
        })}
      >
        <div className={'layout-topbar-actions-left'}>
          <AppTopbarLeft appState={appState} apim={apim} userState={userState} />
        </div>
        <div className={'layout-topbar-actions-right'}>
          <AppTopbarRight apim={apim} searchState={searchState} userState={userState} appState={appState} themeState={themeState} />
        </div>
      </div>
    </div>
  );
};
