import React from 'react';
import { Fieldset } from 'primereact/fieldset';

import { APIListField, CheckboxField, MaskField, NumberField, StaticListAutocompleteField, TextField } from 'forms';
import { trans, ucfirst } from 'utilities';

export const CreditForm = (props: any) => {
  const { t, control, onFieldChange, errorMessage, listsOptions } = props;

  return <>
    <Fieldset legend={ucfirst(trans(t, 'general'))} className={'col-12 m-2'} toggleable>
      <div className={'grid w-full'}>
        <div className={'col-3'}>
          <TextField fieldKey={'label'} control={control} onFieldChange={onFieldChange} errorMessage={errorMessage}
                     label={trans(t, 'form|patrimoine.credits.default.label')} classes={'w-full'}/>
        </div>
        <div className={'col-3'}>
          <APIListField listKey={'creditTypes'} fieldKey={'type'} control={control} onFieldChange={onFieldChange} errorMessage={errorMessage}
                        label={trans(t, 'form|patrimoine.credits.default.type')} classes={'w-full'} disabled/>
        </div>
        <div className={'col-3'}>
          <StaticListAutocompleteField classes={'w-full'} listKey={'banks'} listsOptions={listsOptions} fieldKey={'bank'} control={control} optionLabel={'name'} optionValue={'id'}
                           onFieldChange={onFieldChange} errorMessage={errorMessage} label={trans(t, 'form|patrimoine.credits.default.bank')}
                           rules={{required: trans(t, 'form|requiredField')}}/>
        </div>
        <div className={'col-3'}>
          <MaskField fieldKey={'openingDate'} label={trans(t, 'form|patrimoine.credits.default.opening')}
                     control={control} onFieldChange={onFieldChange} errorMessage={errorMessage} isDate classes={'w-full'}/>
        </div>
      </div>
      <div className={'grid w-full'}>
        <div className={'col-3'}>
          <NumberField fieldKey={'montantEmprunte'} control={control} onFieldChange={onFieldChange} errorMessage={errorMessage}
                       label={trans(t, 'form|patrimoine.credits.default.montant')} addon={'euro'} addonPosition={'after'} classes={'w-full'}/>
        </div>
        <div className={'col-3'}>
          <NumberField fieldKey={'taux'} mode={'decimal'} min={0} max={100} suffix={'%'} addon={'percentage'} addonPosition={'after'} classes={'w-full'}
                       control={control} onFieldChange={onFieldChange} errorMessage={errorMessage} label={trans(t, 'form|patrimoine.credits.default.taux')}/>
        </div>
        <div className={'col-3'}>
          <NumberField fieldKey={'duree'} mode={'decimal'} control={control} onFieldChange={onFieldChange} errorMessage={errorMessage} showButtons min={0}
                       label={trans(t, 'form|patrimoine.credits.default.duree')} addon={'calendar'} classes={'w-full'}/>
        </div>
      </div>
      <div className={'grid w-full'}>
        <div className={'col-3'}>
          <NumberField fieldKey={'montantMensualites'} control={control} onFieldChange={onFieldChange} errorMessage={errorMessage} description={trans(t, 'form|patrimoine.credits.default.montantMensualitesDesc')}
                       label={trans(t, 'form|patrimoine.credits.default.montantMensualites')} addon={'euro'} addonPosition={'after'} classes={'w-full'}/>
        </div>
        <div className={'col-3'}>
          <NumberField fieldKey={'tauxAssurance'} mode={'decimal'} min={0} max={100} suffix={'%'} addon={'percentage'} addonPosition={'after'} classes={'w-full'}
                       control={control} onFieldChange={onFieldChange} errorMessage={errorMessage} label={trans(t, 'form|patrimoine.credits.default.tauxAssurance')}/>
        </div>
        <div className={'col-3'}>
          <NumberField fieldKey={'montantRestantDu'} control={control} onFieldChange={onFieldChange} errorMessage={errorMessage}
                       label={trans(t, 'form|patrimoine.credits.default.montantRestantDu')} addon={'euro'} addonPosition={'after'} classes={'w-full'}/>
        </div>
      </div>
    </Fieldset>

    <Fieldset legend={ucfirst(trans(t, 'insurance'))} className={'col-12 m-2'} toggleable>
      <div className={'grid w-full'}>
        <div className={'col-3'}>
          <CheckboxField fieldKey={'eligibleLoiLemoine'} control={control} onFieldChange={onFieldChange} errorMessage={errorMessage} classes={'w-full'}
                         label={trans(t, 'form|patrimoine.credits.default.eligibleLoiLemoine')}/>
        </div>
        <div className={'col-3'}>
          <CheckboxField fieldKey={'delegationAssurance'} control={control} onFieldChange={onFieldChange} errorMessage={errorMessage} classes={'w-full'}
                         label={trans(t, 'form|patrimoine.credits.default.delegationAssurance')}/>
        </div>
        <div className={'col-3'}>
          <CheckboxField fieldKey={'assuranceGarantieDeces'} control={control} onFieldChange={onFieldChange} errorMessage={errorMessage} classes={'w-full'}
                         label={trans(t, 'form|patrimoine.credits.default.assuranceGarantieDeces')}/>
        </div>
        <div className={'col-3'}>
          <CheckboxField fieldKey={'assuranceGarantiePerteEmploi'} control={control} onFieldChange={onFieldChange} errorMessage={errorMessage} classes={'w-full'}
                         label={trans(t, 'form|patrimoine.credits.default.assuranceGarantiePerteEmploi')}/>
        </div>
        <div className={'col-3'}>
          <CheckboxField fieldKey={'assuranceGarantieITP'} control={control} onFieldChange={onFieldChange} errorMessage={errorMessage} classes={'w-full'}
                         label={trans(t, 'form|patrimoine.credits.default.assuranceGarantieITP')}/>
        </div>
        <div className={'col-3'}>
          <CheckboxField fieldKey={'assuranceGarantieITT'} control={control} onFieldChange={onFieldChange} errorMessage={errorMessage} classes={'w-full'}
                         label={trans(t, 'form|patrimoine.credits.default.assuranceGarantieITT')}/>
        </div>
        <div className={'col-3'}>
          <CheckboxField fieldKey={'assuranceGarantieIPT'} control={control} onFieldChange={onFieldChange} errorMessage={errorMessage} classes={'w-full'}
                         label={trans(t, 'form|patrimoine.credits.default.assuranceGarantieIPT')}/>
        </div>
        <div className={'col-3'}>
          <CheckboxField fieldKey={'assuranceGarantieIPP'} control={control} onFieldChange={onFieldChange} errorMessage={errorMessage} classes={'w-full'}
                         label={trans(t, 'form|patrimoine.credits.default.assuranceGarantieIPP')}/>
        </div>
      </div>
    </Fieldset>
  </>;
};
