import React from 'react';
import { Fieldset } from 'primereact/fieldset';
import { Message } from 'primereact/message';

import { asDate, dateString, flatPerson, trans, ucfirst } from 'utilities';
import { AddressField, ContactField, IndicatorSimple, SimpleText } from 'components';

export const Dirigeant = (props: any) => {
  const { apim, data } = props;
  const { t } = apim.di();
  const identity = [{ key: 'firstName' }, { key: 'lastName' }];
  const contact = [{ key: 'phone1' }, { key: 'phone2' }, { key: 'email' }];
  const dates = [{ key: 'created' }, { key: 'updated' }];

  // Data is split like data.user & data.personnePhysique
  const user: any = data?.user;
  // Let's flat all fields to root level to simplify nested.
  const person = flatPerson(data?.personnePhysique);
  const deceased = person?.deceased ?? false;

  // Find marital status.
  const maritalStatuses = (person?.spouses || []).filter((spouse: any) => spouse.currentSpouse).map((spouse: any) => spouse.maritalStatus);
  const maritalStatus = maritalStatuses.length > 0 ? maritalStatuses[0] : null;

  return <>
    {deceased && (
      <div className={'flex mx-3'}>
        <Message severity={'error'} className={'flex p-2 rounded-md w-full'} icon={'none'} text={ucfirst(trans(t, 'personnePhysiqueDeceased'))}/>
      </div>
    )}
    <div className={'flex flex-wrap flex-row'}>
      <div className={'flex py-4 px-3 flex-grow-1'}>
        <IndicatorSimple color={'accent4'} bgImage title={trans(t, 'civility')} value={person?.civility} method={'list'}
                         methodParams={{ listKey: 'civilities' }}/>
      </div>
      {identity.map((identity, index) => (
        <div key={index} className={'flex py-4 px-3 flex-grow-1'}>
          <IndicatorSimple color={'accent4'} bgImage title={trans(t, identity.key)}
                           text={person ? person[identity.key] : ''}/>
        </div>
      ))}
    </div>

    <Fieldset legend={ucfirst(trans(t, 'civilState'))}>
      <div className={'flex flex-column md:flex-row px-3'}>
        <div className={'flex flex-grow-1 py-4 px-3'}>
          <SimpleText title={trans(t, 'sex')} value={person?.sex} method={'list'} methodParams={{listKey: 'sexes'}}/>
        </div>
        <div className={'flex flex-grow-1 py-4 px-3'}>
          <SimpleText title={trans(t, 'birthDate')} text={dateString(asDate(person?.birthDate), 'DD/MM/YYYY')}/>
        </div>
        <div className={'flex flex-grow-1 py-4 px-3'}>
          <SimpleText title={trans(t, 'birthAddress')}
                      text={(person?.birthAddress?.postalCode || '') + (person?.birthAddress?.city ? ` ${person?.birthAddress.city}` : '') + (person?.birthAddress?.country ? `- ${person?.birthAddress.country}` : '')}/>
        </div>
        <div className={'flex flex-grow-1 py-4 px-3'}>
          <SimpleText title={trans(t, 'birthName')} value={person?.birthName} method={'asIt'}/>
        </div>
        <div className={'flex flex-grow-1 py-4 px-3'}>
          <SimpleText title={trans(t, 'maritalStatus')} value={maritalStatus} method={'list'} methodParams={{listKey: 'maritalStatuses'}}/>
        </div>
        <div className={'flex flex-grow-1 py-4 px-3'}>
          <SimpleText title={trans(t, 'function')} text={person?.function}/>
        </div>
        {person?.deceased && (
          person?.deceasedDate && (
            <div className={'flex flex-grow-1 py-4 px-3'}>
              <SimpleText title={trans(t, 'deceasedDate')} text={dateString(asDate(person?.deceasedDate), 'DD/MM/YYYY')}/>
            </div>
          )
        )}
      </div>
    </Fieldset>

    <Fieldset className={'my-5'} legend={ucfirst(trans(t, 'socialSecurity'))}>
      <div className={'flex flex-column md:flex-row px-3'}>
        {person?.noSocialSecurityNumber ? (
          <div className={'flex flex-grow-1 py-4 px-3'}>
            <SimpleText title={trans(t, 'noSocialSecurityNumber')} value={!person?.noSocialSecurityNumber} method={'bool'}/>
          </div>
        ) : (
          <div className={'flex flex-grow-1 py-4 px-3'}>
            <SimpleText title={trans(t, 'socialSecurityNumber')} text={person?.socialSecurityNumber} copyButton={true}/>
          </div>
        )}
      </div>
    </Fieldset>

    <Fieldset className={'my-5'} legend={ucfirst(trans(t, 'specificities'))}>
      <div className={'flex flex-column md:flex-row px-3'}>
      <div className={'flex flex-grow-1 py-4 px-3'}>
          <SimpleText title={trans(t, 'veteran')} value={person?.veteran} method={'bool'}/>
        </div>
        <div className={'flex flex-grow-1 py-4 px-3'}>
          <SimpleText title={trans(t, 'singleParent')} value={person?.singleParent} method={'bool'}/>
        </div>
      </div>
    </Fieldset>

    <Fieldset className={'my-5'} legend={ucfirst(trans(t, 'contact'))}>
      <div className={'flex flex-column md:flex-row px-3'}>
        {person?.address1 && (
          <div className={'flex flex-grow-1 py-4 px-3'}>
            <AddressField address={person}/>
          </div>
        )}

        {contact.map((contact, index) => (
          <div key={index} className={'flex flex-grow-1 py-4 px-3'}>
            <ContactField title={trans(t, contact.key)} text={person ? person[contact.key] : ''}/>
          </div>
        ))}

        <div className={'flex px-3 py-4'}>
          <SimpleText title={trans(t, 'em.verified')} value={data?.emailVerified} method={'bool'}/>
        </div>
      </div>
    </Fieldset>

    <Fieldset className={'my-5'} legend={ucfirst(trans(t,'userInfo'))}>
      <div className={'flex flex-column md:flex-row px-3'}>
        <div className={'flex flex-grow-1 py-4 px-3'}>
          <SimpleText title={trans(t, 'userName')} text={user?.username}/>
        </div>
        <div className={'flex flex-grow-1 py-4 px-3'}>
          <SimpleText title={trans(t, 'active')} value={person?.active} method={'bool'}/>
        </div>
        <div className={'flex flex-grow-1 py-4 px-3'}>
          <SimpleText title={trans(t, 'lastLogin')} value={user?.lastLogin} method={'datetime'}
                      emptyValue={ucfirst(trans(t, 'never'))}/>
        </div>
      </div>
    </Fieldset>

    <div className={'flex flex-row justify-content-end'}>
      {dates.map((dates, index) => (
        data &&
        <div key={index} className={'flex px-3 py-5'}>
          <SimpleText title={trans(t, dates.key + '.at')} value={user[dates.key]} method={'datetime'}/>
        </div>
      ))}
    </div>
  </>;
};
