import { trans } from 'utilities';
import { TFunction } from 'i18next';
import { AxiosError, AxiosResponse } from 'axios';
import { IRequestParams } from 'services';
import { isEmpty } from 'lodash';

export const getSuccessMessage = (t: TFunction, success: AxiosResponse, p: IRequestParams) => {
  let summary: string = trans(t, 'system|success.summary.default');
  let details: string = trans(t, 'system|success.details.default');

  switch (p.method) {
    case 'delete':
      summary = trans(t, 'system|success.summary.deleted');
      details =  trans(t, 'system|success.details.deleted');
      break

    case 'post':
    case 'put':
      summary = trans(t, 'system|success.summary.created');
      details =  trans(t, 'system|success.details.created');
      break

    case 'patch':
      summary = trans(t, 'system|success.summary.edited');
      details =  trans(t, 'system|success.details.edited');
      break;
  }

  return { s: summary, d: details };
};

export const onApiSuccess = (t: TFunction, success: AxiosResponse, toast: any, p: IRequestParams): void => {
  const { notifSuccess } = p;
  const { summary, details, life } = notifSuccess;
  const { s, d } = getSuccessMessage(t, success, p);
  if (toast && toast.current) {
    toast.current.show({
      severity: 'success',
      summary: summary || s,
      detail: details || d,
      life: life || 4000
    });
  }
};

export const getErrorMessage = (t: TFunction, error: any = null) => {
  const code = error?.response?.status;
  const codeFirstDigit = code !== undefined ? code[0] : 0;

  let summary: string = trans(t, 'system|error.messages.default');
  let details: string = trans(t, 'system|error.pages.default.intro');

  switch (code) {
    case 404:
    case 410:
      summary = trans(t, 'system|error.summary.notFound');
      details =  trans(t, 'system|error.details.notFound');
      break;

    case 401:
    case 402:
    case 403:
    case 407:
    case 511:
      summary = trans(t, 'system|error.summary.unauthorized');
      details =  trans(t, 'system|error.details.unauthorized');
      break;

    case 400:
    case 405:
    case 406:
    case 409:
    case 411:
    case 412:
    case 413:
    case 414:
    case 415:
    case 416:
    case 417:
    case 421:
    case 422:
    case 423:
    case 424:
    case 425:
    case 426:
    case 428:
    case 429:
    case 431:
    case 451:
      summary = trans(t, 'system|error.summary.bad');
      details =  trans(t, 'system|error.details.bad');
      break

    default:
      if (codeFirstDigit === 5) {
        summary = trans(t, 'system|error.summary.server');
        details =  trans(t, 'system|error.details.server');
      }
      break;
  }

  // Do not expose sensitive information (like SQL query fail) if !dev mode
  if ((('1' === process.env.REACT_APP_DEBUG_MODE || '0') || codeFirstDigit === 4) &&
    error.response && error.response.data && error.response.data['hydra:description'] && error.response.data['hydra:title']) {
      summary = error.response.data['hydra:title'];
      details = error.response.data['hydra:description'];
  }

  return { s: summary, d: details };
};

export const onApiError = (t: TFunction, error: AxiosError, toast: any, notifError: any): void => {
  const { summary, details, life } = notifError;
  const { s, d } = getErrorMessage(t, error);
  const code = error?.response?.status;

  if (toast && toast.current) {
    toast.current.show({
      severity: 'error',
      summary: trans(t, 'system|errorCode') + ' [' + code + '] ' + (!isEmpty(summary) ? summary : s),
      detail: !isEmpty(details) ? details : d,
      life: life || 4000
    });
  }
};
