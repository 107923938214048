import React, { useCallback, useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { TFunction } from 'i18next';

import { TabMenu } from 'primereact/tabmenu';

import { isValidUUID, trans, ucfirst } from 'utilities';
import { Error, NotFound } from 'pages';
import { PageLoader } from 'components';
import { IRequestParams, userId } from 'services';
import { useAppState } from 'states';
import { UserProfile } from './tabs';

import { includes, forEach, replace, isEmpty } from 'lodash';
import appUri from 'config/appUri.json';

export const UserFormRouter = (props: any) => {
  const { tab, apim  } = props;
  const appState = useAppState()
  const { t, navigate } = apim.di();
  const id = userId();

  const [loading, setLoading] = useState<boolean>(false);
  const [data, setData] = useState<any>(null);
  const [notFound, setNotFound] = useState<boolean>(false);
  const [errored, setErrored] = useState<boolean>(false);

  const [activeIndex, setActiveIndex] = useState<number>(0);
  const baseUri = replace(appUri.usr.edit, ':id', id || '_');
  const tabs: any[] = [];
  if (id) {
    tabs.push({label: trans(t, 'menu|tab.general'), command: () => navigate(baseUri)});

    forEach(appUri.usr.tabs, (ta) => {
      tabs.push({label: trans(t, 'menu|tab.' + ta), command: () => navigate(baseUri + '/' + ta)});
    });
  }

  // Validate URL & build tabs.
  const checks = useCallback(() => {
    return isUrlValid(t, id, tab);
  }, [id, tab]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (!checks()) setNotFound(true);

    let ai = 0;
    forEach(appUri.usr.tabs, (ta, index) => {
      if (tab === ta) ai = index + 1;
    });

    if (ai !== activeIndex) setActiveIndex(ai);
  }, [id, tab]); // eslint-disable-line react-hooks/exhaustive-deps

  const buildTabContent = useCallback(() => {
    switch (activeIndex) {
      default:
        // Profile.
        return data && <UserProfile data={data} apim={apim} />;
    }
  }, [id, tab, activeIndex, data]); // eslint-disable-line react-hooks/exhaustive-deps

  // Let's detect if API calls from now have to be refreshed (cache expiration).
  const [searchParams] = useSearchParams();
  const lastUpdate = searchParams.get('maj');
  useEffect(() => {
    setLoading(true);
    apim.fetchEntity({
      resourceType: 'users',
      id: id,
      cacheMode: isEmpty(lastUpdate) ? 'default' : 'replace',
      setter: setData,
      setErrored: setErrored,
      setLoading: setLoading,
      setNotFound: setNotFound
    } as IRequestParams).then();
  }, [id, lastUpdate]); // eslint-disable-line react-hooks/exhaustive-deps

  // Update Page Header.
  useEffect(() => {
    appState.setPageTitle((data ? ucfirst(trans(t, 'profile')) + ' ' + (data.firstName + ' ' + data.lastName) : '') + ' | ' + trans(t, 'editing'));
    appState.setPageActions([]);
    appState.setBreadcrumb([
      { label: ((data ? data.firstName + ' ' + data.lastName : trans(t, 'user'))), to: replace(appUri.usr.page, ':id', data?.id || '_') + '?maj=' + Date.now() }
    ]);

  }, [data, lastUpdate]); // eslint-disable-line react-hooks/exhaustive-deps

  if (notFound) return <NotFound asBlock/>;
  if (errored) return <Error asBlock/>;
  if (loading) return <PageLoader/>;

  return (
    <div className={'card height-100 fadein animation-duration-500 flex flex-column'}>
      <div className={'a8-page-header flex flex-wrap'}>
        <div className={'flex-auto flex p-2 justify-content-end'}>
          {tabs.length > 1 && <TabMenu model={tabs} activeIndex={activeIndex} onTabChange={(e) => setActiveIndex(e.index)}/>}
        </div>
      </div>
      {buildTabContent()}
    </div>
  );
};

const isUrlValid = (t: TFunction, id: string | undefined, tab: string | undefined) => {
  // Ensure entity UUID.
  return isValidUUID(id) && includes([...[undefined], ...appUri.usr.exp.tabs], tab);
};
