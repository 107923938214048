import CryptoJS from 'crypto-js';
import { userId } from 'services';

const debugMode = process.env.REACT_APP_DEBUG_MODE || '0';
const secretKey = process.env.REACT_APP_APIM_KEY || 'my super secret key !';

export const encryptData = (message: string) => CryptoJS.AES.encrypt(message, secretKey).toString();
export const decryptData = (ciphertext: string) => CryptoJS.AES.decrypt(ciphertext, secretKey).toString(CryptoJS.enc.Utf8);

// Protect collisions using user id into the key prefix
// Encrypt stored values on PROD mode.

export const setLocalStorageItem = (item: any, key: string, signed: boolean | null = true) => {
  const data: string = JSON.stringify(item || '');

  // Early exit if !PROD mode.
  if ('1' === debugMode) return localStorage.setItem(key + (signed ? '#' + userId() : ''), data);
  // Store as encrypted string.
  return localStorage.setItem(key + (signed ? '#' + userId() : ''), encryptData(data));
};

export const getLocalStorageItem = (key: string, signed: boolean | null = true) => {
  const data = localStorage.getItem(key + (signed ? '#' + userId() : ''));

  // Early exit if no data.
  if (!data) return null;
  // Early exit if !PROD mode.
  if ('1' === debugMode) return JSON.parse(data);
  // Return a decrypted value.
  return JSON.parse(decryptData(data));
};

export const removeLocalStorageItem = (key: string, signed: boolean | null = true) => localStorage.removeItem(key + (signed ? '#' + userId() : ''));
