import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { AxiosResponse } from 'axios';
import { Button } from 'primereact/button';
import { Column } from 'primereact/column';
import { Tooltip } from 'primereact/tooltip';

import { isValidUUID, trans, ucfirst } from 'utilities';
import { DatatableWrapper, textEditor } from 'components';
import { IRequestParam, IRequestParams } from 'services';

export const ProprietairesIndivisionsDatatable = (props: any) => {
  const { apim, data, context, title, editMode, switchIndivision } = props;
  const { t } = apim.di();

  const resourceType: string = 'indivisions';
  const [rows, setRows] = useState<any[]>([]);
  const [loading, setLoading] = useState<boolean>(false);

  // Map bien type / resource type between back <==> front.
  const getBienType = useMemo(() => {
    switch ((data ?? {})['@type']) {
      default: return 'bienDivers';
      case 'BienBancaire': return 'bienBancaire';
      case 'BienEntreprise': return 'bienEntreprise';
      case 'BienImmobilier': return 'bienImmobilier';
    }
  }, [data]);

  const params: IRequestParam[] = [{label: 'byBien', value: data?.id}];
  useEffect(() => {
    setLoading(true);
    apim.fetchEntities({
      resourceType,
      cache: false,
      params,
      setLoading,
      setter: setRows
    } as IRequestParams).then();
  }, [data, context]); // eslint-disable-line react-hooks/exhaustive-deps

  // Handle submission then refresh table rows.
  const onNew = useCallback(() => {
    if (!isValidUUID(data?.id)) return;

    setLoading(true);
    const _d: any = {};
    _d[getBienType] = data['@id'];

    apim.postEntity({
      resourceType: 'proprietaires',
      notif: false,
      data: _d,
      success: (resP: AxiosResponse) => {
        if (!isValidUUID(resP?.data?.id)) return setLoading(false);

        apim.postEntity({
          resourceType,
          notif: false,
          data: {
            proprietaire: resP.data['@id'],
            label: trans(t, 'new.indivision')
          },
          setLoading,
          success: (resI: AxiosResponse) => {
            if (isValidUUID(resI?.data?.id)) setRows([...rows, ...[resI.data]]);
          }
        } as IRequestParams).then();
      }
    } as IRequestParams).then();
  }, [context?.id, data?.id, rows]); // eslint-disable-line react-hooks/exhaustive-deps

  const switchBtnTemplate = () => (
    <>
      <Button type={'button'} className={'a8-indivisions-proprietaires-btn'} onClick={switchIndivision} icon={'pi pi-users'} aria-label={trans(t, 'gobackProprietaires')}/>
      <Tooltip target={'.a8-indivisions-proprietaires-btn'} position={'left'} content={trans(t, 'gobackProprietaires')}/>
    </>
  );

  return (
    <DatatableWrapper resourceType={resourceType} tableKey={'indivisions'} title={title ?? trans(t, 'indivision', 2)} noFilters noGlobalFilter
                      paginator={false} params={params} isLoading={loading} editMode={editMode} editFields={['label']} onRefresh={setRows} rows={rows}
                      onNew={onNew} addTitle={trans(t, 'table|add.indivision')} headerCreateBtn={switchIndivision ? switchBtnTemplate : null}>
      <Column field={'label'} header={ucfirst(trans(t, 'table|patrimoine_headers.label'))} editor={(options: any) => textEditor(options)}/>
    </DatatableWrapper>
  );
};
