import React, { useCallback, useEffect, useState } from 'react';
import { AxiosResponse } from 'axios';

import { Button } from 'primereact/button';
import { TabMenu } from 'primereact/tabmenu';
import { Fieldset } from 'primereact/fieldset';

import { IRequestParams } from 'services';
import { asDate, dateString, trans, ucfirst } from 'utilities';
import { DatesChangesDatatable, Loader, SocieteTimeline } from 'components';
import { Beneficiaires, Etablissements, FormWrapper, Representants } from 'forms';
import { getCompanies, getCurrentCompany, updateCompany } from 'forms/dossier';

import { includes, forEach, isDate } from 'lodash';
import appUri from 'config/appUri.json';

export const Step4 = (props: any) => {
  const { formState, formKey, apim, previous } = props;
  const { t, navigate } = apim.di();
  const [tabs, setTabs] = useState<any>([]);
  const [activeIndex, setActiveIndex] = useState<number>(-1);

  const baseUrl = appUri.dos.add;

  useEffect(() => {
    const companies = getCompanies(formState);
    const currentCompany = getCurrentCompany(formState);

    const tabItems: any[] = [];
    let tabIndex: number = 0;
    const selectedCompanies = formState.getFieldData(formKey, 'selected', 'step2');

    forEach(companies, (c: any) => {
      const isCompanySelected = includes(selectedCompanies, c.siren);
      if (isCompanySelected) {
        // Add the tab.
        tabItems.push({
          index: tabIndex,
          label: c.name ?? c.denomination,
          siren: c.siren,
          command: () => navigate(baseUrl + appUri.dos.wizard.step4 + '/' + c.siren)
        });

        // Define the active index.
        if (c.siren === currentCompany.siren) {
          setActiveIndex(tabIndex)
        }

        tabIndex++;
      }
    });

    setTabs(tabItems);
    formState.setLoading(formKey, false);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const onSubmit = () => {
    formState.setLoading(formKey, true);
    const dossierCreationId = formState.getFieldData(formKey, 'dossierCreationId');

    // Let's format a bit to suit to APIM needs.
    const companies: any[] = [];
    forEach(formState.getFieldData(formKey, 'companies', 'step4'), (company: any, siren: string) => {
      forEach(company.exercicesFiscauxDateChanges || [], ((efdc: any, index: number) => {
        company.exercicesFiscauxDateChanges[index].closingDate = dateString(efdc.closingDate, 'DD/MM');
      }));
      companies.push({...company, ...{siren: siren}});
    });

    // Prepare data to send for last step.
    const data = {
      id: dossierCreationId,
      companiesData: companies
    };

    apim.call({
      resourceType: 'wizardFormDossier',
      action: 'step4',
      data: data,
      notif: false,
      error: () => formState.setLoading(formKey, false),
      success: (res: AxiosResponse) => {
        if (!res?.data) return;

        formState.setLoading(formKey, false);
        formState.resetFormData(formKey);

        // Redirect to the dossier page.
        const dossierId = res.data?.dossierId;
        navigate(appUri.dos.page.replace(':id', dossierId));
      }
    } as IRequestParams).then();
  };

  // Wrap form render into a useCallback to avoid multiple FormWrapper recalls du to form fields updates.
  const renderForm = useCallback(() => {
    return <FormWrapper data={getCurrentCompany(formState)} parentKey={'[step4][companies][' + getCurrentCompany(formState).siren + ']'}
                        multiple formKeyPrefix={formKey} resourceType={'wf_dossier'} onSubmit={onSubmit} hideReload lockedKey keepAlive />
  }, [getCurrentCompany(formState)?.siren]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div className={'a8-wizard wizard-dossier card fadein animation-duration-500'}>
      <h5 className={'mx-2 my-3'}>{trans(t, 'menu|wizard.dossier.title.step4')}</h5>
      <p className={'mx-2 my-3'}><i className={'pi pi-info-circle mr-2 mt-1 text-primary'}></i> {trans(t, 'menu|wizard.dossier.title.step4Help')}</p>

      <div className={'grid p-fluid mt-3'}>
        {!formState.isLoading(formKey) ? (
          tabs.length > 0 ? (
            <>
              {tabs.length > 1 && <div className={'col-12'}><TabMenu model={tabs} activeIndex={activeIndex} onTabChange={(e) => setActiveIndex(e.index)}/></div>}

              <div className={'col-12'}>
                {renderForm()}
              </div>

              <div className={'col-12'}>
                <Fieldset legend={ucfirst(trans(t, 'table|exercicesFiscauxTitle'))} toggleable>
                  <DatesChangesDatatable data={getCurrentCompany(formState).exercicesFiscauxDateChanges || []} apim={apim}
                                         title={trans(t, 'table|exercicesFiscauxDateChangesTitle')} onRefresh={(newData: any) => {
                    formState.setFieldData(formKey, 'exercicesFiscauxDateChanges', '[step4][companies][' + getCurrentCompany(formState).siren + ']', newData);
                    updateCompany(formState, {...getCurrentCompany(formState), ...{exercicesFiscauxDateChanges: newData}})
                  }} stopOnRefresh/>
                </Fieldset>
              </div>

              {getCurrentCompany(formState).representants && (
                <div className={'col-12'}>
                  <Fieldset legend={ucfirst(trans(t, 'representant', 2))} toggleable>
                    <Representants data={getCurrentCompany(formState).representants} apim={apim}/>
                  </Fieldset>
                </div>
              )}

              {getCurrentCompany(formState).beneficiaires && (
                <div className={'col-12'}>
                  <Fieldset legend={ucfirst(trans(t, 'beneficiary', 2))} toggleable>
                    <Beneficiaires data={getCurrentCompany(formState).beneficiaires} apim={apim}/>
                  </Fieldset>
                </div>
              )}

              {getCurrentCompany(formState).institutions && (
                <div className={'col-12'}>
                  <Fieldset legend={ucfirst(trans(t, 'institution', 2))} toggleable>
                    <Etablissements data={getCurrentCompany(formState).institutions} apim={apim}/>
                  </Fieldset>
                </div>
              )}

              {getCurrentCompany(formState).timeline && (
                <div className={'col-12 py-5'}>
                  <SocieteTimeline data={getCurrentCompany(formState).timeline} apim={apim}/>
                </div>
              )}
            </>
          ) : (
            <p className={'mx-3 mt-2'}><i className={'pi pi-info-circle mr-2 mt-1 text-primary'}></i> {trans(t, 'dossier_creation_no_company')}</p>
          )
        ) : (
          <Loader text={trans(t, 'system|loading')}/>
        )}
      </div>

      <div className={'text-right m-2 mr-3'}>
        <Button className={'text-ucfirst p-button-text mr-5'} label={trans(t, 'previous')} onClick={() => navigate(previous)}/>
        <Button type={'button'} className={'text-ucfirst'} label={trans(t, 'validate')} icon={'pi pi-check'} loading={formState.isLoading()} onClick={() => {
          let invalidDates: boolean = false;
          const _cies: any[] = getCompanies(formState);
          forEach(formState.getFieldData(formKey, 'companies', 'step4'), (company: any, siren: string) => {
            const _cie: any = (_cies ?? []).filter((_c: any) => _c.siren === siren);
            if (_cie.length === 0 || !checkDates(_cie[0], company)) invalidDates = true;
          });

          // Early exit if invalidations.
          // @TODO show the fields as invalid
          if (invalidDates) {
            return apim.toast('error')?.current?.show({
              severity: 'error',
              summary: trans(t, 'form|crudError.title'),
              detail: _cies.length > 1 ? trans(t, 'form|step4_multiple_invalid_dates') : trans(t, 'form|step4_unique_invalid_dates'),
              life: 5000
            });
          }

          // Let's manually trigger submit.
          const submits = document.getElementsByClassName('a8-form-submit');
          if (submits.length > 0) {
            forEach(submits, (formSubmit) => (formSubmit as HTMLInputElement).click());
          } else {
            onSubmit();
          }
        }}
        />
      </div>
    </div>
  );
};

const checkDates = (c: any, d: any) => {
  if (!d.firstExerciceFiscalEnd && !c.firstExerciceFiscalEnd) return false;
  if (!d.firstExerciceFiscalStart && !c.firstExerciceFiscalStart) return false;

  const s = asDate(d.firstExerciceFiscalStart ?? c.firstExerciceFiscalStart);
  const e = asDate(d.firstExerciceFiscalEnd ?? c.firstExerciceFiscalEnd);

  return isDate(s) && isDate(e) && (e > s);
}
