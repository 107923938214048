import React from 'react';
import { Dropdown } from 'primereact/dropdown';
import { Tooltip } from 'primereact/tooltip';
import { Button } from 'primereact/button';

import { trans, ucfirst } from 'utilities';
import { suspendDossierSocialData, validateDossierSocialData } from '../annualData';

export const CivilYear = (props: any) => {
  const { baseUri, appState, apim, dossierSocialData, setDossierSocialDataChanged, setDossierSocialDataPatchLoading, dossierSocialDataPatchLoading } = props;
  const { t, navigate } = apim.di();
  const getCivilYears = () => ([].concat(appState.dossier()?.civilYears || [])).sort((a: number, b: number) => a > b ? -1 : 1).map((c: number) => c.toString());

  return <>
    {getCivilYears().length > 0 && (
      <div className={'flex-none flex p-2'}>
        <div className={'field p-inputgroup'}>
          <span className={'p-inputgroup-addon'}>
            <i className={'pi pi-calendar'}></i>
          </span>

          <span className={'p-float-label'}>
            <Dropdown id={'a8-civilYears-select'} value={appState.civilYear()} options={getCivilYears()} placeholder={trans(t, 'form|select')}
                      onChange={(e) => {
                        appState.setCivilYear(e.value);
                        appState.setExercise(e.value); // Let's keep these 2 synchronized ATM.
                        navigate(baseUri.replace(':year', e.value));
                      }}/>
            <label htmlFor={'a8-civilYear-select'} className={'text-ucfirst'}>
              {trans(t, 'civilYear')}
            </label>
          </span>
        </div>

        <div className={'pl-5'}>
          {dossierSocialData && dossierSocialData.validated ? (
            <span>
              <Tooltip target={'.a8-dossier-suspend-annual-data'} position={'top'} mouseTrack/>
              <Button label={ucfirst(trans(t, 'form|company.annualData.suspendShort'))}
                      icon={'pi pi-' + (dossierSocialData.dossierSocialDataPatchLoading ? 'spinner pi-spin' : 'ban')}
                      severity={'warning'} style={{paddingTop: '12px'}}
                      onClick={() => suspendDossierSocialData(apim, dossierSocialData, setDossierSocialDataPatchLoading, () => {
                        setDossierSocialDataChanged(Date.now());
                      })}
                      data-pr-tooltip={ucfirst(trans(t, 'form|company.annualData.suspend'))}
                      data-pr-position={'top'} data-pr-at={'top'} data-pr-my={'top'}
                      className={'a8-dossier-suspend-annual-data'}/>
            </span>
          ) : (
            <span>
              <Tooltip target={'.a8-dossier-validate-annual-data'} position={'top'} mouseTrack/>
              <Button label={ucfirst(trans(t, 'form|company.annualData.validateShort'))}
                      icon={'pi pi-' + (dossierSocialDataPatchLoading ? 'spinner pi-spin' : 'check')}
                      severity={'success'} style={{paddingTop: '12px'}}
                      onClick={() => validateDossierSocialData(apim, dossierSocialData, setDossierSocialDataPatchLoading, () => {
                        setDossierSocialDataChanged(Date.now());
                      })}
                      data-pr-tooltip={ucfirst(trans(t, 'form|company.annualData.validate'))}
                      data-pr-position={'top'} data-pr-at={'top'} data-pr-my={'top'}
                      className={'a8-dossier-validate-annual-data'}
                      disabled={dossierSocialData === null} />
            </span>
          )}
        </div>
      </div>
    )}
  </>;
};
