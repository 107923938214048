import React, { useEffect, useState } from 'react';
import { Controller } from 'react-hook-form';

import { classNames } from 'primereact/utils';
import { Dropdown } from 'primereact/dropdown';
import { Skeleton } from 'primereact/skeleton';
import { AxiosResponse } from 'axios';
import { forEach } from 'lodash';

import { trans, ucfirst } from 'utilities';
import { IRequestParams, useApim } from 'services';

import 'assets/flags/flags.css';
import 'assets/flags/flags_responsive.png';

export const CountryField = (props: any) => {
  const { fieldKey, control, onFieldChange, errorMessage, rules, label, placeholder, classes, disabled } = props;

  const apim = useApim();
  const { t } = apim.di();

  const [options, setOptions] = useState<any[]>([]);
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    apim.fetchEntities({
      resourceType: 'countries',
      params: [{label: 'order[name]', value: 'asc'}, {label: 'itemsPerPage', value: 500}, {label: 'active', value: true}],
      setLoading: setLoading,
      success: (res: AxiosResponse) => {
        const _options: any[] = [];
        const _countries = res.data['hydra:member'] || [];

        // Put frequently used countries first.
        forEach(_countries, (country: any) => {
          if (country.code === 'FRA') return _options.push(country);
        });

        // Put all other countries.
        forEach(_countries, (country: any) => {
          if (country.code === 'FRA') return null;

          return _options.push(country);
        });

        setOptions(_options);
      }
    } as IRequestParams).then();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  // A field key can join multiple possibilities using '||' separator.
  const split = fieldKey.split('||');

  const selectedCountryTemplate = (option: any, props: any) => {
    if (option) {
      return (
        <div className={'flex align-items-center'}>
          <img alt={option.name} src={'https://primefaces.org/cdn/primereact/images/flag/flag_placeholder.png'} className={`mr-2 flag flag-${option.codeShort.toLowerCase()}`} style={{ width: '18px' }} />
          <div>{option.name}</div>
        </div>
      );
    }

    return <span>{props.placeholder}</span>;
  };

  const countryOptionTemplate = (option: any) => {
    return (
      <div className={'flex align-items-center'}>
        <img alt={option.name} src={'https://primefaces.org/cdn/primereact/images/flag/flag_placeholder.png'} className={`mr-2 flag flag-${option.codeShort.toLowerCase()}`} style={{ width: '18px' }} />
        <div>{option.name}</div>
      </div>
    );
  };

  return (<div className={classNames('field', classes || 'col-12 md:col-6 lg:col-4')}>
    {loading ? <Skeleton height={'2.8rem'} /> :
      <Controller
        name={split[0]}
        control={control}
        rules={rules}
        render={({ field, fieldState }) => (
          <>
            <span className={'p-float-label'}>
              <Dropdown
                id={field.name} className={'w-full'} placeholder={placeholder || ucfirst(trans(t, 'form|select_country'))} value={field.value}
                options={options} optionLabel={'name'} optionValue={'code'} filter showClear disabled={disabled}
                onChange={(e) => onFieldChange(field, fieldState, e.value)}
                valueTemplate={selectedCountryTemplate} itemTemplate={countryOptionTemplate}/>
              {(label || field.name) && (
                <label className={'text-ucfirst p-float-label-label'} htmlFor={field.name}>
                  <span>{label ?? trans(t, field.name)}</span>
                </label>
              )}
            </span>
            {errorMessage(field.name)}
          </>
        )}
      />
    }
    </div>
  );
};
