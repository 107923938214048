import React from 'react';
import { Controller } from 'react-hook-form';

import { classNames } from 'primereact/utils';
import { Dropdown } from 'primereact/dropdown';

import { trans, ucfirst } from 'utilities';
import { useApim } from 'services';

import 'assets/flags/flags.css';
import 'assets/flags/flags_responsive.png';

export const BoolSelectField = (props: any) => {
  const { fieldKey, control, onFieldChange, errorMessage, rules, showClear, filter,
    label, placeholder, classes, disabled, trueLabel, falseLabel } = props;

  const apim = useApim();
  const { t } = apim.di();

  const options = [
    {value: true, label: trueLabel ?? ucfirst(trans(t, 'yes'))},
    {value: false, label: falseLabel ?? ucfirst(trans(t, 'no'))}
  ];

  // A field key can join multiple possibilities using '||' separator.
  const split = fieldKey.split('||');

  const selectedChoiceTemplate = (option: any, props: any) => {
    if (option) {
      return (
        <div className={'flex align-items-center'}>
          <div>{option.label}</div>
        </div>
      );
    }

    return <span>{props.placeholder}</span>;
  };

  const choiceOptionTemplate = (option: any) => {
    return (
      <div className={'flex align-items-center'}>
        <div>{option.label}</div>
      </div>
    );
  };

  return (<div className={classNames('field', classes || 'col-12 md:col-6 lg:col-4')}>
    <Controller
      name={split[0]}
      control={control}
      rules={rules}
      render={({ field, fieldState }) => (
        <>
          <span className={'p-float-label'}>
            <Dropdown
              id={field.name} className={'w-full'} placeholder={placeholder || ucfirst(trans(t, 'form|select_choice'))} value={field.value}
              options={options} optionLabel={'label'} optionValue={'value'} filter={filter} showClear={showClear} disabled={disabled}
              onChange={(e) => onFieldChange(field, fieldState, e.value)}
              valueTemplate={selectedChoiceTemplate} itemTemplate={choiceOptionTemplate}/>
            <label className={'text-ucfirst p-float-label-label'} htmlFor={field.name}>
              <span>{label ?? trans(t, field.name)}</span>
            </label>
          </span>
          {errorMessage(field.name)}
        </>
      )}
    />
    </div>
  );
};
