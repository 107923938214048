import React, { useCallback, useEffect, useState } from 'react';
import { AxiosResponse } from 'axios';

import { Button } from 'primereact/button';

import { FormWrapper } from 'forms/index';
import { iri, trans, triggerFormSubmit } from 'utilities';
import { PageLoader } from 'components';
import { IRequestParams } from 'services';

import appUri from 'config/appUri.json';

export const Retraite = (props: any) => {
  const { apim, urls, missionCategory, setMissionCategory, cancelUri, hypotheseId, missionId } = props;
  const { t, navigate } = apim.di();

  const [loading, setLoading] = useState<boolean>(false);
  const [retirementAge, setRetirementAge ] = useState<number>(0);

    useEffect(() => {
    setLoading(true);
    apim.fetchEntities({
      resourceType: 'retirementAges',
      cache: false,
      setLoading,
      success: (res: AxiosResponse) => {
        const retirementAges = res.data['hydra:member'] ?? [];
        const latestRetirementAge = retirementAges.reduce((latest : any, current : any) => {
          const latestDate = new Date(latest.date).getTime();
          const currentDate = new Date(current.date).getTime();
          return latestDate > currentDate ? latest : current;
        }, retirementAges[0]);
        if (latestRetirementAge) setRetirementAge(latestRetirementAge.age);
      }
    } as IRequestParams).then();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const renderForm = useCallback(() =>
      <FormWrapper classes={'grid p-fluid'} formKeyPrefix={'general_retraite'} resourceType={'missionRetraite'} setData={setMissionCategory}
                   data={missionCategory} additionalData={{missionHypothesis : iri('missionHypothesis', hypotheseId)}}
                   hideReload cancelLink multiple context={retirementAge} redirectUri={urls?.form + '/' + missionId + '/hypothese/' + hypotheseId + appUri.dos.edit.missions.steps.retraite.step2}/>
    , [missionCategory?.id, retirementAge]); // eslint-disable-line react-hooks/exhaustive-deps

  return loading ? <PageLoader/> : (
    <>
      {renderForm()}

      <div className={'text-right m-2 pt-4'}>
        <Button type={'button'} className={'text-ucfirst p-button-text p-button-danger mr-5'} label={trans(t, 'close')} onClick={() => navigate(cancelUri)}/>
        <span className={'p-buttonset'}>
          <Button type={'button'} className={'text-ucfirst p-button-text'} label={trans(t, 'back')} icon={'pi pi-arrow-left'} onClick={() => navigate(urls?.form + '/' + missionId + '/hypothese/' + hypotheseId)}/>
          <Button type={'button'} className={'text-ucfirst p-button-text'} label={trans(t, 'next')} icon={'pi pi-arrow-right'} iconPos={'right'} onClick={() => triggerFormSubmit(null)}/>
        </span>
      </div>
    </>
  );
}
