import React from 'react';
import { TriStateCheckbox } from 'primereact/tristatecheckbox';
import { BaseTextField } from './BaseTextField';

export const TriStateField = (props: any) => {
  const { fieldKey, control, onFieldChange, errorMessage,
    rules, label, classes, addon, addonPosition, disabled, tooltip, tooltipOptions, help, textAddon } = props;

  const renderInput = (field: any, fieldState: any) =>
    <div className={'p-inputgroup flex align-items-stretch'}>
      <span style={{padding: '12px 12px 12px 8px'}}>
        <TriStateCheckbox id={field.name} value={field.value} disabled={disabled} aria-invalid={fieldState.error}
                          onChange={(e) => { onFieldChange(field, fieldState, e.value, 'boolean'); }}
                          tooltip={tooltip} tooltipOptions={tooltipOptions || {position: 'top'}} />
      </span>
      <label htmlFor={field.name} className={'flex flex-auto flex-column justify-content-center'}>
        <span>{label}</span>
        {help && (
          <span className={'help text-xs'}>{help}</span>
        )}
      </label>
    </div>
  ;

  return <BaseTextField fieldKey={fieldKey} control={control} onFieldChange={onFieldChange} errorMessage={errorMessage} rules={rules} classes={classes}
                        addon={addon} addonPosition={addonPosition} textAddon={textAddon} renderInput={renderInput} />
};
