import React from 'react';
import { AppBreadcrumb } from './AppBreadcrumb';
import { AppPageActions } from './AppPageActions';

export const AppSubHeader = (props: any) => {
  const { apim, appState } = props;

  return (
    <div className={'layout-breadcrumb-container flex justify-content-between align-items-center wfit'}>
      <AppBreadcrumb appState={appState} apim={apim} />
      <AppPageActions appState={appState} apim={apim} />
    </div>
  );
};
