import React from 'react';

import { trans } from 'utilities';
import { MaskField, TextField } from 'forms';

export const AddSocieteClientFormStandalone = (props: any) => {
  const { t, control, onFieldChange, errorMessage } = props;

  return <>
      <TextField fieldKey={'raisonSociale'} control={control} onFieldChange={onFieldChange} errorMessage={errorMessage} label={trans(t, 'ent.raisonSociale')} classes={'w-full mb-5'} rules={{ required: trans(t, 'form|requiredField') }} />
      <MaskField fieldKey={'siren'} mask={'999 999 999'} control={control} onFieldChange={onFieldChange} errorMessage={errorMessage} label={trans(t, 'ent.siren')} classes={'w-full'} rules={{ required: trans(t, 'form|requiredField') }} />
  </>
};
