import React, { useEffect, useState } from 'react';
import { Skeleton } from 'primereact/skeleton';

import { trans, ucfirst } from 'utilities';
import { IRequestParams } from 'services';
import { AxiosResponse } from 'axios';
import { Card } from 'primereact/card';

export const StatusPage = (props: any) => {
  const { resource, apim } = props;
  const { t } = apim.di();

  const [loading, setLoading] = useState<boolean>(false);
  const [ressourceTitle, setRessourceTitle] = useState<string>('');
  const [success, setSuccess] = useState<boolean>(false);
  const [components, setComponents] = useState<any[]>([]);
  const [message, setMessage] = useState<string>('');

  useEffect(() => {
    setLoading(true);

    switch (resource) {
      case 'authorization':
      case 'core':
      case 'dossier':
      case 'ged':
      case 'notification':
      case 'expert':
      case 'task_manager':
        setRessourceTitle(trans(t, 'ms.' + resource, 1));
        break;

      default:
        setRessourceTitle(trans(t, 'apiManager', 1));
        break;
    }

    apim.call({
      resourceType: 'healthcheck',
      action: 'status',
      method: 'get',
      cache: false,
      notif: false,
      setLoading,
      success: (res: AxiosResponse) => {
        let resourceShort = resource;
        if (resource === 'authorization') {
          resourceShort = 'auth';
        }

        if (resource !== 'all' && resource !== 'apim') {
          res.data.components.forEach((component: any) => {
            if (component.url.includes(resourceShort)) {
              setSuccess(component.success);
              setMessage(component.message);
              setComponents([]);
            }
          })
        } else {
          setSuccess(res.data.success && res.data.successMicroservices);
          setComponents(res.data.components);
          setMessage(res.data.message);
        }
      }
    } as IRequestParams).then();

  }, [resource]); // eslint-disable-line react-hooks/exhaustive-deps

  return <div className={'status-page flex flex-column ml-3'}>
    <h2 className={'header text-ucfirst'}>{ressourceTitle}</h2>
    <div className={'flex flex-column gap-2'}>
      {loading ? (
        <Skeleton shape={'rectangle'} width={'100%'} height={'4rem'} />
      ) : (
        <div className={'status-page-content'}>
          <h3>{ucfirst(trans(t, 'status', 1))} :
            <div className={'flex flex-row'}>
              {success ? <i className={'pi pi-check-circle ml-2 inline-flex'} style={{ color: 'green', fontSize: '1.2rem' }}></i> :
                <i className={'pi pi-times-circle ml-2 inline-flex'} style={{ color: 'red', fontSize: '1.2rem' }}></i>}
              <span className={'ml-2 inline-flex'}>{message}</span>
            </div>
          </h3>

          <div className={'status-page-components flex flex-row flex-wrap gap-5'}>
          {components.map((component: any, i: number) => (
            <Card key={i} title={<a href={component.url}>{ucfirst(trans(t, 'ms.' + component.type))}</a>} className={'flex flex-grow-0 w-2'}>
              <div className={'flex flex-row'}>
                {component.success ? <i className={'pi pi-check-circle ml-2 inline-flex'} style={{color: 'green', fontSize: '1.2rem'}}></i> :
                  <i className={'pi pi-times-circle ml-2 inline-flex'} style={{color: 'red', fontSize: '1.2rem'}}></i>}
                <span className={'ml-2 inline-flex'}>{component.message}</span>
              </div>
            </Card>
            ))}
          </div>
        </div>
      )}
    </div>
  </div>;
};
