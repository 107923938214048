import React, { useEffect } from 'react';

import { ModuleWrapper } from 'pages';
import { Reserves } from './Default';
import { trans } from 'utilities';

export const ReservesRouter = (props: any) => {
  const { appState, apim, id, module, tab, modulesMenu, lastUpdate } = props;
  const { t } = apim.di();

  // Override Page Header (defaults into ModuleWrapper).
  useEffect(() => {
    appState.setPageTitle(trans(t, 'menu|pages.title.dossier.reserves', 1, appState.dossier().title));
  }, [id, module, tab]); // eslint-disable-line react-hooks/exhaustive-deps

  const buildTabContent = () => {
    return <Reserves id={id} appState={appState} apim={apim} modulesMenu={modulesMenu} lastUpdate={lastUpdate} />;
  };

  return <ModuleWrapper appState={appState} apim={apim} modulesMenu={modulesMenu}  buildTabContent={buildTabContent} />;
};
