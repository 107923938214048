import React, { useCallback } from 'react';
import { Column } from 'primereact/column';

import { isAdmin, isExpertAdmin } from 'services';
import { iri, trans } from 'utilities';
import { DatatableWrapper } from 'components';

import appUri from 'config/appUri.json';

export const ExpertDossiers = (props: any) => {
  const { expert, apim } = props;
  const { t, navigate} = apim.di();

  const resourceType = 'userGroups';
  const lazyConfig = {sortField: 'group.label', sortOrder: 1};
  const params = [
    { label: 'user', value: expert?.id},
    { label: 'group.name', value: 'DOSSIER_'},
    { label: 'role.name[]', value: 'ROLE_EXPERT'},
    { label: 'role.name[]', value: 'ROLE_EXPERT_ADMIN'},
    { label: 'expand[]', value: 'user_group:read_group'},
    { label: 'expand[]', value: 'group:read'},
    { label: 'expand[]', value: 'user_group:read_role'},
    { label: 'expand[]', value: 'role:read_list'}
  ];

  const groupBodyTemplate = (rowData: any) => rowData?.group?.label ?? rowData?.group?.name;

  const roleBodyTemplate = (rowData: any) => trans(t, rowData?.role ? 'system|role.' + rowData?.role?.name.toLowerCase() : 'N/R');

  const hookRowEdit = (_rows: any[], _row: any) => ({
    formattedRows: _rows,
    patched: {userGroups: {role: _row?.role?.id ? iri('roles', _row.role.id) : null}},
    id: _row?.id
  });

  const renderDataTable = useCallback(() =>
      <DatatableWrapper
        resourceType={resourceType} tableKey={'dossier-experts'} params={params} lazyConfig={lazyConfig} noFilters editFields={['role']} noEdit noAdd
        title={trans(t, 'menu|pages.title.expertDossiersLists')} addTitle={trans(t, 'table|add.expertLinkDossier')} editMode={isAdmin() || isExpertAdmin()}
        onRowClick={(row: any) => navigate(appUri.dos.page.replace(':id', row?.group?.context?.entity?.id || '_'))} hookRowEdit={hookRowEdit}>
        <Column field={'group'} header={trans(t, 'dossier')} body={groupBodyTemplate}/>
        <Column field={'role'} header={trans(t, 'role')} body={roleBodyTemplate}/>

      </DatatableWrapper>
    , [expert?.id]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      {renderDataTable()}
    </>
  );
};
