import React, { useCallback } from 'react';

import { classNames } from 'primereact/utils';
import { ProgressBar } from 'primereact/progressbar';
import { Steps } from 'primereact/steps';

import { trans, ucfirst } from 'utilities';
import { NotFound } from 'pages';
import { CGContrat, CPContrat, CPDeces, Validation } from './tabs';

import appUri from 'config/appUri.json';

export const ContractFormRouterTD = (props: any) => {
  const { activeIndex, setActiveIndex, urls, contract, updateData, step, apim, appState, dossier } = props;
  const { t, navigate } = apim.di();

  const wizardItems = [
    { label: ucfirst(trans(t, 'menu|wizard.dossier.contracts.prevoyance.step11')), command: () => navigate(urls.form) },
    { label: ucfirst(trans(t, 'menu|wizard.dossier.contracts.prevoyance.step21')), command: () => navigate(urls.form + appUri.dos.edit.contracts.prevoyance.step21) },
    { label: ucfirst(trans(t, 'menu|wizard.dossier.contracts.prevoyance.step22')), command: () => navigate(urls.form + appUri.dos.edit.contracts.prevoyance.step22) },
    { label: ucfirst(trans(t, 'menu|wizard.dossier.contracts.prevoyance.step31')), command: () => navigate(urls.form + appUri.dos.edit.contracts.prevoyance.step31) },
    { label: ucfirst(trans(t, 'menu|wizard.dossier.contracts.prevoyance.step32')), command: () => navigate(urls.form + appUri.dos.edit.contracts.prevoyance.step32) },
    { label: ucfirst(trans(t, 'menu|wizard.dossier.contracts.prevoyance.step34')), command: () => navigate(urls.form + appUri.dos.edit.contracts.prevoyance.step34) },
    { label: ucfirst(trans(t, 'menu|wizard.dossier.contracts.prevoyance.step4')), command: () => navigate(urls.form + appUri.dos.edit.contracts.prevoyance.step41) },
  ];
  const parentItems = [
    { id: '0', label: ucfirst(trans(t, 'menu|wizard.dossier.contracts.prevoyance.step1')), command: () => navigate(urls.form) },
    { id: '1', label: ucfirst(trans(t, 'menu|wizard.dossier.contracts.prevoyance.step2')), command: () => navigate(urls.form + appUri.dos.edit.contracts.prevoyance.step21) },
    { id: '4', label: ucfirst(trans(t, 'menu|wizard.dossier.contracts.prevoyance.step3')), command: () => navigate(urls.form + appUri.dos.edit.contracts.prevoyance.step31) },
    { id: '8', label: ucfirst(trans(t, 'menu|wizard.dossier.contracts.prevoyance.step4')), command: () => navigate(urls.form + appUri.dos.edit.contracts.prevoyance.step41) },
  ];
  const subItems20 = [
    { id: '1', label: ucfirst(trans(t, 'menu|wizard.dossier.contracts.prevoyance.step21')), command: () => navigate(urls.form + appUri.dos.edit.contracts.prevoyance.step21) },
  ];
  const subItems30 = [
    { id: '4', label: ucfirst(trans(t, 'menu|wizard.dossier.contracts.prevoyance.step31')), command: () => navigate(urls.form + appUri.dos.edit.contracts.prevoyance.step31) },
    { id: '7', label: ucfirst(trans(t, 'menu|wizard.dossier.contracts.prevoyance.step34')), command: () => navigate(urls.form + appUri.dos.edit.contracts.prevoyance.step34) },
  ];

  const buildTabContent = useCallback(() => {
    switch (activeIndex) {
      case 1: return <CGContrat apim={apim} urls={urls} data={contract} updateData={updateData}/>;
      case 4: return <CPContrat apim={apim} urls={urls} data={contract} updateData={updateData}/>;
      case 7: return <CPDeces apim={apim} urls={urls} data={contract} updateData={updateData}/>;
      case 8: return <Validation apim={apim} urls={urls} dossier={dossier} appState={appState}/>;

      default: return <NotFound asBlock />
    }
  }, [step, activeIndex]); // eslint-disable-line react-hooks/exhaustive-deps

  const stepProgressSize = 100 / wizardItems.length;

  return (
    <>
      <div className={'grid'}>
        <div className={'col'}>
          <ProgressBar value={stepProgressSize + activeIndex * stepProgressSize} showValue={false}/>
        </div>
      </div>
      <Steps className={'stacked-steps'} model={parentItems} activeIndex={activeIndex < 1 ? 0 : (activeIndex > 3 ? (activeIndex > 7 ? 3 : 2) : 1)}
             onSelect={(e) => setActiveIndex(parseInt(e.item.id!))} readOnly={false}/>

      {activeIndex < 4 && activeIndex > 0 && (
        <Steps model={subItems20} activeIndex={(activeIndex - 1)} onSelect={(e) => setActiveIndex(parseInt(e.item.id!))} readOnly={false}/>
      )}
      {activeIndex > 3 && activeIndex < 8 && (
        <Steps model={subItems30} activeIndex={(activeIndex - (activeIndex === 7 ? 6 : 4))} onSelect={(e) => setActiveIndex(parseInt(e.item.id!))} readOnly={false}/>
      )}

      <div className={classNames('card height-100 fadein animation-duration-500 flex flex-column', {
        'mt-6': !((activeIndex < 4 && activeIndex > 0) || (activeIndex > 3 && activeIndex < 8))
      })}>
        {buildTabContent()}
      </div>
    </>
  );
};
