import React, { useCallback, useEffect, useState } from 'react';
import { AxiosResponse } from 'axios';

import { trans } from 'utilities';
import { IRequestParams, isClient } from 'services';
import { formatFamilyTree, getModuleId, onChartNodeDetailClick, OrgChart, PageLoader } from 'components';

import { isEmpty, replace } from 'lodash';
import appUri from 'config/appUri.json';

export const Foyer = (props: any) => {
  const { id, appState, apim, modulesMenu, lastUpdate } = props;
  const { t, navigate } = apim.di();

  const dossier = appState.dossier();
  const [loading, setLoading] = useState<boolean>(false);
  const [data, setData] = useState<any>(null);

  // Override Page Header (defaults into ModuleWrapper then ReservesRouter).
  useEffect(() => {
    appState.setBreadcrumb([
      isClient()
       ? { label: trans(t, 'dossier', 2) }
       : { label: trans(t, 'dossier', 2), to: appUri.dos.list },
      { label: dossier.title, to: replace(appUri.dos.page, ':id', id)},
      { label: trans(t, 'menu|menu.schema_flux.foyer') }
    ]);

    appState.setModule(getModuleId(modulesMenu, 'foyer'));
  }, [id]); // eslint-disable-line react-hooks/exhaustive-deps

  const formatChart = (dataSource: any) => {
    return {nodes: formatFamilyTree(dataSource, t), config: {'family': {min: false}}};
  };

  const fetchData = useCallback(async () => {
    setLoading(true);

    apim.call({
      resourceType: 'dossiers',
      action: 'module',
      id: id + '|' + getModuleId(modulesMenu, 'foyer'),
      method: 'get',
      cacheMode: isEmpty(lastUpdate) ? 'default' : 'replace',
      setLoading: setLoading,
      success: (res: AxiosResponse) => setData({...res.data, ...{chart: formatChart(res.data)}})
    } as IRequestParams);
  }, [lastUpdate]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    fetchData().then();
  }, [lastUpdate]); // eslint-disable-line react-hooks/exhaustive-deps

  if (loading) return <PageLoader />;

  return (
    <div className={'flex flex-row flex-wrap fadein animation-duration-500'} style={{height: '80vh'}}>
      {data?.chart && (
        <OrgChart data={data.chart} t={t} onDetailsClick={(node: any) => onChartNodeDetailClick(node, navigate)} />
      )}
    </div>
  );
};
