import React from 'react';
import { trans } from 'utilities';
import { APIListField } from 'forms';

export const CGInvaliditeForm = (props: any) => {
  const { t, control, onFieldChange, errorMessage, getValues } = props;
  const { type } = getValues();

  return <div className={'grid w-full px-3'}>
    <div className={'col-3'}>
      <APIListField classes={'w-full'} listKey={'prevoyanceContractBaremesInvalidites'} fieldKey={'invaliditeBaremes'} control={control} onFieldChange={onFieldChange} errorMessage={errorMessage}
                    label={trans(t, 'form|prevoyance_contrat.invaliditeBareme')} showClear rules={{required: trans(t, 'form|requiredField')}} multiple/>
    </div>

    {type !== 'homme_cle' && (
      <div className={'col-3'}>
        <APIListField classes={'w-full'} listKey={'prevoyanceContractFormulesInvalidite'} fieldKey={'invaliditeFormule'} control={control} onFieldChange={onFieldChange} errorMessage={errorMessage}
                      label={trans(t, 'form|prevoyance_contrat.invaliditeFormule')} showClear rules={{required: trans(t, 'form|requiredField')}}/>
      </div>
    )}
  </div>;
};
