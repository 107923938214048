import React, { useCallback, useEffect, useState } from 'react';
import { Column } from 'primereact/column';

import { IRequestParams, isAdmin } from 'services';
import { dialog, trans, ucfirst, triggerFormReset, triggerFormSubmit } from 'utilities';
import {
  DatatableWrapper,
  PageLoader,
  validCell,
  apiListEditor, maskEditor,
  handlePersonnePhysiqueAddress, handlePersonnePhysiqueRelatedEntity, onPersonnePhysiqueAddSubmit,
  birthDateBody, ssnBody, dateTimeCell, simpleCheckboxEditor, personCell
} from 'components';
import { FormWrapper } from 'forms';

import { forEach, omit, replace } from 'lodash';
import appUri from 'config/appUri.json';

export const PersonnePhysiqueSpouses = (props: any) => {
  const { rows, setRows, tableKey, title, editMode, params, context, apim } = props;
  const { t, navigate } = apim.di();

  const [loading, setLoading] = useState<boolean>(false);
  const [maritalStatuses, setMaritalStatuses] = useState<any[]>([]);
  const [matrimonialRegimes, setMatrimonialRegimes] = useState<any[]>([]);

  // /!\ WARNING /!\ Let's keep 'personnesPhysiques' instead of 'spouses' as resourceType on purpose
  // because (believe it or not but) this way is simpler during edit process :)
  const resourceType = 'personnesPhysiques';

  useEffect(() => {
    apim.getList('maritalStatuses', {setter: setMaritalStatuses} as IRequestParams).then();
    apim.getList('matrimonialRegimes', {setter: setMatrimonialRegimes} as IRequestParams).then();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const currentBodyTemplate = (rowData: any) => validCell(rowData?.currentSpouse);
  const maritalDateBody = (rowData: any) => dateTimeCell(rowData?.maritalDate, {format: 'DD/MM/YYYY'});
  const separatedDateBody = (rowData: any) => dateTimeCell(rowData?.separatedDate, {format: 'DD/MM/YYYY'});
  const maritalStatusBodyTemplate = (rowData: any) => {
    const match: any = (maritalStatuses || []).filter((m: any) => rowData?.maritalStatus?.toLowerCase() === m?.value);

    return match.length > 0 ? match[0].translated : rowData?.maritalStatus;
  };
  const matrimonialRegimeBodyTemplate = (rowData: any) => {
    const match: any = (matrimonialRegimes || []).filter((m: any) => rowData?.maritalRegime?.toLowerCase() === m?.value);

    return match.length > 0 ? match[0].translated : rowData?.maritalRegime;
  };
  const activeBodyTemplate = (rowData: any) => validCell(rowData?.active);
  const fullNameBodyTemplate = (rowData: any) => personCell(rowData?.personnePhysique, {
    url: appUri.cie.per.phy.page.replace(':id', rowData?.personnePhysique?.id),
    label: trans(t, 'seeDetails')
  });

  const onRowEditCallback = (patched: any) => {
    if (!patched || !patched[resourceType] || !patched[resourceType].id) return;

    // Handle personnesPhysiquesData (personne_physique_datas).
    handlePersonnePhysiqueRelatedEntity(apim, context, 'personnesPhysiquesData', patched);

    // Handle personnesPhysiquesSpouses (personne_physique_spouses).
    handlePersonnePhysiqueRelatedEntity(apim, context, 'personnesPhysiquesSpouses', patched, setLoading);

    // Handle address.
    handlePersonnePhysiqueAddress(patched[resourceType], apim);
  };

  const hookRowEdit = (_rows: any[], editedRow: any) => {
    if (!editedRow) return;

    const original: any = rows.filter((r: any) => r.id === editedRow.id)[0];
    const personnePhysique: any = editedRow.personnePhysique;
    let tmp: any = omit(editedRow, ['personnePhysique']);
    const patched: any = {
      personnesPhysiques: { id: original?.personnePhysique?.id },
      personnesPhysiquesData: { id: original?.personnePhysique?.latestVersion?.id },
      personnesPhysiquesSpouses: { id: original?.id }
    };

    // Let's pick only patched data.
    // 1. "personne_physiques" fields.
    forEach(['lastName', 'firstName', 'birthDate', 'socialSecurityNumber', 'email', 'phone1'], ((field: string) => {
      if (undefined === tmp['personnePhysique.' + field] || tmp['personnePhysique.' + field] === original?.personnePhysique[field]) {
        tmp = omit(tmp, 'personnePhysique.' + field);

        return;
      }

      patched.personnesPhysiques[field] = tmp['personnePhysique.' + field];
      tmp = omit(tmp, 'personnePhysique.' + field);

      if ('lastName' === field || 'firstName' === field) {
        patched.personnesPhysiquesData[field] = patched.personnesPhysiques[field];
      }
    }));

    // 2. "personnesPhysiquesSpouses" (root fields).
    forEach(['currentSpouse', 'maritalDate', 'maritalRegime', 'maritalStatus', 'active'], ((field: string) => {
      if (undefined === tmp[field] || tmp[field] === original[field]) {
        tmp = omit(tmp, field);

        return;
      }

      patched.personnesPhysiquesSpouses[field] = tmp[field];
      tmp = omit(tmp, field);
    }));

    // Then update rows using a proper rows array.
    // return formatPersonPhysiquePatched(_rows, personnePhysique, tmp, patched, 'personnesPhysiquesSpouses');
    return { formattedRows: _rows, patched: patched, id: personnePhysique?.id };
  };

  const onRowClick = (row: any) => navigate(replace(appUri.cie.per.phy.page, ':id', row?.personnePhysique?.id || '_'));
  const onDelete = (row: any) => {
    if (row?.id) {
      apim.deleteEntity({
        resourceType: 'personnesPhysiquesSpouses',
        id: row?.id
      } as IRequestParams).then();
    }
  };

  const onNew = () => {
    dialog(t, {
      header: trans(t,'form|dossier.personnePhysiqueSpouseAdd'),
      icon: 'none',
      message: renderAddForm(),
      accept: () => triggerFormSubmit('a8-form-company-pps-add'),
      reject: () => triggerFormReset('a8-form-company-pps-reset')
    });
  };

  // Handle submission then refresh table rows.
  const onAddSubmit = (formData: any) => onPersonnePhysiqueAddSubmit(apim, context, formData, rows, setRows, 'personnesPhysiquesSpouses', setLoading);

  // Wrap form render into a useCallback to avoid multiple FormWrapper recalls du to form fields updates.
  const renderAddForm = useCallback(() =>
      <FormWrapper classes={'grid p-fluid w-12'} resourceType={resourceType} formKeyPrefix={'add_pp_dialog'} cancelLink multiple context={context}
                   resetClass={'a8-form-company-pps-reset'} submitClass={'a8-form-company-pps-add'} onSubmit={onAddSubmit} hideReload />
    , [context?.dossierId]); // eslint-disable-line react-hooks/exhaustive-deps

  return (maritalStatuses || []).length === 0 || (matrimonialRegimes || []).length === 0 ? (<PageLoader />) : (
    <DatatableWrapper resourceType={resourceType} tableKey={tableKey || 'personnes-physiques-spouses'} noFilters noGlobalFilter params={params} isLoading={loading}
                      addTitle={trans(t, 'table|add.spouse')} onRowClick={onRowClick} editMode={editMode || false} hookRowEdit={hookRowEdit} onRowEditCallback={onRowEditCallback}
                      rows={(rows || []).sort((a: any, b: any) => a.currentSpouse || a.personnePhysique?.lastName?.localeCompare(b.personnePhysique?.lastName) || a.personnePhysique?.firstName?.localeCompare(b.personnePhysique?.firstName))}
                      onDelete={onDelete} onNew={onNew} title={title || ucfirst(trans(t, 'spouse', rows.length))} paginator={false}>
      <Column field={'personnePhysique.fullName'} header={trans(t, 'fullName')} body={fullNameBodyTemplate}
              style={{ minWidth: '250px', width: '250px' }} />
      <Column field={'personnePhysique.birthDate'} header={trans(t, 'birthDate')} align={'center'} alignHeader={'center'} body={birthDateBody}
              style={{ minWidth: '100px', width: '100px' }} />
      <Column field={'personnePhysique.socialSecurityNumber'} header={trans(t, 'socialSecurityNumber')} align={'center'} alignHeader={'center'} body={ssnBody} />
      <Column field={'currentSpouse'} header={trans(t, 'current')} body={currentBodyTemplate} align={'center'} alignHeader={'center'}
              editor={(options) => simpleCheckboxEditor(options, {label: trans(t, 'current')})}
              style={{ minWidth: '50px', width: '50px' }} />
      <Column field={'maritalStatus'} header={trans(t, 'maritalStatus')} body={maritalStatusBodyTemplate} align={'center'} alignHeader={'center'}
              editor={(options) => apiListEditor(options, {label: trans(t, 'maritalStatus'), listKey: 'maritalStatuses', showClear: true})}
              style={{ minWidth: '150px', width: '150px' }} />
      <Column field={'maritalDate'} header={trans(t, 'maritalDate')} align={'center'} alignHeader={'center'} body={maritalDateBody}
              editor={(options) => maskEditor(options, {label: trans(t, 'maritalDate'), isDate: true})}
              style={{ minWidth: '120px', width: '120px' }} />
      <Column field={'separatedDate'} header={trans(t, 'separatedDate')} align={'center'} alignHeader={'center'} body={separatedDateBody}
              editor={(options) => maskEditor(options, {label: trans(t, 'separatedDate'), isDate: true})}
              style={{ minWidth: '120px', width: '120px' }} />
      <Column field={'maritalRegime'} header={trans(t, 'matrimonialRegime')} body={matrimonialRegimeBodyTemplate} align={'center'} alignHeader={'center'}
              editor={(options) => apiListEditor(options, {label: trans(t, 'matrimonialRegime'), listKey: 'matrimonialRegimes', showClear: true})}
              style={{ minWidth: '250px', width: '250px', maxWidth: '250px' }} />
      {isAdmin() && <Column field={'active'} header={trans(t, 'active')} body={activeBodyTemplate} align={'center'} alignHeader={'center'}
                            editor={(options) => simpleCheckboxEditor(options, {label: trans(t, 'table|active')})}
                            style={{ minWidth: '100px', width: '100px' }} />}
    </DatatableWrapper>
  );
};
