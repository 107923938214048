import { trans, ucfirst } from 'utilities';

import appUri from 'config/appUri.json';

export const getExerciceFiscalEditContext = (t: any, appState: any, context: any) => {
  const { id, year, company, step } = context;
  const dossier: any = appState.dossier();

  let activeIndex: number = -1;
  const urls: any = {
    form: appUri.dos.edit.fiscalYear.uri.replace(':id', id || '_').replace(':year', year!).replace(':company', company),
    landing: appUri.dos.page.replace(':id', id || '_')
  };

  const defaultBC = [
    { label: trans(t, 'dossier', 2), to: appUri.dos.list },
    { label: dossier?.title, to: appUri.dos.page.replace(':id', dossier?.id) },
    { label: trans(t, 'editing') + ' | ' + ucfirst(trans(t, 'exerciceFiscal')), to: appUri.dos.edit.landing.replace(':id', dossier?.id) },
    { label:  ucfirst(trans(t, 'company')) + ' ' + appState.company()?.latestVersion?.raisonSociale, to: appUri.cie.cli.page.replace(':id', company) }
  ];

  switch (step) {
    default:
      activeIndex = 0;
      defaultBC.push({
        label: trans(t, 'menu|wizard.dossier.exerciceFiscal.step1'),
        to: urls.form
      });
      break;

    case 'donnees-fiscales':
      activeIndex = 1;
      defaultBC.push({
        label: trans(t, 'menu|wizard.dossier.exerciceFiscal.step2'),
        to: urls.form + appUri.dos.edit.fiscalYear.steps.step2
      });
      break;
    case 'donnees-sociales-collectives':
      activeIndex = 2;
      defaultBC.push({
        label: trans(t, 'menu|wizard.dossier.exerciceFiscal.step3'),
        to: urls.form + appUri.dos.edit.fiscalYear.steps.step3
      });
      break;
    case 'tresorerie-generale':
      activeIndex = 3;
      defaultBC.push({
        label: trans(t, 'menu|wizard.dossier.exerciceFiscal.step4'),
        to: urls.form + appUri.dos.edit.fiscalYear.steps.step4
      });
      break;
    case 'tresorerie-emprunt':
      activeIndex = 4;
      defaultBC.push({
        label: trans(t, 'menu|wizard.dossier.exerciceFiscal.step5'),
        to: urls.form + appUri.dos.edit.fiscalYear.steps.step4
      });
      break;
    case 'tresorerie-negative':
      activeIndex = 5;
      defaultBC.push({
        label: trans(t, 'menu|wizard.dossier.exerciceFiscal.step6'),
        to: urls.form + appUri.dos.edit.fiscalYear.steps.step4
      });
      break;
    case 'reserves':
      activeIndex = 6;
      defaultBC.push({
        label: trans(t, 'menu|wizard.dossier.exerciceFiscal.step7'),
        to: urls.form + appUri.dos.edit.fiscalYear.steps.step5
      });
      break;
  }

  return { urls, defaultBC, index: activeIndex };
};
