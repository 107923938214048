import React from 'react';
import { Nullable } from 'primereact/ts-helpers';
import { classNames } from 'primereact/utils';
import { InputMask } from 'primereact/inputmask';

import { BaseTextField } from './BaseTextField';
import { asDate, dateString, trans } from 'utilities';
import { useApim } from 'services';

export const MaskField = (props: any) => {
  const { fieldKey, mask, control, onFieldChange, errorMessage, rules, label, tooltip, tooltipOptions,
    classes, addon, addonPosition, format, disabled, isDate, textAddon, description } = props;

  const apim = useApim();
  const { t } = apim.di();

  const regex_pattern: string = '^(?:(?:31(\\/|-|\\.)(?:0?[13578]|1[02]))\\1|(?:(?:29|30)(\\/|-|\\.)(?:0?[13-9]|1[0-2])\\2))(?:(?:1[6-9]|[2-9]\\d)?\\d{2})$|^(?:29(\\/|-|\\.)0?2\\3(?:(?:(?:1[6-9]|[2-9]\\d)?(?:0[48]|[2468][048]|[13579][26])|(?:(?:16|[2468][048]|[3579][26])00))))$|^(?:0?[1-9]|1\\d|2[0-8])(\\/|-|\\.)(?:(?:0?[1-9])|(?:1[0-2]))\\4(?:(?:1[6-9]|[2-9]\\d)?\\d{2})$';
  const isValidInput = (text: Nullable<string>) => text ? text.match(regex_pattern) : false; // check the string with regex
  const isEmptyInput = (text: Nullable<string>) => text !== null && text !== undefined;

  const renderInput = (field: any, fieldState: any) => {
    const currentValue = (field.value && isDate && ['000Z', '00:00'].includes(field.value.substring(20))) ? dateString(field.value, mask === '99/9999' ? 'MM/YYYY' : undefined) : field.value ?? '';

    return (
      <span className={'p-float-label'}>
        <InputMask id={field.name} className={classNames('text-ucfirst w-full', {
          'p-invalid': fieldState.error || (isDate && (!isEmptyInput(currentValue) && !isValidInput(currentValue)))
        })} aria-invalid={fieldState.error}
                   value={currentValue} mask={mask || '99/99/9999'} disabled={disabled} autoClear={false}
                   onChange={(e) => {
                     if (!isDate) return onFieldChange(field, fieldState, e.target.value, format || 'removeSpaces');

                     const matches: false | RegExpMatchArray | null = isValidInput(e.target.value);
                     if (matches) {
                       const newDate: Date = asDate(matches[0], ['DD/MM/YYYY']);
                       newDate.setHours(newDate.getHours() + 12);

                       return onFieldChange(field, fieldState, newDate.toISOString(), format || 'removeSpaces');
                     }

                     return onFieldChange(field, fieldState, e.target.value, format || 'removeSpaces');
                   }}
                   tooltip={tooltip} tooltipOptions={tooltipOptions || {position: 'top'}}/>
        {(label || field.name) && (
          <label className={'text-ucfirst p-float-label-label'} htmlFor={field.name}>
            <span>{label ?? trans(t, field.name)}</span>
          </label>
        )}
      </span>
    );
  };

  return <BaseTextField fieldKey={fieldKey} control={control} errorMessage={errorMessage} rules={rules} classes={classes}
                        addon={addon} addonPosition={addonPosition} textAddon={textAddon} renderInput={renderInput} description={description} />
};
