import React, { useCallback } from 'react';

import { Button } from 'primereact/button';
import { Fieldset } from 'primereact/fieldset';

import { iri, trans, triggerFormSubmit, ucfirst } from 'utilities';
import { FormWrapper } from 'forms';

import appUri from 'config/appUri.json';

export const SocieteExpertsProfile = (props: any) => {
  const { company, apim } = props;
  const { t, navigate} = apim.di();
  const redirectUri = company?.id ? appUri.cie.exp.page.replace(':id', company?.id || '_') + '?maj=' + Date.now() : appUri.cie.exp.list;

  // Wrap form render into a useCallback to avoid multiple FormWrapper recalls du to form fields updates.
  const renderFormImmatriculation = useCallback(() =>
      <FormWrapper data={company} multiple formKeyPrefix={'immatriculation'} resourceType={'societesExperts'} classes={'grid p-fluid w-12'} cancelLink
                   redirectUri={redirectUri} notif={false} hideReload/>
    , [company?.id]); // eslint-disable-line react-hooks/exhaustive-deps

  const renderFormRepresentant = useCallback(() =>
      <FormWrapper data={company?.representantLegal} multiple resourceType={'societesExpertsRepresentantsLegaux'} classes={'grid p-fluid w-12'} cancelLink
                   redirectUri={redirectUri} additionalData={{ societeExpert: iri('societesExperts', company?.id) }} hideReload/>
    , [company?.id, company?.representantLegal?.id]); // eslint-disable-line react-hooks/exhaustive-deps

  const renderFormAddress = useCallback(() =>
      <Fieldset legend={ucfirst(trans(t, 'address'))} toggleable>
        <FormWrapper data={company?.address} multiple formKeyPrefix={'address'} resourceType={'societesExpertsAdresses'} classes={'grid p-fluid w-12'} cancelLink
                     redirectUri={redirectUri} additionalData={{ societeExpert: iri('societesExperts', company?.id) }} notif={false} hideReload/>
      </Fieldset>
    , [company?.id, company?.address?.id]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div className={'a8-form societe-experts'}>
        {renderFormImmatriculation()}
        {company?.id && renderFormRepresentant()}
        {company?.id && renderFormAddress()}

        <div className={'text-right m-2 mt-4'}>
          <Button type={'reset'} className={'text-ucfirst p-button-text p-button-danger mr-5'} label={trans(t, 'close')} onClick={() => navigate(redirectUri)} />
          <Button type={'submit'} className={'text-ucfirst'} label={trans(t, 'save')} icon={'pi pi-check'} iconPos={'right'} onClick={() => triggerFormSubmit(null, () => navigate(redirectUri))} />
        </div>
    </div>
  );
};
