import React, { useCallback, useState }from 'react';
import { AxiosError, AxiosResponse } from 'axios';
import { Link, useSearchParams } from 'react-router-dom';
import { Message } from 'primereact/message';

import { getErrorMessage, isValidUUID, onApiError, trans } from 'utilities';
import { NotFound } from 'pages';
import { IRequestParams, isAdmin, isExpertAdmin } from 'services';
import { FormWrapper } from 'forms';
import { PageLoader } from 'components';

import appUri from 'config/appUri.json';

export const SocieteClientAddForm = (props: any) => {
  const { apim } = props;
  const { t, navigate } = apim.di();
  const redirectUri: string = appUri.cie.cli.list;
  const [searchParams] = useSearchParams();
  const destination = searchParams.get('destination');

  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string|null>(null);
  const [success, setSuccess] = useState<any>(null);
  const [existingCompany, setExistingCompany] = useState<any>(null);
  const [newCompany, setNewCompany] = useState<any>(null);

  // Handle submission.
  const submit = (formData: any) => {
    setLoading(true);
    setError(null);
    setSuccess(null);
    setExistingCompany(null);
    setNewCompany(null);

    apim.fetchEntities({
      resourceType: 'dossierCompanies',
      notif: false,
      params: [{label: 'siren', value: formData.siren}],
      success: (res: AxiosResponse) => {
        // The company already exists.
        if (res?.data && (res.data['hydra:member'] || []).length > 0) {
          setLoading(false);
          setError(trans(t, 'form|validation.company.create.existing'));
          setExistingCompany(res.data['hydra:member'][0]);
        }

        // The company does not exist.
        // Check on Pappers if we find by siren.
        apim.call({
          resourceType: 'companies',
          action: 'autocomplete',
          method: 'get',
          params: [{ label: 'siren', value: formData.siren }],
          notif: false,
          success: (res: AxiosResponse) => {
            if (res?.data && (res.data['hydra:member'] || []).length > 0) {
              // The company exists on Pappers.
              // Create the company based on Pappers information.
              apim.call({
                resourceType: 'companies',
                action: 'create',
                data: { siren: formData.siren },
                notif: false,
                success: (res: AxiosResponse) => {
                  if (isValidUUID(res?.data?.id)) {
                    setLoading(false);
                    setSuccess(trans(t, 'form|confirmation.company.created.fromPappers'));
                    setNewCompany(res?.data);
                  }
                },
                error: (error: AxiosError) => {
                  onApiError(t, error, apim.toast('error'), getErrorMessage(t, error));
                  setLoading(false);
                },
              } as IRequestParams).then();

            } else {
              // The company does not exist on Pappers.
              // Create a skeleton with just siren & update raison sociale.
              apim.postEntity({
                resourceType: 'dossierCompanies',
                data: formData,
                notif: false,
                success: (res: AxiosResponse) => {
                  if (isValidUUID(res?.data?.id)) {
                    setLoading(false);
                    setSuccess(trans(t, 'form|confirmation.company.created.fromScratch'));
                    setNewCompany(res?.data);
                  }
                },
                error: (error: AxiosError) => {
                  onApiError(t, error, apim.toast('error'), getErrorMessage(t, error));
                  setLoading(false);
                },
              } as IRequestParams).then();
            }
          },
          error: (error: AxiosError) => {
            onApiError(t, error, apim.toast('error'), getErrorMessage(t, error));
            setLoading(false);
          },
        } as IRequestParams).then();
      },
      error: (error: AxiosError) => {
        onApiError(t, error, apim.toast('error'), getErrorMessage(t, error));
        setLoading(false);
      },
    } as IRequestParams).then();
  }

  // Wrap form render into a useCallback to avoid multiple FormWrapper recalls du to form fields updates.
  const renderAddForm = useCallback(() =>
      <FormWrapper classes={'grid w-12'} resourceType={'dossierCompanies'} formKeyPrefix={'add'} cancelLink redirectUri={redirectUri}
                   resetClass={'a8-form-company-reset'} submitClass={'a8-form-company-add'} onSubmit={submit} hideReload />
    , []); // eslint-disable-line react-hooks/exhaustive-deps

  // Wrap error message in a callback to refresh when error & existing company changes.
  const renderErrorMessage = useCallback(() => {
    if (error === null) return null;

    let errorMessage: any = <>
      <span>{error}</span>
      {isValidUUID(existingCompany.id) && (
        <Link to={appUri.cie.cli.page.replace(':id', existingCompany.id)}>{trans(t, 'seeDetailsCompany')}</Link>
      )}
    </>;

    return <Message severity={'error'} text={errorMessage} className={'mr-3 mb-5'}/>;
  } , [error, existingCompany]); // eslint-disable-line react-hooks/exhaustive-deps

  // Wrap success message in a callback to refresh when success & new company changes.
  const renderSuccessMessage = useCallback(() => {
    if (success === null) return null;

    // Auto-redirect if URI detected.
    if (destination && ['/', 'h'].includes(destination?.substring(0, 1))) return navigate(destination);

    let successMessage: any = <>
      <span>{success}</span>
      {isValidUUID(newCompany.id) && (
        <Link to={appUri.cie.cli.page.replace(':id', newCompany.id)}>{trans(t, 'seeDetailsCompany')}</Link>
      )}
    </>;

    return <Message severity={'success'} text={successMessage} className={'mr-3 mb-5'}/>;
  } , [destination, success, newCompany]); // eslint-disable-line react-hooks/exhaustive-deps

  if (!(isAdmin() || isExpertAdmin())) return <NotFound asBlock/>;

  return (
    <div className={'card height-100 fadein animation-duration-500 flex flex-column'}>
      <div className={'mx-3'}>
        <h5 className={'pt-3 flex flex-row align-items-center'}>
          {trans(t, 'form|title.company.create')}
        </h5>

        <p>
          {trans(t, 'form|text.company.intro1')}<br/>
          {trans(t, 'form|text.company.intro2')}
        </p>

        <div style={{width: '400px'}} className={'flex flex-column'}>
          {loading ? (
            <PageLoader />
          ) : (
            <>
              {renderSuccessMessage()}
              {renderErrorMessage()}
              {renderAddForm()}
            </>
          )}
        </div>
      </div>
    </div>
  );
};
