import { hookstate, useHookstate, State } from '@hookstate/core';

interface ISearchState {
  toggle: boolean;
  needle: null | string;
}

const emptyState: ISearchState = {
  toggle: false,
  needle: null
} as ISearchState;
const state: State<ISearchState> = hookstate(Object.assign({}, emptyState));
const wrapper = (s: State<ISearchState>) => ({
  isToggle: () => s.toggle.get(),
  toggle: () => s.toggle.set(!s.toggle.get()),
  getTerm: () => s.needle.get(),
  setTerm: (val: string) => s.needle.set(val)
})

export const useSearchState = () => wrapper(useHookstate(state));
