import React, { useCallback } from 'react';

import { PageLoader } from 'components';
import { FormWrapper } from 'forms';

import { replace } from 'lodash';
import appUri from 'config/appUri.json';

export const DossierSettings = (props: any) => {
  const { data } = props;

  // Wrap form render into a useCallback to avoid multiple FormWrapper recalls du to form fields updates.
  const renderForm = useCallback(() =>
      <FormWrapper classes={'grid p-fluid w-12'} formKeyPrefix={'dossier_settings'} resourceType={'dossiers'} cancelLink
                   redirectUri={replace(appUri.dos.page, ':id', data?.id || '_') + '?maj=' + Date.now()} data={data} submitClass={'a8-form-submit pr-6'}/>
    , [data?.id]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div className={'a8-form dossier'}>
      {data ? (
        renderForm()
      ) : (
        <PageLoader />
      )}
    </div>
  );
};
