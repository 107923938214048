import React from 'react';

import { NotificationReceiver, NotificationsList, NotificationsSentList } from 'pages';
import { trimStart } from 'lodash';

import appUri from 'config/appUri.json';

export const RoutesNotification = () => {
  return [
    {
      path: trimStart(appUri.not.list),
      element: <NotificationsList />,
    },
    {
      path: trimStart(appUri.not.sent.list),
      element: <NotificationsSentList />,
    },
    {
      path: trimStart(appUri.not.handler),
      element: <NotificationReceiver />,
    },
  ];
};
