import React, { useMemo } from 'react';
import { Button } from 'primereact/button';
import { Fieldset } from 'primereact/fieldset';

import { iri, isValidUUID, trans, triggerFormSubmit, ucfirst } from 'utilities';
import { FormWrapper } from 'forms';
import { DonationBeneficiairesDatatable } from 'components';

import appUri from 'config/appUri.json';

export const Step2 = (props: any) => {
  const { data, apim, appState, context, dirigeant } = props;
  const { t, navigate} = apim.di();

  const redirectUri: string = appUri.dos.edit.patrimoine.donations.landing.replace(':id', appState?.dossier()?.id);
  const listsOptions: any = {personnesPhysiques: []};
  listsOptions.personnesPhysiques.push({label: ucfirst(trans(t, 'dirigeant')), '@id': iri('personnesPhysiques', dirigeant?.id)});
  if (dirigeant?.spouses?.length > 0 && isValidUUID(dirigeant.spouses[0].spouse?.id)) {
    listsOptions.personnesPhysiques.push({label: ucfirst(trans(t, 'conjoint')), '@id': iri('personnesPhysiques', dirigeant.spouses[0].spouse.id)});
  }

  const renderForm = useMemo(() =>
      <FormWrapper multiple formKeyPrefix={'donation_informations'} resourceType={'donations'} classes={'grid p-fluid w-12'} cancelLink
                   hideReload context={{...context, ...{dirigeant: dirigeant}}} data={data} withRedirect redirectUri={redirectUri} listsOptions={listsOptions}/>
    , [data?.id, context?.id]); // eslint-disable-line react-hooks/exhaustive-deps
  const renderFormNote = useMemo(() =>
      <FormWrapper multiple formKeyPrefix={'zone_note'} resourceType={'donations'} classes={'grid p-fluid w-12'} hideReload data={data} notif={false}/>
    , [data?.id, context?.id]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div className={'a8-wizard wizard-patrimoine-donation fadein animation-duration-500'}>
      <h5 className={'mx-3 pt-3 flex flex-row align-items-center'}>
        <span className={'mr-2 border-circle bg-primary flex align-items-center justify-content-center'} style={{width: '2rem', height: '2rem'}}><i className={'pi pi-info'}></i></span>
        {trans(t, 'menu|wizard.patrimoine.donations.default.step2')}
      </h5>

      {renderForm}

      <Fieldset legend={ucfirst(trans(t, 'beneficiary', 2))} className={'mt-4'} toggleable>
        <DonationBeneficiairesDatatable apim={apim} data={data} editMode context={{...context, ...{dirigeant: dirigeant}}} title={''}/>
      </Fieldset>

      {renderFormNote}

      <div className={'text-right m-2 pt-4'}>
        <Button type={'reset'} className={'text-ucfirst p-button-text p-button-danger mr-5'} label={trans(t, 'close')} onClick={() => navigate(redirectUri)}/>
        <Button type={'submit'} className={'text-ucfirst'} label={trans(t, 'terminate')} icon={'pi pi-check'} onClick={() => triggerFormSubmit(null)}/>
      </div>
    </div>
  );
};
