import React, { useCallback, useEffect, useState } from 'react';
import { AxiosResponse } from 'axios';

import { Button } from 'primereact/button';
import { Column } from 'primereact/column';
import { Divider } from 'primereact/divider';
import { Tooltip } from 'primereact/tooltip';

import { IRequestParams, isAdmin } from 'services';
import { amountCell, amountEditor, apiListEditor, PageLoader, textEditor, validCell, apiListCell, DatatableWrapper, simpleCheckboxEditor } from 'components';
import { FormWrapper } from 'forms';
import { iri, isValidUUID, trans, triggerFormSubmit, ucfirst } from 'utilities';
import { Error, NotFound } from 'pages';

import { forEach } from 'lodash';
import appUri from 'config/appUri.json';

export const Contrats = (props: any) => {
  const { appState, apim, urls, additionalData } = props;
  const { t, navigate } = apim.di();

  const [loading, setLoading] = useState<boolean>(false);
  const [notFound, setNotFound] = useState<boolean>(false);
  const [errored, setErrored] = useState<boolean>(false);

  const dossier = appState.dossier();
  const year = appState.civilYear();

  const [dossierSocialData, setDossierSocialData] = useState<any>(null);
  const [keyTmp, setKeyTmp] = useState<string>('');
  const [fiscalRegimes, setFiscalRegimes] = useState<any[]>([]);
  const [dossierContractStatuses, setDossierContractStatuses] = useState<any[]>([]);
  const cancelUri: string = urls?.landing + '/entreprise/donnees-sociales-dirigeant?maj=' + Date.now();

  // Load fiscal regimes and contract statuses lists.
  useEffect(() => {
    apim.getList('dossierFiscalRegimes', {setter: setFiscalRegimes, setLoading: setLoading} as IRequestParams).then();
    apim.getList('dossierContractStatuses', {setter: setDossierContractStatuses, setLoading: setLoading} as IRequestParams).then();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  // Load the dossier social data with contracts for currently selected civil year.
  useEffect(() => {
    if (!dossier?.id) return;

    setLoading(true);
    apim.fetchEntities({
      resourceType: 'dossierSocialData',
      params: [
        {label: 'dossier', value: dossier?.id},
        {label: 'year', value: year},
        {label: 'expand[]', value: 'dossier_social_data:read_contracts'},
        {label: 'expand[]', value: 'dossier_social_contract:read_list'},
      ],
      setLoading: setLoading,
      setErrored: setErrored,
      setNotFound: setNotFound,
      cache: false,
      success: (res: AxiosResponse) => {
        if (res?.data && (res?.data['hydra:member'] || []).length > 0) {
          setKeyTmp('_' + dossier?.id + '_' + year);
          setDossierSocialData(res.data['hydra:member'][0]);
        } else {
          setDossierSocialData(null);
          setKeyTmp('');
        }
      }
    } as IRequestParams).then();
  }, [dossier?.id, year]); // eslint-disable-line react-hooks/exhaustive-deps

  // Wrap form render into a useCallback to avoid multiple FormWrapper recalls du to form fields updates.
  const renderForm = useCallback(() => {
      if (!dossierContractStatuses) return;
      return <FormWrapper classes={'grid p-fluid w-12'} formKeyPrefix={'contrats'} resourceType={'dossierSocialData'}
                   keyTmp={keyTmp} data={dossierSocialData} additionalData={additionalData} hideReload cancelLink
                   redirectUri={cancelUri} multiple listsOptions={{ dossierContractStatuses: dossierContractStatuses}} />
  }, [dossierSocialData?.id, keyTmp, dossierContractStatuses]); // eslint-disable-line react-hooks/exhaustive-deps

  const formatContracts = (contracts: any[]) => {
    // Add an index to the contrats.
    let _contrats: any[] = [];
    forEach(contracts || [], (contrat: any, index: number) =>
      _contrats.push({...contrat, index: index})
    );

    return _contrats;
  };

  const activeBodyTemplate = (rowData: any) => validCell(rowData?.active);
  const cotisationPriseEnChargeParEntrepriseBodyTemplate = (rowData: any) => validCell(rowData?.cotisationPriseEnChargeParEntreprise);
  const montantCotisationBodyTemplate = (rowData: any) => amountCell(rowData.montantCotisation);
  const chargesSocialesBodyTemplate = (rowData: any) => amountCell(rowData.chargesSociales);
  const fiscalRegimeBodyTemplate = (rowData: any) => apiListCell(rowData.fiscalRegime, fiscalRegimes);

  if (!dossier?.id || loading) return <PageLoader/>;
  if (notFound) return <NotFound asBlock/>;
  if (errored) return <Error asBlock/>;

  /**
   * Load contracts from the previous year.
   */
  const prefillContracts = () => {
    apim.postEntity({
      resourceType: 'dossierSocialDataPrepareContracts',
      data: {
        dossier: iri('dossiers', dossier?.id),
        year: parseInt(year),
      },
      notif: false,
      success: (res: AxiosResponse) => {
        if (res?.data) {
          setKeyTmp('_' + dossier?.id + '_' + year);
          setDossierSocialData(res.data);
        }
      }
    } as IRequestParams).then();
  }

  /**
   * Load contracts from the previous year button template.
   */
  const prefillBtnTemplate = () => {
    if (!isValidUUID(dossierSocialData?.id)
      || (dossierSocialData?.contracts === null || dossierSocialData?.contracts === undefined || dossierSocialData?.contracts?.length === 0)) {
      return <>
        <Button type={'button'} className={'a8-dossier-social-contracts-prefill-btn'} onClick={prefillContracts} icon={'pi pi-history'} aria-label={ucfirst(trans(t, 'loadPreviousYearInformation'))}/>
        <Tooltip target={'.a8-dossier-social-contracts-prefill-btn'} position={'left'} content={ucfirst(trans(t, 'loadPreviousYearInformation'))}/>
      </>;
    }

    return null;
  }

  return (
    <div className={'flex flex-column xl:flex-row flex-wrap fadein animation-duration-500'}>
      {renderForm()}

      <Divider/>

      <h5 className={'px-3'}>{trans(t, 'form|dossier.contractList')}</h5>
      <div className={'grid p-fluid mt-3 w-full'}>
        <div className={'col-12'}>
          <DatatableWrapper resourceType={'dossierSocialContract'} tableKey={'contracts'} editMode formatter={formatContracts} emptyMessage={trans(t, 'contract', 0)}
                            noFilters noGlobalFilter additionalData={{ socialData: iri('dossierSocialData', dossierSocialData?.id) }} paginator={false} headerCreateBtn={prefillBtnTemplate}
                            // keep this fallback on dossier UUID on purpose to avoid APIM error if !valid UUID provided...
                            params={isAdmin() ? [{label: 'socialData', value: dossierSocialData?.id ?? dossier?.id}] : [{label: 'socialData', value: dossierSocialData?.id ?? dossier?.id}, {label: 'active', value: true}]}>
            <Column field={'nomCompagnie'} header={trans(t, 'table|contrat.nomCompagnie')} editor={(options) => textEditor(options, {label: trans(t, 'table|contrat.nomCompagnie')})} />
            <Column field={'nomContrat'} header={trans(t, 'table|contrat.nomContrat')} editor={(options) => textEditor(options, {label: trans(t, 'table|contrat.nomContrat')})} />
            <Column field={'fiscalRegime'} header={trans(t, 'table|contrat.fiscalRegime')} body={fiscalRegimeBodyTemplate}
                    editor={(options) => apiListEditor(options, {label: trans(t, 'table|contrat.fiscalRegime'), listKey: 'dossierFiscalRegimes'})} />
            <Column field={'cotisationPriseEnChargeParEntreprise'} header={trans(t, 'table|contrat.cotisationPriseEnChargeParEntreprise')} dataType={'boolean'} align={'center'} body={cotisationPriseEnChargeParEntrepriseBodyTemplate}
                    editor={(options) => simpleCheckboxEditor(options, {label: null})} style={{ width: '250px' }} />
            <Column field={'montantCotisation'} header={trans(t, 'table|contrat.montantCotisation')} body={montantCotisationBodyTemplate} editor={(options) => amountEditor(options, {label: trans(t, 'table|contrat.montantCotisation')})} />
            <Column field={'chargesSociales'} header={trans(t, 'table|contrat.chargesSociales')} body={chargesSocialesBodyTemplate} editor={(options) => amountEditor(options, {label: trans(t, 'table|contrat.chargesSociales')})} />
            {isAdmin() ? <Column field={'active'} header={trans(t, 'active')} dataType={'boolean'} align={'center'} body={activeBodyTemplate} editor={(options) => simpleCheckboxEditor(options, {label: null})} style={{ width: '100px' }}  /> : ''}
          </DatatableWrapper>
        </div>
      </div>

      <div className={'w-full text-right m-2 mr-3 flex justify-content-end'}>
        <>
          <Button type={'reset'} className={'text-ucfirst p-button-text p-button-danger mr-5'} label={trans(t, 'close')} onClick={() => navigate(cancelUri)}/>
          <span className={'p-buttonset'}>
            <Button type={'submit'} className={'text-ucfirst p-button-text'} label={trans(t, 'back')} icon={'pi pi-arrow-left'} onClick={() => navigate(urls?.form + appUri.dos.edit.civilYear.steps.step4)}/>
            <Button type={'submit'} className={'text-ucfirst'} label={trans(t, 'terminate')} icon={'pi pi-check'} onClick={() => triggerFormSubmit(null)}/>
          </span>
        </>
      </div>
    </div>
  );
};
