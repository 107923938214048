import React from 'react';
import { AxiosResponse } from 'axios';

import { Fieldset } from 'primereact/fieldset';
import { Column } from 'primereact/column';

import { iri, montant, trans, ucfirst } from 'utilities';
import { CheckboxField, NumberField } from 'forms';
import { amountEditor, DatatableWrapper, numberEditor } from 'components';
import { IRequestParams } from 'services';

export const CPDecesForm = (props: any) => {
  const { t, control, onFieldChange, errorMessage, getValues, context, apim } = props;
  const { decesRenteEducation, type } = getValues();
  const amountBody = (rowData: any) => <span>{montant(rowData?.amount ?? 0)}</span>;

  const startBody = (rowData: any) => {
    if (rowData?.startYear === undefined || rowData?.startYear === null) {
      return 'N/A';
    }

    if (rowData?.startYear === 0) {
      return ucfirst(trans(t, 'birth'));
    }

    return <span>{rowData?.startYear + ' ' + trans(t, 'year', rowData?.startYear && rowData?.startYear > 0 ? rowData?.startYear : 1)}</span>;
  };

  const endBody = (rowData: any) => {
    if (rowData?.endYear === undefined || rowData?.endYear === null) {
      return 'N/A';
    }

    return <span>{rowData?.endYear + ' ' + trans(t, 'year', rowData?.endYear ?? 1)}</span>;
  };

  const resourceType: string = 'prevoyanceEducationAnnuities';
  const onNewRow = (_rows: any[], callback: any) => {
    if (_rows.length === 0) {
      apim.postEntity({
        resourceType: resourceType,
        data: {
          startDay: 0,
          endYear: 11,
          amount: 1500,
          contractData: iri('prevoyanceContractsData', context?.data?.id)
        },
        success: (res: AxiosResponse) => callback([res.data])
      } as IRequestParams).then();

      return;
    }

    apim.postEntity({
      resourceType: resourceType,
      data: {
        startYear: parseInt(_rows[_rows.length - 1].endYear) + 1,
        endYear: parseInt(_rows[_rows.length - 1].endYear) + 3,
        amount: _rows[_rows.length - 1].amount,
        contractData: iri('prevoyanceContractsData', context?.data?.id)
      },
      success: (res: AxiosResponse) => callback([..._rows, ...[res.data]])
    } as IRequestParams).then();
  };

  return <>
    <div className={'grid w-full px-3'}>
      <div className={'col-3'}>
        <NumberField classes={'w-full'} fieldKey={'decesTouteCauseCapital'} mode={'currency'}
                     addon={'euro'} addonPosition={'after'} control={control} onFieldChange={onFieldChange} maxFractionDigits={0}
                     errorMessage={errorMessage} label={trans(t, 'form|prevoyance_contrat.decesTouteCauseCapital')}/>
      </div>

      <div className={'col-3'}>
        <NumberField classes={'w-full'} fieldKey={'decesAccidentelCapital'} mode={'currency'}
                     addon={'euro'} addonPosition={'after'} control={control} onFieldChange={onFieldChange} maxFractionDigits={0}
                     errorMessage={errorMessage} label={trans(t, 'form|prevoyance_contrat.decesAccidentelCapital')}/>
      </div>

      {type !== 'homme_cle' && (
        <div className={'col-3'}>
          <NumberField classes={'w-full'} fieldKey={'decesDoubleEffet'} mode={'currency'}
                       addon={'euro'} addonPosition={'after'} control={control} onFieldChange={onFieldChange} maxFractionDigits={0}
                       errorMessage={errorMessage} label={trans(t, 'form|prevoyance_contrat.decesDoubleEffet')}/>
        </div>
      )}

      {type !== 'homme_cle' && (
        <div className={'col-3'}>
          <NumberField classes={'w-full'} fieldKey={'decesFraisObseques'} mode={'currency'}
                       addon={'euro'} addonPosition={'after'} control={control} onFieldChange={onFieldChange} maxFractionDigits={0}
                       errorMessage={errorMessage} label={trans(t, 'form|prevoyance_contrat.decesFraisObseques')}/>
        </div>
      )}

      <div className={'col-3'}>
        <NumberField classes={'w-full'} fieldKey={'decesRenteConjointAnnuelle'} mode={'currency'}
                     addon={'euro'} addonPosition={'after'} control={control} onFieldChange={onFieldChange} maxFractionDigits={0}
                     errorMessage={errorMessage} label={trans(t, 'form|prevoyance_contrat.decesRenteConjointAnnuelle')}/>
      </div>
    </div>

    <div className={'grid w-full px-3'}>
      <div className={'col-3'}>
        <CheckboxField classes={'w-full'} fieldKey={'decesRenteEducation'} control={control} onFieldChange={onFieldChange} errorMessage={errorMessage} label={trans(t, 'form|prevoyance_contrat.decesRenteEducation')}/>
      </div>

      {true === decesRenteEducation && (
        <Fieldset className={'col-12'}>
          <DatatableWrapper
            resourceType={resourceType} tableKey={'prevoyance-tableau-annuites'} noFilters noGlobalFilter
            additionalData={{ contractData: iri('prevoyanceContractsData', context?.data?.id) }}
            onNew={onNewRow} params={[{label: 'contractData', value: context?.data?.id}]}
            title={trans(t, 'table|prevoyanceEducationAnnuitiesTitle')} editMode paginator={false} parentClasses={[]}>
            <Column field={'startYear'} header={trans(t, 'table|prevoyance_age_start')} align={'center'} alignHeader={'center'} body={startBody}
                    editor={(options) => numberEditor(options, {label: trans(t, 'table|prevoyance_age_start'), suffix: ' ' + trans(t, 'year', 2)})}/>
            <Column field={'endYear'} header={trans(t, 'table|prevoyance_age_end')} align={'center'} alignHeader={'center'} body={endBody}
                    editor={(options) => numberEditor(options, {label: trans(t, 'table|prevoyance_age_end'), suffix: ' ' + trans(t, 'year', 2)})}/>
            <Column field={'amount'} header={trans(t, 'table|prevoyance_age')} align={'center'} alignHeader={'center'} body={amountBody}
                    editor={(options) => amountEditor(options, {label: trans(t, 'table|prevoyance_age')})}/>
          </DatatableWrapper>
        </Fieldset>
      )}
    </div>
  </>;
};
