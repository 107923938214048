import React, { useEffect, useRef, useState } from 'react';
import { AxiosResponse } from 'axios';
import { useSearchParams } from 'react-router-dom';

import { Button } from 'primereact/button';
import { Column } from 'primereact/column';
import { ColumnGroup } from 'primereact/columngroup';
import { Row } from 'primereact/row';
import { DataTable } from 'primereact/datatable';
import { FilterMatchMode } from 'primereact/api';
import { InputText } from 'primereact/inputtext';
import { Tooltip } from 'primereact/tooltip';
import { classNames } from 'primereact/utils';

import { dialog, isValidUUID, trans, ucfirst } from 'utilities';
import { IRequestParam, IRequestParams, useApim } from 'services';
import { amountCell } from 'components';

import { forEach, isEmpty, omit, pick, isArray } from 'lodash';
import appConfig from 'config/appConfig.json';

export const DatatableWrapper = (props: any) => {
  const {
    resourceType,           // ApiConfig.json mapping
    tableKey,               // Table identifier

    title,                  // (optional) Title
    params,                 // (optional) Default params override
    lazyConfig,             // (optional) Default LazyConfig override
    paginator,              // (optional) Display pagination
    isLoading,              // (optional) the loading state will compute local loading & this given param
    additionalData,         // (optional) Additional data to be used on POST action (create only, not triggered on PATCH)
    editMode,               // (optional) Can we allow to edit rows directly ?
    editFields,             // (optional) Only care about these fields during edit (PATCH)
    noAdd,                  // (optional) Do not provide the "Add" btn into header even if editMode
    noEdit,                 // (optional) Do not provide the "Edit" pencil btn on each row even if editMode
    noRemove,               // (optional) Do not provide the "Remove" btn on each row even if editMode
    rows,                   // (optional) Initial data (prevents initial fetch)
    newRowTemplate,         // (optional) Data model used by EditMode when adding a new row
    rowUri,                 // (optional) URI-pattern used by rows onClick event
    noFilters,              // (optional) Do not display any filters (except the global search)
    noGlobalFilter,         // (optional) Do not display the global search
    formatter,              // (optional) A formatter to be used by each row parsing
    onRowClick,             // (optional) onRowClick event
    additionalClassNames,   // (optional)
    onNew,                  // (optional) replace onNew event by custom logic ; can re-call a callback :)
    autoEditOnNewRow,       // (optional) auto-edit row on insert
    onDelete,               // (optional) replace onDelete event by custom callback
    onRefresh,              // (optional) onRefresh event
    onSubmit,               // (optional) replace default submit process using given custom event
    stopOnRefresh,          // (optional) return after a refresh instead of just call the callback
    hookRowEdit,            // (optional) formatter / hook to use during row edit process
    onRowEditCallback,      // (optional) callback to call after a row edit event
    refresher,              // (optional) a stated variable used to trigger the DT refresh - @deprecated : try to update rows instead
    disabledItems,          // (optional) a list of default disabled rows
    headerCreateBtn,        // (optional) headers create button
    parentClasses,          // (optional) datatable parent div classes
    emptyMessage,           // (optional) message to display when no entries
    addTitle,               // (optional) title to use on "add" btn (if any)
    selectionMode,          // (optional) by default, row clicks also trigger selection, set selectionMode of DataTable to checkbox to only trigger selection using the checkboxes
    selection,              // (optional) array of selected elements
    isDataSelectable,       // (optional) certain rows can be excluded from selection if isDataSelectable returns false
    onSelectionChange,      // (optional) onSelectionChange event
    expandedRows,           // (optional) expanded rows data
    reorderableRows,        // (optional) @see reorderableRows PrimeReact documentation
    onRowReorder,           // (optional) @see reorderableRows PrimeReact documentation
    headerColumnGroup,      // (optional) @see headerColumnGroup PrimeReact documentation
    onRowToggle,            // (optional) onRowToggle event
    rowExpansionTemplate,   // (optional) expanded rows template
    selectOnEdit,           // (optional) set to false to avoid cell edit on double click (default value = true)
    requestHeader,          // (optional) header added on edition, creation and deletion requests
    footer                  //(optional) add a footer to the tables (total, count or label), when defined in the datatable file
  } = props;

  const apim = useApim();
  const { t, navigate } = apim.di();
  const dt = useRef<any>(null);
  const filtersEnabled = (!noFilters || false === noFilters);

  const [editingRows, setEditingRows] = useState<any[]>([]);
  const addNewRow = useRef(false);

  let globalSearchTimer: ReturnType<typeof setTimeout>;
  const [loading, setLoading] = useState<boolean>(false);
  const [data, setData] = useState<any[]>(rows);
  const [totalRecords, setTotalRecords] = useState<number>(0);
  const [searchParams, setSearchParams] = useSearchParams();

  const iFilters = lazyConfig?.filters && lazyConfig?.filters?.global ? lazyConfig?.filters : { ...lazyConfig?.filters, ...{ global: { value: searchParams.has('search') ? searchParams.get('search') : '', matchMode: FilterMatchMode.CONTAINS } } };
  const iLazyState = {
    ...{
      first: searchParams.has('page') ? parseInt(searchParams.get('page') as string) * (searchParams.has('perPage') ? parseInt(searchParams.get('perPage') as string) : appConfig.pagination.default.itemsPerPage) : 0,
      rows: searchParams.has('perPage') ? parseInt(searchParams.get('perPage') as string) : appConfig.pagination.default.itemsPerPage,
      page: searchParams.has('page') ? parseInt(searchParams.get('page') as string) : 0,
      sortField: searchParams.has('sortField') ? searchParams.get('sortField') : (reorderableRows ? 'rank' : 'id'),
      sortOrder: searchParams.has('sortOrder') ? (searchParams.get('sortOrder') === 'ASC' ? 1 : -1) : 1,
    }, ...lazyConfig, ...{ filters: iFilters }
  }
  const [lazyState, setlazyState] = useState<any>(iLazyState);

  // Refresher update (@deprecated).
  const [lastRefresh, setLastRefresh] = useState<string>(refresher || Date.now());
  useEffect(() => {
    setLastRefresh(refresher);
  }, [refresher]); // eslint-disable-line react-hooks/exhaustive-deps

  // Lazy load data from API.
  useEffect(() => {
    if (rows) return setData(rows);

    loadLazyData();
  }, [lazyState, disabledItems, rows, params]); // eslint-disable-line react-hooks/exhaustive-deps

  const createFooterContent = (
    rows: any[],
    footerConfig: Array<{
      field?: string;
      calculation?: 'sum' | 'count' | 'multiply' | 'none';
      label?: string;
    }>,
    t: any
  ) => {
    const results: { [key: string]: { label: string; value: number | null; calculation?: 'sum' | 'count' | 'multiply' | 'none' } } = {};

    if (isArray(footerConfig)) {
      footerConfig.forEach(({ field, calculation, label }) => {
        if (!field) {
          results['__label__'] = { label: label || '', value: null };
          return;
        }

        let result: number | null = null;
        if (calculation === 'sum') {
          result = rows.length === 0
            ? 0
            : rows.reduce((sum: number, row: any) => sum + (row[field] || 0), 0);
        } else if (calculation === 'count') {
          result = rows.filter((row: any) => row[field] !== undefined && row[field] !== null).length;
        } else if (calculation === 'multiply') {
          result = rows.length === 0
            ? 0
            : rows.reduce((product: number, row: any) => product * (row[field] || 1), 1);
        }
        results[field] = { label: label || ucfirst(trans(t, field)), value: result, calculation };
      });
    }

    return results;
  };

  const loadLazyData = () => {
    setLoading(true);

    const finalParams: IRequestParam[] = [
      { label: 'page', value: lazyState.page + 1 },
      { label: 'itemsPerPage', value: lazyState.rows },
      { label: 'order[' + lazyState.sortField + ']', value: lazyState.sortOrder === -1 ? 'desc' : 'asc' }
    ];

    params?.map((param: IRequestParam) => {
      return finalParams.push(param);
    });

    if (lazyState.filters.global && !isEmpty(lazyState.filters.global.value)) {
      finalParams.push({ label: 'search', value: lazyState.filters.global.value });
    }

    const p = {
      resourceType: resourceType,
      params: finalParams,
      cache: !editMode, // avoid cache hit just after a row edit
      notif: false,
      setLoading: setLoading,
      success: (res: AxiosResponse) => {
        if (res?.data) {
          setTotalRecords(res.data['hydra:totalItems'] || res.data['total']);
          const fetchedData = formatter ? (formatter(res.data['hydra:member'])) : (res.data['hydra:member']);
          setData(fetchedData);
          if (onRefresh) {
            onRefresh(fetchedData);
          }
        }
      },
    } as IRequestParams;

    // If we use 'then' here instead of 'success.callback' we lose cache hit.
    apim.fetchEntities(p).then();
  };

  const onPage = (event: any) => {
    event.filters = lazyState.filters;
    setlazyState({ ...lazyState, ...event });

    // Add the page parameter to the URL.
    let _searchParams = new URLSearchParams(searchParams);
    _searchParams.set('page', event.page);
    _searchParams.set('perPage', event.rows);
    setSearchParams(`?${_searchParams}`)
  };

  const onSort = (event: any) => {
    event.first = 0;
    event.page = 0;
    event.filters = lazyState.filters;
    setlazyState({ ...lazyState, ...event });

    // Add the sort parameter to the URL.
    let _searchParams = new URLSearchParams(searchParams);
    _searchParams.set('sortField', event.sortField);
    _searchParams.set('sortOrder', (event.sortOrder === -1 ? 'DESC' : 'ASC'));
    setSearchParams(`?${_searchParams}`)
  };

  const onFilter = (event: any) => {
    event.first = 0;
    event.page = 0;
    setlazyState({ ...lazyState, ...event });
  };

  const onGlobalFilter = (event: any) => {
    const value = event.target.value;
    if (value.length > 0 && value.length < 2) {
      return;
    }

    let _ls = { ...lazyState };
    _ls.filters['global'].value = value;

    if (globalSearchTimer) {
      clearTimeout(globalSearchTimer);
    }

    globalSearchTimer = setTimeout(() => {
      _ls.first = 0;
      _ls.page = 0;

      setlazyState(_ls);

      // Add the search parameter to the URL.
      let _searchParams = new URLSearchParams(searchParams);
      if (value.length > 0) {
        _searchParams.set('search', value);
        _searchParams.delete('page');
      } else {
        _searchParams.delete('search');
      }

      setSearchParams(`?${_searchParams}`)
    }, 500);
  };

  const onRowEditComplete = (e: any) => {
    let _rows: any[] = [...data];
    const { newData, index } = e;
    _rows[index] = newData;

    const defaults: any = { formattedRows: _rows, patched: {}, id: newData?.id }
    defaults.patched[resourceType] = (editFields || []).length > 0 ? pick(newData, editFields) : newData;
    const { formattedRows, patched, id } = hookRowEdit ? hookRowEdit(_rows, newData) : defaults;

    if (onRefresh) {
      setData(formattedRows);
      onRefresh(formattedRows);

      if (stopOnRefresh === true) return;
    }

    if (onSubmit) {
      setData(formattedRows);

      return onSubmit(
        id,
        omit(patched[resourceType], ['id', '@id', '@type', 'created', 'updated']),
        formattedRows
      );
    }

    const terminate = (finalData: AxiosResponse) => {
      if (!finalData?.data) return;

      // Override row using returned APIM data.
      const mergedResult: any = {
        ...newData,
        ...finalData.data
      };
      formattedRows[index] = mergedResult;
      setData(formattedRows);

      if (onRowEditCallback) {
        patched[resourceType] = mergedResult;

        return onRowEditCallback(patched);
      }
    };

    if (isEmpty(omit(patched[resourceType], ['id']) as object)) {
      setData(formattedRows);
      patched[resourceType] = newData;

      return onRowEditCallback ? onRowEditCallback(patched) : true;
    }

    if (id) {
      apim.patchEntity({
        resourceType,
        id,
        data: omit(patched[resourceType], ['id', '@id', '@type', 'created', 'updated']),
        headers: requestHeader ? requestHeader : '',
        success: (finalRes: AxiosResponse) => terminate(finalRes)
      } as IRequestParams).then();
    } else {
      apim.postEntity({
        resourceType,
        data: { ...patched[resourceType], ...additionalData },
        headers: requestHeader ? requestHeader : '',
        success: (finalRes: AxiosResponse) => terminate(finalRes)
      } as IRequestParams).then();
    }
  };

  const removeBodyTemplate = (rowData: any) =>
    <>
      <Button type={'button'} className={'a8-remove-btn'} icon={'pi pi-trash'} rounded text severity={'danger'} aria-label={ucfirst(trans(t, 'delete'))} onClick={() => {
        dialog(t, {
          message: trans(t, 'system|confirmations.short'),
          accept: () => {
            const _rows: any[] = (data || []).filter((e: any) => e !== rowData);
            setData(_rows);
            if (onRefresh) {
              onRefresh(_rows);
            }
            if (onDelete) {
              return onDelete(rowData);
            }

            const { id } = hookRowEdit ? hookRowEdit(_rows, rowData) : { id: rowData?.id };
            if (id) {
              apim.deleteEntity({
                resourceType: resourceType,
                id: id,
                headers: requestHeader ? requestHeader : ''
              } as IRequestParams).then();
            }
          }
        })
      }}
      />
      <Tooltip target={'.a8-remove-btn'} position={'left'} content={ucfirst(trans(t, 'delete'))} mouseTrack />
    </>
  ;

  const detailsBodyTemplate = (rowData: any) =>
    <>
      <Button type={'button'} className={'a8-details-btn'} icon={'pi pi-eye'} rounded text aria-label={ucfirst(trans(t, 'seeDetails'))} onClick={() => {
        if (onRowClick) return onRowClick(rowData);
        if (navigate) navigate(rowUri.replace(':id', rowData.data?.id ?? rowData.id ?? '_'));
      }}
      />
      <Tooltip target={'.a8-details-btn'} position={'left'} content={ucfirst(trans(t, 'seeDetails'))} mouseTrack />
    </>
  ;

  const header = () => {
    const showTitle = title && 'none' !== title;

    return <div className={'flex flex-column md:flex-row md:align-items-center' + (showTitle ? ' md:justify-content-between' : ' md:justify-content-end')}>
      {showTitle && (
        <h5 className={'m-0'}>{ucfirst(title)}</h5>
      )}

      <div className={'flex flex-column md:flex-row md:justify-content-between md:align-items-center gap-2'}>
        {!noGlobalFilter && (
          <span className={'block mt-2 md:mt-0 p-input-icon-left'}>
              <i className={'pi pi-search'} />
              <InputText type={'search'} onChange={onGlobalFilter} placeholder={trans(t, 'search')} defaultValue={iLazyState.filters?.global?.value ?? ''} />
            </span>
        )}
        {(editMode || onNew) && !noAdd && (
          <>
            <Button type={'button'} className={'a8-dt-new-' + tableKey} icon={'pi pi-plus'} aria-label={addTitle ?? trans(t, 'table|add.default')} severity={'success'}
                    onClick={() => {
                      if (onNew) {
                        onNew(data, (_rows: any[]) => {
                          setData(_rows);

                          if (onRefresh) {
                            onRefresh(_rows);
                          }
                        })
                        addNewRow.current = (autoEditOnNewRow === true)
                      } else {
                        // Insert a new empty row at the top of the DT.
                        const _rows: any[] = [newRowTemplate ?? {}, ...data];
                        setData(_rows);

                        if (onRefresh) {
                          onRefresh(_rows);
                        }

                        // Automatically open the form for the added row.
                        // @see https://stackblitz.com/edit/react-mzrgpq?file=src%2FApp.js
                        // @see https://github.com/primefaces/primereact/issues/1135#issuecomment-1671957246
                        addNewRow.current = (autoEditOnNewRow === true)
                      }
                    }}
            />
            <Tooltip target={'.a8-dt-new-' + tableKey} position={'left'} content={addTitle || trans(t, 'table|add.default')} />
          </>
        )}
        {headerCreateBtn && (
          <>
            {headerCreateBtn()}
          </>
        )}
      </div>
    </div>;
  }

  const isSelectable = (row: any) => (disabledItems || []).filter((elem: string) => elem === row?.id).length === 0;
  const isRowSelectable = (event: any) => (event.data ? isSelectable(event.data) : true);
  const rowClassName = (row: any) => classNames({
    'p-disabled': !isSelectable(row),
    'un-highlight': editMode
  });

  // triggers ActiveRowIndex whenever a new row is added with index = 0
  useEffect(() => {
    if (addNewRow.current) {
      // @todo what if the new inserted row isn't at index 0 ?
      setActiveRowIndex(0);
      addNewRow.current = false;
    }
  }, [data]); // eslint-disable-line react-hooks/exhaustive-deps

  // adding first line of relevant datatable to all the lines currently in edit mode
  const setActiveRowIndex = (index: number) => {
    const activeRowArray = [...editingRows, data[index]];
    setEditingRows(activeRowArray);
  }

  // getting rows currently in edit mode, and check if they form an array. If not, creates an array with only one row and set editingRows
  const onRowEditChange = (event: any) => {
    const { data } = event;
    const activeRowArray = Array.isArray(data) ? data : [data];
    setEditingRows(activeRowArray)
  }

  const onRowsOrderChange = (event: any) => {
    setData(event.value);

    if (onRowReorder) return onRowReorder(event);

    forEach(event.value ?? [], (_r: any, _i: number) => {
      if (!isValidUUID(_r?.id)) return;

      apim.patchEntity({
        resourceType,
        notif: false,
        id: _r?.id,
        data: { rank: _i }
      } as IRequestParams).then();
    });

    if (onRefresh) return onRefresh(event.value);
  };

  const footerResults = footer && footer.length > 0 ? createFooterContent(data, footer, t) : null;
  const footerGroup = footerResults ? (
    <ColumnGroup>
      <Row>
        {React.Children.map(props.children, (child: React.ReactElement) => {
          if (child && child.props) {
            const field = child.props.field;
            const footerData = field && footerResults[field];
            return (
              <Column
                key={field}
                footer={footerData ? (
                  <div className={'flex justify-content-center align-items-center w-full h-full'}>
                  <span className={'font-bold text-center'}>
                    {footerData.label}
                    {footerData.calculation === 'sum' || footerData.calculation === 'multiply' ? amountCell(footerData.value) : footerData.value}
                  </span>
                  </div>
                ) : null}
              />
            );
          }

          return null;
        })}
        {/* Add the footer without key */}
        {footerResults && footerResults['__label__'] && (
          <Column
            footer={
              <div className={'flex justify-content-center align-items-center w-full h-full'}>
              <span className={'font-bold text-center'}>
                {footerResults['__label__'].label}
              </span>
              </div>
            }
          />
        )}
      </Row>
    </ColumnGroup>
  ) : null;

  return (
    <div className={classNames(parentClasses || ['card']) + (lastRefresh ? ' refresher-' + lastRefresh : '')} style={parentClasses === undefined ? { boxShadow: 'none' } : undefined}>
      <DataTable
        ref={dt} header={noGlobalFilter && (!title || 'none' === title) && !editMode ? null : header} stripedRows key={tableKey} headerColumnGroup={headerColumnGroup}
        value={data} lazy loading={loading || (isLoading || false)} className={classNames('datatable-responsive', additionalClassNames, {
        'p-datatable-clickable': !editMode && (rowUri || onRowClick),
        'p-datatable-no-hover': editMode
      })}
        paginator={paginator !== false} first={lazyState.first} rows={lazyState.rows} totalRecords={totalRecords} rowsPerPageOptions={appConfig.pagination.default.rowsPerPageOptions}
        paginatorTemplate={'FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown'}
        currentPageReportTemplate={trans(t, 'result', (totalRecords || 1)) + ' {first} à {last} (sur ' + (totalRecords || 0) + ')'}
        emptyMessage={emptyMessage || trans(t, 'result', 0)} isDataSelectable={isDataSelectable ?? isRowSelectable} rowClassName={rowClassName}
        onPage={onPage} onSort={onSort} sortField={lazyState.sortField} sortOrder={lazyState.sortOrder} selectOnEdit={selectOnEdit || (editMode === true)}
        onFilter={onFilter} filters={filtersEnabled ? lazyState.filters : (lazyState.filters['global'])} filterDisplay={filtersEnabled ? 'row' : undefined}
        onRowClick={(e) => {
          const isRowSelected = selection?.includes(e.data);
          if (editMode) return;
          if (onRowClick) return onRowClick(e.data);
          if (rowUri && navigate && !isRowSelected) navigate(rowUri.replace(':id', e.data?.id || '_'));
        }}
        editMode={editMode ? 'row' : undefined} onRowEditComplete={onRowEditComplete}
        editingRows={editingRows || [false]} onRowEditChange={onRowEditChange}
        reorderableRows={reorderableRows} onRowReorder={onRowsOrderChange}
        selectionMode={selectionMode ?? 'checkbox'} selection={selection} onSelectionChange={onSelectionChange}
        expandedRows={expandedRows} onRowToggle={onRowToggle} rowExpansionTemplate={rowExpansionTemplate}
        footerColumnGroup={footerGroup}
      >
        {editMode && reorderableRows && (
          <Column rowReorder style={{ maxWidth: '30px', width: '30px' }} />
        )}

        {props.children}

        {editMode && !noEdit && (
          <Column rowEditor align={'center'} style={{ maxWidth: '90px', width: '90px' }} />
        )}
        {editMode && !noRemove && (
          <Column body={removeBodyTemplate} align={'center'} style={{ maxWidth: '90px', width: '90px' }} />
        )}
        {editMode && (rowUri || onRowClick) && (
          <Column body={detailsBodyTemplate} align={'center'} style={{ maxWidth: '90px', width: '90px' }} />
        )}
      </DataTable>
    </div>
  );
};
