import React from 'react';
import { InputText } from 'primereact/inputtext';
import { UncontrolledBaseField } from './UncontrolledBaseField';
import { trans } from 'utilities';
import { useApim } from 'services';

export const UncontrolledTextField = (props: any) => {
  const { fieldKey, value, onFieldChange, onBlur, label, tooltip, tooltipOptions, description, classes, addon, addonPosition, disabled } = props;

  const apim = useApim();
  const { t } = apim.di();

  return (
    <UncontrolledBaseField classes={classes} addon={addon} addonPosition={addonPosition} description={description}>
      <span className={'p-float-label'}>
        <InputText id={fieldKey} className={'text-ucfirst w-full'} value={value || ''} disabled={disabled}
                   onChange={(e) => {
                     if (onFieldChange !== null && onFieldChange !== undefined) {
                       onFieldChange(e.target.value)
                     }
                   }}
                   onBlur={(e) => {
                     if (onBlur !== null && onBlur !== undefined) {
                       onBlur(e.target.value)
                     }
                   }}
                   tooltip={tooltip} tooltipOptions={tooltipOptions || {position: 'top'}}/>
        {(label || fieldKey) && (
          <label className={'text-ucfirst p-float-label-label'} htmlFor={fieldKey}>
            <span>{label ?? trans(t, fieldKey)}</span>
          </label>
        )}
      </span>
    </UncontrolledBaseField>
  );
};
