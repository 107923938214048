import * as am5 from '@amcharts/amcharts5';

export class A8Theme extends am5.Theme {
  setupDefaultRules() {

    // this.rule('Label').setAll({
    //   fontSize: 12,
    //   fill: am5.color(0x777777)
    // });

    this.rule('ColorSet').set('colors', [
      am5.color('#27C4B9'),
      am5.color('#A1D317'),
      am5.color('#9456F0'),
      am5.color('#22C3D8'),
      am5.color('#9D22B1'),
    ]);
  }
}
