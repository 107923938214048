import React, { useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { TFunction } from 'i18next';
import { TabMenu } from 'primereact/tabmenu';

import { isValidUUID, trans, ucfirst } from 'utilities';
import { Error, NotFound } from 'pages';
import { PageLoader } from 'components';
import { useAppState, useUserState } from 'states';
import { IRequestParams, useApim } from 'services';
import { DossierExperts, DossierFoyer, DossierNotifications, DossierSettings, DossierSocietes } from './tabs';

import { includes, forEach, replace } from 'lodash';
import appUri from 'config/appUri.json';

export const DossierSettingsFormRouter = () => {
  const { id, tab } = useParams();
  const apim = useApim();
  const { t, navigate } = apim.di();
  const userState = useUserState();
  const appState = useAppState();

  const [loading, setLoading] = useState<boolean>(false);
  const [data, setData] = useState<any>(null);
  const [notFound, setNotFound] = useState<boolean>(false);
  const [errored, setErrored] = useState<boolean>(false);

  const [activeIndex, setActiveIndex] = useState<number>(0);
  const tabs: any[] = [];
  if (id) {
    forEach(appUri.dos.settings.tabs, (ta: any) => {
      tabs.push({label: trans(t, 'menu|tab.' + ta), command: () => navigate(replace(appUri.dos.settings.uri, ':id', data?.id || '_') + '/' + ta)});
    });
  }

  // Validate URL & build tabs.
  const checks = useCallback(() => {
    return isUrlValid(t, id, tab);
  }, [id, tab]); // eslint-disable-line react-hooks/exhaustive-deps
  useEffect(() => {
    if (!checks()) setNotFound(true);

    let ai = 0;
    forEach(appUri.dos.settings.tabs, ((ta: string, index: number) => {
      if (tab === ta) ai = index;
    }));

    if (ai !== activeIndex) setActiveIndex(ai);
  }, [id, tab]); // eslint-disable-line react-hooks/exhaustive-deps

  const buildTabContent = useCallback(() => {
    switch (activeIndex) {
      case 1:
        return data && <DossierFoyer dossier={data} apim={apim} appState={appState} />;

      case 2:
        return data && <DossierSocietes dossier={data} apim={apim} />;

      case 3:
        return data && <DossierExperts dossier={data} apim={apim} />;

      case 4:
        return data && <DossierNotifications dossier={data} apim={apim} userUuid={userState.id()} side={'owner'} />;

      case 5:
        return data && <DossierNotifications dossier={data} apim={apim} userUuid={data.user} side={'client'} />;

      default:
        return data && <DossierSettings data={data} apim={apim} />;
    }
  }, [id, tab, activeIndex, data]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    setLoading(true);
    apim.fetchEntity({
      resourceType: 'dossiers',
      id: id,
      cache: false,
      setter: setData,
      setErrored: setErrored,
      setLoading: setLoading,
      setNotFound: setNotFound
    } as IRequestParams).then();
  }, [id]); // eslint-disable-line react-hooks/exhaustive-deps

  // Update Page Header.
  useEffect(() => {
    appState.setPageTitle((data ? ucfirst(trans(t, 'dossier')) + ' ' + data?.title : '') + ' | ' + ucfirst(trans(t, 'edit_settings')));
    appState.setPageActions([]);
    appState.setBreadcrumb([
      { label: trans(t, 'dossier'), to: appUri.dos.list },
      { label: data ? data.title : trans(t, 'dossier'), to: replace(appUri.dos.page, ':id', data?.id || '_') + '?maj=' + Date.now() },
      { label: trans(t, 'system|actions.settings'), to: appUri.dos.edit.landing.replace(':id', appState.dossier()?.id) },
      { label: trans(t, 'menu|tab.' + (tab || 'infos')) }
    ]);
  }, [data, tab]); // eslint-disable-line react-hooks/exhaustive-deps

  if (notFound) return <NotFound asBlock/>;
  if (errored) return <Error asBlock/>;
  if (loading) return <PageLoader/>;

  return (
    <div className={'card height-100 fadein animation-duration-500 flex flex-column'}>
      <div className={'a8-page-header flex flex-wrap'}>
        <div className={'flex-auto flex p-2 justify-content-end'}>
          {tabs.length > 1 && <TabMenu model={tabs} activeIndex={activeIndex} onTabChange={(e) => setActiveIndex(e.index)}/>}
        </div>
      </div>
      {buildTabContent()}
    </div>
  );
};

const isUrlValid = (t: TFunction, id: string | undefined, tab: string | undefined) => {
  // Ensure valid entity UUID & existing tab.
  return isValidUUID(id) && includes([...[undefined], ...appUri.dos.settings.tabs], tab);
};
