import React, { useEffect, useMemo, useState } from 'react';
import { AxiosResponse } from 'axios';

import { Fieldset } from 'primereact/fieldset';
import { Skeleton } from 'primereact/skeleton';
import { Message } from 'primereact/message';

import { escapeValue, isValidUUID, trans, ucfirst } from 'utilities';
import { NumberField, CheckboxField, MaskField } from 'forms';
import { useFormState } from 'states';
import { IRequestParams } from 'services';
import { EconomicDataAccountsDatatable } from 'components';

export const DonneesEconomiquesForm = (props: any) => {
  const { apim, t, control, onFieldChange, errorMessage, formKey, parentKey, getValues, setValue, context } = props;
  const formState = useFormState();

  // Check what is the company typeTaux: masse salariale VS marge.
  // Find the right exercice fiscal, then get it's ending date.
  let versionDate: any = null;
  const params: any = [
    {label: 'company', value: context.company.id},
    {label: 'order[event.date]', value: 'desc'},
    {label: 'itemsPerPage', value: 1}
  ];

  context.company.exercicesFiscaux.map((exerciceFiscal: any) => {
    if (exerciceFiscal.year === parseInt(context.exercice)) {
      versionDate = exerciceFiscal.end;
      params.push({label: 'byDate', value: versionDate});
    }

    return null;
  });

  // Load company version for given date.
  const [companyDataLoading, setCompanyDataLoading] = useState<boolean>(false);
  const [companyData, setCompanyData] = useState<any>(null);
  useEffect(() => {
    setCompanyDataLoading(true);
    apim.fetchEntities({
      resourceType: 'dossierCompanyData',
      setLoading: setCompanyDataLoading,
      params,
      cache: false,
      success: (res: AxiosResponse) => {
        if (res?.data && (res?.data['hydra:member'] ?? []).length > 0) {
          setCompanyData(res.data['hydra:member'][0]);
        } else {
          setCompanyData(null);
        }
      }
    } as IRequestParams).then();
  }, [context]); // eslint-disable-line react-hooks/exhaustive-deps

  const {
    id,
    valorisationImmobilierPro = 0,
    valeurTotaleEntreprise = 0,
    exonerationZRR = false
  } = getValues();

  // Checking if fields are filled before calculating result
  const calculateQuotePartImmo = () => {
    if (valorisationImmobilierPro + valeurTotaleEntreprise !== 0) {
      const quotePartImmo = valorisationImmobilierPro / valeurTotaleEntreprise * 100;
      formState.setFieldData(formKey, 'quotePart', parentKey, escapeValue(quotePartImmo, 'number'));
      setValue('quotePart', quotePartImmo);
    }
  };

  // Triggering calculations everytime related values are updated
  useEffect(() => calculateQuotePartImmo(), [valorisationImmobilierPro, valeurTotaleEntreprise]); // eslint-disable-line react-hooks/exhaustive-deps

  const buildDt = useMemo(() => {
    return <EconomicDataAccountsDatatable apim={apim} data={getValues()} editMode context={context} title={''}/>
  }, [id]); // eslint-disable-line react-hooks/exhaustive-deps

  return <>
    <Fieldset legend={ucfirst(trans(t, 'general'))} className={'w-full mb-3'} toggleable>
      <div className={'grid'}>
        <NumberField fieldKey={'chiffreAffaires'} mode={'currency'}
                     addon={'euro'} addonPosition={'after'} control={control} onFieldChange={onFieldChange} maxFractionDigits={0}
                     errorMessage={errorMessage} label={trans(t, 'chart|economicData.chiffreAffaires')}/>

        <NumberField fieldKey={'valeurAjoutee'} mode={'currency'}
                     addon={'euro'} addonPosition={'after'} control={control} onFieldChange={onFieldChange} maxFractionDigits={0}
                     errorMessage={errorMessage} label={trans(t, 'chart|economicData.valeurAjoutee')}/>

        <NumberField fieldKey={'ebe'} mode={'currency'}
                     addon={'euro'} addonPosition={'after'} control={control} onFieldChange={onFieldChange} maxFractionDigits={0}
                     errorMessage={errorMessage} label={trans(t, 'chart|economicData.ebe')}/>

        <NumberField fieldKey={'resultatExploitation'} mode={'currency'}
                     addon={'euro'} addonPosition={'after'} control={control} onFieldChange={onFieldChange} maxFractionDigits={0}
                     errorMessage={errorMessage} label={trans(t, 'chart|economicData.resultatExploitation')}/>

        <NumberField fieldKey={'resultatNet'} mode={'currency'}
                     addon={'euro'} addonPosition={'after'} control={control} onFieldChange={onFieldChange} maxFractionDigits={0}
                     errorMessage={errorMessage} label={trans(t, 'chart|economicData.resultatNet')}/>

        <NumberField fieldKey={'capitauxPropres'} mode={'currency'}
                     addon={'euro'} addonPosition={'after'} control={control} onFieldChange={onFieldChange} maxFractionDigits={0}
                     errorMessage={errorMessage} label={trans(t, 'chart|economicData.capitauxPropres')}/>

        <CheckboxField fieldKey={'locationGerance'} control={control} onFieldChange={onFieldChange} errorMessage={errorMessage}
                       label={trans(t, 'chart|economicData.locationGerance')} help={ucfirst(trans(t, 'locationGeranceHelp'))} />

        <CheckboxField fieldKey={'exonerationZRR'} control={control} onFieldChange={onFieldChange} errorMessage={errorMessage}
                       label={trans(t, 'chart|economicData.exonerationZRR')} help={ucfirst(trans(t, 'exonerationZRRHelp'))} />

        {exonerationZRR && (
          <MaskField fieldKey={'startExonerationZRR'} control={control} onFieldChange={onFieldChange} errorMessage={errorMessage} isDate
                     label={trans(t, 'chart|economicData.startExonerationZRR')} mask={'99/9999'}/>
        )}
      </div>
    </Fieldset>

    <Fieldset legend={ucfirst(trans(t, 'ent.comptesCourants'))} className={'w-full mb-3'} toggleable>
      <div className={'grid'}>
        <NumberField fieldKey={'compteCourantAssocie'} mode={'currency'}
                     addon={'euro'} addonPosition={'after'} control={control} onFieldChange={onFieldChange} maxFractionDigits={0}
                     errorMessage={errorMessage} label={trans(t, 'chart|economicData.compteCourantAssocie')}
                     tooltip={trans(t, 'form|tooltip.donneesEconomiques.compteCourantAssocie')}/>

        <NumberField fieldKey={'compteCourantNonAssocie'} mode={'currency'}
                     addon={'euro'} addonPosition={'after'} control={control} onFieldChange={onFieldChange} maxFractionDigits={0}
                     errorMessage={errorMessage} label={trans(t, 'chart|economicData.compteCourantNonAssocie')}
                     tooltip={trans(t, 'form|tooltip.donneesEconomiques.compteCourantNonAssocie')}/>
      </div>

      <div className={'w-full'}>{buildDt}</div>
    </Fieldset>

    {companyDataLoading ? (
      <Skeleton width={'100%'} height={'8rem'} className={'w-full mb-3'}></Skeleton>
    ) : (
      isValidUUID(companyData?.id) ? (
        companyData.typeTaux === 'masse_salariale' ? (
          <Fieldset legend={ucfirst(trans(t, 'masseSalariale'))} className={'w-full mb-3'} toggleable>
            <div className={'grid'}>
              <NumberField fieldKey={'margeBruteSalariale'} mode={'currency'}
                           addon={'euro'} addonPosition={'after'} control={control} onFieldChange={onFieldChange} maxFractionDigits={0}
                           errorMessage={errorMessage} label={trans(t, 'chart|economicData.masseSalariale')}/>

              <NumberField fieldKey={'tauxMargeMasseSalariale'} mode={'decimal'} disabled={true} tooltip={trans(t, 'form|tooltip.donneesEconomiques.tauxMasseSalariale')}
                           addon={'percentage'} addonPosition={'after'} control={control} onFieldChange={onFieldChange} maxFractionDigits={2}
                           errorMessage={errorMessage} label={trans(t, 'chart|economicData.tauxMasseSalariale')} min={0} suffix={'%'}/>
            </div>
          </Fieldset>
        ) : (
          <Fieldset legend={ucfirst(trans(t, 'marge'))} className={'w-full mb-3'} toggleable>
            <div className={'grid'}>
              <NumberField fieldKey={'margeBruteSalariale'} mode={'currency'}
                           addon={'euro'} addonPosition={'after'} control={control} onFieldChange={onFieldChange} maxFractionDigits={0}
                           errorMessage={errorMessage} label={trans(t, 'chart|economicData.margeBruteSalariale')}/>

              <NumberField fieldKey={'tauxMargeMasseSalariale'} mode={'decimal'}
                           addon={'percentage'} addonPosition={'after'} control={control} onFieldChange={onFieldChange} maxFractionDigits={2}
                           errorMessage={errorMessage} label={trans(t, 'chart|economicData.tauxMarge')} min={0} suffix={'%'}/>
            </div>
          </Fieldset>
        )
      ) : (
        <Fieldset legend={ucfirst(trans(t, 'masseSalarialeOuMarge'))} className={'w-full mb-3'} toggleable>
          <Message severity={'info'} className={'p-2 mb-4 rounded-md'} text={ucfirst(trans(t, 'companyChooseTypeTaux'))}/>
          <div className={'grid'}>
            <NumberField fieldKey={'margeBruteSalariale'} mode={'currency'} disabled={true}
                         addon={'euro'} addonPosition={'after'} control={control} onFieldChange={onFieldChange} maxFractionDigits={0}
                         errorMessage={errorMessage} label={trans(t, 'chart|economicData.masseSalarialeMarge')}/>

            <NumberField fieldKey={'tauxMargeMasseSalariale'} mode={'decimal'} disabled={true}
                         addon={'percentage'} addonPosition={'after'} control={control} onFieldChange={onFieldChange} maxFractionDigits={2}
                         errorMessage={errorMessage} label={trans(t, 'chart|economicData.tauxMasseSalarialeMarge')} min={0} suffix={'%'}/>
          </div>
        </Fieldset>
      )
    )}

    <Fieldset legend={ucfirst(trans(t, 'valorisation'))} className={'w-full mb-3'} toggleable>
      <div className={'grid'}>
        <NumberField fieldKey={'valorisationFondsCommerce'} mode={'currency'}
                     addon={'euro'} addonPosition={'after'} control={control} onFieldChange={onFieldChange} maxFractionDigits={0}
                     errorMessage={errorMessage} label={trans(t, 'chart|economicData.valorisationFondsCommerce')}
                     tooltip={trans(t, 'form|tooltip.donneesEconomiques.valorisationFondsCommerce')}/>

        <NumberField fieldKey={'valorisationImmobilierPro'} mode={'currency'}
                     addon={'euro'} addonPosition={'after'} control={control} onFieldChange={onFieldChange} maxFractionDigits={0}
                     errorMessage={errorMessage} label={trans(t, 'chart|economicData.valorisationImmobilierPro')}/>

        <NumberField fieldKey={'valeurTotaleEntreprise'} mode={'currency'}
                     addon={'euro'} addonPosition={'after'} control={control} onFieldChange={onFieldChange} maxFractionDigits={0}
                     errorMessage={errorMessage} label={trans(t, 'chart|economicData.valeurTotaleEntreprise')}/>

        <NumberField fieldKey={'valorisationPartsSurTitre'} mode={'currency'}
                     addon={'euro'} addonPosition={'after'} control={control} onFieldChange={onFieldChange} maxFractionDigits={0}
                     errorMessage={errorMessage} label={trans(t, 'chart|economicData.valorisationPartsSurTitre')}/>

        <NumberField fieldKey={'caf'} mode={'currency'}
                     addon={'euro'} addonPosition={'after'} control={control} onFieldChange={onFieldChange} maxFractionDigits={0}
                     errorMessage={errorMessage} label={trans(t, 'chart|economicData.caf')}/>
      </div>

      <div className={'grid'}>
        <NumberField fieldKey={'quotePart'} mode={'decimal'} disabled={true}
                     addon={'percentage'} addonPosition={'after'} control={control} onFieldChange={onFieldChange} maxFractionDigits={2}
                     errorMessage={errorMessage} label={trans(t, 'chart|economicData.quotePart')} min={0} suffix={'%'}/>

        <NumberField fieldKey={'niveauRisque'} mode={'decimal'}
                     control={control} onFieldChange={onFieldChange} maxFractionDigits={2} showButtons
                     errorMessage={errorMessage} label={trans(t, 'chart|economicData.niveauRisque')} min={0} />
      </div>
    </Fieldset>

    <Fieldset legend={ucfirst(trans(t, 'previsionnel'))} className={'w-full mb-3'} toggleable>
      <div className={'grid'}>
        <NumberField fieldKey={'previsionnelChiffreAffaires'} mode={'currency'}
                     addon={'euro'} addonPosition={'after'} control={control} onFieldChange={onFieldChange} maxFractionDigits={0}
                     errorMessage={errorMessage} label={trans(t, 'chart|economicData.previsionnelChiffreAffaires')}/>

        <NumberField fieldKey={'previsionnelEbe'} mode={'currency'}
                     addon={'euro'} addonPosition={'after'} control={control} onFieldChange={onFieldChange} maxFractionDigits={0}
                     errorMessage={errorMessage} label={trans(t, 'chart|economicData.previsionnelEbe')}/>

        <NumberField fieldKey={'previsionnelResultatNet'} mode={'currency'}
                     addon={'euro'} addonPosition={'after'} control={control} onFieldChange={onFieldChange} maxFractionDigits={0}
                     errorMessage={errorMessage} label={trans(t, 'chart|economicData.previsionnelResultatNet')}/>

        <NumberField fieldKey={'previsionnelMargeBruteSalariale'} mode={'currency'}
                     addon={'euro'} addonPosition={'after'} control={control} onFieldChange={onFieldChange} maxFractionDigits={0}
                     errorMessage={errorMessage} label={trans(t, 'chart|economicData.previsionnelMargeBruteSalariale')}/>
      </div>
    </Fieldset>
  </>;
};
