import React, { useEffect, useState } from 'react';
import { Accordion, AccordionTab } from 'primereact/accordion';

import { iri, trans, ucfirst } from 'utilities';
import { CheckboxField, StaticListField, TextField } from 'forms/index';
import { useFormState } from 'states';

export const MissionRequestForm = (props: any) => {
  const { t, control, onFieldChange, errorMessage, context,  setValue, formKey, parentKey } = props;
  const formState = useFormState();

  const [activeIndex, setActiveIndex] = useState<any>();
  const missionTypes = context?.missionTypes?.map((option: any) => ({
    label: option?.label,
    value: iri('missionTypes',option?.id)
  }));

  const clearFields = (fields: string[]) => {
    fields.forEach((field) => {
      formState.setFieldData(formKey, field, parentKey, null);
      setValue(field, null);
    });
  };

  useEffect(() => {
    switch (activeIndex) {
      case 0:
        clearFields(['societeExpertEmail', 'societeExpertPhone', 'societeExpertRaisonSociale', 'societeExpertUnknown']);
        break;
      case 1:
        clearFields(['societeExpert', 'societeExpertUnknown']);
        break;
      case 2:
        clearFields(['societeExpert', 'societeExpertEmail', 'societeExpertPhone', 'societeExpertRaisonSociale']);
        break;
      default:
        break;
    }
  }, [activeIndex]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      <div className={'flex flex-column mx-3'} style={{minHeight: '50vh'}}>
        <div style={{width: '66vw'}}>
          <p>{trans(t, 'form|mission.request.whichMissionType')}</p>

          <StaticListField listKey={'missionTypes'} listsOptions={{missionTypes: missionTypes}} fieldKey={'missionType'} control={control} label={ucfirst(trans(t, 'missionType'))}
                           optionLabel={'label'} errorMessage={errorMessage} onFieldChange={onFieldChange} editable={true} classes={'col-12 xl:col-4'} rules={{ required: trans(t, 'form|requiredField') }}/>
          <p>{trans(t, 'form|mission.request.whichSocieteExpert')}</p>
          <Accordion activeIndex={activeIndex} onTabChange={(e) => setActiveIndex(e.index)}>
            <AccordionTab header={trans(t, 'form|mission.request.mySocieteExpert')}>
              <CheckboxField fieldKey={'societeExpert'} control={control} onFieldChange={onFieldChange} errorMessage={errorMessage}
                             label={trans(t, 'form|mission.request.mySocieteExpertMessage',1, context?.societeExpert?.nomCommercial)} classes={'col-12'}/>
            </AccordionTab>
            <AccordionTab header={trans(t, 'form|mission.request.anotherSocieteExpert')}>
              <div className={'flex flex-grow-1 col-12 flex-wrap'}>
                <TextField fieldKey={'societeExpertRaisonSociale'} control={control} onFieldChange={onFieldChange} errorMessage={errorMessage} label={ucfirst(trans(t, 'ent.raisonSociale'))} classes={'col-12 xl:col-4'}/>
                <TextField fieldKey={'societeExpertEmail'} control={control} onFieldChange={onFieldChange} errorMessage={errorMessage} label={ucfirst(trans(t, 'email'))} addon={'at'} classes={'col-12 xl:col-4'}/>
                <TextField fieldKey={'societeExpertPhone'} control={control} onFieldChange={onFieldChange} errorMessage={errorMessage} label={ucfirst(trans(t, 'phone'))} addon={'phone'} classes={'col-12 xl:col-4'}/>
              </div>
            </AccordionTab>
            <AccordionTab header={trans(t, 'form|mission.request.societeExpertUnknown')}>
              <CheckboxField fieldKey={'societeExpertUnknown'} control={control} onFieldChange={onFieldChange} errorMessage={errorMessage}
                             label={trans(t, 'form|mission.request.societeExpertUnknownMessage')} classes={'col-12'}/>
            </AccordionTab>
          </Accordion>
        </div>
      </div>

    </>
  )
};
