import React, { useState, useEffect } from 'react';

import { Column } from 'primereact/column';
import { Dropdown } from 'primereact/dropdown';

import { IRequestParams } from 'services';
import { trans, ucfirst } from 'utilities';
import {
  amountCell,
  amountEditor,
  DatatableWrapper, dateEditor,
  dateTimeCell,
  percentCell,
  percentEditor,
  simpleCheckboxEditor,
  validCell
} from 'components';

export const SettingsSalaryTaxDatatable = (props: any) => {
  const { apim, tablekey, title, resourceType, baseUri, params, year } = props;
  const { t, navigate } = apim.di();
  const tabUri = baseUri + '/fiscalite/:year'
  const [data, setData] = useState<any[]>([]);
  const updatedBodyTemplate = (rowData: any) => dateTimeCell(rowData?.updated);
  const createdBodyTemplate = (rowData: any) => dateTimeCell(rowData?.created);
  const activeBodyTemplate = (rowData: any) => validCell(rowData?.active);
  const salaryFromBodyTemplate = (rowData: any) => amountCell(rowData.salaryFrom);
  const salaryToBodyTemplate = (rowData: any) => amountCell(rowData.salaryTo);
  const rateBodyTemplate = (rowData: any) => percentCell(rowData.rate);
  const dateBodyTemplate = (rowData: any) => dateTimeCell(rowData?.date, { format: 'YYYY' });

  useEffect(() => {
    apim.fetchEntities({
      resourceType: 'salaryTaxRates',
      setter: setData,
    } as IRequestParams);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  //Creating a new array that stores all the years from salaryTaxRates minus the duplicates
  const uniqueYears = Array.from(
    //Go through all the years
    data.reduce((years, item) => {
      //Transforms date into year
      const year = new Date(item.date).getFullYear();
      //Adds year to years set if not already present
      if (!years.has(year)) {
        years.add(year);
      }
      return years;
    }, new Set<number>())
  ) as number[];
  uniqueYears.sort((a, b) => b - a);

  const fullP: any[] = year ? (params ?? []).concat([{
    label: 'date[after]',
    value: year + '-01-01'
  }, { label: 'date[before]', value: year + '-12-31' }]) : params;

  return (
    <>
      <div className={'flex flex-row align-items-center gap-5 mx-4 mt-5'}>
        <h5 className={'p-0 m-0'}>{trans(t, 'taxSelectYear')}</h5>
        <span className={'p-float-label'}>
          <Dropdown value={year} options={uniqueYears.map((year) => ({ label: year.toString(), value: year.toString() }))}
                    placeholder={trans(t, 'form|select')} onChange={(e) => {
            navigate(tabUri.replace(':year', e.value))
          }}/>
          <label htmlFor={'a8-year-select'} className={'text-ucfirst'}>{trans(t, 'otherYear')}</label>
        </span>
      </div>

      <DatatableWrapper resourceType={resourceType} tableKey={tablekey} title={title} params={fullP} editMode
                        editFields={['salaryFrom', 'salaryTo', 'rate', 'date', 'active']} noGlobalFilter noFilters>
        <Column field={'salaryFrom'} header={ucfirst(trans(t, 'from'))} sortable body={salaryFromBodyTemplate} align={'center'}
                editor={(options) => amountEditor(options, { label: trans(t, 'amount') })} style={{ width: '10vw' }}/>
        <Column field={'salaryTo'} header={ucfirst(trans(t, 'to'))} sortable body={salaryToBodyTemplate} align={'center'}
                editor={(options) => amountEditor(options, { label: trans(t, 'amount') })} style={{ width: '10vw' }}/>
        <Column field={'rate'} header={ucfirst(trans(t, 'rate'))} sortable body={rateBodyTemplate} align={'center'}
                editor={(options) => percentEditor(options, { label: '%', maxFractionDigits: 3 })}
                style={{ width: '5vw' }} />
        <Column field={'date'} body={dateBodyTemplate} sortable header={ucfirst(trans(t, 'otherYear'))} align={'center'}
                editor={(options) => dateEditor(options, { label: trans(t, 'otherYear'), dateFormat: 'yy', view: 'year' })} style={{ width: '20vw' }} />
        <Column field={'updated'} header={trans(t, 'updated')} sortable align={'center'} body={updatedBodyTemplate}
                style={{ width: '15vw' }} />
        <Column field={'created'} header={trans(t, 'created')} sortable align={'center'} body={createdBodyTemplate}
                style={{ width: '15vw' }} />
        <Column field={'active'} header={trans(t, 'active')} sortable body={activeBodyTemplate} align={'center'}
                editor={(options) => simpleCheckboxEditor(options, { label: trans(t, 'table|active') })}
                style={{ width: '5vw' }} />
      </DatatableWrapper>
    </>
  );
};
