import React, {useEffect, useRef, useState} from 'react';
import { Panel } from 'primereact/panel';
import { Button } from 'primereact/button';

import { dateString, isValidUUID, trans, ucfirst } from 'utilities';
import { DocumentViewer, Loader, Userpicture } from 'components';
import { AxiosResponse } from 'axios';
import { IRequestParams } from 'services';

export const Document = (props: any) => {
  const { apim, document } = props;
  const { t } = apim.di();

  const viewerRef = useRef<any>(null);
  const [documentPreview, setDocumentPreview] = useState<any>();
  const dates = [{ key: 'created' }, { key: 'updated' }];

  useEffect(() => {
    if (document?.fileStatus === 'loaded') {
      setDocumentPreview({...document, ...{uri: document.url}});
    } else {
      setDocumentPreview(null);
    }
  }, [document]); // eslint-disable-line react-hooks/exhaustive-deps

  const loadDocument = () => {
    if (document?.fileStatus === 'unloaded') {
      apim.call({
        resourceType: 'documents',
        action: 'load',
        id: document.id,
        method: 'get',
        success: (res: AxiosResponse) => {
          if (!res?.data) return;

          document.status = 'processed';
          document.url = res.data.url;
          document.mime = res.data.mime;
          document.extension = res.data.extension;
          document.size = res.data.size;
          setDocumentPreview({...document, ...{uri: document.url}});
        }
      } as IRequestParams).then();
    } else {
    }
  };

  const headerTemplate = (options: any) => {
    const className = `${options.className} justify-content-space-between`;

    return (
      <div className={className}>
        <div>
          <div className="font-bold text-lg">{ucfirst(trans(t, 'file_preview'))}</div>
        </div>
        <div>
          {options.togglerElement}
        </div>
      </div>
    );
  };

  return <>
    <Panel headerTemplate={headerTemplate} toggleable className={'mb-4'}>
      {isValidUUID(document?.id) ? (
        <>
          {documentPreview?.uri !== null && documentPreview?.uri !== undefined ? (
            <div style={{height: '700px'}}>
              <DocumentViewer documentPreview={documentPreview} viewerRef={viewerRef}/>
            </div>
          ) : (
            <div style={{height: '700px'}}>
              <>
                <p>{trans(t, 'loadDocumentSummary')}</p>
                <Button type={'button'} onClick={loadDocument} icon={'pi pi-download'} label={ucfirst(trans(t, 'loadDocument'))}/>
              </>
            </div>
          )}
        </>
      ) : (
        <Loader text={trans(t, 'system|loading')}/>
      )}
    </Panel>

    <div className={'flex flex-row justify-content-end'}>
      {isValidUUID(document?.author) && (
        <div className={'flex px-3 align-items-center'}>
          <strong className={'titre titre--gray pr-2'}>{ucfirst(trans(t, 'author'))}</strong>
          <div>
            <Userpicture apim={apim} user={document.author} size={'small'}/>
          </div>
        </div>
      )}

      {dates.map((date, index: number) => (
        document && document[date.key] &&
        <div key={index} className={'flex px-3 align-items-center'}>
          <strong className={'titre titre--gray pr-2'}>{ucfirst(trans(t, date.key + '.at'))}</strong>
          <div>
            {dateString(document[date.key], 'DD/MM/YYYY') + ' ' + trans(t, 'at') + ' ' + dateString(document[date.key], 'HH:mm')!.replace(':', 'h')}
          </div>
        </div>
      ))}
    </div>
  </>;
};
