import React, { useCallback, useEffect, useState } from 'react';
import { AxiosResponse } from 'axios';
import { Panel } from 'primereact/panel';

import { flatPerson, trans, ucfirst } from 'utilities';
import { IRequestParams } from 'services';
import {
  getModuleId, ModulesMenu, PageLoader,
  PersonnePhysiqueChildren, PersonnePhysiqueParents, PersonnePhysiqueSpouses
} from 'components';
import { Error } from 'pages';
import { FormWrapper, onPersonPhysiqueFormSubmit } from 'forms';

import { omit, replace } from 'lodash';
import appUri from 'config/appUri.json';

export const DossierFoyer = (props: any) => {
  const { dossier, apim } = props;
  const { t, navigate } = apim.di();

  const [loading, setLoading] = useState<boolean>(false);
  const [errored, setErrored] = useState<boolean>(false);

  const [dirigeant, setDirigeant] = useState<any>(null);
  const [parents, setParents] = useState<any>(null);
  const [children, setChildren] = useState<any>(null);
  const [spouses, setSpouses] = useState<any>(null);

  useEffect(() => {
    setLoading(true);

    apim.fetchEntities({
      resourceType: 'modules',
      params: [{label: 'order[label]', value: 'asc'}, {label: 'exists[parent]', value: false}],
      success: (res: AxiosResponse) => {
        if (!res?.data || res.data['hydra:member'].length === 0) return setErrored(true);

        apim.call({
          resourceType: 'dossiers',
          action: 'module',
          id: dossier?.id + '|' + getModuleId(ModulesMenu({t: t, id: dossier?.id, modules: res.data['hydra:member']}), 'foyer'),
          method: 'get',
          cache: false,
          setLoading: setLoading,
          setErrored: setErrored,
          success: (res: AxiosResponse) => {
            const test: any = (res?.data?.metadata['hydra:member'] || []).filter((c: any) => c.key === 'family');
            if (test.length === 0 || !test[0].data) return;

            // Just replace 'spouse', 'child' or 'parent' key by 'personnePhysique' to be able to use a standard PP datatable process :)
            const d: any = test[0].data;
            setDirigeant({...d, ...{ dossierId: dossier?.id }});
            setParents((d.parents || []).map((parent: any) => {
              return {
                ...omit(parent, ['parent']),
                ...{ personnePhysique: parent.parent }
              }
            }));
            setChildren((d.children || []).map((child: any) => {
              return {
                ...omit(child, ['child']),
                ...{ personnePhysique: child.child }
              }
            }));
            setSpouses((d.spouses || []).map((spouse: any) => {
              return {
                ...omit(spouse, ['spouse']),
                ...{ personnePhysique: spouse.spouse }
              }
            }));
          }
        } as IRequestParams);
      },
      error: () => setLoading(false)
    } as IRequestParams).then();
  }, [dossier?.id]); // eslint-disable-line react-hooks/exhaustive-deps

  const redirectUri = replace(appUri.dos.page, ':id', dossier?.id || '_') + '/foyer?maj=' + Date.now();
  const onFormSubmit = (formData: any) => onPersonPhysiqueFormSubmit(formData, dirigeant, apim, () => navigate(redirectUri));

  // Wrap form render into a useCallback to avoid multiple FormWrapper recalls du to form fields updates.
  const renderForm = useCallback(() =>
      <FormWrapper data={flatPerson(dirigeant)} formKeyPrefix={'personne_physique'} resourceType={'personnesPhysiques'} cancelLink classes={'mt-4'} subClasses={''}
                   onSubmit={onFormSubmit} redirectUri={redirectUri} />
    , [dossier?.id, dirigeant?.id]); // eslint-disable-line react-hooks/exhaustive-deps

  if (errored) return <Error asBlock/>;
  if (loading) return <PageLoader/>;

  return (
    <div className={'grid'}>
      <div className={'col-12'}>
        <Panel header={ucfirst(trans(t, 'dirigeant'))} toggleable>
          {renderForm()}
        </Panel>
      </div>

      <div className={'col-12'}>
        <Panel header={ucfirst(trans(t, 'spouse', spouses?.length))} toggleable>
          <PersonnePhysiqueSpouses rows={spouses} setRows={setSpouses} apim={apim} title={'none'} editMode context={dirigeant} />
        </Panel>
      </div>

      <div className={'col-12'}>
        <Panel header={ucfirst(trans(t, 'child', children?.length))} toggleable>
          <PersonnePhysiqueChildren rows={children} setRows={setChildren} apim={apim} title={'none'} editMode context={dirigeant} />
        </Panel>
      </div>

      <div className={'col-12'}>
        <Panel header={ucfirst(trans(t, 'parent', parents?.length))} toggleable>
          <PersonnePhysiqueParents rows={parents} setRows={setParents} apim={apim} title={'none'} editMode context={dirigeant} />
        </Panel>
      </div>
    </div>
  );
};
