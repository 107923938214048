import React, { useCallback } from 'react';
import { AxiosResponse } from 'axios';
import { Fieldset } from 'primereact/fieldset';

import { IRequestParams } from 'services';
import { FormWrapper, onPersonPhysiqueFormSubmit } from 'forms';
import { flatPerson, iri, isValidUUID, trans, ucfirst } from 'utilities';

import { forEach } from 'lodash';
import appUri from 'config/appUri.json';

export const DirigeantProfile = (props: any) => {
  const { data, apim } = props;
  const { t, navigate} = apim.di();

  // Let's flat all fields to root level to simplify nested forms.
  const user: any = data?.user;
  const person = flatPerson(data?.personnePhysique);
  const redirectUri = isValidUUID(user?.id) ?
    appUri.usr.dir.page.replace(':id', user.id) + '?maj=' + Date.now() :
    appUri.usr.dir.list;

  const onEditFormSubmit = (formData: any) => onPersonPhysiqueFormSubmit(formData, data?.personnePhysique, apim, () => navigate(redirectUri));
  const onCreateUserCallback = (_user: any, created: boolean) => {
    if (!created || !isValidUUID(_user?.id)) return;

    apim.fetchEntities({
      resourceType: 'roles',
      success: (res: AxiosResponse) => {
        if (!res?.data || !res.data['hydra:member']) return;

        const roles: any[] = res.data['hydra:member'].filter((_role: any) => ['ROLE_USER', 'ROLE_CLIENT'].includes(_role.name));
        forEach(roles, (_r: any, index: number) => {
          if (!isValidUUID(_r?.id)) return;

          apim.postEntity({
            resourceType: 'userRoles',
            notif: false,
            data: {
              user: iri('users', _user.id),
              role: iri('roles', _r.id),
            },
            success: () => {
              if (index < (roles.length - 1)) return;

              navigate(appUri.usr.dir.page.replace(':id', _user.id));
            }
          } as IRequestParams).then();
        })
      }
    } as IRequestParams).then();
  };

  // Wrap forms render into a useCallback to avoid multiple FormWrapper recalls du to form fields updates.
  const renderPersonForm = useCallback(() => !isValidUUID(user?.id) ?
      <FormWrapper data={{active: true}} formKeyPrefix={'dirigeant'} resourceType={'users'} cancelLink redirectUri={redirectUri} callback={onCreateUserCallback}/>
      :
      <FormWrapper data={person} formKeyPrefix={'personne_physique'} resourceType={'personnesPhysiques'} cancelLink onSubmit={onEditFormSubmit} redirectUri={redirectUri}/>
  , [person?.personnePhysiqueId]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div className={'grid'}>
      <div className={'col-12'}>
        <Fieldset legend={ucfirst(trans(t, 'profile'))} toggleable>
          <div className={'col-12'}>
            {renderPersonForm()}
          </div>
        </Fieldset>
      </div>
    </div>
  );
};
