import React, { useEffect, useState } from 'react';
import { AxiosResponse } from 'axios';

import { ModuleFormWrapper } from 'forms';
import { iri } from 'utilities';
import { IRequestParams } from 'services';
import { PageLoader } from 'components';
import { Error, NotFound } from 'pages';

import { Reserves } from './Reserves';

import { replace } from 'lodash';
import appUri from 'config/appUri.json';

export const ReservesFormRouter = (props: any) => {
  const { appState, apim, step, urls } = props;

  const [loading, setLoading] = useState<boolean>(false);
  const [notFound, setNotFound] = useState<boolean>(false);
  const [errored, setErrored] = useState<boolean>(false);

  const [data, setData] = useState<any>(null);
  const [keyTmp, setKeyTmp] = useState<string>('');

  const buildTabContent = () => <Reserves appState={appState} apim={apim} data={data} urls={urls} keyTmp={keyTmp}
                                          additionalData={{company: iri('dossierCompanies', appState.company()?.id), fiscalYear: parseInt(appState.exercise())}} />;

  useEffect(() => {
    setLoading(true);

    apim.fetchEntities({
      resourceType: 'reserves',
      params: [{label: 'company', value: appState.company()?.id}, {label: 'fiscalYear', value: appState.exercise()}],
      cache: false,
      setLoading: setLoading,
      setErrored: setErrored,
      setNotFound: setNotFound,
      success: (res: AxiosResponse) => {
        if (res?.data && (res?.data['hydra:member'] || []).length > 0) {
          setData(res.data['hydra:member'][0]);
          setKeyTmp('');
        } else {
          setData(null);
          setKeyTmp('_' + appState.company()?.id + '_' + appState.exercise());
        }
      }
    } as IRequestParams).then();
  }, [appState.exercise()]); // eslint-disable-line react-hooks/exhaustive-deps

  if (loading) return <PageLoader/>;
  if (notFound) return <NotFound asBlock/>;
  if (errored) return <Error asBlock/>;

  return <ModuleFormWrapper appState={appState} apim={apim} mode={appUri.reservedKeywords.exerciceFiscal} buildTabContent={buildTabContent}
                            baseUri={replace(appUri.dos.edit.fiscalYear.uri, ':id', appState.dossier()?.id || '_')} step={step}/>
};
