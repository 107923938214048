import React, { useEffect, useState } from 'react';
import { AxiosResponse } from 'axios';
import { useSearchParams } from 'react-router-dom';
import { Chip } from 'primereact/chip';
import { Skeleton } from 'primereact/skeleton';

import { asDate, trans, ucfirst } from 'utilities';
import { Error, NotFound } from 'pages';
import { AMChartXY, IndicatorSimple, PageLoader } from 'components';
import { IRequestParams, isClient } from 'services';

import appUri from 'config/appUri.json';
import { forEach } from 'lodash';

export const PrevoyanceContrat = (props: any) => {
  const { id, appState, apim, version, themeState } = props;
  const { t} = apim.di();

  const dossier = appState.dossier();
  const [loading, setLoading] = useState<boolean>(false);
  const [prevoyance, setPrevoyance] = useState<any>(null);
  const [chartDataLoading, setChartDataLoading] = useState<boolean>(false);
  const [chartData, setChartData] = useState<any>(null);
  const [tarifChartDataLoading, setTarifChartDataLoading] = useState<boolean>(false);
  const [tarifChartData, setTarifChartData] = useState<any>(null);
  const [versions, setVersions] = useState<any[]|null>(null);
  const [notFound, setNotFound] = useState<boolean>(false);
  const [errored, setErrored] = useState<boolean>(false);
  const [data, setData] = useState<any>(null);

  const [searchParams] = useSearchParams();
  const lastUpdate = searchParams.get('maj');

  // Override Page Header (defaults into ModuleWrapper, then PrevoyanceRouter).
  useEffect(() => {
    appState.setBreadcrumb([
      isClient()
       ? { label: trans(t, 'dossier', 2) }
       : { label: trans(t, 'dossier', 2), to: appUri.dos.list },
      { label: dossier.title, to: appUri.dos.page.replace(':id', id) },
      { label: trans(t, 'menu|menu.schema_flux.prevoyance_contrats'), to: appUri.dos.page.replace(':id', id) + '/prevoyance/contrats' },
      { label: data?.name ?? trans(t, 'contrat') }
    ]);
  }, [id, version, data?.id]); // eslint-disable-line react-hooks/exhaustive-deps

  // Fetch besoin.
  useEffect(() => {
    apim.fetchEntity({
      resourceType: 'prevoyances',
      params: [{label: 'dossier', value: dossier.id}],
      success: (res: AxiosResponse) => {
        if (!res?.data) {
          return;
        }

        setPrevoyance(res?.data['hydra:member'][0]);
      }
    } as IRequestParams).then();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  // Fetch version with contract.
  useEffect(() => {
    apim.fetchEntity({
      resourceType: 'prevoyanceContractsData',
      id: version,
      setErrored,
      setNotFound,
      setLoading,
      success: (res: AxiosResponse) => {
        if (!res?.data || !res?.data?.contract?.id) {
          setNotFound(true);
          setLoading(false);
          return;
        }

        setData(res?.data);
      }
    } as IRequestParams).then();
  }, [version, lastUpdate]); // eslint-disable-line react-hooks/exhaustive-deps

  // Fetch all contract versions.
  // @TODO once contracts are becoming bigger, prepare a dedicated endpoint in the API.
  useEffect(() => {
    if (data === null || data === undefined) {
      return;
    }

    apim.fetchEntities({
      resourceType: 'prevoyanceContractsData',
      params: [
        {label: 'contract', value: data.contract.id},
        {label: 'itemsPerPage', value: 500},
      ],
      setter: setVersions,
    } as IRequestParams).then();
  }, [data]); // eslint-disable-line react-hooks/exhaustive-deps

  // Generate couverture/besoin chart data.
  useEffect(() => {
    setChartDataLoading(true);

    if (prevoyance === null || prevoyance === undefined) {
      return;
    }

    if (data === null || data === undefined) {
      return;
    }

    let _chartDataBesoins: any = [];
    let _chartDataCouvertures: any = [];

    let besoinIncapacite = prevoyance.besoinPersoArretTemporaire ?? null;
    if (data?.proPerso === 'pro') {
      besoinIncapacite = prevoyance.besoinProArretTemporaire ?? null;
    }

    let max = besoinIncapacite;
    let minStart: number|null = null;

    if (data.incapaciteIdemniteJournalieres !== null && data.incapaciteIdemniteJournalieres !== undefined) {
      forEach(data.incapaciteIdemniteJournalieres, (ij: any) => {
        if (minStart === null || ij.startDay <= minStart) {
          minStart = ij.startDay;
        }
      });
    }

    if (minStart !== null && minStart > 0) {
      _chartDataCouvertures.push({
        category: 0 + ' - ' + minStart + ' ' + trans(t, 'day', minStart),
        tooltip: ucfirst(trans(t, 'couverture')) + ' : ' + 0 + ' € / ' + trans(t, 'day'),
        displayValue: 0 + ' € / ' + trans(t, 'day'),
        type: 'category',
        value: 0,
      });

      _chartDataBesoins.push({
        category: '0 - ' + minStart + ' ' + trans(t, 'day', minStart),
        tooltip: ucfirst(trans(t, 'besoin')) + ' : ' + besoinIncapacite + ' € / ' + trans(t, 'day'),
        displayValue: besoinIncapacite + ' € / ' + trans(t, 'day'),
        type: 'category',
        value: besoinIncapacite,
      });
    }

    if (data.incapaciteIdemniteJournalieres !== null && data.incapaciteIdemniteJournalieres !== undefined) {
      forEach(data.incapaciteIdemniteJournalieres, (ij: any) => {
        const contractAmount = ij.amount;
        const amount = besoinIncapacite !== null ? Math.max(0, besoinIncapacite - contractAmount) : 0;
        if (contractAmount > max) {
          max = contractAmount;
        }

        if (minStart === null || ij.startDay <= minStart) {
          minStart = ij.startDay;
        }

        _chartDataCouvertures.push({
          category: ij.startDay + ' - ' + ij.endDay + ' ' + trans(t, 'day', ij.endDay),
          tooltip: ucfirst(trans(t, 'couverture')) + ' : ' + ij.amount + ' € / ' + trans(t, 'day'),
          displayValue: contractAmount + ' € / ' + trans(t, 'day'),
          type: 'category',
          value: contractAmount,
        });

        if (amount > 0) {
          _chartDataBesoins.push({
            category: ij.startDay + ' - ' + ij.endDay + ' ' + trans(t, 'day', ij.endDay),
            tooltip: ucfirst(trans(t, 'besoin')) + ' : ' + amount + ' € / ' + trans(t, 'day'),
            displayValue: amount + ' € / ' + trans(t, 'day'),
            type: 'category',
            value: amount,
          });
        }
      });
    }

    let _chartData: any = {
      data: [
        {
          seriesType: 'ColumnSeries',
          label: 'Couverture',
          stacked: false,
          cornerRadiusTL: 0,
          cornerRadiusTR: 0,
          tooltipField: 'displayValue',
          tooltipColor: '#ffffff',
          values: _chartDataCouvertures,
          fill: '#cfb434',
          stroke: '#cfb434',
        },
        {
          seriesType: 'ColumnSeries',
          label: 'Besoin',
          stacked: true,
          tooltipField: 'displayValue',
          tooltipColor: '#ffffff',
          values: _chartDataBesoins,
          fill: '#0000c5',
          stroke: '#0000c5',
        },
      ]
    };

    _chartData.max = max;
    _chartData.min = 0;

    setChartData(_chartData);
    setChartDataLoading(false);
  }, [data, prevoyance]); // eslint-disable-line react-hooks/exhaustive-deps

  // Generate tarif chart data.
  useEffect(() => {
    setTarifChartDataLoading(true);

    if (versions === null || versions === undefined) {
      return;
    }

    let _tarifChartDataAmounts: any = [];

    if (versions.length > 0) {
      forEach(versions, (version: any) => {
        const date = asDate(version.date);
        if (!(typeof date.getTime === 'function')) {
          return;
        }

        if (version.cotisationAnnuelle !== null
        && version.cotisationAnnuelle !== undefined) {
          _tarifChartDataAmounts.push({
            date: date.getTime(),
            tooltip: version.cotisationAnnuelle + ' €',
            type: 'date',
            value: version.cotisationAnnuelle,
          });
        }
      });
    }

    let _tarifChartData: any = {
      data: [
        {
          seriesType: 'LineSeries',
          label: 'Cotisations',
          values: _tarifChartDataAmounts,
        }
      ]
    };

    setTarifChartData(_tarifChartData);
    setTarifChartDataLoading(false);
  }, [versions]); // eslint-disable-line react-hooks/exhaustive-deps

  if (notFound) return <NotFound asBlock/>;
  if (errored) return <Error asBlock/>;
  if (!dossier?.id || loading) return <PageLoader/>;

  return (
    <div className={'flex flex-column md:flex-row flex-wrap fadein animation-duration-500'}>
      <div className={'flex w-12 md:w-3 py-4 pl-2 pr-3'}>
        <IndicatorSimple color={'primary'} dark bgImage title={trans(t, 'form|contract.name')} text={data?.contract?.name}/>
      </div>

      <div className={'flex w-12 md:w-3 py-4 pl-2 pr-3'}>
        <IndicatorSimple color={'primary'} bgImage title={trans(t, 'form|contract.type')} value={data?.contract?.type} method={'list'} methodParams={{listKey: 'prevoyanceContractTypes'}}/>
      </div>

      <div className={'flex w-12 md:w-3 py-4 pl-2 pr-3'}>
        <IndicatorSimple color={'accent2'} bgImage title={trans(t, 'form|contract.date_effet')} value={data?.contract?.dateEffet} method={'date'}/>
      </div>

      <div className={'flex w-12 md:w-3 py-4 pl-2 pr-3'}>
        <IndicatorSimple color={'accent6'} bgImage title={trans(t, 'form|contract.periode_resiliation')} value={data?.periodeResiliation} method={'list'} methodParams={{listKey: 'prevoyanceContractPeriodesResiliations'}}/>
      </div>

      <div className={'flex w-12 md:w-3 py-4 pl-2 pr-3'}>
        <IndicatorSimple color={'accent5'} dark bgImage title={trans(t, 'form|contract.invaliditeBareme')} value={data?.invaliditeBaremeChoisi} method={'list'} methodParams={{listKey: 'prevoyanceContractBaremesInvalidites'}}/>
      </div>

      <div className={'flex w-12 md:w-3 py-4 pl-2 pr-3'}>
        <IndicatorSimple color={'primary'} bgImage title={trans(t, 'form|contract.proPerso')} value={data?.proPerso} method={'list'} methodParams={{listKey: 'prevoyanceContractProPerso'}}/>
      </div>

      <div className={'flex w-12 md:w-3 py-4 pl-2 pr-3'}>
        <IndicatorSimple color={'accent3'} bgImage title={trans(t, 'form|contract.cotisationAnnuelle')} value={data?.cotisationAnnuelle}/>
      </div>

      <div className={'flex w-12 flex-wrap gap-4 py-4 justify-content-center'}>
        <Chip key={'cumulableAvecAutresContrats'} label={trans(t, 'form|prevoyance_contrat.cumulableAvecAutresContrats')} icon={'pi pi-' + (true === data?.cumulableAvecAutresContrats ? 'check' : 'times')}
              style={{ backgroundColor: true === data?.cumulableAvecAutresContrats ? '#E3F2FD' : ''}}/>
        <Chip key={'tarifEvolueAvecAge'} label={trans(t, 'form|prevoyance_contrat.tarifEvolueAvecAge')} icon={'pi pi-' + (true === data?.tarifEvolueAvecAge ? 'check' : 'times')}
              style={{ backgroundColor: true === data?.tarifEvolueAvecAge ? '#E3F2FD' : ''}}/>
        <Chip key={'couvertureAffectionsPsy'} label={trans(t, 'form|prevoyance_contrat.couvertureAffectionsPsy')} icon={'pi pi-' + (true === data?.couvertureAffectionsPsy ? 'check' : 'times')}
              style={{ backgroundColor: true === data?.couvertureAffectionsPsy ? '#E3F2FD' : ''}}/>
        <Chip key={'couverturePathologiesDos'} label={trans(t, 'form|prevoyance_contrat.couverturePathologiesDos')} icon={'pi pi-' + (true === data?.couverturePathologiesDos ? 'check' : 'times')}
              style={{ backgroundColor: true === data?.couverturePathologiesDos ? '#E3F2FD' : ''}}/>
        <Chip key={'couvertureTempsPartielTherapeutique'} label={trans(t, 'form|prevoyance_contrat.couvertureTempsPartielTherapeutique')} icon={'pi pi-' + (true === data?.couvertureTempsPartielTherapeutique ? 'check' : 'times')}
              style={{ backgroundColor: true === data?.couvertureTempsPartielTherapeutique ? '#E3F2FD' : ''}}/>
        <Chip key={'incapaciteRemplacementDirigeant'} label={trans(t, 'form|prevoyance_contrat.incapaciteRemplacementDirigeant')} icon={'pi pi-' + (true === data?.incapaciteRemplacementDirigeant ? 'check' : 'times')}
              style={{ backgroundColor: true === data?.incapaciteRemplacementDirigeant ? '#E3F2FD' : ''}}/>
      </div>

      <div className={'flex w-12 flex-wrap gap-4 py-4 justify-content-center'}>
        <h3>{ucfirst(trans(t, 'form|contract.couvertureBesoin'))}</h3>
        {chartDataLoading ? (
          <Skeleton width={'100%'} height={'15rem'} />
        ) : (
          <AMChartXY chartData={chartData} showCursor verticalLegend={!themeState.isDesktop()} min={chartData?.min ?? null} max={chartData?.max ?? null}/>
        )}
      </div>

      <div className={'flex w-12 flex-wrap gap-4 py-4 justify-content-center'}>
        <h3>{ucfirst(trans(t, 'form|contract.evolutionTarifaire'))}</h3>
        {tarifChartDataLoading ? (
          <Skeleton width={'100%'} height={'15rem'} />
        ) : (
          <AMChartXY chartData={tarifChartData} showCursor verticalLegend={!themeState.isDesktop()} xType={'date'} />
        )}
      </div>
    </div>
  );
};
