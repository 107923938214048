import React, { useEffect, useState } from 'react';
import { AxiosResponse } from 'axios';

import { Badge } from 'primereact/badge';
import { Divider } from 'primereact/divider';

import { iri, isValidUUID, trans } from 'utilities';
import {
  PageLoader,
  PatrimoineBiensBancairesDatatable, PatrimoineBiensDiversDatatable,
  PatrimoineBiensEntreprisesDatatable, PatrimoineBiensImmobiliersDatatable,
  PatrimoineContratsAssuranceVieDatatable, PatrimoineContratsCapitalisationDatatable, PatrimoineContratsRetraiteDatatable,
  PatrimoineCreditsDatatable
} from 'components';
import { IRequestParams, isClient } from 'services';
import { Error, NotFound } from 'pages';

import appUri from 'config/appUri.json';

export const PatrimoineBiens = (props: any) => {
  const { id, appState, apim, type } = props;
  const { t} = apim.di();
  const dossier = appState.dossier();

  const [context, setContext] = useState<any>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [notFound, setNotFound] = useState<boolean>(false);
  const [errored, setErrored] = useState<boolean>(false);

  const resourceType: string = 'patrimoines';
  useEffect(() => {
    setLoading(true);
    apim.fetchEntity({
      resourceType: 'dossiers',
      id,
      setErrored,
      setNotFound,
      setter: appState.setDossier,
      success: (resDossier: AxiosResponse) => {
        if (!isValidUUID(resDossier?.data?.id)) return setLoading(false);

        // Ensure "patrimoine" entity.
        apim.fetchEntities({
          resourceType,
          params: [{label: 'dossier', value: id}],
          setErrored,
          success: (res: AxiosResponse) => {
            if (!res?.data || !res?.data['hydra:member'] || res?.data['hydra:member'].length === 0) {
              // Let's create data for this dossier.
              apim.postEntity({
                resourceType,
                data: {dossier: iri('dossiers', dossier?.id), date: new Date().toISOString()},
                notif: false,
                setErrored,
                setLoading,
                setter: setContext
              } as IRequestParams).then();
            } else {
              apim.fetchEntity({
                resourceType,
                id: res.data['hydra:member'][0].id,
                setErrored,
                success: (res: AxiosResponse) => {
                  if (isValidUUID(res?.data?.id) && !res?.data?.date) {
                    apim.patchEntity({
                      resourceType,
                      id: res?.data?.id,
                      data: {date: new Date().toISOString()},
                      notif: false,
                      setErrored,
                      setLoading,
                      success: () => {
                        setLoading(false);
                        setContext(res?.data);
                      }
                    } as IRequestParams).then();
                  } else {
                    setLoading(false);
                    setContext(res?.data);
                  }
                }
              } as IRequestParams).then();
            }
          }
        } as IRequestParams).then();
      },
    } as IRequestParams).then();
  }, [id]); // eslint-disable-line react-hooks/exhaustive-deps

  // Override Page Header (defaults into ModuleWrapper, then PrevoyanceRouter).
  useEffect(() => {
    appState.setBreadcrumb([
      isClient()
        ? { label: trans(t, 'dossier', 2) }
        : { label: trans(t, 'dossier', 2), to: appUri.dos.list },
      { label: dossier.title, to: appUri.dos.page.replace(':id', id)},
      { label: trans(t, 'menu|menu.schema_flux.patrimoine_biens') }
    ]);
  }, [id]); // eslint-disable-line react-hooks/exhaustive-deps

  if (notFound) return <NotFound asBlock/>;
  if (errored) return <Error asBlock/>;
  if (!dossier?.id || !context?.id || loading) return <PageLoader/>;

  return (
    <div className={'flex flex-column md:flex-row flex-wrap fadein animation-duration-500'}>
      <div className={'w-full grid'}>
        {!type && (
          <Divider align={'center'} type={'dashed'}><Badge severity={'info'} value={trans(t, 'credit', 2)}/></Divider>
        )}
        {(!type || 'credits' === type) && (
          <div className={'col-12'}>
            <PatrimoineCreditsDatatable apim={apim} editMode={false} appState={appState} context={context}/>
          </div>
        )}
        {!type && (
          <div className={'col-12 mt-5'}>
            <Divider align={'center'} type={'dashed'}><Badge severity={'info'} value={trans(t, 'bien', 2)}/></Divider>
          </div>

        )}
        {(!type || 'immobilier' === type) && (
          <div className={'col-12'}>
            <PatrimoineBiensImmobiliersDatatable apim={apim} editMode={false} appState={appState} context={context}/>
          </div>
        )}
        {(!type || 'entreprise' === type) && (
          <div className={'col-12'}>
            <PatrimoineBiensEntreprisesDatatable apim={apim} editMode={false} appState={appState} context={context}/>
          </div>
        )}
        {(!type || 'banque' === type) && (
          <div className={'col-12'}>
            <PatrimoineBiensBancairesDatatable apim={apim} editMode={false} appState={appState} context={context}/>
          </div>
        )}
        {(!type || 'divers' === type) && (
          <div className={'col-12'}>
            <PatrimoineBiensDiversDatatable apim={apim} editMode={false} appState={appState} context={context}/>
          </div>
        )}

        {!type && (
          <div className={'col-12 mt-5'}>
            <Divider align={'center'} type={'dashed'}><Badge severity={'info'} value={trans(t, 'contract', 2)}/></Divider>
          </div>
        )}
        {(!type || 'epargne-assurance-vie' === type) && (
          <div className={'col-12'}>
            <PatrimoineContratsAssuranceVieDatatable apim={apim} editMode={false} appState={appState} context={context}/>
          </div>
        )}
        {(!type || 'capitalisation' === type) && (
          <div className={'col-12'}>
            <PatrimoineContratsCapitalisationDatatable apim={apim} editMode={false} appState={appState} context={context}/>
          </div>
        )}
        {(!type || 'retraite' === type) && (
          <div className={'col-12'}>
            <PatrimoineContratsRetraiteDatatable apim={apim} editMode={false} appState={appState} context={context}/>
          </div>
        )}
      </div>
    </div>
  );
};
