import React, { useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { AxiosResponse } from 'axios';

import { IRequestParams, isClient, useApim } from 'services';
import { useAppState } from 'states';
import { iri, isValidUUID } from 'utilities';
import { Error, NotFound, Unauthorized } from 'pages';
import { getDirigeantData, getModuleId, ModulesMenu, PageLoader } from 'components';

// Contracts
import { AssuranceVieRouter } from './contrats/assuranceVie/AssuranceVieRouter';
import { CapitalisationRouter } from './contrats/capitalisation/CapitalisationRouter';
import { RetraiteRouter } from './contrats/retraite/RetraiteRouter';
// Biens
import { BienBancaireRouter } from './biens/bancaire/BienBancaireRouter';
import { BienDiversRouter } from './biens/divers/BienDiversRouter';
import { BienImmobilierRouter } from './biens/immobilier/BienImmobilierRouter';
import { BienEntrepriseRouter } from './biens/entreprise/BienEntrepriseRouter';
// Credits
import { CreditRouter } from './credits/CreditRouter';
// Donations
import { DonationRouter } from './donations/DonationRouter';

export const BienRouteur = (props: any) => {
  const { parentType } = props;
  const { id, bienId, creditId, contractId, donationId, type, step } = useParams();
  const apim = useApim();
  const { t} = apim.di();

  const appState = useAppState();
  const dossier: any = appState.dossier();

  const [context, setContext] = useState<any>(null);
  const [foyer, setFoyer] = useState<any>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [loadingFoyer, setLoadingFoyer] = useState<boolean>(false);
  const [notFound, setNotFound] = useState<boolean>(false);
  const [errored, setErrored] = useState<boolean>(false);

  // Fetch / Ensure "patrimoine" entity.
  const resourceType: string = 'patrimoines';
  useEffect(() => {
    setLoading(true);
    apim.fetchEntity({
      resourceType: 'dossiers',
      id,
      setErrored,
      setNotFound,
      setter: appState.setDossier,
      success: (resDossier: AxiosResponse) => {
        if (!isValidUUID(resDossier?.data?.id)) return setLoading(false);

        apim.fetchEntities({
          resourceType,
          params: [{label: 'dossier', value: id}],
          cache: false,
          setErrored,
          success: (res: AxiosResponse) => {
            if (!res?.data || !res?.data['hydra:member'] || res?.data['hydra:member'].length === 0) {
              // Let's create data for this dossier.
              apim.postEntity({
                resourceType,
                data: {dossier: iri('dossiers', dossier?.id)},
                setErrored,
                setLoading,
                setter: setContext
              } as IRequestParams).then();
            } else {
              apim.fetchEntity({
                resourceType,
                id: res.data['hydra:member'][0].id,
                // cache: false,
                setErrored,
                setLoading,
                setter: setContext
              } as IRequestParams).then();
            }
          }
        } as IRequestParams).then();
      },
    } as IRequestParams).then();
  }, [id]); // eslint-disable-line react-hooks/exhaustive-deps

  const modulesMenu = useCallback((modules: any[] | null = null) => {
    return ModulesMenu({t, id: dossier?.id, modules});
  }, [dossier?.id]); // eslint-disable-line react-hooks/exhaustive-deps

  // Fetch foyer data.
  useEffect(() => {
    if (!isValidUUID(dossier?.id)) return;

    setLoadingFoyer(true);
    apim.fetchEntities({
      resourceType: 'modules',
      params: [{label: 'order[label]', value: 'asc'}, {label: 'exists[parent]', value: false}],
      setLoading,
      success: (res: AxiosResponse) => {
        const mm: any[] = modulesMenu(res?.data['hydra:member']);

        apim.call({
          resourceType: 'dossiers',
          action: 'module',
          id: dossier?.id + '|' + getModuleId(mm, 'foyer'),
          method: 'get',
          setLoading: setLoadingFoyer,
          success: (resFoyer: AxiosResponse) => setFoyer(getDirigeantData(resFoyer?.data))
        } as IRequestParams);
      }
    } as IRequestParams).then();
  }, [dossier?.id]); // eslint-disable-line react-hooks/exhaustive-deps

  const buildContent = useCallback(() => {
    // Credits
    if (parentType === 'credit') return <CreditRouter apim={apim} appState={appState} context={context} creditId={creditId} step={step} dirigeant={foyer}/>;
    // Credits
    if (parentType === 'donation') return <DonationRouter apim={apim} appState={appState} context={context} donationId={donationId} step={step} dirigeant={foyer}/>;

    switch (type) {
      // Biens
      default:
        return <BienDiversRouter apim={apim} appState={appState} context={context} bienId={bienId} step={step} dirigeant={foyer}/>;
      case 'bancaire':
        return <BienBancaireRouter apim={apim} appState={appState} context={context} bienId={bienId} step={step} dirigeant={foyer}/>;
      case 'entreprise':
        return <BienEntrepriseRouter apim={apim} appState={appState} context={context} bienId={bienId} step={step} dirigeant={foyer}/>;
      case 'immobilier':
        return <BienImmobilierRouter apim={apim} appState={appState} context={context} bienId={bienId} step={step} dirigeant={foyer}/>;

      // Contrats
      case 'epargne-assurance-vie':
        return <AssuranceVieRouter apim={apim} appState={appState} context={context} contractId={contractId} step={step} dirigeant={foyer}/>;
      case 'capitalisation':
        return <CapitalisationRouter apim={apim} appState={appState} context={context} contractId={contractId} step={step} dirigeant={foyer}/>;
      case 'retraite':
        return <RetraiteRouter apim={apim} appState={appState} context={context} contractId={contractId} step={step} dirigeant={foyer}/>;
    }
  }, [id, bienId, contractId, creditId, donationId, type, step, context?.id, foyer?.id]); // eslint-disable-line react-hooks/exhaustive-deps

  if (isClient()) return <Unauthorized asBlock/>;
  if (notFound) return <NotFound asBlock/>;
  if (errored) return <Error asBlock/>;
  if (!dossier?.id || loading || loadingFoyer) return <PageLoader/>;

  return (
    <div className={'card height-100 fadein animation-duration-500 flex flex-column'}>
      {buildContent()}
    </div>
  );
};
