import React from 'react';
import { Card } from 'primereact/card';
import { classNames } from 'primereact/utils';

export const SearchResultNotImplemented = (props: any) => {
  const { data } = props;

  return <Card className={classNames('a8-search-item mb-4 mx-1')}>
    <p>TODO: implement this card for {data['@type']}.</p>
  </Card>
    ;
};
