import React, { useState } from 'react';

import { Button } from 'primereact/button';
import { Fieldset } from 'primereact/fieldset';

import { AssociatesPersonnesMoralesDatatable, DirigeantsPersonnesMoralesDatatable } from 'components';
import { isAGE, isValidUUID, trans, ucfirst } from 'utilities';
import { isAdmin, isExpertAdmin } from 'services';

import appUri from 'config/appUri.json';

export const Step4 = (props: any) => {
  const { data, setData, apim, baseUrl, cancelUrl, header } = props;
  const { t, navigate} = apim.di();

  // Flat dirigeantPersonneMorale on the root level to be able to use (almost) the same datatable as for the dirigeants.
  const formatAssociates = (_associates: any[]) => {
    return _associates.map((apm: any) => {
      if (isValidUUID(apm?.associateId)) return apm;

      else if (isValidUUID(apm.dirigeantPersonneMorale?.id)) return {
        ...apm.dirigeantPersonneMorale,
        ...{ associateId: apm.id, active: apm.active }
      }

      else if (isValidUUID(apm.company?.id)) return {
        ...{ company: apm.company },
        ...{ associateId: apm.id, active: apm.active }
      }

      return false;
    });
  };

  // Wrap these data into states to allow datatables to update them.
  const [dirigeants, setDirigeants] = useState<any[]>((data?.dirigeantPersonnesMorales || []).filter((a: any) => isAdmin() ? true : a?.active));
  const [associates, setAssociatesState] = useState<any[]>(formatAssociates(data?.associates || []).filter((a: any) => {
    if (isValidUUID(a.dirigeantPersonneMorale?.id)) return isAdmin() ? true : a?.active;
    else if (isValidUUID(a.company?.id)) return isAdmin() ? true : a?.active;

    return false;
  }));

  // Let's hook this update.
  const setAssociates = (_associates: any[]) => {
    setAssociatesState(_associates);
    // Refresh data for further steps.
    setData({...data, ...{associates: [...(data?.associates || []).filter((a: any) => {
      if (isValidUUID(a.dirigeantPersonneMorale?.id)) return false;
      else if (isValidUUID(a.company?.id)) return false;

      return true;
    }), ...formatAssociates(_associates)]}});
  };

  return (
    <div className={'a8-wizard wizard-societe-client card fadein animation-duration-500'}>
      <h5 className={'pt-3'}>{trans(t, 'menu|wizard.company.client.title.step4')}</h5>

      <div className={'col-12'}>
        <Fieldset legend={ucfirst(trans(t, 'dirigeant', dirigeants.length))} toggleable>
          <DirigeantsPersonnesMoralesDatatable header={header} rows={dirigeants} setRows={setDirigeants} apim={apim} title={'none'} editMode={isAdmin() || isExpertAdmin() || isAGE(data?.event)} context={data}/>
        </Fieldset>
      </div>

      <div className={'col-12'}>
        <Fieldset legend={ucfirst(trans(t, 'associate', associates.length))} toggleable>
          <AssociatesPersonnesMoralesDatatable header={header} rows={associates} setRows={setAssociates} apim={apim} title={'none'} editMode={isAdmin() || isExpertAdmin() || isAGE(data?.event)} context={data} dirigeants={dirigeants}/>
        </Fieldset>
      </div>

      <div className={'text-right m-2 mr-3'}>
        <>
          <Button type={'reset'} className={'text-ucfirst p-button-text p-button-danger mr-5'} label={trans(t, 'close')} onClick={() => navigate(cancelUrl)}/>
          <span className={'p-buttonset'}>
            <Button type={'submit'} className={'text-ucfirst p-button-text'} label={trans(t, 'back')} icon={'pi pi-arrow-left'} onClick={() => navigate(baseUrl + appUri.cie.cli.wizard.step3)}/>
            <Button type={'submit'} className={'text-ucfirst p-button-text'} label={trans(t, 'next')} icon={'pi pi-arrow-right'} iconPos={'right'} onClick={() => navigate(baseUrl + appUri.cie.cli.wizard.step5)}/>
          </span>
        </>
      </div>
    </div>
  );
};
