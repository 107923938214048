import React from 'react';
import { Image } from 'primereact/image';

import { trans } from 'utilities';

export const IndicatorContract = (props: any) => {
  const { t } = props;

  return (
    <div className={'flex-grow-1 flex-column indicateur-contrat flex-wrap text-center'}>
      <h6>{props.title || 'NR'}</h6>
      {props.label === 'existing' ? (
        <Image src={'/assets/icons/contract-true.png'} alt={'icône contrat existant'} width={'70'} />
      ) : ( props.label === 'not_existing' ? (
            <Image src={'/assets/icons/contract-false.png'} alt={'icône aucun contrat'} width={'70'} />
          ):
          <Image src={'/assets/icons/contract-unknown.png'} alt={'icône contrat non renseigné'} width={'70'} />
      )}
      <div>{trans(t,'chart|' + props.label) || trans(t,'chart|existing_unset')}</div>
    </div>
  );
};
