import React from 'react';
import { trans, ucfirst } from 'utilities';
import { CheckboxField } from 'forms';

export const Divers = (props: any) => {
  const { t, control, onFieldChange, errorMessage } = props;

  return (
    <div className={'flex col-12 flex-wrap'}>
      <CheckboxField
        fieldKey={'doWatch'}
        control={control}
        onFieldChange={onFieldChange}
        errorMessage={errorMessage}
        label={ucfirst(trans(t, 'watchChanges'))}
        help={ucfirst(trans(t, 'watchChangesHelp'))}
      />
    </div>
  );
}
