import React from 'react';
import { Checkbox } from 'primereact/checkbox';
import { UncontrolledBaseField } from './UncontrolledBaseField';

export const UncontrolledCheckboxField = (props: any) => {
  const { fieldKey, value, onFieldChange, label, tooltip, tooltipOptions, description, classes, addon, addonPosition, disabled, help } = props;

  return (
    <UncontrolledBaseField classes={classes} addon={addon} addonPosition={addonPosition} description={description} >
      <div className={'p-inputgroup flex align-items-stretch'}>
        <span style={{padding: '12px 12px 12px 8px'}}>
          <Checkbox inputId={fieldKey} id={fieldKey} value={value} disabled={disabled}
                    onChange={(e) => onFieldChange(e.checked)} checked={value && value !== false}
                    tooltip={tooltip} tooltipOptions={tooltipOptions || {position: 'top'}} />
        </span>
        <label htmlFor={fieldKey} className={'flex flex-auto flex-column justify-content-center'}><span>{label}</span>
          {help && (
            <span className={'help text-xs'}>{help}</span>
          )}
        </label>
      </div>
    </UncontrolledBaseField>
  );
};
