import { hookstate, useHookstate, State } from '@hookstate/core';
import { jwtDecode, JwtPayload } from 'jwt-decode';

import { updateSession, resetSession, getUserFromLocalStorage, getTokens } from 'services';
import { isValidUUID, uuidFromIri } from 'utilities';

interface IUserState {
  isAuthenticated: boolean;
  tokens: {
    accessToken: string | null;
    refreshToken: string | null;
    expire: number | null;
  },
  id: string | null;
  username: string | null;
  email: string | null;
  roles: string[] | null;
  lastLogin: string | null;
  firstName: string | null;
  lastName: string | null;
  initials: string | null;
  pictureUrl: string | null;
  dossier: string | null;
  companies: string[] | null;
  societesExperts: string[] | null;

  // Impersonation properties
  previousUserId: string | null;
  previousDossier: string | null;
  previousCompanies: string | null;
  previousSocietesExperts: string[] | null;
  previousTokens: {
    accessToken: string | null;
    refreshToken: string | null;
    expire: number | null;
  },
}

const emptyState: IUserState = {
  tokens: {
    accessToken: null,
    refreshToken: null,
    expire: null
  },
  isAuthenticated: false,
  id: null,
  username: null,
  email: null,
  roles: null,
  lastLogin: null,
  firstName: null,
  lastName: null,
  initials: null,
  pictureUrl: null,
  dossier: null,
  societesExperts: null,
  previousUserId: null,
  previousDossier: null,
  previousSocietesExperts: null,
  previousTokens: {
    accessToken: null,
    refreshToken: null,
    expire: null
  },
} as IUserState;

const state: State<IUserState> = hookstate(Object.assign({}, emptyState));

const wrapper = (s: State<IUserState>) => ({
  isAuthenticated: () => true === s.isAuthenticated.get(),
  logoutUser: () => resetSession(),
  populateUserFromProvider: (data: any, reset: boolean = false) => {
    const user: any = data?.user;
    const accessToken: string = data?.jwt ?? data?.accessToken;
    const refreshToken: string = data?.refreshToken;
    const metadata: any = data?.metadata;

    // Check if we are in the context of an impersonation.
    const previousUser: any = data?.previousUser;
    if (previousUser !== null && previousUser !== undefined && isValidUUID(previousUser.id)) {
      const tokens: any = getTokens();
      const currentUser: any = getUserFromLocalStorage();

      s.previousUserId.set(currentUser.id);
      s.previousDossier.set(currentUser.dossier);
      s.previousCompanies.set(currentUser.companies);
      s.previousSocietesExperts.set(currentUser.societesExperts);
      s.previousTokens.accessToken.set(tokens.accessToken);
      s.previousTokens.expire.set(tokens.expire);
      s.previousTokens.refreshToken.set(tokens.accessTokenrefreshToken);
    } else {
      s.previousUserId.set(null);
      s.previousDossier.set(null);
      s.previousCompanies.set(null);
      s.previousSocietesExperts.set(null);
      s.previousTokens.accessToken.set(null);
      s.previousTokens.expire.set(null);
      s.previousTokens.refreshToken.set(null);
    }

    // Map authenticated state.
    s.isAuthenticated.set(!reset);

    if (!reset) {
      // Map user state.
      s.merge(user);

      // Map initials.
      if (user?.initials !== undefined && user?.initials !== null) {
        s.initials.set(user?.initials);
      } else if (user?.firstName && user?.lastName) {
        s.initials.set(user.firstName.substring(0, 1) + user.lastName.substring(0, 1));
      } else {
        s.initials.set(user?.username.substring(0, 2));
      }

      // Map picture.
      if (user?.pictureUrl !== undefined && user?.pictureUrl !== null) {
        s.pictureUrl.set(user?.pictureUrl);
      } else {
        s.pictureUrl.set(null);
      }

      // Map roles.
      if (user?.roles !== undefined && user?.roles !== null && user?.roles.length > 0) {
        s.roles.set(user.roles);
      } else {
        s.roles.set(null);
      }

      // Flat DOSSIER (if any).
      if (metadata?.dossier) {
        s.dossier.set(uuidFromIri(metadata.dossier));
      } else {
        s.dossier.set(null);
      }

      // Flat COMPANIES (if any).
      if (metadata?.companies && metadata?.companies['hydra:totalItems'] > 0) {
        s.companies.set(metadata?.companies['hydra:member']);
      } else {
        s.companies.set([]);
      }

      // Flat CABINETS (if any).
      if (metadata?.societeExperts && metadata?.societeExperts['hydra:totalItems'] > 0) {
        s.societesExperts.set(metadata?.societeExperts['hydra:member']);
      } else {
        s.societesExperts.set([]);
      }

      const payload = jwtDecode<JwtPayload>(accessToken);
      if (payload?.exp) {
        // Map tokens state.
        s.tokens.accessToken.set(accessToken);
        s.tokens.expire.set(payload.exp);
        s.tokens.refreshToken.set(refreshToken);
      }

      updateSession(s.value);
    } else {
      resetSession();
    }
  },
  id: () => s.id.get(),
  email: () => s.email.get(),
  fullName: () => s.firstName.get() + ' ' + s.lastName.get(),
  title: () => s.firstName.get() + ' ' + s.lastName.get(),
  subtitle: () => s.email.get(),
  short: () => s.firstName.get(),
  initials: () => s.initials.get(),
  pictureUrl: () => s.pictureUrl.get(),
  dossier: () => s.dossier.get(),
  societesExperts: () => s.societesExperts.get(),
  checkAuth: (callback: any, uState: any, always: any | null = null) => {
    if (!uState.isAuthenticated()) {
      const jsonUser = getUserFromLocalStorage();
      if (jsonUser && jsonUser.tokens) {
        if (Date.now().toString().substring(0, 10) < jsonUser.tokens.expire.toString().substring(0, 10)) {
          uState.populateUserFromProvider({
            user: jsonUser,
            accessToken: jsonUser.tokens.accessToken,
            refreshToken: jsonUser.tokens.refreshToken,
            metadata: {dossier: jsonUser?.dossier}
          });
          callback();
        }
      }
    } else {
      callback();
    }

    if (always) {
      always();
    }
  }
})

export const useUserState = () => wrapper(useHookstate(state));
