import React, { useState } from 'react';

import { Button } from 'primereact/button';
import { Fieldset } from 'primereact/fieldset';

import { RepartitionCapitalDatatable } from 'components';
import { isAGE, trans, ucfirst } from 'utilities';
import { isAdmin, isExpertAdmin } from 'services';

import appUri from 'config/appUri.json';

export const Step5 = (props: any) => {
  const { data, apim, baseUrl, cancelUrl, header, onRefresh } = props;
  const { t, navigate} = apim.di();

  const [associates] = useState<any[]>((data?.associates || []).filter((a: any) => isAdmin() ? true : a?.active));

  return (
    <div className={'a8-wizard wizard-societe-client card fadein animation-duration-500'}>
      <h5>{trans(t, 'menu|wizard.company.client.title.step5')}</h5>

      <div className={'col-12'}>
        <Fieldset legend={ucfirst(trans(t, 'repartitionCapital'))} toggleable>
          <RepartitionCapitalDatatable rows={associates} apim={apim} title={'none'} editMode={isAdmin() || isExpertAdmin() || isAGE(data?.event)} header={header} onRefresh={onRefresh}/>
        </Fieldset>
      </div>

      <div className={'text-right m-2 mr-3'}>
        <>
          <Button type={'reset'} className={'text-ucfirst p-button-text p-button-danger mr-5'} label={trans(t, 'close')} onClick={() => navigate(cancelUrl)} />
          <span className={'p-buttonset'}>
            <Button type={'submit'} className={'text-ucfirst p-button-text'} label={trans(t, 'back')} icon={'pi pi-arrow-left'} onClick={() => navigate(baseUrl + appUri.cie.cli.wizard.step4)} />
            {(isAdmin() || isExpertAdmin() || isAGE(data?.event)) && (
              <Button type={'submit'} className={'text-ucfirst'} label={trans(t, 'terminate')} icon={'pi pi-check'} onClick={() => navigate(cancelUrl)} />
            )}
          </span>
        </>
      </div>
    </div>
  );
};
