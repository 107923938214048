import React, { useCallback, useEffect, useState } from 'react';

import { AutoComplete, AutoCompleteCompleteEvent } from 'primereact/autocomplete';
import { Skeleton } from 'primereact/skeleton';

import { isValidUUID, trans, ucfirst } from 'utilities';
import { IRequestParam, IRequestParams, useApim } from 'services';
import { UncontrolledBaseField } from './UncontrolledBaseField';

import appConfig from 'config/appConfig.json';
import { forEach, isArray, isString } from 'lodash';

export const UncontrolledAPIAutocompleteField = (props: any) => {
  const { fieldKey, value, onFieldChange, label, placeholder, classes, tooltip, tooltipOptions, description, action, displayMaxChar,
    multiple, addon, addonPosition, disabled, resourceType, params, itemTemplate, optionKey, optionValue, formatter, forceSelection } = props;

  const apim = useApim();
  const { t } = apim.di();

  const [iItems, setIItems] = useState<any[]>();
  const [items, setItems] = useState<any[]>();
  const [loading, setLoading] = useState<boolean>(false);

  // Load initial field value as object.
  // We have a UUID and we want to loaded object.
  useEffect(() => {
    if (!value) return;

    const vs: any[] = isArray(value) ? value : [value];
    if (vs.length > 0) {
      const isValidId: boolean = isValidUUID(isArray(value) ? value[0].id ?? value[0] : value);

      setLoading(true);
      const initParams = params ?? [];
      initParams.push({label: 'itemsPerPage', value: vs.length});
      forEach(vs, ((val: any) => initParams.push(
        // Try id first.
        isValidId ? { label: 'id[]', value: isString(val) ? val : val.id }
        // Use optionKey else.
        : { label: optionKey ?? 'id[]', value: isString(val) ? val : val[optionKey ?? 'id'] }
      )));

      if (action) {
        apim.call({
          resourceType,
          action,
          method: 'get',
          params: initParams,
          notif: false,
          setter: setIItems,
          formatter,
          setLoading
        } as IRequestParams).then();
      } else {
        apim.fetchEntities({
          resourceType,
          params: initParams,
          setter: setIItems,
          formatter,
          setLoading
        } as IRequestParams).then();
      }
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const search = (event: AutoCompleteCompleteEvent) => {
    const fullParams = params ?? [];
    forEach([{ label: 'itemsPerPage', value: appConfig.pagination.autocomplete.itemsPerPage }], ((p: IRequestParam) => {
      if (fullParams.filter((fp: IRequestParam) => fp.label === p.label).length === 0) {
        fullParams.push(p);
      }
    }));
    fullParams.push({label: 'search', value: event.query});

    if (action) {
      apim.call({
        resourceType: resourceType,
        action: action,
        method: 'get',
        params: fullParams,
        notif: false,
        setter: setItems,
        formatter: formatter,
      } as IRequestParams).then();
    } else {
      apim.fetchEntities({
        resourceType: resourceType,
        params: fullParams,
        setter: setItems,
        formatter: formatter,
      } as IRequestParams).then();
    }
  }

  const defaultItemTemplate = (option: any, props: any) => {
    if (option) {
      return (
        <div className={'flex align-items-center'}>
          <div>{option[optionKey ?? 'id']}</div>
        </div>
      );
    }

    return <span>{props.placeholder}</span>;
  };

  const buildFirstVal = useCallback(() => {
    if ((isArray(value) ? value : [value]).length === 0) {
      return [];
    }

    if (!items) {
      if (iItems) {
        if (!multiple && iItems.length > 0) {
          return iItems[0].value === 'none' ? (iItems.length > 1 ? iItems[1] : iItems[0]) : iItems[0];
        }

        return iItems.filter((_i: any) => {
          return value.includes(_i.id ?? _i.value ?? _i);
        });
      }
    }

    return value;
  }, [value, iItems]); // eslint-disable-line react-hooks/exhaustive-deps

  let _val: any = buildFirstVal();

  // Limit display to authorized max char.
  if (displayMaxChar) {
    if (multiple) {
      forEach(value ?? [], ((_v: any, i: number) => {
        if (!_v[optionKey ?? 'id'] || (_v[optionKey ?? 'id'] && _v[optionKey ?? 'id'].length <= displayMaxChar)) return;

        const nV: any = {};
        nV[optionKey ?? 'id'] = _v[optionKey ?? 'id'].substring(0, displayMaxChar) + '...';
        _val[i] = {..._v, ...nV};
      }));
    } else {
      if (_val[optionKey ?? 'id'] && _val[optionKey ?? 'id'].length > displayMaxChar) {
        _val[optionKey ?? 'id'] = _val[optionKey ?? 'id'].substring(0, displayMaxChar) + '...';
      }
    }
  }

  return (
    <div>
      <UncontrolledBaseField classes={classes} addon={addon} addonPosition={addonPosition} description={description} >
        {loading ? <Skeleton height={'2.8rem'} /> :
          <span className={'p-float-label'}>
            <AutoComplete id={fieldKey} className={'w-full'} placeholder={placeholder ?? ucfirst(trans(t, 'form|select'))} value={_val}
                          dropdown forceSelection={forceSelection} multiple={true === multiple} tooltip={tooltip} tooltipOptions={tooltipOptions ?? { position: 'top' }} disabled={disabled}
                          suggestions={items ?? []} completeMethod={search} field={optionKey ?? 'id'} itemTemplate={itemTemplate ?? defaultItemTemplate}
                          onChange={(e) => {
                            onFieldChange(optionValue && e.value && e.value[optionValue] ? e.value[optionValue] : e.value)
                          }} />
            {(label ?? fieldKey) && (
              <label className={'text-ucfirst p-float-label-label'} htmlFor={fieldKey}>
                <span>{label ?? trans(t, fieldKey)}</span>
              </label>
            )}
          </span>
        }
      </UncontrolledBaseField>
    </div>
  );
};
