import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Button } from 'primereact/button';
import { Card } from 'primereact/card';

import { IRequestParams, isClient, useApim } from 'services';
import { useAppState } from 'states';
import { trans, ucfirst } from 'utilities';
import { Error, NotFound, Unauthorized } from 'pages';
import { PageLoader } from 'components';
import { PatrimoineFormLanding } from './patrimoine';
import { PrevoyanceFormLanding } from './prevoyance';
import { MissionsFormLanding } from './missions';

import fiscalExerciceImage from 'assets/images/illustrations/landing/fiscal-exercice.jpg';
import annualDataImage from 'assets/images/illustrations/landing/annual-data.jpg';
import dossierMissionsImage from 'assets/images/illustrations/landing/missions.jpg';
import dossierSettingsImage from 'assets/images/illustrations/landing/settings.jpg';
import prevoyanceImage from 'assets/images/illustrations/landing/prevoyance.jpg';
import patrimoineImage from 'assets/images/illustrations/landing/patrimoine.jpg';

import appUri from 'config/appUri.json';

export const DossierFormLanding = () => {
  const { id, module, tab  } = useParams();
  const apim = useApim();
  const { t, navigate } = apim.di();
  const appState = useAppState();
  const dossier: any = appState.dossier();

  const [loading, setLoading] = useState<boolean>(false);
  const [notFound, setNotFound] = useState<boolean>(false);
  const [errored, setErrored] = useState<boolean>(false);

  useEffect(() => {
    if (dossier?.id === id) return;

    // Update AppState Dossier.
    apim.fetchEntity({
      resourceType: 'dossiers',
      id,
      setLoading,
      setErrored,
      setNotFound,
      setter: appState.setDossier,
    } as IRequestParams).then();
  }, [id]); // eslint-disable-line react-hooks/exhaustive-deps

  // Update active index & Page Header.
  useEffect(() => {
    const bc: any[] = module ? [
      { label: trans(t, 'dossier', 2), to: appUri.dos.list },
      { label: dossier?.title, to: appUri.dos.page.replace(':id', dossier?.id) },
      { label: trans(t, 'editing'), to: appUri.dos.edit.landing.replace(':id', dossier?.id) },
      { label: ucfirst(trans(t, 'menu|module.' + module)) }
    ] : [
      { label: trans(t, 'dossier', 2), to: appUri.dos.list },
      { label: dossier?.title, to: appUri.dos.page.replace(':id', dossier?.id) },
      { label: trans(t, 'editing'), to: appUri.dos.edit.landing.replace(':id', dossier?.id) },
    ];

    appState.setBreadcrumb(bc);
    appState.setPageTitle(ucfirst(trans(t, 'dossier')) + ' ' + appState.dossier()?.title + ' | ' + trans(t, 'editing'));
  }, [dossier?.id, module]); // eslint-disable-line react-hooks/exhaustive-deps

  if (isClient()) return <Unauthorized asBlock/>;
  if (!dossier?.id || loading) return <PageLoader/>;
  if (notFound) return <NotFound asBlock/>;
  if (errored) return <Error asBlock/>;

  // Return module's forms first.
  if ('missions' === module) return <MissionsFormLanding apim={apim} appState={appState} dossier={dossier}/>;
  if ('patrimoine' === module) return <PatrimoineFormLanding apim={apim} dossier={dossier} tab={tab}/>;
  if ('prevoyance' === module) return <PrevoyanceFormLanding apim={apim} dossier={dossier} tab={tab}/>;

  const header = (image: string) => <img alt={'form-landing-header'} src={image}
                                         className={'border-round border-noround-bottom'}/>;
  const headerH = (image: string) => <img alt={'form-landing-header'} src={image}
                                          className={'border-round border-noround-right'}/>;

  return (
    <div className={'p-2 flex justify-content-center'}>
      <div>
        <h2 className={'mb-3 text-center'}>{trans(t, 'menu|landing.dossier.title')}</h2>
        <p className={'mb-5 text-center'}>{trans(t, 'menu|landing.dossier.subtitle')}</p>

        <div className={'a8-dossier-card-wrapper'}>
          <div className={'flex gap-5 justify-content-center'}>
            <Card title={trans(t, 'menu|landing.dossier.exerciceFiscal.title')}
                  subTitle={trans(t, 'menu|landing.dossier.exerciceFiscal.subtitle')}
                  header={header(fiscalExerciceImage)} className={'a8-dossier-card-item col'}
                  onClick={() => navigate(appUri.dos.edit.fiscalYear.uri
                    .replace(':id', dossier?.id)
                    .replace(':company', appState.company()?.id)
                    .replace(':year', (appState.exercise()).toString()) + appUri.dos.edit.fiscalYear.steps.step1
                  )}>
              <p className={'m-0'}>{trans(t, 'menu|landing.dossier.exerciceFiscal.body')}</p>
            </Card>

            <Card title={trans(t, 'menu|landing.dossier.civilYear.title')}
                  subTitle={trans(t, 'menu|landing.dossier.civilYear.subtitle')}
                  header={header(annualDataImage)} className={'a8-dossier-card-item col'}
                  onClick={() => navigate(appUri.dos.edit.civilYear.uri
                    .replace(':id', dossier?.id)
                    .replace(':year', appState.civilYear()) + appUri.dos.edit.civilYear.steps.step1
                  )}>
              <p className={'m-0'}>{trans(t, 'menu|landing.dossier.civilYear.body')}</p>
            </Card>

            <Card title={trans(t, 'menu|landing.dossier.missions.title')}
                  subTitle={trans(t, 'menu|landing.dossier.missions.subtitle')} header={header(dossierMissionsImage)}
                  className={'a8-dossier-card-item col'}
                  onClick={() => navigate(appUri.dos.edit.missions.select.replace(':id', dossier?.id) + '/new')}>
              <p className={'m-0'}>{trans(t, 'menu|landing.dossier.missions.body')}</p>
            </Card>
          </div>

          <div className={'flex gap-5 my-5'}>
            <Card title={trans(t, 'menu|landing.dossier.prevoyance.title')}
                  subTitle={trans(t, 'menu|landing.dossier.prevoyance.subtitle')}
                  header={headerH(prevoyanceImage)}
                  className={'a8-dossier-card-item a8-dossier-card-item--horizontal flex'}
                  onClick={() => navigate(appUri.dos.edit.prevoyance.uri.replace(':id', dossier?.id))}>
              <p className={'m-0'}>{trans(t, 'menu|landing.dossier.prevoyance.body')}</p>
            </Card>

            <Card title={trans(t, 'menu|landing.dossier.patrimoine.title')}
                  subTitle={trans(t, 'menu|landing.dossier.patrimoine.subtitle')}
                  header={headerH(patrimoineImage)}
                  className={'a8-dossier-card-item a8-dossier-card-item--horizontal flex'}
                  onClick={() => navigate(appUri.dos.edit.patrimoine.uri.replace(':id', dossier?.id))}>
              <p className={'m-0'}>{trans(t, 'menu|landing.dossier.patrimoine.body')}</p>
            </Card>
          </div>

          <div className={'flex gap-5 my-5'}>
            <div className={'col'}>
              <Card title={trans(t, 'menu|landing.dossier.settings.title')}
                    subTitle={trans(t, 'menu|landing.dossier.settings.subtitle')}
                    header={headerH(dossierSettingsImage)}
                    className={'a8-dossier-card-item a8-dossier-card-item--horizontal flex'}
                    onClick={() => navigate(appUri.dos.settings.uri.replace(':id', dossier?.id))}>
                <p className={'m-0'}>{trans(t, 'menu|landing.dossier.settings.body')}</p>
              </Card>
            </div>
          </div>
        </div>

        <div className={'flex align-items-center justify-content-center mt-6'}>
          <Button icon={'pi pi-chevron-left'} link label={trans(t, 'form|backToDossier')}
                  onClick={() => navigate(appUri.dos.page.replace(':id', dossier?.id))}/>
        </div>
      </div>
    </div>
  )
};
