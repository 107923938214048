import { montant, shortNumber, trans } from 'utilities';

export const formatXYChart = (dataSource: any, key: string, t: any, opt: any = null) => {
  if (!dataSource?.charts || (dataSource?.charts['hydra:member'] || []).length < 1) return null;

  const options = {...{
    method: 'montant',
    tradCategories: true,
  }, ...opt};

  const charts: any = (dataSource?.charts ? (dataSource?.charts['hydra:member'] || []) : []).filter((c: any) => c.key === key);
  if (charts.length === 0) {
    return {
      title: trans(t, 'chart|title'),
      data: {}
    };
  }

  // Build chart data according to the series.
  const chart = charts[0];
  const series = chart.series;

  // Build data.
  let data = series.map((serie: any) => {
    let method = options.method || 'montant';

    // Use the backend specified symbol if any.
    const symbol = serie?.options?.symbol || null;
    if (symbol === '%') {
      method = 'percentage';
    }

    // Use the backend specified precision if any.
    let precision = serie?.options?.precision || 1;

    return {
      label: serie.translated || trans(t, 'chart|' + serie.key),
      seriesType: getSerieTypeByKey(serie.key),
      values: serie.values?.map((sv: any) => {
        // Determine the tooltip to display.
        let tooltip: string = '';
        if (method === 'montant') {
          tooltip = montant(sv.value);
        } else if (method === 'asIt') {
          tooltip = sv.value;
        } else if (method === 'percentage') {
          tooltip = shortNumber(sv.value, {precision: precision, symbol: symbol});
        } else if (method === 'label') {
          tooltip = sv.value.label;
        } else if (typeof method === 'function') {
          tooltip = method(sv.value);
        }

        // Determine the text to display on hover.
        let hoverTooltip: string = tooltip;
        if (sv.tooltip !== null && sv.tooltip !== undefined && sv.tooltip.length > 0) {
          hoverTooltip = sv.tooltip;
        }

        // Determine the value to use.
        let value: any = sv.value;
        if (value !== null && typeof value === 'object' && value.value !== null) {
          value = value.value;
        }

        // Determine the date to use.
        let date = null;
        if (sv.date !== null && sv.date !== undefined) {
          date = new Date();

          if (sv.date.toString().length === 4) {
            date.setFullYear(sv.date);
          }
        }

        return {...sv, ...{
          category: (options.tradCategories !== false) ? trans(t, 'chart|' + sv.category) : sv.category,
          date: date ? date.getTime() : null,
          tooltip: tooltip,
          hoverTooltip: hoverTooltip,
          value: value,
        }};
      })
    }
  });

  return {
    title: trans(t, 'chart|' + chart.key),
    data: data,
  };
};

const getSerieTypeByKey = (key: string) => {
  switch (key) {
    case 'dirigeantSocialData.chart.historiqueCotisationsSociales.serie.cotisationsSocialesObligatoires':
    case 'dirigeantSocialData.chart.historiqueCotisationsSociales.serie.ressourcesTotales':
    case 'dirigeantSocialData.chart.historiqueCotisationsSociales.serie.somme':
    case 'dirigeantSocialData.chart.historiqueCotisationsSociales.serie.versementsContratsFacultatifs':
    case 'dirigeantSocialData.chart.historiqueCotisationsSocialesPourcentageRessourcesTotales.serie.cotisationsSocialesObligatoires':
    case 'dirigeantSocialData.chart.historiqueCotisationsSocialesPourcentageRessourcesTotales.serie.somme':
    case 'dirigeantSocialData.chart.historiqueCotisationsSocialesPourcentageRessourcesTotales.serie.versementsContratsFacultatifs':
    case 'missionRetraite.chart.projectionMontantAnnuelRetraite.serie.actuel':
    case 'missionRetraite.chart.projectionMontantAnnuelRetraite.serie.preconise':
    case 'revenuImposition.chart.tauxMoyenEtRevenuEvolution.serie.revenuDisponible':
    case 'revenuImposition.chart.tauxMoyenEtRevenuEvolution.serie.tauxMoyen':
    case 'socialCollectiveData.chart.masseSalariale.serie':
    case 'socialCollectiveData.chart.masseSalarialePourcentage.serie':
    case 'tresorerie.emprunt.capitalDuEtAnnuites.annuites':
      return 'LineSeries';

    default:
      return 'ColumnSeries';
  }
};

export const formatPieChart = (dataSource: any, key: string, t: any, opt: any = null) => {
  const options = {...{
      method: 'montant',
      tradCategories: true,
    }, ...opt};

  const chart: any = (dataSource.charts['hydra:member'] || []).filter((c: any) => c.key === key);
  if (chart.length === 0) return {
    title: null,
    data: {}
  };

  const formattedChart: any = {
    title: trans(t, 'chart|' + chart[0].key),
    data: chart[0].series[0].values?.map((sv: any) => {
      const label: string = (options.tradCategories !== false) ? trans(t, 'chart|' + sv.category) : sv.category;

      return {...sv, ...{
        value: sv.value,
        label: label,
        tooltip: 'asIt' === (options.method || 'montant') ? sv.value : montant(sv.value),
        settings: {
          labelDisabled: options?.hideLabels || label?.toLowerCase() === 'autre'
        }
      }};
    })
  };

  if (chart[0].options?.label) {
    formattedChart.label = chart[0].options.label;
  }

  return formattedChart;
};
