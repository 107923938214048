import React, { useCallback, useEffect, useState } from 'react';
import { AxiosResponse } from 'axios';

import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';

import { dateString, period, trans } from 'utilities';
import { IRequestParams, isClient } from 'services';
import { AMChartXY, formatTable, formatXYChart, getModuleId, IndicatorSimple, PageLoader } from 'components';
import { getIndicator } from 'pages';
import { useThemeState } from 'states';

import appUri from 'config/appUri.json';

export const TresorerieEtEmprunt = (props: any) => {
  const { id, appState, apim, modulesMenu, lastUpdate } = props;
  const { t} = apim.di();
  const themeState = useThemeState();

  const dossier = appState.dossier();
  const [loading, setLoading] = useState<boolean>(false);
  const [data, setData] = useState<any>(null);

  // Override Page Header (defaults into ModuleWrapper, then TresorerieRouter).
  useEffect(() => {
    appState.setBreadcrumb([
     isClient()
      ? { label: trans(t, 'dossier', 2) }
      : { label: trans(t, 'dossier', 2), to: appUri.dos.list },
     { label: dossier.title, to: appUri.dos.page.replace(':id', id)},
     { label: trans(t, 'menu|menu.schema_flux.tresorerie_emprunt') }
    ]);

    appState.setModule(getModuleId(modulesMenu, 'tresorerie.emprunt'));
  }, [id]); // eslint-disable-line react-hooks/exhaustive-deps

  const overrideTable1 = (dataSource: any) => {
    return {...dataSource, ...{
        data: dataSource.data?.map((loan: any, i: number) => {
          return {...loan, ...{
              'nomEmprunt': i === dataSource.data?.length ? trans(t, 'table|total').toUpperCase() : loan.nomEmprunt,
              'groupe': loan.groupe ? trans(t, loan.groupe === true ? 'table|yes' : 'table|no') : null,
              'dateDebut': dateString(loan.dateDebut),
              'dateFin': dateString(loan.dateFin)
            }}
        })
      }
    }
  };

  const fetchData = useCallback(async () => {
    if (!appState.exercise()) return null;
    setLoading(true);

    apim.call({
      resourceType: 'dossiers',
      action: 'module',
      id: id + '|' + getModuleId(modulesMenu, 'tresorerie.emprunt'),
      method: 'get',
      params: [{label: 'company', value: appState.company()?.id}, {label: 'year', value: appState.exercise()}],
      setLoading: setLoading,
      success: (res: AxiosResponse) => setData({...res.data, ...{
          table1: overrideTable1(formatTable(res.data, 'tresorerie.emprunt.table.emprunt', t, {method: 'simpleRows'})),
          chart1: formatXYChart(res.data, 'tresorerie.emprunt.capitalDuEtAnnuites', t, {tradCategories: false}),
          chart2: formatXYChart(res.data, 'tresorerie.banqueDeFranceNoteEvolution', t, {tradCategories: false, method: 'label'})
      }})
    } as IRequestParams);
  }, [appState.company()?.id, appState.exercise(), lastUpdate]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    fetchData().then();
  }, [appState.company()?.id, appState.exercise(), lastUpdate]); // eslint-disable-line react-hooks/exhaustive-deps

  if (loading) return <PageLoader />;

  return (
    <div className={'flex flex-column md:flex-row flex-wrap fadein animation-duration-500'}>
      <div className={'flex md:w-4 xl:w-3 py-4 pl-2 pr-3'}>
        <IndicatorSimple color={'primary'} dark bgImage title={trans(t, 'chart|tresorerie.montantTotalEmprunt')} value={getIndicator(data, 'tresorerie.montantTotalEmprunt').value} tooltip={getIndicator(data, 'tresorerie.montantTotalEmprunt').options?.tooltip ? trans(t, 'chart|' + getIndicator(data, 'tresorerie.montantTotalEmprunt').options?.tooltip) : null} precision={getIndicator(data, 'tresorerie.montantTotalEmprunt').options?.precision ?? 1} />
      </div>

      <div className={'flex md:w-4 xl:w-3 py-4 px-3'}>
        <IndicatorSimple color={'accent2'} bgImage title={trans(t, 'chart|tresorerie.tauxEndettement')}
                         value={getIndicator(data, 'tresorerie.tauxEndettement').value}
                         tooltip={getIndicator(data, 'tresorerie.tauxEndettement').options?.tooltip ? trans(t, 'chart|' + getIndicator(data, 'tresorerie.tauxEndettement').options?.tooltip) : null}
                         precision={getIndicator(data, 'tresorerie.tauxEndettement').options?.precision ?? 0}
                         symbol={getIndicator(data, 'tresorerie.tauxEndettement').options?.symbol ?? '%'}/>
      </div>

      <div className={'flex md:w-4 xl:w-3 py-4 px-3'}>
        <IndicatorSimple color={'accent2'} bgImage title={trans(t, 'chart|tresorerie.effetDeLevier')}
                         value={getIndicator(data, 'tresorerie.effetDeLevier').value}
                         tooltip={getIndicator(data, 'tresorerie.effetDeLevier').options?.tooltip ? trans(t, 'chart|' + getIndicator(data, 'tresorerie.effetDeLevier').options?.tooltip) : null}
                         precision={getIndicator(data, 'tresorerie.effetDeLevier').options?.precision ?? 0}
                         symbol={getIndicator(data, 'tresorerie.effetDeLevier').options?.symbol ?? '%'} />
      </div>

      <div className={'flex md:w-4 xl:w-3 py-4 pl-3 pr-2'}>
        <IndicatorSimple color={'primary'} bgImage title={trans(t, 'chart|tresorerie.capaciteEmprunt')}
                         value={getIndicator(data, 'tresorerie.capaciteEmprunt').value}
                         tooltip={getIndicator(data, 'tresorerie.capaciteEmprunt').options?.tooltip ? trans(t, 'chart|' + getIndicator(data, 'tresorerie.capaciteEmprunt').options?.tooltip) : null}
                         precision={getIndicator(data, 'tresorerie.capaciteEmprunt').options?.precision ?? 0}
                         symbol={getIndicator(data, 'tresorerie.capaciteEmprunt').options?.symbol ?? null}
        />
      </div>

      <div className={'flex md:w-4 xl:w-3 py-4 pl-2 pr-3'}>
        <IndicatorSimple color={'primary'} bgImage title={trans(t, 'chart|tresorerie.totalEmpruntCAF')}
                         text={period(getIndicator(data, 'tresorerie.totalEmpruntCAF').value)}
                         unite={'none'} />
      </div>

      <div className={'flex md:w-4 xl:w-3 py-4 px-3'}>
        <IndicatorSimple color={'primary'} bgImage title={trans(t, 'chart|tresorerie.partDePlacement')}
                         value={getIndicator(data, 'tresorerie.partDePlacement').value}
                         tooltip={getIndicator(data, 'tresorerie.partDePlacement').options?.tooltip ? trans(t, 'chart|' + getIndicator(data, 'tresorerie.partDePlacement').options?.tooltip) : null}
                         precision={getIndicator(data, 'tresorerie.partDePlacement').options?.precision ?? 0}
                         symbol={getIndicator(data, 'tresorerie.partDePlacement').options?.symbol ?? null} />
      </div>

      <div className={'flex md:w-4 xl:w-3 py-4 px-3'}>
        <IndicatorSimple color={'primary'} bgImage title={trans(t, 'chart|tresorerie.duClient')}
                         value={getIndicator(data, 'tresorerie.duClient').value}
                         tooltip={getIndicator(data, 'tresorerie.duClient').options?.tooltip ? trans(t, 'chart|' + getIndicator(data, 'tresorerie.duClient').options?.tooltip) : null}
                         precision={getIndicator(data, 'tresorerie.duClient').options?.precision ?? 0}
                         symbol={getIndicator(data, 'tresorerie.duClient').options?.symbol ?? null} />
      </div>

      <div className={'flex md:w-4 xl:w-3 py-4 pl-3 pr-2'}>
        <IndicatorSimple color={'primary'} bgImage title={trans(t, 'chart|tresorerie.stock')}
                         value={getIndicator(data, 'tresorerie.stock').value}
                         tooltip={getIndicator(data, 'tresorerie.stock').options?.tooltip ? trans(t, 'chart|' + getIndicator(data, 'tresorerie.stock').options?.tooltip) : null}
                         precision={getIndicator(data, 'tresorerie.stock').options?.precision ?? 0}
                         symbol={getIndicator(data, 'tresorerie.stock').options?.symbol ?? null} />
      </div>

      {!themeState.isDesktop() ? (
        <div className={'md:w-4 py-4 px-3'}>
          <IndicatorSimple color={'accent'} bgImage title={trans(t, 'chart|tresorerie.ecartTauxDuMarche')}
                           value={getIndicator(data, 'tresorerie.ecartTauxDuMarche').value}
                           tooltip={getIndicator(data, 'tresorerie.ecartTauxDuMarche').options?.tooltip ? trans(t, 'chart|' + getIndicator(data, 'tresorerie.ecartTauxDuMarche').options?.tooltip) : null}
                           precision={getIndicator(data, 'tresorerie.ecartTauxDuMarche').options?.precision ?? 0}
                           symbol={getIndicator(data, 'tresorerie.ecartTauxDuMarche').options?.symbol ?? null} />
        </div>):('')}

      <div className={'flex w-12 xl:w-9 py-4 px-3'}>
        {themeState.isMobile() || themeState.isTablet() ? (
          <div className={'flex h-30rem flex-row flex-grow-1'}>
            <AMChartXY chartData={data?.chart1} showCursor verticalLegend />
          </div>
        ) : themeState.isNotebook() ? (
          <div className={'flex h-30rem flex-row flex-grow-1'}>
            <AMChartXY chartData={data?.chart1} showCursor />
          </div>
        ) : (
          <div className={'flex flex-column flex-grow-1'}>
            <AMChartXY chartData={data?.chart1} showCursor hideXlabels/>
          </div>
        )}
      </div>

      {themeState.isDesktop() ? (
        <div className={'md:w-3 py-4 px-3'}>
          <IndicatorSimple color={'accent'} bgImage title={trans(t, 'chart|tresorerie.ecartTauxDuMarche')}
                           value={getIndicator(data, 'tresorerie.ecartTauxDuMarche').value}
                           tooltip={getIndicator(data, 'tresorerie.ecartTauxDuMarche').options?.tooltip ? trans(t, 'chart|' + getIndicator(data, 'tresorerie.ecartTauxDuMarche').options?.tooltip) : null}
                           precision={getIndicator(data, 'tresorerie.ecartTauxDuMarche').options?.precision ?? 0}
                           symbol={getIndicator(data, 'tresorerie.ecartTauxDuMarche').options?.symbol ?? null} />
        </div>):('')}

      <div className={'grid p-fluid mt-3 w-12'}>
        <div className={'col-12'}>
          <DataTable value={data?.table1?.data} className={'datatable-responsive'} emptyMessage={trans(t, 'table|loan_none')} stripedRows={true}>
            <Column field={'nomEmprunt'} />
            <Column field={'groupe'} header={trans(t, 'table|' + data?.table1?.headers[1])} />
            <Column field={'banque'} header={trans(t, 'table|' + data?.table1?.headers[2])} />
            <Column field={'montantEmprunte'} header={trans(t, 'table|' + data?.table1?.headers[3])} align={'right'} />
            <Column field={'taux'} header={trans(t, 'table|' + data?.table1?.headers[4])} align={'center'} alignHeader={'center'} />
            <Column field={'dateDebut'} header={trans(t, 'table|' + data?.table1?.headers[5])} align={'center'} alignHeader={'center'} />
            <Column field={'dateFin'} header={trans(t, 'table|' + data?.table1?.headers[6])} align={'center'} alignHeader={'center'} />
            <Column field={'mensualite'} header={trans(t, 'table|' + data?.table1?.headers[7])} align={'right'} />
            <Column field={'capitalRestantDu'} header={trans(t, 'table|' + data?.table1?.headers[8])} align={'right'} />
            <Column field={'ecartTauxMarche'} header={trans(t, 'table|' + data?.table1?.headers[9])} align={'center'} alignHeader={'center'} />
            <Column field={'ira'} header={trans(t, 'table|' + data?.table1?.headers[10])} />
          </DataTable>
        </div>
      </div>

      <div className={'md:w-3 py-4 px-3'}>
        <IndicatorSimple color={'accent'} bgImage title={trans(t, 'chart|tresorerie.noteBanqueDeFrance')} method={'asIt'} value={getIndicator(data, 'tresorerie.noteBanqueDeFrance').value} options={{symbol: ''}} />
      </div>

      <div className={'flex md:w-9 py-4 px-3'}>
        <div className={'flex flex-row flex-grow-1'}>
          <AMChartXY chartData={data?.chart2} showCursor min={0} max={12} maxPrecision={0} />
        </div>
      </div>
    </div>
  );
};
