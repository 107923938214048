import React from 'react';
import { Column } from 'primereact/column';

import { trans, ucfirst } from 'utilities';
import { DatatableWrapper, dateTimeCell, simpleCheckboxEditor, textEditor, validCell } from 'components';

export const SettingsBankDatatable = (props: any) => {
  const { lazyConfig, apim, tablekey, title, resourceType } = props;
  const { t } = apim.di();

  const lazyC = { ...{ sortField: 'name', sortOrder: 1 }, ...lazyConfig };
  const groupBodyTemplate = (rowData: any) => validCell(rowData?.group);
  const updatedBodyTemplate = (rowData: any) => dateTimeCell(rowData?.updated);
  const createdBodyTemplate = (rowData: any) => dateTimeCell(rowData?.created);
  const activeBodyTemplate = (rowData: any) => validCell(rowData?.active);
  // @todo when new row is added then edited, data flashes in empty row

  return (
    <DatatableWrapper resourceType={resourceType} lazyConfig={lazyC} tableKey={tablekey} title={title} editMode
                      editFields={['name', 'group', 'active']} noFilters>
      <Column field={'name'} header={ucfirst(trans(t, 'name'))} sortable
              editor={(options) => textEditor(options, { label: trans(t, 'name') })} />
      <Column field={'group'} header={ucfirst(trans(t, 'group'))} sortable align={'center'} body={groupBodyTemplate}
              editor={(options) => simpleCheckboxEditor(options, { label: trans(t, 'group') })}
              style={{ width: '5vw' }} />
      <Column field={'updated'} header={trans(t, 'updated')} sortable align={'center'} body={updatedBodyTemplate}
              style={{ width: '15vw' }} />
      <Column field={'created'} header={trans(t, 'created')} sortable align={'center'} body={createdBodyTemplate}
              style={{ width: '15vw' }} />
      <Column field={'active'} header={trans(t, 'active')} sortable align={'center'} body={activeBodyTemplate}
              editor={(options) => simpleCheckboxEditor(options, { label: trans(t, 'table|active') })}
              style={{ width: '5vw' }} />
    </DatatableWrapper>
  );
};
