import React, { useEffect, useMemo, useState } from 'react';
import { Fieldset } from 'primereact/fieldset';

import { Error, NotFound } from 'pages';
import { ContractBeneficiairesDatatable, EmprunteursDatatable, IndicatorSimple, PageLoader, SimpleText, Userpicture } from 'components';
import { IRequestParams, isClient } from 'services';
import { dateString, isValidUUID, trans, ucfirst } from 'utilities';

import appUri from 'config/appUri.json';

export const PatrimoineCredit = (props: any) => {
  const { apim, appState, type, id } = props;
  const { t} = apim.di();
  const dossier: any = appState?.dossier();

  const [loading, setLoading] = useState<boolean>(false);
  const [data, setData] = useState<any>();
  const [bank, setBank] = useState<any>();
  const [types, setTypes] = useState<any[]>([]);
  const [notFound, setNotFound] = useState<boolean>(false);
  const [errored, setErrored] = useState<boolean>(false);

  useEffect(() => {
    apim.getList('creditTypes', {setter: setTypes} as IRequestParams).then();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => fetchData(), [dossier?.id, id]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (isValidUUID(data?.bank)) fetchBank(data?.bank);
  }, [data]); // eslint-disable-line react-hooks/exhaustive-deps

  const fetchData = () => {
    if ((data?.id === id) || !isValidUUID(id)) return;

    setLoading(true);
    apim.fetchEntity({
      resourceType: 'credits',
      id: id,
      setLoading,
      setErrored,
      setNotFound,
      setter: setData
    } as IRequestParams).then();
  }

  const fetchBank = (bankId: any) => {
    if (!isValidUUID(bankId)) return;

    apim.fetchEntity({
      resourceType: 'banks',
      id: bankId,
      setter: setBank
    } as IRequestParams).then();
  }

  // Override Page Header (defaults into ModuleWrapper, then PatrimoineRouter).
  useEffect(() => {
    appState.setBreadcrumb([
      isClient()
        ? { label: trans(t, 'dossier', 2) }
        : { label: trans(t, 'dossier', 2), to: appUri.dos.list },
      { label: dossier.title, to: appUri.dos.page.replace(':id', dossier?.id) },
      { label: trans(t, 'menu|menu.schema_flux.patrimoine_biens'), to: appUri.dos.page.replace(':id', dossier?.id) + '/patrimoine/biens' },
      { label: data?.label ?? data?.company?.latestVersion?.raisonSociale ?? trans(t, 'credit') }
    ]);
  }, [dossier?.id, id, type, data?.id]); // eslint-disable-line react-hooks/exhaustive-deps

  const getType = useMemo(() => {
    const match: any = types.filter((_t: any) => _t.value === data?.type);

    return match.length > 0 ? match[0] : null;
  }, [dossier?.id, type, id, types, data]); // eslint-disable-line react-hooks/exhaustive-deps

  if (notFound) return <NotFound asBlock/>;
  if (errored) return <Error asBlock/>;
  if (loading) return <PageLoader/>;

  return (
    <div className={'flex flex-column md:flex-row flex-wrap fadein animation-duration-500 mb-5'}>

      <div className={'flex w-12 md:w-6 py-4 pl-2 pr-3'}>
        <IndicatorSimple color={'primary'} dark bgImage title={trans(t, 'form|patrimoine.credits.default.label')} text={data?.label}/>
      </div>
      <div className={'flex w-12 md:w-6 py-4 pl-2 pr-3'}>
        <IndicatorSimple color={'accent4'} bgImage title={trans(t, 'form|patrimoine.credits.default.bank')} text={bank?.name}/>
      </div>
      <div className={'flex w-12 md:w-4 py-4 pl-2 pr-3'}>
        <IndicatorSimple color={'accent3'} bgImage title={trans(t, 'form|patrimoine.credits.default.montant')} value={data?.montantEmprunte}/>
      </div>
      <div className={'flex w-12 md:w-4 py-4 pl-2 pr-3'}>
        <IndicatorSimple color={'accent2'} bgImage title={trans(t, 'form|patrimoine.credits.default.montantRestantDu')} value={data?.montantRestantDu}/>
      </div>
      <div className={'flex w-12 md:w-4 py-4 pl-2 pr-3'}>
        <IndicatorSimple color={'accent5'} bgImage title={trans(t, 'form|patrimoine.credits.default.montantMensualites')} value={data?.montantMensualites} method={'montant'} exactValue={true}/>
      </div>

      <Fieldset className={'my-5 w-12'} legend={ucfirst(trans(t, 'general'))} toggleable>
        <div className={'flex flex-column md:flex-row px-3'}>
          <div className={'flex flex-grow-1 py-4 px-3'}>
            <SimpleText title={trans(t, 'form|patrimoine.credits.default.opening')} value={data?.openingDate} method={'date'}/>
          </div>
          <div className={'flex flex-grow-1 py-4 px-3'}>
            <SimpleText title={trans(t, 'form|patrimoine.credits.default.duree')} text={data?.duree}/>
          </div>
          <div className={'flex flex-grow-1 py-4 px-3'}>
            <SimpleText title={trans(t, 'type')} text={getType?.translated}/>
          </div>
          <div className={'flex flex-grow-1 py-4 px-3'}>
            <SimpleText title={trans(t, 'form|patrimoine.credits.default.taux')} value={data?.taux} method={'percentage'}/>
          </div>
          <div className={'flex flex-grow-1 py-4 px-3'}>
            <SimpleText title={trans(t, 'form|patrimoine.credits.default.tauxAssurance')} value={data?.tauxAssurance} method={'percentage'}/>
          </div>
        </div>
      </Fieldset>

      <Fieldset legend={ucfirst(trans(t, 'insurance'))} className={'w-full mt-5 w-12'} toggleable>
        <div className={'flex flex-column md:flex-row px-3'}>
          <div className={'flex flex-grow-1 py-4 px-3'}>
            <SimpleText title={trans(t, 'form|patrimoine.credits.default.assuranceGarantieDeces')} value={data?.assuranceGarantieDeces} method={'bool'}/>
          </div>
          <div className={'flex flex-grow-1 py-4 px-3'}>
            <SimpleText title={trans(t, 'form|patrimoine.credits.default.assuranceGarantieITP')} value={data?.assuranceGarantieITP} method={'bool'}/>
          </div>
          <div className={'flex flex-grow-1 py-4 px-3'}>
            <SimpleText title={trans(t, 'form|patrimoine.credits.default.assuranceGarantieITT')} value={data?.assuranceGarantieITT} method={'bool'}/>
          </div>
          <div className={'flex flex-grow-1 py-4 px-3'}>
            <SimpleText title={trans(t, 'form|patrimoine.credits.default.assuranceGarantieIPP')} value={data?.assuranceGarantieIPP} method={'bool'}/>
          </div>
          <div className={'flex flex-grow-1 py-4 px-3'}>
            <SimpleText title={trans(t, 'form|patrimoine.credits.default.assuranceGarantieIPT')} value={data?.assuranceGarantieIPT} method={'bool'}/>
          </div>
          <div className={'flex flex-grow-1 py-4 px-3'}>
            <SimpleText title={trans(t, 'form|patrimoine.credits.default.assuranceGarantiePerteEmploi')} value={data?.assuranceGarantiePerteEmploi} method={'bool'}/>
          </div>
          <div className={'flex flex-grow-1 py-4 px-3'}>
            <SimpleText title={trans(t, 'form|patrimoine.credits.default.delegationAssurance')} value={data?.delegationAssurance} method={'bool'}/>
          </div>
          <div className={'flex flex-grow-1 py-4 px-3'}>
            <SimpleText title={trans(t, 'form|patrimoine.credits.default.eligibleLoiLemoine')} value={data?.eligibleLoiLemoine} method={'bool'}/>
          </div>
        </div>
      </Fieldset>

      {data && (
        <Fieldset legend={ucfirst(trans(t, 'emprunteur', 2))} className={'w-full mt-5'} toggleable>
          <EmprunteursDatatable apim={apim} data={data} title={''}/>
        </Fieldset>
      )}

      <Fieldset legend={ucfirst(trans(t, 'beneficiary', 2))} className={' w-full mt-4'} toggleable>
        <ContractBeneficiairesDatatable apim={apim} data={data}/>
      </Fieldset>

      {data?.description && (
        <Fieldset legend={ucfirst(trans(t, 'form|zone_note'))} className={'w-full mt-5'} toggleable>
          <div className={'a8-bien-description'} dangerouslySetInnerHTML={{__html: data.description}}></div>
        </Fieldset>
      )}

      <div className={'flex flex-row justify-content-end w-full mt-5'}>
        {isValidUUID(data?.author) && (
          <div className={'flex px-3 align-items-center'}>
            <strong className={'titre titre--gray pr-2'}>{ucfirst(trans(t, 'author'))}</strong>
            <div>
              <Userpicture apim={apim} user={data.author} size={'small'}/>
            </div>
          </div>
        )}

        {data?.created && (
          <div className={'flex px-3 align-items-center'}>
            <strong className={'titre titre--gray pr-2'}>{ucfirst(trans(t, 'created.at'))}</strong>
            <div>
              {dateString(data?.created, 'DD/MM/YYYY') + ' ' + trans(t, 'at') + ' ' + dateString(data?.created, 'HH:mm')!.replace(':', 'h')}
            </div>
          </div>
        )}

        {data?.updated && (
          <div className={'flex px-3 align-items-center'}>
            <strong className={'titre titre--gray pr-2'}>{ucfirst(trans(t, 'updated.at'))}</strong>
            <div>
              {dateString(data?.updated, 'DD/MM/YYYY') + ' ' + trans(t, 'at') + ' ' + dateString(data?.updated, 'HH:mm')!.replace(':', 'h')}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};
