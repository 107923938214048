import React, { useCallback, useState } from 'react';
import { AxiosResponse } from 'axios';
import { Button } from 'primereact/button';

import { iri, isValidUUID, trans, triggerFormSubmit } from 'utilities';
import { FormWrapper } from 'forms';
import { IRequestParams } from 'services';
import { PageLoader } from 'components';

import appUri from 'config/appUri.json';

export const Step1 = (props: any) => {
  const { data, apim, appState, context, dirigeant } = props;
  const { t, navigate} = apim.di();

  const [loading, setLoading] = useState<boolean>(false);

  // Auto-handle early links e.g. Donation <=> Patrimoine
  const onSubmit = (formData: any) => {
    setLoading(true);
    const baseUrl = appUri.dos.edit.patrimoine.donations.uri.replace(':id', appState?.dossier()?.id);

    // Just navigate to next step if data already passed Step1.
    if (isValidUUID(data?.id)) return navigate(baseUrl.replace(':donationId?', data?.id) + appUri.dos.edit.patrimoine.donations.steps.default.step2);

    const postPD = (donationId: string) => {
      apim.postEntity({
        resourceType: 'patrimoineDonations',
        notif: false,
        data: {
          patrimoine: iri('patrimoines', context?.id),
          donation: iri('donations', donationId),
        },
        success: (resP: AxiosResponse) => {
          if (!isValidUUID(resP?.data?.id)) return apim.displayError(trans(t, 'form|errors.default.summary'), trans(t, 'form|errors.default.detail'));

          if (dirigeant?.children?.length > 0) {
            apim.postEntity({
              resourceType: 'donationBeneficiaires',
              notif: false,
              data: {
                donation: iri('donations', donationId),
                type: 'enfants',
                percentage: 100
              },
              always: () => navigate(baseUrl.replace(':donationId?', donationId) + appUri.dos.edit.patrimoine.donations.steps.default.step2)
            } as IRequestParams).then();
          } else {
            navigate(baseUrl.replace(':donationId?', donationId) + appUri.dos.edit.patrimoine.donations.steps.default.step2);
          }
        }
      } as IRequestParams).then();
    };

    // Just link selected "donation"
    if (isValidUUID(formData?.existing)) postPD(formData?.existing);
    else {
      // Create & link a new "donation".
      apim.postEntity({
        resourceType: 'donations',
        data: {
          label: trans(t, 'new.donation'), type: formData?.type ?? 'simple',
          personnePhysique: iri('personnesPhysiques', dirigeant?.id),
        },
        notif: false,
        success: (res: AxiosResponse) => {
          if (!isValidUUID(res?.data?.id)) return apim.displayError(trans(t, 'form|errors.default.summary'), trans(t, 'form|errors.default.detail'));

          postPD(res?.data?.id);
        }
      } as IRequestParams).then();
    }
  };

  const renderForm = useCallback(() =>
      <FormWrapper multiple formKeyPrefix={'donation_selection'} resourceType={'donations'} classes={'grid p-fluid w-12'}
                   cancelLink hideReload context={context} data={data} onSubmit={onSubmit} globalDisabled={isValidUUID(data?.id)}/>
    , [data?.id, context?.id]); // eslint-disable-line react-hooks/exhaustive-deps

  if (loading) return <PageLoader/>;

  return (
    <div className={'a8-wizard wizard-patrimoine-donation fadein animation-duration-500'}>
      <h5 className={'mx-3 pt-3 flex flex-row align-items-center'}>
        <span className={'mr-2 border-circle bg-primary flex align-items-center justify-content-center'} style={{width: '2rem', height: '2rem'}}><i className={'pi pi-search'}></i></span>
        {trans(t, 'menu|wizard.patrimoine.donations.default.step1')}
      </h5>

      {renderForm()}

      <div className={'text-right m-2 pt-4'}>
        <Button type={'reset'} className={'text-ucfirst p-button-text p-button-danger mr-5'} label={trans(t, 'close')} onClick={() => navigate(appUri.dos.edit.patrimoine.donations.landing.replace(':id', appState?.dossier()?.id))}/>
        <Button type={'submit'} className={'text-ucfirst p-button-text'} label={trans(t, 'next')} icon={'pi pi-arrow-right'} iconPos={'right'} onClick={() => triggerFormSubmit(null)}/>
      </div>
    </div>
  );
};
