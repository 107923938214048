import React from 'react';
import { trans } from 'utilities';
import { NumberField } from 'forms';

export const ReservesForm = (props: any) => {
  const { t, control, onFieldChange, errorMessage } = props;
  const fields: any[] = [
    {key: 'reserves'},
    {key: 'autresReserves'},
    {key: 'benefices', disabled: true, tooltip: trans(t, 'form|tooltip.reserves.benefices')},
    {key: 'capitalSocialSurCapitauxPropres', addon: 'percentage', mode: 'decimal', tooltip: trans(t, 'form|tooltip.reserves.capitauxPropres')},
    {key: 'partReservesSurChargesAnnuelles', addon: 'percentage', mode: 'decimal',  tooltip: trans(t, 'form|tooltip.reserves.reservesSurChargesAnnuelles')},
  ];

  return <>
    {fields.map((field: any, index: number) => (
      <NumberField key={index} fieldKey={field.key} mode={field.mode || 'currency'} control={control}
                   addon={field.addon || 'euro'} addonPosition={'after'} onFieldChange={onFieldChange}
                   errorMessage={errorMessage} label={trans(t, 'chart|reserves.' + field.key)}
                   tooltip={field.tooltip} disabled={field.disabled || false} />
    ))}
  </>;
};
