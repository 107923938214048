import React, { useEffect } from 'react';

import { isValidUUID, trans, ucfirst } from 'utilities';
import { APIAutocompleteField, RolesField, PermissionsField } from 'forms';

import { forEach, union } from 'lodash';
import { IRequestParams, useApim } from 'services';
import { useFormState } from 'states';

import { AxiosResponse } from 'axios';

export const UserGroupForm = (props: any) => {
  const { t, control, formKey, parentKey, onFieldChange, errorMessage, getValues, context, setValue } = props;
  const apim = useApim();
  const formState = useFormState();

  const groupParams = [{label: 'order[raisonSociale]', value: 'asc'}];
  const usersFormatter = (rows: any[]) => rows.map((r: any) => ({...r, ...{autocompleteLabel: r.fullName + (r.email !== undefined && r.email !== null ? ' (' + r.email + ')' : '')}}));
  const usersParams = [
    {label: 'order[lastName]', value: 'asc'},
    {label: 'order[firstName]', value: 'asc'}
  ];

  // Filter by role(s).
  // Example: show only experts.
  if (context?.role) {
    usersParams.push({label: 'role', value: context?.role});
  }

  if (context?.roles) {
    forEach(context?.roles ?? [], (role: any) => {
      usersParams.push({label: 'role[]', value: role});
    });
  }

  // Filter by group(s).
  // Example: show only members of a group (dossier, societe expert, etc).
  if (context?.group) {
    usersParams.push({label: 'order[group.label]', value: 'asc'});
    usersParams.push({label: 'group', value: context?.group});
  }

  if (context?.groups) {
    forEach(context?.groups ?? [], (group: any) => {
      usersParams.push({label: 'group[]', value: group});
    });
  }

  // Load & set the default role, if any.
  useEffect(() => {
    if (context?.defaultRole && context?.defaultRole.length > 0) {
      apim.fetchEntities({
        resourceType: 'roles',
        params: [{label: 'name', value: context.defaultRole}],
        success: (res: AxiosResponse) => {
          if (!res?.data || (res?.data['hydra:member'] || []).length === 0) {
            return;
          }

          if (isValidUUID(res.data['hydra:member'][0].id)) {
            formState.setFieldData(formKey, 'role', parentKey, res.data['hydra:member'][0].id);
            setValue('role', res.data['hydra:member'][0].id)
          }
        }
      } as IRequestParams).then();
    }
  }, [context.defaultRole]); // eslint-disable-line react-hooks/exhaustive-deps

  return <div className={'grid w-full pt-3'}>
    {context?.displayGroup === true && (
      <div className={'col-12'}>
        <APIAutocompleteField fieldKey={'group'} control={control} onFieldChange={onFieldChange} errorMessage={errorMessage} label={context?.groupTitle ?? trans(t, 'societeExpert')}
                              getValues={getValues} placeholder={ucfirst(trans(t, 'form|select'))} optionKey={context?.groupOptionKey ?? 'raisonSociale'} optionValue={'group'}
                              resourceType={context?.groupResourceType ?? 'societesExperts'} params={groupParams} classes={'col'}/>
      </div>
    )}

    {context?.displayUser === true && (
      <div className={'col-12'}>
        <APIAutocompleteField fieldKey={'user'} control={control} onFieldChange={onFieldChange} errorMessage={errorMessage} label={context?.userTitle ?? trans(t, 'dos.expert')}
                              getValues={getValues} placeholder={ucfirst(trans(t, 'form|select_user'))} optionKey={'autocompleteLabel'} formatter={usersFormatter}
                              resourceType={'users'} params={usersParams} classes={'col'} rules={{ required: trans(t, 'form|requiredField') }} />
      </div>
    )}

    {context?.displaySourceExpert === true && (
      <div className={'col-12'}>
        <APIAutocompleteField fieldKey={'sourceUser'} control={control} onFieldChange={onFieldChange} errorMessage={errorMessage} label={context?.userTitle ?? trans(t, 'expert')}
                              getValues={getValues} placeholder={ucfirst(trans(t, 'form|select_user'))} optionKey={'autocompleteLabel'} formatter={usersFormatter}
                              resourceType={'usersIndex'} params={union(usersParams,context?.params)} classes={'col'}/>
        {ucfirst(trans(t, 'form|group.copyGroupTo'))}
      </div>
    )}

    {context?.displayExpert === true && (
      <div className={'col-12'}>
        <APIAutocompleteField fieldKey={'expert'} control={control} onFieldChange={onFieldChange} errorMessage={errorMessage} label={context?.userTitle ?? trans(t, 'expert')}
                              getValues={getValues} placeholder={ucfirst(trans(t, 'form|select_user'))} optionKey={'autocompleteLabel'} formatter={usersFormatter}
                              resourceType={'usersIndex'} params={union(usersParams,context?.params)} classes={'col'} />
        {ucfirst(trans(t, 'form|group.copyGroupGivingRole'))}
      </div>

      )}

    {context?.displayRole === true && (
      <div className={'col-12'}>
        <RolesField fieldKey={'role'} control={control} classes={'col'} onFieldChange={onFieldChange} errorMessage={errorMessage} editable label={trans(t, 'role')}
                    rolesAvailables={context?.rolesAvailables} enforceRolesAvailables={context?.enforceRolesAvailables} defaultRole={context?.defaultRole}/>
      </div>
    )}

    {context?.displayPermissions === true && (
      <div className={'col-12'}>
        {ucfirst(trans(t, 'form|group.copyGroupGivingPermissions'))}
        <PermissionsField fieldKey={'permissions'} classes={'col'} label={null}
                          getValues={getValues} setValue={setValue} formKey={formKey} parentKey={parentKey}/>
      </div>
    )}
  </div>;
};
