import React, { useCallback, useState } from 'react';
import { AxiosResponse } from 'axios';
import { Button } from 'primereact/button';

import { iri, isValidUUID, trans, triggerFormSubmit } from 'utilities';
import { FormWrapper } from 'forms';
import { IRequestParams } from 'services';
import { PageLoader } from 'components';

import appUri from 'config/appUri.json';

export const Step1 = (props: any) => {
  const { data, apim, appState, context, dirigeant } = props;
  const { t, navigate} = apim.di();

  const [loading, setLoading] = useState<boolean>(false);

  // Auto-handle early links e.g. Contract <=> Patrimoine & Contract <=> Souscripteur
  const onSubmit = (formData: any) => {
    setLoading(true);
    const baseUrl = appUri.dos.edit.patrimoine.contracts.uri.replace(':id', appState?.dossier()?.id).replace(':type', 'retraite');

    // Just navigate to next step if data already passed Step1.
    if (isValidUUID(data?.id)) return navigate(baseUrl.replace(':contractId?', data?.id) + appUri.dos.edit.patrimoine.contracts.steps.default.step2);

    const postPCR = (contractId: string) => {
      const _terminate = () => {
        apim.postEntity({
          resourceType: 'patrimoineContractsRetraite',
          notif: false,
          data: {
            patrimoine: iri('patrimoines', context?.id),
            contractRetraite: iri('contractsRetraite', contractId),
          },
          success: (resP: AxiosResponse) => {
            if (!isValidUUID(resP?.data?.id))
              return apim.displayError(trans(t, 'form|errors.default.summary'), trans(t, 'form|errors.default.detail'));

            if (isValidUUID(dirigeant?.id)) {
              apim.postEntity({
                resourceType: 'contractSouscripteurs',
                notif: false,
                data: {
                  personnePhysique: iri('personnesPhysiques', dirigeant?.id),
                  contractRetraite: iri('contractsRetraite', contractId),
                },
                success: (resP: AxiosResponse) => {
                  if (!isValidUUID(resP?.data?.id)) return apim.displayError(trans(t, 'form|errors.default.summary'), trans(t, 'form|errors.default.detail'));
                },
                always: () => navigate(baseUrl.replace(':contractId?', contractId) + appUri.dos.edit.patrimoine.contracts.steps.default.step2)
              } as IRequestParams).then();
            } else navigate(baseUrl.replace(':contractId?', contractId) + appUri.dos.edit.patrimoine.contracts.steps.default.step2);
          }
        } as IRequestParams).then();
      }

      if (dirigeant?.spouses?.length > 0 && isValidUUID(dirigeant.spouses[0].spouse?.id)) {
        apim.postEntity({
          resourceType: 'contractBeneficiaires',
          notif: false,
          data: {
            contractRetraite: iri('contractsRetraite', contractId),
            type: 'conjoint',
            percentage: 100
          },
          always: () => _terminate()
        } as IRequestParams).then();
      } else {
        _terminate();
      }
    };

    // Just link selected "contract"
    if (isValidUUID(formData?.existing)) postPCR(formData?.existing);
    else {
      // Create & link a new "contract".
      apim.postEntity({
        resourceType: 'contractsRetraite',
        data: {
          label: trans(t, 'new.contract'),
          type: formData?.type ?? 'mono_support'
        },
        notif: false,
        success: (res: AxiosResponse) => {
          if (!isValidUUID(res?.data?.id)) return apim.displayError(trans(t, 'form|errors.default.summary'), trans(t, 'form|errors.default.detail'));

          postPCR(res?.data?.id);
        }
      } as IRequestParams).then();
    }
  };

  const renderForm = useCallback(() =>
      <FormWrapper multiple formKeyPrefix={'contract_retraite_selection'} resourceType={'contractsRetraite'} classes={'grid p-fluid w-12'}
                   cancelLink hideReload context={context} data={data} onSubmit={onSubmit} globalDisabled={isValidUUID(data?.id)}/>
    , [data?.id, context?.id]); // eslint-disable-line react-hooks/exhaustive-deps

  if (loading) return <PageLoader/>;

  return (
    <div className={'a8-wizard wizard-patrimoine-contrat-retraite fadein animation-duration-500'}>
      <h5 className={'mx-3 pt-3 flex flex-row align-items-center'}>
        <span className={'mr-2 border-circle bg-primary flex align-items-center justify-content-center'} style={{width: '2rem', height: '2rem'}}><i className={'pi pi-search'}></i></span>
        {trans(t, 'menu|wizard.patrimoine.contracts.default.step1')}
      </h5>

      {renderForm()}

      <div className={'text-right m-2 pt-4'}>
        <Button type={'reset'} className={'text-ucfirst p-button-text p-button-danger mr-5'} label={trans(t, 'close')} onClick={() => navigate(appUri.dos.edit.patrimoine.contracts.landing.replace(':id', appState?.dossier()?.id))}/>
        <Button type={'submit'} className={'text-ucfirst p-button-text'} label={trans(t, 'next')} icon={'pi pi-arrow-right'} iconPos={'right'} onClick={() => triggerFormSubmit(null)}/>
      </div>
    </div>
  );
};
