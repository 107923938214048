import React from 'react';
import { trans, ucfirst } from 'utilities';
import { APIListField, MaskField, StaticListField, TextField } from 'forms';

export const ContexteForm = (props: any) => {
  const { t, control, onFieldChange, errorMessage, listsOptions } = props;

  return <>
    <div className={'grid w-full px-3'}>
      <div className={'col-3'}>
        <TextField classes={'w-full'} fieldKey={'name'} control={control} onFieldChange={onFieldChange} rules={{ required: trans(t, 'form|requiredField') }}
                   errorMessage={errorMessage} label={trans(t, 'form|contract.name')} />
      </div>

      <div className={'col-3'}>
        <MaskField classes={'w-full'} fieldKey={'dateEffet'} control={control} onFieldChange={onFieldChange} label={ucfirst(trans(t, 'form|contract.date_effet'))}
                   rules={{ required: trans(t, 'form|requiredField') }} errorMessage={errorMessage} isDate/>
      </div>

      <div className={'col-3'}>
        <StaticListField classes={'w-full'} listKey={'insuranceCompanies'} listsOptions={listsOptions} fieldKey={'insuranceCompany'} control={control} optionLabel={'name'} optionValue={'id'}
                         onFieldChange={onFieldChange} errorMessage={errorMessage} editable label={trans(t, 'form|contract.company')} rules={{ required: trans(t, 'form|requiredField') }}/>
      </div>

      <div className={'col-3'}>
        <APIListField classes={'w-full'} listKey={'prevoyanceContractTypes'} fieldKey={'type'} control={control} onFieldChange={onFieldChange} errorMessage={errorMessage}
                      label={trans(t, 'form|contract.type')} rules={{ required: trans(t, 'form|requiredField') }}/>
      </div>
    </div>
  </>;
};
