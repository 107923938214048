import React from 'react';

import { Dropdown } from 'primereact/dropdown';
import { MultiSelect } from 'primereact/multiselect';

import { trans } from 'utilities';
import { useApim } from 'services';
import { UncontrolledBaseField } from './UncontrolledBaseField';

import { isArray, isString, uniq } from 'lodash';

export const UncontrolledStaticListField = (props: any) => {
  const { fieldKey, value, listKey, listsOptions, onFieldChange, label, placeholder, classes, description, addon, maxSelectedLabels,
    addonPosition, optionLabel, optionValue, showClear, itemTemplate, multiple, uniqKeys, filter, display, showSelectAll, selectedItemsLabel } = props;

  const apim = useApim();
  const { t } = apim.di();

  // Let's parse the value to properly handle multiple / single + array / !array cases.
  let widgetValue: any = value;
  if (multiple) {
    // It's a multiple valued widget.
    // Make sure we have an array.
    if (!isArray(widgetValue)) {
      widgetValue = [widgetValue ?? {}];
    }

    // Filter undefined & null values.
    widgetValue = widgetValue.filter((item: any) => {
      return item !== undefined && item !== null;
    })

    // Map each item to its value if it's an object.
    widgetValue = widgetValue.map((item: any) => {
      return isString(item) ? item : (item[optionValue ?? 'value']);
    });

    // Avoid duplicate items.
    if (uniqKeys !== false) {
      widgetValue = uniq(widgetValue);
    }

  } else {
    // It's a single valued widget.
    // Make sure we have only one value.
    if (isArray(widgetValue) && widgetValue.length > 0) {
      widgetValue = widgetValue[0];
    }

    if (!isString(widgetValue)) {
      widgetValue = widgetValue[optionValue ?? 'value']
    }
  }

  return (
    <UncontrolledBaseField classes={classes} addon={addon} addonPosition={addonPosition} description={description} >
      <span className={'p-float-label'}>
        {true === multiple ? (
          <MultiSelect
            id={fieldKey} className={'w-full'} placeholder={placeholder ?? trans(t, 'form|select')} value={widgetValue} display={display ?? 'chip'} selectedItemsLabel={selectedItemsLabel}
            options={(listsOptions ?? {})[listKey]} optionLabel={optionLabel ?? 'translated'} optionValue={optionValue ?? 'value'} maxSelectedLabels={maxSelectedLabels}
            onChange={(e) => onFieldChange(e.value)} showClear={showClear} itemTemplate={itemTemplate} filter={filter !== false} showSelectAll={showSelectAll !== false}
          />
        ) : (
          <Dropdown
            id={fieldKey} className={'w-full'} placeholder={placeholder ?? trans(t, 'form|select')} value={widgetValue} filter={filter !== false}
            options={listKey ? (listsOptions ?? {})[listKey] : listsOptions} optionLabel={optionLabel ?? 'translated'} optionValue={optionValue ?? 'value'}
            onChange={(e) => onFieldChange(e.value)} showClear={showClear} itemTemplate={itemTemplate}
          />
        )}

        {(label || fieldKey) && (
          <label className={'text-ucfirst p-float-label-label'} htmlFor={fieldKey}>
            <span>{label ?? trans(t, fieldKey)}</span>
          </label>
        )}
      </span>
    </UncontrolledBaseField>
  );
};
