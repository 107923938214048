import React from 'react';
import { trans } from 'utilities';
import { NumberField } from 'forms';

export const TresorerieNegativeForm = (props: any) => {
  const { t, control, onFieldChange, errorMessage } = props;
  const fields: any[] = [
    {key: 'compteBancaireNegatif'},
    {key: 'moyenneCbSurExercice'},
    {key: 'coutReelAgios'}
  ];

  return <>
    {fields.map((field: any, index: number) => (
      <NumberField key={index} fieldKey={field.key} mode={field.mode || 'currency'}
                   addon={field.addon || 'euro'} addonPosition={'after'} control={control} onFieldChange={onFieldChange}
                   errorMessage={errorMessage} label={trans(t, 'chart|tresorerie.' + field.key)} tooltip={field.tooltip} />
    ))}
  </>;
};
