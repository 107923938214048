import React, { useEffect } from 'react';

import { trans } from 'utilities';
import { PrevoyanceContractsDatatable } from 'components';
import { isClient } from 'services';

import appUri from 'config/appUri.json';

export const PrevoyanceContrats = (props: any) => {
  const { id, appState, apim } = props;
  const { t} = apim.di();

  const dossier = appState.dossier();

  // Override Page Header (defaults into ModuleWrapper, then PrevoyanceRouter).
  useEffect(() => {
    appState.setBreadcrumb([
      isClient()
       ? { label: trans(t, 'dossier', 2) }
       : { label: trans(t, 'dossier', 2), to: appUri.dos.list },
      { label: dossier.title, to: appUri.dos.page.replace(':id', id)},
      { label: trans(t, 'menu|menu.schema_flux.prevoyance_contrats') }
    ]);
  }, [id]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div className={'flex flex-column md:flex-row flex-wrap fadein animation-duration-500'}>
      <div className={'w-full'}>
        <PrevoyanceContractsDatatable apim={apim} params={[{label: 'dossier.id', value: dossier.id}]} />
      </div>
    </div>
  );
};
