import { isValidUUID, trans } from 'utilities';
import { isAdmin, isExpert } from 'services';
import { useAppState } from 'states';

import appUri from 'config/appUri.json';

const dirigeantMenu = (t: any, appState: any) => {
  const items: any[] = [{
    label: trans(t, 'menu|menu.mon_dossier'),
    icon: 'home',
    to: appUri.home
  }];

  if (isValidUUID(appState.dossier()?.id)) {
    items.push({
      label: trans(t, 'menu|menu.mes_entreprises'),
      icon: 'building',
      to: appUri.dos.page.replace(':id', appState.dossier()?.id) + '/entreprise/donnees-juridiques'
    });

    items.push({
      label: trans(t, 'menu|menu.ma_ged'),
      icon: 'folder-open',
      to: appUri.dos.page.replace(':id', appState.dossier()?.id) + '/ged'
    });

    items.push({
      label: trans(t, 'menu|menu.mon_foyer'),
      icon: 'users',
      to: appUri.dos.page.replace(':id', appState.dossier()?.id) + '/foyer'
    });
  }

  return [
    {
      label: trans(t, 'menu|menu.main'),
      icon: 'home',
      items: items
    }
  ];
};

const expertMenu = (t: any) => {
  const items: any[] = [
    {
      label: trans(t, 'menu|menu.home'),
      icon: 'home',
      to: appUri.home
    },
    {
      label: trans(t, 'menu|menu.dossiers'),
      icon: 'list',
      to: appUri.dos.list
    },
    {
      label: trans(t, 'dos.add'),
      icon: 'plus',
      to: appUri.dos.add
    },
    {
      label: trans(t, 'menu|menu.ged'),
      icon: 'folder-open',
      to: appUri.ged
    }
  ];

  return [
    {
      label: trans(t, 'menu|menu.main'),
      icon: 'home',
      items: items
    }
  ];
};

export const MainMenu = (props: any) => {
  const { apim } = props;
  const { t } = apim.di();
  const appState = useAppState();

  return isAdmin() ? [
    {
      label: trans(t, 'menu|menu.main'),
      icon: 'home',
      items: [
        {
          label: trans(t, 'menu|menu.home'),
          icon: 'home',
          to: appUri.home
        },
        {
          label: trans(t, 'menu|menu.dossiers'),
          icon: 'list',
          to: appUri.dos.list
        },
        {
          label: trans(t, 'dos.add'),
          icon: 'plus',
          to: appUri.dos.add
        },
        {
          label: trans(t, 'menu|menu.ged'),
          icon: 'folder-open',
          to: appUri.ged
        }
      ]
    }
  ] : (isExpert() ? expertMenu(t) : dirigeantMenu(t, appState));
};
