import React, { useState } from 'react';
import { SchemaMenuLabelRow as Row } from './SchemaMenuLabelRow';

export const SchemaMenuMenuRow = (props: any) => {
  const defaultClasses: string = 'text-ucfirst';
  const colors = {
    primary: '#070AB0',
    primaryLight: '#142659'
  };
  const fonts = {
    primaryLight: 'OpenSans-Bold, Open Sans'
  };

  const [color, setColor] = useState<string>(colors.primaryLight);

  return (
    <text fill={color} fontFamily={fonts.primaryLight} fontSize={'14'} fontWeight={'normal'}
          onMouseEnter={() => setColor(colors.primary)}
          onMouseLeave={() => setColor(colors.primaryLight)}
          onClick={props.onClick}>
      <Row classNames={props.classNames || defaultClasses} pos={props.pos} label={props.label} />
    </text>
  );
};
