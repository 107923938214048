import React, { useCallback, useState } from 'react';

import { Column } from 'primereact/column';
import { Tooltip } from 'primereact/tooltip';
import { Button } from 'primereact/button';

import { dialog, iri, trans, triggerFormSubmit } from 'utilities';
import { DatatableWrapper, dateTimeCell, validCell } from 'components';
import { IRequestParams, isAdmin, isExpertAdmin, isSuperAdmin } from 'services';
import { FormWrapper } from 'forms';

import { forEach } from 'lodash';

export const SocieteExpertsDossiers = (props: any) => {
  const { tableKey, title, noFilters, apim, company } = props;
  const { t } = apim.di();

  const filtersEnabled = (!noFilters || false === noFilters);
  const [selectedDossiers, setSelectedDossiers] = useState<any[]>([]);

  const newParams: any[] =
    [{ label: 'societeExpertId', value: company.id}];

  const entrepreneurBodyTemplate = (rowData: any) => {
    return <div className={'flex flex-column'}>
      <div className={'text-primary'}>{rowData?.entrepreneurName?.toUpperCase()}</div>
      {rowData.entrepreneur && rowData.entrepreneur.email ? <div className={'text-sm mt-1'}>{rowData.entrepreneur.email}</div> : ''}
      {rowData.entrepreneur && rowData.entrepreneur.phone ? <div className={'text-sm mt-1'}>{rowData.entrepreneur.phone}</div> : ''}
    </div>;
  };

  const societeExpertBodyTemplate = (rowData: any) => rowData?.societeExpert?.toUpperCase() ?? null;

  const managerBodyTemplate = (rowData: any) => {
    const manager = rowData.manager;
    if (manager === undefined) return null;

    return (
      <>
        <img alt={manager?.name} src={manager?.picture || 'https://www.primefaces.org/wp-content/uploads/2020/05/placeholder.png'} width={32} style={{ verticalAlign: 'middle' }} />
        <span title={manager?.firstName + ' ' + manager?.lastName} style={{ marginLeft: '.5em', verticalAlign: 'middle' }} className={'image-text'}>{manager?.firstName?.toUpperCase()} {manager?.lastName.substring(0, 1).toUpperCase() + '.'}</span>
      </>
    );
  };

  const companyBodyTemplate = (rowData: any) => {
    const companies = rowData.companies;
    if (companies !== undefined && companies.length > 0) {
      let companyNames: string[] = [];

      const mainCompany = rowData.mainCompany;
      if (mainCompany !== undefined) {
        companyNames.push(mainCompany?.toUpperCase());
      }

      forEach(companies, ((company: any) => {
        if (mainCompany === undefined || company.name !== mainCompany) {
          companyNames.push(company?.name?.toUpperCase());
        }
      }));

      return <ul className={'list-none p-0 m-0'}>
        {companyNames.map((item: string, index: number) =>
          <li key={index} className={'pb-1'}>{index === 0 ? <span className={'block pb-1'}>{item}</span> : <span className={'block text-sm'}>{item}</span>}</li>
        )}
      </ul>;
    }

    return null;
  };

  const activeBodyTemplate = (rowData: any) => validCell(rowData?.active);
  const updatedBodyTemplate = (rowData: any) => dateTimeCell(rowData?.updated);
  const createBtnTemplate = () => {
    const isSelectionEmpty = selectedDossiers?.length === 0;

    const onAddSubmit = (formData: any) => {

      apim.postEntity({
        resourceType: 'societesExperts',
        action: 'manage',
        data: {
          societeExpert: iri('societesExperts', company?.id),
          user:  formData?.expert,
          role: formData?.role,
          permissions: formData?.permissions,
          dossiers: selectedDossiers.map((dossier) => dossier.id),
          clean: false,
        },
        success: () => {
        }
      } as IRequestParams);
    }

    const addForm =
      <>
        {trans(t, 'form|group.addGroupConfirmation')}
        <br/>
        {trans(t, 'form|group.addGroupWarning')}
        <FormWrapper classes={'grid'} resourceType={'userGroups'} formKeyPrefix={'expert_add_group'} cancelLink multiple
                     submitClass={'a8-expert-add-group'} onSubmit={onAddSubmit} hideReload
                     context={{
                       roles: ['ROLE_EXPERT', 'ROLE_EXPERT_ADMIN'],
                       params:  [{label: 'societeExpertId', value: company.id}],
                       displayExpert: true,
                       displayRole: true,
                       rolesAvailables: ['ROLE_EXPERT', 'ROLE_EXPERT_ADMIN'],
                       enforceRolesAvailables: true,
                       defaultRole: 'ROLE_EXPERT',
                       displayPermissions: true,
                     }}/>
      </>

    const onSetSubmit = (formData: any) => {

      apim.postEntity({
        resourceType: 'societesExperts',
        action: 'manage',
        data: {
          societeExpert: iri('societesExperts', company?.id),
          user:  formData?.expert,
          role: formData?.role,
          permissions: formData?.permissions,
          dossiers: selectedDossiers.map((dossier) => dossier.id),
          clean: true,
        },
        success: () => {
        }
      } as IRequestParams);
    }

    const setForm =
      <>
        {trans(t, 'form|group.setGroupConfirmation')}
        <br/>
        {trans(t, 'form|group.setGroupWarning')}
        <FormWrapper classes={'grid'} resourceType={'userGroups'} formKeyPrefix={'expert_set_group'} cancelLink multiple
                     submitClass={'a8-expert-set-group'} onSubmit={onSetSubmit} hideReload
                     context={{
                       roles: ['ROLE_EXPERT', 'ROLE_EXPERT_ADMIN'],
                       params:  [{label: 'societeExpertId', value: company.id}],
                       displayExpert: true,
                       displayRole: true,
                       rolesAvailables: ['ROLE_EXPERT', 'ROLE_EXPERT_ADMIN'],
                       enforceRolesAvailables: true,
                       defaultRole: 'ROLE_EXPERT',
                       displayPermissions: true,
                     }}/>

      </>

    const onCopySubmit = (formData: any) => {
      apim.postEntity({
        resourceType: 'societesExperts',
        action: 'copy',
        data: {
          societeExpert: iri('societesExperts', company?.id),
          user:  formData?.expert,
          role: formData?.role,
          sourceUser: formData?.sourceUser,
          clean: true,
        },
        success: () => {
        }
      } as IRequestParams);
    }

    const copyForm =
      <>
        {trans(t, 'form|group.copyGroupConfirmation')}
        <br/>
        {trans(t, 'form|group.copyGroupWarning')}
        <br/>
        <br/>

        {trans(t, 'form|group.copyGroup')}
        <FormWrapper classes={'grid'} resourceType={'userGroups'} formKeyPrefix={'expert_copy_group'} cancelLink multiple
                     submitClass={'a8-expert-copy-group'} onSubmit={onCopySubmit} hideReload
                     context={{
                       roles: ['ROLE_EXPERT', 'ROLE_EXPERT_ADMIN'],
                       group: company?.id,
                       params:  [{label: 'societeExpertId', value: company.id}],
                       displayExpert: true,
                       displaySourceExpert: true,
                       displayRole: true,
                       rolesAvailables: ['ROLE_EXPERT', 'ROLE_EXPERT_ADMIN'],
                       enforceRolesAvailables: true,
                       defaultRole: 'ROLE_EXPERT',
                     }}/>
      </>

    return (
      <>
        <Button className={'a8-expert-add-group-btn'}
                disabled={isSelectionEmpty}
                onClick={() => {
                  dialog(t, {
                    header: trans(t,'addGroup'),
                    icon: 'none',
                    message:  addForm,
                    className: 'w-6',
                    accept: () => triggerFormSubmit('a8-expert-add-group')
                  });
                }} rounded icon={'pi pi-user-plus'} />

        <Tooltip target={'.a8-expert-add-group-btn'} position={'top'} content={trans(t, 'addGroup')}
                 mouseTrack/>

        <Button className={'a8-expert-set-group-btn'}
                disabled={isSelectionEmpty}
                onClick={() => {
                  dialog(t, {
                    header: trans(t,'setGroup'),
                    icon: 'none',
                    message:  setForm,
                    className: 'w-6',
                    accept: () => triggerFormSubmit('a8-expert-set-group')
                  });
                }} rounded icon={'pi pi-user-edit'} />

        <Tooltip target={'.a8-expert-set-group-btn'} position={'top'} content={trans(t, 'setGroup')}
                 mouseTrack/>

        <Button className={'a8-expert-copy-group-btn'}
                onClick={() => {
                  dialog(t, {
                    header: trans(t,'copyGroup'),
                    icon: 'none',
                    message: copyForm,
                    className: 'w-6',
                    accept: () => triggerFormSubmit('a8-expert-copy-group')
                  });
                }} rounded icon={'pi pi-users'} />

        <Tooltip target={'.a8-expert-copy-group-btn'} position={'top'} content={trans(t, 'copyGroup')}
                 mouseTrack/>
      </>
    )
  }

  const renderDT = useCallback(() =>
      <DatatableWrapper resourceType={'dossiersIndex'} tableKey={tableKey || 'dossiers'} title={title || trans(t, 'menu|pages.title.societe.experts')}
                        noFilters={noFilters} selection={selectedDossiers} headerCreateBtn={isAdmin() || isSuperAdmin() || isExpertAdmin() ? createBtnTemplate : ''} params={newParams}
                        onSelectionChange={(e: any) => {setSelectedDossiers(e.value)}}>
        <Column selectionMode={'multiple'} headerStyle={{ width: '3rem' }} />
        <Column field={'entrepreneurName'} sortField={'entrepreneurName'} header={trans(t, 'dirigeant')} sortable filter={filtersEnabled} body={entrepreneurBodyTemplate}/>
        <Column field={'identifier'} header={trans(t, 'numero_client')} sortable filter={filtersEnabled} style={{ width: '150px' }}/>
        <Column field={'internalIdentifier'} header={trans(t, 'ent.reference')} sortable filter={filtersEnabled} style={{ width: '100px' }}/>
        <Column field={'mainCompany'} header={trans(t, 'entreprise', 2)} sortable filter={filtersEnabled} body={companyBodyTemplate} style={{ maxWidth: '250px' }}/>
        <Column field={'societeExpert'} header={trans(t, 'societeExpert')} sortable filter={filtersEnabled} body={societeExpertBodyTemplate} style={{ maxWidth: '250px' }}/>
        <Column sortField={'manager.firstName'} header={trans(t, 'expert')} sortable filter={filtersEnabled} body={managerBodyTemplate} style={{ maxWidth: '250px' }}/>
        <Column field={'updated'} header={trans(t, 'updated')} sortable align={'center'} body={updatedBodyTemplate} style={{ width: '225px' }}/>
        <Column field={'active'} header={trans(t, 'active')} sortable filter={filtersEnabled}
                dataType={'boolean'} align={'center'} body={activeBodyTemplate} style={{ width: '100px' }}/>
      </DatatableWrapper>
    , [selectedDossiers]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      {renderDT()}
    </>
  );
};
