import React, { useCallback, useEffect, useState } from 'react';
import { AxiosResponse } from 'axios';

import { Button } from 'primereact/button';

import { IRequestParams } from 'services';
import { FormWrapper } from 'forms';
import { iri, trans, triggerFormSubmit } from 'utilities';
import { Error } from 'pages';
import { Loader } from 'components';

import appUri from 'config/appUri.json';

export const Validation = (props: any) => {
  const { apim, urls, dossier, contract } = props;
  const { t, navigate } = apim.di();

  const cancelUri: string = urls?.landing + '/prevoyance/contrats';

  const [loading, setLoading] = useState<boolean>(false);
  const [data, setData] = useState<any>(null);
  const [errored, setErrored] = useState<boolean>(false);

  useEffect(() => {
    setLoading(true);
    apim.fetchEntities({
      resourceType: 'prevoyances',
      params: [
        {label: 'dossier', value: dossier?.id},
        {label: 'year', value: contract?.dateEffet?.length > 0 ? parseInt(contract.dateEffet.substring(0, 4) ): new Date().getFullYear() }
      ],
      cache: false,
      setErrored,
      success: (res: AxiosResponse) => {
        if (!res?.data || !res?.data['hydra:member'] || res?.data['hydra:member'].length === 0) {
          setLoading(false);

          return setData(null);
        }

        apim.fetchEntity({
          resourceType: 'prevoyances',
          id: res.data['hydra:member'][0].id,
          cache: false,
          setErrored,
          setLoading,
          setter: setData
        } as IRequestParams).then();
      }
    } as IRequestParams).then();
  }, [dossier?.id]); // eslint-disable-line react-hooks/exhaustive-deps

  // Wrap form render into a useCallback to avoid multiple FormWrapper recalls du to form fields updates.
  const renderForm = useCallback(() =>
      <FormWrapper classes={'grid pt-3'} formKeyPrefix={'contrat_validation'} resourceType={'prevoyances'} hideReload cancelLink
                   data={{...{year: contract?.dateEffet?.length > 0 ? parseInt(contract.dateEffet.substring(0, 4)) : new Date().getFullYear()}, ...data}}
                   multiple additionalData={{ dossier: iri('dossiers', dossier?.id) }} redirectUri={cancelUri}/>
    , [data?.id]); // eslint-disable-line react-hooks/exhaustive-deps

  if (errored) return <Error asBlock/>;

  return (
    <div className={'fadein animation-duration-500'}>
      <h5 className={'mx-3 pt-3 flex flex-row align-items-center'}>
        <span className={'mr-2 border-circle bg-primary flex align-items-center justify-content-center'} style={{width: '2rem', height: '2rem'}}><i className={'pi pi-check'}></i></span>
        {trans(t, 'form|title.contract.prevoyance.step4')}
      </h5>

      {loading ? <Loader /> : renderForm()}

      <div className={'text-right m-2 pt-4'}>
        <Button type={'reset'} className={'text-ucfirst p-button-text p-button-danger mr-5'} label={trans(t, 'close')} onClick={() => navigate(cancelUri)} />
        <span className={'p-buttonset'}>
          <Button type={'submit'} className={'text-ucfirst p-button-text'} label={trans(t, 'back')} icon={'pi pi-arrow-left'} onClick={() => navigate(urls?.form + appUri.dos.edit.contracts.prevoyance.step34)} />
            <Button type={'submit'} className={'text-ucfirst'} label={trans(t, 'terminate')} icon={'pi pi-check'} onClick={() => triggerFormSubmit(null)} />
        </span>
      </div>
    </div>
  );
};
