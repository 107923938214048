import React from 'react';

import { DossierRouter, DossiersList } from 'pages';
import {
  BienRouteur, BiensLanding, DonationsLanding,
  DossierFormLanding, MissionsFormRouter,
  AnnualDataFormRouter,
  DossierSettingsFormRouter,
  DossierWizardForm,
  ExerciceRouterForm,
  ContractsFormRouter
} from 'forms';
import { trimStart } from 'lodash';

import appUri from 'config/appUri.json';

export const RoutesDossier = () => {
  return [
    // LIST.
    {
      path: trimStart(appUri.dos.list),
      element: <DossiersList />
    },

    // NEW.
    {
      path: trimStart(appUri.dos.add) + '/:step?/:siren?',
      element: <DossierWizardForm />
    },
    // EDIT.
    {
      path: trimStart(appUri.dos.edit.landing) + '/:module?/:tab?',
      element: <DossierFormLanding />,
    },
    {
      path: trimStart(appUri.dos.edit.civilYear.uri) + '/:step?',
      element: <AnnualDataFormRouter />,
    },
    {
      path: trimStart(appUri.dos.edit.fiscalYear.uri) + '/:step?',
      element: <ExerciceRouterForm />,
    },
    {
      path: trimStart(appUri.dos.edit.contracts.uri) + '/:step?',
      element: <ContractsFormRouter />,
    },
    {
      path: trimStart(appUri.dos.edit.patrimoine.biens.landing),
      element: <BiensLanding />,
    },
    {
      path: trimStart(appUri.dos.edit.patrimoine.biens.uri) + '/:step?',
      element: <BienRouteur />,
    },
    {
      path: trimStart(appUri.dos.edit.patrimoine.contracts.landing),
      element: <BiensLanding />,
    },
    {
      path: trimStart(appUri.dos.edit.patrimoine.contracts.uri) + '/:step?',
      element: <BienRouteur />,
    },
    {
      path: trimStart(appUri.dos.edit.patrimoine.credits.landing),
      element: <BiensLanding/>,
    },
    {
      path: trimStart(appUri.dos.edit.patrimoine.credits.uri) + '/:step?',
      element: <BienRouteur parentType={'credit'}/>,
    },
    {
      path: trimStart(appUri.dos.edit.patrimoine.donations.landing),
      element: <DonationsLanding/>,
    },
    {
      path: trimStart(appUri.dos.edit.patrimoine.donations.uri) + '/:step?',
      element: <BienRouteur parentType={'donation'}/>, // Reuse similar logic instead of "duplicate" useless DonationRouteur ATM.
    },
    {
      path: trimStart(appUri.dos.edit.missions.uri) + '/:missionId?',
      element: <MissionsFormRouter />,
    },
    {
      path: trimStart(appUri.dos.edit.missions.uri) + '/:missionId/hypothese/:hypotheseId?/:step?/:year?',
      element: <MissionsFormRouter />,
    },
    // SETTINGS.
    {
      path: trimStart(appUri.dos.settings.uri) + '/:tab?',
      element: <DossierSettingsFormRouter />,
    },
    // MULTIPLE URLs to access to a DOSSIER PAGE.
    {
      path: trimStart(appUri.dos.page) + appUri.dos.pageExercice,
      element: <DossierRouter />,
    },
    {
      path: trimStart(appUri.dos.page) + appUri.dos.pageExerciceShort,
      element: <DossierRouter />,
    },
    {
      path: trimStart(appUri.dos.page) + appUri.dos.pageCompany,
      element: <DossierRouter />,
    },
    {
      path: trimStart(appUri.dos.page) + appUri.dos.pageCivile,
      element: <DossierRouter />,
    },
    {
      path: trimStart(appUri.dos.page) + appUri.dos.pageCivileShort,
      element: <DossierRouter />,
    },
    {
      path: trimStart(appUri.dos.page) + appUri.dos.pageMission,
      element: <DossierRouter />,
    },
    {
      path: trimStart(appUri.dos.page) + appUri.dos.pageMissionRetraite,
      element: <DossierRouter />,
    },
    {
      path: trimStart(appUri.dos.page) + appUri.dos.pageContract,
      element: <DossierRouter />,
    },
    {
      path: trimStart(appUri.dos.page) + appUri.dos.pageResource,
      element: <DossierRouter />,
    },
  ];
};
