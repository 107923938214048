import React, { useCallback, useState } from 'react';
import { AxiosError, AxiosResponse } from 'axios';

import { Button } from 'primereact/button';
import { Card } from 'primereact/card';
import { Chip } from 'primereact/chip';
import { Message } from 'primereact/message';

import { getErrorMessage, isValidUUID, onApiError, trans, ucfirst} from 'utilities';
import { IRequestParams } from 'services';
import { FormWrapper } from 'forms';
import { subscribeToHub } from 'forms/dossier';
import { forEach } from 'lodash';
import { PageLoader } from 'components';

export const Step1 = (props: any) => {
  const { formState, formKey, next, previous, apim } = props;
  const { t, navigate, toastError} = apim.di();
  const parentKey = 'step1';

  const [emptyAutocompleteResults, setEmptyAutocompleteResults] = useState<boolean|null>(null);
  const [autocompleteResults, setAutocompleteResults] = useState<any[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [formError, setFormError] = useState<string|null>(null);

  const autocomplete = (firstName: string, lastName: string, birthDate: string|null) => {
    setLoading(true);
    setFormError(null);

    let data: any = {
      firstName: firstName,
      lastName: lastName,
      birthDate: null,
    }

    if (birthDate !== null && birthDate !== undefined) {
      data.birthDate = birthDate.substring(0,2) + '-' + birthDate.substring(3,5) + '-' + birthDate.substring(6);
    }

    apim.call({
      resourceType: 'entrepreneurs',
      method: 'POST',
      action: 'autocomplete',
      notif: false,
      cache: false,
      data,
      success: (res: AxiosResponse) => {
        if (res.data['results'].length > 0) {
          // Display the autocomplete results.
          setAutocompleteResults(res.data['results']);
          setEmptyAutocompleteResults(null);
        } else {
          setAutocompleteResults([]);
          setEmptyAutocompleteResults(true);
        }

        formState.setLoading(formKey, false);
        setLoading(false);
      },
      error: (error: AxiosError) => {
        onApiError(t, error, toastError, {});
        formState.setLoading(formKey, false);
      }
    });
  };

  const onSubmit = (formData: any) => {
    if (formState.isLoading(formKey)) return;

    const _fd = formData[parentKey];
    if (!_fd.firstName || !_fd.lastName) return;

    // Autocomplete endpoint.
    formState.setLoading(formKey, true);
    autocomplete(_fd.firstName, _fd.lastName, _fd.birthDate)
  };

  const selectAutocompleteResult = (autocompleteResult: any) => {
    formState.setLoading(formKey, true);
    setLoading(true);

    const birthDate = autocompleteResult.birthDate.substring(8,10) + '-' + autocompleteResult.birthDate.substring(5,7) + '-' + autocompleteResult.birthDate.substring(0,4);
    formState.setFieldData(formKey, 'firstName', 'step1', autocompleteResult.firstName);
    formState.setFieldData(formKey, 'lastName', 'step1', autocompleteResult.lastName);
    formState.setFieldData(formKey, 'birthDate', 'step1', birthDate);

    submitEntrepreneur(autocompleteResult.firstName, autocompleteResult.lastName, birthDate, false);
  };

  const displayAutocompleteResult = (autocompleteResult: any, i: number) => {
    let name: string = '';
    let birthDate: string | null = null;

    if (autocompleteResult.firstName !== null && autocompleteResult.firstName !== undefined
      && autocompleteResult.lastName !== null && autocompleteResult.lastName !== undefined) {
      name = autocompleteResult.firstName + ' ' + autocompleteResult.lastName;
    }

    if (autocompleteResult.birthDateFormatted !== null && autocompleteResult.birthDateFormatted !== undefined) {
      birthDate = autocompleteResult.birthDateFormatted;
    }

    return <div key={i} className={'w-3 flex-auto flex'} style={{maxWidth: '25%'}}>
      <Card title={<div className={'flex flex-row justify-content-between'}>
        {name}
        <Button type={'submit'} size={'small'} className={'text-ucfirst'} icon={'pi pi-check'} label={trans(t, autocompleteResult.type + '_select')} onClick={() => {
          selectAutocompleteResult(autocompleteResult);
        }}/>
      </div>} className={'flex-auto m-2'}>
        <div className={'flex flex-column gap-2'}>
          <div className={'flex flex-row gap-2 align-items-center mb-2'}>
            <Chip label={ucfirst(trans(t, autocompleteResult.type))}/>
            <p className={'mb-0'}>Né(e) le {birthDate}</p>
          </div>

          {autocompleteResult.companies !== null && autocompleteResult.companies !== undefined && autocompleteResult.companies.length > 0 && (
            <div className={'flex flex-column gap-2'}>
              <p className={'mb-0'}>Liste des entreprises :</p>
              {autocompleteResult.companies.map((company: any, j: number) => {
                return <p key={j} className={'mb-0'}>{company.name} ({ucfirst(trans(t, company.type))})</p>
              })}
            </div>
          )}
        </div>
      </Card>
    </div>
  }

  const submitEntrepreneur = (firstName: string, lastName: string, birthDate: string|null|undefined, unknownEntrepreneur: boolean) => {
    formState.setLoading(formKey, true);

    if ((firstName === null || firstName === undefined || firstName.length === 0)
      || (lastName === null || lastName === undefined || lastName.length === 0)
      || (birthDate === null || birthDate === undefined || birthDate.length === 0)) {
      setFormError(trans(t, 'dirigeant_form_incomplete'));
      formState.setLoading(formKey, false);
      return;
    }

    let data = {
      id: null,
      firstName: firstName,
      lastName: lastName,
      birthDate: birthDate.substring(0,2) + '-' + birthDate.substring(3,5) + '-' + birthDate.substring(6),
      unknownEntrepreneur: unknownEntrepreneur,
    }

    // First call to check the dossier creation status.
    const dossierCreationId = formState.getFieldData(formKey, 'dossierCreationId');
    if (isValidUUID(dossierCreationId)) {
      apim.call({
        resourceType: 'dossierCreations',
        method: 'GET',
        id: dossierCreationId,
        notif: false,
        async: false,
        cache: false,
        success: () => {
          data.id = dossierCreationId;
          submitEntrepreneurInner(data);
        },
      } as IRequestParams).then();
    } else {
      submitEntrepreneurInner(data);
    }
  }

  const submitEntrepreneurInner = (data: any) => {
    // Fetch "entrepreneur" data.
    apim.call({
      resourceType: 'wizardFormDossier',
      action: 'step1',
      data: data,
      notif: false,
      error: (error: AxiosError) => {
        onApiError(t, error, toastError, getErrorMessage(t, error));
        formState.setLoading(formKey, false)
      },
      success: (res: AxiosResponse) => {
        if (res?.data && res?.data.id) {
          const _dossierCreationId = res.data.id;
          const _unknownEntrepreneur = res.data.unknownEntrepreneur;
          const _entrepreneurIri = res.data.entrepreneur;

          // Preprocess companies.
          // Add companies from the entrepreneur.
          let _companies : any[] = [];
          forEach(res.data.entrepreneurCompanies, (company: any) => {
            _companies.push({
              name: company.raisonSociale ?? company.nomCommercial ?? company.name,
              siren: company.siren,
              created: company.created,
              stopped: company.stopped,
              type: company.type,
              nafCode: company.nafCode,
              nafCodeLabel: company.nafCodeLabel
            });
          });

          // Add companies already added in the dossier creation.
          forEach(res.data.companiesLoaded, (company: any) => {
            let found = false;
            forEach(_companies, (_company: any) => {
              if (!found && _company.siren === company.siren) {
                found = true;
              }
            })

            if (!found) {
              _companies.push({
                name: company.raisonSociale ?? company.nomCommercial ?? company.name,
                siren: company.siren,
                created: company.created,
                stopped: company.stopped,
                type: company.type,
                nafCode: company.nafCode,
                nafCodeLabel: company.nafCodeLabel
              });
            }
          });

          // Save data for further steps.
          formState.setFieldData(formKey, 'dossierCreationId', null, _dossierCreationId);
          formState.setFieldData(formKey, 'entrepreneurIri', null, _entrepreneurIri);
          formState.setFieldData(formKey, 'unknownEntrepreneur', null, _unknownEntrepreneur);
          formState.setFieldData(formKey, 'companies', null, _companies);
          formState.setLoading(formKey, false);

          // Subscribe to Hub events.
          formState.setLoading(formKey, false);
          subscribeToHub(formState);

          if (res?.data.unknownEntrepreneurWarning) {
            // Unknown entrepreneur, show a message.
            toastError?.current.show({
              severity: 'error',
              summary: trans(t, 'system|error.pages.404.title'),
              detail: trans(t, 'system|error.pages.404.search_entrepreneur'),
              life: 4000
            });

            return;
          }

          const dossierCreationId = formState.getFieldData(formKey, 'dossierCreationId');
          if (dossierCreationId === res.data.id) {
            formState.setLoading(formKey, false);
            navigate(next);
            return;
          }

          navigate(next);
        } else formState.setLoading(formKey, false);
      },
    } as IRequestParams).then();
  }

  // Wrap form render into a useCallback to avoid multiple FormWrapper recalls du to form fields updates.
  const renderForm = useCallback(() =>
      <FormWrapper classes={'grid p-fluid mt-3'} parentKey={parentKey} multiple formKeyPrefix={formKey}
                   resourceType={'wf_dossier'} subClasses={'col-12 grid'} hideReload onSubmit={onSubmit}
                   cancelLink keepAlive />
    , []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div className={'a8-wizard wizard-dossier card fadein animation-duration-500'}>
      <h5 className={'mx-2 my-3'}>{trans(t, 'menu|wizard.dossier.title.step1')}</h5>
      <p className={'mx-2 my-3'}><i className={'pi pi-info-circle mr-2 mt-1 text-primary'}></i> {trans(t, 'menu|wizard.dossier.title.step1Help')}</p>

      {formError && (
        <Message text={formError} severity={'error'} className={'w-full mb-1'} />
      )}

      {renderForm()}

      {loading ? (
        <PageLoader />
      ) : (
        emptyAutocompleteResults ? (
          <p className={'mb-5'}>{ucfirst(trans(t, 'dirigeant_none'))}...<br/>{ucfirst(trans(t, 'dirigeant_force_next_step')) + '.'}</p>
        ) : (
          autocompleteResults.length > 0 && (
            <>
              {autocompleteResults.filter((autocompleteResult: any) => autocompleteResult.type === 'dirigeant').length > 0 && (
                <>
                  <h4 className={'mt-0 mb-3'}>Dirigeant(s) trouvé(s)</h4>
                  <div className={'flex flex-row flex-wrap mb-5'}>
                    {autocompleteResults.filter((autocompleteResult: any) => autocompleteResult.type === 'dirigeant').map((autocompleteResult: any, i: number) => {
                      return displayAutocompleteResult(autocompleteResult, i);
                    })}
                  </div>
                </>
              )}

              {autocompleteResults.filter((autocompleteResult: any) => autocompleteResult.type === 'beneficiaire').length > 0 && (
                <>
                  <h4 className={'mt-0 mb-3'}>Bénéficiaire(s) trouvé(s)</h4>
                  <div className={'flex flex-row flex-wrap'}>
                    {autocompleteResults.filter((autocompleteResult: any) => autocompleteResult.type === 'beneficiaire').map((autocompleteResult: any, i: number) => {
                      return displayAutocompleteResult(autocompleteResult, i);
                    })}
                  </div>
                </>
              )}
            </>
          )
        )
      )}

    <div className={'text-right m-2 mr-3'}>
      <>
        <Button className={'text-ucfirst p-button-text mr-5'} label={trans(t, 'previous')} onClick={() => navigate(previous)}/>
        <Button type={'submit'} className={'text-ucfirst'} label={trans(t, 'search')} icon={'pi pi-search'} loading={formState.isLoading(formKey)} onClick={() => {
            // Let's manually trigger submit.
            forEach(document.getElementsByClassName('a8-form-submit'), (formSubmit: any) => (formSubmit as HTMLInputElement).click());
        }} />

        <Button type={'submit'} className={'text-ucfirst ml-3'} label={trans(t, 'continue')} icon={'pi pi-check'} loading={formState.isLoading(formKey)} onClick={() => {
          const firstName = formState.getFieldData(formKey, 'firstName', 'step1');
          const lastName = formState.getFieldData(formKey, 'lastName', 'step1');
          const birthDate = formState.getFieldData(formKey, 'birthDate', 'step1');

          submitEntrepreneur(firstName, lastName, birthDate, true);
        }} />
      </>
    </div>
  </div>
  );
};
