import React, { useCallback, useEffect, useState } from 'react';

import { Column } from 'primereact/column';

import { dialog, iri, isValidUUID, trans, triggerFormReset, triggerFormSubmit, ucfirst } from 'utilities';
import { apiListAutocompleteEditor, apiListEditor, companyCell, DatatableWrapper, onPersonneMoraleAddSubmit, personCell, simpleCheckboxEditor, validCell } from 'components';
import { IRequestParams, isAdmin, isExpert } from 'services';
import { FormWrapper } from 'forms';

import { pick, trim } from 'lodash';
import appUri from 'config/appUri.json';

export const DirigeantsPersonnesMoralesDatatable = (props: any) => {
  const { rows, setRows, tableKey, title, editMode, params, context, apim, header } = props;
  const { t } = apim.di();

  const [loading, setLoading] = useState<boolean>(false);
  const [dirigeantStatus, setDirigeantStatus] = useState<any[]>([]);

  const resourceType = 'dirigeantsPersonnesMorales';

  useEffect(() => {
    apim.getList('dirigeantStatus', {setter: setDirigeantStatus} as IRequestParams).then();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const ppFormatter = (rows: any[]) =>
    rows.map((r: any) => (isValidUUID(r.id)) ? ({...r, ...{autocompleteLabel: trim(r.firstName + ' ' + r.lastName)}}) : r);

  const titleBodyTemplate = (rowData: any) => companyCell(rowData?.company, t);
  const representantBodyTemplate = (rowData: any) => {
    if (!isValidUUID(rowData?.representant?.id)) return null;

    return personCell(rowData?.representant, {
      url: appUri.cie.per.phy.page.replace(':id', rowData.representant.id),
      label: trans(t, 'seeDetails')
    });
  }
  const holdingBodyTemplate = (rowData: any) => validCell(rowData?.company?.latestVersion?.isHolding || false);
  const statusBodyTemplate = (rowData: any) => {
    const match: any = (dirigeantStatus || []).filter((m: any) => rowData?.dirigeantStatus?.toLowerCase() === m?.value);

    return match.length > 0 ? match[0].translated : rowData?.dirigeantStatus;
  };
  const activeBodyTemplate = (rowData: any) => validCell(rowData?.active);

  const onNew = () => {
    dialog(t, {
      header: trans(t,'form|company.personneMoraleDirigeantAdd'),
      icon: 'none',
      message: renderAddForm(),
      accept: () => triggerFormSubmit('a8-form-company-dpm-add'),
      reject: () => triggerFormReset('a8-form-company-dpm-reset')
    });
  };

  // Handle submission then refresh table rows.
  const onAddSubmit = useCallback(
    (formData: any) => onPersonneMoraleAddSubmit(apim, context, formData, rows, setRows, false, setLoading, header)
    , [rows]); // eslint-disable-line react-hooks/exhaustive-deps

  const hookRowEdit = (_rows: any[], _rowData: any) => ({
    formattedRows: _rows,
    patched: { dirigeantsPersonnesMorales: {
        ...pick(_rowData, ['active', 'dirigeantStatus']),
        ...{ representant: isValidUUID(_rowData?.representant?.id) ? iri('personnesPhysiques', _rowData.representant.id) : null }}},
    id: _rowData?.id
  });

  // Wrap form render into a useCallback to avoid multiple FormWrapper recalls du to form fields updates.
  const renderAddForm = useCallback(() =>
      <FormWrapper classes={'grid p-fluid w-12'} resourceType={'dossierCompanies'} formKeyPrefix={'add_pm_dialog'} cancelLink multiple
                   resetClass={'a8-form-company-dpm-reset'} submitClass={'a8-form-company-dpm-add'} onSubmit={onAddSubmit} hideReload />
    , [rows]); // eslint-disable-line react-hooks/exhaustive-deps

  if (editMode) {
    // Editable datatable.
    return (
      <DatatableWrapper resourceType={resourceType} tableKey={tableKey || 'dirigeants-personnes-morales'} noFilters noGlobalFilter params={params} isLoading={loading} onRefresh={setRows}
                        title={title || (ucfirst(trans(t, 'dirigeant', rows.length)) + ' - ' + ucfirst(trans(t, 'personneMorale', rows.length)))} onNew={onNew}
                        addTitle={trans(t, 'table|add.dirigeant')} additionalData={{ companyData: iri('dossierCompanyData', context?.id) }} paginator={false}
                        editMode={(editMode && (isAdmin() || isExpert())) || false} hookRowEdit={hookRowEdit} requestHeader={header}
                        rows={(rows || [])
                          .sort((a: any, b: any) => a.company?.latestVersion?.raisonSociale?.localeCompare(b.company?.latestVersion?.raisonSociale))
                          .map((_r: any) => ({..._r, ...{representant: isValidUUID(_r?.representant?.id) ? {..._r?.representant, ...{autocompleteLabel: trim(_r?.representant?.firstName + ' ' + _r?.representant?.lastName)}} : null}}))}>
        <Column field={'company.latestVersion.raisonSociale'} header={trans(t, 'ent.raisonSociale')} body={titleBodyTemplate}
                style={{ minWidth: '300px', width: '300px' }} />
        <Column field={'company.latestVersion.type'} header={trans(t, 'ent.formeJuridique')} align={'center'} alignHeader={'center'}
                style={{ minWidth: '200px', width: '200px' }} />
        <Column field={'company.latestVersion.rcsCity'} header={trans(t, 'ent.rcsCity')}
                style={{ minWidth: '200px', width: '200px' }} />
        <Column field={'company.siren'} header={trans(t, 'ent.siren')} align={'center'} alignHeader={'center'}
                style={{ minWidth: '150px', width: '150px' }} />
        <Column field={'company.latestVersion.siret'} header={trans(t, 'ent.siret')} align={'center'} alignHeader={'center'}
                style={{ minWidth: '200px', width: '200px' }} />
        <Column field={'company.latestVersion.isHolding'} header={trans(t, 'holding')} align={'center'} alignHeader={'center'} body={holdingBodyTemplate}
                style={{ minWidth: '100px', width: '100px' }} />
        <Column field={'representant'} header={trans(t, 'ent.representantLegal')} body={representantBodyTemplate}
                editor={(options) => apiListAutocompleteEditor(options, {
                  label: trans(t, 'ent.representantLegal'),
                  placeholder: ucfirst(trans(t, 'form|select_personnePhysique')),
                  resourceType: 'personnesPhysiques',
                  params: [
                    {label: 'order[firstName]', value: 'asc'},
                    {label: 'order[lastName]', value: 'asc'},
                    {label: 'byCompany', value: options?.rowData?.company?.id}
                  ],
                  optionKey: 'autocompleteLabel',
                  action: 'autocomplete',
                  formatter: ppFormatter
                })} style={{ minWidth: '250px', width: '250px' }} />
        <Column field={'dirigeantStatus'} header={trans(t, 'status')} align={'center'} alignHeader={'center'} body={statusBodyTemplate}
                editor={(options) => apiListEditor(options, {label: trans(t, 'function'), listKey: 'dirigeantStatus', showClear: true})}
                style={{ minWidth: '150px', width: '150px' }} />
        {isAdmin() && <Column field={'active'} header={trans(t, 'active')} body={activeBodyTemplate} align={'center'} alignHeader={'center'}
                              editor={(options) => simpleCheckboxEditor(options, {label: trans(t, 'table|active')})}
                              style={{ minWidth: '100px', width: '100px' }} />}
      </DatatableWrapper>
    );
  }

  return (
    <DatatableWrapper resourceType={resourceType} tableKey={tableKey || 'dirigeants-personnes-morales'} noFilters noGlobalFilter params={params} isLoading={loading} onRefresh={setRows}
                      title={title || (ucfirst(trans(t, 'dirigeant', rows.length)) + ' - ' + ucfirst(trans(t, 'personneMorale', rows.length)))} editMode={false}
                      addTitle={trans(t, 'table|add.dirigeant')} additionalData={{ companyData: iri('dossierCompanyData', context?.id) }} paginator={false}
                      rows={(rows || [])
                        .sort((a: any, b: any) => a.company?.latestVersion?.raisonSociale?.localeCompare(b.company?.latestVersion?.raisonSociale))
                        .map((_r: any) => ({..._r, ...{representant: isValidUUID(_r?.representant?.id) ? {..._r?.representant, ...{autocompleteLabel: trim(_r?.representant?.firstName + ' ' + _r?.representant?.lastName)}} : null}}))}>
      <Column field={'company.latestVersion.raisonSociale'} header={trans(t, 'ent.raisonSociale')} body={titleBodyTemplate}
              style={{ minWidth: '300px', width: '300px' }} />
      <Column field={'company.latestVersion.type'} header={trans(t, 'ent.formeJuridique')} align={'center'} alignHeader={'center'}
              style={{ minWidth: '200px', width: '200px' }} />
      <Column field={'company.latestVersion.rcsCity'} header={trans(t, 'ent.rcsCity')}
              style={{ minWidth: '200px', width: '200px' }} />
      <Column field={'company.siren'} header={trans(t, 'ent.siren')} align={'center'} alignHeader={'center'}
              style={{ minWidth: '150px', width: '150px' }} />
      <Column field={'company.latestVersion.siret'} header={trans(t, 'ent.siret')} align={'center'} alignHeader={'center'}
              style={{ minWidth: '200px', width: '200px' }} />
      <Column field={'company.latestVersion.isHolding'} header={trans(t, 'holding')} align={'center'} alignHeader={'center'} body={holdingBodyTemplate}
              style={{ minWidth: '100px', width: '100px' }} />
      <Column field={'representant'} header={trans(t, 'ent.representantLegal')} body={representantBodyTemplate}
              style={{ minWidth: '250px', width: '250px' }} />
      <Column field={'dirigeantStatus'} header={trans(t, 'status')} align={'center'} alignHeader={'center'} body={statusBodyTemplate}
              style={{ minWidth: '150px', width: '150px' }} />
      {isAdmin() && <Column field={'active'} header={trans(t, 'active')} body={activeBodyTemplate} align={'center'} alignHeader={'center'}
                            style={{ minWidth: '100px', width: '100px' }} />}
    </DatatableWrapper>
  );
};
