import React, { useCallback, useEffect, useState } from 'react';
import { AxiosResponse } from 'axios';

import { trans } from 'utilities';
import { IRequestParams, isClient } from 'services';
import { formatXYChart, getModuleId, AMChartXY, IndicatorSimple, PageLoader } from 'components';
import { getIndicator } from 'pages';
import { useThemeState } from 'states';

import { replace } from 'lodash';
import appUri from 'config/appUri.json';

export const TresorerieGenerale = (props: any) => {
  const { id, appState, apim, modulesMenu, lastUpdate } = props;
  const { t } = apim.di();
  const themeState = useThemeState();

  const dossier = appState.dossier();
  const [loading, setLoading] = useState<boolean>(false);
  const [data, setData] = useState<any>(null);

  // Override Page Header (defaults into ModuleWrapper, then TresorerieRouter).
  useEffect(() => {
    appState.setBreadcrumb([
     isClient()
      ? { label: trans(t, 'dossier', 2) }
      : { label: trans(t, 'dossier', 2), to: appUri.dos.list },
     { label: dossier.title, to: replace(appUri.dos.page, ':id', id)},
     { label: trans(t, 'menu|menu.schema_flux.tresorerie_generale') }
    ]);

    appState.setModule(getModuleId(modulesMenu, 'tresorerie.generale'));
  }, [id]); // eslint-disable-line react-hooks/exhaustive-deps

  const fetchData = useCallback(async () => {
    if (!appState.exercise()) return null;

    setLoading(true);
    apim.call({
      resourceType: 'dossiers',
      action: 'module',
      id: id + '|' + getModuleId(modulesMenu, 'tresorerie.generale'),
      method: 'get',
      params: [{label: 'company', value: appState.company()?.id}, {label: 'year', value: appState.exercise()}],
      setLoading,
      success: (res: AxiosResponse) => setData({...res.data, ...{chart1: formatXYChart(res.data, 'tresorerie.generale.chart.histogram', t, {tradCategories: false})}})
    } as IRequestParams);
  }, [appState.company()?.id, appState.exercise(), lastUpdate]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    fetchData().then();
  }, [appState.company()?.id, appState.exercise(), lastUpdate]); // eslint-disable-line react-hooks/exhaustive-deps

  if (loading) return <PageLoader/>;

  return (
    <div className={'flex flex-column xl:flex-row flex-wrap fadein animation-duration-500'}>
      <div className={'flex flex-column xl:w-4'}>
        <div className={'flex py-4 pl-2 pr-3'}>
          <IndicatorSimple color={'primary'} dark bgImage title={trans(t, 'chart|tresorerie.tresorerie') + ' ' + appState.exercise()} value={getIndicator(data, 'tresorerie.tresorerie').value} />
        </div>
        <div className={'flex py-4 pl-2 pr-3'}>
          <IndicatorSimple color={'primary'} bgImage title={trans(t, 'chart|tresorerie.tresorerie') + ' ' + (appState.exercise() - 1)} value={getIndicator(data, 'tresorerie.tresoreriePreviousYear').value} />
        </div>
      </div>

      <div className={'flex xl:w-8 py-4 px-3'}>
        <div className={'flex h-30rem xl:h-full flex-row flex-grow-1'}>
          {!themeState.isDesktop() ? (
            <AMChartXY chartData={data?.chart1} showCursor verticalLegend />
          ) : (
            <AMChartXY chartData={data?.chart1} showCursor />
          )}
        </div>
      </div>

      <div className={'flex xl:w-4 py-4 pl-2 pr-3'}>
        <IndicatorSimple color={'accent3'} bgImage title={trans(t, 'chart|tresorerie.excedentStructurel')} value={getIndicator(data, 'tresorerie.excedentStructurel').value} tooltip={getIndicator(data, 'tresorerie.excedentStructurel').options?.tooltip ? trans(t, 'chart|' + getIndicator(data, 'tresorerie.excedentStructurel').options?.tooltip) : null} precision={getIndicator(data, 'tresorerie.excedentStructurel').options?.precision ?? 1} />
      </div>

      <div className={'flex xl:w-4 py-4 px-3'}>
        <IndicatorSimple color={'accent'} bgImage title={trans(t, 'chart|tresorerie.montantPlace')} value={getIndicator(data, 'tresorerie.montantPlace').value} tooltip={getIndicator(data, 'tresorerie.montantPlace').options?.tooltip ? trans(t, 'chart|' + getIndicator(data, 'tresorerie.montantPlace').options?.tooltip) : null} precision={getIndicator(data, 'tresorerie.montantPlace').options?.precision ?? 1} />
      </div>

      <div className={'flex xl:w-4 py-4 pl-3 pr-2'}>
        <IndicatorSimple color={'accent2'} bgImage title={trans(t, 'chart|tresorerie.duClient')} value={getIndicator(data, 'tresorerie.duClient').value} tooltip={getIndicator(data, 'tresorerie.duClient').options?.tooltip ? trans(t, 'chart|' + getIndicator(data, 'tresorerie.duClient').options?.tooltip) : null} precision={getIndicator(data, 'tresorerie.duClient').options?.precision ?? 1} />
      </div>

      <div className={'flex xl:w-4 py-4 pl-2 pr-3'}>
        <IndicatorSimple color={'accent2'} bgImage title={trans(t, 'chart|tresorerie.duClientHorsDelais')} value={getIndicator(data, 'tresorerie.duClientHorsDelais').value} tooltip={getIndicator(data, 'tresorerie.duClientHorsDelais').options?.tooltip ? trans(t, 'chart|' + getIndicator(data, 'tresorerie.duClientHorsDelais').options?.tooltip) : null} precision={getIndicator(data, 'tresorerie.duClientHorsDelais').options?.precision ?? 1} />
      </div>

      <div className={'flex xl:w-4 py-4 px-3'}>
        <IndicatorSimple color={'accent2'} bgImage title={trans(t, 'chart|tresorerie.perteInduite')} value={getIndicator(data, 'tresorerie.perteInduite').value} tooltip={getIndicator(data, 'tresorerie.perteInduite').options?.tooltip ? trans(t, 'chart|' + getIndicator(data, 'tresorerie.perteInduite').options?.tooltip) : null} precision={getIndicator(data, 'tresorerie.perteInduite').options?.precision ?? 1} />
      </div>
    </div>
  );
};
