import { forEach } from 'lodash';

export const triggerFormCancel = (cancelClass: string | null = null, callback: any | null = null) => triggerFormAction(cancelClass || 'a8-form-cancel', callback);
export const triggerFormReset = (resetClass: string | null = null, callback: any | null = null) => triggerFormAction(resetClass || 'a8-form-reset', callback);
export const triggerFormSubmit = (submitClass: string | null = null, callback: any | null = null) => triggerFormAction(submitClass || 'a8-form-submit', callback);

const triggerFormAction = (actionClass: string, callback: any | null = null) => {
  forEach(document.getElementsByClassName(actionClass), (formReset) => {
    (formReset as HTMLInputElement).click();
  });

  if (callback) {
    return callback();
  }
};
