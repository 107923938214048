export { buildA8ChildTemplate } from './a8_child';
export {
  buildA8CompanyGroupTemplate,
  buildA8Company0Template,
  buildA8CompanyTemplate,
  buildA8Company2Template,
  buildA8Company3Template,
  buildA8Company4Template,
  buildA8Company5Template,
  buildA8Company5pTemplate
} from './a8_company';
export { buildA8GroupTemplate } from './a8_groups';
export { buildA8LinkTemplate } from './a8_link';
export { buildA8MainTemplate } from './a8_main';
export { buildA8PartnerTemplate } from './a8_partner';
