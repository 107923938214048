export interface INotification {
  id: string | null,
  title: string | null,
  content: string | null,
  read: boolean | null,
  done: boolean | null,
  user: string | null,
  author: string | null,
  dossier: string,
  sendEmail:  boolean | null,
  active: boolean | null,
  created: string | null,
  updated: string | null,
  deleted: string | null
}

export interface INotificationsGroup {
  id: string | null,
  title: string | null,
  content: string | null,
  read: boolean | null,
  done: boolean | null,
  type: string | null,
  user: string | null,
  dossier: string,
  emailSent:  boolean | null,
  author: string | null,
  notificationSent:  boolean | null,
  notifications: INotification[],
  active: boolean | null,
  created: string | null,
  updated: string | null,
  deleted: string | null
  dossierUnique: boolean | null
}

export const emptyNotification: INotification = {
  id: null,
  title: null,
  content: null,
  user: null,
  author: null,
  dossier: null,
  active: null,
  created: null,
  updated: null,
  deleted: null,
} as unknown as INotification;
