import React from 'react';
import { Controller } from 'react-hook-form';
import { classNames } from 'primereact/utils';

export const BaseTimeField = (props: any) => {
  const { fieldKey, control, errorMessage, rules, classes, addon, addonPosition, renderInput } = props;
  // A field key can join multiple possibilities using '||' separator.
  const split = fieldKey.split('||');

  return <>
    <Controller
      name={split[0]}
      control={control}
      rules={rules}
      render={({ field, fieldState }) => (
        <div className={classNames('field', classes || 'col-12 md:col-6 lg:col-4')}>
          {addon ? (
            <div className={'p-inputgroup'}>
              {(!addonPosition || addonPosition === 'before') && (
                <span className={'p-inputgroup-addon'}><i className={'pi pi-' + addon}></i></span>
              )}
              {renderInput(field, fieldState)}
              {addonPosition && addonPosition === 'after' && (
                <span className={'p-inputgroup-addon'}><i className={'pi pi-' + addon}></i></span>
              )}
            </div>
          ) : (renderInput(field, fieldState))}
          {errorMessage(field.name)}
        </div>
      )}
    />
  </>
};
