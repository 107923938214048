import React, { useState } from 'react';
import { Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { classNames } from 'primereact/utils';
import { AutoComplete } from 'primereact/autocomplete';
import { Skeleton } from 'primereact/skeleton';

import { trans, ucfirst } from 'utilities';

import { includes, isArray } from 'lodash';

export const StaticListAutocompleteField = (props: any) => {
  const { listKey, fieldKey, control, onFieldChange, errorMessage, rules, listsOptions, loading,
    label, placeholder, description, classes, optionLabel, optionValue } = props;

  const { t } = useTranslation();
  // A field key can join multiple possibilities using '||' separator.
  const split = fieldKey.split('||');
  const [filteredOptions, setFilteredOptions] = useState(listsOptions[listKey]);
  const search = (event: any) => {
    const results = (listsOptions[listKey] || []).filter((option: any) =>
      option[optionLabel].toLowerCase().includes(event.query.toLowerCase())
    );
    if (results?.length > 0) {
      setFilteredOptions(results);
    } else {
      setFilteredOptions(listsOptions[listKey]);
    }
  };

  return (<div className={classNames('field', classes ?? 'col-12 md:col-6 lg:col-4')}>
      {loading ?
        <Skeleton height={'2.8rem'} /> :
        <Controller
          name={split[0]}
          control={control}
          rules={rules}
          render={({ field, fieldState }) => {
            const v: any[] = (listsOptions[listKey] ?? []).filter((i: any) => includes(isArray(field.value) ? field.value.map((fv: any) => fv[optionValue ?? 'id'] ?? fv) : [field.value], i[optionValue ?? 'id']));
            return (
              <>
            <span className={'p-float-label'}>
              <AutoComplete field={optionLabel ?? 'id'} className={classNames('w-full', {'p-invalid': fieldState.error})}
                            placeholder={placeholder ?? ucfirst(trans(t, 'form|select'))} value={(v[0]) ?? field.value}
                            suggestions={filteredOptions} completeMethod={search} forceSelection dropdown
                            onChange={(e) => {onFieldChange(field, fieldState, e.value ? (e.value[optionValue ?? 'id'] ?? e.value) : e.value)}}/>
              {(label ?? field.name) && (
                <label className={'text-ucfirst p-float-label-label'} htmlFor={field.name}>
                  <span>{label ?? trans(t, field.name)}</span>
                </label>
              )}
            </span>
                {description && (<span className={'small'}>{description}</span>)}
                {errorMessage(field.name)}
              </>
            )
          }}
        />
      }
    </div>
  );
};
