import { IRequestParams, subscribe } from 'services';
import { dateString, iri } from 'utilities';
import { forEach, includes } from 'lodash';

const debugMode = process.env.REACT_APP_DEBUG_MODE || '0';

export const formKey = 'a8_ged';
export const parentKey = 'uploaded';
export const parentKeyGed = 'ged';

export const getFiles = (formState: any, localParentKey: string = parentKey, selected: boolean = false, sort: boolean = true): any[] => {
  const files: any[] = formState.getFieldData(formKey, selected ? 'selectedFiles' : 'files', localParentKey) || [];

  return sort ? files.sort((a: any, b: any) => a.dateSort > b.dateSort ? -1 : 1) : files;
};

export const getFile = (formState: any, id: string, localParentKey: string = parentKey): any => {
  const relatedFile = getFiles(formState, localParentKey).filter((f: any) => f.id === id);

  return relatedFile.length > 0 ? relatedFile[0] : null;
};

export const updateFile = (formState: any, file: any, localParentKey: string = parentKey) => {
  let files: any[];
  let index = 0;
  const _f: any[] = getFiles(formState, localParentKey, false, false);

  // Handle particular id switch case.
  if (file.id.substring(0, 4) === 'NEW@') {
    index = _f.map((_file: any) => _file.id).indexOf(file.oldId);
    files = _f.filter((f: any) => f.id !== file.oldId);
    file.id = file.id.substring(4);
  } else {
    index = _f.map((_file: any) => _file.id).indexOf(file.id);
    files = _f.filter((f: any) => f.id !== file.id);
  }

  files.splice(index, 0, file);
  formState.setFieldData(formKey, 'files', localParentKey, files);
};

export const removeFile = (apim: any, formState: any, file: any, localParentKey: string = parentKey, distantRemove: boolean = true) => {
  // Mark file as 'deleting' into the table.
  updateFile(formState, {...file, ...{deleting: true}}, localParentKey);

  const files: any[] = getFiles(formState, localParentKey, false).filter((f: any) => f.id !== file.id);

  if (!distantRemove) {
    formState.setFieldData(formKey, 'files', localParentKey, files);
    return;
  }

  apim.deleteEntity({
    resourceType: 'documents',
    id: file.id,
    success: () => formState.setFieldData(formKey, 'files', localParentKey, files),
    error: () => {
      // Unmark flagged file from the table.
      updateFile(formState, {...file, ...{deleting: false}}, localParentKey);
    }
  } as IRequestParams).then();
};

export const cancelFile = (apim: any, formState: any, file: any, localParentKey: string = parentKey) => {
  apim.call({
    resourceType: 'fileUploads',
    id: file.id,
    action: 'cancel',
    method: 'get',
    success: () => removeFile(apim, formState, file, localParentKey, false)
  } as IRequestParams).then();
};

export const removeFiles = (apim: any, formState: any, files: any[], localParentKey: string = parentKey, setSelected: any, distantRemove: boolean = true) => {
  // Mark all files as 'deleting' into the table.
  const _data: any[] = getFiles(formState, parentKeyGed).filter((f: any) => !includes((files || []).map((s: any) => s.id), f.id));
  forEach(files || [], ((d: any) => _data.push({...d, ...{deleting: true}})));
  formState.setFieldData(formKey, 'files', parentKeyGed, _data);

  const _files: any[] = getFiles(formState, localParentKey, false).filter((f: any) => !includes((files || []).map((s: any) => s.id), f.id));

  if (!distantRemove) {
    formState.setFieldData(formKey, 'files', localParentKey, _files);
    if (setSelected) setSelected([]);

    return;
  }

  // Then process deletion request (VBO) then remove files from datatable.
  apim.call({
    resourceType: 'documents',
    action: 'multipleDelete',
    data: { documents: (files || []).map((s: any) => iri('documents', s.id)) },
    success: () => {
      formState.setFieldData(formKey, 'files', localParentKey, _files);
      if (setSelected) setSelected([]);
    },
    error: () => {
      // Unmark all flagged files from the table.
      const _data: any[] = getFiles(formState, parentKeyGed).filter((f: any) => !includes((files || []).map((s: any) => s.id), f.id));
      forEach(files || [], ((d: any) => _data.push({...d, ...{deleting: false}})));
      formState.setFieldData(formKey, 'files', parentKeyGed, _data);
    }
  } as IRequestParams).then();
};

export const subscribeToHub = (apim: any, formState: any) => {
  const userId = formState.getFieldData(formKey, 'userId');
  if (!userId) return;

  const subscribed = formState.hasSubscribed(formKey);
  if (subscribed) return;

  // Subscribe to the hub.
  subscribe(['/users/' + userId + '/ged'], ((m: any) => {
    if ('1' === debugMode) console.log(m);
    if (!m.data) return;

    const data = JSON.parse(m.data);
    if (!data) return;
    if (data.status !== 'processed') return;
    if (!data.id || !data.fileUuid) return;

    const relatedFile = getFile(formState, data.id);
    if (!relatedFile) return;

    relatedFile.active = true;
    relatedFile.status = 'processed';
    // /!\ Switch from fileUpload ID to DocumentID.
    relatedFile.id = 'NEW@' + (data.fileUuid ?? '');
    relatedFile.oldId = data.id;
    relatedFile.url = data.fileUrl;

    updateFile(formState, relatedFile);

    // Let's move this file from uploaded box to documents list.
    const newDocument: any = {
      ...data.fileData,
      ...{
        url: data.fileUrl,
        updated: data.updated,
        dateSort: dateString(data.updated, 'YYYY-MM-DD HH:mm:ss'),
        dossiers: relatedFile.dossiers,
        companies: relatedFile.companies
      },
    }

    const _data: any[] = (formState.getFieldData(formKey, 'files', parentKeyGed) ?? []).filter((f: any) => f.id !== data.id);
    formState.setFieldData(formKey, 'files', parentKeyGed, [newDocument].concat(_data));
    removeFile(null, formState, data.fileData, parentKey, false);

    // Handle pre-filled dossier / company.
    forEach(relatedFile?.dossiers ?? [], ((d: any) => {
      apim.postEntity({
        resourceType: 'dossierDocuments',
        notif: false,
        data: {
          document: data.fileUuid,
          dossier: iri('dossiers', d.id)
        }
      } as IRequestParams).then();
    }));

    forEach(relatedFile?.companies ?? [], ((c: any) => {
      apim.postEntity({
        resourceType: 'dossierCompanyDocuments',
        notif: false,
        data: {
          document: data.fileUuid,
          company: iri('dossierCompanies', c.id)
        }
      } as IRequestParams).then();
    }));
  }));

  formState.setSubscribed(formKey, true);
};
