import React from 'react';
import { trans } from 'utilities';
import { APIListField, NumberField, StaticListField } from 'forms';

export const CPInvaliditeForm = (props: any) => {
  const { t, control, onFieldChange, errorMessage, getValues, listsOptions } = props;
  const { invaliditeBaremes, type } = getValues();
  const baremesKeys: string[] = invaliditeBaremes ?? [];

  return <div className={'grid w-full px-3'}>
    <div className={'col-3'}>
      <NumberField classes={'w-full'} fieldKey={'invaliditeTotaleRente'} mode={'currency'}
                   addon={'euro'} addonPosition={'after'} control={control} onFieldChange={onFieldChange} maxFractionDigits={0}
                   errorMessage={errorMessage} label={trans(t, type !== 'homme_cle' ? 'form|prevoyance_contrat.invaliditeTotaleRente' : 'form|prevoyance_contrat.invaliditeProRente')}/>
    </div>

    <div className={'col-3'}>
      <NumberField classes={'w-full'} fieldKey={'invaliditeTotaleCapital'} mode={'currency'}
                   addon={'euro'} addonPosition={'after'} control={control} onFieldChange={onFieldChange} maxFractionDigits={0}
                   errorMessage={errorMessage} label={trans(t, 'form|prevoyance_contrat.invaliditeTotaleCapital')}/>
    </div>

    <div className={'col-3'}>
      <NumberField classes={'w-full'} fieldKey={'invaliditePartielleSeuil'} mode={'decimal'} min={0} max={100} suffix={' %'}
                   addon={'percentage'} addonPosition={'after'} control={control} onFieldChange={onFieldChange}
                   errorMessage={errorMessage} label={trans(t, 'form|prevoyance_contrat.invaliditePartielleSeuil')}/>
    </div>

    <div className={'col-3'}>
      <APIListField classes={'w-full'} listKey={'prevoyanceContractInvaliditeSecuriteSociale'} fieldKey={'invaliditePartielleSeuilSecuriteSociale'} control={control} onFieldChange={onFieldChange} errorMessage={errorMessage}
                    label={trans(t, 'form|prevoyance_contrat.invaliditePartielleSeuilSecuriteSociale')}/>
    </div>

    <div className={'col-3'}>
      <NumberField classes={'w-full'} fieldKey={'invaliditeTotaleSeuil'} mode={'decimal'} min={0} max={100} suffix={' %'}
                   addon={'percentage'} addonPosition={'after'} control={control} onFieldChange={onFieldChange}
                   errorMessage={errorMessage} label={trans(t, 'form|prevoyance_contrat.invaliditeTotaleSeuil')}/>
    </div>

    <div className={'col-3'}>
      <APIListField classes={'w-full'} listKey={'prevoyanceContractInvaliditeSecuriteSociale'} fieldKey={'invaliditeTotaleSeuilSecuriteSociale'} control={control} onFieldChange={onFieldChange} errorMessage={errorMessage}
                    label={trans(t, 'form|prevoyance_contrat.invaliditeTotaleSeuilSecuriteSociale')}/>
    </div>

    <div className={'col-3'}>
      <StaticListField classes={'w-full'} listKey={'invaliditeBaremes'} fieldKey={'invaliditeBaremeChoisi'} control={control}
                       listsOptions={{invaliditeBaremes: baremesKeys.length > 0 ? listsOptions.invaliditeBaremes.filter((b: any) => baremesKeys.includes(b.value)) : []}}
                       onFieldChange={onFieldChange} errorMessage={errorMessage} label={trans(t, 'form|prevoyance_contrat.invaliditeBareme')}/>
    </div>
  </div>;
};
