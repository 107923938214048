import React, { useCallback, useEffect, useState } from 'react';
import { AxiosResponse } from 'axios';
import { Checkbox } from 'primereact/checkbox';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';

import { trans } from 'utilities';
import { IRequestParams, isClient } from 'services';
import { formatPieChart, formatXYChart, AMChartPie, AMChartXY, IndicatorSimple, PageLoader, getModuleId, formatTable, IndicatorContract } from 'components';
import { getIndicator } from 'pages';

import { isEmpty, replace } from 'lodash';
import appUri from 'config/appUri.json';
import appConfig from 'config/appConfig.json';

export const DonneesSocialesDirigeant = (props: any) => {
  const { id, appState, apim, modulesMenu, lastUpdate } = props;
  const { t } = apim.di();

  const dossier = appState.dossier();
  const [loading, setLoading] = useState<boolean>(false);
  const [data, setData] = useState<any>(null);
  const [chart1Ratio, setChart1Ratio] = useState<boolean>(false);

  // Override Page Header (defaults into ModuleWrapper, then EntrepriseRouter).
  useEffect(() => {
    appState.setBreadcrumb([
     isClient()
      ? { label: trans(t, 'dossier', 2) }
      : { label: trans(t, 'dossier', 2), to: appUri.dos.list },
     { label: dossier.title, to: replace(appUri.dos.page, ':id', id)},
     { label: trans(t, 'menu|menu.schema_flux.entreprise_dsd') }
    ]);

    appState.setModule(getModuleId(modulesMenu, 'donnees.sociales.dirigeant'));
  }, [id]); // eslint-disable-line react-hooks/exhaustive-deps

  const overrideTable1 = (dataSource: any) => {
    return {...dataSource, ...{
        data: dataSource.data?.map((contrat: any, i: number) => {
          return {...contrat, ...{
              cotisationPriseEnChargeParEntreprise: trans(t, contrat.cotisationPriseEnChargeParEntreprise === true ? 'table|yes' : 'table|no')
            }};
        })
      }
    }
  };

  const fetchData = useCallback(async () => {
    if (!appState.civilYear()) return null;
    setLoading(true);

    apim.call({
      resourceType: 'dossiers',
      action: 'module',
      id: id + '|' + getModuleId(modulesMenu, 'donnees.sociales.dirigeant'),
      method: 'get',
      params: [{label: 'company', value: appState.company()?.id}, {label: 'year', value: appState.civilYear()}],
      cacheMode: isEmpty(lastUpdate) ? 'default' : 'replace',
      setLoading: setLoading,
      success: (res: AxiosResponse) => setData({...res.data, ...{
          chart1: {
            ratio: formatXYChart(res.data, 'dirigeantSocialData.chart.historiqueCotisationsSocialesPourcentageRessourcesTotales', t, {tradCategories: false, method: 'percentage'}),
            value: formatXYChart(res.data, 'dirigeantSocialData.chart.historiqueCotisationsSociales', t, {tradCategories: false})
          },
          chart2: formatPieChart(res.data, 'dirigeantSocialData.chart.montantRepartition', t),
          table1: overrideTable1(formatTable(res.data, 'dirigeantSocialData.table.contrats', t, {method: 'simpleRows'}))
      }})
    } as IRequestParams);
  }, [appState.company()?.id, appState.civilYear(), lastUpdate]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    fetchData().then();
  }, [appState.company()?.id, appState.civilYear(), lastUpdate]); // eslint-disable-line react-hooks/exhaustive-deps

  if (loading) return <PageLoader />;

  const onChart1RatioChange = (e: any) => setChart1Ratio(e.checked || false);

  return (
    <div className={'flex flex-column md:flex-row flex-wrap fadein animation-duration-500'}>
      <div className={'flex md:w-6 xl:w-4 py-4 pl-2 pr-3'}>
        <IndicatorSimple color={'primary'} dark bgImage title={trans(t, 'chart|dirigeantSocialData.cotisationsSocialesObligatoires')} value={getIndicator(data, 'dirigeantSocialData.cotisationsSocialesObligatoires').value} />
      </div>
      <div className={'flex md:w-6 xl:w-4 py-4 px-3'}>
        <IndicatorSimple color={'primary'} dark bgImage title={trans(t, 'chart|dirigeantSocialData.versementsContratsFacultatifs')} value={getIndicator(data, 'dirigeantSocialData.versementsContratsFacultatifs').value} />
      </div>

      <div className={'flex md:w-6 py-4 px-3'}>
        {(data?.chart1?.ratio?.data?.length > 0 || data?.chart1?.ratio?.value?.length > 0) && (
        <div className={'flex h-screen md:h-30rem xl:h-full flex-row flex-grow-1'}>
          <div className={'flex align-items-center'} style={{position: 'absolute', zIndex: 10}}>
            <Checkbox inputId={'chart3Ratio'} name={'chart3Ratio'} onChange={onChart1RatioChange} checked={chart1Ratio} />
            <label htmlFor={'chart3Ratio'} className={'ml-2'}>{trans(t, 'chart|afficherEnPourcentageDesRessourcesTotales')}</label>
          </div>
          {window.innerWidth < appConfig.breakpoints.notebook ? (
            <AMChartXY chartData={chart1Ratio ? data?.chart1?.ratio : data?.chart1?.value} showCursor verticalLegend />
          ) : (
            <AMChartXY chartData={chart1Ratio ? data?.chart1?.ratio : data?.chart1?.value} showCursor />
          )}
        </div>
        )}
      </div>

      <div className={'flex column md:w-6 py-4 px-3'}>
        {data?.chart2?.data?.length > 0 && (
          <div className={'flex h-30rem xl:h-full flex-row flex-grow-1 '}>
            {window.innerWidth < appConfig.breakpoints.notebook ? (
              <AMChartPie chartData={data?.chart2} verticalLegend />
            ) : (
              <AMChartPie chartData={data?.chart2} hideLegend />
            )}
          </div>
        )}
      </div>

      <div className={'flex md:w-6 xl:w-4 py-4 pl-2 pr-3'}>
        <IndicatorSimple color={'accent2'} bgImage title={trans(t, 'chart|dirigeantSocialData.cotisationsSocialesTotalesSurRevenus')} value={getIndicator(data, 'dirigeantSocialData.cotisationsSocialesTotalesSurRevenus').value} options={{symbol: '%', precision: 1}} />
      </div>
      <div className={'flex md:w-6 xl:w-4 py-4 px-3'}>
        <IndicatorSimple color={'accent3'} bgImage title={trans(t, 'chart|dirigeantSocialData.cotisationsObligatoiresRetraite')} value={getIndicator(data, 'dirigeantSocialData.cotisationsObligatoiresRetraite').value} />
      </div>
      <div className={'flex md:w-6 xl:w-4 py-4 pl-3 pr-2'}>
        <IndicatorSimple color={'accent2'} bgImage title={trans(t, 'chart|dirigeantSocialData.cotisationsRetraiteSurCotisationsTotales')} value={getIndicator(data, 'dirigeantSocialData.cotisationsRetraiteSurCotisationsTotales').value} options={{symbol: '%', precision: 1}} />
      </div>
      <div className={'flex md:w-6 xl:w-4 py-4 pl-2 pr-3'}>
        <IndicatorSimple color={'primary'} bgImage title={trans(t, 'chart|dirigeantSocialData.prelevementTotalNetReel')} value={getIndicator(data, 'dirigeantSocialData.prelevementTotalNetReel').value} />
      </div>
      <div className={'flex md:w-6 xl:w-4 py-4 px-3'}>
        <IndicatorSimple color={'primary'} bgImage title={trans(t, 'chart|dirigeantSocialData.remunerationAnnuelle')} value={getIndicator(data, 'dirigeantSocialData.remunerationAnnuelle').value} />
      </div>
      <div className={'flex md:w-6 xl:w-4 py-4 pl-3 pr-2'}>
        <IndicatorSimple color={'accent2'} bgImage title={trans(t, 'chart|dirigeantSocialData.partPreleveeSurAssiette')} value={getIndicator(data, 'dirigeantSocialData.partPreleveeSurAssiette').value} options={{symbol: '%'}} />
      </div>

      <div className={'flex p-fluid mt-3 w-12 justify-content-around align-content-center align-items-center'}>
        <div className={'col-12'}>
          <h5>{trans(t, 'table|dirigeantSocialData.table.contrats')}</h5>
          <div className={'flex flex-column md:flex-row flex-grow-1 justify-content-center'}>
            {['dirigeantSocialData.statusContratRetraite', 'dirigeantSocialData.statusContratPrevoyance', 'dirigeantSocialData.statusContratSante'].map((contract: string, i: number) => (
              <div key={'indicator-contract-' + i} className={'flex flex-wrap w-12 md:w-4 py-4 px-3'}>
                <IndicatorContract t={t} title={trans(t, 'chart|' + contract)} label={getIndicator(data, contract).value} />
              </div>
            ))}
          </div>
        </div>
      </div>

      <div className={'grid p-fluid mt-1 w-full'}>
        <div className={'col-12'}>
          {data?.table1?.data?.length > 0 && (
            <DataTable value={data?.table1?.data} className={'datatable-responsive p-datatable-no-hover flex-1'} stripedRows={true}>
              <Column field={'nomCompagnie'} header={trans(t, 'table|dirigeantSocialData.table.contrats.header.nomCompagnie')} />
              <Column field={'nomContrat'} header={trans(t, 'table|dirigeantSocialData.table.contrats.header.nomContrat')} />
              <Column field={'cadreFiscal'} header={trans(t, 'table|dirigeantSocialData.table.contrats.header.cadreFiscal')} />
              <Column field={'cotisationPriseEnChargeParEntreprise'} header={trans(t, 'table|dirigeantSocialData.table.contrats.header.cotisationPriseEnChargeParEntreprise')} />
              <Column field={'chargesSociales'} header={trans(t, 'table|dirigeantSocialData.table.contrats.header.chargesSociales')} />
              <Column field={'montantCotisations'} header={trans(t, 'table|dirigeantSocialData.table.contrats.header.montantCotisations')} />
            </DataTable>
          )}
        </div>
      </div>
    </div>
  );
};
