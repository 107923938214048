import React, { useEffect, useState } from 'react';
import { Fieldset } from 'primereact/fieldset';

import { DossierCompaniesLinks, PageLoader } from 'components';
import { trans, ucfirst } from 'utilities';
import { IRequestParams } from 'services';

import { AxiosResponse } from 'axios';

export const DossierSocietes = (props: any) => {
  const { dossier, apim } = props;
  const { t } = apim.di();

  const [linksLoading, setLinksLoading] = useState<boolean>(true);
  const [links, setLinks] = useState<any[]>([]);

  // Load dossier company links.
  useEffect(() => {
    let ignore = false;

    apim.fetchEntities({
      resourceType: 'dossierCompanyLinks',
      setLoading: setLinksLoading,
      params: [
        {label: 'dossier', value: dossier.id},
        {label: 'expand[]', value: 'dossier_company_link:read_company'},
        {label: 'expand[]', value: 'dossier_company:read'},
        {label: 'expand[]', value: 'dossier_company:read_latest_version'},
        {label: 'expand[]', value: 'dossier_company_data:read_minimal'},
        {label: 'itemsPerPage', value: 500}
      ],
      success: (res: AxiosResponse) => {
        if (!ignore) {
          setLinks([]);

          if (res?.data && (res?.data['hydra:member'] || []).length > 0) {
            setLinks(res.data['hydra:member']);
          }
        }
      }
    } as IRequestParams).then();

    return () => {
      ignore = true;
    };
  }, [apim, dossier]);

  return (linksLoading ? (
      <PageLoader />
    ) : (
      <div className={'a8-dossier-companies'}>
        <div className={'col-12'}>
          <Fieldset legend={ucfirst(trans(t, 'company', links.length))} toggleable>
            <DossierCompaniesLinks rows={links} setRows={setLinks} apim={apim} title={'none'} editMode dossier={dossier} />
          </Fieldset>
        </div>
      </div>
    )
  );
};
