import React, { useCallback, useEffect, useState } from 'react';
import { useParams, useLocation, useSearchParams } from 'react-router-dom';
import { TFunction } from 'i18next';
import { TabMenu } from 'primereact/tabmenu';

import { IRequestParams, isAdmin, isExpertAdmin, useApim} from 'services';
import { useAppState, useFormState, useUserState } from 'states';
import { DossiersDatatable, PageLoader } from 'components';
import { Error, NotFound } from 'pages';
import { MissionTypesSettings, SocieteExpertsFormRouter } from 'forms';
import { addShortcut, isValidUUID, trans } from 'utilities';
import { SocieteExperts } from './SocieteExperts';
import { SocieteExpertExperts } from './tabs';

import { forEach, includes, isEmpty } from 'lodash';
import appUri from 'config/appUri.json';

export const SocieteExpertsRouter = (props: any) => {
  const { action } = props;
  const { id, tab } = useParams();

  const apim = useApim();
  const { t, navigate } = apim.di();
  const location = useLocation();
  const appState = useAppState();
  const formState = useFormState();
  const userState = useUserState();

  const [loading, setLoading] = useState<boolean>(false);
  const [data, setData] = useState<any>();
  const [notFound, setNotFound] = useState<boolean>(false);
  const [errored, setErrored] = useState<boolean>(false);

  // Let's detect if we are in "edit/add" mode
  // then render the appropriate display (form or module dashboard).
  const editMode = appUri.reservedKeywords.edit === action;
  const addMode = appUri.reservedKeywords.add === action;

  // Handle tabs.
  const [activeIndex, setActiveIndex] = useState<number>(0);
  const baseUri = appUri.cie.exp.page.replace(':id', id || '_');
  const tabs: any[] = [];
  if (id) {
    tabs.push({ label: trans(t, 'menu|tab.societeExpert'), command: () => navigate(baseUri) });

    forEach(appUri.cie.exp.tabs, (ta) => {
      tabs.push({ label: trans(t, 'menu|tab.' + ta), command: () => navigate(baseUri + '/' + ta) });
    });

    if (isExpertAdmin() || isAdmin()) {
    tabs.push({ label: trans(t, 'menu|tab.parametres'), command: () => navigate(baseUri + '/parametres') });
    }
  }

  // Validate URL & build tabs.
  const checks = useCallback(() => {
    return isUrlValid(t, id, tab);
  }, [id, tab]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (!checks()) setNotFound(true);

    let ai = 0;
    forEach(appUri.cie.exp.tabs, (ta, index) => {
      if (tab === ta) ai = index + 1;
    });

    if (tab === 'parametres') ai = 3;
    if (ai !== activeIndex) setActiveIndex(ai);
  }, [id, tab]); // eslint-disable-line react-hooks/exhaustive-deps

  // Let's detect if API calls from now have to be refreshed (cache expiration).
  const [searchParams] = useSearchParams();
  const lastUpdate = searchParams.get('maj');
  const fetchData = useCallback(() => {
    if (!id || editMode || addMode) return;
    setLoading(true);

    apim.fetchEntities({
      resourceType: 'societesExperts',
      id: id,
      cacheMode: isEmpty(lastUpdate) ? 'default' : 'replace',
      setLoading: setLoading,
      setter: setData,
      setErrored: setErrored,
      setNotFound: setNotFound
    } as IRequestParams).then();
  }, [id, lastUpdate, data]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (!data || !id || (data.id !== id) || lastUpdate) {
      fetchData();
    } else {
      setLoading(false);
    }
  }, [id, lastUpdate]); // eslint-disable-line react-hooks/exhaustive-deps

  // Update Page Header.
  useEffect(() => {
    let pageTitle: string = trans(t, 'societeExpert') + ' ' + (data?.raisonSociale || '');
    if (editMode || addMode) {
      pageTitle += ' | ' + trans(t, editMode ? 'edit' : 'adding');
      appState.setPageActions([]);
    } else {
      const newPA: any[] = [
        { label: trans(t, 'short.add'), icon: 'bookmark', command: () => addShortcut(t, formState, appState) },
        { label: trans(t, 'cie.edit'), icon: 'pencil', to: appUri.cie.exp.edit.replace(':id', id || '_') }
      ];
      if (isAdmin()) {
        newPA.push({ label: trans(t, 'cie.add'), icon: 'plus', to: appUri.cie.exp.add, className:'bg-green-500' });
      }
      appState.setPageActions(newPA);
    }
    appState.setPageTitle(pageTitle);
    appState.setBreadcrumb([{
      label: trans(t, 'societeExpert', 2),
      to: appUri.cie.exp.list
    }, { label: data?.raisonSociale || trans(t, 'societeExpert') }]);
  }, [data, lastUpdate, userState.id()]); // eslint-disable-line react-hooks/exhaustive-deps

  const buildTabContent = useCallback(() => {
    switch (activeIndex) {
      case 1:
        // Users expert management.
        return <SocieteExpertExperts apim={apim} company={data}/>;

      case 2:
        // Dossiers management.
        return <DossiersDatatable appState={appState} tableKey={'users-experts-dossiers'} title={trans(t, 'dossier', 2)}
                                  params={[{ label: 'societeExpertId', value: data?.id }]} noFilters apim={apim}/>;
      case 3:
        // Missions settings.
        if (isExpertAdmin() || isAdmin()) {
          return <MissionTypesSettings appState={appState} tableKey={'users-experts-dossiers'} title={trans(t, 'settings')} data={data} noFilters apim={apim}/>;
        } else setNotFound(true);
        return null;

      default:
        // Profile.
        return <SocieteExperts t={t} company={data}/>;
    }
  }, [id, tab, activeIndex, data]); // eslint-disable-line react-hooks/exhaustive-deps

  if (editMode || addMode) return <SocieteExpertsFormRouter id={id} tab={tab} societeType={'experts'} appState={appState} apim={apim} location={location} action={action}/>
  if (notFound) return <NotFound asBlock/>;
  if (errored) return <Error asBlock/>;
  if (loading) return <PageLoader/>;

  return (
    <div className={'card height-100 fadein animation-duration-500 flex flex-column'}>
      <div className={'a8-page-header flex flex-wrap'}>
        <div className={'flex-auto flex p-2 justify-content-end pt-0'}>
          {tabs.length > 1 && <TabMenu className={'pb-2'} model={tabs} activeIndex={activeIndex} onTabChange={(e) => setActiveIndex(e.index)}/>}
        </div>
      </div>
      {buildTabContent()}
    </div>
  );
};

const isUrlValid = (t: TFunction, id: string | undefined, tab: string | undefined) => {
  // Ensure valid entity UUID & existing tab.
  return isValidUUID(id) && (includes([...[undefined], ...appUri.cie.exp.tabs], tab) || (tab === 'parametres'));
};
