import React, { useRef } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';

import { asDate, dateString, trans } from 'utilities';

export const Representants = (props: any) => {
  const { data, apim } = props;
  const { t } = apim.di();
  const dt = useRef<any>(null);

  const addressBodyTemplate = (rowData: any) => {
    return <div>
      <div className={'postalCode'}>{rowData.postalCode}</div>
      <div className={'city'}>{rowData.city}</div>
      <div className={'country'}>{rowData.country}</div>
    </div>;
  };

  const nameBodyTemplate = (rowData: any) => {
    if (rowData.isPersonneMorale) {
      return <div>
        <div key={'name'}>{rowData.completeName}</div>
        <div key={'siren'}>{trans(t, 'siren')} : {rowData.siren}</div>
      </div>;

    } else {
      return <div>
        <div key={'name'}>{(rowData.mainFirstName ?? rowData.firstName) + ' ' + rowData.lastName}</div>
        {rowData.birthDate && (
          <div key={'birthDate'}>{trans(t, 'born')} : {dateString(asDate(rowData.birthDate))}</div>
        )}
      </div>;
    }
  };

  return (
    <>
      <DataTable ref={dt} value={data} className={'datatable-responsive'} emptyMessage={trans(t, 'representant_none')} stripedRows={true} sortField={'siege'}>
        <Column field={'name'} header={trans(t, 'name')} body={nameBodyTemplate} style={{ width: '250px' }} />
        <Column field={'addressLine2'} header={trans(t, 'address')} body={addressBodyTemplate} />
        <Column field={'function'} header={trans(t, 'function')} />
        <Column field={'nationality'} header={trans(t, 'nationality')} />
      </DataTable>
    </>
  );
};
