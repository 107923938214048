import React, { useEffect, useState } from 'react';
import { AxiosResponse } from 'axios';

import { Button } from 'primereact/button';
import { Column } from 'primereact/column';
import { Skeleton } from 'primereact/skeleton';
import { SpeedDial } from 'primereact/speeddial';

import { FormWrapper } from 'forms/index';
import { IRequestParams } from 'services';
import { dialog, iri, isValidUUID, trans, ucfirst } from 'utilities';
import { amountCell, apiListCell, DatatableWrapper, dateTimeCell, SimpleText } from 'components';

import { trimStart } from 'lodash';
import appUri from 'config/appUri.json';

export const ContratsFacultatifs = (props: any) => {
  const { apim, urls, missionCategory, hypothese, dossier, cancelUri, missionId, appState } = props;
  const { t, navigate } = apim.di();
  const [cadresFiscaux, setCadresFiscaux] = useState<any[]>([]);
  const [expandedRows, setExpandedRows] = useState<any[] | null>(null);
  const [editMode, setEditMode] = useState<boolean>(false);
  const [rows, setRows] = useState<any[]>( []);
  const [data, setData] = useState<any[]>(rows ?? []);
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    if (isValidUUID(missionId) && appState.mission()?.id !== missionId) {
      appState.setMission(missionId)
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (isValidUUID(missionCategory?.id)) {
      apim.fetchEntities({
        resourceType: 'missionRetraiteContracts',
        params: [{ label: 'missionRetraite', value: missionCategory?.id }],
        setter: setRows,
        notif: false
      } as IRequestParams);
    }
  }, [missionCategory?.id, setRows]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    setData(rows);
  }, [rows]);

  useEffect(() => {
    apim.getList('dossierFiscalRegimes', { setter: setCadresFiscaux } as IRequestParams).then();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps
  const cadreFiscalTemplate = (rowData: any) => apiListCell(rowData?.cadreFiscal, cadresFiscaux);
  const valeurEnStockBodyTemplate = (rowData: any) => amountCell(rowData?.valeurEnStock);
  const versementAnnuelBodyTemplate = (rowData: any) => amountCell(rowData?.versementAnnuel);
  // const montantRetraiteFacultativeEstimeeBodyTemplate = (rowData: any) => amountCell(rowData?.montantRetraiteFacultativeEstimee);
  // const gainFiscalAnneeBodyTemplate = (rowData: any) => amountCell(rowData?.gainFiscalAnnee);
  // const gainFiscalCumuleBodyTemplate = (rowData: any) => amountCell(rowData?.gainFiscalCumule);
  const createdBodyTemplate = (rowData: any) => dateTimeCell(rowData?.created);
  const updatedBodyTemplate = (rowData: any) => dateTimeCell(rowData?.updated);

  let newParams: any[] = [{ label: 'missionRetraite', value: iri('missionRetraite', missionCategory?.id) }];

  const onEdit = (rowData: any) => {
    setEditMode(true);
    setExpandedRows([rowData]);
  };

  const actionsBodyTemplate = (rowData: any) => {
    let items = [];
    items.push({
      label: ucfirst(trans(t, 'edit')),
      icon: 'pi pi-pencil',
      className: 'bg-indigo-500',
      command: () => onEdit(rowData),
    });
    items.push({
      label: ucfirst(trans(t, 'system|actions.delete')),
      icon: 'pi pi-trash',
      className: 'bg-red-500',
      command: () => {
        dialog(t, {
          message: trans(t, 'system|confirmations.short'),
          accept: () => {
            if (rowData?.id) {
              apim.deleteEntity({
                resourceType: 'missionRetraiteContracts',
                id: rowData?.id,
                success: () => {
                  setData(data => data.filter(item => item.id !== rowData?.id));
                },
              } as IRequestParams);
            }
          },
          acceptClassName: 'p-button-danger',
          rejectClassName: 'p-button-text p-button-primary'
        });
      }
    })

    return <>
      <SpeedDial className={'a8-speedial-datatable relative z-5'} model={items} direction={'left'} transitionDelay={40}
                 showIcon={'pi pi-ellipsis-v'} hideIcon={'pi pi-times'} buttonClassName={'p-button-text'}/>
    </>
  }
  const onNewRow = (_rows: any[], callback: any) => {
    if (missionCategory) {
      setLoading(true)
      apim.postEntity({
        resourceType: 'missionRetraiteContracts',
        data: {
          missionRetraite : iri('missionRetraite', missionCategory?.id),
          nomReferenceContrat : 'Nouveau contrat'
        },
        setLoading,
        success: (res: AxiosResponse) => {
          setData([...data, res.data]);
          callback([..._rows, ...[res.data]]);

          // Toggle edit on added row.
          setExpandedRows([res.data]);
          onEdit(res.data);
        }
      } as IRequestParams).then();
    }
  };

  const onSubmitCallback = (newData : any) => {
    setEditMode(false);
    setData((data) =>
      data.map((item) => (item.id === newData?.id ? newData : item))
    );
  }

  const rowExpansionTemplate = (rowData: any) => {
    return (
      editMode
        ? <FormWrapper classes={'grid p-fluid'} submitClass={'pr-5'}
                       formKeyPrefix={'contrats_facultatifs_retraite'} resourceType={'missionRetraiteContracts'}
                       additionalData={{ missionRetraite: iri('missionRetraite', missionCategory?.id) }}
                       hideReload cancelLink data={rowData} callback={onSubmitCallback}/>
        :
        <div className={'flex flex-column col-12'}>
          <div className={'flex flex-wrap flex-row col-12'}>
            <div className={'col-4'}>
              <SimpleText text={rowData.nomReferenceContrat} title={ucfirst(trans(t, 'nomReferenceContrat'))}/>
            </div>

            <div className={'col-4'}>
              <SimpleText text={rowData.compagnie} title={ucfirst(trans(t, 'company'))}/>
            </div>

            <div className={'col-4'}>
              <SimpleText  methodParams={{ listKey: 'dossierFiscalRegimes' }} value={rowData.cadreFiscal}
                           title={trans(t, 'cadreFiscal')} method={'list'} />
            </div>
          </div>

          <div className={'flex flex-wrap flex-row col-12'}>
            <div className={'col-4'}>
              <SimpleText value={rowData.valeurEnStock} title={trans(t, 'valeurEnStock')}/>
            </div>
            <div className={'col-4'}>
              <SimpleText value={rowData.versementAnnuel} title={trans(t, 'versementAnnuel')}/>
            </div>
            <div className={'col-4'}>
              <SimpleText text={rowData.tableMortalite}  title={ucfirst(trans(t, 'tableMortalite'))}/>
            </div>
          </div>
          <div className={'flex flex-wrap flex-row col-12'}>
            <div className={'col-4'}>
              <SimpleText value={rowData.tauxTechnique}  method={'percentage'} title={trans(t, 'tauxTechnique')}/>
            </div>
            <div className={'col-4'}>
              <SimpleText value={rowData.montantRetraiteFacultativeEstimee} title={trans(t, 'montantRetraiteFacultativeEstimee')}/>
            </div>
            <div className={'col-4'}>
              <SimpleText value={rowData.gainFiscalAnnee} title={trans(t, 'gainFiscalAnnee')}/>
            </div>
          </div>
          <div className={'flex flex-wrap flex-row col-12'}>

            <div className={'col-4'}>
              <SimpleText value={rowData.gainFiscalCumule} title={trans(t, 'gainFiscalCumule')}/>
            </div>
            <div className={'col-4'}>
              <SimpleText value={rowData.parametreRemuneration} method={'percentage'} title={trans(t, 'parametreRemuneration')}/>
            </div>

          </div>
          <div className={'flex flex-wrap flex-row col-12'}>
            <div className={'flex col-4'}>
              <SimpleText value={rowData.reversionAutomatique} method={'bool'} title={trans(t, 'reversionAutomatique')}/>
            </div>
            <div className={'flex col-4'}>
              <SimpleText value={rowData.garantieBonneFin} method={'bool'} title={trans(t, 'garantieBonneFin')}/>
            </div>
            <div className={'flex col-4'}>
              <SimpleText value={rowData.exonerationsCotisations} method={'bool'} title={trans(t, 'exonerationsCotisations')}/>
            </div>
          </div>
        </div>
    )
  };

  return (
    <>
    {(loading) ? (
      <>
        <Skeleton width={'100%'} height={'20rem'} className={'mb-5'}/>
      </>
    ) : (
      <DatatableWrapper
        resourceType={'missionRetraiteContracts'} title={trans(t, 'menu|pages.title.missionRetraiteContract')} stripedRows={!editMode}
        additionalClassNames={editMode ? 'p-datatable-no-hover' : ''} params={newParams} onNew={onNewRow} noFilters={true} paginator={false}
        rows={data} onRefresh={setData} expandedRows={expandedRows} rowExpansionTemplate={rowExpansionTemplate} onRowToggle={(e: any) => {
          setExpandedRows(e.data);
        }}>
        <Column expander={true} style={{ width: '3em' }} />
        <Column field={'cadreFiscal'} header={trans(t, 'cadreFiscal')} sortable body={cadreFiscalTemplate}/>
        <Column field={'nomReferenceContrat'} header={trans(t, 'nomReferenceContrat')} sortable
        />
        <Column field={'compagnie'} header={trans(t, 'company')} sortable style={{ width: '200px' }}/>
        <Column field={'valeurEnStock'} header={trans(t, 'valeurEnStock')} body={valeurEnStockBodyTemplate}
                style={{ width: '200px' }}/>
        <Column field={'versementAnnuel'} header={trans(t, 'versementAnnuel')} body={versementAnnuelBodyTemplate}
                style={{ width: '200px' }}/>
        {/*<Column field={'montantRetraiteFacultativeEstimee'} header={trans(t, 'montantRetraiteFacultativeEstimee')}*/}
        {/*        body={montantRetraiteFacultativeEstimeeBodyTemplate} style={{ width: '200px' }}/>*/}
        {/*<Column field={'gainFiscalAnnee'} header={trans(t, 'gainFiscalAnnee')} body={gainFiscalAnneeBodyTemplate}*/}
        {/*        style={{ width: '200px' }}/>*/}
        {/*<Column field={'gainFiscalCumule'} header={trans(t, 'gainFiscalCumule')} body={gainFiscalCumuleBodyTemplate}*/}
        {/*        style={{ width: '200px' }}/>*/}
        <Column field={'updated'} header={trans(t, 'updated')} sortable align={'center'} body={updatedBodyTemplate}
                style={{ width: '225px' }}/>
        <Column field={'created'} header={trans(t, 'created')} sortable align={'center'} body={createdBodyTemplate}
                style={{ width: '225px' }}/>
        <Column header={trans(t, 'system|action', 2)} align={'right'} body={actionsBodyTemplate}
                style={{ width: '150px' }}/>
      </DatatableWrapper>
      )}
      <div className={'text-right m-2 pt-4'}>
        <>
          <Button type={'reset'} className={'text-ucfirst p-button-text p-button-danger mr-5'} label={trans(t, 'close')}
                  onClick={() => navigate(cancelUri)}/>
          <Button type={'submit'} className={'text-ucfirst p-button-text'} label={trans(t, 'back')}
                  icon={'pi pi-arrow-left'}
                  onClick={() => navigate(urls?.form + '/' + missionId + '/hypothese/' + hypothese?.id + appUri.dos.edit.missions.steps.retraite.step2)}/>
          <Button type={'submit'} className={'text-ucfirst'} label={trans(t, 'terminate')} icon={'pi pi-check'}
                  onClick={() => {
                    navigate((trimStart(appUri.dos.page) + appUri.dos.pageMissionRetraite)
                      .replace(':id', dossier?.id)
                      .replace(':module', 'retraite')
                      .replace(':tab', 'obligatoire')
                      .replace(':mission', missionId))}}
          />
        </>
      </div>
    </>
  )
}
