import React from 'react';
import { Checkbox } from 'primereact/checkbox';
import { UncontrolledBaseField } from './UncontrolledBaseField';

export const SimpleUncontrolledCheckboxField = (props: any) => {
  const { fieldKey, value, onFieldChange, tooltip, tooltipOptions, description, classes, addon, addonPosition, disabled } = props;

  return (
    <UncontrolledBaseField classes={classes} addon={addon} addonPosition={addonPosition} description={description} >
          <Checkbox inputId={fieldKey} id={fieldKey} value={value} disabled={disabled}
                    onChange={(e) => onFieldChange(e.checked)} checked={value && value !== false}
                    tooltip={tooltip} tooltipOptions={tooltipOptions || {position: 'top'}} />
    </UncontrolledBaseField>
  );
};
