import React, { useEffect, useState } from 'react';

import { ScrollPanel } from 'primereact/scrollpanel';
import { Skeleton } from 'primereact/skeleton';
import { isValidUUID, trans } from 'utilities';
import { IRequestParams } from 'services';
import { RecentActivityItem } from 'components/widgets/RecentActivityItem';

export const DocumentActivityLog = (props: any) => {
  const { apim, document } = props;
  const { t } = apim.di();

  const [loading, setLoading] = useState<boolean>(false);
  const [activities, setActivities] = useState<any[]>([]);

  useEffect(() => {
    if (isValidUUID(document?.id)) {
      setLoading(true);
      apim.fetchEntity({
        resourceType: 'documentActivities',
        setLoading: setLoading,
        id: document.id,
        notif: false,
        cache: false,
        setter: setActivities
      } as IRequestParams).then();
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return <>
    <h6 className={'header text-ucfirst'}>{trans(t, 'activity.latest', 1)}</h6>
    <ScrollPanel style={{height: '72vh'}}>
      <div className={'flex flex-column gap-2'}>
        {loading ? (
          [1, 2, 3, 4, 5, 6, 7, 8].map((i: number) => (
            <Skeleton key={i} shape={'rectangle'} width={'100%'} height={'4rem'}/>
          ))
        ) : (
          activities.map((activity: any, i: number) => (
            <RecentActivityItem key={i} data={activity} apim={apim}/>
          ))
        )}
      </div>
    </ScrollPanel>
  </>;
};
