import React, { useEffect, useState } from 'react';
import { Fieldset } from 'primereact/fieldset';

import { escapeValue, trans, ucfirst } from 'utilities';
import { APIListField, CheckboxField, CountryField, MaskField, StaticListField, TextField } from 'forms';
import { isAdmin } from 'services';
import { useFormState } from 'states';

const codesPostaux = require('codes-postaux');

export const PersonnePhysiquePlusNestedForm = (props: any) => {
  const { t, control, onFieldChange, errorMessage, getValues, setValue, formKey, parentKey } = props;
  const fields: any[] = [{key: 'address1', tradKey: 'address'}, {key: 'address2', tradKey: 'complement'}, {key: 'break'}, {key: 'postalCode'}];
  const breakField = (index: any) => index ? <div key={index} className={'field col-12 md:col-6 lg:col-4'}/> : <div className={'field col-12 md:col-6 lg:col-4'}/>;
  const { birthAddress, city, noSocialSecurityNumber, postalCode } = getValues();
  const [citiesArray, setCitiesArray] = useState<any[]>([]);
  const [birthCitiesArray, setBirthCitiesArray] = useState<any[]>([]);
  const formState = useFormState();
  const birthAddressPostalCode = birthAddress ? birthAddress['postalCode'] : null;

  useEffect(() => setCitiesArray(codesPostaux.find(postalCode) ?? []), [postalCode]); // eslint-disable-line react-hooks/exhaustive-deps
  useEffect(() => {
    if (citiesArray.length === 1 && city?.upperCase !== citiesArray[0].libelleAcheminement?.upperCase) {
      formState.setFieldData(formKey, 'city', parentKey, escapeValue(citiesArray[0].libelleAcheminement, 'string'));
      setValue('city', citiesArray[0].libelleAcheminement);
    }
  }, [citiesArray]); // eslint-disable-line react-hooks/exhaustive-deps

  const cityOptions = citiesArray.map((option: any) => ({
    label: option.libelleAcheminement,
    value: option.libelleAcheminement
  }));

  useEffect(() => setBirthCitiesArray(codesPostaux.find(birthAddressPostalCode) ?? []), [birthAddressPostalCode]); // eslint-disable-line react-hooks/exhaustive-deps
  useEffect(() => {
    if (birthCitiesArray.length === 1 && birthCitiesArray[0].libelleAcheminement?.upperCase !== birthAddress?.city?.upperCase) {
      formState.setFieldData(formKey, 'birthAddress.city', parentKey, escapeValue(birthCitiesArray[0].libelleAcheminement, 'string'));
      setValue('birthAddress.city', birthCitiesArray[0].libelleAcheminement);
    }
  }, [birthCitiesArray]); // eslint-disable-line react-hooks/exhaustive-deps

  const birthCityOptions = birthCitiesArray.map((option: any) => ({
    label: option.libelleAcheminement,
    value: option.libelleAcheminement
  }));

  return <>
    <Fieldset legend={ucfirst(trans(t, 'civilState'))}>
      <div className={'flex flex-row flex-wrap'}>
        <APIListField listKey={'sexes'} fieldKey={'sex'} control={control} onFieldChange={onFieldChange} errorMessage={errorMessage} label={ucfirst(trans(t, 'sex'))}/>
        <APIListField listKey={'civilities'} fieldKey={'civility'} control={control} onFieldChange={onFieldChange} errorMessage={errorMessage} label={ucfirst(trans(t, 'civility'))}/>
        <TextField fieldKey={'birthName'} control={control} onFieldChange={onFieldChange} label={ucfirst(trans(t, 'birthName'))} errorMessage={errorMessage}/>
        <TextField fieldKey={'lastName'} control={control} onFieldChange={onFieldChange} label={ucfirst(trans(t, 'lastName'))}
                   errorMessage={errorMessage} rules={{required: trans(t, 'form|requiredField')}}/>
        <TextField fieldKey={'firstName'} control={control} onFieldChange={onFieldChange} errorMessage={errorMessage} label={ucfirst(trans(t, 'firstName', 2))}/>
        <MaskField fieldKey={'birthDate'} control={control} onFieldChange={onFieldChange} label={ucfirst(trans(t, 'birthDate'))}
                   errorMessage={errorMessage} rules={{required: trans(t, 'form|requiredField')}} isDate/>
        <TextField fieldKey={'birthAddress.postalCode'} control={control} onFieldChange={onFieldChange} label={ucfirst(trans(t, 'birthplacePostalCode'))} errorMessage={errorMessage}/>
        <StaticListField listKey={'birthCities'} listsOptions={{birthCities:birthCityOptions}} fieldKey={'birthAddress.city'} label={ucfirst(trans(t, 'birthplaceCity'))} control={control} optionLabel={'label'} errorMessage={errorMessage} onFieldChange={onFieldChange} editable={true}/>
        <CountryField fieldKey={'birthAddress.country'} control={control} onFieldChange={onFieldChange} label={ucfirst(trans(t, 'birthplaceCountry'))} errorMessage={errorMessage}/>
        <TextField fieldKey={'function'} control={control} onFieldChange={onFieldChange} label={ucfirst(trans(t, 'function'))} errorMessage={errorMessage}/>
        <TextField fieldKey={'initials'} control={control} onFieldChange={onFieldChange} errorMessage={errorMessage} label={trans(t, 'initials')}/>
      </div>

      <div className={'flex flex-row flex-wrap'}>
        <CheckboxField fieldKey={'deceased'} control={control} onFieldChange={onFieldChange} errorMessage={errorMessage} label={ucfirst(trans(t, 'deceased'))}/>
        {true === getValues()['deceased'] && (
         <MaskField fieldKey={'deceasedDate'} control={control} onFieldChange={onFieldChange} label={ucfirst(trans(t, 'deceasedDate'))} errorMessage={errorMessage} isDate/>
        )}
      </div>
    </Fieldset>

    <Fieldset className={'my-5'} legend={ucfirst(trans(t, 'socialSecurity'))}>
      <div className={'flex flex-row flex-wrap'}>
        <CheckboxField fieldKey={'noSocialSecurityNumber'} control={control} onFieldChange={onFieldChange} errorMessage={errorMessage} label={ucfirst(trans(t, 'noSocialSecurityNumber'))}/>
        {true !== noSocialSecurityNumber && (
         <MaskField fieldKey={'socialSecurityNumber'} control={control} onFieldChange={onFieldChange} label={ucfirst(trans(t, 'socialSecurityNumber'))} errorMessage={errorMessage} mask={'9 99 99 99 999 999 99'}/>
        )}
      </div>
    </Fieldset>

    <Fieldset className={'my-5'} legend={ucfirst(trans(t, 'specificities'))}>
      <div className={'flex flex-row flex-wrap'}>
        <CheckboxField fieldKey={'veteran'} control={control} onFieldChange={onFieldChange} errorMessage={errorMessage} label={ucfirst(trans(t, 'veteran'))}/>
        <CheckboxField fieldKey={'singleParent'} control={control} onFieldChange={onFieldChange} errorMessage={errorMessage} label={ucfirst(trans(t, 'singleParent'))}/>
      </div>
    </Fieldset>

    <Fieldset legend={ucfirst(trans(t, 'contact'))}>
      <div className={'flex flex-row flex-wrap'}>
        <TextField fieldKey={'email'} control={control} onFieldChange={onFieldChange} errorMessage={errorMessage} label={ucfirst(trans(t, 'email'))} addon={'at'}/>
        <TextField fieldKey={'phone1'} control={control} onFieldChange={onFieldChange} errorMessage={errorMessage} label={ucfirst(trans(t, 'phone1'))} addon={'phone'}/>
        <TextField fieldKey={'phone2'} control={control} onFieldChange={onFieldChange} errorMessage={errorMessage} label={ucfirst(trans(t, 'phone2'))} addon={'phone'}/>
      </div>
    </Fieldset>

    <Fieldset className={'mt-5'} legend={ucfirst(trans(t, 'address'))}>
      <div className={'flex flex-row flex-wrap'}>
        {fields.map((field: any, index: number) => (
         'break' === field.key ? (
          breakField(index)
         ) : (
          <TextField key={index} fieldKey={field.key} control={control} onFieldChange={onFieldChange}
                     errorMessage={errorMessage} label={trans(t, field.tradKey || field.key)} tooltip={field.tooltip}/>
         )
        ))}
        <StaticListField listKey={'cities'} listsOptions={{cities:cityOptions}} fieldKey={'city'} control={control} label={ucfirst(trans(t, 'city'))} optionLabel={'label'} errorMessage={errorMessage} onFieldChange={onFieldChange} editable={true}/>
        <CountryField fieldKey={'country'} control={control} onFieldChange={onFieldChange} errorMessage={errorMessage} label={ucfirst(trans(t, 'country'))}/>
      </div>
    </Fieldset>

    {isAdmin() && (
     <div className={'flex flex-row flex-wrap mt-5'}>
       <CheckboxField fieldKey={'active'} control={control} onFieldChange={onFieldChange} errorMessage={errorMessage} label={ucfirst(trans(t, 'active'))}/>
     </div>
    )}
  </>
};
