import React, { useCallback, useEffect, useState } from 'react';
import { AxiosResponse } from 'axios';
import { useParams } from 'react-router-dom';

import { Button } from 'primereact/button';
import { Column } from 'primereact/column';
import { Dropdown } from 'primereact/dropdown';

import { FormWrapper } from 'forms/index';
import { trans, ucfirst, iri, triggerFormSubmit, isValidUUID } from 'utilities';
import { DatatableWrapper, dateTimeCell, PageLoader, simpleCheckboxEditor, textEditor, validCell } from 'components';
import { Error, NotFound } from 'pages';
import { IRequestParams, userId } from 'services';
import { useFormState } from 'states';

import { forEach, toUpper } from 'lodash';
import appUri from 'config/appUri.json';

export const MissionSelection = (props: any) => {
  const formState = useFormState();

  const { type } = useParams();
  const { missionId, apim, appState, urls, setMissionCategory, dossier
  } = props;
  const { t, navigate } = apim.di();
  const cancelUri: string = urls?.landing + '?maj=' + Date.now();
  const [userSocietes, setUserSocietes] = useState<any[]>([]);

  const [missions, setMissions] = useState<any[]>([]);
  const [selectedMission, setSelectedMission] = useState<any>(null);
  const [missionType, setMissionType] = useState<any>(null);
  const [selectedHypothesis, setSelectedHypothesis] = useState<any>(null);

  const [hypothesis, setHypothesis] = useState<any[]>()
  const [loading, setLoading] = useState<boolean>(false);
  const [notFound, setNotFound] = useState<boolean>(false);
  const [errored, setErrored] = useState<boolean>(false);

  useEffect(() => {
    if (missions?.length > 0 && isValidUUID(missionId) && !hypothesis && isValidUUID(missionType?.id) && appState?.mission()?.missionType === iri('missionTypes', missionType?.id)) {
      setSelectedMission(missions?.find(item => item.id === appState.mission()?.id));
      window.history.replaceState(null, '', urls?.form + '/' + appState.mission()?.id);
    }
    if (selectedMission?.id === appState.mission()?.id && isValidUUID(selectedHypothesis?.id)) {
      window.history.replaceState(null, '', urls?.form + '/' + appState.mission()?.id + '/hypothese/' + selectedHypothesis?.id);
    }
  }, [appState.mission()?.id, missionType?.id, missions, missionId, hypothesis, selectedHypothesis]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (isValidUUID(userId())){
      apim.fetchEntity({
        resourceType: 'userGroups',
        params: [
          {label: 'user', value: userId()},
          {label: 'group.name', value: 'SOCIETE_EXPERT_'},
          {label: 'role.name[]', value: 'ROLE_EXPERT'},
          {label: 'role.name[]', value: 'ROLE_EXPERT_ADMIN'},
          {label: 'expand[]', value: 'user_group:read_group'},
          {label: 'expand[]', value: 'group:read'},
        ],
        setter: setUserSocietes
      } as IRequestParams).then();
    }
  },[]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
      if (!dossier?.id || !type) return;
    setLoading(true);
     apim.fetchEntities({
        resourceType: 'missionTypes',
        params: [{ label: 'code', value: toUpper(type) }],
        setErrored,
        setNotFound,
        success: (resType: AxiosResponse) => {
          if (resType?.data && resType?.data['hydra:member'] && resType?.data['hydra:member'].length > 0) {
            setMissionType(resType?.data?.['hydra:member']?.[0]);
            apim.fetchEntities({
              resourceType: 'missions',
              params: [
                { label: 'dossier', value: dossier?.id },
                { label: 'missionType', value: resType?.data?.['hydra:member']?.[0]?.id },
                { label: 'expand[]', value: 'mission_type:read' },
                { label: 'expand[]', value: 'mission:read_hypothesis' },
                { label: 'expand[]', value: 'mission_hypothesis:read' },
              ],
              cache: false,
              setErrored,
              setNotFound,
              setLoading,
              setter: setMissions,
              success: (res: AxiosResponse) => {
                if (!!res?.data?.['hydra:member']?.length && appState?.mission() !== null && appState?.mission()?.missionType !== iri('missionTypes',resType?.data['hydra:member'][0]?.id)) {
                  appState.setMission(res?.data?.['hydra:member'][0]?.id)
                }
              }
            } as IRequestParams).then();
          }
        }
      } as IRequestParams).then();
  }, [dossier?.id, type]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (selectedMission?.['hypothesis']) {
      setHypothesis(selectedMission?.['hypothesis']);
      setSelectedHypothesis(selectedMission?.hypothesis[0]);
      // Map current hypothesis from URL.
      forEach(selectedMission?.['hypothesis'], (_h: any) => {
        if (_h?.id === selectedHypothesis?.id) {
          setSelectedHypothesis(_h);
        }
      });
    }  }, [selectedMission?.id, appState.mission()?.id]); // eslint-disable-line react-hooks/exhaustive-deps

  // // Rewrite URL.
  // useEffect(() => {
  //   if (isValidUUID(mission?.id) && isValidUUID(hypothese?.id)) {
  //     window.history.replaceState(null, '', urls?.form + '/' + mission?.id + '/hypothese/' + hypothese?.id);
  //   }
  // }, [/*mission?.id, hypothese?.id, */appState.mission()?.id]); // eslint-disable-line react-hooks/exhaustive-deps
  // When creating a mission creating a linked hypothesis then creating a linked mission category
  // i.e. linking to entity missionRetraite/missionStatusClient/missionRemuneration
  const onSubmitCallback = (_mission: any, created: boolean) => {
    if (!isValidUUID(_mission?.id)) return;
    setLoading(true);

    if (created) {
      apim.postEntity({
        resourceType: 'missionHypothesis',
        notif: false,
        data: {
          mission: iri('missions', _mission.id),
          label: ucfirst(trans(t, 'firstHypothesis'))
        },
        success: (resHypothesis: AxiosResponse) => {
          appState.addMission(_mission);

          if (isValidUUID(resHypothesis?.data?.id)) {
            apim.postEntity({
              resourceType: 'mission_' + missionType?.code?.toLowerCase(),
              notif: false,
              data: { missionHypothesis: iri('missionHypothesis', resHypothesis?.data?.id) },
              success: (resCat: AxiosResponse) => {
                if (resCat?.data) {
                  const _missionYear = new Date(_mission?.date).getFullYear()
                  setMissionCategory(resCat?.data);
                  setLoading(false);
                  navigate(`${urls?.form}/${_mission?.id}/hypothese/${resHypothesis?.data?.id}${(appUri.dos.edit.missions.steps as Record<string, any>)[missionType?.code.toLowerCase()].step1}/${_missionYear}`);
                }
              }
            } as IRequestParams).then();
          }
        }
      } as IRequestParams).then();

    } else {
      apim.fetchEntities({
        resourceType: 'mission_' + missionType?.code?.toLowerCase(),
        params: [{ label: 'missionHypothesis', value: selectedHypothesis?.id }],
        success: (resCat: AxiosResponse) => {
          const missionYear = new Date(selectedMission?.date).getFullYear()
          if (resCat?.data && resCat?.data['hydra:member'] && resCat?.data['hydra:member'].length > 0) {
            setMissionCategory(resCat?.data['hydra:member'][0]);
            setLoading(false);
            navigate(`${urls?.form}/${selectedMission?.id}/hypothese/${selectedHypothesis?.id}${(appUri.dos.edit.missions.steps as Record<string, any>)[missionType?.code.toLowerCase()].step1}/${missionYear}`);
          } else {
            apim.postEntity({
              resourceType: 'mission_' + missionType?.code?.toLowerCase(),
              notif: false,
              data: { missionHypothesis: iri('missionHypothesis', selectedHypothesis?.id) },
              success: (resCat: AxiosResponse) => {
                if (resCat?.data) {
                  setMissionCategory(resCat?.data);
                  setLoading(false);
                  navigate(`${urls?.form}/${selectedMission?.id}/hypothese/${selectedHypothesis?.id}${(appUri.dos.edit.missions.steps as Record<string, any>)[missionType?.code.toLowerCase()].step1}/${missionYear}`);
                }
              }
            } as IRequestParams).then();
          }
        }
      } as IRequestParams).then();
    }
  };

  const renderMissionForm = useCallback(() => {

    return (
      <FormWrapper formKeyPrefix={'mission'} resourceType={'missions'}
                   data={missionId === 'new' ? {} : appState.mission()? appState.mission() : selectedMission} subClasses={'w-12 py-0'}
                   callback={onSubmitCallback} hideReload cancelLink context={userSocietes}
                   multiple redirectUri={cancelUri} additionalData={{
        author: userId(),
        missionType: iri('missionTypes', missionType?.id),
        dossier: iri('dossiers', dossier?.id),
      }}
      />
    );
  }, [selectedMission?.id, selectedHypothesis?.id, missionId, dossier?.id, appState.mission()?.id, userSocietes, missionType?.id]); // eslint-disable-line react-hooks/exhaustive-deps

  const renderHypothesisDropdown = useCallback(() => {
    if (missionId !== 'new' && isValidUUID(selectedHypothesis?.id)) {
      return (
        <>
          <Dropdown id={'a8-mission-select'} value={selectedHypothesis?.id} options={hypothesis} optionLabel={'label'} optionValue={'id'}
                    className={'border-round w-18rem'}
                    onChange={(e) => {
                      setSelectedHypothesis(hypothesis?.find(item => item.id === e.value));
                      navigate(urls?.form + '/' + selectedMission?.id + '/hypothese/' + e.value);
                    }}>
          </Dropdown>
          <label htmlFor={'a8-contract-select'}
                 className={'text-ucfirst'}>{trans(t, 'form|hypothesis.select')}</label>
        </>
      );
    }
  }, [hypothesis, missionId, selectedHypothesis?.id]); // eslint-disable-line react-hooks/exhaustive-deps

  if (loading) return <PageLoader/>;
  if (notFound) return <NotFound asBlock/>;
  if (errored) return <Error asBlock/>;

  const updatedBodyTemplate = (rowData: any) => dateTimeCell(rowData?.updated);
  const createdBodyTemplate = (rowData: any) => dateTimeCell(rowData?.created);
  const validatedBodyTemplate = (rowData: any) => dateTimeCell(rowData?.validated);
  const validBodyTemplate = (rowData: any) => validCell(rowData?.valid);

  const onRowEditCallback = () => {
    apim.fetchEntities({
      resourceType: 'missions',
      params: [
        { label: 'expand[]', value: 'mission_type:read' },
        { label: 'expand[]', value: 'mission:read_hypothesis' },
        { label: 'expand[]', value: 'mission_hypothesis:read' },
      ],
      id: missionId,
      cache: false,
      success: (res: AxiosResponse) => {
        if (res?.data['hypothesis'] && res.data['hypothesis'].length > 0) {
          setHypothesis(res?.data['hypothesis']);
          if (!selectedHypothesis || !selectedHypothesis.id) {
            setSelectedHypothesis(res.data.hypothesis[0]);
          }
        }
      },
      setLoading,
      setErrored,
      setNotFound
    } as IRequestParams).then();
  }
  const onDelete = (row: any) => {
    if (row?.id) {
      apim.deleteEntity({
        resourceType: 'missionHypothesis',
        id: row?.id,
        success: () => {
          apim.fetchEntities({
            resourceType: 'missionHypothesis',
            params: [
              { label: 'mission', value: iri('missions', appState.mission()?.id) },
            ],
            cache: false,
            success: (res: AxiosResponse) => {
              if (res?.data) {
                setHypothesis(res?.data['hydra:member']);
              }
            },
          } as IRequestParams).then()
        },
      } as IRequestParams).then();
    }
  }

  return (
    <>
      <div className='p-3 flex flex-column'>
        <div className='flex flex-row'>
          <div>
            <div className={'p-float-label'}>
              <Dropdown id={'a8-mission-select'} value={missionId === 'new' ? missionId : isValidUUID(appState.mission()?.id) && appState?.mission()?.missionType === iri('missionTypes',missionType?.id)
                ? appState.mission()?.id : missions.length === 0 ? 'new' : missionId}
                        options={[...[{ id: 'new', label: trans(t, 'form|mission.new') }], ...missions]}
                        optionLabel={'label'} optionValue={'id'}
                        className={'border-round w-18rem'}
                        onChange={(e) => {
                          if (e.value !== 'new') {
                            setSelectedMission(missions.find(item => item.id === e.value));
                            appState.setMission(e.value);
                          } else {
                            appState.setMission(null);
                            setSelectedMission(null);
                          }
                          navigate(urls?.form + '/' + e.value);
                        }}
                        placeholder={ucfirst(trans(t, 'form|mission.select'))}/>
              <label htmlFor={'a8-contract-select'} className={'text-ucfirst'}>{trans(t, 'form|mission.select')}</label>
            </div>
          </div>
          <div className={'ml-5 w-8'}>
            {renderMissionForm()}
          </div>
        </div>
        <div className={'p-float-label'}>
          {renderHypothesisDropdown()}
        </div>
      </div>

      {(missionId !== 'new' && hypothesis && hypothesis?.length > 0) && (
        <DatatableWrapper rows={hypothesis} resourceType={'missionHypothesis'} title={trans(t, 'menu|pages.title.hypothesis')}
                          editMode onDelete={onDelete} onRowEditCallback={onRowEditCallback} noFilters editFields={['label', 'valid']}
                          additionalData={missionId !== 'new' ? { mission: iri('missions', missionId) } : null} loading={loading} noGlobalFilter>
          <Column field={'label'} header={trans(t, 'name')} sortable style={{ width: '200px' }}
                  editor={(options) => textEditor(options, { label: trans(t, 'name') })}/>
          <Column field={'valid'} header={trans(t, 'validated')} dataType={'boolean'} align={'center'}
                  body={validBodyTemplate} editor={(options) => simpleCheckboxEditor(options, { label: null })}
                  style={{ width: '100px' }}/>
          <Column field={'validated'} header={trans(t, 'validated_date')} sortable align={'center'}
                  body={validatedBodyTemplate} style={{ width: '225px' }}/>
          <Column field={'updated'} header={trans(t, 'updated')} sortable align={'center'} body={updatedBodyTemplate}
                  style={{ width: '225px' }}/>
          <Column field={'created'} header={trans(t, 'created')} sortable align={'center'} body={createdBodyTemplate}
                  style={{ width: '225px' }}/>
        </DatatableWrapper>
      )}

      <div className={'text-right m-2'}>
        <Button type={'button'} className={'text-ucfirst p-button-text p-button-danger mr-5'} label={trans(t, 'close')}
                onClick={() => navigate(cancelUri)}/>
        {((hypothesis && hypothesis?.length > 0) || missionId === 'new') && (
          <Button type={'button'} className={'text-ucfirst p-button-text'} label={trans(t, 'next')} loading={formState.isLoading('mission')}
                  icon={'pi pi-arrow-right'} iconPos={'right'} onClick={() => {
            triggerFormSubmit(null);
          }}/>
        )}
      </div>
    </>
  )
}
