import React, { useEffect } from 'react';
import { classNames } from 'primereact/utils';
import { LicencedOrgChart as OrgChart } from 'components';

import { removeLocalStorageItem, trans } from 'utilities';
import {
  buildA8ChildTemplate,
  buildA8CompanyTemplate,
  buildA8Company0Template,
  buildA8Company2Template,
  buildA8Company3Template,
  buildA8Company4Template,
  buildA8Company5Template,
  buildA8Company5pTemplate,
  buildA8CompanyGroupTemplate, buildA8GroupTemplate,
  buildA8LinkTemplate, buildA8MainTemplate, buildA8PartnerTemplate
} from './templates';

import { forEach, uniqueId } from 'lodash';

// See https://balkan.app/OrgChartJS/Docs/GettingStarted
export const BalkanOrgChart = (props: any) => {
  const { data, inlineStyle, additionnalClassNames, t, onDetailsClick } = props;
  const chartId = uniqueId('a8-org-chart-');

  useEffect(() => {
    if (!data || data.nodes?.length < 1) return;

    const elem = document.getElementById(chartId);
    if (!elem) return;

    // Custom templates /!\ ORDER MATTERS !
    OrgChart.templates.a8_child = buildA8ChildTemplate(t);
    OrgChart.templates.a8_company_0 = buildA8Company0Template(t);
    OrgChart.templates.a8_company_group = buildA8CompanyGroupTemplate(t);
    OrgChart.templates.a8_company = buildA8CompanyTemplate(t);
    OrgChart.templates.a8_company_2 = buildA8Company2Template(t);
    OrgChart.templates.a8_company_3 = buildA8Company3Template(t);
    OrgChart.templates.a8_company_4 = buildA8Company4Template(t);
    OrgChart.templates.a8_company_5 = buildA8Company5Template(t);
    OrgChart.templates.a8_company_5p = buildA8Company5pTemplate(t);
    OrgChart.templates.a8_main = buildA8MainTemplate(t);
    OrgChart.templates.a8_partner = buildA8PartnerTemplate(t);
    OrgChart.templates.a8_link = buildA8LinkTemplate(t);

    // Build / override group templates.
    buildA8GroupTemplate();

    const chart = new OrgChart(elem, {
      tags: {
        'group': {template: 'group'},
        'moreThanFifty-group': data.config?.moreThanFifty ?? {subTreeConfig: {columns: 2, layout: OrgChart.layout.treeLeftOffset}},
        'lessThanFifty-group': data.config?.lessThanFifty ?? {subTreeConfig: {columns: 2, layout: OrgChart.layout.treeRightOffset}},
        'children-group': data.config?.children ?? {min: true, subTreeConfig: {columns: 3}},
        'family-group': data.config?.family ?? {min: true},
        'ig': data.config?.ig || {template: 'invisibleGroup', subTreeConfig: {orientation: OrgChart.orientation.bottom}},
        'transparent': {template: 'transparent'},
        'a8_main': data.config?.main || {template: 'a8_main', subTreeConfig: {/*layout: layout.treeLeftOffset, */orientation: OrgChart.orientation.top_left}},
        'a8_partner': data.config?.partner || {template: 'a8_partner'},
        'a8_link': data.config?.link || {template: 'a8_link'},
        'a8_child': data.config?.child || {template: 'a8_child'},
        'a8_company': data.config?.company || {template: 'a8_company', min: true},
        'a8_company_group':  {template: 'a8_company_group'},
        'a8_company_0':  {template: 'a8_company_0'},
        'a8_company_2': {template: 'a8_company_2', min: true},
        'a8_company_3': {template: 'a8_company_3', min: true},
        'a8_company_4': {template: 'a8_company_4', min: true},
        'a8_company_5': {template: 'a8_company_5', min: true},
        'a8_company_5p': {template: 'a8_company_5p', min: true},
      },

      nodeBinding: {
        imgs: 'img',
        description: 'description',
        field_0: 'name',
        field_1: 'title',
        img_0: 'img',
        img_group: 'img_group',
        expandLink: 'expandLink',

        raisonSociale: 'raisonSociale',
        capital: 'capital',
        type: 'type',
        dateCloture: 'dateCloture',

        beneficiaryName: 'beneficiaryName',
        beneficiaryRole: 'beneficiaryRole',
        beneficiaryRatio: 'beneficiaryRatio',
        beneficiaryImg: 'beneficiaryImg',
        beneficiary2Name: 'beneficiary2Name',
        beneficiary2Role: 'beneficiary2Role',
        beneficiary2Ratio: 'beneficiary2Ratio',
        beneficiary2Img: 'beneficiary2Img',
        beneficiary3Name: 'beneficiary3Name',
        beneficiary3Role: 'beneficiary3Role',
        beneficiary3Ratio: 'beneficiary3Ratio',
        beneficiary3Img: 'beneficiary3Img',
        beneficiary4Name: 'beneficiary4Name',
        beneficiary4Role: 'beneficiary4Role',
        beneficiary4Ratio: 'beneficiary4Ratio',
        beneficiary4Img: 'beneficiary4Img',
        beneficiary5Name: 'beneficiary5Name',
        beneficiary5Role: 'beneficiary5Role',
        beneficiary5Ratio: 'beneficiary5Ratio',
        beneficiary5Img: 'beneficiary5Img',
        moreBeneficiariesLink: 'moreBeneficiariesLink'
      },

      nodeMenu: {
        details: { text: trans(t, 'seeDetails'), onClick: onDetailsPageClick },
        // edit: { text: trans(t, 'edit') },
        // add: { text: trans(t, 'add') },
        // remove: { text: trans(t, 'delete') },
      },

      // mouseScrool: OrgChart.none,
      mouseScrool: OrgChart.action.yScroll,
      // showYScroll: OrgChart.scroll.visible,
      // showXScroll: OrgChart.scroll.visible,

      // miniMap: true,
      // layout: OrgChart.mixed,

      enableSearch: false,
      // nodeTreeMenu: true,

      linkBinding: {
        link_field_0: 'linkLabel'
      },
      slinks: data.links || []
    } as OrgChart.options);

    function onDetailsPageClick(nodeId: string) {
      const node: any = chart.get(nodeId);
      if (node && onDetailsClick) onDetailsClick(node);
    }

    if (chart) {
      chart.on('click', (sender, args) =>  {
        // Expand groups or expandable nodes.
        if (args.node.tags.indexOf('group') !== -1 ||
          ['a8_company_5p', 'a8_company_5', 'a8_company_4', 'a8_company_3', 'a8_company_2', 'a8_company'].includes(args.node.templateName))
        {
          if (args.node.min) sender.maximize(args.node.id);
          else sender.minimize(args.node.id);
        }

        return false;
      });

      chart.on('field', (sender, args) => {
        if (args.node.min) {
          if (args.name === 'img') {
            const node: any = sender.get(args.node.id);

            if (node?.img_group) {
              args.value += '<image xlink:href="' + node.img_group + '" x="105" y="45" width="42" height="42" ></image>';
            } else {
              args.value += '<text style="font-size: 28px;" fill="#070AB0" x="130" y="70" text-anchor="middle">' + args.node.stChildrenIds.length + '</text>';
            }
          }
        }
      });

      chart.load(data.nodes || []);
    }

    // Let's clean this dirty module mess...!
    return () => {
      forEach(Object.keys(localStorage), (key: string) => {
        if (key.startsWith('a8')) return;

        removeLocalStorageItem(key, false);
      });
    }
  }, [data]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div className={classNames('w-full a8-org-chart', additionnalClassNames)} style={inlineStyle}>
      <div id={chartId}/>
    </div>
  );
};
