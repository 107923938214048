import React, { useEffect, useState } from 'react';
import { AxiosResponse } from 'axios';
import { Link } from 'react-router-dom';

import { Button } from 'primereact/button';
import { InputSwitch} from 'primereact/inputswitch';
import { Panel } from 'primereact/panel';
import { Toolbar} from 'primereact/toolbar';
import { Tooltip } from 'primereact/tooltip';
import { classNames } from 'primereact/utils';

import { dateAgoString, trans, ucfirst, uuidFromIri } from 'utilities';
import { useAppState, useNotificationState } from 'states';
import { IRequestParams, isAdmin, isClient, isExpert, isExpertAdmin, useApim, userId }  from 'services';
import { PageLoader, Userbadge } from 'components';
import { INotification, INotificationsGroup } from 'interfaces';
import { UncontrolledStaticListField } from 'forms';

import moment from 'moment';
import appUri from 'config/appUri.json';

export const NotificationsList = () => {
  const apim = useApim();
  const appState = useAppState();
  const notificationState = useNotificationState();

  const { t } = apim.di();

  const itemsPerPage = 5;
  const [lastRefresh, setLastRefresh] = useState<number>(Date.now());
  const [loading, setLoading] = useState<boolean>(false);
  const [groupTotal, setGroupTotal] = useState<number>(5);
  const [notificationTotal, setNotificationTotal] = useState<number>(itemsPerPage);

  const [notificationsGroups, setNotificationsGroups] = useState<INotificationsGroup[] | null>(null);
  const [page, setPage] = useState<number>(1);

  // Filter by read/done statuses.
  const [showOnlyUnread, setShowOnlyUnread] = useState<boolean>(false);
  const [showOnlyUndone, setShowOnlyUndone] = useState<boolean>(true);

  // Filter by dossier.
  const [fDossier, setFDossier] = useState<string>('all');
  const [fDossierOptionsLoading, setFDossierOptionsLoading] = useState<boolean>(false);
  const [fDossierOptions, setFDossierOptions] = useState<any[]>([{label: ucfirst(trans(t, 'allDossiers')), id: 'all', dossier: null}]);
  const [dossiers, setDossiers] = useState<any[]>([]);

  // Filter by period.
  const [fPeriod, setFPeriod] = useState<string>('all');
  const fPeriodOptions = [
    {code: 'all', label: trans(t, 'period_all')},
    {code: 'last_12_months', label: trans(t, 'period_last_12_months')},
    {code: 'last_6_months', label: trans(t, 'period_last_6_months')},
    {code: 'last_30_days', label: trans(t, 'period_last_30_days')},
    {code: 'last_trimester', label: trans(t, 'period_last_trimester')},
    {code: 'last_month', label: trans(t, 'period_last_month')},
    {code: 'last_week', label: trans(t, 'period_last_week')},
    {code: 'last_year', label: trans(t, 'period_last_year')},
    {code: 'this_year', label: trans(t, 'period_this_year')},
    {code: 'this_month', label: trans(t, 'period_this_month')},
    {code: 'this_trimester', label: trans(t, 'period_this_trimester')}
  ];

  // Update Page Header.
  useEffect(() => {
    appState.setPageTitle(trans(t, 'menu|pages.title.notifications'));
    appState.setBreadcrumb([{label: trans(t, 'notification', 2)}]);
    appState.setPageActions([]);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  // Load dossiers filter.
  useEffect(() => {
    setFDossierOptionsLoading(true);

    apim.fetchEntities({
      resourceType: 'notificationDossiers',
      notif: false,
      success: (res: AxiosResponse) => {
        const _dossierOptions: any[] = [{label: ucfirst(trans(t, 'allDossiers')), id: 'all'}];
        const _dossiers: any[] = [];

        res?.data?.['hydra:member']?.map((dossier: any) => {
          _dossierOptions.push({
            label: dossier.identifier,
            id: dossier.id,
          });

          _dossiers.push(dossier);

          return null;
        });

        setFDossierOptions(_dossierOptions);
        setDossiers(_dossiers);
        setFDossierOptionsLoading(false);
      }
    } as IRequestParams).then();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  // Load initial notifications.
  useEffect(() => {
    if (!fDossierOptionsLoading) {
      loadMore(page, true);
    }
  }, [fDossierOptionsLoading, showOnlyUnread, showOnlyUndone, fPeriod, fDossier, lastRefresh]); // eslint-disable-line react-hooks/exhaustive-deps

  const switchReadStatus = (notification: INotification) => {
    if (!notificationsGroups) return;

    apim.patchEntity({
      resourceType: 'notifications',
      id: notification.id,
      data: {
        read: !notification.read
      },
      notif: false,
      success: (res: AxiosResponse) => {
        // Update list.
        const newNotification = res?.data;

        const patchedGroup = notificationsGroups?.find(
          (group: INotificationsGroup) => group.id === uuidFromIri(res.data?.notificationContext?.['@id'])
        );

        if (patchedGroup) {
          const updatedNotifications = patchedGroup.notifications?.map((notification: INotification) =>
            notification.id === newNotification.id ? { ...notification, read: newNotification.read } : notification
          );

          const updatedGroups = notificationsGroups?.map((group: INotificationsGroup) =>
            group.id === patchedGroup.id ? { ...group, notifications: updatedNotifications } : group
          );

          setNotificationsGroups(updatedGroups);

          // Update state.
          if (notification.read === false) {
            notificationState.decCountUnread();
          } else {
            notificationState.incCountUnread();
          }

          if (notification.read === showOnlyUnread){
            setNotificationTotal(notificationTotal + 1);
          } else {
            setNotificationTotal(notificationTotal - 1);
          }
        }

      }
    } as IRequestParams).then();
  }

  const switchDoneStatus = (notification: INotification) => {
    if (!notificationsGroups) return;

    apim.patchEntity({
      resourceType: 'notifications',
      id: notification.id,
      data: { done: !notification.done },
      notif: false,
      success: (res: AxiosResponse) => {
        // Update list.
        const newNotification = res.data;

        const patchedGroup = notificationsGroups.find(
          (group: INotificationsGroup) => group.id === uuidFromIri(res.data.notificationContext?.['@id'])
        );

        if (patchedGroup) {
          const updatedNotifications = patchedGroup.notifications?.map((notification: INotification) =>
            notification.id === newNotification.id ? { ...notification, done: newNotification.done } : notification
          );

          const updatedGroups = notificationsGroups.map((group: INotificationsGroup) =>
            group.id === patchedGroup.id ? { ...group, notifications: updatedNotifications } : group
          );

          setNotificationsGroups(updatedGroups);

          // Update state.
          if (notification.done === false) {
            notificationState.decCount();
          } else {
            notificationState.incCount();
          }

          if (notification.done === showOnlyUndone){
            setNotificationTotal(notificationTotal + 1);
          } else {
            setNotificationTotal(notificationTotal - 1);
          }
        }
      }
    } as IRequestParams).then();
  };

  const deleteNotification = (notification: INotification) => {
    if (!notificationsGroups) return;

    apim.deleteEntity({
      resourceType: 'notifications',
      id: notification.id,
      notif: true,
      success: () => {
        // Remove the notification from the list.
        const updatedGroups = notificationsGroups.reduce((acc, group) => {
          const updatedNotifications = group.notifications?.filter(n => n.id !== notification.id);
          if (updatedNotifications?.length > 0) {
            acc.push({ ...group, notifications: updatedNotifications });
          }
          return acc;
        }, [] as INotificationsGroup[]);

        setNotificationsGroups(updatedGroups);

        // Update state.
        if (notification.done === false) {
          notificationState.decCount();
        }
        if (notification.read === false) {
          notificationState.decCountUnread();
        }
        setNotificationTotal(notificationTotal - 1);
      }
    } as IRequestParams).then();
  }

  const markAllAsRead = () => {
    apim.call({
      resourceType: 'notifications',
      action: 'markAllAsRead',
      notif: true,
      data: { user: userId(), read: true },
      success: () => {
        setLastRefresh(Date.now());
        notificationState.setCountUnread(0);
      }
    } as IRequestParams).then();
  }

  const markAllAsDone = () => {
    apim.call({
      resourceType: 'notifications',
      action: 'markAllAsDone',
      notif: true,
      data: { user: userId(), done: true },
      success: () => {
        setLastRefresh(Date.now());
        notificationState.setCount(0);
      }
    } as IRequestParams).then();
  }

  const markGroupAsRead = (notificationsGroup: INotificationsGroup) => {
    if (!notificationsGroups) return;

    apim.patchEntity({
      resourceType: 'notificationContexts',
      id: notificationsGroup.id,
      notif: true,
      data: { read: true },
      success: () => {
        setLastRefresh(Date.now());
        const unreadCount = notificationsGroup.notifications?.filter((notification: INotification) => notification.read === false).length || 0;

        // Update list.
        const updatedGroupNotifications = notificationsGroup.notifications?.map(
          (notification: INotification) => ({ ...notification, read: true })
        );
        const updatedGroups = notificationsGroups.map((group: INotificationsGroup) =>
          group.id === notificationsGroup.id ? { ...group, notifications: updatedGroupNotifications, read: true } : group
        );
        setNotificationsGroups(updatedGroups);

        // Update state.
        notificationState.setCountUnread(notificationState.getCountUnread() - unreadCount);

        if (showOnlyUnread) {
          setNotificationTotal(notificationTotal - unreadCount);
        }
      }
    } as IRequestParams).then();
  }

  const markGroupAsDone = (notificationsGroup: INotificationsGroup) => {
    if (!notificationsGroups) return;

    apim.patchEntity({
      resourceType: 'notificationContexts',
      id: notificationsGroup.id,
      notif: true,
      data: { done: true },
      success: () => {
        setLastRefresh(Date.now());
        const undoneCount = notificationsGroup.notifications?.filter((notification: INotification) => notification.done === false).length || 0;

        // Update list.
        const updatedGroupNotifications = notificationsGroup.notifications?.map(
          (notification: INotification) => ({ ...notification, done: true })
        );
        const updatedGroups = notificationsGroups.map((group: INotificationsGroup) =>
          group.id === notificationsGroup.id ? { ...group, notifications: updatedGroupNotifications, done: true } : group
        );
        setNotificationsGroups(updatedGroups);

        // Update state.
        notificationState.setCount(notificationState.getCount() - undoneCount);

        if (showOnlyUndone) {
          setNotificationTotal(notificationTotal - undoneCount);
        }
      }
    } as IRequestParams).then();
  }

  const deleteGroup = (notificationsGroup: INotificationsGroup) => {
    if (!notificationsGroups) return;

    apim.deleteEntity({
      resourceType: 'notificationContexts',
      id: notificationsGroup.id,
      notif: true,
      success: () => {
        const undoneCount = notificationsGroup.notifications?.filter((notification: INotification) => notification.done === false).length || 0;
        const unreadCount = notificationsGroup.notifications?.filter((notification: INotification) => notification.read === false).length || 0;

        // Remove the notification from the list.
        const updatedGroups = notificationsGroups.filter(group => group.id !== notificationsGroup.id);

        setNotificationsGroups(updatedGroups);

        // Update state.
        notificationState.setCount(notificationState.getCount() - undoneCount);
        notificationState.setCountUnread(notificationState.getCount() - unreadCount);

        setNotificationTotal(notificationTotal - notificationsGroup?.notifications?.length);
      }
    } as IRequestParams).then();
  }

  const loadMore = (pageNumber: number, initialCall: boolean) => {
    if ((notificationsGroups && notificationsGroups?.length < groupTotal) || initialCall) {
      setLoading(true);

      const params = [
        {label: 'user', value: userId()},
        {label: 'order[created]', value: 'DESC'},
        {label: 'active', value: 'true'},
        {label: 'itemsPerPage', value: itemsPerPage},
        {label: 'page', value: pageNumber},
        {label: 'expand[]', value: 'notification_context:read_notifications'},
        {label: 'expand[]', value: 'notification:read'}
      ];

      if (showOnlyUnread) {
        params.push({label: 'read', value: 'false'});
      }

      if (showOnlyUndone) {
        params.push({label: 'done', value: 'false'});
      }

      if (fDossier !== 'all') {
        params.push({label: 'dossier', value: fDossier});
      }

      if (fPeriod !== 'all') {
        const now = moment();

        switch (fPeriod) {
          case 'last_12_months':
            params.push({label: 'created[before]', value: now.toISOString()});
            params.push({label: 'created[after]', value: now.subtract(12, 'M').toISOString()});
            break;

          case 'last_6_months':
            params.push({label: 'created[before]', value: now.toISOString()});
            params.push({label: 'created[after]', value: now.subtract(6, 'M').toISOString()});
            break;

          case 'last_30_days':
            params.push({label: 'created[before]', value: now.toISOString()});
            params.push({label: 'created[after]', value: now.subtract(30, 'd').toISOString()});
            break;

          case 'last_trimester':
            params.push({label: 'created[before]', value: now.toISOString()});
            params.push({label: 'created[after]', value: now.subtract(3, 'M').toISOString()});
            break;

          case 'last_month':
            params.push({label: 'created[before]', value: now.toISOString()});
            params.push({label: 'created[after]', value: now.subtract(1, 'M').toISOString()});
            break;

          case 'last_week':
            params.push({label: 'created[before]', value: now.toISOString()});
            params.push({label: 'created[after]', value: now.subtract(1, 'w').toISOString()});
            break;

          case 'last_year':
            params.push({label: 'created[before]', value: now.toISOString()});
            params.push({label: 'created[after]', value: now.subtract(1, 'y').toISOString()});
            break;

          case 'this_year':
            params.push({label: 'created[before]', value: now.toISOString()});
            params.push({label: 'created[after]', value: moment().startOf('year').toISOString()});
            break;

          case 'this_month':
            params.push({label: 'created[before]', value: now.toISOString()});
            params.push({label: 'created[after]', value: moment().startOf('month').toISOString()});
            break;

          case 'this_trimester':
            params.push({label: 'created[before]', value: now.toISOString()});
            params.push({label: 'created[after]', value: moment().startOf('quarter').toISOString()});
            break;
        }
      }

      apim.fetchEntities({
        cache: false,
        notif: false,
        params,
        resourceType: 'notificationContexts',
        success: (res: AxiosResponse) => {
          if (!res?.data || !res.data?.['hydra:member']) return;

          setGroupTotal(res.data?.['hydra:totalItems']);

          const filterNotificationsGroups = (filter: (notification: INotification) => boolean) => {
            if (initialCall || notificationsGroups) {
              const n = initialCall ? (res.data['hydra:member'] ?? []) : notificationsGroups ? [...notificationsGroups, ...(res.data['hydra:member'] ?? [])] : [];
              return n.reduce((acc: INotificationsGroup[], group: INotificationsGroup) => {
                const filteredNotifications = group.notifications?.filter(filter);
                if (filteredNotifications.length > 0) {
                  acc.push({ ...group, notifications: filteredNotifications });
                }
                return acc;
              }, []);
            }
            return  []

          };

          if (showOnlyUndone && showOnlyUnread) {
            setNotificationsGroups(filterNotificationsGroups((notification: INotification) => notification.done === false && notification.read === false));
          } else if (showOnlyUnread) {
            setNotificationsGroups(filterNotificationsGroups((notification: INotification) => notification.read === false));
          } else if (showOnlyUndone) {
            setNotificationsGroups(filterNotificationsGroups((notification: INotification) =>  notification.done === false));
          } else {
            setNotificationsGroups(initialCall ? (res.data['hydra:member'] ?? []) : notificationsGroups ? [...notificationsGroups, ...(res.data['hydra:member'] ?? [])] : []);
          }

          apim.fetchEntities({
            resourceType: 'notifications',
            params,
            notif: false,
            cache: false,
            success: (res: AxiosResponse) => {
              if (!res?.data) return;
              setNotificationTotal(res.data?.['hydra:totalItems']);
              setLoading(false);
            }
          } as IRequestParams).then();
        }
      } as IRequestParams).then();
    }
  }

  const [hoveredNotification, setHoveredNotification] = useState<string | null>(null);
  const [hoveredGroup, setHoveredGroup] = useState<string | null>(null);

  const itemTemplate = (notificationsGroup: INotificationsGroup) => {
    const expandDossier = (dossier: string | null): any => {
      let notificationDossier: any = null;

      dossiers.forEach((d: any) => {
        if (uuidFromIri(d['@id']) === uuidFromIri(dossier)) {
          notificationDossier = d;
        }
      });

      return notificationDossier;
    };

    const notificationHeaderTemplate = (options: any, notification: INotification) => {
      const className = `${options.className} justify-content-space-between h-3rem`;
      const single = options?.single;

      return (
        <div className={className}>
          <div className={'flex align-items-center gap-3'}>
            {single && notificationsGroup?.type === 'alert' && (
              <i className={'pi pi-bell text-red-500 text-2xl'} />
            )}
            {single && notificationsGroup?.type === 'info' && (
              <i className={'pi pi-info-circle text-green-500 text-2xl'} />
            )}
            {notification.title && (
              <span className={classNames('a8-notification-title mt-1 mb-1 font-bold')} style={notification.read ? {color: 'grey'} : {}}>{notification.title}</span>
            )}
          </div>
          <div className={'flex flex-wrap align-items-center justify-content-start gap-1'}>
            {hoveredNotification === `notification-${notification.id}` && (
              <>
                <Tooltip target={'.read'} position={'bottom'}/>
                <Button icon={notification.read ? 'pi pi-check' : 'pi pi-envelope'} className={'read'} rounded text onClick={() => switchReadStatus(notification)}
                        data-pr-tooltip={ucfirst(trans(t, notification.read === true ? 'markAsUnread' : 'markAsRead'))} style={notification.read ? {color: 'grey'} : {}}/>
                <Tooltip target={'.done'} position={'bottom'}/>
                <Button icon={notification.done ? 'pi pi-check-square' : 'pi pi-pen-to-square'} className={'done'} rounded text onClick={() => switchDoneStatus(notification)}
                        data-pr-tooltip={ucfirst(trans(t, notification.done ? 'markAsUndone' : 'markAsDone'))} style={notification.done ? {color: 'grey'} : {}}/>
                <Tooltip target={'.delete'} position={'bottom'}/>
                <Button icon={'pi pi-trash'} className={'delete'} rounded text onClick={() => deleteNotification(notification)} data-pr-tooltip={ucfirst(trans(t, 'delete'))} severity={'danger'}/>
              </>
            )}
            {options.togglerElement}
          </div>
        </div>
      );
    };

    const groupHeaderTemplate = (options: any, notificationsGroup: INotificationsGroup ) => {
      const className = `${options.className} justify-content-space-between h-3rem`;

      return (
        <div className={className}>
          <div className={'flex align-items-center gap-3'}>
            {notificationsGroup?.type === 'alert' && (
              <i className={'pi pi-bell mr-1 text-red-500 text-2xl'}/>
            )}
            {notificationsGroup?.type === 'info' && (
              <i className={'pi pi-info-circle text-green-500 text-2xl'}/>
            )}
            {notificationsGroup?.dossierUnique === false && !isClient() && (
              <Button size={'small'} label={ucfirst(trans(t, 'multipleDossiers'))}/>
            )}
            {notificationsGroup.title && (
              <span className={classNames('a8-notification-title text-lg mt-1 mb-1 text-primary font-bold')} style={notificationsGroup.read ? {color: 'grey'} : {}}>{notificationsGroup.title}</span>
            )}
          </div>
          <div className={'flex flex-wrap align-items-center justify-content-start gap-1'}>
            {hoveredGroup === `group-${notificationsGroup.id}` && (
              <>
                <Tooltip target={'.read'} position={'bottom'}/>
                <Button icon={notificationsGroup.read ? 'pi pi-check' : 'pi pi-envelope'} className={'read'} rounded text onClick={() => markGroupAsRead(notificationsGroup)}
                        data-pr-tooltip={ucfirst(trans(t, 'markAllAsRead'))} style={notificationsGroup.read ? {color: 'grey'} : {}}/>
                <Tooltip target={'.done'} position={'bottom'}/>
                <Button icon={notificationsGroup.done ? 'pi pi-check-square' : 'pi pi-pen-to-square'} className={'done'} rounded text onClick={() => markGroupAsDone(notificationsGroup)}
                        data-pr-tooltip={ucfirst(trans(t, 'markAllAsDone'))} style={notificationsGroup.done ? {color: 'grey'} : {}}/>
                <Tooltip target={'.delete'} position={'bottom'}/>
                <Button icon={'pi pi-trash'} className={'delete'} rounded text onClick={() => deleteGroup(notificationsGroup)} data-pr-tooltip={ucfirst(trans(t, 'deleteAll'))} severity={'danger'}/>
              </>
            )}
            {options.togglerElement}
          </div>
        </div>
      );
    };

    const footerTemplate = (options: any, notification: INotification | INotificationsGroup) => {
      const detailedDossier = expandDossier(notification?.dossier ?? null);

      return <div className={`${options.className} p-0 pt-3`}>
        <div className={`flex flex-wrap align-items-center justify-content-start gap-1 border-top-1 border-200 text-sm px-3 py-2`}>
          <span style={notification.read ? {color: 'grey'} : {}}>{ucfirst(trans(t, 'sent_by'))}</span>
          <Userbadge apim={apim} user={notification.author} size={'normal'} shape={'circle'} style={notification.read ? {color: 'grey'} : {color: 'black'}}/>
          <span style={notification.read ? {color: 'grey'} : {}}>{dateAgoString(notification.created)}</span>
          {detailedDossier !== undefined && detailedDossier !== null && (isExpert() || isAdmin()) && (
            <>
              <span style={notification.read ? {color: 'grey'} : {}}>{trans(t, 'on_dossier')}</span>
              <Link className={'font-bold'} to={appUri.dos.page.replace(':id', uuidFromIri(notificationsGroup?.dossier))}>
                {detailedDossier.name}
              </Link>
            </>
          )}
        </div>
      </div>;
    }

    const renderNotification = (notification: INotification, single: boolean) => (
      <Panel
        key={notification.id} className={`a8-notification-${notification.id}`}
        onMouseEnter={() => setHoveredNotification(`notification-${notification.id}`)}
        onMouseLeave={() => setHoveredNotification(null)}
        headerTemplate={(options) => notificationHeaderTemplate({ ...options, dossierUnique: notificationsGroup.dossierUnique, single }, notification)}
        footerTemplate={single ? (options) => footerTemplate(options, notification) : ''} toggleable collapsed={notification.done || notification.read || false }>
        {notification.content && (
          <div
            style={notification.read ? {color: 'grey'} : {}}
            className={'a8-notification-content text-sm pt-2 pl-1'}
            dangerouslySetInnerHTML={{ __html: notification.content }}
          />
        )}
      </Panel>
    );

    return (
      <>
        {(notificationsGroup?.notifications?.length > 1 || (notificationsGroup?.title !== null && notificationsGroup?.title?.length > 0)) ? (
          <Panel key={notificationsGroup.id} className={'a8-notification-' + notificationsGroup.id}
                 headerTemplate={(options) => groupHeaderTemplate(options, notificationsGroup)}
                 footerTemplate={(options) => footerTemplate(options, notificationsGroup)}
                 onMouseEnter={() => setHoveredGroup(`group-${notificationsGroup.id}`)}
                 onMouseLeave={() => setHoveredGroup(null)}
                 toggleable collapsed={notificationsGroup.done || notificationsGroup.read || false}>
            {notificationsGroup.content && (
              <div
                style={notificationsGroup.read ? {color: 'grey'} : {}}
                className={'a8-notification-content text-sm pt-2 pl-1'}
                dangerouslySetInnerHTML={{ __html: notificationsGroup.content }}
              />
            )}
            <div className={'flex flex-column gap-1'}>
              {notificationsGroup?.notifications?.map((notification: INotification)=> (
                renderNotification(notification, false)
              ))}
            </div>
          </Panel>
        ) : (
          <div className={'flex flex-column gap-1'}>
            {notificationsGroup?.notifications?.map((notification: INotification) => (
              renderNotification(notification, true)
            ))}
          </div>
        )}
      </>
    );
  };

  const startContent = (
    <div className={'flex flex-row gap-3 align-items-center'}>
      {(isExpert() || isExpertAdmin() || isAdmin()) && (
        <UncontrolledStaticListField fieldKey={'dossier'} onFieldChange={(value: any) => setFDossier(value)} label={trans(t, 'dossier')}
                                     placeholder={ucfirst(trans(t, 'form|select_dossier'))} value={fDossier} listsOptions={{dossiers: fDossierOptions}} listKey={'dossiers'} optionLabel={'label'} optionValue={'id'} />
      )}
      <UncontrolledStaticListField fieldKey={'period'} onFieldChange={(value: any) => setFPeriod(value || 'all')} label={trans(t, 'period')}
                                   placeholder={ucfirst(trans(t, 'form|select_period'))} value={fPeriod} listsOptions={{periods: fPeriodOptions}} listKey={'periods'} optionLabel={'label'} optionValue={'code'} />
    </div>
  );

  const endContent = (
    <div className={'flex flex-row gap-5 align-items-center'}>
      <div className={'flex flex-row'}>
        <Button label={ucfirst(trans(t, 'markAllAsRead'))} text rounded onClick={() => markAllAsRead()} />
        <Button label={ucfirst(trans(t, 'markAllAsDone'))} text rounded onClick={() => markAllAsDone()} />
      </div>
      <div className={'flex flex-row gap-3 align-items-end'}>
        <div className={'flex flex-row gap-3 align-items-center'}>
          <label htmlFor={'a8-notifications-toggle-unread'}>{ucfirst(trans(t, 'displayOnlyUnreadNotifications'))}</label>
          <InputSwitch checked={showOnlyUnread} inputId={'a8-notifications-toggle-unread'}
                       onChange={(e) => {
                         setPage(1);
                         setShowOnlyUnread(e.value);
                       }}/>

        </div>
        <div className={'flex flex-row gap-3 align-items-center'}>
          <label htmlFor={'a8-notifications-toggle-undone'}>{ucfirst(trans(t, 'displayOnlyUndoneNotifications'))}</label>
          <InputSwitch checked={showOnlyUndone} inputId={'a8-notifications-toggle-undone'}
                       onChange={(e) => {
                         setPage(1);
                         setShowOnlyUndone(e.value);
                       }}/>
        </div>
      </div>
    </div>
  );

  const loadNextPage = () => {
    setPage(prevPage => {
      const newPage = prevPage + 1;
      loadMore(newPage, false);
      return newPage;
    });
  };

  return <>
    <Toolbar start={startContent} end={endContent} className={'mb-3 border-round'} />
    {(notificationsGroups?.length === 0 && loading) || notificationsGroups === null || loading ? (
      <PageLoader />
    ) : (
      notificationsGroups && notificationsGroups?.length > 0 ? (
        <>
          <p className={'text-sm'}>{ucfirst(trans(t, 'youHaveNotification', notificationTotal))}</p>
          <div className={'flex flex-column gap-3'}>
            {notificationsGroups.sort((a: INotificationsGroup, b: INotificationsGroup) => (a?.created ?? '') > (b?.created ?? '') ? -1 : 1).map((notificationGroup: INotificationsGroup) =>(
              <div className={'a8-notification'} key={notificationGroup.id}>
                {itemTemplate(notificationGroup)}
              </div>))
            }
          </div>
          {notificationsGroups?.length < groupTotal && (
            loading ? (
              <PageLoader />
            ) : (
              <div className={'flex flex-row gap-3 justify-content-center p-5'}>
                <Button label={ucfirst(trans(t, 'seeMoreNotifications'))} onClick={loadNextPage} />
              </div>
            )
          )}
        </>
      ) : (
        <div className={'flex flex-row gap-3 justify-content-center p-8'}>
          <p className={'font-bold text-xl'}>{ucfirst(trans(t, 'emptyNotifications'))}</p>
        </div>
      )
    )}
  </>
};
