import React, { useEffect, useState } from 'react';

import { Dropdown } from 'primereact/dropdown';
import { Skeleton } from 'primereact/skeleton';
import { classNames } from 'primereact/utils';

import { trans } from 'utilities';
import { IRequestParams, useApim } from 'services';
import { UncontrolledBaseField } from './UncontrolledBaseField';

export const UncontrolledAPIListField = (props: any) => {
  const { listKey, fieldKey, value, onFieldChange, label, placeholder, tooltip, tooltipOptions, description, classes, style,
    addon, addonPosition, disabled, optionLabel, optionValue, showClear, data, className } = props;

  const apim = useApim();
  const { t } = apim.di();

  const [options, setOptions] = useState<any[]>(data || []);
  const [loading, setLoading] = useState<boolean>(true);
  useEffect(() => {
    if (data) return setLoading(false);

    apim.getList(listKey, {setter: setOptions, setLoading: setLoading} as IRequestParams).then();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  let defaultValue: string = '';
  if (value) {
    const testDF: any[] = (options || []).filter((i: any) => value.toLowerCase() === i[optionValue || 'value']);
    if (testDF.length === 1) {
      defaultValue = testDF[0][optionValue || 'value'];
    }
  }

  return (
    <UncontrolledBaseField classes={classes} addon={addon} addonPosition={addonPosition} description={description} >
      {loading ? <Skeleton height={'2.8rem'} /> :
        <span className={'p-float-label'}>
          <Dropdown
            id={fieldKey} className={classNames(className, 'w-12')} placeholder={placeholder || trans(t, 'form|select')} value={defaultValue || value}
            options={options} optionLabel={optionLabel || 'translated'} optionValue={optionValue || 'value'}
            onChange={(e) => onFieldChange(e.value)} showClear={showClear} disabled={disabled}
            tooltip={tooltip} tooltipOptions={tooltipOptions || { position: 'top' }} style={style}
          />
          {(label || fieldKey) && (
            <label className={'text-ucfirst p-float-label-label'} htmlFor={fieldKey}>
              <span>{label ?? trans(t, fieldKey)}</span>
            </label>
          )}
        </span>
      }
    </UncontrolledBaseField>
  );
};
