import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Button } from 'primereact/button';
import { Column } from 'primereact/column';
import { Tooltip } from 'primereact/tooltip';

import { isValidUUID, trans, ucfirst } from 'utilities';
import { amountCell, apiListCell, DatatableWrapper, dateTimeCell } from 'components';
import { IRequestParams, isAdmin, isExpert } from 'services';

import appUri from 'config/appUri.json';

export const PatrimoineContratsAssuranceVieDatatable = (props: any) => {
  const { apim, appState, context, title, editMode } = props;
  const { t, navigate } = apim.di();

  const [rows, setRows] = useState<any[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [types, setTypes] = useState<any[]>([]);
  // const [familles, setFamilles] = useState<any[]>([]);
  useEffect(() => {
    apim.getList('contractSupportTypes', {setter: setTypes} as IRequestParams).then();
    // apim.getList('creditType1Familles', {setter: setFamilles} as IRequestParams).then();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps
  const resourceType: string = 'patrimoineContractsAssuranceVie';

  // Load the list of banks.
  const [banks, setBanks] = useState<any[]>([]);
  useEffect(() => {
    setLoading(true);
    apim.fetchEntities({
      resourceType: 'banks',
      params: [{ label: 'order[name]', value: 'asc' }, { label: 'limit', value: 250 }],
      cache: editMode === false,
      setter: setBanks,
      setLoading
    } as IRequestParams).then();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    setLoading(true);
    apim.fetchEntities({
      resourceType,
      params: [{label: 'patrimoine', value: context?.id}, {label: 'expand[]', value: 'contract_assurance_vie:read'}],
      cache: editMode === false,
      setter: setRows,
      setLoading,
      formatter: (_arr: any[]) => _arr.map((row: any) => ({...row?.contractAssuranceVie, ...{id: row?.id, contractId: row?.contractAssuranceVie?.id}}))
    } as IRequestParams).then();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const onNew = () => {
    return (!isValidUUID(appState?.dossier()?.id) || !isValidUUID(context?.id)) ? null :
    navigate(appUri.dos.edit.patrimoine.contracts.uri.replace(':id', appState?.dossier()?.id).replace(':type', 'epargne-assurance-vie').replace('/:contractId?', ''));
  };

  const labelBody = (rowData: any) =>
    <div className={'flex flex-column'}>
      <div className={'font-medium'}>{rowData?.label}</div>
      <Link className={'block mt-1 text-sm'} to={editMode ?
        appUri.dos.edit.patrimoine.contracts.uri
          .replace(':id', appState?.dossier()?.id)
          .replace(':type', 'epargne-assurance-vie')
          .replace(':contractId?', rowData?.contractId) + appUri.dos.edit.patrimoine.contracts.steps.default.step2 :
        appUri.dos.page.replace(':id', appState?.dossier()?.id) + '/patrimoine/contrats/epargne-assurance-vie/' + rowData?.contractId
      }>{trans(t, 'seeDetails')}</Link>
    </div>
  ;
  const bankBody = (rowData: any) => {
    const bank: any = banks.filter((_b: any) => _b.id === rowData?.bank);

    return bank.length > 0 ? bank[0].name : '';
  };

  const amountBody = (rowData: any) => {
    const calc: number = (rowData?.montantFondEuro ?? 0) + (rowData?.montantFondUniteCompte ?? 0) + (rowData?.montantFondUniteCompteImmobilier ?? 0);

    return amountCell(calc);
  };

  const editBody = (rowData: any) =>
    <>
      <Button type={'button'} className={'a8-details-btn'} icon={'pi pi-pencil'} rounded text aria-label={ucfirst(trans(t, 'edit'))} onClick={() => {
        navigate(appUri.dos.edit.patrimoine.contracts.uri
          .replace(':id', appState?.dossier()?.id)
          .replace(':type', 'epargne-assurance-vie')
          .replace(':contractId?', rowData?.contractId) + appUri.dos.edit.patrimoine.contracts.steps.default.step2);
      }}
      />
      <Tooltip target={'.a8-details-btn'} position={'left'} content={ucfirst(trans(t, 'edit'))} mouseTrack/>
    </>
  ;

  return (
    <DatatableWrapper resourceType={resourceType} tableKey={'patrimoine-contrats-assurance-vie'} title={title ?? trans(t, 'assuranceVie')}
                      editMode={editMode} noEdit onNew={onNew} noAdd={!isExpert() && !isAdmin()} noFilters noGlobalFilter paginator={false} rows={rows} isLoading={loading}
                      footer={[{ field: 'montantFondEuro', calculation: 'sum', label: ucfirst(trans(t, 'total')) + ' : ' }]} t={t}>
      <Column field={'label'} header={ucfirst(trans(t, 'table|patrimoine_headers.label'))} body={labelBody}/>
      <Column field={'bank'} header={ucfirst(trans(t, 'table|patrimoine_headers.bank'))} style={{ width: '250px' }}
              align={'center'} alignHeader={'center'} body={bankBody}/>
      <Column field={'type'} header={ucfirst(trans(t, 'table|patrimoine_headers.type'))} style={{ width: '250px' }}
              align={'center'} alignHeader={'center'} body={(rowData: any) => apiListCell(rowData?.type, types)}/>
      <Column field={'openingDate'} header={ucfirst(trans(t, 'table|patrimoine_headers.openingDate'))} style={{ width: '250px' }}
              align={'center'} alignHeader={'center'} body={(rowData: any) => dateTimeCell(rowData?.openingDate, {format: 'DD/MM/YYYY'})}/>
      <Column field={'montantFondEuro'} header={ucfirst(trans(t, 'table|patrimoine_headers.montant'))} style={{ width: '250px' }}
              align={'center'} alignHeader={'center'} body={amountBody}/>
      {!editMode && (isExpert() || isAdmin()) && (
        <Column body={editBody} align={'center'} style={{ maxWidth: '90px', width: '90px' }} />
      )}
    </DatatableWrapper>
  );
};
