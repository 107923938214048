import { AxiosResponse } from 'axios';

import { IRequestParams } from 'services';
import { iri, isValidUUID } from 'utilities';
import { forEach, isEmpty, pick } from 'lodash';

export const onPersonPhysiqueFormSubmit = (formData: any, original: any, apim: any, callback: any | null = null) => {
  // Let's manually handle "personnesPhysiques" vs "personnesPhysiquesData" fields.
  const rootFields: any = pick(formData, ['active', 'lastName', 'firstName', 'birthName', 'birthDate', 'socialSecurityNumber', 'noSocialSecurityNumber', 'deceased', 'deceasedDate', 'email', 'phone1', 'phone2']);
  const versionFields: any = pick(formData, ['lastName', 'firstName', 'initials', 'function', 'sex', 'civility', 'veteran', 'singleParent', 'disabled']);
  // Then Address + BirthAddress fields.
  const addressFields: any = pick(formData, ['address1', 'address2', 'postalCode', 'city', 'country']);
  const birthAddressFields: any = {};
  forEach(['postalCode', 'city', 'country'], ((_f: string) => {
    if (undefined === formData['birthAddress.' + _f]) return;
    birthAddressFields[_f] = formData['birthAddress.' + _f];
  }));

  // some custom checks on mask to date fields
  forEach(['birthDate', 'deceasedDate'], ((d: string) => {
    if ('' === rootFields[d]) rootFields[d] = null;
  }));

  const patchBirthAddress = (_original: any) => {
    if (!isEmpty(birthAddressFields)) {
      if (isValidUUID(_original?.birthAddress?.id)) {
        apim.patchEntity({
          resourceType: 'personnesPhysiquesAddresses',
          id: _original.birthAddress.id,
          data: birthAddressFields,
          notif: isEmpty(rootFields) && isEmpty(versionFields) && isEmpty(addressFields),
          success: callback
        } as IRequestParams).then();
      } else {
        apim.postEntity({
          resourceType: 'personnesPhysiquesAddresses',
          data: {
            ...birthAddressFields,
            ...{ personnePhysiqueBirthAddress: iri('personnesPhysiques', _original?.id) }
          },
          notif: isEmpty(rootFields) && isEmpty(versionFields),
          success: callback
        } as IRequestParams).then();
      }
    } else {
      if (callback) {
        callback()
      }
    }
  };

  const patchAddress = (_original: any) => {
    if (!isEmpty(addressFields)) {
      if (isValidUUID(_original?.latestVersion?.address?.id)) {
        apim.patchEntity({
          resourceType: 'personnesPhysiquesAddresses',
          id: _original?.latestVersion?.address?.id,
          data: addressFields,
          notif: isEmpty(rootFields) && isEmpty(versionFields) && isEmpty(birthAddressFields),
          success: () => patchBirthAddress(_original)
        } as IRequestParams).then();
      } else {
        apim.postEntity({
          resourceType: 'personnesPhysiquesAddresses',
          data: {
            ...addressFields,
            ...{ personnePhysiqueAddress: iri('personnesPhysiquesData', _original?.latestVersion?.id) }
          },
          notif: isEmpty(rootFields) && isEmpty(versionFields),
          success: () => patchBirthAddress(_original)
        } as IRequestParams).then();
      }
    } else {
      patchBirthAddress(_original);
    }
  };

  const patchVersion = (_original: any) => {
    if (!isEmpty(versionFields)) {
      apim.patchEntity({
        resourceType: 'personnesPhysiquesData',
        id: _original?.latestVersion?.id,
        data: versionFields,
        notif: isEmpty(rootFields) && isEmpty(addressFields) && isEmpty(birthAddressFields),
        success: () => patchAddress(_original)
      } as IRequestParams).then();
    } else {
      patchAddress(_original);
    }
  };

  const patchRootFields = (_original: any) => {
    if (!isEmpty(rootFields)) {
      apim.patchEntity({
        resourceType: 'personnesPhysiques',
        id: _original?.id,
        data: rootFields,
        success: () => patchVersion(_original)
      } as IRequestParams).then();
    } else {
      patchVersion(_original);
    }
  };

  if (!isValidUUID(original?.id)) {
    apim.postEntity({
      resourceType: 'personnesPhysiques',
      data: rootFields,
      success: (res: AxiosResponse) => {
        apim.postEntity({
          resourceType: 'personnesPhysiquesData',
          data: { personnePhysique: iri('personnesPhysiques', res?.data?.id) },
          notif: false,
          success: () => patchVersion(res?.data)
        } as IRequestParams).then();
      }
    } as IRequestParams).then();
  } else {
    patchRootFields(original);
  }
};

