import React from 'react';

export const Loader = (props: any) => {
  const { text, size, color } = props;

  return (
    <div className={'flex align-items-center py-5 px-3'}>
      <i className={'pi pi-spin pi-spinner mr-2 text-' + (color || 'primary')} style={{ fontSize: (size || 2) + 'rem' }}></i>
      <p className={'m-0 text-lg'}>{text}</p>
    </div>
  );
};
