import React from 'react';
import { Card } from 'primereact/card';
import { classNames } from 'primereact/utils';

import { trans, ucfirst } from 'utilities';
import dossier from 'assets/images/illustrations/search/dossier.webp';

import { replace } from 'lodash';
import appUri from 'config/appUri.json';

export const SearchResultDossier = (props: any) => {
  const { data, apim, setVisibleFullScreen } = props;
  const { t, navigate } = apim.di();
  const redirectUri = replace(appUri.dos.page, ':id', data.id || '_');
  const header = () => <img alt={'Card'} src={dossier} className={'border-round border-noround-bottom'} />;

  return <Card header={header} title={ucfirst(trans(t, 'dossier'))} subTitle={data.identifier ?? data.title ?? data.id} className={classNames('a8-search-item mb-4 mx-1')} onClick={() => {
    navigate(redirectUri);
    setVisibleFullScreen(false);
  }}>
    <ul className={'m-0 pl-4'}>
      <li>{ucfirst(trans(t, 'entrepreneur'))}: {data.entrepreneurName}</li>
      <li>{ucfirst(trans(t, 'identifier'))}: {data.identifier}</li>
      <li>{ucfirst(trans(t, 'company'))}: {data.mainCompany}</li>
      <li>{ucfirst(trans(t, 'societeExpert'))}: {data.societeExpert}</li>
    </ul>
  </Card>
    ;
};
