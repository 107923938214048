import React from 'react';
import { trans, ucfirst } from 'utilities';
import { APIListField, CheckboxField, MaskField, StaticListField } from 'forms';

export const CGContratForm = (props: any) => {
  const { t, control, onFieldChange, errorMessage, getValues } = props;
  const { periodeResiliation, type } = getValues();

  return <div className={'grid w-full px-3'}>
    <div className={'col-3'}>
      <APIListField classes={'w-full'} listKey={'prevoyanceContractPeriodesResiliations'} fieldKey={'periodeResiliation'} control={control} onFieldChange={onFieldChange} errorMessage={errorMessage}
                    label={trans(t, 'form|contract.periode_resiliation')} rules={{required: trans(t, 'form|requiredField')}}/>
    </div>

    {periodeResiliation !== 'tout_moment' && (
      <div className={'col-3'}>
        <MaskField classes={'w-full'} fieldKey={'dateResiliation'} control={control} onFieldChange={onFieldChange} label={ucfirst(trans(t, 'form|contract.date_resiliation'))} errorMessage={errorMessage} isDate/>
      </div>
    )}

    <div className={'col-3'}>
      <StaticListField classes={'w-full'} listKey={'tarifEvolueAvecAge'} listsOptions={{tarifEvolueAvecAge: [{name: trans(t, 'yes'), value: true}, {name: trans(t, 'no'), value: false}]}}
                       fieldKey={'tarifEvolueAvecAge'} control={control} optionLabel={'name'} optionValue={'value'}
                       onFieldChange={onFieldChange} errorMessage={errorMessage} label={trans(t, 'form|prevoyance_contrat.tarifEvolueAvecAge')}/>
    </div>

    {type !== 'tempo_deces' && (
      <div className={'col-3'}>
        <APIListField classes={'w-full'} listKey={'prevoyanceContractNatures'} fieldKey={'natureContrat'} control={control} onFieldChange={onFieldChange} errorMessage={errorMessage}
                      label={trans(t, 'form|prevoyance_contrat.natureContrat')} rules={{required: trans(t, 'form|requiredField')}}/>
      </div>
    )}

    {type !== 'tempo_deces' && type !== 'frais_generaux' && (
      <div className={'col-3'}>
        {type !== 'homme_cle' && (
          <APIListField classes={'w-full'} listKey={'prevoyanceContractVersementPrestations'} fieldKey={'versementPrestations'} control={control} onFieldChange={onFieldChange} errorMessage={errorMessage}
                        label={trans(t, 'form|prevoyance_contrat.versementPrestations')} rules={{required: trans(t, 'form|requiredField')}}/>
        )}
      </div>
    )}

    <div className={'col-3'}>
      <CheckboxField classes={'w-full'} fieldKey={'cumulableAvecAutresContrats'} control={control} onFieldChange={onFieldChange} errorMessage={errorMessage} label={trans(t, 'form|prevoyance_contrat.cumulableAvecAutresContrats')}/>
    </div>
  </div>;
};
