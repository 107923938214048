import React from 'react';
import appUri from 'config/appUri.json';

import { SocieteClientWizardForm } from 'forms';
import {
  SocietesExpertsList, SocieteExpertsRouter,
  SocietesClientList, SocieteClientRouter
} from 'pages';
import { trimStart } from 'lodash';

export const RoutesSociete = () => {
  return [
    {
      path: trimStart(appUri.cie.exp.list),
      element: <SocietesExpertsList/>
    },
    {
      path: trimStart(appUri.cie.exp.add),
      element: <SocieteExpertsRouter action={appUri.reservedKeywords.add}/>,
    },
    {
      path: trimStart(appUri.cie.exp.page) + '/:tab?',
      element: <SocieteExpertsRouter/>
    },
    {
      path: trimStart(appUri.cie.exp.edit) + '/:tab?',
      element: <SocieteExpertsRouter action={appUri.reservedKeywords.edit}/>,
    },
    {
      path: trimStart(appUri.cie.cli.list),
      element: <SocietesClientList/>
    },
    {
      path: trimStart(appUri.cie.cli.add),
      element: <SocieteClientRouter action={appUri.reservedKeywords.add}/>,
    },
    {
      path: trimStart(appUri.cie.cli.delete),
      element: <SocieteClientRouter action={appUri.reservedKeywords.delete}/>,
    },
    {
      path: trimStart(appUri.cie.cli.page) + '/:tab?',
      element: <SocieteClientRouter/>
    },
    {
      path: trimStart(appUri.cie.cli.edit) + '/version/:vid?/:modificationScope?/:step?',
      element: <SocieteClientWizardForm/>,
    },
  ];
};
