import React, { useCallback, useEffect, useState } from 'react';
import { AxiosResponse } from 'axios';
import { useParams, useSearchParams} from 'react-router-dom';
import { useAppState } from 'states';

import { ProgressBar } from 'primereact/progressbar';
import { Steps } from 'primereact/steps';

import { isValidUUID, trans, ucfirst } from 'utilities';
import { Error, NotFound } from 'pages';
import { Loader, PageLoader } from 'components';
import { IRequestParams, useApim } from 'services';
import { getMissionContext } from './ModuleHelper';
import { ContratsFacultatifs, DatesDepart, Retraite } from './retraite';
import { StatutDirigeant } from './statutDirigeant';
import { RemunerationDirigeant } from './remunerationDirigeant';
import { MissionSelection } from './tabs';

import appUri from 'config/appUri.json';

export const MissionsFormRouter = () => {
  const { id, step, type, hypotheseId, missionId, year } = useParams();
  const apim = useApim();
  const navigate = apim.navigate();

  const { t } = apim.di();
  const appState = useAppState();
  const dossier = appState.dossier();

  const [activeIndex, setActiveIndex] = useState<number>(-1);
  const { urls, defaultBC, index } = getMissionContext(t, appState, { id, step, type, hypotheseId, missionId });

  const [loading, setLoading] = useState<boolean>(false);
  const [notFound, setNotFound] = useState<boolean>(false);
  const [errored, setErrored] = useState<boolean>(false);

  const [missionCategory, setMissionCategory] = useState<any>(null);
  const cancelUri: string = urls?.landing + '?maj=' + Date.now();

  useEffect(() => {
    if (year) {
      appState.setCivilYear(year.toString());
      appState.setExercise(year.toString())
    }
  }, [year]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (dossier?.id === id) return;

    // Fetch dossier.
    setLoading(true);
    apim.fetchEntity({
      resourceType: 'dossiers',
      id,
      setLoading,
      setErrored,
      setNotFound,
      setter: appState.setDossier,
    } as IRequestParams).then();
  }, [id,activeIndex]); // eslint-disable-line react-hooks/exhaustive-deps
  //TODO enlever activeIndex de la dépendance quand le problème du dossier undefined sera réglé

  // Update active index & Page Header.
  useEffect(() => {
    setActiveIndex(index);
    appState.setBreadcrumb(defaultBC);
    appState.setPageActions([]);
    appState.setPageTitle(ucfirst(trans(t, 'dossier')) + ' ' + dossier?.title + ' | ' + trans(t, 'editing'));
  }, [dossier?.id, step, index]); // eslint-disable-line react-hooks/exhaustive-deps

  const wizardItems: any[] = [];
  wizardItems.push({ label: ucfirst(trans(t, 'menu|wizard.dossier.missions.selection')), command: () => navigate(`${urls?.form}/${missionId}/hypothese/${hypotheseId}`) });

  if (type && type in appUri.dos.edit.missions.steps) {
    const steps = appUri.dos.edit.missions.steps[type as keyof typeof appUri.dos.edit.missions.steps];

    if (type === 'retraite' || type === 'statut_dirigeant' || type === 'remuneration_dirigeant') {
      const formLength = Object.keys(steps).length;

      for (let index = 1; index <= formLength; index++) {
        const stepUri = steps[`step${index}` as keyof typeof steps];
        if (stepUri) {
          wizardItems.push({
            label: ucfirst(trans(t, `menu|wizard.dossier.missions.${type}.step${index}`)),
            command: () => {
              navigate(`${urls?.form}/${missionId}/hypothese/${hypotheseId}${stepUri}`);
            }
          });
        }
      }
    }
  }
  const [searchParams] = useSearchParams();
  const lastUpdate = searchParams.get('maj');
  const buildTabContent = useCallback(() => {
    if (type ==='retraite') {
      switch (activeIndex) {
        case -1: return <Loader/>;

        case 1: return <Retraite apim={apim} urls={urls} hypotheseId={hypotheseId} missionId={missionId}
                                 cancelUri={cancelUri}
                                 missionCategory={missionCategory} setMissionCategory={setMissionCategory}/>;

        case 2: return <DatesDepart apim={apim} urls={urls} cancelUri={cancelUri} hypotheseId={hypotheseId} missionId={missionId}
                                    missionCategory={missionCategory} setMissionCategory={setMissionCategory}/>;

        case 3: return <ContratsFacultatifs apim={apim} appState={appState} urls={urls} cancelUri={cancelUri} hypotheseId={hypotheseId}
                                            missionId={missionId} missionCategory={missionCategory} dossier={dossier}/>;

        default: return <MissionSelection apim={apim} urls={urls} appState={appState} missionId={missionId} cancelUri={cancelUri}
                                          setMissionCategory={setMissionCategory} dossier={dossier} />;
      }
    }

    if (type ==='statut_dirigeant') {
      switch (activeIndex) {
        case -1: return <Loader/>;

        case 1: return <StatutDirigeant apim={apim} urls={urls} hypotheseId={hypotheseId} missionId={missionId} cancelUri={cancelUri} appState={appState}
                                        missionCategory={missionCategory} setMissionCategory={setMissionCategory} dossier={dossier}/>;

        default: return <MissionSelection apim={apim} urls={urls} appState={appState} missionId={missionId} cancelUri={cancelUri}
                                          setMissionCategory={setMissionCategory} dossier={dossier} />;
      }
    }

    if (type ==='remuneration_dirigeant') {
      switch (activeIndex) {
        case -1: return <Loader/>;

        case 1: return <RemunerationDirigeant apim={apim} urls={urls} appState={appState} hypotheseId={hypotheseId} missionId={missionId}
                                              cancelUri={cancelUri}
                                              missionCategory={missionCategory} setMissionCategory={setMissionCategory}
                                              dossier={dossier} year={year} />;

        default: return <MissionSelection apim={apim} urls={urls} appState={appState} missionId={missionId}
                                          cancelUri={cancelUri}
                                          setMissionCategory={setMissionCategory} dossier={dossier}/>;
      }
    }

  }, [dossier?.id, missionId, hypotheseId, missionCategory, step, activeIndex, lastUpdate]); // eslint-disable-line react-hooks/exhaustive-deps

  const renderSteps = useCallback(() =>
      <Steps className={'capitalize'} model={wizardItems} activeIndex={activeIndex} onSelect={(e) => setActiveIndex(e.index)} readOnly={!isValidUUID(hypotheseId)}/>
    , [dossier?.id, missionId, hypotheseId, missionCategory, step, activeIndex, lastUpdate]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (!isValidUUID(missionCategory?.id) && isValidUUID(hypotheseId) && type) {
      apim.fetchEntities({
        resourceType: `mission_${type}`,
        params: [{ label: 'missionHypothesis', value: hypotheseId }],
        success: (resCat: AxiosResponse) => {
          if (resCat?.data && resCat?.data['hydra:member'] && resCat?.data['hydra:member'].length > 0) {
            setMissionCategory(resCat?.data['hydra:member'][0]);
          }
        }
      } as IRequestParams).then();
    }
  }, [hypotheseId, type]); // eslint-disable-line react-hooks/exhaustive-deps

  //@TODO Manu : après la sélection de la mission, dossier.id est undefined, il faut recharger la page pour passer à l'étape suivante
  if (!dossier?.id || loading) return <PageLoader/>;
  if (errored) return <Error asBlock/>;
  if (notFound) return <NotFound asBlock/>;

  const stepProgressSize = 100 / wizardItems.length;

  return (
    <>
      <div className={'grid'}>
        <div className={'col'}>
          <ProgressBar value={stepProgressSize + activeIndex * stepProgressSize} showValue={false}/>
        </div>
      </div>

      {renderSteps()}

      <div className={'card height-100 fadein animation-duration-500 flex flex-column'}>
        {buildTabContent()}
      </div>
    </>
  );
};
