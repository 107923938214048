import React from 'react';
import { Dropdown } from 'primereact/dropdown';

import { trans } from 'utilities';

export const YearsSpan = (props: any) => {
  const { appState, apim, missionYear } = props;
  const { t } = apim.di();

  //useMemo to cache calculation result instead of calculating every render
  const years = React.useMemo(() => {
    const yearsArray = [];
    for (let i = parseInt(missionYear); i <= (parseInt(missionYear) + 4); i++) {
      yearsArray.push(i);
    }
    return yearsArray;
  }, [missionYear]);

  return <>
    {years?.length > 0 && (
      <div className={'flex-none flex p-2'}>
        <div className={'field p-inputgroup'}>
          <span className={'p-inputgroup-addon'}>
            <i className={'pi pi-calendar'}></i>
          </span>

          <span className={'p-float-label'}>
            <Dropdown id={'a8-civilYears-select'} value={parseInt(appState.civilYear())} options={years} placeholder={trans(t, 'form|select')}
                      onChange={(e) => {
                        appState.setCivilYear(e.value);
                        appState.setExercise(e.value); // Let's keep these 2 synchronized ATM.
                      }}/>
            <label htmlFor={'a8-civilYear-select'} className={'text-ucfirst'}>
              {trans(t, 'civilYear')}
            </label>
          </span>
        </div>
      </div>
    )}
  </>;
};
