import React from 'react';

import { trimStart } from 'lodash';
import { UserRouter } from 'pages';

import appUri from 'config/appUri.json';

export const RoutesAccount = () => {
  return [
    {
      path: trimStart(appUri.usr.page) + '/:tab?',
      element: <UserRouter />,
    },
    {
      path: trimStart(appUri.usr.edit) + '/:tab?',
      element: <UserRouter action={appUri.reservedKeywords.edit} />,
    },
  ];
};
