import React, { useRef } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';

import { trans } from 'utilities';
import { amountCell } from 'components';

export const Beneficiaires = (props: any) => {
  const { data, apim } = props;
  const { t } = apim.di();
  const dt = useRef<any>(null);

  const nameBodyTemplate = (rowData: any) => {
    return <div>
      <div key={'name'}>{(rowData.prenom_usuel ?? rowData.prenom) + ' ' + rowData.nom}</div>
      {rowData.date_de_naissance_formatee && (
        <div key={'birthDate'}>{trans(t, 'born')} : {rowData.date_de_naissance_formatee}</div>
      )}
    </div>;
  };

  const addressBodyTemplate = (rowData: any) => {
    return <div>
      {(rowData.adresse_ligne_1 !== null || rowData.adresse_ligne_2 !== null) && (
        <div className={'address'}>{rowData.adresse_ligne_1}&nbsp;{rowData.adresse_ligne_2}</div>
      )}
      <div className={'postalCode'}>{rowData.code_postal}</div>
      <div className={'city'}>{rowData.ville}</div>
      <div className={'country'}>{rowData.pays}</div>
    </div>;
  };

  const nbPartsBodyTemplate = (rowData: any) => trans(t, 'table|nbTitresInconnu');
  const percentPartsBodyTemplate = (rowData: any) => amountCell(rowData?.details_parts_directes?.pourcentage_pleine_propriete ?? rowData.pourcentage_parts, {symbol: '%', precision: 3});
  const percentParts2BodyTemplate = (rowData: any) => amountCell(rowData?.details_parts_directes?.pourcentage_nue_propriete, {symbol: '%', precision: 3});
  const percentParts3BodyTemplate = (rowData: any) => amountCell(rowData?.details_parts_directes?.pourcentage_usufruit, {symbol: '%', precision: 3});

  return (
    <>
      <DataTable ref={dt} value={data} className={'datatable-responsive'} dataKey={'prenom'} emptyMessage={trans(t, 'institution_none')} stripedRows={true} sortField={'siege'}>
        <Column field={'nom'} header={trans(t, 'lastName')} body={nameBodyTemplate} style={{ width: '250px' }} />
        <Column field={'adresse'} header={trans(t, 'address')} body={addressBodyTemplate} />
        <Column field={'nbTitresPleinePropriete'} header={trans(t, 'table|nbTitresPleinePropriete')} body={nbPartsBodyTemplate} align={'center'} alignHeader={'center'} />
        <Column field={'rowData.details_parts_directes.pourcentage_pleine_propriete'} header={'%'} body={percentPartsBodyTemplate} align={'center'} alignHeader={'center'} />
        <Column field={'nbTitresNuePropriete'} header={trans(t, 'table|nbTitresNuePropriete')} body={nbPartsBodyTemplate} align={'center'} alignHeader={'center'} />
        <Column field={'rowData.details_parts_directes.pourcentage_nue_propriete'} header={'%'} body={percentParts2BodyTemplate} align={'center'} alignHeader={'center'} />
        <Column field={'nbTitresUsufruit'} header={trans(t, 'table|nbTitresUsufruit')} body={nbPartsBodyTemplate} align={'center'} alignHeader={'center'} />
        <Column field={'rowData.details_parts_directes.pourcentage_usufruit'} header={'%'} body={percentParts3BodyTemplate} align={'center'} alignHeader={'center'} />
      </DataTable>
    </>
  );
};
