import React, { useCallback, useEffect, useState } from 'react';
import { AxiosResponse } from 'axios';
import { Checkbox } from 'primereact/checkbox';

import { dateString, isValidUUID, trans, ucfirst } from 'utilities';
import { IRequestParams, isClient } from 'services';
import { getModuleId, IndicatorSimple, PageLoader, formatPieChart, AMChartPie, Userpicture } from 'components';
import { getIndicator } from 'pages';
import { useThemeState } from 'states';

import { isEmpty, replace } from 'lodash';
import appUri from 'config/appUri.json';

export const PatrimoineVueGenerale = (props: any) => {
  const { id, appState, apim, modulesMenu, lastUpdate } = props;
  const { t } = apim.di();
  const themeState = useThemeState();

  const dossier = appState.dossier();
  const [loading, setLoading] = useState<boolean>(false);
  const [showDetails, setShowDetails] = useState<boolean>(false);
  const [data, setData] = useState<any>(null);

  // Override Page Header (defaults into ModuleWrapper then ReservesRouter).
  useEffect(() => {
    appState.setBreadcrumb([
     isClient()
      ? { label: trans(t, 'dossier', 2) }
      : { label: trans(t, 'dossier', 2), to: appUri.dos.list },
     { label: dossier.title, to: replace(appUri.dos.page, ':id', id)},
     { label: trans(t, 'menu|menu.schema_flux.patrimoine') }
    ]);

    appState.setModule(getModuleId(modulesMenu, 'patrimoine'));
  }, [id]); // eslint-disable-line react-hooks/exhaustive-deps

  const fetchData = useCallback(async () => {
    if (!appState.patrimoine()) return null;

    setLoading(true);
    apim.call({
      resourceType: 'dossiers',
      action: 'module',
      id: id + '|' + getModuleId(modulesMenu, 'patrimoine'),
      method: 'get',
      params: [{label: 'patrimoine', value: appState.patrimoine()}],
      cacheMode: isEmpty(lastUpdate) ? 'default' : 'replace',
      setLoading,
      success: (res: AxiosResponse) => setData({...res.data, ...{
          patrimoine: ((res?.data?.metadata['hydra:member'] ?? []).filter((c: any) => c.key === 'patrimoine'))[0]?.data ?? null,
          chart1: formatPieChart(res.data, 'patrimoine.chart.immobilier', t, {hideLabels: true}),
          chart2: formatPieChart(res.data, 'patrimoine.chart.financier', t, {hideLabels: true}),
          chart3: formatPieChart(res.data, 'patrimoine.chart.mobilier', t, {hideLabels: true}),
          chart4: formatPieChart(res.data, 'patrimoine.chart.autres', t, {hideLabels: true}),
          chart1Details: formatPieChart(res.data, 'patrimoine.chart.immobilierDetails', t, {}),
          chart2Details: formatPieChart(res.data, 'patrimoine.chart.financierDetails', t, {}),
          chart3Details: formatPieChart(res.data, 'patrimoine.chart.mobilierDetails', t, {}),
          chart4Details: formatPieChart(res.data, 'patrimoine.chart.autresDetails', t, {}),
          chart5: formatPieChart(res.data, 'patrimoine.chart.lie_activite', t, {hideLabels: true}),
          chart6: formatPieChart(res.data, 'patrimoine.chart.non_lie_activite', t, {hideLabels: true})
      }})
    } as IRequestParams);
  }, [appState.patrimoine(), lastUpdate]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    fetchData().then();
  }, [appState.patrimoine(), lastUpdate]); // eslint-disable-line react-hooks/exhaustive-deps

  const onShowDetailsChange = (e: any) => setShowDetails(e.checked || false);

  if (loading) return <PageLoader/>;

  return (
    <div className={'flex flex-column fadein animation-duration-500'}>
      <>
        <div className={'flex flex-colum md:flex-row flex-wrap w-12 pb-5'}>
          <div className={'flex w-12 md:w-3 py-4 px-3'}>
            <IndicatorSimple color={'accent2'} dark bgImage title={trans(t, 'chart|patrimoine.total')}
                             value={getIndicator(data, 'patrimoine.total').value}
                             precision={getIndicator(data, 'patrimoine.total').options?.precision ?? 2}/>
          </div>
          <div className={'flex w-12 md:w-3 py-4 px-3'}>
            <IndicatorSimple color={'accent4'} dark bgImage title={trans(t, 'chart|patrimoine.securite')}
                             value={getIndicator(data, 'patrimoine.securite').value}
                             precision={getIndicator(data, 'patrimoine.securite').options?.precision ?? 2}/>
          </div>
          <div className={'flex w-12 md:w-3 py-4 px-3'}>
            <IndicatorSimple color={'accent6'} dark bgImage title={trans(t, 'chart|patrimoine.flux')}
                             value={getIndicator(data, 'patrimoine.flux').value}
                             precision={getIndicator(data, 'patrimoine.flux').options?.precision ?? 2}/>
          </div>
          <div className={'flex w-12 md:w-3 py-4 px-3'}>
            <IndicatorSimple color={'accent5'} dark bgImage title={trans(t, 'chart|patrimoine.transmission')}
                             value={getIndicator(data, 'patrimoine.transmission').value}
                             precision={getIndicator(data, 'patrimoine.transmission').options?.precision ?? 2}/>
          </div>
        </div>

        <div className={'flex align-items-start mx-3'}>
          <Checkbox inputId={'chart1detail'} name={'chart1detail'} onChange={onShowDetailsChange} checked={showDetails}/>
          <label htmlFor={'chart1detail'} className={'ml-2'}>{trans(t, 'chart|afficherDetail')}</label>
        </div>

        {showDetails ? (
          <div className={'flex flex-colum md:flex-row flex-wrap'}>
            <div className={'flex w-6 xl:w-3 py-4 px-3'}>
              <div className={'flex flex-row flex-grow-1'}>
                {themeState.isMobile() || themeState.isTablet() ? (
                  <AMChartPie chartData={data?.chart1Details} verticalLegend alignLabels={false}/>
                ) : themeState.isNotebook() ? (
                  <AMChartPie chartData={data?.chart1Details} alignLabels={false}/>
                ) : (
                  <AMChartPie chartData={data?.chart1Details} hideLegend alignLabels={false}/>
                )}
              </div>
            </div>

            <div className={'flex w-6 xl:w-3 py-4 px-3'}>
              <div className={'flex flex-row flex-grow-1'}>
                {themeState.isMobile() || themeState.isTablet() ? (
                  <AMChartPie chartData={data?.chart2Details} verticalLegend alignLabels={false}/>
                ) : themeState.isNotebook() ? (
                  <AMChartPie chartData={data?.chart2Details} alignLabels={false}/>
                ) : (
                  <AMChartPie chartData={data?.chart2Details} hideLegend alignLabels={false}/>
                )}
              </div>
            </div>

            <div className={'flex w-6 xl:w-3 py-4 px-3'}>
              <div className={'flex flex-row flex-grow-1'}>
                {themeState.isMobile() || themeState.isTablet() ? (
                  <AMChartPie chartData={data?.chart3Details} verticalLegend alignLabels={false}/>
                ) : themeState.isNotebook() ? (
                  <AMChartPie chartData={data?.chart3Details} alignLabels={false}/>
                ) : (
                  <AMChartPie chartData={data?.chart3Details} hideLegend alignLabels={false}/>
                )}
              </div>
            </div>

            <div className={'flex w-6 xl:w-3 py-4 px-3'}>
              <div className={'flex flex-row flex-grow-1'}>
                {themeState.isMobile() || themeState.isTablet() ? (
                  <AMChartPie chartData={data?.chart4Details} verticalLegend alignLabels={false}/>
                ) : themeState.isNotebook() ? (
                  <AMChartPie chartData={data?.chart4Details} alignLabels={false}/>
                ) : (
                  <AMChartPie chartData={data?.chart4Details} hideLegend alignLabels={false}/>
                )}
              </div>
            </div>
          </div>
        ) : (
          <div className={'flex flex-colum md:flex-row flex-wrap'}>
            <div className={'flex w-6 xl:w-3 py-4 px-3'}>
              <div className={'flex flex-row flex-grow-1'}>
                {themeState.isMobile() || themeState.isTablet() ? (
                  <AMChartPie chartData={data?.chart1} verticalLegend/>
                ) : themeState.isNotebook() ? (
                  <AMChartPie chartData={data?.chart1}/>
                ) : (
                  <AMChartPie chartData={data?.chart1} hideLegend/>
                )}
              </div>
            </div>

            <div className={'flex w-6 xl:w-3 py-4 px-3'}>
              <div className={'flex flex-row flex-grow-1'}>
                {themeState.isMobile() || themeState.isTablet() ? (
                  <AMChartPie chartData={data?.chart2} verticalLegend/>
                ) : themeState.isNotebook() ? (
                  <AMChartPie chartData={data?.chart2}/>
                ) : (
                  <AMChartPie chartData={data?.chart2} hideLegend/>
                )}
              </div>
            </div>

            <div className={'flex w-6 xl:w-3 py-4 px-3'}>
              <div className={'flex flex-row flex-grow-1'}>
                {themeState.isMobile() || themeState.isTablet() ? (
                  <AMChartPie chartData={data?.chart3} verticalLegend/>
                ) : themeState.isNotebook() ? (
                  <AMChartPie chartData={data?.chart3}/>
                ) : (
                  <AMChartPie chartData={data?.chart3} hideLegend/>
                )}
              </div>
            </div>

            <div className={'flex w-6 xl:w-3 py-4 px-3'}>
              <div className={'flex flex-row flex-grow-1'}>
                {themeState.isMobile() || themeState.isTablet() ? (
                  <AMChartPie chartData={data?.chart4} verticalLegend/>
                ) : themeState.isNotebook() ? (
                  <AMChartPie chartData={data?.chart4}/>
                ) : (
                  <AMChartPie chartData={data?.chart4} hideLegend/>
                )}
              </div>
            </div>
          </div>
        )}

        <div className={'flex flex-colum md:flex-row flex-wrap w-12 pb-5'}>
          <div className={'flex w-12 md:w-3 py-4 px-3'}>
            <IndicatorSimple color={'accent2'} bgImage title={trans(t, 'chart|patrimoine.immobilier')}
                             value={getIndicator(data, 'patrimoine.immobilier').value}
                             precision={getIndicator(data, 'patrimoine.immobilier').options?.precision ?? 2}/>
          </div>
          <div className={'flex w-12 md:w-3 py-4 px-3'}>
            <IndicatorSimple color={'accent3'} bgImage title={trans(t, 'chart|patrimoine.financier')}
                             value={getIndicator(data, 'patrimoine.financier').value}
                             precision={getIndicator(data, 'patrimoine.financier').options?.precision ?? 2}/>
          </div>
          <div className={'flex w-12 md:w-3 py-4 px-3'}>
            <IndicatorSimple color={'accent4'} bgImage title={trans(t, 'chart|patrimoine.mobilier')}
                             value={getIndicator(data, 'patrimoine.mobilier').value}
                             precision={getIndicator(data, 'patrimoine.mobilier').options?.precision ?? 2}/>
          </div>
          <div className={'flex w-12 md:w-3 py-4 px-3'}>
            <IndicatorSimple color={'accent5'} bgImage title={trans(t, 'chart|patrimoine.autres')}
                             value={getIndicator(data, 'patrimoine.autres').value}
                             precision={getIndicator(data, 'patrimoine.autres').options?.precision ?? 2}/>
          </div>
        </div>

        <div className={'flex flex-colum md:flex-row flex-wrap w-12 py-5'}>
          <div className={'flex w-12 xl:w-6 py-4 px-3'}>
            <div className={'flex flex-row flex-grow-1'}>
              {themeState.isMobile() || themeState.isTablet() ? (
                <AMChartPie chartData={data?.chart5} verticalLegend/>
              ) : themeState.isNotebook() ? (
                <AMChartPie chartData={data?.chart5}/>
              ) : (
                <AMChartPie chartData={data?.chart5} hideLegend/>
              )}
            </div>
          </div>

          <div className={'flex w-12 xl:w-6 py-4 px-3'}>
            <div className={'flex flex-row flex-grow-1'}>
              {themeState.isMobile() || themeState.isTablet() ? (
                <AMChartPie chartData={data?.chart6} verticalLegend/>
              ) : themeState.isNotebook() ? (
                <AMChartPie chartData={data?.chart6}/>
              ) : (
                <AMChartPie chartData={data?.chart6} hideLegend/>
              )}
            </div>
          </div>
        </div>

        <div className={'flex flex-row justify-content-end mt-4'}>
          {isValidUUID(data?.patrimoine?.author) && (
            <div className={'flex px-3 align-items-center'}>
              <strong className={'titre titre--gray pr-2'}>{ucfirst(trans(t, 'author'))}</strong>
              <div>
                <Userpicture apim={apim} user={data.patrimoine.author} size={'small'}/>
              </div>
            </div>
          )}

          {data?.patrimoine?.lastUpdate && (
            <div className={'flex px-3 align-items-center'}>
              <strong className={'titre titre--gray pr-2'}>{ucfirst(trans(t, 'lastUpdated.at'))}</strong>
              <div>
                {dateString(data?.patrimoine?.lastUpdate, 'DD/MM/YYYY') + ' ' + trans(t, 'at') + ' ' + dateString(data?.patrimoine?.lastUpdate, 'HH:mm')!.replace(':', 'h')}
              </div>
            </div>
          )}
        </div>
      </>
    </div>
  );
};
