import React from 'react';

import { trans } from 'utilities';
import { APIListField, TextField } from 'forms';

export const RepresentantSocieteExperts = (props: any) => {
  const { t, control, onFieldChange, errorMessage } = props;

  return <>
    <APIListField listKey={'civilities'} fieldKey={'civility'} control={control} onFieldChange={onFieldChange} errorMessage={errorMessage} label={trans(t, 'civility')} />
    <TextField fieldKey={'lastName'} control={control} onFieldChange={onFieldChange} label={trans(t, 'lastName')} errorMessage={errorMessage} />
    <TextField fieldKey={'firstName'} control={control} onFieldChange={onFieldChange} errorMessage={errorMessage} label={trans(t, 'firstName')} />
    <TextField fieldKey={'function'} control={control} onFieldChange={onFieldChange} errorMessage={errorMessage} label={trans(t, 'function')} />
    <TextField fieldKey={'email'} control={control} onFieldChange={onFieldChange} errorMessage={errorMessage} label={trans(t, 'email')} addon={'at'} />
    <TextField fieldKey={'phone'} control={control} onFieldChange={onFieldChange} errorMessage={errorMessage} label={trans(t, 'phone')} addon={'phone'} />
  </>;
};
