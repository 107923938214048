import React, { useEffect, useRef, useState } from 'react';
import { AxiosResponse } from 'axios';

import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Dropdown } from 'primereact/dropdown';
import { Badge } from 'primereact/badge';
import { InputTextarea } from 'primereact/inputtextarea';
import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';

import { trans, ucfirst, uuidFromIri } from 'utilities';
import { IRequestParams } from 'services';
import { updateCompany, UncontrolledMaskField } from 'forms';
import { DocumentViewer } from 'components';

import { includes, find } from 'lodash';

export const UploadedFiles = (props: any) => {
  const { company, /*uploadProgress,*/ apim, formState, formKey } = props;
  const { t } = apim.di();
  const dt = useRef<any>(null);
  const modalRef = useRef<any>(null);
  const viewerRef = useRef<any>(null);
  const [selected, setSelected] = useState<any[]>((company.files || []).filter((cf: any) => includes(company.selectedFiles, cf.id)));
  const [types, setTypes] = useState<any[]>([]);
  const [modalVisible, setModalVisible] = useState<boolean>(false);
  const [documentPreview, setDocumentPreview] = useState<any>();

  useEffect(() => {
    setSelected((company.files || []).filter((cf: any) => includes(company.selectedFiles, cf.id)));
  }, [company.selectedFiles]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    apim.fetchEntities({
      resourceType: 'documentTypes',
      params: [
        {label: 'order[name]', value: 'asc'},
        {label: 'itemsPerPage', value: 500}
      ],
      setter: setTypes,
    } as IRequestParams).then()
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const updateFile = (newFileData: any) => {
    company.files = company.files.filter((f: any) => f.id !== newFileData.id);
    company.files.push({...newFileData, ...{pendingUpdate: true, lastUpdate: Date.now()}});
    updateCompany(formState, company);
  };

  const updateFileEntity = (newFileData: any) => {
    if (newFileData.id) {
      apim.patchEntity({
        resourceType: 'documents',
        id: newFileData.id,
        data: {
          type: newFileData.type,
          date: newFileData.date,
          description: newFileData.comment
        },
        notif: false
      } as IRequestParams).then();
    }
  };

  const removeFile = (file: any) => {
    company.files = company.files.filter((f: any) => f.id !== file.id);
    company.selectedFiles = company.selectedFiles.filter((f: any) => f !== file.id);
    updateCompany(formState, company);

    apim.deleteEntity({
      resourceType: 'documents',
      id: file.id
    } as IRequestParams).then();
  };

  const labelBodyTemplate = (rowData: any) => {
    return rowData.source === 'local' ? (
      <>
        <div className={'first-line flex align-items-center'}>
          {isSelectable(rowData) ? (
            <span onClick={() => removeFile(rowData)} className={'mr-3 qe-icon-wrapper cursor-pointer'}>
              <i className={'pi pi-trash text-danger'} />
            </span>
          ) : (
            <span className={'mr-3 qe-icon-wrapper'}>
              <i className={'pi pi-spin pi-spinner'} />
            </span>
          )}
          <Button label={rowData.name ?? rowData.path} link onClick={() => onPreviewClick(rowData)} className={'text-left p-0'} />
        </div>
      </>
    ) : <Button label={rowData.name ?? rowData.path} link onClick={() => onPreviewClick(rowData)} className={'text-left p-0'} />
  };

  const sourceBodyTemplate = (rowData: any) => {
    return <Badge value={rowData.source} severity={rowData.source !== 'local' ? 'info' : 'success'} className={'px-4'}>{rowData.source}</Badge>;
  }

  const fileTypeBodyTemplate = (rowData: any) => {
    const file = company.files.filter((f: any) => f.id === rowData.id)[0];
    const change = (event: any) => {
      const newType = find(types, (t: any) => t.id === event?.value);
      updateFile({...rowData, ...{type: newType['@id']}});
      updateFileEntity({...rowData, ...{type: newType['@id']}});
    };

    // Determine the default type.
    let type = null;
    if (file?.type?.length > 0) {
      type = uuidFromIri(file.type);
    } else if (file?.type?.id !== null && file?.type?.id !== undefined) {
      type = file.type.id;
    }

    return (
      <span className={'p-float-label'}>
        <Dropdown value={type} onChange={change} options={types} optionLabel={'name'} placeholder={trans(t, 'form|select')}
                optionValue={'id'} filter className={'w-full'} />
        <label htmlFor={'ms-tag-' + file.name}>{trans(t, 'menu|tag.filetype')}</label>
      </span>
    );
  };

  const dateBodyTemplate = (rowData: any) =>
    <UncontrolledMaskField value={rowData.date} inputStyle={{textAlign: 'center'}} onFieldChange={(value: any) => {
      updateFile({...rowData, ...{date: value}})

      if (value === null || (value !== undefined && !value.includes('_'))) {
        return updateFileEntity({...rowData, ...{date: value}})
      }
    }} isDate/>;

  const commentBodyTemplate = (rowData: any) =>
    <InputTextarea value={rowData.comment} onChange={(e) => {
      return updateFile({...rowData, ...{comment: e.target.value}})
    }} onBlur={(e) => {
      return updateFileEntity({...rowData, ...{comment: e.target.value}})
    }} autoResize rows={1}/>;

  const dateSortBodyTemplate = () => <></>;

  const updateSelection = (s: any[]) => {
    company.selectedFiles = s.map((sf: any) => sf.id);
    updateCompany(formState, company);
  };

  const isSelectable = (data: any) => data.status !== 'uploading';
  const isRowSelectable = (event: any) => (event.data ? isSelectable(event.data) : true);
  const rowClassName = (data: any) => (isSelectable(data) ? 'un-highlight' : 'un-highlight p-disabled');

  const onPreviewClick = (doc: any) => {
    if (doc?.status === 'unloaded') {
      apim.call({
        resourceType: 'documents',
        action: 'load',
        id: doc.id,
        method: 'get',
        notif: false,
        success: (res: AxiosResponse) => {
          if (!res?.data) return;

          doc.status = 'processed';
          company.files = company.files.filter((f: any) => f.id !== res.data.id);
          doc.url = res.data.url;
          doc.mime = res.data.mime;
          doc.extension = res.data.extension;
          doc.size = res.data.size;
          company.files.push(doc);

          updateCompany(formState, company);
          setDocumentPreview({...doc, ...{uri: doc.url}});
        }
      } as IRequestParams).then();
    } else {
      setDocumentPreview({...doc, ...{uri: doc.url}});
    }

    setModalVisible(true);
  };

  return (
    <div className={'col-12'}>
      <div className={'card'}>
        <DataTable
          ref={dt} className={'datatable-responsive'} dataKey={'id'} stripedRows
          value={company.files} rows={10} paginator loading={formState.isLoading(formKey)}
          emptyMessage={trans(t, 'file_none')} sortField={'dateSort'} sortOrder={-1}
          selectionMode={'checkbox'} selection={selected} onSelectionChange={(e: any) => updateSelection(e.value)}
          isDataSelectable={isRowSelectable} rowClassName={rowClassName}
        >
          <Column selectionMode={'multiple'} headerStyle={{ width: '3rem' }} />
          <Column field={'dateSort'} body={dateSortBodyTemplate} style={{ width: '0px' }} />
          <Column field={'name'} header={trans(t, 'file')} body={labelBodyTemplate} />
          <Column field={'source'} header={'Provenance'} body={sourceBodyTemplate} alignHeader={'center'} bodyClassName={'text-center'} style={{ width: '100px', minWidth: '100px' }} />
          <Column field={'type'} header={trans(t, 'type') + ' *'} body={fileTypeBodyTemplate} style={{ maxWidth: '300px', width: '300px' }} />
          <Column field={'date'} header={trans(t, 'date') + ' *'} body={dateBodyTemplate} align={'center'} alignHeader={'center'} style={{ width: '120px', minWidth: '120px' }} />
          <Column field={'comment'} header={trans(t, 'comment')} body={commentBodyTemplate} style={{ width: '300px' }} />
        </DataTable>
      </div>

      <Dialog content={''} ref={modalRef} header={ucfirst(trans(t, 'file_preview'))} visible={modalVisible} style={{ width: '80vw', height: '100%' }} onHide={() => setModalVisible(false)}>
        <DocumentViewer documentPreview={documentPreview} viewerRef={viewerRef}/>
      </Dialog>
    </div>
  );
};
