import React from 'react';
import { classNames } from 'primereact/utils';

export const SchemaMenuLabelRow = (props: any) => {
  const defaultClasses: string = 'uppercase';
  const pos = [...props.pos || [], ...[18, 36]];

  return (
    <tspan className={classNames('menu-item', props.classNames || defaultClasses)} x={pos[0]} y={pos[1]}>{props.label}</tspan>
  );
};
