import React from 'react';
import { Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { classNames } from 'primereact/utils';
import { Dropdown } from 'primereact/dropdown';
import { Skeleton } from 'primereact/skeleton';

import { trans } from 'utilities';

export const StaticListField = (props: any) => {
  const { listKey, fieldKey, control, onFieldChange, errorMessage, rules, listsOptions, loading,
    label, placeholder, description, filter, classes, optionLabel, optionValue, showClear, editable } = props;

  const { t } = useTranslation();
  // A field key can join multiple possibilities using '||' separator.
  const split = fieldKey.split('||');

  return (<div className={classNames('field', classes ?? 'col-12 md:col-6 lg:col-4')}>
    {loading ?
      <Skeleton height={'2.8rem'} /> :
      <Controller
        name={split[0]}
        control={control}
        rules={rules}
        render={({ field, fieldState }) => {

          return (
            <>
            <span className={'p-float-label'}>
              <Dropdown
                id={field.name} className={classNames('w-full', {'p-invalid': fieldState.error})} placeholder={placeholder ?? trans(t, 'form|select')}
                value={field?.value ? (field?.value[optionValue ?? 'value'] ?? field?.value) : field?.value}
                options={listKey ? (listsOptions ?? {})[listKey] : listsOptions} optionLabel={optionLabel ?? 'translated'} optionValue={optionValue ?? 'value'}
                onChange={(e) => onFieldChange(field, fieldState, e.value)} showClear={showClear} filter={filter} editable={editable}
              />
              {(label ?? field.name) && (
                <label className={'text-ucfirst p-float-label-label'} htmlFor={field.name}>
                  <span>{label ?? trans(t, field.name)}</span>
                </label>
              )}
            </span>
              {description && (<span className={'small'}>{description}</span>)}
              {errorMessage(field.name)}
            </>
          )
        }}
      />
    }
    </div>
  );
};
