import React, { useCallback, useEffect, useState } from 'react';
import { Column } from 'primereact/column';

import { IRequestParams, isAdmin } from 'services';
import { dialog, trans, ucfirst, triggerFormReset, triggerFormSubmit } from 'utilities';
import {
  DatatableWrapper,
  PageLoader,
  validCell,
  apiListEditor, maskEditor,
  handlePersonnePhysiqueAddress, handlePersonnePhysiqueRelatedEntity, onPersonnePhysiqueAddSubmit,
  birthDateBody, dateTimeCell, simpleCheckboxEditor, ssnBody, personCell
} from 'components';
import { FormWrapper } from 'forms';

import { forEach, omit, replace } from 'lodash';
import appUri from 'config/appUri.json';

export const PersonnePhysiqueChildren = (props: any) => {
  const { rows, setRows, tableKey, title, editMode, params, context, apim } = props;
  const { t, navigate } = apim.di();

  const [loading, setLoading] = useState<boolean>(false);
  const [adoptionRegimes, setAdoptionRegimes] = useState<any[]>([]);
  const [childStatuses, setChildStatuses] = useState<any[]>([]);

  // /!\ WARNING /!\ Let's keep 'personnesPhysiques' instead of 'children' as resourceType on purpose
  // because (believe it or not but) this way is simpler during edit process :)
  const resourceType = 'personnesPhysiques';

  useEffect(() => {
    apim.getList('adoptionRegimes', {setter: setAdoptionRegimes} as IRequestParams).then();
    apim.getList('childStatuses', {setter: setChildStatuses} as IRequestParams).then();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const supportedBodyTemplate = (rowData: any) => validCell(rowData?.supported);
  const adoptedBodyTemplate = (rowData: any) => validCell(rowData?.adopted);
  const adoptionDateBody = (rowData: any) => dateTimeCell(rowData?.adoptionDate, {format: 'DD/MM/YYYY'});
  const adoptionRegimeBodyTemplate = (rowData: any) => {
    const match: any = (adoptionRegimes || []).filter((m: any) => rowData?.adoptionRegime?.toLowerCase() === m?.value);

    return match.length > 0 ? match[0].translated : rowData?.adoptionRegime;
  };
  const statusBodyTemplate = (rowData: any) => {
    const match: any = (childStatuses || []).filter((m: any) => rowData?.status?.toLowerCase() === m?.value);

    return match.length > 0 ? match[0].translated : rowData?.status;
  };
  const activeBodyTemplate = (rowData: any) => validCell(rowData?.active);
  const fullNameBodyTemplate = (rowData: any) => personCell(rowData?.personnePhysique, {
    url: appUri.cie.per.phy.page.replace(':id', rowData?.personnePhysique?.id),
    label: trans(t, 'seeDetails')
  });

  const onRowEditCallback = (patched: any) => {
    if (!patched || !patched[resourceType] || !patched[resourceType].id) return;

    // Handle personnesPhysiquesData (personne_physique_datas).
    handlePersonnePhysiqueRelatedEntity(apim, context, 'personnesPhysiquesData', patched);

    // Handle personnesPhysiquesChildren (personne_physique_childs).
    handlePersonnePhysiqueRelatedEntity(apim, context, 'personnesPhysiquesChildren', patched, setLoading);

    // Handle address.
    handlePersonnePhysiqueAddress(patched[resourceType], apim);
  };

  const hookRowEdit = (_rows: any[], editedRow: any) => {
    if (!editedRow) return;

    const original: any = rows.filter((r: any) => r.id === editedRow.id)[0];
    const personnePhysique: any = editedRow.personnePhysique;
    let tmp: any = omit(editedRow, ['personnePhysique']);
    const patched: any = {
      personnesPhysiques: { id: original?.personnePhysique?.id },
      personnesPhysiquesData: { id: original?.personnePhysique?.latestVersion?.id },
      personnesPhysiquesChildren: { id: original?.id }
    };

    // Let's pick only patched data.
    // 1. "personne_physiques" fields.
    forEach(['lastName', 'firstName', 'birthDate', 'socialSecurityNumber', 'email', 'phone1'], ((field: string) => {
      if (undefined === tmp['personnePhysique.' + field] || tmp['personnePhysique.' + field] === original?.personnePhysique[field]) {
        tmp = omit(tmp, 'personnePhysique.' + field);

        return;
      }

      patched.personnesPhysiques[field] = tmp['personnePhysique.' + field];
      tmp = omit(tmp, 'personnePhysique.' + field);

      if ('lastName' === field || 'firstName' === field) {
        patched.personnesPhysiquesData[field] = patched.personnesPhysiques[field];
      }
    }));

    // 2. "personnesPhysiquesChildren" (root fields).
    forEach(['supported', 'adopted', 'adoptionDate', 'adoptionRegime', 'status', 'active'], ((field: string) => {
      if (undefined === tmp[field] || tmp[field] === original[field]) {
        tmp = omit(tmp, field);

        return;
      }

      patched.personnesPhysiquesChildren[field] = tmp[field];
      tmp = omit(tmp, field);
    }));

    // Then update rows using a proper rows array.
    // return formatPersonPhysiquePatched(_rows, personnePhysique, tmp, patched, 'personnesPhysiquesChildren');
    return { formattedRows: _rows, patched: patched, id: personnePhysique?.id };
  };

  const onRowClick = (row: any) => navigate(replace(appUri.cie.per.phy.page, ':id', row?.personnePhysique?.id || '_'));
  const onDelete = (row: any) => {
    if (row?.id) {
      apim.deleteEntity({
        resourceType: 'personnesPhysiquesChildren',
        id: row?.id
      } as IRequestParams).then();
    }
  };

  const onNew = () => {
    dialog(t, {
      header: trans(t,'form|dossier.personnePhysiqueChildAdd'),
      icon: 'none',
      message: renderAddForm(),
      accept: () => triggerFormSubmit('a8-form-company-ppc-add'),
      reject: () => triggerFormReset('a8-form-company-ppc-reset')
    });
  };

  // Handle submission then refresh table rows.
  const onAddSubmit = (formData: any) => onPersonnePhysiqueAddSubmit(apim, context, formData, rows, setRows, 'personnesPhysiquesChildren', setLoading);

  // Wrap form render into a useCallback to avoid multiple FormWrapper recalls du to form fields updates.
  const renderAddForm = useCallback(() =>
      <FormWrapper classes={'grid p-fluid w-12'} resourceType={resourceType} formKeyPrefix={'add_pp_dialog'} cancelLink multiple context={context}
                   resetClass={'a8-form-company-ppc-reset'} submitClass={'a8-form-company-ppc-add'} onSubmit={onAddSubmit} hideReload />
    , [context?.dossierId]); // eslint-disable-line react-hooks/exhaustive-deps

  return (adoptionRegimes || []).length === 0 ? (<PageLoader />) : (
    <DatatableWrapper resourceType={resourceType} tableKey={tableKey || 'personnes-physiques-enfants'} noFilters noGlobalFilter params={params} isLoading={loading}
                      addTitle={trans(t, 'table|add.child')} onRowClick={onRowClick} editMode={editMode || false} hookRowEdit={hookRowEdit} onRowEditCallback={onRowEditCallback}
                      rows={(rows || []).sort((a: any, b: any) => a.personnePhysique?.lastName?.localeCompare(b.personnePhysique?.lastName) || a.personnePhysique?.firstName?.localeCompare(b.personnePhysique?.firstName))}
                      onDelete={onDelete} onNew={onNew} title={title || ucfirst(trans(t, 'child', rows.length))} paginator={false}>
      <Column field={'personnePhysique.fullName'} header={trans(t, 'fullName')} body={fullNameBodyTemplate}
              style={{ minWidth: '250px', width: '250px' }} />
      <Column field={'personnePhysique.birthDate'} header={trans(t, 'birthDate')} align={'center'} alignHeader={'center'} body={birthDateBody}
              style={{ minWidth: '100px', width: '100px' }} />
      <Column field={'personnePhysique.socialSecurityNumber'} header={trans(t, 'socialSecurityNumber')} align={'center'} alignHeader={'center'} body={ssnBody} />
      <Column field={'supported'} header={trans(t, 'supportedChild')} body={supportedBodyTemplate} align={'center'} alignHeader={'center'}
              editor={(options) => simpleCheckboxEditor(options, {label: trans(t, 'supportedChild')})}
              style={{ minWidth: '120px', width: '120px' }} />
      <Column field={'adopted'} header={trans(t, 'adopted')} body={adoptedBodyTemplate} align={'center'} alignHeader={'center'}
              editor={(options) => simpleCheckboxEditor(options, {label: trans(t, 'adopted')})}
              style={{ minWidth: '120px', width: '120px' }} />
      <Column field={'adoptionDate'} header={trans(t, 'adoptionDate')} align={'center'} alignHeader={'center'} body={adoptionDateBody}
              editor={(options) => maskEditor(options, {label: trans(t, 'adoptionDate'), isDate: true})}
              style={{ minWidth: '150px', width: '150px' }} />
      <Column field={'adoptionRegime'} header={trans(t, 'adoptionRegime')} body={adoptionRegimeBodyTemplate} align={'center'} alignHeader={'center'}
              editor={(options) => apiListEditor(options, {label: trans(t, 'adoptionRegime'), listKey: 'adoptionRegimes', showClear: true})}
              style={{ minWidth: '200px', width: '200px' }} />
      <Column field={'status'} header={trans(t, 'status')} body={statusBodyTemplate} align={'center'} alignHeader={'center'}
              editor={(options) => apiListEditor(options, {label: trans(t, 'status'), listKey: 'childStatuses', showClear: true})}
              style={{ minWidth: '150px', width: '150px' }} />
      {isAdmin() && <Column field={'active'} header={trans(t, 'active')} body={activeBodyTemplate} align={'center'} alignHeader={'center'}
                            editor={(options) => simpleCheckboxEditor(options, {label: trans(t, 'table|active')})}
                            style={{ minWidth: '100px', width: '100px' }} />}
    </DatatableWrapper>
  );
};
