import React, { useCallback, useEffect, useState } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';
import { TFunction } from 'i18next';
import { TabMenu } from 'primereact/tabmenu';

import { IRequestParams, isClient, useApim } from 'services';
import { useAppState, useFormState, useUserState } from 'states';
import { PageLoader } from 'components';
import { Error, NotFound } from 'pages';
import { UserFormRouter } from 'forms';
import { addShortcut, isValidUUID, trans, ucfirst } from 'utilities';
import { User } from './User';

import { includes, forEach, replace } from 'lodash';
import appUri from 'config/appUri.json';

export const UserRouter = (props: any) => {
  const { action } = props;
  const { id, tab } = useParams();

  const apim = useApim();
  const { t, navigate } = apim.di();
  const appState = useAppState();
  const formState = useFormState();
  const userState = useUserState();

  const [loading, setLoading] = useState<boolean>(false);
  const [data, setData] = useState<any>();
  const [notFound, setNotFound] = useState<boolean>(false);
  const [errored, setErrored] = useState<boolean>(false);

  // Let's detect if we are in "edit mode"
  // then render the appropriate display (edit form or module dashboard).
  const editMode = appUri.reservedKeywords.edit === action;

  // Handle tabs.
  const [activeIndex, setActiveIndex] = useState<number>(0);
  const baseUri = replace(appUri.usr.page, ':id', id || '_');
  const tabs: any[] = [];
  if (id) {
    tabs.push({label: trans(t, 'menu|tab.general'), command: () => navigate(baseUri)});

    forEach(appUri.usr.tabs, (ta) => {
      tabs.push({label: trans(t, 'menu|tab.' + ta), command: () => navigate(baseUri + '/' + ta)});
    });
  }

  // Validate URL & build tabs.
  const checks = useCallback(() => {
    return isUrlValid(t, id, tab);
  }, [id, tab]); // eslint-disable-line react-hooks/exhaustive-deps
  useEffect(() => {
    if (!checks()) setNotFound(true);

    let ai = 0;
    forEach(appUri.usr.tabs, (ta, index) => {
      if (tab === ta) ai = index + 1;
    });

    if (ai !== activeIndex) setActiveIndex(ai);
  }, [id, tab]); // eslint-disable-line react-hooks/exhaustive-deps

  // Let's detect if API calls from now have to be refreshed (cache expiration).
  const [searchParams] = useSearchParams();
  const lastUpdate = searchParams.get('maj');
  const fetchData = useCallback(() => {
    if (!id || editMode) return;
    setLoading(true);

    apim.fetchEntity({
      resourceType: 'users',
      id: id,
      cache: false,
      setLoading: setLoading,
      setter: setData,
      setErrored: setErrored,
      setNotFound: setNotFound
    } as IRequestParams).then();
  }, [id, lastUpdate]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (!data || !id || (data.id !== id) || lastUpdate) {
      fetchData();
    } else {
      setLoading(false);
    }
  }, [id, lastUpdate]); // eslint-disable-line react-hooks/exhaustive-deps

  // Update Page Header.
  useEffect(() => {
    let pageTitle: string = ucfirst(trans(t, 'user')) + ' ' + (data ? data.firstName + ' ' + data.lastName : '');
    if (editMode) {
      pageTitle += ' | ' + trans(t, 'edit');
      appState.setPageActions([]);
    } else {
      const newPA: any[] = [{ label: ucfirst(trans(t, 'usr.edit')), icon: 'pencil', to: replace(appUri.usr.edit, ':id', id || '_') }];
      if (!isClient()) {
        newPA.push({ label: ucfirst(trans(t, 'short.add')), icon: 'bookmark', command: () => addShortcut(t, formState, appState) });
      }
      appState.setPageActions(newPA);
    }
    appState.setPageTitle(pageTitle);
    appState.setBreadcrumb([{ label: data ? data.firstName + ' ' + data.lastName : trans(t, 'user') }]);
  }, [data, lastUpdate, userState.id()]); // eslint-disable-line react-hooks/exhaustive-deps

  const buildTabContent = useCallback(() => {
    switch (activeIndex) {
      default:
        // Profile.
        return <User t={t} data={data} />;
    }
  }, [id, tab, activeIndex, data]); // eslint-disable-line react-hooks/exhaustive-deps

  if (editMode) return <UserFormRouter data={data} apim={apim}/>;
  if (notFound) return <NotFound asBlock/>;
  if (errored) return <Error asBlock/>;
  if (loading) return <PageLoader/>;

  return (
    <div className={'card height-100 fadein animation-duration-500 flex flex-column'}>
      <div className={'a8-page-header flex flex-wrap'}>
        <div className={'flex-auto flex p-2 justify-content-end pt-0'}>
          {tabs.length > 1 && <TabMenu className={'pb-2'} model={tabs} activeIndex={activeIndex} onTabChange={(e) => setActiveIndex(e.index)}/>}
        </div>
      </div>
      {buildTabContent()}
    </div>
  );
};

const isUrlValid = (t: TFunction, id: string | undefined, tab: string | undefined) => {
  // Ensure entity UUID.
  return isValidUUID(id) && includes([...[undefined], ...appUri.usr.exp.tabs], tab);
};
