import React from 'react';
import appUri from 'config/appUri.json';

import { DocumentRouter } from 'pages';

import { trimStart } from 'lodash';

export const RoutesDocument = () => {
  return [
    {
      path: trimStart(appUri.doc.page),
      element: <DocumentRouter />,
    },
    {
      path: trimStart(appUri.doc.page) + '/:tab?',
      element: <DocumentRouter />
    },
  ];
};
