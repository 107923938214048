import React from 'react';
import { useTranslation } from 'react-i18next';
import { classNames } from 'primereact/utils';
import { shortNumber, trans, ucfirst } from 'utilities';

export const ContactField = (props: any) => {
  const { t } = useTranslation();
  const { text, title, percent, emptyValue } = props;

  let displayValue: any = '';
  if (text !== undefined && text !== null) {
    // Display given text.
    displayValue = text.split('\n').map((item: string, index: number) => {
      return (index === 0) ? item : [<br key={index} />, item]
    });
  }

  // Check if the displayed value is empty.
  const isEmptyValue = (null === displayValue || displayValue.includes(undefined) || '' === displayValue);
  return (
    <div>
      <div className={'flex flex-row align-items-end'}>
        {title === trans(t, 'email') &&
          <img src='/assets/images/avatar/mail.png' alt={trans(t, 'email')} style={{height: '1.6em'}} className={'mr-2'} />
        }
        {title.includes(trans(t, 'phone')) &&
          <img src='/assets/images/avatar/phone.png' alt={trans(t, 'phone')} style={{height: '1.6em'}} className={'mr-2'} />
        }
        <strong className={'titre'} style={{color: '#5B6276'}}>{ucfirst(title)}</strong>
      </div>

      <div className={'informations flex flex-row'}>
        <div className={classNames('flex flex-1 valeur pt-2')}>
          {!isEmptyValue && title === trans(t, 'email') ? (
            <a href={`mailto:${displayValue}`} className={'link-email'}>{displayValue}</a>
          ) : !isEmptyValue && title.includes(trans(t, 'phone')) ? (
            <a href={`tel:${displayValue}`} className={'link-phone'}>{displayValue}</a>
          ) : (
            <span>{isEmptyValue ? (emptyValue || 'NR') : displayValue}</span>
          )}
        </div>
        {percent && (
          <div className={'pourcent-conteneur'}>
            <span className={'pourcent'}>{shortNumber(percent, {symbol: '%', precision: 1})}</span>
          </div>
        )}
      </div>
    </div>
  );
};
