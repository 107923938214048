import { trans, ucfirst } from 'utilities';
import appUri from 'config/appUri.json';

export const getMissionContext = (t: any, appState: any, context: any) => {
  const { id, type, step, hypotheseId, missionId } = context;
  const dossier: any = appState.dossier();

  let activeIndex: number = -1;
  const urls: any = {
    formBase: appUri.dos.edit.missions.uri
      .replace(':id', id || '_')
      .replace(':module', 'mission'),

    form: appUri.dos.edit.missions.uri
      .replace(':id', id || '_')
      .replace(':module', 'missions')
      .replace(':type', type),

    landing: appUri.dos.page.replace(':id', id || '_'),
  };

  const defaultBC = [
    { label: trans(t, 'dossier', 2), to: appUri.dos.list },
    { label: dossier?.title, to: appUri.dos.page.replace(':id', dossier?.id) },
    { label: trans(t, 'editing') },
    { label: ucfirst(trans(t, type)), to: appUri.dos.edit.missions.select.replace(':id', dossier?.id)+ '/new' },
  ];

  if (!hypotheseId || !step) {
    activeIndex = 0;
    defaultBC.push({
      label: trans(t, 'menu|wizard.dossier.missions.selection'),
      to: urls.form,
    });
  } else {
        switch (step) {
          case 'informations-generales':
            activeIndex = 1;
            defaultBC.push({
              label: trans(t, `menu|wizard.dossier.missions.${type}.step1`),
              to: `${urls?.form}/${missionId}/hypothese/${hypotheseId}${(appUri.dos.edit.missions.steps as Record<string, any>)[type]['step1']}`,
            });
            break;
          case 'dates-depart':
            activeIndex = 2;
            defaultBC.push({
              label: trans(t, `menu|wizard.dossier.missions.${type}.step2`),
              to: `${urls?.form}/${missionId}/hypothese/${hypotheseId}${(appUri.dos.edit.missions.steps as Record<string, any>)[type]['step2']}`,
            });
            break;
          case 'contrats-facultatifs':
            activeIndex = 3;
            defaultBC.push({
              label: trans(t, `menu|wizard.dossier.missions.${type}.step3`),
              to: `${urls?.form}/${missionId}/hypothese/${hypotheseId}${(appUri.dos.edit.missions.steps as Record<string, any>)[type]['step3']}`,
            });
            break;
        }
  }

  return { urls, defaultBC, index: activeIndex };
};
