import React, { useEffect, useState } from 'react';
import { AxiosResponse } from 'axios';
import { Button } from 'primereact/button';
import { Card } from 'primereact/card';

import { asDate, dateAgoString, dateString, trans, ucfirst } from 'utilities';
import { IRequestParams } from 'services';
import { PageLoader } from 'components';

export const Step0 = (props: any) => {
  const { formState, formKey, next, apim, user } = props;
  const { t, navigate} = apim.di();

  // Load user dossier creation.
  // We must display the list so that user can continue one which was not finished.
  const [dossierCreations, setDossierCreations] = useState<any[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  useEffect(() => {
    apim.call({
      resourceType: 'dossierCreations',
      method: 'GET',
      notif: false,
      cache: false,
      setLoading,
      success: (res: AxiosResponse) => {
        if (res.data['hydra:member'].length > 0) {
          // Display the entities.
          setDossierCreations(res.data['hydra:member']);
        } else {
          // Directly go to the next step.
          navigate(next);
        }
      },
    } as IRequestParams).then();
  }, [user]); // eslint-disable-line react-hooks/exhaustive-deps

  const deleteDossierCreation = (dossierCreation: any) => {
    apim.deleteEntity({
      resourceType: 'dossierCreation',
      id: dossierCreation.id,
      notif: true,
      success: () => {
        const _dossierCreations: any[] = [];
        dossierCreations.forEach((dc) => {
          if (dc.id !== dossierCreation.id) {
            _dossierCreations.push(dc);
          }
        });

        if (_dossierCreations.length === 0) {
          newDossierCreation();
        }

        setDossierCreations(_dossierCreations);
      }
    } as IRequestParams).then();
  };

  const newDossierCreation = () => {
    formState.resetFormData(formKey);
    navigate(next);
  };

  const editDossierCreation = (dossierCreation: any) => {
    formState.setFieldData(formKey, 'dossierCreationId', null, dossierCreation.id);
    formState.setFieldData(formKey, 'firstName', 'step1', dossierCreation.firstName);
    formState.setFieldData(formKey, 'lastName', 'step1', dossierCreation.lastName);
    formState.setFieldData(formKey, 'birthDate', 'step1', dossierCreation.birthDate);
    formState.setFieldData(formKey, 'unknownEntrepreneur', 'step1', dossierCreation.unknownEntrepreneur);
    formState.setFieldData(formKey, 'currentCompany', null, null);
    formState.setFieldData(formKey, 'companies', null, dossierCreation.companies);
    formState.setFieldData(formKey, 'selected', 'step2', dossierCreation.companies);
    navigate(next);
  };

  const createDossierCreation = () => {
    formState.resetFormData(formKey);
    formState.setFieldData(formKey, 'dossierCreationId', null, null);
    formState.setFieldData(formKey, 'firstName', 'step1', null);
    formState.setFieldData(formKey, 'lastName', 'step1', null);
    formState.setFieldData(formKey, 'birthDate', 'step1', null);
    formState.setFieldData(formKey, 'unknownEntrepreneur', 'step1', null);
    formState.setFieldData(formKey, 'currentCompany', null, null);
    formState.setFieldData(formKey, 'companies', null, []);
    formState.setFieldData(formKey, 'selected', 'step2', []);
    navigate(next);
  };

  return (
    <div className={'a8-wizard wizard-dossier card fadein animation-duration-500'}>
      <h5 className={'mx-2 my-3'}>{trans(t, 'menu|wizard.dossier.title.step0')}</h5>
      <p className={'mx-2 my-3'}><i className={'pi pi-info-circle mr-2 mt-1 text-primary'}></i> {trans(t, 'menu|wizard.dossier.title.step0Help')}</p>

      {loading ? (
        <>
          <PageLoader/>
        </>
      ) : (
        <>
          <div className={'flex flex-row flex-wrap m-3 mt-5 mb-5'}>
            {dossierCreations.map((dossierCreation: any) => {
              let name = '';
              let title = '';
              if (dossierCreation.firstName !== null && dossierCreation.firstName !== undefined
                && dossierCreation.lastName !== null && dossierCreation.lastName !== undefined) {
                title += dossierCreation.firstName + ' ' + dossierCreation.lastName;
                name += dossierCreation.firstName + ' ' + dossierCreation.lastName;
              }

              if (dossierCreation.birthDate !== null && dossierCreation.birthDate !== undefined) {
                title += ' (' + dateString(asDate(dossierCreation.birthDate)) + ')';
              }

              return <div key={dossierCreation.id} className={'w-3'} style={{maxWidth: '25%'}}>
                <Card title={name} className={'m-2'}>
                  <p className={'mb-0'}>
                    {ucfirst(trans(t, 'progress'))} : {ucfirst(trans(t, 'step'))} {dossierCreation.step}<br/>
                    {ucfirst(trans(t, 'dirigeant'))} : {title}<br/>
                    {dossierCreation.companies?.length > 0 && (
                      <>
                        {ucfirst(trans(t, 'company_plural')) + ' : ' + dossierCreation.companies?.length + ' ' + trans(t, 'added_companies')}<br/>
                      </>
                    )}
                    {dossierCreation.documents?.length > 0 && (
                      <>
                        {ucfirst(trans(t, 'document_plural')) + ' : ' + dossierCreation.documents?.length + ' ' + trans(t, 'added_documents')}<br/>
                      </>
                    )}
                    {ucfirst(trans(t, 'created'))} : {dateAgoString(asDate(dossierCreation.created))} ({dateString(asDate(dossierCreation.created))})<br/>
                    {ucfirst(trans(t, 'updated'))} : {dateAgoString(asDate(dossierCreation.updated))} ({dateString(asDate(dossierCreation.updated))})<br/>
                  </p>
                  <div className={'flex flex-row gap-2 mt-3'}>
                    <Button type={'button'} size={'small'} severity={'warning'} className={'text-ucfirst'} icon={'pi pi-times'} label={trans(t, 'abandon')} onClick={() => {
                      deleteDossierCreation(dossierCreation);
                    }}/>
                    <Button type={'submit'} size={'small'} className={'text-ucfirst'} icon={'pi pi-check'} label={trans(t, 'resume')} onClick={() => {
                      editDossierCreation(dossierCreation);
                    }}/>
                  </div>
                </Card>
              </div>
            })}
          </div>

          <div className={'text-right m-2'}>
            <Button className={'text-ucfirst p-button-text mr-5'} label={trans(t, 'continue')} onClick={() => createDossierCreation()}/>
          </div>
        </>
      )}
    </div>
  );
};
