import React from 'react';
import { useTranslation } from 'react-i18next';
import { trans } from 'utilities';

export const AppFooter = () => {
  const { t } = useTranslation();

  return (
    <div className='layout-footer text-center shadow-2 text-gray-600 w-full'>
      <div className='p-2'>&copy; {(new Date().getFullYear())} ATOME 8 / <span className={'a8-version'}>v{process.env.REACT_APP_VERSION}</span> / <a href={'https://atome8.statuspage.io/'} target={'_blank'} rel={'noreferrer'}>{trans(t, 'system|page_status')}</a></div>
    </div>
  );
};
