import React, { useCallback, useEffect, useState } from 'react';
import { Button } from 'primereact/button';
import { AxiosResponse } from 'axios';
import { useSearchParams } from 'react-router-dom';

import { Dropdown } from 'primereact/dropdown';
import { Tooltip } from 'primereact/tooltip';

import { FormWrapper } from 'forms';
import { dateString, dialog, flatPrevoyanceContract, iri, isValidUUID, trans, triggerFormReset, triggerFormSubmit, ucfirst } from 'utilities';
import { IRequestParams } from 'services';
import { PageLoader } from 'components';
import { Error, NotFound } from 'pages';

import { omit } from 'lodash';
import appUri from 'config/appUri.json';

export const Contexte = (props: any) => {
  const { apim, appState, urls, data, setData } = props;
  const { t, navigate } = apim.di();
  const cancelUri: string = urls?.landing + '/prevoyance/contrats';

  const [contracts, setContracts] = useState<any[]>([]);
  const [versions, setVersions] = useState<any[]>([]);

  const [insuranceCompanies, setInsuranceCompanies] = useState<any[]>([]);

  const [loading, setLoading] = useState<boolean>(false);
  const [notFound, setNotFound] = useState<boolean>(false);
  const [errored, setErrored] = useState<boolean>(false);

  const [searchParams] = useSearchParams();
  const lastUpdate = searchParams.get('maj');

  useEffect(() => {
    if (!appState.dossier()?.id) return;

    // Fetch dossier contracts.
    apim.fetchEntities({
      resourceType: 'prevoyanceContracts',
      params: [{ label: 'dossier', value: appState.dossier()?.id }],
      cache: false,
      setErrored,
      setNotFound,
      setLoading,
      setter: setContracts
    } as IRequestParams).then();
  }, [appState.dossier()?.id]); // eslint-disable-line react-hooks/exhaustive-deps

  // Fetch contract versions.
  useEffect(() => {
    if (!isValidUUID(data?.id)) return;

    apim.fetchEntities({
      resourceType: 'prevoyanceContractsData',
      params: [{ label: 'contract', value: data?.id }],
      cache: false,
      setter: setVersions
    } as IRequestParams).then();
  }, [data?.id]); // eslint-disable-line react-hooks/exhaustive-deps

  // Load the list of insurance companies.
  useEffect(() => {
    // setLoading(true);
    apim.fetchEntities({
      resourceType: 'insuranceCompanies',
      params: [{ label: 'order[name]', value: 'asc' }, { label: 'limit', value: 250 }],
      setter: setInsuranceCompanies,
      setErrored,
      setNotFound
    } as IRequestParams).then();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const onSubmit = (submitted: any) => {
    if (!submitted) return;

    setLoading(true);
    const handleContract = (_data: any) => {
      if (isValidUUID(data?.id)) {
        if (Object.keys(_data).length) {
          // Save changes.
          apim.patchEntity({
            resourceType: 'prevoyanceContracts',
            id: data?.id,
            data: _data,
            setErrored,
            notif: false,
            success: (res: AxiosResponse) => {
              if (!res?.data?.id) {
                setLoading(false);
                return setErrored(true);
              }

              setData({...data, ..._data});
              return navigate(urls?.formBase.replace(':version', data?.data?.id) + appUri.dos.edit.contracts.prevoyance.step21);
            }
          } as IRequestParams).then();
        } else {
          // Simply navigate to next step.
          return navigate(urls?.formBase.replace(':version', data?.data?.id) + appUri.dos.edit.contracts.prevoyance.step21);
        }
      } else {
        apim.postEntity({
          resourceType: 'prevoyanceContracts',
          data: _data,
          setErrored,
          notif: false,
          success: (res: AxiosResponse) => {
            if (!res?.data?.id) {
              setLoading(false);
              setErrored(true);
              return;
            }

            // Associate an empty PrevoyanceContractsData.
            apim.postEntity({
              resourceType: 'prevoyanceContractsData',
              data: { contract: iri('prevoyanceContracts', res.data.id), date: new Date().toISOString(), active: true },
              setErrored,
              notif: false,
              success: (_res: AxiosResponse) => {
                if (!_res?.data?.id) return setData(flatPrevoyanceContract(res.data, null));

                setData(flatPrevoyanceContract(res.data, _res.data));
                return navigate(urls?.formBase.replace(':version', _res.data.id) + appUri.dos.edit.contracts.prevoyance.step21);
              }
            } as IRequestParams).then();
          }
        } as IRequestParams).then();
      }
    };

    // Handle new insurance company.
    if (submitted.insuranceCompany && !isValidUUID(submitted.insuranceCompany)) {
      apim.postEntity({
        resourceType: 'insuranceCompanies',
        data: { name: submitted.insuranceCompany, active: true },
        setErrored,
        notif: false,
        success: (res: AxiosResponse) => {
          if (!res?.data?.id) return handleContract(omit(submitted, ['insuranceCompany']));

          setInsuranceCompanies([...insuranceCompanies, ...[res.data]]);
          return handleContract({...omit(submitted, ['insuranceCompany']), ...{insuranceCompany: res.data.id}});
        }
      } as IRequestParams).then();
    } else {
      return handleContract(submitted);
    }
  };

  const switchContract = (contractId: string | null) => {
    const match: any[] = (contracts ?? []).filter((c: any) => c.id === contractId);

    // Make sure we have one match.
    if (match.length < 1) {
      // return window.location.replace(urls?.formBase.replace(':version', match.length < 1 ? trans(t, 'new') : match[0].versions[0].id) + '?maj=' + Date.now());
      triggerFormReset(null);
      setData(null);
    } else {
      // Make sure we know the latest version.
      if (match[0].latestVersion === null || match[0].latestVersion === undefined) {
        triggerFormReset(null);
        setData(null);
      }
    }

    navigate(urls?.formBase.replace(':version', match.length < 1 ? trans(t, 'new') : match[0].latestVersion.id) + '?maj=' + Date.now());
  };

  const switchVersion = (id: string | null) => {
    const match: any[] = (data?.versions ?? []).filter((v: any) => v.id !== id).sort((a: any, b: any) => a?.date > b?.date ? -1 : 1);

    if (match.length > 0) {
      navigate(urls?.formBase.replace(':version', match[0].id) + '?maj=' + Date.now());
    }
  };

  // Wrap form render into a useCallback to avoid multiple FormWrapper recalls du to form fields updates.
  const renderForm = useCallback(() =>
      <>
        <h6 className={'mx-3 mt-5 mb-6 flex flex-row align-items-center'}>{ucfirst(trans(t, 'contract_prevoyance'))}</h6>
        <FormWrapper classes={'grid'} formKeyPrefix={'contrat_contexte'} resourceType={'prevoyanceContracts'} onSubmit={onSubmit}
                     data={data} additionalData={data?.data?.id ? {} : { dossier: iri('dossiers', appState.dossier()?.id) }}
                     hideReload cancelLink multiple listsOptions={{ insuranceCompanies: insuranceCompanies }}/>
      </>
    , [data, insuranceCompanies, lastUpdate]); // eslint-disable-line react-hooks/exhaustive-deps

  if (notFound) return <NotFound asBlock/>;
  if (errored) return <Error asBlock/>;
  if (loading) return <PageLoader/>;

  return (
    <div className={'a8-wizard wizard-contrat-prevoyance fadein animation-duration-500'}>
      <h5 className={'mx-3 pt-3 flex flex-row align-items-center'}>
        <span className={'mr-2 border-circle bg-primary flex align-items-center justify-content-center'} style={{width: '2rem', height: '2rem'}}><i className={'pi pi-info'}></i></span>
        {trans(t, 'form|title.contract.prevoyance.step1')}
      </h5>

      <div className={'a8-form contrat-prevoyance-context pt-3'}>
        <div className={'flex-none flex p-2 xl:w-4'}>
          <div className={'field p-inputgroup'}>
            <span className={'p-float-label'}>
              <Dropdown id={'a8-contract-select'} value={data?.id ?? 'new'} options={[...[{id: 'new', name: trans(t, 'form|contract.new')}], ...contracts]} optionLabel={'name'} optionValue={'id'}
                        className={'border-round'}
                        onChange={(e) => switchContract(e.value)}
                        placeholder={ucfirst(trans(t, 'form|contract.select'))} />
              <label htmlFor={'a8-contract-select'} className={'text-ucfirst'} >{trans(t, 'form|contract.select')}</label>
            </span>
          </div>

          <span className={'pl-4'}>
            <Button disabled={!data?.id || (contracts ?? []).length < 2} className={'a8-pc-delete'} icon={'pi pi-trash'} rounded outlined severity={'danger'} aria-label={trans(t, 'delete')}
                    onClick={() => dialog(t, {
                      message: ucfirst(trans(t, 'system|confirmations.dossier.delete-prevoyance-contract')),
                      icon: 'pi pi-trash',
                      accept: () => {
                        apim.deleteEntity({
                          resourceType: 'prevoyanceContracts',
                          id: data?.id,
                          success: () => switchContract(null)
                        } as IRequestParams);
                      }
                    })}
            />
            <Tooltip target={'.a8-pc-delete'} position={'top'} content={ucfirst(trans(t, 'system|actions.delete-contract'))} />
          </span>
        </div>

        {data?.id && (
          <div className={'flex-none flex p-2 xl:w-4'}>
            <div className={'field p-inputgroup'}>
            <span className={'p-float-label'}>
              <Dropdown id={'a8-contract-version-select'} value={data?.data?.id ?? 'new'} options={[...[{id: 'new', label: trans(t, 'form|contract.new_version')}], ...versions.map((v: any) => ({...v, ...{ label: dateString(v.date, 'DD/MM/YYYY HH:mm') }}))]}
                        className={'border-round'} placeholder={ucfirst(trans(t, 'form|contract.select'))} optionLabel={'label'} optionValue={'id'}
                        onChange={(e) => {
                          if (isValidUUID(e.value)) return navigate(urls?.formBase.replace(':version', e.value) + '?maj=' + Date.now());

                          setLoading(true);
                          apim.postEntity({
                            resourceType: 'prevoyanceContractsData',
                            data: { dossier: iri('dossiers', appState.dossier()?.id), contract: iri('prevoyanceContracts', data.id), date: new Date().toISOString(), active: true },
                            setLoading,
                            setErrored,
                            notif: false,
                            success: (res: AxiosResponse) => {
                              if (!res?.data?.id) return setData(flatPrevoyanceContract(omit(data, ['data']), null));

                              return navigate(urls?.formBase.replace(':version', res.data.id));
                            }
                          } as IRequestParams).then();
                        }} />
              <label htmlFor={'a8-contract-version-select'} className={'text-ucfirst'} >{trans(t, 'form|contract.select_version')}</label>
            </span>
            </div>

            <span className={'pl-4'}>
              <Button disabled={!data?.data?.id || (versions ?? []).length < 2} className={'a8-pcd-delete'} icon={'pi pi-trash'} rounded outlined severity={'danger'} aria-label={trans(t, 'delete')}
                      onClick={() => dialog(t, {
                        message: ucfirst(trans(t, 'system|confirmations.dossier.delete-prevoyance-contract-version')),
                        icon: 'pi pi-trash',
                        accept: () => {
                          apim.deleteEntity({
                            resourceType: 'prevoyanceContractsData',
                            id: data?.data?.id,
                            success: () => switchVersion(data?.data?.id),
                          } as IRequestParams);
                        }
                      })}
              />
              <Tooltip target={'.a8-pcd-delete'} position={'top'} content={ucfirst(trans(t, 'system|actions.delete-version'))} />
            </span>
          </div>
        )}

        {renderForm()}

        <div className={'text-right m-2 pt-4'}>
          <>
            <Button type={'reset'} className={'text-ucfirst p-button-text p-button-danger mr-5'} label={trans(t, 'close')} onClick={() => navigate(cancelUri)} />
            <Button type={'submit'} className={'text-ucfirst'} label={trans(t, 'save')} icon={'pi pi-arrow-right'} iconPos={'right'} onClick={() => triggerFormSubmit(null)} />
          </>
        </div>
      </div>
    </div>
  );
};
