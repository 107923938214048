import React from 'react';
import { isValidUUID, trans, ucfirst, uuidFromIri } from 'utilities';
import { APIAutocompleteField, APIListField, CheckboxField } from 'forms';

export const RetraiteSelectionForm = (props: any) => {
  const { t, control, onFieldChange, errorMessage, getValues, context, globalDisabled } = props;
  const dossier = isValidUUID(context?.dossier?.id) ? context?.dossier?.id : uuidFromIri(context?.dossier);

  const itemTemplate = (option: any, props: any) => {
    if (option) {
      return (
        <div className={'flex align-items-center'}>
          <div>{option.label}</div>
          {option.family && (
             <small className={'mx-2'}>({option.family})</small>
          )}
        </div>
      );
    }

    return <span>{props.placeholder}</span>;
  };

  return <div className={'grid w-full px-3'}>
    <div className={'col-12 pt-5'}>
      <APIListField listKey={'contractRetraiteTypes'} fieldKey={'type'} control={control} onFieldChange={onFieldChange} errorMessage={errorMessage}
                    label={trans(t, 'form|patrimoine.contracts.default.type')} rules={{required: trans(t, 'form|requiredField')}}/>
      <CheckboxField fieldKey={'alreadyExists'} control={control} onFieldChange={onFieldChange} errorMessage={errorMessage} classes={'mb-0'}
                     label={trans(t, 'form|patrimoine.contracts.default.alreadyExists')} disabled={globalDisabled} help={trans(t, 'form|patrimoine.contracts.default.alreadyExistsHelp')}/>
    </div>

    {getValues()['alreadyExists'] === true && (
      <div className={'col-12'}>
        <APIAutocompleteField fieldKey={'existing'} control={control} onFieldChange={onFieldChange} errorMessage={errorMessage} label={trans(t, 'contract')}
                              getValues={getValues} placeholder={ucfirst(trans(t, 'form|select'))} optionKey={'label'} itemTemplate={itemTemplate}
                              resourceType={'contractsRetraite'} params={[{label: 'byDossierRelatives', value: dossier}]} disabled={globalDisabled}/>
      </div>
    )}
  </div>;
};
