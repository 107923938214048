import React from 'react';
import { AxiosResponse } from 'axios';
import { Column } from 'primereact/column';

import { iri, isValidUUID, montant, trans } from 'utilities';
import { amountEditor, DatatableWrapper, numberEditor, PageLoader } from 'components';
import { IRequestParams } from 'services';

export const PrevoyanceIndemnitesJournalieresDatatable = (props: any) => {
  const { title, context, apim } = props;
  const { t } = apim.di();
  const resourceType: string = 'prevoyanceDailyIndemnities';
  const lazyC = { sortField: 'startDay', sortOrder: 1 };

  const onNewRow = (_rows: any[], callback: any) => {
    if (_rows.length === 0) {
      if (isValidUUID(context?.id)) {
        apim.postEntity({
          resourceType: resourceType,
          data: {
            startDay: 0,
            endDay: 31,
            amount: 100,
            prevoyanceObligatoire: iri('prevoyancesObligatoires', context?.id ?? '_')
          },
          success: (res: AxiosResponse) => callback([res.data])
        } as IRequestParams).then();
      }

      return;
    }

    if (isValidUUID(context?.id)) {
      apim.postEntity({
        resourceType: resourceType,
        data: {
          startDay: parseInt(_rows[_rows.length - 1].endDay),
          endDay: parseInt(_rows[_rows.length - 1].endDay) + 365,
          amount: _rows[_rows.length - 1].amount,
          prevoyanceObligatoire: iri('prevoyancesObligatoires', context?.id ?? '_')
        },
        success: (res: AxiosResponse) => callback([..._rows, ...[res.data]])
      } as IRequestParams).then();
    }
  };

  const amountBody = (rowData: any) => <span>{rowData?.amount ? montant(rowData?.amount) : 'N/A'}</span>;
  const startBody = (rowData: any) => {
    if (!rowData?.startDay) return '0 ' + trans(t, 'day', 2);
    return <span>{rowData?.startDay + ' ' + trans(t, 'day', rowData?.startDay && rowData?.startDay > 0 ? rowData?.startDay : 1)}</span>;
  };
  const endBody = (rowData: any) => {
    if (!rowData?.endDay) return 'N/A';

    return <span>{rowData?.endDay + ' ' + trans(t, 'day', rowData?.endDay ?? 1)}</span>;
  };

  if (!isValidUUID(context?.id)) {
    return <PageLoader />;
  }

  return (
    <DatatableWrapper
      resourceType={resourceType} tableKey={'prevoyance-tableau-indemnites'} noFilters noGlobalFilter onNew={onNewRow}
      additionalData={{ prevoyanceObligatoire: iri('prevoyancesObligatoires', context?.id) }}
      params={[{label: 'prevoyanceObligatoire', value: context?.id}]} lazyConfig={lazyC}
      title={title ?? trans(t, 'table|prevoyanceDailyIndemnitiesTitle')} editMode paginator={false} parentClasses={[]}>
      <Column field={'amount'} header={trans(t, 'table|prevoyance_ij')} align={'center'} alignHeader={'center'} body={amountBody}
              editor={(options) => amountEditor(options, {label: trans(t, 'table|prevoyance_ij')})}/>
      <Column field={'startDay'} header={trans(t, 'table|prevoyance_ij_start')} align={'center'} alignHeader={'center'} body={startBody}
              editor={(options) => numberEditor(options, {label: trans(t, 'table|prevoyance_ij_start'), suffix: ' ' + trans(t, 'day', 2)})}/>
      <Column field={'endDay'} header={trans(t, 'table|prevoyance_ij_end')} align={'center'} alignHeader={'center'} body={endBody}
              editor={(options) => numberEditor(options, {label: trans(t, 'table|prevoyance_ij_end'), suffix: ' ' + trans(t, 'day', 2)})}/>
    </DatatableWrapper>
  );
};
