import React, { useEffect, useState } from 'react';

import { Skeleton } from 'primereact/skeleton';

import { IRequestParams, useApim } from 'services';
import { UncontrolledCheckboxField } from 'forms';
import { isValidUUID, trans, ucfirst } from 'utilities';

export const UncontrolledPermissionsField = (props: any) => {
  const { value, onFieldChange, classes } = props;

  const apim = useApim();
  const { t } = apim.di();

  const [loading, setLoading] = useState<boolean>(false);
  const [permissions, setPermissions] = useState<any>([]);

  useEffect(() => {
    setLoading(true);
    apim.fetchEntities({
      resourceType: 'permissions',
      params: [
        {label: 'expand[]', value: 'permission:read_group'},
        {label: 'expand[]', value: 'permission_group:read'}
      ],
      setter: setPermissions,
      setLoading,
    } as IRequestParams).then();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const getPermissionsByGroup = (): any[] => {
    if (permissions.length === 0) {
      return [];
    }

    let permissionsWithoutGroup: any = [];
    let permissionsByGroup: any = [];

    permissions.map((permission: any) => {
      // Check if the permission is in a group.
      if (isValidUUID(permission?.permissionGroup?.id)) {
        let found = false;
        permissionsByGroup.map((item: any) => {
          if (item.permissionGroup?.id === permission?.permissionGroup?.id) {
            found = true;
            item.permissions.push(permission);
          }

          return null;
        });

        if (!found) {
          const item = {
            permissionGroup: permission?.permissionGroup,
            permissions: [permission],
          }

          permissionsByGroup.push(item);
        }
      } else {
        // Add in the list which have no group.
        permissionsWithoutGroup.push(permission);
      }

      return null;
    })

    if (permissionsWithoutGroup.length > 0) {
      permissionsByGroup.push({permissionGroup: null, permissions: permissionsWithoutGroup})
    }

    return permissionsByGroup;
  }

  const getPermissionValue = (permission: any): boolean => {
    if (value.length === 0) {
      return false;
    }

    let found = false;
    value.map((v: any) => {
      if (v.id === permission.id) {
        found = true;
      }

      return null;
    });

    return found;
  }

  const changePermissionValue = (permission: any, permissionValue: boolean) => {
    let _value = [];

    if (permissionValue) {
      // Add permission to list.
      let found = false;
      if (value.length > 0) {
        value.map((v: any) => {
          if (v.id === permission.id) {
            found = true;
          }

          _value.push(v);
          return null;
        });
      }

      if (!found) {
        _value.push(permission);
      }

    } else {
      // Remove permission from list.
      if (value.length > 0) {
        value.map((v: any) => {
          if (v.id !== permission.id) {
            _value.push(v);
          }

          return null;
        });
      }
    }

    onFieldChange(_value);
  }

  return <div className={classes} style={{maxHeight: '200px', overflowY: 'auto'}}>
      {loading ? (
        <Skeleton height={'2.8rem'} />
      ) : (
        <>
          {getPermissionsByGroup().map((item: any) => {
            return <div>
              {item.permissionGroup !== null ? (
                <h3 className={'text-lg m-0'}>{item.permissionGroup.label ?? item.permissionGroup.name}</h3>
              ) : (
                <h3 className={'text-lg m-0'}>{ucfirst(trans(t, 'other'))}</h3>
              )}

              <div className={'mb-3'}>
                {item.permissions.map((permission: any) => {
                  return <UncontrolledCheckboxField key={permission.id} classes={''}
                                                    value={getPermissionValue(permission)}
                                                    onFieldChange={(v: any) => changePermissionValue(permission, v)}
                                                    label={permission.label ?? permission.name}
                                                    tooltip={permission.description}/>
                })
                }
              </div>
            </div>;
          })}
        </>
      )}
    </div>;
};
