import React from 'react';
import { Link } from 'react-router-dom';
import { Button } from 'primereact/button';

import { handleCopy, isValidUUID } from 'utilities';
import { addressCell, dateTimeCell } from 'components';

import { at, forEach } from 'lodash';

// Find a value into 'company' path or fallback on root key else.
export const flatC = (rowData: any, fieldName: string) =>
  at(rowData, ['company.' + fieldName])[0] ??
  rowData[fieldName]
;

// Find a value into 'company.latestVersion' path or fallback on flatC else.
export const flatCV = (rowData: any, fieldName: string) =>
  at(rowData, ['company.latestVersion.' + fieldName])[0] ??
  at(rowData, ['latestVersion.' + fieldName])[0] ??
  flatPP(rowData, fieldName)
;

// Find a value into 'company.version' path or fallback on flatC else.
export const flatCVV = (rowData: any, fieldName: string) =>
  at(rowData, ['company.version.' + fieldName])[0] ??
  at(rowData, ['version.' + fieldName])[0] ??
  flatPP(rowData, fieldName)
;

// Find a value into 'personnePhysique' path or fallback on root key else.
export const flatPP = (rowData: any, fieldName: string) =>
  at(rowData, ['personnePhysique.' + fieldName])[0] ??
  rowData[fieldName]
;

// Find a value into 'personnePhysique.latestVersion' path or fallback on flatPP else.
export const flatPPV = (rowData: any, fieldName: string) =>
  at(rowData, ['personnePhysique.latestVersion.' + fieldName])[0] ??
  at(rowData, ['latestVersion.' + fieldName])[0] ??
  flatPP(rowData, fieldName)
;

// Flat PersonnePhysiques from Indivision to Root.
export const flatPPI = (_items: any[]) => {
  const _flat: any[] = (_items ?? []).filter((_i: any) => isValidUUID(_i?.personnePhysique?.id)).map((_i: any) => ({
    ..._i,
    ...{ sortField: _i.personnePhysique?.title ?? _i.personnePhysique?.firstName ?? _i.personnePhysique?.lastName }
  }));

  forEach((_items ?? []).filter((_i: any) => _i?.indivision?.indivisionMembers?.length > 0), (_i: any) => {
    forEach(_i?.indivision?.indivisionMembers, (_im: any) => {
      _flat.push({
        ..._i,
        ...{
          indivisionId: _i.indivision.id,
          indivisionPersonnePhysique: _im?.personnePhysique,
          indivisionPercent: _im?.percentage ?? 0,
          sortField: _i.indivision.label + (_im?.percentage ?? '00')
        }
      })

    });
  });

  return _flat.sort((a: any, b: any) => a.sortField > b.sortField ? -1 : 1);
};

// Personne physique - [VERSION] (personne_physique_data) :
export const firstNameBody = (rowData: any) => <span>{flatPPV(rowData, 'firstName')}</span>;
export const lastNameBody = (rowData: any, link: any | null = null) =>
  <div className={'flex flex-column'}>
    <div>{flatPPV(rowData, 'lastName')}</div>
    {link && (
      <Link className={'block mt-1 text-sm'} to={link.url}>{link.label}</Link>
    )}
  </div>
;

export const fullNameBody = (rowData: any, link: any | null = null) =>
  <div className={'flex flex-column'}>
    <div>{flatPPV(rowData, 'firstName')} {flatPPV(rowData, 'lastName')}</div>
    {link && (
      <Link className={'block mt-1 text-sm'} to={link.url}>{link.label}</Link>
    )}
  </div>
;

export const addressBody = (rowData: any) => {
  const address = flatPPV(rowData, 'address');
  if (!address?.address1 && !address?.address2 && !address?.postalCode && !address?.city) return <span></span>;

  const addressString =
    (address.address1 || '') + ' ' + (address.address2 || '') + ' ' +
    (address.postalCode || '') + ', ' + (address.city || '');

  return (
    <span className={'flex align-items-center justify-content-center'}>
      <a target={'_blank'} rel={'noreferrer'} href={`https://maps.google.com/maps?q=${addressString}`} className={'link-address'}>{addressCell(address)}</a>
      <Button onClick={() => handleCopy(addressString)} icon='pi pi-copy' size={'small'} rounded text className={'ml-2'} />
    </span>
  );
};

export const companyAddressBody = (rowData: any) => {
  const address = flatCVV(rowData, 'headquarterAddress') || flatCV(rowData, 'headquarterAddress');
  const city = flatCVV(rowData, 'headquarterCity') || flatCV(rowData, 'headquarterCity');
  const postalCode = flatCVV(rowData, 'headquarterPostalCode') || flatCV(rowData, 'headquarterPostalCode');
  const country = flatCVV(rowData, 'headquarterCountry') || flatCV(rowData, 'headquarterCountry');

  const addressObj = {
    address1: address,
    address2: null,
    city,
    postalCode,
    country,
  }

  if (!address && !city && !postalCode && !country) return <span></span>;
  const addressString = (address || '') + ' ' + (postalCode || '') + ', ' + (city || '');

  return (
    <span className={'flex align-items-center justify-content-center'}>
      <a target={'_blank'} rel={'noreferrer'} href={`https://maps.google.com/maps?q=${addressString}`} className={'link-address'}>{addressCell(addressObj)}</a>
      <Button onClick={() => handleCopy(addressString)} icon='pi pi-copy' size={'small'} rounded text className={'ml-2'} />
    </span>
  );
};

// Personne physique - [ROOT] (personne_physique) :
export const birthNameBody = (rowData: any) => <span>{flatPP(rowData, 'birthName')}</span>;
export const birthDateBody = (rowData: any) => dateTimeCell(flatPP(rowData, 'birthDate'), {format: 'DD/MM/YYYY'});
export const emailBody = (rowData: any) => {
  const email = (flatPP(rowData, 'email') || '') as string;
  return '' !== email ? (
    <span className={'flex align-items-center'}>
      <a href={`mailto:${email}`} className={'link-email'}>{email}</a>
      <Button onClick={() => handleCopy(email)} icon='pi pi-copy' size={'small'} rounded text className={'ml-2'} />
    </span>
  ) : (<span></span>);
};
export const ssnBody = (rowData: any) => {
  const socialSecurityNumber = (flatPP(rowData, 'socialSecurityNumber') || '') as string;
  return '' !== socialSecurityNumber ? (
    <span className={'flex align-items-center justify-content-center'}>
      {socialSecurityNumber}
      <Button onClick={() => handleCopy(socialSecurityNumber)} icon='pi pi-copy' size={'small'} rounded text className={'ml-2'} />
    </span>
  ) : (<span></span>);
};

const phoneBody = (rowData: any, fieldName: string) => {
  const phone = flatPP(rowData, fieldName) as string;
  return phone ? (<span><a href={`tel:${phone}`} className={'link-phone flex align-items-center justify-content-center'}>{phone}<Button onClick={() => handleCopy(phone)} icon='pi pi-copy' size={'small'} rounded text className={'ml-2'} /></a></span>) : (<span></span>);
};

export const phone1Body = (rowData: any) => phoneBody(rowData, 'phone1');
export const phone2Body = (rowData: any) => phoneBody(rowData, 'phone2');
