import React, { useCallback, useEffect, useState } from 'react';
import { AxiosResponse } from 'axios';
import { useParams } from 'react-router-dom';

import { Button } from 'primereact/button';
import { Column } from 'primereact/column';
import { DataTableExpandedRows } from 'primereact/datatable';
import { Fieldset } from 'primereact/fieldset';
import { Message } from 'primereact/message';
import { Skeleton } from 'primereact/skeleton';

import { YearsSpan } from '../../headers';
import { FormWrapper, UncontrolledAPIListField } from 'forms/index';
import { asDate, dateString, iri, trans, triggerFormSubmit, ucfirst } from 'utilities';
import { IRequestParams } from 'services';
import { amountCell, amountEditor, companyCell, DatatableWrapper } from 'components';

import appUri from 'config/appUri.json';
import { forEach, trimStart } from 'lodash';

export const RemunerationDirigeant = (props: any) => {
  const { year } = useParams();
  const { apim, urls, missionCategory, setMissionCategory, cancelUri, hypotheseId, dossier, appState, missionId } = props;
  const { t, navigate } = apim.di();
  const [societes, setSocietes] = useState<any[]>([]);
  const [fiscalData, setFiscalData] = useState<any>();
  const [statutData, setStatutData] = useState<any>();
  const [societeType, setSocieteType] = useState<any>({});
  const [civilData, setCivilData] = useState<any>();

  const [loading, setLoading] = useState<boolean>(false);

  const [missionYearTotal, setMissionYearTotal] = useState<number>(0);
  const [nextYearTotal, setNextYearTotal] = useState<number>(0);
  const [expandedRows, setExpandedRows] = useState<DataTableExpandedRows>();

  const redirectUri = (trimStart(appUri.dos.page) + appUri.dos.pageMission)
    .replace(':id', dossier?.id)
    .replace(':module', 'missions')
    .replace(':type', 'remuneration_dirigeant')
    .replace(':mission', missionId);

  useEffect(() => {
    if (!missionId) return;

    setLoading(true);
    apim.fetchEntity({
      resourceType: 'missions',
      id : missionId,
      cache: false,
      success : (resMission :AxiosResponse) => {
        apim.fetchEntity({
          resourceType: 'dossiers',
          id: dossier?.id,
          params: [{label: 'date', value: resMission?.data?.date}],
          action: 'societes',
          success: (resCompanies: AxiosResponse) => {
            if (resCompanies?.data?.companies?.length > 0) {
              setSocietes(resCompanies?.data?.companies);
            }  else {
              setLoading(false);
            }
          }
        } as IRequestParams).then();
      }
    } as IRequestParams).then();
  }, [missionId]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (missionCategory?.id && societes && year) {
      let _fiscalData: any[] = [];
      let _statutData: any[] = [];
      let _civilData: any[] = [];

      forEach(societes, (societe: any) => {
        apim.postEntity({
          resourceType: 'missionRemunerationFiscalInitial',
          data: {
            company: iri('dossierCompanies', societe?.id),
            missionRemuneration: iri('mission_remuneration_dirigeant', missionCategory?.id),
            fiscalYear: parseInt(year),
          },
          notif: false,
          setLoading,
          success: (res: AxiosResponse) => {
            const itemsWithCompany = res?.data?.items?.map((item: any) => ({
              ...item,
              dossierCompany: res?.data?.dossierCompany
            }))
            _fiscalData.push(itemsWithCompany);
            if (_fiscalData.length === societes.length){
              setFiscalData(_fiscalData.flat())
            }
          }
        } as IRequestParams).then();

        apim.postEntity({
          resourceType: 'missionRemunerationStatutInitial',
          data: {
            company: iri('dossierCompanies', societe?.id),
            missionRemuneration: iri('mission_remuneration_dirigeant', missionCategory?.id),
          },
          notif: false,
          success: (res: AxiosResponse) => {
            _statutData.push(res?.data);
            if (_statutData.length === societes.length){
              setStatutData(_statutData.flat())
            }
          }
        } as IRequestParams).then();

        apim.postEntity({
          resourceType: 'missionRemunerationCivilInitial',
          data: {
            company: iri('dossierCompanies', societe?.id),
            missionRemuneration: iri('mission_remuneration_dirigeant', missionCategory?.id),
            civilYear: parseInt(year),
          },
          notif: false,
          setLoading,
          success: (res: AxiosResponse) => {
            const itemsWithCompany = res?.data?.items?.map((item: any) => ({
              ...item,
              dossierCompany: res?.data?.dossierCompany
            }))
            _civilData.push(itemsWithCompany);
            if (_civilData.length === societes.length){
              setCivilData(_civilData.flat())
            }
          }
        } as IRequestParams).then();
      })
    }
  }, [year, societes]); // eslint-disable-line react-hooks/exhaustive-deps

  const companyBodyTemplate = (rowData: any) => companyCell(rowData, t);

  const exerciceFiscalBodyTemplate = (rowData: any) =>{
    return (
      <span>{dateString(asDate(rowData?.exerciceStartDate)) + ' - ' + dateString(asDate(rowData?.exerciceEndDate))}</span>
    );
  };
  const exerciceFiscalPartDatesTemplate = (rowData: any) => {

    return rowData?.fiscalYearStartDate ?
      <span>{ucfirst(trans(t, ('part'))) + ' ' + rowData?.fiscalYearPart + ' : ' + dateString(rowData?.fiscalYearStartDate) + ' - ' + dateString(rowData?.fiscalYearEndDate)}</span>
      : '-';
  };

  const calculateTotal = (data: any, keyPrefix: string): number => {
    if (data?.length === 2) {
      switch (keyPrefix) {
        case 'remuneration':
          return (data[0].remunerationP1 ?? 0) + (data[1].remunerationP2 ?? 0);
        case 'salaires':
          return (data[0].salairesP1 ?? 0) + (data[1].salairesP2 ?? 0);
        default:
          return 0;
      }
    } else if (data?.length === 1)
    {
      switch (keyPrefix) {
        case 'remuneration':
          return data[0].remunerationP1 ?? 0;
        case 'salaires':
          return data[0].salairesP1 ?? 0;
        default:
          return 0;
      }
    } else {
      return 0;
    }
  }
  const remunerationExpandableBodyTemplate = (rowData: any) => {
    const societeFiscalData = fiscalData?.filter((data: any ) => data?.dossierCompany === iri('dossierCompanies', rowData?.id) &&parseInt(data.fiscalYear) === parseInt(appState.civilYear()));
    const remunerationTotal = calculateTotal(societeFiscalData, 'remuneration');

    return amountCell(remunerationTotal);
  };

  const salaryExpandableBodyTemplate = (rowData: any) => {
    const societeFiscalData = fiscalData?.filter((data: any ) => data?.dossierCompany === iri('dossierCompanies', rowData?.id) && parseInt(data.fiscalYear) === parseInt(appState.civilYear()));
    const salaryTotal = calculateTotal(societeFiscalData, 'salaires');

    return amountCell(salaryTotal);
  };

  const dividendesExpandableBodyTemplate = (rowData: any) => {
    const societeCivilData = civilData?.filter((data: any ) => data?.dossierCompany === iri('dossierCompanies', rowData?.id) && parseInt(data.civilYear) === parseInt(appState.civilYear()));
    return amountCell(societeCivilData?.[0]?.dividendes || 0);
  };
  const getTotal = (rowData: any) => {
    const societeFiscalData = fiscalData?.filter((data: any ) => data?.dossierCompany === iri('dossierCompanies', rowData?.id) && parseInt(data.fiscalYear) === parseInt(appState.civilYear()));
    const salaryTotal = calculateTotal(societeFiscalData, 'salaires');
    const remunerationTotal = calculateTotal(societeFiscalData, 'remuneration');
    const societeCivilData = civilData?.filter((data: any ) => data?.dossierCompany === iri('dossierCompanies', rowData?.id) && parseInt(data.civilYear) === parseInt(appState.civilYear()));

    return (salaryTotal ?? 0) + (remunerationTotal ?? 0) + (societeCivilData?.[0]?.dividendes ?? 0);
  }

  const totalBodyTemplate = (rowData: any) => amountCell(getTotal(rowData));
  const dividendesBodyTemplate = (rowData: any) => amountCell(rowData?.dividendes);
  const remunerationBodyTemplate = (rowData: any) => {
    const value = (rowData?.fiscalYearPart === 1) ? rowData.remunerationP1 || null : rowData.remunerationP2 || null;

    return amountCell(value);
  };

  const salaryBodyTemplate = (rowData: any) => {
    const value = (rowData?.fiscalYearPart === 1) ? rowData.salairesP1 || null : rowData.salairesP2 || null;

    return amountCell(value);
  };
  const getMissionTotal = (year: any) => {
    let fiscalTotal = 0;
    let civilTotal = 0;
    fiscalData?.filter((item: any) => parseInt(item.fiscalYear) === parseInt(year)).forEach((company: any) => {
      fiscalTotal += (company?.remunerationP1 ?? 0) + (company?.remunerationP2 ?? 0) + (company?.salairesP1 ?? 0) + (company?.salairesP2 ?? 0);
    });

    civilData?.filter((item: any) => item.civilYear === year).forEach((company: any) => {
      civilTotal += (company?.dividendes ?? 0);
    });

    return (fiscalTotal ?? 0) + (civilTotal ?? 0);
  }

  const civilYearBodyTemplate = (rowData: any) => rowData.civilYear;

  const rowExpansionTemplate = (societeData : any) => {
    const fiscalRows = fiscalData?.filter((item: any) => item.fiscalYear === parseInt(appState.civilYear()) && item.dossierCompany === iri('dossierCompanies', societeData.id));
    const civilRows = civilData?.filter((item: any) => item.civilYear === parseInt(appState.civilYear())&& item.dossierCompany === iri('dossierCompanies', societeData.id));
    const hookRowEdit = (_rows: any[], editedRow: any) => {
      const patched: any = editedRow.fiscalYearPart === 1?
        {
          missionRemunerationFiscal: {
            remunerationP1: editedRow.remuneration,
            salairesP1: editedRow.salaires,
            company: editedRow.dossierCompany,
            missionRemuneration:  iri('mission_remuneration_dirigeant', missionCategory?.id),
            fiscalYear: editedRow.fiscalYear,
          }
        } : {
          missionRemunerationFiscal: {
            remunerationP2: editedRow.remuneration,
            salairesP2: editedRow.salaires,
            company: editedRow.dossierCompany,
            missionRemuneration:   iri('mission_remuneration_dirigeant', missionCategory?.id),
            fiscalYear: editedRow.fiscalYear,
          }
        };

      return { formattedRows: _rows, patched: patched, id: editedRow?.id };
    };

    const fiscalOnRowEditCallback = (patched: any) => {
      const patchedData = patched.missionRemunerationFiscal;

      fiscalData.forEach((fiscalDataItem: any) => {
        if (parseInt(fiscalDataItem.fiscalYear) === parseInt(patchedData.fiscalYear) && fiscalDataItem.id === patchedData.id && fiscalDataItem.fiscalYearPart === patchedData.fiscalYearPart) {
          if (patchedData.fiscalYearPart === 1) {
            fiscalDataItem.salairesP1 = patchedData.salairesP1;
            fiscalDataItem.remunerationP1 = patchedData.remunerationP1;

            if (!year) return;

            if (patchedData.fiscalYear === parseInt(year)) {
              setMissionYearTotal(getMissionTotal(patchedData.fiscalYear));
            } else if (patchedData.fiscalYear === parseInt(year) + 1) {
              setNextYearTotal(getMissionTotal(patchedData.fiscalYear));
            }
          } else if (patchedData.fiscalYearPart === 2) {

            fiscalDataItem.salairesP2 = patchedData.salairesP2;
            fiscalDataItem.remunerationP2 = patchedData.remunerationP2;

            if (!year) return;

            if (patchedData.fiscalYear === parseInt(year)) {
              setMissionYearTotal(getMissionTotal(patchedData.fiscalYear));
            } else if (patchedData.fiscalYear === parseInt(year) + 1) {
              setNextYearTotal(getMissionTotal(patchedData.fiscalYear));
            }
          }
        }
      });
    };

    const civilOnRowEditCallback = (patched: any) => {
      const patchedData = patched.missionRemunerationCivil;
      civilData.forEach((civilDataItem: any) => {
        if (parseInt(civilDataItem.civilYear) === parseInt(patchedData.civilYear) && civilDataItem.id === patchedData.id) {
          civilDataItem.dividendes = patchedData.dividendes;
          if (!year) return;
          if (patchedData.civilYear === parseInt(year)) {
            setMissionYearTotal(getMissionTotal(patchedData.civilYear));
          } else if (patchedData.civilYear === parseInt(year) + 1) {
            setNextYearTotal(getMissionTotal(patchedData.civilYear));
          }
        }
      });
    };

    return (
      <>
        <DatatableWrapper parentClasses={'m-0 p-0 blue'} resourceType={'missionRemunerationCivil'} rows={civilRows} editMode onRowEditCallback={civilOnRowEditCallback}
                          noAdd noRemove noGlobalFilter noFilters paginator={false}>
          <Column className={'w-1'}/>
          <Column field='civilYear' header={trans(t, 'civilYear')} body={civilYearBodyTemplate} className={'w-3'}/>
          <Column field='dividendes' header={trans(t, 'dividendes')} body={dividendesBodyTemplate} editor={(options) => amountEditor(options, {label: trans(t, 'dividendes'), className: 'w-10rem'})}/>
        </DatatableWrapper>
        <DatatableWrapper parentClasses={'m-0 p-0'} rows={fiscalRows} editMode  resourceType={'missionRemunerationFiscal'}
                          noAdd noRemove hookRowEdit={hookRowEdit} onRowEditCallback={fiscalOnRowEditCallback} noGlobalFilter noFilters paginator={false} >
          <Column className={'w-1'}/>
          <Column field='exerciceFiscal' header={trans(t, 'exerciceFiscal')} body={exerciceFiscalBodyTemplate} className={'w-2'}/>
          <Column field='exerciceFiscalPartDates' header={trans(t, 'decoupageExerciceFiscal')} body={exerciceFiscalPartDatesTemplate} className={'w-3'} align={'center'}/>
          <Column field='remuneration' className={'w-2'} header={trans(t, 'remuneration')} body={remunerationBodyTemplate}
                  editor={(options) => amountEditor(options, {label: trans(t, 'remuneration'), className: 'w-10rem'})}/>
          <Column field='salaires' header={trans(t, 'salary', 2)} body={salaryBodyTemplate}
                  editor={(options) => amountEditor(options, {label: trans(t, 'salary', 2), className: 'w-10rem'})}/>
        </DatatableWrapper>
      </>
    )
  }

  const renderEconomicDataForm = useCallback(() =>
      <FormWrapper classes={'grid p-fluid'} submitClass={'a8-remuneration_dirigeant-form'} formKeyPrefix={'general_remuneration_dirigeant'} resourceType={'mission_remuneration_dirigeant'} setData={setMissionCategory}
                   data={missionCategory} additionalData={{missionHypothesis : iri('missionHypothesis', hypotheseId)}} context={{missionYear : year, missionYearTotal : missionYearTotal, nextYearTotal : nextYearTotal, missionCategory : missionCategory?.id, missionHypothesis : iri('missionHypothesis', hypotheseId)}}
                   hideReload cancelLink multiple notif={false}/>
    , [missionCategory, year, hypotheseId, missionYearTotal, nextYearTotal]); // eslint-disable-line react-hooks/exhaustive-deps

  const renderFiscalDTExpandable = useCallback(() =>
      <>
        <DatatableWrapper additionalClassNames={'p-datatable-no-hover'} rows={societes} expandedRows={expandedRows} noGlobalFilter noFilters paginator={false} rowHover={false}
                          rowExpansionTemplate={rowExpansionTemplate} onRowToggle={(e: any) => setExpandedRows(e.data as DataTableExpandedRows)}>
          <Column expander={true} className={'w-1'}/>
          <Column field='company' header={trans(t, 'company')} body={companyBodyTemplate} className={'w-3'}/>
          <Column field='dividendes' header={trans(t, 'dividendes')} body={dividendesExpandableBodyTemplate} className={'w-2'}/>
          <Column field='remuneration' header={trans(t, 'remuneration')} body={remunerationExpandableBodyTemplate} className={'w-2'}/>
          <Column field='salaires' header={trans(t, 'salary', 2)} body={salaryExpandableBodyTemplate} className={'w-2'}/>
          <Column field='total' header={trans(t, 'civilTotal')} body={totalBodyTemplate} className={'w-2'} align={'center'}/>
        </DatatableWrapper>
        <p>{ucfirst(trans(t, 'brutDataDisplayed'))}.</p>
      </>
    , [expandedRows, societes, appState.civilYear(), fiscalData, civilData]); // eslint-disable-line react-hooks/exhaustive-deps

  const onFieldChange = (e: any, data: any, index: number) => {
    const updatedSocieteType = { ...societeType, [index]: e };
    setSocieteType(updatedSocieteType);
    apim.patchEntity({
      resourceType: 'missionRemunerationStatut',
      id: data?.id,
      data: {type : e},
      notif: false,
    } as IRequestParams).then();
  }

  return (
    <>
      {(loading) ? (
        <>
          <Skeleton width={'100%'} height={'20rem'} className={'mb-5'}/>
          <Skeleton width={'100%'} height={'10rem'} className={'mb-5'}/>
          <Skeleton width={'100%'} height={'10rem'} className={'mb-5'}/>
          <Skeleton width={'100%'} height={'10rem'} className={'mb-5'}/>
        </>
      ) : (
        <>
          {societes?.length === 0 && (
            <Message text={ucfirst(trans(t, 'company_empty')) + '. '} className={'w-full mx-3 py-3'}/>
          )}

          {societes?.length > 0 && (
            <>
              <div className={'a8-page-header flex justify-content-center mb-4'}>
                <YearsSpan appState={appState} apim={apim} missionYear={year}/>
              </div>
              {renderFiscalDTExpandable()}
              {renderEconomicDataForm()}
            </>
          )}

          {statutData?.length > 0 && (
            <Fieldset legend={ucfirst(trans(t, 'recommendedStatus', statutData?.length))} toggleable>
              {statutData?.map((data: any, index: number) => (
                <div className={'flex flex-row ml-4'} key={'div' + index}>
                  <div className={'w-2 mt-3'} key={'description' + index}>{companyCell(societes?.find(societe => iri('dossierCompanies', societe?.id)  === data?.company), t)}</div>
                  <div className='mt-3 w-3'>
                    <UncontrolledAPIListField listKey={'societeTypes'} fieldKey={'type'} className={'w-full'}  value={societeType[index] || data?.type}
                                              label={trans(t, 'ent.formeJuridique')} onFieldChange={(e: any) => onFieldChange(e, data, index)}/>

                  </div>
                </div>
              ))}
            </Fieldset>
          )}
          <div className={'text-right m-2 pt-4'}>
            <Button type={'button'} className={'text-ucfirst p-button-text p-button-danger mr-5'} label={trans(t, 'close')} onClick={() => navigate(cancelUri)}/>
            <span className={'p-buttonset'}>
          <Button type={'button'} className={'text-ucfirst p-button-text'} label={trans(t, 'back')} icon={'pi pi-arrow-left'} onClick={() => navigate(urls?.form + '/' + missionId + '/hypothese/' + hypotheseId)}/>

              {societes?.length > 0 &&  (
                <Button type={'submit'} className={'text-ucfirst'} label={trans(t, 'terminate')} icon={'pi pi-check'}
                        onClick={() => {
                          triggerFormSubmit('a8-remuneration_dirigeant-form');
                          navigate(redirectUri);
                        }}/>
              )}
        </span>
          </div>
        </>)}
    </>
  );
}
