import React from 'react';
import { Link, useLocation } from 'react-router-dom';

import { isValidUUID, trans, ucfirst, uuidFromIri } from 'utilities';
import { APIAutocompleteField, APIListField, CheckboxField, StaticListField } from 'forms';

import appUri from 'config/appUri.json';

export const BienEntrepriseSelectionForm = (props: any) => {
  const { t, control, onFieldChange, errorMessage, getValues, context, globalDisabled, listsOptions } = props;
  const dossier = isValidUUID(context?.dossier?.id) ? context?.dossier?.id : uuidFromIri(context?.dossier);
  const location = useLocation();

  const itemTemplate = (option: any, props: any) => {
    if (option) {
      return (
        <div className={'flex align-items-center'}>
          <div>{option.label}</div>
          {option.family && (
             <small className={'mx-2'}>({option.family})</small>
          )}
        </div>
      );
    }

    return <span>{props.placeholder}</span>;
  };

  const newCompanyLink =
    <span className={'block text-xs pt-1 pl-1 pb-2'}>
      {trans(t, 'form|patrimoine.biens.entreprise.createNewQ')} <Link to={
        appUri.cie.cli.add + '?destination=' + location.pathname
      }>{trans(t, 'form|patrimoine.biens.entreprise.createNewL')}</Link>
    </span>
  ;

  return <div className={'grid w-full px-3'}>
    <div className={'col-12 pt-5'}>
      <APIListField listKey={'bienEntrepriseTypes'} fieldKey={'type'} control={control} onFieldChange={onFieldChange} errorMessage={errorMessage}
                    label={trans(t, 'form|patrimoine.biens.default.type')} rules={{required: trans(t, 'form|requiredField')}}/>
      <StaticListField listKey={'companies'} listsOptions={listsOptions} fieldKey={'company'} control={control} optionLabel={'raisonSociale'} optionValue={'id'}
                       onFieldChange={onFieldChange} errorMessage={errorMessage} editable label={trans(t, 'form|patrimoine.biens.entreprise.company')}
                       rules={{required: trans(t, 'form|requiredField')}} description={newCompanyLink}/>
      <CheckboxField fieldKey={'alreadyExists'} control={control} onFieldChange={onFieldChange} errorMessage={errorMessage} classes={'mb-0'}
                     label={trans(t, 'form|patrimoine.biens.default.alreadyExists')} disabled={globalDisabled} help={trans(t, 'form|patrimoine.biens.default.alreadyExistsHelp')}/>
    </div>

    {getValues()['alreadyExists'] === true && (
      <div className={'col-12'}>
        <APIAutocompleteField fieldKey={'existing'} control={control} onFieldChange={onFieldChange} errorMessage={errorMessage} label={trans(t, 'bien')}
                              getValues={getValues} placeholder={ucfirst(trans(t, 'form|select'))} optionKey={'label'} itemTemplate={itemTemplate}
                              resourceType={'biensEntreprises'} params={[{label: 'byDossierRelatives', value: dossier}]} disabled={globalDisabled}/>
      </div>
    )}
  </div>;
};
