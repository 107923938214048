import React, { useCallback, useState } from 'react';
import { AxiosResponse } from 'axios';
import { Button } from 'primereact/button';

import { iri, isValidUUID, trans, triggerFormSubmit } from 'utilities';
import { FormWrapper } from 'forms';
import { IRequestParams } from 'services';
import { PageLoader } from 'components';

import appUri from 'config/appUri.json';

export const Step1 = (props: any) => {
  const { data, apim, appState, context, dirigeant } = props;
  const { t, navigate} = apim.di();

  const [loading, setLoading] = useState<boolean>(false);

  // Auto-handle early links e.g. Bien <=> Patrimoine & Bien <=> Proprietaires
  const onSubmit = (formData: any) => {
    setLoading(true);
    const baseUrl = appUri.dos.edit.patrimoine.biens.uri.replace(':id', appState?.dossier()?.id).replace(':type', 'divers');

    // Just navigate to next step if data already passed Step1.
    if (isValidUUID(data?.id)) return navigate(baseUrl.replace(':bienId?', data?.id) + appUri.dos.edit.patrimoine.biens.steps.default.step2);

    const postPBD = (bienId: string) => {
      apim.postEntity({
        resourceType: 'patrimoineBiensDivers',
        notif: false,
        data: {
          patrimoine: iri('patrimoines', context?.id),
          bienDivers: iri('biensDivers', bienId),
        },
        success: (resP: AxiosResponse) => {
          if (!isValidUUID(resP?.data?.id)) return apim.displayError(trans(t, 'form|errors.default.summary'), trans(t, 'form|errors.default.detail'));

          const linkDirigeant = () => {
            if (isValidUUID(dirigeant?.id)) {
              apim.postEntity({
                resourceType: 'proprietaires',
                notif: false,
                data: {
                  personnePhysique: iri('personnesPhysiques', dirigeant?.id),
                  bienDivers: iri('biensDivers', bienId),
                  type: 'pleine_propriete'
                },
                success: (resP: AxiosResponse) => {
                  if (!isValidUUID(resP?.data?.id)) return apim.displayError(trans(t, 'form|errors.default.summary'), trans(t, 'form|errors.default.detail'));
                },
                always: () => navigate(baseUrl.replace(':bienId?', bienId) + appUri.dos.edit.patrimoine.biens.steps.default.step2)
              } as IRequestParams).then();
            } else navigate(baseUrl.replace(':bienId?', bienId) + appUri.dos.edit.patrimoine.biens.steps.default.step2);
          };

          // Finally let's link by default the dossier dirigeant (+ conjoint(e)) as proprietaires.
          if (dirigeant?.spouses?.length > 0 && isValidUUID(dirigeant.spouses[0].spouse?.id)) {
            apim.postEntity({
              resourceType: 'proprietaires',
              notif: false,
              data: {
                personnePhysique: iri('personnesPhysiques', dirigeant.spouses[0].spouse?.id),
                bienDivers: iri('biensDivers', bienId),
                type: 'pleine_propriete'
              },
              always: () => linkDirigeant()
            } as IRequestParams).then();
          } else {
            linkDirigeant();
          }
        }
      } as IRequestParams).then();
    };

    // Just link selected "bien"
    if (isValidUUID(formData?.existing)) postPBD(formData?.existing);
    else {
      // Create & link a new "bien".
      apim.postEntity({
        resourceType: 'biensDivers',
        data: {label: trans(t, 'new.bien')},
        notif: false,
        success: (res: AxiosResponse) => {
          if (!isValidUUID(res?.data?.id)) return apim.displayError(trans(t, 'form|errors.default.summary'), trans(t, 'form|errors.default.detail'));

          postPBD(res?.data?.id);
        }
      } as IRequestParams).then();
    }
  };

  const renderForm = useCallback(() =>
      <FormWrapper multiple formKeyPrefix={'bien_divers_selection'} resourceType={'biensDivers'} classes={'grid p-fluid w-12'}
                   cancelLink hideReload context={context} data={data} onSubmit={onSubmit} globalDisabled={isValidUUID(data?.id)}/>
    , [data?.id, context?.id]); // eslint-disable-line react-hooks/exhaustive-deps

  if (loading) return <PageLoader/>;

  return (
    <div className={'a8-wizard wizard-patrimoine-bien-divers fadein animation-duration-500'}>
      <h5 className={'mx-3 pt-3 flex flex-row align-items-center'}>
        <span className={'mr-2 border-circle bg-primary flex align-items-center justify-content-center'} style={{width: '2rem', height: '2rem'}}><i className={'pi pi-search'}></i></span>
        {trans(t, 'menu|wizard.patrimoine.biens.default.step1')}
      </h5>

      {renderForm()}

      <div className={'text-right m-2 pt-4'}>
        <Button type={'reset'} className={'text-ucfirst p-button-text p-button-danger mr-5'} label={trans(t, 'close')} onClick={() => navigate(appUri.dos.edit.patrimoine.biens.landing.replace(':id', appState?.dossier()?.id))}/>
        <Button type={'submit'} className={'text-ucfirst p-button-text'} label={trans(t, 'next')} icon={'pi pi-arrow-right'} iconPos={'right'} onClick={() => triggerFormSubmit(null)}/>
      </div>
    </div>
  );
};
