import React from 'react';
import { trans } from 'utilities';
import { APIListField } from 'forms';

export const CGIncapaciteForm = (props: any) => {
  const { t, control, onFieldChange, errorMessage } = props;

  return <div className={'grid w-full px-3'}>
    <div className={'col-3'}>
      <APIListField classes={'w-full'} listKey={'prevoyanceContractFanchiseHospitalisation'} fieldKey={'incapaciteFanchiseHospitalisation'} control={control} onFieldChange={onFieldChange} errorMessage={errorMessage}
                    label={trans(t, 'form|prevoyance_contrat.incapaciteFanchiseHospitalisation')} rules={{ required: trans(t, 'form|requiredField') }}/>
    </div>

    <div className={'col-3'}>
      <APIListField classes={'w-full'} listKey={'prevoyanceContractCarences'} fieldKey={'incapaciteMaladieHorsPsyCarence'} control={control} onFieldChange={onFieldChange} errorMessage={errorMessage}
                    label={trans(t, 'form|prevoyance_contrat.attenteMaladie')}/>
    </div>
  </div>;
};
