import React, { useCallback, useState } from 'react';
import { AxiosResponse } from 'axios';

import { Column } from 'primereact/column';

import { dialog, iri, isValidUUID, trans, triggerFormReset, triggerFormSubmit, ucfirst } from 'utilities';
import { companyCell, DatatableWrapper, onPersonneMoraleAddSubmit, simpleCheckboxEditor, validCell, companyAddressBody } from 'components';
import { IRequestParams, isAdmin, isExpert } from 'services';
import { FormWrapper } from 'forms';

import { trim } from 'lodash';

export const AssociatesPersonnesMoralesDatatable = (props: any) => {
  const { rows, setRows, tableKey, title, editMode, params, context, apim, dirigeants, header } = props;
  const { t } = apim.di();

  const [loading, setLoading] = useState<boolean>(false);

  const resourceType = 'associates';

  const titleBodyTemplate = (rowData: any) => companyCell(rowData?.company, t);
  const holdingBodyTemplate = (rowData: any) => validCell(rowData?.company?.latestVersion?.isHolding || false);
  const activeBodyTemplate = (rowData: any) => validCell(rowData?.active);

  const onNew = () => {
    dialog(t, {
      header: trans(t,'form|company.personneMoraleAssociateAdd'),
      icon: 'none',
      message: renderAddForm(),
      accept: () => triggerFormSubmit('a8-form-company-apm-add'),
      reject: () => triggerFormReset('a8-form-company-apm-reset')
    });
  };

  // Handle submission then refresh table rows.
  const onAddSubmit = useCallback(
    (formData: any) => {
      // Check if this company is a dirigeantPersonneMorale.
      const match: any = (dirigeants || []).filter((d: any) => formData?.companyId === d?.company?.id);
      if (match.length > 0) {
        apim.postEntity({
          resourceType: 'associates',
          data: {
            dirigeantPersonneMorale: match[0]['@id'],
            companyData: iri('dossierCompanyData', context.id)
          },
          headers: header,
          setLoading,
          success: (res: AxiosResponse) => {
            const _rows: any[] = [...rows];
            _rows.push({
              ...res.data.dirigeantPersonneMorale,
              ...{ associateId: res.data.id, active: res.data.active }
            });
            setRows(_rows);
          }
        } as IRequestParams).then();
      } else {
        onPersonneMoraleAddSubmit(apim, context, formData, rows, setRows, true, setLoading, header);
      }
    }
    , [rows]); // eslint-disable-line react-hooks/exhaustive-deps

  const hookRowEdit = (_rows: any[], _rowData: any) => ({
    formattedRows: _rows,
    patched: _rowData,
    id: _rowData?.associateId
  });

  const onRowSubmit = (formData: any) => {
    const handleDirigeantPersonneMorale = () => {
      if (!formData?.id) return;

      const _data: any = isValidUUID(formData.representant?.id) ? {
        dirigeantStatus: formData?.dirigeantStatus,
        representant: iri('personnesPhysiques', formData.representant.id)
      } : {
        dirigeantStatus: formData?.dirigeantStatus
      };

      apim.patchEntity({
        resourceType: 'dirigeantsPersonnesMorales',
        id: formData.id,
        data: _data,
        headers: header,
        notif: false
      } as IRequestParams).then();
    }

    if (!formData?.associateId) return;

    apim.patchEntity({
      resourceType: resourceType,
      id: formData?.associateId,
      data: {
        active: formData.active === true,
      },
      headers: header,
      success: handleDirigeantPersonneMorale
    } as IRequestParams).then();
  };

  // Wrap form render into a useCallback to avoid multiple FormWrapper recalls du to form fields updates.
  const renderAddForm = useCallback(() =>
      <FormWrapper classes={'grid p-fluid w-12'} resourceType={'dossierCompanies'} formKeyPrefix={'add_pm_dialog'} cancelLink multiple
                   resetClass={'a8-form-company-apm-reset'} submitClass={'a8-form-company-apm-add'} onSubmit={onAddSubmit} hideReload />
    , [rows]); // eslint-disable-line react-hooks/exhaustive-deps

  return <DatatableWrapper resourceType={resourceType} tableKey={tableKey || 'associates-personnes-morales'} noFilters noGlobalFilter params={params} isLoading={loading} onRefresh={setRows}
                           title={title || (ucfirst(trans(t, 'associate', rows.length)) + ' - ' + ucfirst(trans(t, 'personneMorale', rows.length)))} onNew={onNew}
                           addTitle={trans(t, 'table|add.shareholder')} additionalData={{ companyData: iri('dossierCompanyData', context?.id) }} paginator={false}
                           editMode={(editMode && (isAdmin() || isExpert())) || false} hookRowEdit={hookRowEdit} onRowEditCallback={onRowSubmit} editFields={['active']} requestHeader={header}
                           rows={(rows || [])
                             .sort((a: any, b: any) => a.company?.latestVersion?.raisonSociale?.localeCompare(b.company?.latestVersion?.raisonSociale))
                             .map((_r: any) => ({..._r, ...{representant: isValidUUID(_r?.representant?.id) ? {..._r?.representant, ...{autocompleteLabel: trim(_r?.representant?.firstName + ' ' + _r?.representant?.lastName)}} : null}}))}>
    <Column field={'company.latestVersion.raisonSociale'} header={trans(t, 'ent.raisonSociale')} body={titleBodyTemplate}
            style={{ minWidth: '300px', width: '300px' }} />
    <Column field={'company.latestVersion.type'} header={trans(t, 'ent.formeJuridique')} align={'center'} alignHeader={'center'}
            style={{ minWidth: '200px', width: '200px' }} />
    <Column field={'company.latestVersion.rcsCity'} header={trans(t, 'ent.rcsCity')}
            style={{ minWidth: '200px', width: '200px' }} />
    <Column field={'company.siren'} header={trans(t, 'ent.siren')} align={'center'} alignHeader={'center'}
            style={{ minWidth: '150px', width: '150px' }} />
    <Column field={'company.latestVersion.siret'} header={trans(t, 'ent.siret')} align={'center'} alignHeader={'center'}
            style={{ minWidth: '200px', width: '200px' }} />
    <Column field={'company.latestVersion.isHolding'} header={trans(t, 'holding')} align={'center'} alignHeader={'center'} body={holdingBodyTemplate}
            style={{ minWidth: '100px', width: '100px' }} />
    <Column field={'company.latestVersion.headquarterAddress'} header={trans(t, 'address')} align={'center'} alignHeader={'center'} body={companyAddressBody} />
    {isAdmin() && <Column field={'active'} header={trans(t, 'active')} body={activeBodyTemplate} align={'center'} alignHeader={'center'}
                          editor={(options) => simpleCheckboxEditor(options, {label: trans(t, 'table|active')})}
                          style={{ minWidth: '100px', width: '100px' }} />}
  </DatatableWrapper>
};
