import React, { useEffect, useState } from 'react';
import { AxiosResponse } from 'axios';

import { Fieldset } from 'primereact/fieldset';
import { Skeleton } from 'primereact/skeleton';

import { dateString, isValidUUID, trans, ucfirst } from 'utilities';
import {
  DirigeantsPersonnesPhysiquesDatatable, IndicatorSimple, IndicatorShort,
  RepartitionCapitalDatatable, SocieteTimeline, SimpleText, EmployeesPersonnesPhysiquesDatatable, AddressField, Userpicture, DirigeantsPersonnesMoralesDatatable
} from 'components';
import { IRequestParams, isAdmin } from 'services';

export const SocieteClient = (props: any) => {
  const { apim, company, event } = props;
  const { t } = apim.di();
  const identificationInfo = [{ key: 'siret' }, { key: 'tvaNumber' }, { key: 'rcsNumber' }];
  const booolean = [{ key: 'isHolding' }, { key: 'isLocationNue' }, { key: 'isLocationGerance' }];
  const dates = [{ key: 'created' }, { key: 'updated' }];

  const [loading, setLoading] = useState<boolean>(false);
  const [dirigeantsPP, setDirigeantsPP] = useState<any[]>([]);
  const [employees, setEmployees] = useState<any[]>([]);
  const [dirigeantsPM, setDirigeantsPM] = useState<any[]>([]);
  const [associates, setAssociates] = useState<any[]>([]);
  const [clauses, setClauses] = useState<any[]>([]);

  // Fetch Personnes physiques data.
  useEffect(() => {
    if (!isValidUUID(company?.id)) return;

    setLoading(true);
    apim.fetchEntity({
      resourceType: 'dossierCompanyData',
      id: company?.id,
      params: [
        {label: 'expand[]', value: 'dossier_company_data:read_clauses'},
        {label: 'expand[]', value: 'dossier_company_clause:read'},
        {label: 'expand[]', value: 'dossier_company_data:read_associates'},
        {label: 'expand[]', value: 'associate:read'},
        {label: 'expand[]', value: 'associate:read_company'},
        {label: 'expand[]', value: 'dossier_company:read'},
        {label: 'expand[]', value: 'dossier_company:read_latest_version'},
        {label: 'expand[]', value: 'dossier_company_data:read_list'},
        {label: 'expand[]', value: 'associate:read_personne_physique'},
        {label: 'expand[]', value: 'associate:read_dirigeant_personne_physique'},
        {label: 'expand[]', value: 'dirigeant_personne_physique:read'},
        {label: 'expand[]', value: 'associate:read_dirigeant_personne_morale'},
        {label: 'expand[]', value: 'dirigeant_personne_morale:read'},
        {label: 'expand[]', value: 'personne_physique:read_list'},
        {label: 'expand[]', value: 'dossier_company_data:read_personnes_morales'},
        {label: 'expand[]', value: 'personne_physique:read_companies'},
        {label: 'expand[]', value: 'personne_physique:read_address'},
        {label: 'expand[]', value: 'personne_physique:read_birth_address'},
        {label: 'expand[]', value: 'personne_physique:read_latest_version'},
        {label: 'expand[]', value: 'personne_physique_data:read_list'},
        {label: 'expand[]', value: 'personne_physique_data:read_address'},
        {label: 'expand[]', value: 'personne_physique_address:read_list'},
        {label: 'expand[]', value: 'dossier_company_data:read_employees'},
        {label: 'expand[]', value: 'personne_physique_employee:read_list'},
        {label: 'expand[]', value: 'personne_physique_employee:read_person'},
      ],
      success: (res: AxiosResponse) => {
        setDirigeantsPP((res?.data?.dirigeantPersonnesPhysiques || []).filter((a: any) => isAdmin() ? true : a?.active));
        setDirigeantsPM((res?.data?.dirigeantPersonnesMorales || []).filter((a: any) => isAdmin() ? true : a?.active));
        setEmployees((res?.data?.employees || []).filter((a: any) => isAdmin() ? true : a?.active));
        setAssociates((res?.data?.associates || []).filter((a: any) => isAdmin() ? true : a?.active));
        setClauses((res?.data?.clauses || []));
        setLoading(false);
      }
    } as IRequestParams).then();
  }, [company]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      <div className={'flex flex-wrap flex-row'}>
        <div className={'flex flex-grow-1 py-4 px-3'}>
          <IndicatorShort title={trans(t, 'ent.raisonSociale')} text={company?.raisonSociale}/>
        </div>
        <div className={'flex flex-grow-1 py-4 px-3'}>
          <IndicatorShort title={trans(t, 'ent.formeJuridique')} value={company?.type} method={'list'} methodParams={{listKey: 'formesJuridiques'}}/>
        </div>
      </div>

      <div className={'flex flex-wrap flex-row'}>
        <div className={'flex flex-grow-1 py-4 px-3'}>
          <IndicatorSimple color={'accent3'} bgImage title={trans(t, `ent.nomCommercial`)}
                           text={company?.nomCommercial}/>
        </div>
        <div className={'flex flex-grow-1 py-4 px-3'}>
          <IndicatorSimple color={'accent4'} bgImage title={trans(t, `ent.dateCloture`)}
                           text={company?.fiscalYearEnd}/>
        </div>
        <div className={'flex flex-grow-1 py-4 px-3'}>
          <IndicatorSimple color={'accent2'} bgImage title={trans(t, `ent.capital`)}
                           value={company?.capital} method={'montant'}/>
        </div>

        <div className={'flex flex-grow-1 py-4 px-3'}>
          <IndicatorSimple color={'accent5'} bgImage title={trans(t, 'ent.creation')}
                           value={company?.creation} method={'date'}/>
        </div>
      </div>

      <Fieldset legend={(
        <>
          {ucfirst(trans(t, 'immatriculation'))}
          {company?.deleted && (
            <span className={'text-pink-500 text-sm mt-1' }> ({trans(t, 'ent.deregistered')})</span>
          )}
        </>
      )} toggleable className={'mb-3'}>
        <div className={'flex flex-column md:flex-row px-3'}>
          <div className={'flex flex-grow-1 py-2 px-3'}>
            <SimpleText title={trans(t, 'ent.siren')} text={company?.siren} copyButton={true}/>
          </div>
          {identificationInfo.map((identificationInfo, index) => (
            <div key={index} className={'flex flex-grow-1 py-2 px-3'}>
              <SimpleText title={trans(t, `ent.${identificationInfo.key}`)}
                          text={company ? company[identificationInfo.key] : null} copyButton={true}/>
            </div>
          ))}

          <div className={'flex flex-grow-1 py-2 px-3'}>
            <SimpleText title={trans(t, `ent.doWatch`)} value={company?.doWatch} method={'bool'}/>
          </div>

          {company?.deleted && (
            <div className={'flex flex-grow-1 py-2 px-3'}>
              <SimpleText title={trans(t, `ent.deregisteredDate`)}
                          value={company?.deleted} method={'date'}/>
            </div>
          )}
        </div>
      </Fieldset>

      <Fieldset legend={ucfirst(trans(t, 'additionalInfo'))} toggleable className={'mb-3'}>
        <div className={'flex flex-column md:flex-row px-3'}>
          <div className={'flex flex-grow-1 py-2 px-3'}>
            <SimpleText title={trans(t, 'ent.activity')} text={company?.activity} />
          </div>
          <div className={'flex flex-grow-1 py-2 px-3'}>
            <SimpleText title={trans(t, 'ent.typeTaux')} value={company?.typeTaux} method={'list'}
                        methodParams={{ listKey: 'typesTaux' }} />
          </div>

          <div className={'flex flex-grow-1 py-2 px-3'}>
            <SimpleText title={trans(t, 'ent.duration')}
                        text={company?.duration ? company.duration + ' ' + trans(t, 'year', 2) : 'NR'} />
          </div>
          {booolean.map((booolean, index) => (
            <div key={index} className={'flex flex-grow-1 py-2 px-3'}>
              <SimpleText title={trans(t, `ent.${booolean.key}`)}
                          value={company ? company[booolean.key] : null} method={'bool'} />
            </div>
          ))}
        </div>
      </Fieldset>

      <Fieldset legend={ucfirst(trans(t, 'specificActes'))} toggleable className={'mb-3'}>
        <div className={'flex flex-column md:flex-row px-3'}>
          <div className={'flex flex-grow-1 py-2 px-3'}>
            <SimpleText title={trans(t, `ent.isSpecificActes`)} value={company?.isSpecificActes} method={'bool'}/>
          </div>
          {clauses?.sort((a, b) => a?.clause.localeCompare(b?.clause))?.map((clause: any, index: number) => (
            clause?.clause !== 'other' ?
              <div key={index} className={'flex flex-grow-1 py-2 px-3'}>
                <SimpleText title={trans(t, `ent.specificClauses`)}
                            value={clause ? clause?.clause : null} method={'list'} methodParams={{listKey: 'specificClauses'}}/>
              </div>
              :
              <div key={index} className={'flex flex-grow-1 py-2 px-3'}>
                <SimpleText title={trans(t, 'ent.specificClauseOther')} text={company?.specificClauses}/>
              </div>
          ))}
        </div>
      </Fieldset>

      {company?.headquarterAddress !== null && company?.headquarterAddress !== undefined && company?.headquarterAddress.length > 0 && (
        <Fieldset legend={ucfirst(trans(t, 'contact'))} toggleable className={'mb-3'}>
          <div className={'flex flex-column md:flex-row py-2 px-3'}>
            <div className={'flex flex-grow-1 px-3'}>
              <AddressField title={trans(t, 'ent.headquarterAddress')} address={{
                address1: company?.headquarterAddress,
                postalCode: company?.headquarterPostalCode,
                city: company?.headquarterCity
              }} />
            </div>
          </div>
        </Fieldset>
      )}

      {loading ? (
        <>
          <Skeleton width={'100%'} height={'6rem'} className={'mb-3'}></Skeleton>

          <Skeleton width={'100%'} height={'6rem'} className={'mb-3'}></Skeleton>

          <Skeleton width={'100%'} height={'6rem'} className={'mb-3'}></Skeleton>

          <Skeleton width={'100%'} height={'6rem'} className={'mb-3'}></Skeleton>
        </>
      ) : (
        <>
          {dirigeantsPP.length > 0 &&
            <Fieldset legend={ucfirst(trans(t, 'dirigeant', dirigeantsPP.length)) + ' - ' + ucfirst(trans(t, 'personnePhysique', dirigeantsPP.length))} toggleable className={'mb-3'}>
              <DirigeantsPersonnesPhysiquesDatatable rows={dirigeantsPP} apim={apim} title={'none'} loading={loading} editMode={false} />
            </Fieldset>
          }

          {employees.length > 0 &&
            <Fieldset legend={ucfirst(trans(t, 'employee', employees.length))} toggleable>
              <EmployeesPersonnesPhysiquesDatatable rows={employees} apim={apim} title={'none'} loading={loading} editMode={false} />
            </Fieldset>
          }

          {dirigeantsPM.length > 0 &&
            <Fieldset legend={ucfirst(trans(t, 'dirigeant', dirigeantsPM.length)) + ' - ' + ucfirst(trans(t, 'personneMorale', dirigeantsPM.length))} toggleable className={'mb-3'}>
              <DirigeantsPersonnesMoralesDatatable rows={dirigeantsPM} apim={apim} title={'none'} loading={loading} editMode={false} />
            </Fieldset>
          }

          {associates.length > 0 &&
            <Fieldset legend={ucfirst(trans(t, 'repartitionCapital'))} toggleable className={'mb-3'}>
              <RepartitionCapitalDatatable apim={apim} rows={associates} t={t} title={'none'} loading={loading} editMode={false} />
            </Fieldset>
          }
        </>
      )}

      {(company?.events || []).length > 0 && (
        <Fieldset legend={ucfirst(trans(t, 'event', 2))} toggleable className={'mb-3'}>
          <SocieteTimeline data={(company?.events ?? []).filter((_e: any) => event?.date ? _e.date <= event?.date : true).sort((a: any, b: any) => a.date > b.date ? -1 : 1)} apim={apim} />
        </Fieldset>
      )}

      <div className={'flex flex-row justify-content-end'}>
        {isValidUUID(company?.company?.author) && (
          <div className={'flex px-3 align-items-center'}>
            <strong className={'titre titre--gray pr-2'}>{ucfirst(trans(t, 'author'))}</strong>
            <div>
              <Userpicture apim={apim} user={company.company.author} size={'small'} />
            </div>
          </div>
        )}

        {dates.map((date, index: number) => (
          company?.company && company.company[date.key] &&
          <div key={index} className={'flex px-3 align-items-center'}>
            <strong className={'titre titre--gray pr-2'}>{ucfirst(trans(t, date.key + '.at'))}</strong>
            <div>
              {dateString(company.company[date.key], 'DD/MM/YYYY') + ' ' + trans(t, 'at') + ' ' + dateString(company[date.key], 'HH:mm')!.replace(':', 'h')}
            </div>
          </div>
        ))}

        {isValidUUID(company?.author) && (
          <div className={'flex px-3 align-items-center'}>
            <strong className={'titre titre--gray pr-2'}>{ucfirst(trans(t, 'versionAuthor'))}</strong>
            <div>
              <Userpicture apim={apim} user={company.author} size={'small'} />
            </div>
          </div>
        )}

        {dates.map((date, index: number) => (
          company && company[date.key] &&
          <div key={index} className={'flex px-3 align-items-center'}>
            <strong className={'titre titre--gray pr-2'}>{ucfirst(trans(t, 'version' + ucfirst(date.key) + '.at'))}</strong>
            <div>
              {dateString(company[date.key], 'DD/MM/YYYY') + ' ' + trans(t, 'at') + ' ' + dateString(company[date.key], 'HH:mm')!.replace(':', 'h')}
            </div>
          </div>
        ))}
      </div>
    </>
  )
    ;
};
