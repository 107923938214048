import { LicencedOrgChart as OrgChart } from 'components';
import { trans } from 'utilities';

const color1: string = '#070AB0';
const color2: string = '#656565';
const color3: string = '#afafaf';

export const buildA8Company0Template = (t: any): OrgChart.template => {
  const template: OrgChart.template = Object.assign({}, OrgChart.templates.ana);

  const defs = '' +
    '<filter x="-50%" y="-50%" width="200%" height="200%" filterUnits="objectBoundingBox" id="a8-company-shadow">' +
    '<feOffset dx="0" dy="4" in="SourceAlpha" result="shadowOffsetOuter1" />' +
    '<feGaussianBlur stdDeviation="10" in="shadowOffsetOuter1" result="shadowBlurOuter1" />' +
    '<feColorMatrix values="0 0 0 0 0   0 0 0 0 0   0 0 0 0 0  0 0 0 0.1 0" in="shadowBlurOuter1" type="matrix" result="shadowMatrixOuter1" />' +
    '<feMerge><feMergeNode in="shadowMatrixOuter1" /><feMergeNode in="SourceGraphic" /></feMerge>' +
    '</filter>';

  const node = '' +
    '<rect filter="url(#a8-company-shadow)" x="0" y="0" height="120" width="350" fill="#ffffff" stroke-width="1" stroke="#eeeeee" rx="10" ry="10"></rect>' +
    '<rect fill="#ffffff" x="180" y="60" width="160" height="50" rx="10" ry="10" filter="url(#a8-company-shadow)"></rect>' +
    '<text style="font-size: 11px;" fill="' + color2 + '" x="330" y="75" text-anchor="end">' + trans(t, 'capital').toUpperCase() + '</text>' +
    '<image xlink:href="/assets/images/avatar/capital.png" x="190" y="68" width="32" height="32"></image>';

  const nodeMenuButton =
    '<g style="cursor: pointer;" transform="matrix(1,0,0,1,320,20)" data-ctrl-n-menu-id="{id}">'
    + '<rect x="-4" y="-10" fill="#000000" fill-opacity="0" width="22" height="22"></rect>'
    + '<circle cx="0" cy="0" r="2" fill="' + color1 + '"></circle><circle cx="7" cy="0" r="2" fill="' + color1 + '"></circle><circle cx="14" cy="0" r="2" fill="' + color1 + '"></circle>'
    + '</g>';

  const linkLabel = '<text text-anchor="left" fill="' + color1 + '" data-width="290" x="5" y="0" style="font-size:14px;">{val}</text>';

  template.defs = defs;
  template.size = [350, 120];
  template.node = node;
  template.img_0 = '';
  template.type = '<text style="font-size: 18px;" fill="' + color3 + '" x="15" y="105">{val}</text>';
  template.raisonSociale = '<text data-width="250" data-text-overflow="multiline" style="font-size: 16px; font-weight: 600;" fill="' + color1 + '" x="15" y="30">{val}</text>';
  template.dateCloture = '<text style="font-size: 14px;" fill="' + color2 + '" x="15" y="80">{val}</text>';
  template.capital = '<text style="font-size: 24px;" fill="' + color1 + '" x="325" y="103" text-anchor="end">{val}</text>';
  template.nodeMenuButton = nodeMenuButton;
  template.link_field_0 = linkLabel;

  return template;
};

export const buildA8CompanyGroupTemplate = (t: any): OrgChart.template => {
  const template: OrgChart.template = Object.assign({}, OrgChart.templates.a8_company_0);

  const node = '' +
    '<rect filter="url(#a8-company-shadow)" x="0" y="0" height="150" width="350" fill="#ffffff" stroke-width="1" stroke="#eeeeee" rx="10" ry="10"></rect>' +
    '<rect fill="#ffffff" x="180" y="60" width="160" height="50" rx="10" ry="10" filter="url(#a8-company-shadow)"></rect>' +
    '<text style="font-size: 11px;" fill="' + color2 + '" x="330" y="75" text-anchor="end">' + trans(t, 'capital').toUpperCase() + '</text>' +
    '<image xlink:href="/assets/images/avatar/capital.png" x="190" y="68" width="32" height="32"></image>';

  template.size = [350, 190];
  template.node = node;
  // expand link :
  template.expandLink = '<text style="font-size: 14px;" fill="' + color3 + '" x="160" y="140" text-anchor="middle">{val}</text>';

  return template;
};

export const buildA8CompanyTemplate = (t: any): OrgChart.template => {
  const template: OrgChart.template = Object.assign({}, OrgChart.templates.a8_company_0);

  const node = '' +
    '<rect filter="url(#a8-company-shadow)" x="0" y="0" height="185" width="350" fill="#ffffff" stroke-width="1" stroke="#eeeeee" rx="10" ry="10"></rect>' +
    '<rect fill="#ffffff" x="180" y="60" width="160" height="50" rx="10" ry="10" filter="url(#a8-company-shadow)"></rect>' +
    '<text style="font-size: 11px;" fill="' + color2 + '" x="330" y="75" text-anchor="end">' + trans(t, 'capital').toUpperCase() + '</text>' +
    '<image xlink:href="/assets/images/avatar/capital.png" x="190" y="68" width="32" height="32"></image>';

  template.size = [350, 190];
  template.node = node;
  // Beneficiaries :
  template.beneficiaryRatio = '<text style="font-size: 20px;" fill="' + color1 + '" x="305" y="160" text-anchor="middle">{val}</text>';
  template.beneficiaryName = '<text style="font-size: 16px;" fill="' + color2 + '" x="70" y="148" text-anchor="start" data-width="180" data-text-overflow="ellipsis">{val}</text>';
  template.beneficiaryRole = '<text style="font-size: 14px;" fill="' + color3 + '" x="70" y="168" text-anchor="start">{val}</text>';
  template.beneficiaryImg = '<image preserveAspectRatio="xMidYMid slice" clip-path="url(#{randId})" xlink:href="{val}" x="5" y="128" width="48" height="48"></image>';

  template.min = Object.assign({}, OrgChart.templates.a8_company_group);
  template.min.field_0 = "";
  template.min.imgs = "";
  template.min.img_0 = "";

  return template;
};

export const buildA8Company2Template = (t: any): OrgChart.template => {
  const template: OrgChart.template = Object.assign({}, OrgChart.templates.a8_company);

  const node = '' +
    '<rect filter="url(#a8-company-shadow)" x="0" y="0" height="245" width="350" fill="#ffffff" stroke-width="1" stroke="#eeeeee" rx="10" ry="10"></rect>' +
    '<rect fill="#ffffff" x="180" y="60" width="160" height="50" rx="10" ry="10" filter="url(#a8-company-shadow)"></rect>' +
    '<text style="font-size: 11px;" fill="' + color2 + '" x="330" y="75" text-anchor="end">' + trans(t, 'capital').toUpperCase() + '</text>' +
    '<image xlink:href="/assets/images/avatar/capital.png" x="190" y="68" width="32" height="32"></image>';

  template.size = [350, 270];
  template.node = node;
  // Beneficiaries :
  template.beneficiary2Ratio = '<text style="font-size: 20px;" fill="' + color1 + '" x="305" y="220" text-anchor="middle">{val}</text>';
  template.beneficiary2Name = '<text style="font-size: 16px;" fill="' + color2 + '" x="70" y="208" text-anchor="start" data-width="180" data-text-overflow="ellipsis">{val}</text>';
  template.beneficiary2Role = '<text style="font-size: 14px;" fill="' + color3 + '" x="70" y="228" text-anchor="start">{val}</text>';
  template.beneficiary2Img = '<image preserveAspectRatio="xMidYMid slice" clip-path="url(#{randId})" xlink:href="{val}" x="5" y="188" width="48" height="48"></image>';

  return template;
};

export const buildA8Company3Template = (t: any): OrgChart.template => {
  const template: OrgChart.template = Object.assign({}, OrgChart.templates.a8_company_2);

  const node = '' +
    '<rect filter="url(#a8-company-shadow)" x="0" y="0" height="305" width="350" fill="#ffffff" stroke-width="1" stroke="#eeeeee" rx="10" ry="10"></rect>' +
    '<rect fill="#ffffff" x="180" y="60" width="160" height="50" rx="10" ry="10" filter="url(#a8-company-shadow)"></rect>' +
    '<text style="font-size: 11px;" fill="' + color2 + '" x="330" y="75" text-anchor="end">' + trans(t, 'capital').toUpperCase() + '</text>' +
    '<image xlink:href="/assets/images/avatar/capital.png" x="190" y="68" width="32" height="32"></image>';

  template.size = [350, 340];
  template.node = node;
  // Beneficiaries :
  template.beneficiary3Ratio = '<text style="font-size: 20px;" fill="' + color1 + '" x="305" y="280" text-anchor="middle">{val}</text>';
  template.beneficiary3Name = '<text style="font-size: 16px;" fill="' + color2 + '" x="70" y="268" text-anchor="start" data-width="180" data-text-overflow="ellipsis">{val}</text>';
  template.beneficiary3Role = '<text style="font-size: 14px;" fill="' + color3 + '" x="70" y="288" text-anchor="start">{val}</text>';
  template.beneficiary3Img = '<image preserveAspectRatio="xMidYMid slice" clip-path="url(#{randId})" xlink:href="{val}" x="5" y="248" width="48" height="48"></image>';

  return template;
};

export const buildA8Company4Template = (t: any): OrgChart.template => {
  const template: OrgChart.template = Object.assign({}, OrgChart.templates.a8_company_3);

  const node = '' +
    '<rect filter="url(#a8-company-shadow)" x="0" y="0" height="365" width="350" fill="#ffffff" stroke-width="1" stroke="#eeeeee" rx="10" ry="10"></rect>' +
    '<rect fill="#ffffff" x="180" y="60" width="160" height="50" rx="10" ry="10" filter="url(#a8-company-shadow)"></rect>' +
    '<text style="font-size: 11px;" fill="' + color2 + '" x="330" y="75" text-anchor="end">' + trans(t, 'capital').toUpperCase() + '</text>' +
    '<image xlink:href="/assets/images/avatar/capital.png" x="190" y="68" width="32" height="32"></image>';

  template.size = [350, 410];
  template.node = node;
  // Beneficiaries :
  template.beneficiary4Ratio = '<text style="font-size: 20px;" fill="' + color1 + '" x="305" y="340" text-anchor="middle">{val}</text>';
  template.beneficiary4Name = '<text style="font-size: 16px;" fill="' + color2 + '" x="70" y="328" text-anchor="start" data-width="180" data-text-overflow="ellipsis">{val}</text>';
  template.beneficiary4Role = '<text style="font-size: 14px;" fill="' + color3 + '" x="70" y="348" text-anchor="start">{val}</text>';
  template.beneficiary4Img = '<image preserveAspectRatio="xMidYMid slice" clip-path="url(#{randId})" xlink:href="{val}" x="5" y="308" width="48" height="48"></image>';

  return template;
};

export const buildA8Company5Template = (t: any): OrgChart.template => {
  const template: OrgChart.template = Object.assign({}, OrgChart.templates.a8_company_4);

  const node = '' +
    '<rect filter="url(#a8-company-shadow)" x="0" y="0" height="430" width="350" fill="#ffffff" stroke-width="1" stroke="#eeeeee" rx="10" ry="10"></rect>' +
    '<rect fill="#ffffff" x="180" y="60" width="160" height="50" rx="10" ry="10" filter="url(#a8-company-shadow)"></rect>' +
    '<text style="font-size: 11px;" fill="' + color2 + '" x="330" y="75" text-anchor="end">' + trans(t, 'capital').toUpperCase() + '</text>' +
    '<image xlink:href="/assets/images/avatar/capital.png" x="190" y="68" width="32" height="32"></image>';

  template.size = [350, 480];
  template.node = node;
  // Beneficiaries :
  template.beneficiary5Ratio = '<text style="font-size: 20px;" fill="' + color1 + '" x="305" y="400" text-anchor="middle">{val}</text>';
  template.beneficiary5Name = '<text style="font-size: 16px;" fill="' + color2 + '" x="70" y="388" text-anchor="start" data-width="180" data-text-overflow="ellipsis">{val}</text>';
  template.beneficiary5Role = '<text style="font-size: 14px;" fill="' + color3 + '" x="70" y="408" text-anchor="start">{val}</text>';
  template.beneficiary5Img = '<image preserveAspectRatio="xMidYMid slice" clip-path="url(#{randId})" xlink:href="{val}" x="5" y="368" width="48" height="48"></image>';

  return template;
};

export const buildA8Company5pTemplate = (t: any): OrgChart.template => {
  const template: OrgChart.template = Object.assign({}, OrgChart.templates.a8_company_5);

  const node = '' +
    '<rect filter="url(#a8-company-shadow)" x="0" y="0" height="465" width="350" fill="#ffffff" stroke-width="1" stroke="#eeeeee" rx="10" ry="10"></rect>' +
    '<rect fill="#ffffff" x="180" y="60" width="160" height="50" rx="10" ry="10" filter="url(#a8-company-shadow)"></rect>' +
    '<text style="font-size: 11px;" fill="' + color2 + '" x="330" y="75" text-anchor="end">' + trans(t, 'capital').toUpperCase() + '</text>' +
    '<image xlink:href="/assets/images/avatar/capital.png" x="190" y="68" width="32" height="32"></image>';

  template.size = [350, 500];
  template.node = node;

  // Beneficiaries :
  template.moreBeneficiariesLink = '<text style="font-size: 16px;" fill="' + color1 + '" x="175" y="445" text-anchor="middle">{val}</text>';

  return template;
};
