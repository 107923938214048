import React from 'react';
import { trans, ucfirst } from 'utilities';
import { APIListField, EditorField, TextField } from 'forms/index';

export const StatutDirigeantForm = (props: any) => {
  const { t, control, onFieldChange, errorMessage } = props;

  return (
    <div className={'flex flex-column col-12'}>
      <div className={'flex flex-wrap flex-row col-12'}>

        <div className={'col-4'}>
          <APIListField listKey={'clientStatus'} fieldKey={'statutPreconise'} control={control} onFieldChange={onFieldChange} errorMessage={errorMessage}
                        label={ucfirst(trans(t, 'statutPreconise'))} showClear rules={{ required: trans(t, 'form|requiredField') }} classes={'w-full'}/>
        </div>

        <div className={'col-4'}>
          <TextField fieldKey={'caisseRetraitePrevoyance'} control={control} onFieldChange={onFieldChange}
                     errorMessage={errorMessage} label={ucfirst(trans(t, 'caisseRetraitePrevoyance'))} classes={'w-full'}
                     rules={{ required: trans(t, 'form|requiredField') }}/>
        </div>

      </div>
      <EditorField classes={'col'} fieldKey={'comment'} control={control} onFieldChange={onFieldChange}
                   errorMessage={errorMessage} label={ucfirst(trans(t, 'comment'))}
                   rules={{ required: trans(t, 'form|requiredField') }} />
    </div>
  )
};
