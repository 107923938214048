import React from 'react';
import { classNames } from 'primereact/utils';
import { Calendar } from 'primereact/calendar';
import { BaseTimeField } from './BaseTimeField';
import { trans, asDate } from 'utilities';
import { useApim } from 'services';

export const DateTimeField = (props: any) => {
  const { fieldKey, dateFormat, control, onFieldChange, errorMessage, rules,
    label, classes, addon, addonPosition, disabled, showButtonBar, showTime, hourFormat, maxDate, view } = props;

  const apim = useApim();
  const { t } = apim.di();

  const renderInput = (field: any, fieldState: any) =>
    <span className={'p-float-label'}>
      <Calendar className={classNames('text-ucfirst w-full', {'p-invalid': fieldState.error})} value={field.value ? asDate(field.value as string) : null}
                aria-invalid={fieldState.error}
                onChange={(e) => {
                  const newDate: Date = asDate(e.target.value as unknown as string);
                  if (!showTime) newDate.setHours(newDate.getHours() + 12);

                  onFieldChange(field, fieldState, newDate, 'date');
                }}
                showButtonBar={showButtonBar || true} dateFormat={dateFormat || 'dd/mm/yy'} id={field.name}
                disabled={disabled} showTime={showTime} hourFormat={hourFormat || '24'} maxDate={maxDate} view={view}/>
      {(label || field.name) && (
        <label className={'text-ucfirst p-float-label-label'} htmlFor={field.name}>
          <span>{label ?? trans(t, field.name)}</span>
        </label>
      )}
    </span>
  ;

  return <BaseTimeField fieldKey={fieldKey} control={control} onFieldChange={onFieldChange} errorMessage={errorMessage} rules={rules} classes={classes}
                        addon={addon} addonPosition={addonPosition} renderInput={renderInput} />
};
