import { MutableRefObject } from 'react';
import { hookstate, useHookstate, State } from '@hookstate/core';

interface IThemeState {
  menuActive: boolean,
  desktopMenuActive: boolean,
  rightMenuActive: boolean,
  mobileMenuActive: boolean,
  mobileTopbarActive: boolean,
  inlineMenuActive: boolean,
  ticketCollectorActive: boolean,

  menuClick: boolean,
  topbarItemClick: boolean,
  inlineMenuClick: boolean,

  menuMode: null | string,
  activeTopbarItem: null | string,

  toast: MutableRefObject<any> | null,
  toastError: MutableRefObject<any> | null,
  toastSuccess: MutableRefObject<any> | null,
}

const emptyState: IThemeState = {
  menuActive: false,
  desktopMenuActive: true,
  rightMenuActive: false,
  mobileMenuActive: false,
  mobileTopbarActive: false,
  inlineMenuActive: false,
  ticketCollectorActive: false,

  menuClick: false,
  topbarItemClick: false,
  inlineMenuClick: false,

  menuMode: 'static',
  activeTopbarItem: null,

  toast: null,
  toastError: null,
  toastSuccess: null,
} as IThemeState;
const state: State<IThemeState> = hookstate(Object.assign({}, emptyState));
const wrapper = (s: State<IThemeState>) => ({
  isBiggerDesktop: () => window.innerWidth > 1800,
  isDesktop: () => window.innerWidth > 1200,
  isNotebook: () => window.innerWidth > 768 && window.innerWidth < 1200,
  isTablet: () => window.innerWidth > 576 &&  window.innerWidth < 768,
  isMobile: () => window.innerWidth < 576,
  isMenuActive: () => s.menuActive.get() === true,
  toggleMenu: () => s.menuActive.set(!s.menuActive.get()),
  isDesktopMenuActive: () => s.desktopMenuActive.get() === true,
  toggleDesktopMenu: (val: boolean | null = null) => s.desktopMenuActive.set(val || !s.desktopMenuActive.get()),
  isRightMenuActive: () => s.rightMenuActive.get() === true,
  toggleRightMenu: (val: boolean | null = null) => s.rightMenuActive.set(val || !s.rightMenuActive.get()),
  isMobileMenuActive: () => s.mobileMenuActive.get() === true,
  toggleMobileMenu: (val: boolean | null = null) => s.mobileMenuActive.set(val || !s.mobileMenuActive.get()),
  isMobileTopbarActive: () => s.mobileTopbarActive.get() === true,
  toggleMobileTopbarMenu: (val: boolean | null = null) => s.mobileTopbarActive.set(val || !s.mobileTopbarActive.get()),
  isInlineMenuActive: () => s.inlineMenuActive.get() === true,
  toggleInlineMenu: (val: boolean | null = null) => s.inlineMenuActive.set(val || !s.inlineMenuActive.get()),
  isTicketCollectorActive: () => s.ticketCollectorActive.get() === true,
  toggleTicketCollector: () => s.ticketCollectorActive.set(!s.ticketCollectorActive.get()),

  menuMode: () => s.menuMode.get(),
  isMenuClicked: () => s.menuClick.get() === true,
  toggleMenuClick: (val = false) => s.menuClick.set(val),
  activeTopbarItem: () => s.activeTopbarItem.get(),
  isTopbarItemClicked: () => s.topbarItemClick.get() === true,
  toggleTopbarItemClick: (val = false) => s.topbarItemClick.set(val),
  isInlineMenuClicked: () => s.inlineMenuClick.get() === true,
  toggleInlineMenuClick: (val = false) => s.inlineMenuClick.set(val),

  onTopbarItemClick: (event: any) => {
    s.topbarItemClick.set(true);

    if (s.activeTopbarItem.get() === event.item) s.activeTopbarItem.set(null);
    else {
      s.activeTopbarItem.set(event.item);
    }

    event.originalEvent.preventDefault();
  },

  setToast: (toastRef: any, type: string | null = 'default') => {
    if ('error' === type) return s.toastError.set(toastRef);
    if ('success' === type) return s.toastSuccess.set(toastRef);
    s.toast.set(toastRef)
  },
  toast: (type: string | null = 'default') => {
    if ('error' === type) return s.get({ noproxy: true }).toastError!;
    if ('success' === type) return s.get({ noproxy: true }).toastSuccess!;
    return s.get({ noproxy: true }).toast!;
  },

  state: () => s,
});

export const useThemeState = () => wrapper(useHookstate(state));
