import React, { useCallback, useState } from 'react';
import { AxiosResponse } from 'axios';

import { Column } from 'primereact/column';

import { IRequestParams, isAdmin, isExpert } from 'services';
import { dialog, iri, trans, triggerFormReset, triggerFormSubmit, ucfirst } from 'utilities';
import {
  DatatableWrapper,
  validCell,
  handlePersonnePhysiqueAddress, handlePersonnePhysiqueRelatedEntity, onPersonnePhysiqueAddSubmit,
  addressBody, birthDateBody, ssnBody, emailBody, phone1Body, simpleCheckboxEditor, personCell
} from 'components';
import { FormWrapper } from 'forms';

import { forEach, omit } from 'lodash';
import appUri from 'config/appUri.json';

export const AssociatesPersonnesPhysiquesDatatable = (props: any) => {
  const { rows, setRows, tableKey, title, editMode, params, context, apim, dirigeants, header } = props;
  const { t } = apim.di();

  const [loading, setLoading] = useState<boolean>(false);

  // /!\ WARNING /!\ Let's keep 'personnesPhysiques' instead of 'associates' as resourceType on purpose
  // because (believe it or not but) this way is simpler during edit process :)
  const resourceType = 'personnesPhysiques';

  const activeBodyTemplate = (rowData: any) => validCell(rowData?.active);
  const fullNameBodyTemplate = (rowData: any) => personCell(rowData?.personnePhysique, {
    url: appUri.cie.per.phy.page.replace(':id', rowData?.personnePhysique?.id),
    label: trans(t, 'seeDetails')
  });

  const onRowEditCallback = (patched: any) => {
    if (!patched || !patched[resourceType] || !patched[resourceType].id) return;

    // Handle personnesPhysiquesData (personne_physique_datas).
    handlePersonnePhysiqueRelatedEntity(apim, context, 'personnesPhysiquesData', patched, setLoading, '', header);

    // Handle associates.
    handlePersonnePhysiqueRelatedEntity(apim, context, 'associates', patched, setLoading, '', header);

    // Handle address.
    handlePersonnePhysiqueAddress(patched[resourceType], apim, header);
  };

  const hookRowEdit = (_rows: any[], editedRow: any) => {
    if (!editedRow) return;

    const original: any = rows.filter((r: any) => r.id === editedRow.id)[0];
    const personnePhysique: any = editedRow.personnePhysique;
    let tmp: any = omit(editedRow, ['personnePhysique']);
    const patched: any = {
      personnesPhysiques: { id: original?.personnePhysique?.id },
      personnesPhysiquesData: { id: original?.personnePhysique?.latestVersion?.id },
      associates: { id: original?.id }
    };

    // Let's pick only patched data.
    // 1. "personne_physiques" fields.
    forEach(['lastName', 'firstName', 'birthDate', 'socialSecurityNumber', 'email', 'phone1'], ((field: string) => {
      if (undefined === tmp['personnePhysique.' + field] || tmp['personnePhysique.' + field] === original?.personnePhysique[field]) {
        tmp = omit(tmp, 'personnePhysique.' + field);

        return;
      }

      patched.personnesPhysiques[field] = tmp['personnePhysique.' + field];
      tmp = omit(tmp, 'personnePhysique.' + field);

      if ('lastName' === field || 'firstName' === field) {
        patched.personnesPhysiquesData[field] = patched.personnesPhysiques[field];
      }
    }));

    // 2. "associates" (root fields).
    forEach(['active'], ((field: string) => {
      if (undefined === tmp[field] || tmp[field] === original[field]) {
        tmp = omit(tmp, field);

        return;
      }

      patched.associates[field] = tmp[field];
      tmp = omit(tmp, field);
    }));

    // Then update rows using a proper rows array.
    // return formatPersonPhysiquePatched(_rows, personnePhysique, tmp, patched, 'associates');
    return { formattedRows: _rows, patched: patched, id: personnePhysique?.id };
  };

  const onDelete = (row: any) => {
    if (row?.id) {
      apim.deleteEntity({
        resourceType: 'associates',
        id: row?.id,
        headers: header,
      } as IRequestParams).then();
    }
  };

  const onNew = () => {
    dialog(t, {
      header: trans(t,'form|company.personnePhysiqueAssociateAdd'),
      icon: 'none',
      message: renderAddForm(),
      accept: () => triggerFormSubmit('a8-form-company-app-add'),
      reject: () => triggerFormReset('a8-form-company-app-reset')
    });
  };

  // Handle submission then refresh table rows.
  const onAddSubmit = useCallback(
    (formData: any) => {
      // Check if this person is a dirigeantPersonnePhysique.
      const match: any = (dirigeants || []).filter((d: any) => formData?.personnePhysiqueId === d?.personnePhysique?.id);
      if (match.length > 0) {
        apim.postEntity({
          resourceType: 'associates',
          data: {
            dirigeantPersonnePhysique: match[0]['@id'],
            companyData: iri('dossierCompanyData', context.id)
          },
          headers: header,
          setLoading,
          success: (res: AxiosResponse) => {
            const _rows: any[] = [...rows];
            _rows.push({
              ...omit(res.data, ['dirigeantPersonnePhysique']),
              ...{
                dirigeantPersonnePhysique: omit(res.data.dirigeantPersonnePhysique, ['personnePhysique']),
                personnePhysique: res.data.dirigeantPersonnePhysique.personnePhysique
              }
            });
            setRows(_rows);
          }
        } as IRequestParams).then();
      } else {
        onPersonnePhysiqueAddSubmit(apim, context, formData, rows, setRows, 'associates', setLoading, '', header);
      }
    }
    , [rows]); // eslint-disable-line react-hooks/exhaustive-deps

  // Wrap form render into a useCallback to avoid multiple FormWrapper recalls du to form fields updates.
  const renderAddForm = useCallback(() =>
      <FormWrapper classes={'grid p-fluid w-12'} resourceType={resourceType} formKeyPrefix={'add_pp_dialog'} cancelLink multiple context={context}
                   resetClass={'a8-form-company-app-reset'} submitClass={'a8-form-company-app-add'} onSubmit={onAddSubmit} hideReload />
    , [context?.companyId, rows]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <DatatableWrapper resourceType={resourceType} tableKey={tableKey || 'associates-personnes-physiques'} noFilters noGlobalFilter params={params} isLoading={loading} onRefresh={setRows}
                      title={title || (ucfirst(trans(t, 'associate', rows.length)) + ' - ' + ucfirst(trans(t, 'personnePhysique', rows.length)))} onDelete={onDelete} onNew={onNew}
                      addTitle={trans(t, 'table|add.associate')} additionalData={{ companyData: iri('dossierCompanyData', context?.id) }} paginator={false}
                      editMode={(editMode && (isAdmin() || isExpert())) || false} hookRowEdit={hookRowEdit} onRowEditCallback={onRowEditCallback}
                      rows={(rows || []).sort((a: any, b: any) => a.personnePhysique?.lastName?.localeCompare(b.personnePhysique?.lastName) || a.personnePhysique?.firstName?.localeCompare(b.personnePhysique?.firstName))}>
      <Column field={'personnePhysique.fullName'} header={trans(t, 'fullName')} body={fullNameBodyTemplate}
              style={{ minWidth: '250px', width: '250px' }} />
      <Column field={'personnePhysique.birthDate'} header={trans(t, 'birthDate')} align={'center'} alignHeader={'center'} body={birthDateBody}
              style={{ minWidth: '100px', width: '100px' }} />
      <Column field={'personnePhysique.socialSecurityNumber'} header={trans(t, 'socialSecurityNumber')} align={'center'} alignHeader={'center'} body={ssnBody} />
      <Column field={'personnePhysique.address'} header={trans(t, 'address')} body={addressBody} />
      <Column field={'personnePhysique.email'} header={trans(t, 'email')} body={emailBody} />
      <Column field={'personnePhysique.phone1'} header={trans(t, 'phone')} body={phone1Body} />
      {isAdmin() && <Column field={'active'} header={trans(t, 'active')} body={activeBodyTemplate} align={'center'} alignHeader={'center'}
                             editor={(options) => simpleCheckboxEditor(options, {label: trans(t, 'table|active')})}
                             style={{ minWidth: '100px', width: '100px' }} />}
    </DatatableWrapper>
  );
};
