import React, { useEffect, useState } from 'react';
import { AxiosResponse } from 'axios';
import { Skeleton } from 'primereact/skeleton';
import { Image } from 'primereact/image';

import { IRequestParams } from 'services';

export const ManagedImage = (props: any) => {
  const { apim, imageId, imageUrl, imageAlt, width, height, SkeletonShape, SkeletonSize, classes, imageStyle } = props;

  const [url, setUrl] = useState<string>(imageUrl);
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    if (!url) {
      setLoading(true);

      apim.fetchEntity({
        resourceType: 'images',
        id: imageId,
        setLoading,
        notif: false,
        success: (res: AxiosResponse) => {
          if (!res?.data) return;
          setUrl(res.data.url ?? null);
        }
      } as IRequestParams).then();
    }
  }, [imageId]); // eslint-disable-line react-hooks/exhaustive-deps

  if (loading) return <Skeleton shape={SkeletonShape ?? 'circle'} size={SkeletonSize ?? width ?? '3rem'}/>;

  return <Image src={url ?? '/assets/icons/missing-image.png'} alt={imageAlt} width={width} height={height} className={classes} imageStyle={imageStyle}/>;
};
