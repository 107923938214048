import React, { useEffect } from 'react';

import { trans } from 'utilities';
import { ShortcutsDatatable } from 'components';
import { useAppState } from 'states';
import { useApim } from 'services';

export const ShortcutsList = () => {
  const apim = useApim();
  const { t } = apim.di();
  const appState = useAppState();

  // Update Page Header.
  useEffect(() => {
    appState.setPageTitle(trans(t, 'menu|pages.title.shortcuts'));
    appState.setBreadcrumb([{label: trans(t, 'shortcut', 1)}]);
    appState.setPageActions([]);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div className={'col-12'}>
      <div className={'card'}>
        <ShortcutsDatatable tableKey={'shortcuts'} noFilters title={trans(t, 'menu|pages.title.shortcuts')} apim={apim} />
      </div>
    </div>
  );
};
