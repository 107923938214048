import React, { useEffect, useState } from 'react';
import { Column } from 'primereact/column';
import { Tooltip } from 'primereact/tooltip';
import { SpeedDial } from 'primereact/speeddial';

import { IRequestParams, isAdmin, isExpert, isSuperAdmin } from 'services';
import { dateString, dialog, trans, ucfirst } from 'utilities';
import { DatatableWrapper, dateTimeCell, validCell } from 'components';
import { useAppState } from 'states';

import appUri from 'config/appUri.json';

export const PrevoyanceContractsDatatable = (props: any) => {
  const { lazyConfig, apim, tablekey, title, params } = props;
  const { t, navigate } = apim.di();
  const appState = useAppState();

  const lazyC = { ...{ sortField: 'name', sortOrder: 1 }, ...lazyConfig };
  const [disabledItems, setDisabledItems] = useState<any[]>([]);
  const [prevoyanceContractTypes, setPrevoyanceContractTypes] = useState<any[]>([]);

  useEffect(() => {
    apim.getList('prevoyanceContractTypes', {setter: setPrevoyanceContractTypes} as IRequestParams).then();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const contracyTypeBodyTemplate = (rowData: any) => {
    const match: any = (prevoyanceContractTypes || []).filter((m: any) => rowData?.type?.toLowerCase() === m?.value);
    return match.length > 0 ? match[0].translated : rowData?.type;
  };

  const proPersoBodyTemplate = (rowData: any) => {
    if (rowData?.proPerso === 'pro') {
      return ucfirst(trans(t, 'professional'));
    } else {
      return ucfirst(trans(t, 'personnal'));
    }
  };

  const companyBodyTemplate = (rowData: any) => <span>{rowData?.insuranceCompany?.name}</span>;
  const dateEffetBodyTemplate = (rowData: any) => dateTimeCell(rowData?.dateEffet);
  const activeBodyTemplate = (rowData: any) => validCell(rowData?.active);
  const updatedBodyTemplate = (rowData: any) => dateTimeCell(rowData?.updated);
  const createdBodyTemplate = (rowData: any) => dateTimeCell(rowData?.created);
  const deletedBodyTemplate = (rowData: any) => dateTimeCell(rowData?.deleted);

  const periodeResiliationBodyTemplate = (rowData: any) => {
    if (rowData.periodeResiliation === null
      || rowData.periodeResiliation === undefined ) {
      return 'N/A';
    }

    if (rowData.periodeResiliation === 'tout_moment') {
      return trans(t, 'anyTime');

    } else if (rowData.periodeResiliation === 'tout_moment_apres_date') {
      return trans(t, 'startingFrom') + ' ' + dateString(rowData?.dateResiliation);

    } else {
      return dateTimeCell(rowData?.dateResiliation);
    }
  }

  const actionsBodyTemplate = (rowData: any) => {
    let items = [];

    if (isAdmin() || isExpert()) {
      items.push({
        label: ucfirst(trans(t, 'edit')),
        icon: 'pi pi-pencil',
        className: 'bg-indigo-500',
        command: () => {
          navigate(appUri.dos.page.replace(':id', (appState.dossier()?.id ?? '_')) + '/edition/contrats/prevoyance/contrat/' + rowData?.latestVersion?.id);
        }
      });
    }

    if (isAdmin()) {
      items.push({
        label: rowData?.active ? ucfirst(trans(t, 'system|actions.disable')) : ucfirst(trans(t, 'system|actions.enable')),
        icon: rowData?.active ? 'pi pi-ban' : 'pi pi-check',
        className: 'bg-orange-500',
        command: () => {
          if (rowData?.id) {
            const active= !rowData.active;
            const action= active ? 'enable' : 'disable';

            apim.patchEntity({
              resourceType: 'prevoyanceContracts',
              id: rowData.id,
              data: { active: active },
              notifSuccess: {
                summary: trans(t, 'notification|prevoyanceContract.'+action+'.summary'),
                details: trans(t, 'notification|prevoyanceContract.'+action+'.details'),
              },
              success: () => {
                rowData.active = active;
              },
            } as IRequestParams);
          }
        }
      });

      if (rowData.deleted === null || rowData.deleted === undefined) {
        items.push({
          label: ucfirst(trans(t, 'system|actions.archive')),
          icon: 'pi pi-folder',
          className:'bg-red-500',
          command: () => {
            dialog(t, {
              message: ucfirst(trans(t, 'system|confirmations.prevoyanceContract.archive')),
              accept: () => {
                if (rowData?.id) {
                  const deleted = new Date();
                  apim.patchEntity({
                    resourceType: 'prevoyanceContracts',
                    id: rowData?.id,
                    data: {
                      active: false,
                      deleted: deleted
                    },
                    notifSuccess: {
                      summary: trans(t, 'notification|prevoyanceContract.archive.summary'),
                      details: trans(t, 'notification|prevoyanceContract.archive.details'),
                    },
                    success: () => {
                      if (isSuperAdmin()) {
                        rowData.deleted = deleted;
                        rowData.active = false;
                      } else {
                        const _disabledItems: any[] = disabledItems.filter((d: string) => d !== rowData.id);
                        _disabledItems.push(rowData.id);
                        setDisabledItems(_disabledItems);
                      }
                    },
                  } as IRequestParams);
                }
              },
              acceptClassName: 'p-button-danger',
              rejectClassName: 'p-button-text p-button-primary'
            });
          }
        });
      }
    }

    if (isSuperAdmin()) {
      items.push({
        label: ucfirst(trans(t, 'system|actions.delete')),
        icon: 'pi pi-trash',
        className:'bg-red-500',
        command: () => {
          dialog(t, {
            message: ucfirst(trans(t, 'system|confirmations.prevoyanceContract.delete')),
            accept: () => {
              if (rowData?.id) {
                apim.deleteEntity({
                  resourceType: 'prevoyanceContracts',
                  id: rowData?.id,
                  success: () => {
                    const _disabledItems: any[] = disabledItems.filter((d: string) => d !== rowData.id);
                    _disabledItems.push(rowData.id);
                    setDisabledItems(_disabledItems);
                  },
                } as IRequestParams);
              }
            },
            acceptClassName: 'p-button-danger',
            rejectClassName: 'p-button-text p-button-primary'
          });
        }
      });

      if (rowData.deleted !== null && rowData.deleted !== undefined) {
        items.push({
          label: ucfirst(trans(t, 'system|actions.unarchive')),
          icon: 'pi pi-folder-open',
          className: 'bg-red-500',
          command: () => {
            dialog(t, {
              message: ucfirst(trans(t, 'system|confirmations.prevoyanceContract.unarchive')),
              accept: () => {
                if (rowData?.id) {
                  apim.patchEntity({
                    resourceType: 'prevoyanceContracts',
                    id: rowData?.id,
                    data: {
                      active: true,
                      deleted: null
                    },
                    notifSuccess: {
                      summary: trans(t, 'notification|prevoyanceContract.unarchive.summary'),
                      details: trans(t, 'notification|prevoyanceContract.unarchive.details'),
                    },
                    success: () => {
                      rowData.deleted = null;
                      rowData.active = true;
                    },
                  } as IRequestParams);
                }
              },
              acceptClassName: 'p-button-danger',
              rejectClassName: 'p-button-text p-button-primary'
            });
          }
        });
      }
    }

    return <>
      <Tooltip target={'.a8-speedial-datatable .p-speeddial-action'} position={'top'} mouseTrack />
      <SpeedDial className={'a8-speedial-datatable relative z-5'} model={items} direction={'left'} transitionDelay={40} showIcon={'pi pi-ellipsis-v'} hideIcon={'pi pi-times'} buttonClassName={'p-button-text'} />
    </>
  };

  const onRowClick = (rowData: any) => navigate(appUri.dos.page.replace(':id', (appState.dossier()?.id ?? '_')) + '/prevoyance/contrats/version/' + rowData?.latestVersion?.id);

  return (
    <DatatableWrapper resourceType={'prevoyanceContractsIndex'} tableKey={tablekey ?? 'prevoyanceContracts'}
                      title={title ?? trans(t, 'contract', 2)} lazyConfig={lazyC} params={params} noFilters onRowClick={onRowClick}>
      <Column field={'name'} header={ucfirst(trans(t, 'form|contract.name'))} sortable />
      <Column field={'type'} header={ucfirst(trans(t, 'form|contract.type'))} sortable align={'center'} body={contracyTypeBodyTemplate} />
      <Column field={'proPerso'} header={ucfirst(trans(t, 'form|contract.proPerso'))} sortable align={'center'} body={proPersoBodyTemplate} />
      <Column field={'insuranceCompany.name'} header={trans(t, 'form|contract.company')} sortable align={'center'} body={companyBodyTemplate} />
      <Column field={'dateEffet'} header={trans(t, 'form|contract.date_effet')} sortable align={'center'} body={dateEffetBodyTemplate} />
      <Column field={'periodeResiliation'} header={trans(t, 'form|contract.periode_resiliation')} sortable align={'center'} body={periodeResiliationBodyTemplate} />
      <Column field={'updated'} header={trans(t, 'updated')} sortable align={'center'} body={updatedBodyTemplate} style={{ width: '225px' }} />
      <Column field={'created'} header={trans(t, 'created')} sortable align={'center'} body={createdBodyTemplate} style={{ width: '225px' }} />
      {isSuperAdmin() &&
        <Column field={'deleted'} header={trans(t, 'deleted')} sortable align={'center'} body={deletedBodyTemplate} style={{ width: '100px' }} />}
      {(isAdmin() || isExpert()) &&
        <Column field={'active'} header={trans(t, 'active')} sortable dataType={'boolean'} align={'center'} body={activeBodyTemplate} style={{ width: '100px' }} />}
      {(isAdmin() || isExpert()) && <Column header={trans(t, 'system|action', 2)} align={'right'} body={actionsBodyTemplate} style={{ width: '80px', maxWidth: '80px' }} />}
    </DatatableWrapper>
  );
};
