import React, { useEffect, useState } from 'react';

import { ProgressBar } from 'primereact/progressbar';
import { Tree } from 'primereact/tree';
import { classNames } from 'primereact/utils';
import { Dialog } from 'primereact/dialog';
import { Message } from 'primereact/message';

import { addShortcut, isValidUUID, sendNotification, trans, ucfirst } from 'utilities';
import { SchemaMenu } from 'components';
import { isClient, subscribe } from 'services';
import { useFormState } from 'states';

import appUri from 'config/appUri.json';
import { forEach } from 'lodash';

export const DossierSchema = (props: any) => {
  const { appState, apim, id, modulesMenu } = props;
  const { t } = apim.di();
  const formState = useFormState();

  const dossier = appState.dossier();
  const isProcessing = dossier.status === 'processing';
  const isClientDeceased = dossier.personnePhysique?.deceased ?? false;

  // Override Dossier Page Header.
  useEffect(() => {
    appState.setPageTitle(trans(t, 'menu|pages.title.dossier.schema', 1, appState.dossier().title));
    appState.setBreadcrumb([
      isClient()
       ? { label: trans(t, 'dossier', 2) }
       : { label: trans(t, 'dossier', 2), to: appUri.dos.list },
      { label: appState.dossier().title, to: appUri.dos.page.replace(':id', appState.dossier().id) }
    ])

    let pageActions: any[] = [];
    if (!isClient()) {
      pageActions = [
        { label: trans(t, 'edit'), icon: 'pencil', to: appUri.dos.edit.landing.replace(':id', dossier?.id) },
        { label: trans(t, 'ged'), icon: 'briefcase', to: appUri.dos.page.replace(':id', dossier?.id) + '/ged' },
        { label: ucfirst(trans(t, 'mission', 2)), icon: 'chart-bar', to: appUri.dos.missions.history.replace(':id', id ?? '-') },
        { label: trans(t, 'short.add'), icon: 'bookmark', command: () => addShortcut(t, formState, appState) },
      ];

      // Only enable notifications if the dossier user is defined.
      if (isValidUUID(dossier.user)) {
        pageActions.push({ label: trans(t, 'form|dossier.notificationSend'), icon: 'pi pi-bell', command: () => sendNotification(apim, [dossier.user]) });
      }
    }

    appState.setPageActions(pageActions);
  }, [id]); // eslint-disable-line react-hooks/exhaustive-deps

  // Check if the dossier is in processing state.
  // Then, display a modal for following the progression.
  const [progressSubscribed, setProgressSubscribed] = useState<boolean>(false);
  const [progressData, setProgressData] = useState<any>([]);
  const [processingDialogVisible, setProcessingDialogVisible] = useState<boolean>(isProcessing);

  if (isProcessing) {
    const topic = '/dossiers/' + dossier.id + '/progress';
    if (!progressSubscribed) {
      subscribe([topic], ((m: any) => {
        if (!m.data) return;

        const data = JSON.parse(m.data);
        let progressData: any = {};
        if (data.progress) {
          progressData.progress = data.progress;
          if (data.tasks) {
            progressData.treeData = formatTree(data);
          }
        }

        setProgressData(progressData)
      }));

      setProgressSubscribed(true);
    }
  }

  const formatTree = (data: any): any => (data.tasks ?? []).map((task: any) => formatTreeItem(task));

  const formatTreeItem = (task: any): any => {
    const treeDataItem: any = {
      key: task.key,
      label: task.label,
      data: task,
      icon: task.progress === 100 ? 'pi pi-check text-green-400' : 'pi pi-spin pi-spinner',
      children: []
    };

    forEach((task.subtasks ?? []), (subtask: any): void => treeDataItem.children.push(formatTreeItem(subtask)));

    return treeDataItem;
  }

  const nodeTemplate = (node: any, options: any) => {
    const done = node.data.progress === 100;
    return <div>
        <span key={node.key + '_label'} className={classNames(options.className, done ? 'text-green-400' : '')}>{node.label}</span>
        {node.data.progress !== undefined && node.data.progress > 0 && !done && (
          <ProgressBar key={node.key + '_progress'} value={node.data.progress} className={'mt-1 mb-1'}></ProgressBar>
        )}
      </div>;
  }

  return <>
    {isProcessing && (
      <Dialog content={''} header={trans(t, 'dos.processing')} visible={processingDialogVisible} style={{ width: '50vw' }} onHide={() => setProcessingDialogVisible(false)}>
        <p className={'mb-5'}>
          {trans(t, 'system|processing.dossier.row1')}<br/>
          {trans(t, 'system|processing.dossier.row2')}
        </p>

        <h6>Progression</h6>
        <ProgressBar value={progressData.progress} mode={progressData.progress === undefined ? 'indeterminate' : 'determinate'} className={'mb-5'}></ProgressBar>
        {progressData.treeData && (
          <>
            <h6>Détails</h6>
            <Tree value={progressData.treeData} nodeTemplate={nodeTemplate} className={'w-full'} />
          </>
        )}
      </Dialog>
    )}
    {isClientDeceased && (
      <div className={'flex mb-3'}>
        <Message severity={'error'} className={'flex p-2 rounded-md w-full'} icon={'none'} text={ucfirst(trans(t, 'personnePhysiqueDeceased'))}/>
      </div>
    )}
    <SchemaMenu apim={apim} modulesMenu={modulesMenu}/>
  </>
};
