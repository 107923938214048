import React from 'react';

import { SearchResultCompany } from './SearchResultCompany';
import { SearchResultDossier } from './SearchResultDossier';
import { SearchResultUser } from './SearchResultUser';
import { SearchResultNotImplemented } from './SearchResultNotImplemented';

export const SearchResult = (props: any) => {
  const { index, data, searchState, apim, setVisibleFullScreen } = props;

  if (data['@type'] === 'DossierCompany') {
    return <SearchResultCompany key={index} data={data} apim={apim} searchState={searchState} setVisibleFullScreen={setVisibleFullScreen} />
  }

  if (data['@type'] === 'User') {
    return <SearchResultUser key={index} data={data} apim={apim} searchState={searchState} setVisibleFullScreen={setVisibleFullScreen} />
  }

  if (data['@type'] === 'Dossier') {
    return <SearchResultDossier key={index} data={data} apim={apim} searchState={searchState} setVisibleFullScreen={setVisibleFullScreen} />
  }

  return <SearchResultNotImplemented key={index} data={data} apim={apim} searchState={searchState} setVisibleFullScreen={setVisibleFullScreen} />
};
