import React from 'react';
import { NotFound } from 'pages';
import { CivilYear, ExerciceFiscal } from './headers';

import appUri from 'config/appUri.json';

export const ModuleWrapper = (props: any) => {
  const { baseUri, appState, apim, mode, buildTabContent, step, additionnalData } = props;

  const buildHeader = () => {
    switch (mode) {
      case appUri.reservedKeywords.civilYear:
        return <CivilYear appState={appState} apim={apim} mode={appUri.reservedKeywords.civilYear} buildTabContent={buildTabContent}
                          baseUri={baseUri} step={step} additionnalData={additionnalData}/>;

      default:
        return <ExerciceFiscal appState={appState} apim={apim} mode={appUri.reservedKeywords.civilYear} buildTabContent={buildTabContent}
                               baseUri={baseUri} step={step} additionnalData={additionnalData}/>
    }
  };

  if (!buildTabContent) return <NotFound asBlock/>;

  return (
    <div className={'card height-100 fadein animation-duration-500 flex flex-column'}>
      <div className={'a8-page-header flex justify-content-center mb-5'}>
        {buildHeader()}
      </div>
      {buildTabContent()}
    </div>
  );
};
