import React, { useEffect } from 'react';
import { useFormState } from 'states';
import { escapeValue, trans, ucfirst } from 'utilities';
import { MaskField, StaticListField, TextField } from 'forms/index';

export const MissionSelectionForm = (props: any) => {
  const { t, control, onFieldChange, errorMessage, context, setValue, formKey, parentKey } = props;
  const formState = useFormState()
  const societesExpert = context?.map((option: any) => ({
    label: option?.group?.label,
    value: option?.group?.context?.entity?.id
  }));

  useEffect(() => {
    if (context.length === 1) {
      formState.setFieldData(formKey, 'societeExpert', parentKey, escapeValue(context[0].group?.context?.entity?.id, 'string'));
      setValue('societeExpert', context[0].group?.context?.entity?.id);
    }
  }, [context]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div className={'flex flex-row col-12'}>
      <div className={'col-4'}>
        <TextField fieldKey={'label'} control={control} onFieldChange={onFieldChange} errorMessage={errorMessage} label={ucfirst(trans(t, 'form|mission.title'))} classes={'w-full'}/>
      </div>
        <div className={'col-4'}>
          <StaticListField listKey={'societesExpert'} listsOptions={{societesExpert: societesExpert}} fieldKey={'societeExpert'} control={control} label={ucfirst(trans(t, 'societeExpert'))}
                           optionLabel={'label'} errorMessage={errorMessage} onFieldChange={onFieldChange} classes={'w-full'} editable={false}/>
        </div>
      <div className={'col-4'}>
        <MaskField fieldKey={'date'} control={control} onFieldChange={onFieldChange} classes={'w-full'} errorMessage={errorMessage} isDate
                   rules={{ required: trans(t, 'form|requiredField') }} label={ucfirst(trans(t, 'ent.date'))}/>
      </div>
    </div>
  )
};
