import React from 'react';
import { trans } from 'utilities';
import { NumberField, APIListField, CheckboxField } from 'forms';

export const TresorerieEtEmpruntForm = (props: any) => {
  const { t, control, onFieldChange, errorMessage, getValues } = props;

  const fields: any[] = [
    { key: 'tauxEndettement', addon: 'percentage', mode: 'decimal' },
    { key: 'effetDeLevier', addon: 'percentage', mode: 'decimal', tooltip: trans(t, 'form|tooltip.tresorerie.effetDeLevier') },
    { key: 'capaciteEmprunt' },
  ];

  return (
    <>
      <NumberField key={'montantTotalEmprunt'} fieldKey={'montantTotalEmprunt'} mode={'currency'} addon={'euro'} addonPosition={'after'} control={control}
                   onFieldChange={onFieldChange} errorMessage={errorMessage} label={trans(t, 'chart|tresorerie.montantTotalEmprunt')}/>

      {fields.map((field: any, index: number) => {
        const isFieldManuelle = getValues()[`${field.key}Manuelle`];
        return (
          <div key={index} className={'flex-column flex flex-wrap w-4'}>
            <NumberField key={field.key} fieldKey={field.key} mode={field.mode || 'currency'} classes={'col-12 pb-0 mb-0'} addon={field.addon || 'euro'}
                         addonPosition={'after'} control={control} onFieldChange={onFieldChange} errorMessage={errorMessage}
                         label={trans(t, 'chart|tresorerie.' + field.key)} tooltip={field.tooltip} disabled={!isFieldManuelle}/>
            <CheckboxField fieldKey={`${field.key}Manuelle`} control={control} onFieldChange={onFieldChange} errorMessage={errorMessage}
                           classes={'pb-2 text-s'} label={trans(t, `form|forceInput`)}/>
          </div>
        );
      })}

      <NumberField key={'stock'} fieldKey={'stock'} mode={'currency'} addon={'euro'} addonPosition={'after'} control={control} onFieldChange={onFieldChange}
                   errorMessage={errorMessage} label={trans(t, 'chart|tresorerie.stock')}/>

      <APIListField listKey={'noteBanqueDeFrance'} fieldKey={'noteBanque'} control={control} onFieldChange={onFieldChange} errorMessage={errorMessage}
        label={trans(t, 'chart|tresorerie.noteBanqueDeFrance')} showClear/>
    </>
  );
};
