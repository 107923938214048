import React, { useCallback, useEffect, useState } from 'react';
import { Column } from 'primereact/column';

import { dialog, trans, triggerFormReset, triggerFormSubmit, ucfirst } from 'utilities';
import { simpleCheckboxEditor, companyCell, DatatableWrapper, flatCV, onPersonneMoraleAddSubmit, PageLoader, validCell } from 'components';
import { IRequestParams } from 'services';
import { FormWrapper } from 'forms';

import { replace } from 'lodash';
import appUri from 'config/appUri.json';

export const DossierCompaniesLinks = (props: any) => {
  const { rows, setRows, tableKey, title, editMode, params, dossier, apim } = props;
  const { t, navigate } = apim.di();

  const [loading, setLoading] = useState<boolean>(false);
  const [typesTaux, setTypesTaux] = useState<any[]>([]);
  const [societeTypes, setSocieteTypes] = useState<any[]>([]);

  const resourceType = 'dossierCompaniesLinks';

  useEffect(() => {
    apim.getList('typesTaux', {setter: setTypesTaux} as IRequestParams).then();
    apim.getList('societeTypes', {setter: setSocieteTypes} as IRequestParams).then();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const titleBodyTemplate = (rowData: any) => {
    const statusText = rowData.company?.latestVersion?.deleted ? <span className={'text-pink-500 text-sm mt-1' }>({trans(t, 'ent.deregistered')})</span> : null;

    return (
      <span>
      {companyCell(rowData.company, t)} {statusText}
    </span>
    );
  };

  const mainBodyTemplate = (rowData: any) => validCell(rowData?.main ?? false);

  const consolidableBodyTemplate = (rowData: any) => validCell(rowData?.consolidable ?? false);

  const typeBodyTemplate = (rowData: any) => {
    const tt = flatCV(rowData, 'type');
    if (!tt) return <>N/A</>;

    const match: any = (societeTypes || []).filter((m: any) => tt.toLowerCase() === m?.value);
    return match.length > 0 ? match[0].translated : <>{flatCV(rowData, 'type')}</>;
  };

  const typesTauxBodyTemplate = (rowData: any) => {
    const tt = flatCV(rowData, 'typeTaux');
    if (!tt) return <>N/A</>;

    const match: any = (typesTaux || []).filter((m: any) => tt.toLowerCase() === m?.value);

    return match.length > 0 ? match[0].translated : <>{flatCV(rowData, 'typeTaux')}</>;
  };

  const onRowClick = (row: any) => {
    return navigate(replace(appUri.cie.cli.page, ':id', row?.company.id || '_'));
  }

  const onNew = () => {
    dialog(t, {
      header: trans(t,'form|company.add'),
      icon: 'none',
      message: renderAddForm(),
      accept: () => triggerFormSubmit('a8-form-company-add'),
      reject: () => triggerFormReset('a8-form-company-reset')
    });
  };

  const onDelete = (row: any) => {
    apim.deleteEntity({
      resourceType: 'dossierCompanyLinks',
      id: row?.id,
      success: () => {
        setRows(rows.filter((rowLoop: any) => rowLoop.id !== row.id));
      },
    } as IRequestParams);
  }

  // Handle submission then refresh table rows.
  const onAddSubmit = (formData: any) => {
    onPersonneMoraleAddSubmit(apim, dossier, formData, rows, setRows, true, setLoading);
  }

  // Wrap form render into a useCallback to avoid multiple FormWrapper recalls du to form fields updates.
  const renderAddForm = useCallback(() =>
      <FormWrapper classes={'grid p-fluid w-12'} resourceType={'dossierCompanies'} formKeyPrefix={'add_pm_dialog'} cancelLink multiple
                   resetClass={'a8-form-company-reset'} submitClass={'a8-form-company-add'} onSubmit={onAddSubmit} hideReload />
    , []); // eslint-disable-line react-hooks/exhaustive-deps

  return (typesTaux || []).length === 0 ? (<PageLoader />) : (
    <DatatableWrapper resourceType={resourceType} tableKey={tableKey || 'dossier-societes-liens'} noFilters noGlobalFilter params={params} isLoading={loading}
                      addTitle={trans(t, 'table|add.company')} onRowClick={onRowClick} editMode={editMode || false}
                      rows={(rows || []).sort((a: any, b: any) => a.latestVersion?.raisonSociale?.localeCompare(b.latestVersion?.raisonSociale))}
                      onNew={onNew} onDelete={onDelete} title={title || (ucfirst(trans(t, 'company', rows.length)))}
                      paginator={false} editFields={['main', 'consolidable', 'typeTaux']}>
      <Column field={'company.latestVersion.raisonSociale'} header={trans(t, 'ent.raisonSociale')} body={titleBodyTemplate} style={{ width: '250px' }} />
      <Column field={'company.latestVersion.type'} header={trans(t, 'ent.formeJuridique')} align={'center'} alignHeader={'center'} body={typeBodyTemplate} style={{ width: '250px' }} />
      <Column field={'company.latestVersion.typeTaux'} header={trans(t, 'ent.typeTaux')} align={'center'} alignHeader={'center'} body={typesTauxBodyTemplate} style={{ width: '250px' }} />
      <Column field={'main'} header={trans(t, 'ent.principale')} body={mainBodyTemplate} align={'center'} alignHeader={'center'}
              editor={(options) => simpleCheckboxEditor(options, {label: trans(t, 'ent.principale')})} style={{ width: '150px' }} />
      <Column field={'consolidable'} header={trans(t, 'ent.consolidable')} body={consolidableBodyTemplate} align={'center'} alignHeader={'center'}
              editor={(options) => simpleCheckboxEditor(options, {label: trans(t, 'ent.consolidable')})} style={{ width: '150px' }} />

    </DatatableWrapper>
  );
};
