import React, { useRef, useState } from 'react';
import { AxiosResponse } from 'axios';

import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';
import { Tooltip } from 'primereact/tooltip';

import { trans, ucfirst } from 'utilities';
import { IRequestParams } from 'services';
import { DocumentViewer } from 'components';
import {
  cancelFile,
  getFile,
  getFiles,
  removeFile,
  updateFile
} from './GedHelper';

export const UploadedFiles = (props: any) => {
  const { formState, formKey, apim } = props;
  const { t } = apim.di();
  const dt = useRef<any>(null);
  const modalRef = useRef<any>(null);
  const viewerRef = useRef<any>(null);
  const [modalVisible, setModalVisible] = useState<boolean>(false);
  const [documentPreview, setDocumentPreview] = useState<any>();

  const labelBodyTemplate = (rowData: any) => {
    const file: any = getFile(formState, rowData.id);

    return (
      <>
        <div className={'first-line flex align-items-center'}>
          {file.status !== 'uploading' ? (
            <span onClick={() => removeFile(apim, formState, file)} className={'mr-3 qe-icon-wrapper cursor-pointer'}>
              <i className={'pi pi-trash text-danger'} />
            </span>
          ) : (
            <>
              <span className={'mr-3 qe-icon-wrapper'}><i className={'pi pi-spin pi-spinner'} /></span>
              <span data-pr-tooltip={trans(t, 'cancel')} onClick={() => cancelFile(apim, formState, file)} className={'mr-3 qe-icon-wrapper cursor-pointer cancel-upload-' + file.id}><i className={'pi pi-stop-circle text-danger'} /></span>
              <Tooltip target={'.cancel-upload-' + file.id} position={'bottom'} />
            </>
          )}
          <Button label={file.name} link onClick={() => onPreviewClick(file)} className={'text-left'} />
        </div>
      </>
    );
  };

  const dateSortBodyTemplate = () => <></>;

  const onPreviewClick = (doc: any) => {
    if (doc?.status === 'unloaded') {
      apim.call({
        resourceType: 'documents',
        action: 'load',
        id: doc.id,
        method: 'get',
        success: (res: AxiosResponse) => {
          if (!res?.data) return;

          doc.status = 'processed';
          doc.url = res.data.url;
          doc.mime = res.data.mime;
          doc.extension = res.data.extension;
          doc.size = res.data.size;

          updateFile(formState, doc);
          setDocumentPreview({...doc, ...{uri: doc.url}});
        }
      } as IRequestParams).then();
    } else {
      setDocumentPreview({...doc, ...{uri: doc.url}});
    }
    setModalVisible(true);
  };

  return (
    <div className={'col-12'}>
      <div className={'card'}>
        <DataTable
          ref={dt} className={'datatable-responsive'} dataKey={'id'} stripedRows
          value={getFiles(formState)} rows={10} paginator loading={formState.isLoading(formKey)}
          emptyMessage={trans(t, 'file_none')} sortField={'dateSort'} sortOrder={-1}>
          <Column field={'dateSort'} body={dateSortBodyTemplate} style={{ width: '0px' }} />
          <Column field={'name'} header={trans(t, 'file')} body={labelBodyTemplate} />
        </DataTable>
      </div>

      <Dialog content={''} ref={modalRef} header={ucfirst(trans(t, 'file_preview'))} visible={modalVisible} style={{ width: '80vw', height: '100%' }} onHide={() => setModalVisible(false)}>
        <DocumentViewer documentPreview={documentPreview} viewerRef={viewerRef}/>
      </Dialog>
    </div>
  );
};
