import React, { useEffect, useState } from 'react';

import { isValidUUID, trans, ucfirst } from 'utilities';
import { APIAutocompleteField, MaskField, TextField, UncontrolledCheckboxField } from 'forms';
import { useFormState } from 'states';

import { trim } from 'lodash';

export const AddSocieteClientForm = (props: any) => {
  const { t, control, onFieldChange, errorMessage, getValues, setValue, formKeyPrefix, context, useIndex } = props;
  const formState = useFormState();
  const [displayNew, setDisplayNew] = useState<boolean>(false);

  const formatter = (rows: any[]) => rows.map((r: any) => {
    if (useIndex) {
      return {...r, ...{ autocompleteLabel: trim(r.raisonSociale || r.nomCommercial) }}
    } else {
      return {...r, ...{ autocompleteLabel: trim(r.latestVersion.name) }}
    }
  });

  const params = [
    {label: 'order[raisonSociale]', value: 'asc'},
    {label: 'order[nomCommercial]', value: 'asc'}
  ];

  if (isValidUUID(context?.companyId)) {
    params.push({label: 'byCompany', value: context.companyId});
  }

  // Cancel PM selection if user checks that he wants to create a new entity.
  useEffect(() => {
    if (!displayNew) return;

    formState.setFieldData(formKeyPrefix, 'companyId', null, null);
    setValue('companyId', null);
  }, [displayNew]); // eslint-disable-line react-hooks/exhaustive-deps

  return <div className={'grid pt-2'} style={{width: '400px'}}>
    {!displayNew && (
      <APIAutocompleteField fieldKey={'companyId'} control={control} onFieldChange={onFieldChange} errorMessage={errorMessage} label={trans(t, 'company')}
                            getValues={getValues} placeholder={ucfirst(trans(t, 'form|select_company'))} optionKey={'autocompleteLabel'} formatter={formatter}
                            resourceType={useIndex ? 'dossierCompaniesIndex' : 'dossierCompanies'} params={params} classes={'col mb-0'} />
    )}

    <UncontrolledCheckboxField classes={'col-12'} fieldKey={'createNew'} onFieldChange={(value: any) => setDisplayNew(value)} label={ucfirst(trans(t, 'form|createNew.personneMorale'))} value={displayNew} />

    {displayNew && (
      <div className={'px-2 pt-3 grid'}>
        <TextField fieldKey={'raisonSociale'} control={control} onFieldChange={onFieldChange} errorMessage={errorMessage} label={trans(t, 'ent.raisonSociale')} classes={'col-12'} />
        <MaskField fieldKey={'siren'} mask={'999 999 999'} control={control} onFieldChange={onFieldChange} errorMessage={errorMessage} label={trans(t, 'ent.siren')} classes={'col-12'} />
      </div>
    )}
  </div>;
};
