import React, { useEffect, useState } from 'react';
import { Skeleton } from 'primereact/skeleton';

import { IRequestParams } from 'services';
import { isValidUUID, trans, uuidFromIri } from 'utilities';

export const Username = (props: any) => {
  const { user, apim, style } = props;
  const { t } = apim.di();

  const [username, setUsername] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    setLoading(true);

    apim.fetchEntity({
      resourceType: 'users',
      id: isValidUUID(user) ? user : uuidFromIri(user),
      setter: setUsername,
      notif: false,
      formatter: (data: any) => {
        if (data && (data['fullName'] !== undefined && data['fullName'] !== null)) {
          return data['fullName'];
        }

        return trans(t, 'author_unknown');
      },
      setLoading: setLoading,
      error: () => setUsername(trans(t, 'author_unknown'))
    } as IRequestParams).then();
  }, [user]); // eslint-disable-line react-hooks/exhaustive-deps

  return loading ? <Skeleton width={'8rem'} height={'1rem'}></Skeleton> : <span className={'titre'} style={style}>{username}</span>;
};
