import React, { useCallback, useEffect, useState } from 'react';
import { AxiosResponse } from 'axios';
import { Button } from 'primereact/button';

import { IRequestParams } from 'services';
import { PageLoader } from 'components';
import { FormWrapper } from 'forms';
import { trans, triggerFormSubmit } from 'utilities';
import { Error, NotFound } from 'pages';

import appUri from 'config/appUri.json';

export const DonneesSocialesCollectives = (props: any) => {
  const { appState, apim, additionalData, urls } = props;
  const { t, navigate } = apim.di();
  const cancelUri: string = urls?.landing + '/entreprise/donnees-sociales-collectives?maj=' + Date.now();

  const [loading, setLoading] = useState<boolean>(false);
  const [notFound, setNotFound] = useState<boolean>(false);
  const [errored, setErrored] = useState<boolean>(false);

  const [socialCollectiveData, setSocialCollectiveData] = useState<any>(null);
  const [keyTmp, setKeyTmp] = useState<string>('');

  useEffect(() => {
    setLoading(true);

    apim.fetchEntities({
      resourceType: 'socialCollectiveData',
      params: [{label: 'company', value: appState.company()?.id}, {label: 'fiscalYear', value: appState.exercise()}],
      cache: false,
      setLoading: setLoading,
      setErrored: setErrored,
      setNotFound: setNotFound,
      success: (res: AxiosResponse) => {
        if (res?.data && (res?.data['hydra:member'] || []).length > 0) {
          setSocialCollectiveData(res.data['hydra:member'][0]);
          setKeyTmp('');
        } else {
          setSocialCollectiveData(null);
          setKeyTmp('_' + appState.company()?.id + '_' + appState.exercise())
        }
      }
    } as IRequestParams).then();
  }, [appState.company()?.id, appState.exercise()]); // eslint-disable-line react-hooks/exhaustive-deps

  // Wrap form render into a useCallback to avoid multiple FormWrapper recalls du to form fields updates.
  const renderForm = useCallback(() =>
      <FormWrapper classes={'grid p-fluid w-12'} formKeyPrefix={'donnees_sociales_collectives'} resourceType={'socialCollectiveData'} keyTmp={keyTmp} keepAlive multiple
                   data={socialCollectiveData} additionalData={additionalData} hideReload cancelLink redirectUri={urls?.form + appUri.dos.edit.fiscalYear.steps.step4} />
    , [appState.company()?.id, appState.exercise(), socialCollectiveData?.id, keyTmp]); // eslint-disable-line react-hooks/exhaustive-deps

  if (loading) return <PageLoader/>;
  if (notFound) return <NotFound asBlock/>;
  if (errored) return <Error asBlock/>;

  return (
    <div className={'flex flex-column xl:flex-row flex-wrap fadein animation-duration-500'}>
      {renderForm()}

      <div className={'w-full text-right m-2 mr-3 mt-3'}>
        <>
          <Button type={'reset'} className={'text-ucfirst p-button-text p-button-danger mr-5'} label={trans(t, 'close')} onClick={() => navigate(cancelUri)} />
          <span className={'p-buttonset'}>
            <Button type={'submit'} className={'text-ucfirst p-button-text'} label={trans(t, 'back')} icon={'pi pi-arrow-left'} onClick={() => navigate(urls?.form + appUri.dos.edit.fiscalYear.steps.step2)} />
            <Button type={'submit'} className={'text-ucfirst'} label={trans(t, 'save')} icon={'pi pi-arrow-right'} iconPos={'right'} onClick={() => triggerFormSubmit(null)} />
          </span>
        </>
      </div>
    </div>
  );
};
