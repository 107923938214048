import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import fr_chart from 'assets/locales/fr-FR/chart.json';
import fr_common from 'assets/locales/fr-FR/common.json';
import fr_form from 'assets/locales/fr-FR/form.json';
import fr_menu from 'assets/locales/fr-FR/menu.json';
import fr_system from 'assets/locales/fr-FR/system.json';
import fr_table from 'assets/locales/fr-FR/table.json';
import fr_tree from 'assets/locales/fr-FR/tree.json';
import fr_notification from 'assets/locales/fr-FR/notification.json';

import fr_primereact from 'assets/locales/fr-FR/primereact.json';

i18n
  .use(initReactI18next)
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    ns: ['common', 'chart', 'menu', 'form', 'system', 'table', 'tree', 'notification', 'primereact'],
    defaultNS: 'common',
    lng: 'fr-FR',
    fallbackLng: 'fr-FR',
    resources: {
      'fr-FR': {
        common: fr_common,
        chart: fr_chart,
        system: fr_system,
        menu: fr_menu,
        form: fr_form,
        table: fr_table,
        tree: fr_tree,
        notification: fr_notification,
        primereact: fr_primereact,
      }
    },
    debug: process.env.REACT_APP_DEBUG_MODE === '1',
    interpolation: {
      escapeValue: false, // react already safes from xss => https://www.i18next.com/translation-function/interpolation#unescape
      format: (value, format, lng) => {
          if (format === 'uppercase') return value.toUpperCase();

        return value;
      }
    }
  });
