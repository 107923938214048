import React from 'react';
import { Column } from 'primereact/column';

import { isAdmin, isExpertAdmin } from 'services';
import { iri, trans } from 'utilities';
import { DatatableWrapper, dateTimeCell, rolesEditor } from 'components';

import appUri from 'config/appUri.json';

export const SocieteExpertExperts = (props: any) => {
  const { company, apim } = props;
  const { t, navigate} = apim.di();

  const resourceType = 'userGroups';
  const lazyConfig = {sortField: 'user.lastName', sortOrder: 1};
  let params: any[] = [
    {label: 'order[user.firstName]', value: 'asc'},
    {label: 'role.name[]', value: 'ROLE_EXPERT'},
    {label: 'role.name[]', value: 'ROLE_EXPERT_ADMIN'},
    {label: 'group', value: company.group},
    {label: 'expand[]', value: 'user_group:read_role'},
    {label: 'expand[]', value: 'role:read_list'},
    {label: 'expand[]', value: 'user_group:read_user'},
    {label: 'expand[]', value: 'user:read'}
  ];

  const roleBodyTemplate = (rowData: any) => trans(t, rowData?.role ? 'system|role.' + rowData?.role?.name.toLowerCase() : 'N/R');
  const lastLoginBodyTemplate = (rowData: any) => dateTimeCell(rowData?.user?.lastLogin);

  const onNew = () => {
    return navigate(appUri.usr.exp.add + '?cabinet=' + company.id);
  }

  const hookRowEdit = (_rows: any[], _row: any) => ({
    formattedRows: _rows,
    patched: {userGroups: {role: _row?.role?.id ? iri('roles', _row.role.id) : null}},
    id: _row?.id
  });

  return (
    <>
      <DatatableWrapper
        resourceType={resourceType} tableKey={'dossier-experts'} params={params} lazyConfig={lazyConfig} noFilters editFields={['role']}
        title={trans(t,'form|societeExpert.expertUserTitle')} addTitle={trans(t, 'table|add.expert')} editMode={isAdmin() || isExpertAdmin()}
        onRowClick={(row: any) => navigate(appUri.usr.exp.page.replace(':id', row?.user?.id || '_'))} onNew={onNew} hookRowEdit={hookRowEdit}>
        <Column field={'user.lastName'} header={trans(t, 'lastName')} sortable style={{ width: '200px' }}/>
        <Column field={'user.firstName'} header={trans(t, 'firstName')} sortable style={{ width: '200px' }}/>
        <Column field={'role.id'} header={trans(t, 'role')} body={roleBodyTemplate}
                editor={(options) => rolesEditor(options, {
                  fieldKey: 'role',
                  label: trans(t, 'role'),
                  rolesAvailables: ['ROLE_EXPERT', 'ROLE_EXPERT_ADMIN']
                })}/>
        <Column field={'user.email'} header={trans(t, 'email')} sortable style={{ width: '300px' }}/>
        <Column field={'user.phone1'} header={trans(t, 'phone')} sortable style={{ width: '200px' }}/>
        <Column field={'user.lastLogin'} header={trans(t, 'lastLogin')} sortable body={lastLoginBodyTemplate} align={'center'} style={{ width: '220px' }}/>
      </DatatableWrapper>
    </>
  );
};
