import React, { useCallback, useEffect, useState } from 'react';
import { TFunction } from 'i18next';
import { TabMenu } from 'primereact/tabmenu';

import { isValidUUID, trans, ucfirst } from 'utilities';
import { Error, NotFound } from 'pages';
import { PageLoader } from 'components';
import { IRequestParams } from 'services';
import { PersonnePhysiqueFoyer, PersonnePhysiqueProfile } from './tabs';

import { includes, forEach } from 'lodash';
import appUri from 'config/appUri.json';

export const PersonnePhysiqueFormRouter = (props: any) => {
  const { id, tab, appState, apim } = props;
  const { t, navigate } = apim.di();

  const [loading, setLoading] = useState<boolean>(false);
  const [data, setData] = useState<any>(null);
  const [notFound, setNotFound] = useState<boolean>(false);
  const [errored, setErrored] = useState<boolean>(false);

  const [activeIndex, setActiveIndex] = useState<number>(0);
  const baseUri = appUri.cie.per.phy.edit.replace(':id', id || '_');
  const tabs: any[] = id ? [
    {label: trans(t, 'menu|tab.general'), command: () => navigate(baseUri)},
    {label: trans(t, 'menu|tab.foyer'), command: () => navigate(baseUri + '/foyer')}
  ] : [];

  // Validate URL & build tabs.
  const checks = useCallback(() => {
    return isUrlValid(t, id, tab);
  }, [id, tab]); // eslint-disable-line react-hooks/exhaustive-deps
  useEffect(() => {
    if (!checks()) setNotFound(true);

    let ai = 0;
    forEach(appUri.cie.per.phy.tabs, (ta, index) => {
      if (tab === ta) ai = index + 1;
    });

    if (ai !== activeIndex) setActiveIndex(ai);
  }, [id, tab]); // eslint-disable-line react-hooks/exhaustive-deps

  const buildTabContent = useCallback(() => {
    switch (activeIndex) {
      case 1: return data && <PersonnePhysiqueFoyer data={data} apim={apim}/>;
      default: return data && <PersonnePhysiqueProfile data={data} apim={apim}/>;
    }
  }, [id, tab, activeIndex, data]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    setLoading(true);
    apim.fetchEntity({
      resourceType: 'personnesPhysiques',
      id: id,
      cache: false,
      setter: setData,
      setErrored: setErrored,
      setLoading: setLoading,
      setNotFound: setNotFound
    } as IRequestParams).then();
  }, [id]); // eslint-disable-line react-hooks/exhaustive-deps

  // Update Page Header.
  useEffect(() => {
    appState.setPageTitle((data ? ucfirst(trans(t, 'profile')) + ' ' + (data.firstName + ' ' + data.lastName) : '') + ' | ' + trans(t, 'editing'));
    appState.setPageActions([]);
    appState.setBreadcrumb([
      { label: trans(t, 'editing') },
      { label: trans(t, 'personnePhysique', 2) },
      { label: ((data ? data.firstName + ' ' + data.lastName : trans(t, 'personnePhysique'))), to: appUri.cie.per.phy.page.replace(':id', data?.id || '_') + '?maj=' + Date.now() }
    ]);

  }, [data]); // eslint-disable-line react-hooks/exhaustive-deps

  if (notFound) return <NotFound asBlock/>;
  if (errored) return <Error asBlock/>;
  if (loading) return <PageLoader/>;

  return (
    <div className={'card height-100 fadein animation-duration-500 flex flex-column'}>
      <div className={'a8-page-header flex flex-wrap'}>
        <div className={'flex-auto flex p-2 justify-content-end'}>
          {tabs.length > 1 && <TabMenu model={tabs} activeIndex={activeIndex} onTabChange={(e) => setActiveIndex(e.index)}/>}
        </div>
      </div>
      {buildTabContent()}
    </div>
  );
};

const isUrlValid = (t: TFunction, id: string | undefined, tab: string | undefined) => {
  // Ensure valid entity UUID & existing tab.
  return isValidUUID(id) && includes([...[undefined], ...appUri.cie.per.phy.tabs], tab);
};
