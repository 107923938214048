import React from 'react';
import { trans } from 'utilities';
import { CheckboxField, NumberField } from 'forms';

export const CPIncapaciteForm = (props: any) => {
  const { t, control, onFieldChange, errorMessage, getValues } = props;
  const { incapaciteFanchiseReduiteAccident, incapaciteFanchiseReduiteHospitalisation, type } = getValues();

  return <>
    <div className={'grid w-full px-3'}>
      <div className={'col-3'}>
        <NumberField classes={'w-full'} fieldKey={'incapaciteFanchiseReduiteAccident'} mode={'decimal'} min={0} showButtons minFractionDigits={0}
                     control={control} onFieldChange={onFieldChange} maxFractionDigits={0}
                     suffix={' ' + trans(t, 'day', incapaciteFanchiseReduiteAccident > 0 ? incapaciteFanchiseReduiteAccident : 1)}
                     errorMessage={errorMessage} label={trans(t, 'form|prevoyance_contrat.incapaciteFanchiseReduiteAccident')}/>
      </div>

      <div className={'col-3'}>
        <NumberField classes={'w-full'} fieldKey={'incapaciteFanchiseReduiteHospitalisation'} mode={'decimal'} min={0} showButtons minFractionDigits={0}
                     suffix={' ' + trans(t, 'day', incapaciteFanchiseReduiteHospitalisation > 0 ? incapaciteFanchiseReduiteHospitalisation : 1)}
                     control={control} onFieldChange={onFieldChange} maxFractionDigits={0}
                     errorMessage={errorMessage} label={trans(t, 'form|prevoyance_contrat.incapaciteFanchiseReduiteHospitalisation')}/>
      </div>

      {type !== 'frais_generaux' && (
        <div className={'col-3'}>
          <NumberField classes={'w-full'} fieldKey={'incapaciteCapitalMaladiesRedoutees'} mode={'currency'}
                       addon={'euro'} addonPosition={'after'} control={control} onFieldChange={onFieldChange} maxFractionDigits={0}
                       errorMessage={errorMessage} label={trans(t, 'form|prevoyance_contrat.incapaciteCapitalMaladiesRedoutees')}/>
        </div>
      )}
    </div>

    <div className={'grid w-full px-3'}>
      <div className={'col-1'}>
        <CheckboxField classes={'w-full'} fieldKey={'incapaciteRachatPsy'} control={control} onFieldChange={onFieldChange} errorMessage={errorMessage} label={trans(t, 'form|prevoyance_contrat.incapaciteRachatPsy')}/>
      </div>

      <div className={'col-1'}>
        <CheckboxField classes={'w-full'} fieldKey={'incapaciteRachatDos'} control={control} onFieldChange={onFieldChange} errorMessage={errorMessage} label={trans(t, 'form|prevoyance_contrat.incapaciteRachatDos')}/>
      </div>

      <div className={'col-3'}>
        <CheckboxField classes={'w-full'} fieldKey={'incapaciteRemplacementDirigeant'} control={control} onFieldChange={onFieldChange} errorMessage={errorMessage} label={trans(t, 'form|prevoyance_contrat.incapaciteRemplacementDirigeant')}/>
      </div>
    </div>
  </>;
};
