import React from 'react';
import { trans, dateString, ucfirst, dateAgoString } from 'utilities';

import appUri from 'config/appUri.json';
import { replace } from 'lodash';

export const RecentActivityItem = (props: any) => {
  const { apim, data } = props;
  const { t } = apim.di();

  let redirectUri: string|null = null;
  if (data.dossierUuid) {
    redirectUri = appUri.dos.page.replace(':id', data.dossierUuid);
  } else if (data.companyUuid) {
    redirectUri = appUri.cie.cli.page.replace(':id', data.companyUuid);
  }

  let authorRedirectUri: string|null = null;
  if (data.authorUuid) {
    authorRedirectUri = replace(appUri.usr.exp.page, ':id', data.authorUuid);
  }

  return (data.title || data.message) && <div className={'flex flex-row pt-2'}>
    {data.icon && (
      <i className={'pi pi-' + data.icon + ' align-self-start p-2 mr-2'}></i>
    )}

    <div className={'flex flex-column'}>
      {data.title && (
        redirectUri ?
          (
            <a href={redirectUri} className={'font-bold mb-1 text-color'}>
              {ucfirst(data.title)}
            </a>
          ) : (
            <span className={'font-bold mb-1 text-color'}>
              {ucfirst(data.title)}
            </span>
          )
      )}

      {data.message && (
        <span className={'mb-2'}>{ucfirst(data.message)}</span>
      )}

      <span className={'flex align-items-center'}>
        {data.authorPicture && (
          <img className={'mr-2'} src={data.authorPicture} alt={data.authorName ?? ucfirst(trans(t, 'administrator'))} />
        )}

        <small className={'muted-text'}>
          {authorRedirectUri ? (
            <a href={authorRedirectUri} className={'text-color'} title={ucfirst(trans(t, 'seeUserProfile'))}>
              {data.authorName ?? ucfirst(trans(t, 'atome8'))}
            </a>
          ) : (
            <span className={'text-color'}>
              {data.authorName ?? ucfirst(trans(t, 'atome8'))}
            </span>
          )}
          <span>, </span>
          <span title={dateString(data.date, 'DD/MM/YYYY HH:mm') ?? ''}>
            {dateAgoString(data.date)}
          </span>
        </small>
      </span>
    </div>
  </div>;
};
