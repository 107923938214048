import React from 'react';

export const SchemaMenuBlockMenu = (props: any) => {
  const module = props.module;
  const currentActiveModuleMenu = props.currentActiveModuleMenu || '';
  const pos = [...props.pos || [], ...[115.5, 241.5, .25, 8.25]];
  const labelPos = props?.additionnalLabel ? [...props.labelPos || [], ...[13, 28.5]] : [...props.labelPos || [], ...[18, 36] ];
  const additionnalLabelPos = props?.additionnalLabel ? [...props.additionnalLabelPos || [], ...[63, 43.75]] : [];
  const colors = {
    white: '#FFFFFF',
    gray: '#AAAEBB',
    primary: '#070AB0',
  };
  const fonts = {
    primary: 'OpenSans-Bold, Open Sans',
  };

  return module === currentActiveModuleMenu ? (
    <g id={'module-' + module + '-menu'} className={'module-menu active'}
       onMouseLeave={() => props.setter('')}
       transform={props.transform}>
      <rect fill={colors.white} id='etiquette-bg' stroke={colors.gray} strokeWidth='0.5' height={pos[0]} width={pos[1]} x={pos[2]} y={pos[3]}></rect>
      {props.children}
      <text fill={colors.primary} fontFamily={fonts.primary} fontSize='12' fontWeight='bold' className='etiquette-label'>
        <tspan className={'uppercase'} x={labelPos[0]} y={labelPos[1]}>{props.label}</tspan>
      </text>
      <text fill={colors.primary} fontFamily={fonts.primary} fontSize='12' fontWeight='bold' className='etiquette-label'>
        <tspan className={'uppercase'} x={additionnalLabelPos[0]} y={additionnalLabelPos[1]}>{props.additionnalLabel}</tspan>
      </text>
    </g>
  ) : (<></>);
};
