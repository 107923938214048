import React, { useEffect } from 'react';
import { Panel } from 'primereact/panel';
import { Message } from 'primereact/message';

import { flatPerson, trans, ucfirst } from 'utilities';
import { PersonnePhysiqueChildren, PersonnePhysiqueParents, PersonnePhysiqueSpouses } from 'components';
import { useAppState } from 'states';

import { omit } from 'lodash';

export const DirigeantFoyer = (props: any) => {
  const { apim, data } = props;
  const { t } = apim.di();
  const appState = useAppState();

  // Update Page Header.
  useEffect(() => {
    appState.setPageTitle(trans(t, 'menu|pages.title.user.clients'));
    appState.setBreadcrumb([{ label: ucfirst(trans(t, 'dirigeant', 2)) }]);
    appState.setPageActions([]);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  // Let's flat all fields to root level to simplify nested.
  const person = flatPerson(data?.personnePhysique);
  const parents = (data?.personnePhysique?.parents || []).map((parent: any) => {
    return {
      ...omit(parent, ['parent']),
      ...{ personnePhysique: parent.parent }
    }
  });
  const children = (data?.personnePhysique?.children || []).map((child: any) => {
    return {
      ...omit(child, ['child']),
      ...{ personnePhysique: child.child }
    }
  });
  const spouses = (data?.personnePhysique?.spouses || []).map((spouse: any) => {
    return {
      ...omit(spouse, ['spouse']),
      ...{ personnePhysique: spouse.spouse }
    }
  });

  return <>
    {(person?.deceased ?? false) && (
      <div className={'flex mx-3'}>
        <Message severity={'error'} className={'flex p-2 rounded-md w-full'} icon={'none'} text={ucfirst(trans(t, 'personnePhysiqueDeceased'))}/>
      </div>
    )}

    {spouses?.length > 0 && (
      <div className={'col-12 pt-4 px-0'}>
        <Panel header={ucfirst(trans(t, 'spouse', spouses?.length))} toggleable>
          <PersonnePhysiqueSpouses rows={spouses} apim={apim} title={'none'} context={person}/>
        </Panel>
      </div>
    )}

    {children?.length > 0 && (
      <div className={'col-12 pt-4 px-0'}>
        <Panel header={ucfirst(trans(t, 'child', children?.length))} toggleable>
          <PersonnePhysiqueChildren rows={children} apim={apim} title={'none'} context={person}/>
        </Panel>
      </div>
    )}

    {parents?.length > 0 && (
      <div className={'col-12 pt-4 px-0'}>
        <Panel header={ucfirst(trans(t, 'parent', parents?.length))} toggleable>
          <PersonnePhysiqueParents rows={parents} apim={apim} title={'none'} context={person}/>
        </Panel>
      </div>
    )}
  </>;
};
