import React, { useEffect } from 'react';
import { trans, ucfirst } from 'utilities';
import { CheckboxField, NumberField, TextField} from 'forms/index';
import { useFormState } from 'states';

export const RetraiteForm = (props: any) => {
  const { t, control, onFieldChange, errorMessage, context, formKey, setValue, parentKey, getValues } = props;
  const formState = useFormState()
  const { ageLegal } = getValues()

  useEffect(() => {
    if (context && !ageLegal){
      formState.setFieldData(formKey, 'ageLegal', parentKey, context)
      setValue('ageLegal', context);
    }
  }, [context]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div className={'flex flex-column col-12'}>
      <div className={'flex flex-wrap flex-row col-12'}>
        <div className={'col-4'}>
          <NumberField fieldKey={'referenceYear'} mode={'decimal'} classes={'w-full'} showButtons maxFractionDigits={0}
                       minFractionDigits={0}
                       control={control} onFieldChange={onFieldChange} errorMessage={errorMessage}
                       label={trans(t, 'referenceYear')} />
        </div>

        <div className={'col-4'}>
          <TextField fieldKey={'statutAvantEtude'} control={control} onFieldChange={onFieldChange}
                     errorMessage={errorMessage} label={ucfirst(trans(t, 'statutAvantEtude'))} classes={'w-full'}/>
        </div>

        <div className={'col-4'}>
          <TextField fieldKey={'statutApresEtude'} control={control} onFieldChange={onFieldChange}
                     errorMessage={errorMessage} label={ucfirst(trans(t, 'statutApresEtude'))} classes={'w-full'}/>
        </div>
      </div>

      <div className={'flex flex-wrap flex-row col-12'}>
        <div className={'col-4'}>
          <NumberField fieldKey={'trimestresAcquis'} mode={'decimal'} showButtons maxFractionDigits={0}
                       minFractionDigits={0} classes={'w-full'}
                       control={control} onFieldChange={onFieldChange} errorMessage={errorMessage}
                       label={trans(t, 'trimestresAcquis')}/>
        </div>

        <div className={'col-4'}>
          <NumberField fieldKey={'pointsComplementairesAcquis'} mode={'decimal'} showButtons maxFractionDigits={0}
                       minFractionDigits={0} classes={'w-full'}
                       control={control} onFieldChange={onFieldChange} errorMessage={errorMessage}
                       label={trans(t, 'pointsComplementairesAcquis')}/>
        </div>

        <div className={'col-4'}>
          <NumberField fieldKey={'montantRetraiteAcquise'} mode={'currency'} addon={'euro'} addonPosition={'after'}
                       classes={'w-full'}
                       control={control} onFieldChange={onFieldChange} errorMessage={errorMessage}
                       label={trans(t, 'montantRetraiteAcquise')}/>
        </div>
      </div>

      <div className={'flex flex-wrap flex-row col-12'}>
        <div className={'col-4'}>
          <NumberField fieldKey={'montantRetraiteFacultativeEstimee'} mode={'currency'} addon={'euro'}
                       addonPosition={'after'} classes={'w-full'}
                       control={control} onFieldChange={onFieldChange} errorMessage={errorMessage}
                       label={trans(t, 'montantRetraiteFacultativeEstimee')}/>
        </div>

        <div className={'col-4'}>
          <NumberField fieldKey={'chargesSocialesAvantLiquidation'} mode={'currency'} addon={'euro'}
                       addonPosition={'after'} classes={'w-full'}
                       control={control} onFieldChange={onFieldChange} errorMessage={errorMessage}
                       label={trans(t, 'chargesSocialesAvantLiquidation')}/>
        </div>

        <div className={'col-4'}>
          <NumberField fieldKey={'trimestresNecessaires'} mode={'decimal'} showButtons maxFractionDigits={0}
                       minFractionDigits={0} classes={'w-full'}
                       control={control} onFieldChange={onFieldChange} errorMessage={errorMessage}
                       label={trans(t, 'trimestresNecessaires')}/>
        </div>
      </div>

      <div className={'flex flex-wrap flex-row col-12'}>
        <div className={'col-4'}>
          <NumberField fieldKey={'ageLegal'} mode={'decimal'} showButtons maxFractionDigits={0} minFractionDigits={0}
                       classes={'w-full'}
                       control={control} onFieldChange={onFieldChange} errorMessage={errorMessage}
                       label={trans(t, 'ageLegal')}/>
        </div>

        <div className={'col-4'}>
          <NumberField fieldKey={'trimestresCotisesSurDerniersRevenusDeclares'} mode={'decimal'} showButtons
                       maxFractionDigits={0} minFractionDigits={0} classes={'w-full'}
                       control={control} onFieldChange={onFieldChange} errorMessage={errorMessage}
                       label={trans(t, 'trimestresCotisesSurDerniersRevenusDeclares')}/>
        </div>

        <div className={'col-4'}>
          <CheckboxField fieldKey={'conditionCarriereLongue'} control={control} onFieldChange={onFieldChange}
                         errorMessage={errorMessage} label={ucfirst(trans(t, 'conditionCarriereLongue'))}
                         classes={'w-full'}/>
        </div>
      </div>

      <div className={'flex flex-wrap flex-row col-12'}>
        <div className={'col-4'}>
          <NumberField fieldKey={'cumulRemunerationNetteAnnuelle'} mode={'currency'} addon={'euro'}
                       addonPosition={'after'} classes={'w-full'}
                       control={control} onFieldChange={onFieldChange} errorMessage={errorMessage}
                       label={trans(t, 'cumulRemunerationNetteAnnuelle')}/>
        </div>

        <div className={'col-4'}>
          <NumberField fieldKey={'chargesSocialesAvantLiquidationSurRevenuPreconise'} mode={'currency'} addon={'euro'}
                       addonPosition={'after'} classes={'w-full'}
                       control={control} onFieldChange={onFieldChange} errorMessage={errorMessage}
                       label={trans(t, 'chargesSocialesAvantLiquidationSurRevenuPreconise')}/>
        </div>

        <div className={'col-4'}>
          <NumberField fieldKey={'montantAnnuelNetRetraiteProjete'} mode={'currency'} addon={'euro'}
                       addonPosition={'after'} classes={'w-full'}
                       control={control} onFieldChange={onFieldChange} errorMessage={errorMessage}
                       label={trans(t, 'montantAnnuelNetRetraiteProjete')}/>
        </div>
      </div>

      <div className={'flex flex-wrap flex-row col-12'}>
        <div className={'col-4'}>
          <NumberField fieldKey={'montantCapitalAuTerme'} mode={'currency'} addon={'euro'} addonPosition={'after'}
                       classes={'w-full'}
                       control={control} onFieldChange={onFieldChange} errorMessage={errorMessage}
                       label={trans(t, 'montantCapitalAuTerme')}/>
        </div>

        <div className={'col-4'}>
          <NumberField fieldKey={'ramSamFinal'} mode={'currency'} addon={'euro'} addonPosition={'after'}
                       classes={'w-full'}
                       control={control} onFieldChange={onFieldChange} errorMessage={errorMessage}
                       label={trans(t, 'ramSamFinal')} tooltip={trans(t, 'form|tooltip.retraite.ramSamFinal')} />
        </div>

        <div className={'col-4'}>
          <NumberField fieldKey={'remunerationSocialePreconisee'} mode={'currency'} addon={'euro'}
                       addonPosition={'after'} classes={'w-full'}
                       control={control} onFieldChange={onFieldChange} errorMessage={errorMessage}
                       label={trans(t, 'remunerationSocialePreconisee')}/>
        </div>
      </div>

      <div className={'flex flex-wrap flex-row col-12'}>
        <div className={'col-4'}>
          <NumberField fieldKey={'revalorisation'} in={0} max={100} suffix={'%'} addon={'percentage'}
                       addonPosition={'after'} classes={'w-full'} mode={'decimal'}
                       control={control} onFieldChange={onFieldChange} errorMessage={errorMessage}
                       label={trans(t, 'revalorisation')}/>
        </div>

        <div className={'col-4'}>
          <TextField fieldKey={'caisseRetraiteAvantEtude'} control={control} onFieldChange={onFieldChange}
                     errorMessage={errorMessage} label={ucfirst(trans(t, 'caisseRetraiteAvantEtude'))}
                     classes={'w-full'}/>
        </div>

        <div className={'col-4'}>
          <TextField fieldKey={'caisseRetraiteApresEtude'} control={control} onFieldChange={onFieldChange}
                     errorMessage={errorMessage} label={ucfirst(trans(t, 'caisseRetraiteApresEtude'))}
                     classes={'w-full'}/>
        </div>
      </div>

    </div>
  )
};
