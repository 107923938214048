import React from 'react';

import { trans, ucfirst } from 'utilities';
import { AddPersonnePhysiqueForm, AddSocieteClientForm, StaticListField } from 'forms';

export const AddEconomicDataAccountOwnerForm = (props: any) => {
  const { t, control, onFieldChange, errorMessage, getValues, setValue, formKeyPrefix, context } = props;
  const { type } = getValues();

  const statList: any[] = [
    {translated: ucfirst(trans(t, 'personnePhysique')), value: 'personne_physique'},
    {translated: ucfirst(trans(t, 'personneMorale')), value: 'personne_morale'}
  ];

  return <div className={'grid pt-3 pr-3'} style={{width: '400px'}}>
    <StaticListField listKey={'types'} listsOptions={{types: statList}} fieldKey={'type'} control={control} errorMessage={errorMessage} onFieldChange={onFieldChange}
                     label={trans(t, 'type')} classes={'w-full'}/>

    {'personne_physique' === type && (
      <AddPersonnePhysiqueForm t={t} control={control} onFieldChange={onFieldChange} errorMessage={errorMessage}
                               getValues={getValues} setValue={setValue} formKeyPrefix={formKeyPrefix} context={{companyId: context?.company?.id}}/>
    )}

    {'personne_morale' === type && (
      <AddSocieteClientForm t={t} control={control} onFieldChange={onFieldChange} errorMessage={errorMessage}
                            getValues={getValues} setValue={setValue} formKeyPrefix={formKeyPrefix} context={{companyId: context?.company?.id}} useIndex={false}/>
    )}
  </div>;
};
