import React from 'react';
import { classNames } from 'primereact/utils';

export const SchemaMenuLabel = (props: any) => {
  const module = props.module;
  const pos = props.pos && props.pos.length === 4 ? props.pos : [46.5, 156.5, .25, 8.25];
  const disabled = props.disabled || false;
  const strokeWidth = props.strokeWidth === null || props.strokeWidth === undefined ? 0.5 : props.strokeWidth;

  const colors = {
    white: '#FFFFFF',
    gray: '#AAAEBB',
    primary: '#070AB0',
  };
  const fonts = {
    primary: 'OpenSans-Bold, Open Sans',
    primaryLight: 'OpenSans-Bold, Open Sans'
  };

  return (
    <g id={'module-' + module + '-label'}
       onMouseEnter={() => {
         if (!disabled) props.setter(module)
       }}
       transform={props.transform}>
      <rect fill={colors.white} height={pos[0]} width={pos[1]} stroke={colors.gray} strokeWidth={strokeWidth} x={pos[2]} y={pos[3]}></rect>
      <text fill={colors.primary} fontFamily={fonts.primary} fontSize='12' fontWeight='bold'
            className={classNames('etiquette-label', {'disabled' : disabled})}
            onClick={props.onClick}>
        {props.children}
      </text>
    </g>
  );
};
