import React, { useCallback, useEffect, useState } from 'react';
import { AxiosResponse } from 'axios';
import { Fieldset } from 'primereact/fieldset';

import { IRequestParams, isClient } from 'services';
import {
  DossierCompaniesDatatable,
  formatFamilyTree, formatOrgTree, onChartNodeDetailClick, OrgChart,
  getModuleId, PageLoader } from 'components';
import { isValidUUID, trans, ucfirst } from 'utilities';

import { isEmpty, replace } from 'lodash';
import appUri from 'config/appUri.json';

export const DonneesJuridiques = (props: any) => {
  const { id, appState, apim, modulesMenu, lastUpdate } = props;
  const { t, navigate } = apim.di();

  const dossier = appState.dossier();
  const [loading, setLoading] = useState<boolean>(false);
  const [data, setData] = useState<any>(null);
  const [dirigeantStatus, setDirigeantStatus] = useState<any[]>([]);
  const [societeTypes, setSocieteTypes] = useState<any[]>([]);

  // Override Page Header (defaults into ModuleWrapper then ReservesRouter).
  useEffect(() => {
    appState.setBreadcrumb([
      isClient()
       ? { label: trans(t, 'dossier', 2) }
       : { label: trans(t, 'dossier', 2), to: appUri.dos.list },
      { label: dossier.title, to: replace(appUri.dos.page, ':id', id)},
      { label: trans(t, 'menu|menu.schema_flux.entreprise_dj') }
    ]);

    appState.setModule(getModuleId(modulesMenu, 'donnees.juridiques'));
  }, [id]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    apim.getList('dirigeantStatus', {setter: setDirigeantStatus} as IRequestParams).then();
    apim.getList('societeTypes', {setter: setSocieteTypes} as IRequestParams).then();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const fetchData = useCallback(async () => {
    if (!appState.event()) return null;

    setLoading(true);
    apim.call({
      resourceType: 'dossiers',
      action: 'module',
      id: id + '|' + getModuleId(modulesMenu, 'donnees.juridiques'),
      method: 'get',
      params: isValidUUID(appState.event()?.id) ? [{label: 'event', value: appState.event()?.id}] : [{label: 'company', value: appState.company()?.id}],
      cacheMode: isEmpty(lastUpdate) ? 'default' : 'replace',
      setLoading,
      success: (res: AxiosResponse) => {
        const companies = ((res?.data?.metadata['hydra:member'] ?? []).filter((c: any) => c.key === 'companies'))[0]?.data ?? [];
        const chartData = {nodes: formatFamilyTree(res.data, t).concat(formatOrgTree(res.data, t, dirigeantStatus, societeTypes))};

        setData({...res.data, ...{
          companies: companies,
          chart: chartData,
        }});
      }
    } as IRequestParams);
  }, [appState.company()?.id, appState.event()?.id, lastUpdate, dirigeantStatus, societeTypes]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    fetchData().then();
  }, [appState.company()?.id, appState.event()?.id, lastUpdate, dirigeantStatus]); // eslint-disable-line react-hooks/exhaustive-deps

  if (loading) return <PageLoader/>;

  return (
    <div className={'flex flex-row flex-wrap fadein animation-duration-500 w-full'}>
      {data?.chart && (
        <OrgChart data={data?.chart} t={t} onDetailsClick={(node: any) => onChartNodeDetailClick(node, navigate)}/>
      )}

      <Fieldset legend={ucfirst(trans(t, 'seeDetailsCompanies'))} toggleable className={'col-12'}>
        <DossierCompaniesDatatable noFilters noGlobalFilter title={'none'} apim={apim} params={[{label: 'dossiers.dossier', value: id}]} rows={data?.companies ?? []} useVersion={true}/>
      </Fieldset>
    </div>
  );
};
