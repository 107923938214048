import React from 'react';
import appUri from 'config/appUri.json';

import { ExpertsList, ExpertRouter, DirigeantsList, DirigeantRouter } from 'pages';
import { trimStart } from 'lodash';

export const RoutesUser = () => {
  return [
    {
      path: trimStart(appUri.usr.exp.list),
      element: <ExpertsList/>,
    },
    {
      path: trimStart(appUri.usr.dir.list),
      element: <DirigeantsList/>,
    },
    {
      path: trimStart(appUri.usr.exp.add),
      element: <ExpertRouter action={appUri.reservedKeywords.add}/>,
    },
    {
      path: trimStart(appUri.usr.dir.add),
      element: <DirigeantRouter action={appUri.reservedKeywords.add}/>,
    },
    {
      path: trimStart(appUri.usr.dir.page) + '/:tab?',
      element: <DirigeantRouter/>,
    },
    {
      path: trimStart(appUri.usr.exp.page) + '/:tab?',
      element: <ExpertRouter/>,
    },
    {
      path: trimStart(appUri.usr.dir.edit) + '/:tab?',
      element: <DirigeantRouter action={appUri.reservedKeywords.edit}/>,
    },
    {
      path: trimStart(appUri.usr.exp.edit) + '/:tab?',
      element: <ExpertRouter action={appUri.reservedKeywords.edit}/>,
    },
  ];
};
