import React from 'react';

import { Card } from 'primereact/card';

import { trans } from 'utilities';
import { StaticListField } from 'forms';

export const ContratsForm = (props: any) => {
  const { t, control, onFieldChange, errorMessage, listsOptions } = props;

  return <>
    <div className={'px-3 w-full'}>
      <div className={'grid gap-4'}>
        <Card title={trans(t, 'form|dossier.contractStatusRetraiteTitle')} className={'col'}>
          <StaticListField listKey={'dossierContractStatuses'} listsOptions={listsOptions} fieldKey={'contractStatusRetraite'} control={control} onFieldChange={onFieldChange} errorMessage={errorMessage} classes={'col'}
                        label={trans(t, 'form|dossier.contractStatus')} showClear />
        </Card>

        <Card title={trans(t, 'form|dossier.contractStatusPrevoyanceTitle')} className={'col'}>
          <StaticListField listKey={'dossierContractStatuses'} listsOptions={listsOptions} fieldKey={'contractStatusPrevoyance'} control={control} onFieldChange={onFieldChange} errorMessage={errorMessage} classes={'col'}
                        label={trans(t, 'form|dossier.contractStatus')} showClear />
        </Card>

        <Card title={trans(t, 'form|dossier.contractStatusSanteTitle')} className={'col'}>
          <StaticListField listKey={'dossierContractStatuses'} listsOptions={listsOptions} fieldKey={'contractStatusSante'} control={control} onFieldChange={onFieldChange} errorMessage={errorMessage} classes={'col'}
                        label={trans(t, 'form|dossier.contractStatus')} showClear />
        </Card>
      </div>
    </div>
  </>;
};
