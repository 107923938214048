import React, { useCallback, useEffect, useState } from 'react';
import { AxiosResponse } from 'axios';

import { iri, trans } from 'utilities';
import { IRequestParams, isClient, userId } from 'services';
import { Unauthorized } from 'pages';
import { PageLoader } from 'components';
import { FormWrapper } from 'forms';

import appUri from 'config/appUri.json';

export const MissionRequest = (props: any) => {
  const { t, appState, apim } = props;
  const { navigate } = apim.di();
  const dossier: any = appState.dossier();
  const [loading, setLoading] = useState<boolean>(false);
  const [context, setContext] = useState<any>({ missionTypes: [], userSocietes: [] });

  const onSubmit = (formData: any) => {
    if (formData.societeExpert || (formData.societeExpertPhone && formData.societeExpertEmail && formData.societeExpertRaisonSociale) || formData.societeExpertUnknown) {
      formData.societeExpert = formData?.societeExpert === true ? dossier?.societeExpert : null;
      apim.postEntity({
        resourceType: 'missionRequest',
        data: {
          ...formData,
          author: userId(),
          dossier : iri('dossiers', dossier?.id),
        },
        setLoading,
        success: () => {
          navigate(redirectUri);
        }
      } as IRequestParams);
    } else {
      apim.toast('error')?.current?.show({
        severity: 'error',
        summary: trans(t, 'system|error.summary.bad'),
        detail: trans(t, 'system|error.details.missionRequest'),
        life: 4000
      });
    }
  }

  const renderForm = useCallback(() =>
      <FormWrapper classes={'grid p-fluid'} submitClass={'a8-mission-request-form pr-5'} resourceType={'missionRequest'} context={context}
                   hideReload cancelLink redirectUri={redirectUri} onSubmit={onSubmit}/>
    , [context]); // eslint-disable-line react-hooks/exhaustive-deps

  // Update Page Header.
  useEffect(() => {
    appState.setPageTitle(trans(t, 'menu|pages.title.missions'));
    appState.setBreadcrumb([
      { label: trans(t, 'dossier', 2), to: appUri.dos.list },
      { label: dossier?.title, to: appUri.dos.page.replace(':id', dossier?.id) },
      { label: trans(t, 'mission', 2) }]);
  }, [dossier]); // eslint-disable-line react-hooks/exhaustive-deps

  const redirectUri = appUri.dos.missions.history.replace(':id', appState.dossier()?.id);

  useEffect(() => {
    apim.fetchEntity({
      resourceType: 'missionTypes',
      success: (missionRes: AxiosResponse) => {
        apim.fetchEntity({
          resourceType: 'societesExperts',
          id: dossier?.societeExpert,
          cache: false,
          setLoading: setLoading,
          success: (userRes: AxiosResponse) => {
            if (missionRes?.data && (missionRes?.data['hydra:member'] || []).length > 0 && userRes?.data ) {
              setContext ({ societeExpert: userRes?.data , missionTypes: missionRes?.data['hydra:member'] })
            }
          }
        } as IRequestParams).then();

      }
    } as IRequestParams).then();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  if (!isClient()) return <Unauthorized asBlock/>;

  if (!dossier?.id || loading || !context) return <PageLoader/>;

  return (
    <>
      {renderForm()}
    </>
  );
};
