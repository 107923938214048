import React from 'react';
import { Column } from 'primereact/column';

import { displayHtml, trans, ucfirst } from 'utilities';
import { DatatableWrapper, dateTimeCell, simpleCheckboxEditor, textAreaEditor, validCell } from 'components';

export const SettingsAlertCategoriesDatatable = (props: any) => {
  const { apim, tablekey, title, resourceType } = props;
  const { t } = apim.di();

  const mailTitleBodyTemplate = (rowData: any) => displayHtml(rowData?.mailTitle);
  const mailContentBodyTemplate = (rowData: any) => displayHtml(rowData?.mailContent);
  const updatedBodyTemplate = (rowData: any) => dateTimeCell(rowData?.updated);
  const createdBodyTemplate = (rowData: any) => dateTimeCell(rowData?.created);
  const activeBodyTemplate = (rowData: any) => validCell(rowData?.active);

  return (
    <DatatableWrapper resourceType={resourceType} tableKey={tablekey} title={title} editMode
                      editFields={['label', 'mailTitle', 'mailContent', 'active']} noFilters>
      <Column field={'label'} header={ucfirst(trans(t, 'name'))} sortable
              editor={(options) => textAreaEditor(options, { label: trans(t, 'name'), rows: 5 })}
              style={{ width: '10vw' }} />
      <Column field={'mailTitle'} header={ucfirst(trans(t, 'mailTitle'))} sortable body={mailTitleBodyTemplate}
              editor={(options) => textAreaEditor(options, { label: trans(t, 'mailTitle'), rows: 5 })}
              style={{ width: '15vw' }} />
      <Column field={'mailContent'} header={ucfirst(trans(t, 'mailContent'))} sortable body={mailContentBodyTemplate}
              editor={(options) => textAreaEditor(options, { label: trans(t, 'mailContent'), rows: 5 })}/>
      <Column field={'updated'} header={trans(t, 'updated')} sortable align={'center'} body={updatedBodyTemplate}
              style={{ width: '10vw' }} />
      <Column field={'created'} header={trans(t, 'created')} sortable align={'center'} body={createdBodyTemplate}
              style={{ width: '10vw' }} />
      <Column field={'active'} header={trans(t, 'active')} sortable align={'center'} body={activeBodyTemplate}
              editor={(options) => simpleCheckboxEditor(options, { label: trans(t, 'table|active') })}
              style={{ width: '5vw' }} />
    </DatatableWrapper>
  );
};
