import React from 'react';
import { Card } from 'primereact/card';
import { Button } from 'primereact/button';

import { trans, ucfirst } from 'utilities';
import { PrevoyanceFormRouter } from 'forms';

import prevoyanceImage from 'assets/images/illustrations/landing/prevoyance.jpg';
import dossierContractsImage from 'assets/images/illustrations/landing/contracts.jpg';

import appUri from 'config/appUri.json';

export const PrevoyanceFormLanding = (props: any) => {
  const { apim, dossier, tab } = props;
  const { t, navigate } = apim.di();

  // Return tab's forms first.
  if (tab) return <PrevoyanceFormRouter apim={apim} dossier={dossier} tab={tab} />;

  const header = (image: string) => <img alt={'form-landing-header'} src={image} className={'border-round border-noround-bottom'} />;

  return (
    <div className={'p-2 flex justify-content-center'}>
      <div>
        <h2 className={'mb-3 text-center'}>{trans(t, 'menu|landing.prevoyance.title')}</h2>
        <p className={'mb-5 text-center'}>{trans(t, 'menu|landing.prevoyance.subtitle')}</p>

        <div className={'a8-dossier-card-wrapper'}>
          <div className={'flex gap-5 justify-content-center'}>
            <Card title={trans(t, 'menu|landing.dossier.prevoyance.title')}
                  subTitle={trans(t, 'menu|landing.dossier.prevoyance.subtitle')}
                  header={header(prevoyanceImage)} className={'a8-dossier-card-item col-4'}
                  onClick={() => navigate(appUri.dos.edit.prevoyance.uri
                    .replace(':id', dossier?.id) + '/' + appUri.dos.edit.prevoyance.tabs[0]
                  )}>
              <p className={'m-0'}>{trans(t, 'menu|landing.dossier.prevoyance.body')}</p>
            </Card>

            <Card title={trans(t, 'menu|landing.dossier.prevoyanceContracts.title')}
                  subTitle={trans(t, 'menu|landing.dossier.prevoyanceContracts.subtitle')}
                  header={header(dossierContractsImage)} className={'a8-dossier-card-item col-4'}
                  onClick={() => navigate(appUri.dos.edit.contracts.uri
                    .replace(':id', dossier?.id)
                    .replace(':module', 'prevoyance')
                    .replace(':version', 'selection')
                  )}>
              <p className={'m-0'}>{trans(t, 'menu|landing.dossier.prevoyanceContracts.body')}</p>
            </Card>
          </div>
        </div>

        <div className={'flex align-items-center justify-content-center mt-6'}>
          <Button icon={'pi pi-chevron-left'} link label={ucfirst(trans(t, 'back'))} onClick={() => navigate(appUri.dos.edit.landing.replace(':id', dossier?.id))} />
        </div>
      </div>
    </div>
  )
};
