import React from 'react';

export const UncontrolledBaseField = (props: any) => {
  const { classes, addon, addonPosition, description } = props;

  // Default addon position.
  let addonPositionDefault: any = addonPosition;
  if (addon && !addonPosition) {
    addonPositionDefault = 'before';
  }

  return (
    <div className={classes || 'max-w-full'}>
      {addon ? (
        <div className={'p-inputgroup '}>
          {addonPositionDefault === 'before' && (
            <span className={'p-inputgroup-addon border-round-left'}><i className={'pi pi-' + addon}></i></span>
          )}
          {props.children}
          {addonPositionDefault === 'after' && (
            <span className={'p-inputgroup-addon border-round-right'}><i className={'pi pi-' + addon}></i></span>
          )}
        </div>
      ) : (
        <>{props.children}</>
      )}
      {description && (<span className={'small'}>{description}</span>)}
    </div>
  );
};
