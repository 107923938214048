import React from 'react';

import { ShortcutsList } from 'pages';
import appUri from 'config/appUri.json';
import { trimStart } from 'lodash';

export const RoutesShortcuts = () => {
  return [
    {
      path: trimStart(appUri.short.list),
      element: <ShortcutsList />,
    },
  ];
};
