import React, { useMemo, useState } from 'react';
import { Button } from 'primereact/button';
import { Fieldset } from 'primereact/fieldset';

import { iri, trans, triggerFormSubmit, ucfirst } from 'utilities';
import { FormWrapper } from 'forms';
import { ProprietairesDatatable, ProprietairesIndivisionsDatatable } from 'components';

import appUri from 'config/appUri.json';

export const Step2 = (props: any) => {
  const { data, apim, appState, context, dirigeant } = props;
  const { t, navigate} = apim.di();
  const redirectUri: string = appUri.dos.edit.patrimoine.biens.landing.replace(':id', appState?.dossier()?.id);

  const [indivisionMode, setIndivisionMode] = useState<boolean>(false);

  const renderForm = useMemo(() =>
      <FormWrapper multiple formKeyPrefix={'bien_immobilier_informations'} resourceType={'biensImmobiliers'} classes={'grid p-fluid w-12'}
                   cancelLink hideReload context={{...context, ...{dirigeant: dirigeant}}} withRedirect redirectUri={redirectUri}
                   data={{...data, ...{creditBiens: (data?.creditBiens ?? []).map((_c: any) => _c?.credit)}}}
                   formatter={(_data: any) => ({..._data, ...{creditBiens: (_data?.creditBiens ?? []).map((_c: any) => ({credit: iri('credits', _c?.id ?? _c)}))}})}/>
    , [data?.id, context?.id]); // eslint-disable-line react-hooks/exhaustive-deps
  const renderFormNote = useMemo(() =>
      <FormWrapper multiple formKeyPrefix={'zone_note'} resourceType={'biensImmobiliers'} classes={'grid p-fluid w-12'} hideReload data={data} notif={false}/>
    , [data?.id, context?.id]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div className={'a8-wizard wizard-patrimoine-bien-immobilier fadein animation-duration-500'}>
      <h5 className={'mx-3 pt-3 flex flex-row align-items-center'}>
        <span className={'mr-2 border-circle bg-primary flex align-items-center justify-content-center'} style={{width: '2rem', height: '2rem'}}><i className={'pi pi-info'}></i></span>
        {trans(t, 'menu|wizard.patrimoine.biens.default.step2')}
      </h5>

      {renderForm}

      <Fieldset legend={ucfirst(trans(t, 'property'))} className={'mt-0'} toggleable>
        {indivisionMode ? (
          <ProprietairesIndivisionsDatatable apim={apim} data={data} editMode context={{...context, ...{dirigeant: dirigeant}}} title={''} switchIndivision={() => setIndivisionMode(false)}/>
        ) : (
          <ProprietairesDatatable apim={apim} data={data} editMode context={{...context, ...{dirigeant: dirigeant}}} title={''} switchIndivision={() => setIndivisionMode(true)}/>
        )}
      </Fieldset>

      {renderFormNote}

      <div className={'text-right m-2 pt-4'}>
        <Button type={'reset'} className={'text-ucfirst p-button-text p-button-danger mr-5'} label={trans(t, 'close')} onClick={() => navigate(redirectUri)}/>
        <Button type={'submit'} className={'text-ucfirst'} label={trans(t, 'terminate')} icon={'pi pi-check'} onClick={() => triggerFormSubmit(null)}/>
      </div>
    </div>
  );
};
