import React, { useEffect, useState } from 'react';
import { trans } from 'utilities';

export const AppTopbarLeft = (props: any) => {
  const { appState, apim } = props;
  const { t } = apim.di();

  const defaultPageTitle = trans(t, 'system|welcome');
  const [pageTitle, setPageTitle] = useState<string>('');

  // Update page title.
  const currentPageTitle = appState.pageTitle();
  useEffect(() => {
    setPageTitle(currentPageTitle || defaultPageTitle);
  }, [currentPageTitle]); // eslint-disable-line react-hooks/exhaustive-deps

  return <h1 className={'a8-page-title pl-3'}>{pageTitle}</h1>;
};
