import React, { useCallback, useEffect, useState } from 'react';
import { AxiosResponse } from 'axios';

import { Fieldset } from 'primereact/fieldset';
import { Button } from 'primereact/button';

import { FormWrapper } from 'forms';
import { iri, trans, triggerFormSubmit, ucfirst } from 'utilities';
import { PatrimoineHeritiersDatatable } from 'components';
import { IRequestParams } from 'services';

import appUri from 'config/appUri.json';
import { startCase, toLower } from 'lodash';

export const Patrimoine = (props: any) => {
  const { data, dossier, apim } = props;
  const [successionOption, setSuccessionOption] = useState<any>( null);
  const [translatedSuccessionOption, setTranslatedSuccessionOption] = useState<any>( null)
  const [successionDonationOptions, setSuccessionDonationOptions] = useState<any[]>( []);
  const [successionDonationOptionsLoading, setSuccessionDonationOptionsLoading] = useState<boolean>(false);

  const { t, navigate } = apim.di();
  const redirectUri = appUri.dos.page.replace(':id', dossier?.id || '_') + '/patrimoine?maj=' + Date.now();

  useEffect(() => {
    if (!data?.id) return
    apim.getList('successionDonationOptions', {setter: setSuccessionDonationOptions, setLoading: setSuccessionDonationOptionsLoading} as IRequestParams).then();
    apim.fetchEntities({
      resourceType: 'patrimoineFoyers',
      params: [
        {label: 'patrimoine', value: data.id},
      ],
      cache: false,
      success: (res: AxiosResponse) => {
        setSuccessionOption(res?.data?.['hydra:member']?.[0]?.successionOptionSurvivant)
      }
    } as IRequestParams).then();
  }, [data]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (!successionOption || !successionDonationOptions) return
    setTranslatedSuccessionOption(successionDonationOptions?.find(option => option.value === successionOption)?.translated);
  }, [successionOption, successionDonationOptions]); // eslint-disable-line react-hooks/exhaustive-deps

    // Wrap form render into a useCallback to avoid multiple FormWrapper recalls du to form fields updates.
  const renderForm = useCallback(() =>
      <FormWrapper classes={'grid px-3'} formKeyPrefix={'general'} resourceType={'patrimoines'} cancelLink multiple hideReload={true}
                   redirectUri={redirectUri} data={data} additionalData={{ dossier: iri('dossiers', dossier?.id) }} context={data}/>
    , [data?.id]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div className={'a8-form patrimoine'}>
      <h5 className={'mx-3 pb-5 flex flex-row align-items-center'}>
        {ucfirst(trans(t, 'menu|menu.schema_flux.patrimoine'))}
      </h5>

      {renderForm()}

      <Fieldset legend={ucfirst(trans(t, 'form|patrimoine.succession.heritier',2))} className={'w-full mb-3'} toggleable>
        {!successionDonationOptionsLoading && (translatedSuccessionOption ? <span>{`${trans(t, 'form|patrimoine.succession.heritierHelper1')} ${startCase(toLower(dossier?.title))}${trans(t, 'form|patrimoine.succession.heritierHelper2')} "${translatedSuccessionOption.toLowerCase()}".`}</span>
          : <span>{trans(t, 'incompletePatrimoine')}</span>)}
        <PatrimoineHeritiersDatatable apim={apim} data={data} editMode context={{dossier: dossier}} title={''}/>
      </Fieldset>

      <div className={'flex justify-content-end'}>
        <Button type={'reset'} className={'text-ucfirst p-button-text p-button-danger mr-5'} label={trans(t, 'close')} onClick={() => navigate(redirectUri)}/>
        <Button type={'submit'} className={'text-ucfirst'} label={trans(t, 'save')} icon={'pi pi-check'} iconPos={'right'} onClick={() => triggerFormSubmit(null)}/>
      </div>
    </div>
  );
};
