import React from 'react';
import { Fieldset } from 'primereact/fieldset';
import { trans, ucfirst } from 'utilities';
import { MaskField, NumberField } from 'forms';

export const ValidationForm = (props: any) => {
  const { t, control, onFieldChange, errorMessage } = props;

  return <>
    <Fieldset legend={ucfirst(trans(t, 'form|update_indicators'))} className={'col mx-3'}>
      <div className={'grid'}>
        <div className={'col-2'}>
          <NumberField classes={'w-full'} fieldKey={'indiceCouverture'} mode={'decimal'} min={0} max={10} showButtons minFractionDigits={0}
                       control={control} onFieldChange={onFieldChange} maxFractionDigits={0}
                       errorMessage={errorMessage} label={trans(t, 'form|prevoyance.indiceCouverture')}/>
        </div>

        <div className={'col-2'}>
          <NumberField classes={'w-full'} fieldKey={'indiceProtection'} mode={'decimal'} min={0} max={10} showButtons minFractionDigits={0}
                       control={control} onFieldChange={onFieldChange} maxFractionDigits={0}
                       errorMessage={errorMessage} label={trans(t, 'form|prevoyance.indiceProtection')}/>
        </div>

        <div className={'col-2'}>
          <MaskField classes={'w-full'} fieldKey={'lastUpdate'} control={control} onFieldChange={onFieldChange} label={ucfirst(trans(t, 'updated'))} errorMessage={errorMessage} isDate/>
        </div>
      </div>
    </Fieldset>
  </>
;
};
