import React, { useCallback, useEffect, useState } from 'react';
import { AxiosResponse } from 'axios';

import { Checkbox } from 'primereact/checkbox';
import { InputText } from 'primereact/inputtext';

import { dateString, trans } from 'utilities';
import { IRequestParams, isClient } from 'services';
import { formatPieChart, formatXYChart, AMChartPie, AMChartXY, getModuleId, IndicatorSimple, PageLoader } from 'components';
import { getIndicator } from 'pages';
import { useThemeState } from 'states';

import { isEmpty, replace } from 'lodash';
import appUri from 'config/appUri.json';

export const RetraiteObligatoire = (props: any) => {
  const { id, appState, apim, modulesMenu, lastUpdate } = props;
  const { t } = apim.di();
  const themeState = useThemeState();

  const dossier = appState.dossier();
  const [loading, setLoading] = useState<boolean>(false);
  const [data, setData] = useState<any>(null);

  // Override Page Header (defaults into ModuleWrapper, then RetraiteRouter).
  useEffect(() => {
    appState.setBreadcrumb([
      isClient()
       ? { label: trans(t, 'dossier', 2) }
       : { label: trans(t, 'dossier', 2), to: appUri.dos.list },
      { label: dossier.title, to: replace(appUri.dos.page, ':id', id)},
      { label: trans(t, 'mission', 2), to: replace(appUri.dos.missions.history, ':id', id)},
      { label: trans(t, 'menu|menu.schema_flux.retraite_obligatoire') }
    ]);

    appState.setModule(getModuleId(modulesMenu, 'retraite.obligatoire'));
  }, [id]); // eslint-disable-line react-hooks/exhaustive-deps

  const fetchData = useCallback(async () => {
    if (!appState.mission()) return null;
    setLoading(true);

    apim.call({
      resourceType: 'dossiers',
      action: 'module',
      id: id + '|' + getModuleId(modulesMenu, 'retraite.obligatoire'),
      method: 'get',
      params: [{label: 'mission', value: appState.mission()?.id}],
      cacheMode: isEmpty(lastUpdate) ? 'default' : 'replace',
      setLoading: setLoading,
      success: (res: AxiosResponse) => setData({...res.data, ...{
          chart1: formatXYChart(res.data, 'missionRetraite.chart.projectionTrimestres', t, {tradCategories: false, method: 'asIt'}),
          chart2: formatPieChart(res.data, 'missionRetraite.chart.trimestres', t),
          chart3: formatXYChart(res.data, 'missionRetraite.chart.projectionMontantAnnuelRetraite', t, {tradCategories: false})
      }})
    } as IRequestParams);
  }, [appState.company()?.id, appState.mission()?.id, lastUpdate]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    fetchData().then();
  }, [appState.company()?.id, appState.mission()?.id, lastUpdate]); // eslint-disable-line react-hooks/exhaustive-deps

  if (loading) return <PageLoader />;

  return (
    <div className={'flex flex-column xl:flex-row flex-wrap fadein animation-duration-500'}>
      <div className={'flex xl:w-6 py-4 px-3'}>
        <div className={'flex flex-row flex-grow-1'}>
          {themeState.isMobile() ||themeState.isTablet() ? (
            <div className={'h-30rem flex flex-row flex-grow-1'}>
              <AMChartXY chartData={data?.chart1} showCursor extraMax={.1} extraMin={1} verticalLegend />
            </div>
          ) : (
            <AMChartXY chartData={data?.chart1} showCursor extraMax={.1} extraMin={1} />
          )}
        </div>
      </div>
      <div className={'xl:w-6 py-4 px-3'}>
        <div className={'flex flex-row flex-grow-1'}>
            {themeState.isMobile() ||themeState.isTablet() ? (
            <div className={'h-30rem flex flex-row flex-grow-1'}>
              <AMChartPie chartData={data?.chart2} verticalLegend />
            </div>
          ) : (
            <AMChartPie chartData={data?.chart2} hideLegend />
          )}
        </div>
      </div>

      <div className={'flex justify-content-center xl:justify-content-start xl:w-2 py-4 px-3'}>
        <div className={'flex flex-column p-fluid'}>
          <div className={'row w-full'}>
              <ul className={'graphique-frise'}>
                <h5>{trans(t, 'chart|missionRetraite.timeline')}</h5>
                <span className={'graphique-frise-fleche'}>▲</span>
                <li>{trans(t, 'chart|missionRetraite.auPlusTot')} : {dateString(getIndicator(data, 'missionRetraite.auPlusTot').value)}</li>
                <li>{trans(t, 'chart|missionRetraite.tauxPleinAutomatique')} : {dateString(getIndicator(data, 'missionRetraite.tauxPleinAutomatique').value)}</li>
                <li>{trans(t, 'chart|missionRetraite.tauxPleinAcquis')} : {dateString(getIndicator(data, 'missionRetraite.tauxPleinAcquis').value)}</li>
                <li>{trans(t, 'chart|missionRetraite.souhaitee')} : {dateString(getIndicator(data, 'missionRetraite.souhaitee').value)}</li>
                <li>{trans(t, 'chart|missionRetraite.preconisee')} : {dateString(getIndicator(data, 'missionRetraite.preconisee').value)}</li>
              </ul>
          </div>

          <div className={'row'}>
            <div className={'col-12'}>
              <div className={'p-inputgroup'}>
                <span className={'p-inputgroup-addon p-inputgroup-addon-checkbox'}>
                  <Checkbox checked={true === getIndicator(data, 'missionRetraite.carriereLongue').value} disabled={true} />
                </span>
                <InputText placeholder={trans(t, 'chart|missionRetraite.carriereLongue')} disabled className={'a8-fake-dis'} />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className={'xl:w-10 py-4 px-3'}>
        <div className={'flex flex-row flex-grow-1'}>
            {themeState.isMobile() ||themeState.isTablet() ? (
            <div className={'h-30rem flex flex-row flex-grow-1'}>
              <AMChartXY chartData={data?.chart3} showCursor extraMax={.1} extraMin={.1} verticalLegend />
            </div>
          ) : (
            <AMChartXY chartData={data?.chart3} showCursor extraMax={.1} extraMin={.1} xType={'date'} />
          )}
        </div>
      </div>
      <div className={'flex md:flex-row flex-wrap'}>
        <div className={'flex w-12 md:w-6 xl:w-3 py-4 pl-2 pr-3'}>
          <IndicatorSimple color={'primary'} dark bgImage title={trans(t, 'chart|missionRetraite.montantRetraiteAcquise')} value={getIndicator(data, 'missionRetraite.montantRetraiteAcquise').value} />
        </div>
        <div className={'flex w-12 md:w-6 xl:w-3 py-4 px-3'}>
          <IndicatorSimple color={'accent'} bgImage title={trans(t, 'chart|missionRetraite.chargeSocialeAvantLiquidationDesDroits')} value={getIndicator(data, 'missionRetraite.chargeSocialeAvantLiquidationDesDroits').value} />
        </div>
        <div className={'flex w-12 md:w-6 xl:w-3 py-4 px-3'}>
          <IndicatorSimple color={'accent'} bgImage title={trans(t, 'chart|missionRetraite.montantRetraiteProjete')} value={getIndicator(data, 'missionRetraite.montantRetraiteProjete').value} />
        </div>
        <div className={'flex w-12 md:w-6 xl:w-3 py-4 pl-3 pr-2'}>
          <IndicatorSimple color={'accent'} bgImage title={trans(t, 'chart|missionRetraite.trimestresCotisesSurDerniersRevenusDeclares')} value={getIndicator(data, 'missionRetraite.trimestresCotisesSurDerniersRevenusDeclares').value} options={{symbol: ''}} />
        </div>
        <div className={'flex w-12 md:w-6 xl:w-3 py-4 pl-2 pr-3'}>
          <IndicatorSimple color={'primary'} size={'min'} bgImage title={trans(t, 'chart|missionRetraite.caisseRetraiteAvantEtude')} emptyValue={getIndicator(data, 'missionRetraite.caisseRetraiteAvantEtude').value} />
        </div>
        <div className={'flex w-12 md:w-6 xl:w-3 py-4 px-3'}>
          <IndicatorSimple color={'primary'} size={'min'} bgImage title={trans(t, 'chart|missionRetraite.caisseRetraiteApresEtude')} emptyValue={getIndicator(data, 'missionRetraite.caisseRetraiteApresEtude').value} />
        </div>
        <div className={'flex w-12 md:w-6 xl:w-3 py-4 px-3'}>
          <IndicatorSimple color={'primary'} size={'min'} bgImage title={trans(t, 'chart|missionRetraite.statutAvantEtude')} emptyValue={getIndicator(data, 'missionRetraite.statutAvantEtude').value} />
        </div>
        <div className={'flex w-12 md:w-6 xl:w-3 py-4 pl-3 pr-2'}>
          <IndicatorSimple color={'primary'} size={'min'} bgImage title={trans(t, 'chart|missionRetraite.statutApresEtude')} emptyValue={getIndicator(data, 'missionRetraite.statutApresEtude').value} />
        </div>
        <div className={'flex w-12 md:w-4 xl:w-4 py-4 pl-2 pr-3'}>
          <IndicatorSimple color={'accent3'} bgImage title={trans(t, 'chart|missionRetraite.tauxPleinAcquis')} emptyValue={dateString(getIndicator(data, 'missionRetraite.tauxPleinAcquis').value)} />
        </div>
        <div className={'flex w-12 md:w-4 xl:w-4 py-4 px-3'}>
          <IndicatorSimple color={'accent3'} bgImage title={trans(t, 'chart|missionRetraite.remunerationSocialePreconisee')} value={getIndicator(data, 'missionRetraite.remunerationSocialePreconisee').value} />
        </div>
        <div className={'flex w-12 md:w-4 xl:w-4 py-4 pl-3 pr-2'}>
          <IndicatorSimple color={'accent3'} bgImage title={trans(t, 'chart|missionRetraite.samSamFinal')} value={getIndicator(data, 'missionRetraite.samSamFinal').value} />
        </div>
      </div>
    </div>
  );
};
