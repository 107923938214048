import React from 'react';
import { Checkbox } from 'primereact/checkbox';
import { BaseTextField } from './BaseTextField';

export const CheckboxField = (props: any) => {
  const { fieldKey, control, onFieldChange, errorMessage,
    rules, label, classes, addon, addonPosition, disabled, tooltip, tooltipOptions, help, textAddon } = props;

  const renderInput = (field: any, fieldState: any) =>
    <div className={'p-inputgroup flex align-items-stretch'}>
      <span style={{padding: '12px 12px 12px 8px'}}>
        <Checkbox inputId={field.name + '_input'} id={field.name} checked={field.value && field.value !== false} disabled={disabled}
                  onChange={(e) => { onFieldChange(field, fieldState, e.checked, 'boolean'); }}
                  tooltip={tooltip} tooltipOptions={tooltipOptions || {position: 'top'}} />
      </span>
      <label htmlFor={field.name + '_input'} className={'flex flex-auto flex-column justify-content-center'}>
        <span>{label}</span>
        {help && (<span className={'help text-xs'}>{help}</span>)}
      </label>
    </div>
  ;

  return <BaseTextField fieldKey={fieldKey} control={control} onFieldChange={onFieldChange} errorMessage={errorMessage} rules={rules} classes={classes}
                        addon={addon} addonPosition={addonPosition} textAddon={textAddon} renderInput={renderInput} />
};
