import React, { useCallback } from 'react';

import { PageLoader } from 'components';
import { FormWrapper, onPersonPhysiqueFormSubmit } from 'forms';
import { flatPerson } from 'utilities';

import appUri from 'config/appUri.json';

export const PersonnePhysiqueProfile = (props: any) => {
  const { data, apim } = props;
  const { navigate} = apim.di();

  // Let's flat all fields to root level to simplify nested forms.
  const person = flatPerson(data);
  const redirectUri = appUri.cie.per.phy.page.replace(':id', data?.id || '_') + '?maj=' + Date.now();

  const onFormSubmit = (formData: any) => onPersonPhysiqueFormSubmit(formData, data, apim, () => navigate(redirectUri));

  // Wrap form render into a useCallback to avoid multiple FormWrapper recalls du to form fields updates.
  const renderForm = useCallback(() =>
      <FormWrapper data={person} formKeyPrefix={'personne_physique'} resourceType={'personnesPhysiques'} cancelLink classes={'mt-4'} subClasses={''}
                   onSubmit={onFormSubmit} redirectUri={redirectUri} />
    , [person?.personnePhysiqueId]); // eslint-disable-line react-hooks/exhaustive-deps

  return !person ? (<PageLoader/>) : (
    <div className={'grid'}>
      <div className={'col-12'}>
        {renderForm()}
      </div>
    </div>
  );
};
