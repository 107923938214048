import React, { useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { AxiosResponse } from 'axios';

import { ProgressBar } from 'primereact/progressbar';
import { Steps } from 'primereact/steps';
import { Skeleton } from 'primereact/skeleton';

import { iri, trans, ucfirst } from 'utilities';
import { Error, NotFound } from 'pages';
import { Loader, PageLoader } from 'components';
import { IRequestParams, useApim } from 'services';
import { useAppState } from 'states';
import { getCivilYearEditContext } from './ModuleHelper';
import {
  DonneesSocialesDirigeant,
  RevenuJuridic,
  RevenuFiscal,
  RevenuSocial,
  Contrats
} from './tabs';
import { CivilYear } from '../headers';

import appUri from 'config/appUri.json';

export const AnnualDataFormRouter = () => {
  const { id, year, step } = useParams();
  const apim = useApim();
  const { t, navigate } = apim.di();
  const appState = useAppState();
  const dossier: any = appState.dossier();

  // Update AppState "currents".
  useEffect(() => {
    if (!year) return;

    appState.setCivilYear(year);
    appState.setExercise(year);
  }, [year]); // eslint-disable-line react-hooks/exhaustive-deps

  const [loading, setLoading] = useState<boolean>(false);
  const [notFound, setNotFound] = useState<boolean>(false);
  const [errored, setErrored] = useState<boolean>(false);

  // Specific to some steps but easier to handle here.
  const [dossierSocialDataChanged, setDossierSocialDataChanged] = useState<number>(Date.now());
  const [dossierSocialDataPatchLoading, setDossierSocialDataPatchLoading] = useState<boolean>(false);
  const [dossierSocialData, setDossierSocialData] = useState<any>(null);

  useEffect(() => {
    if (dossier?.id === id) return;

    // Update AppState Dossier.
    apim.fetchEntity({
      resourceType: 'dossiers',
      id,
      // cache: false, // No need to prevent caching here
      setLoading,
      setErrored,
      setNotFound,
      setter: appState.setDossier,
    } as IRequestParams).then();
  }, [id]); // eslint-disable-line react-hooks/exhaustive-deps

  const [activeIndex, setActiveIndex] = useState<number>(-1);
  const { urls, defaultBC, index } = getCivilYearEditContext(t, appState, { id, year, step });

  // Update active index & Page Header.
  useEffect(() => {
    setActiveIndex(index);

    appState.setBreadcrumb(defaultBC);
    appState.setPageActions([]);
    appState.setPageTitle(ucfirst(trans(t, 'dossier')) + ' ' + appState.dossier()?.title + ' | ' + trans(t, 'editing'));
  }, [dossier?.id, appState.civilYear(), step]); // eslint-disable-line react-hooks/exhaustive-deps

  const wizardItems = [
    { label: ucfirst(trans(t, 'menu|wizard.dossier.annualData.step1')), command: () => navigate(urls.form) },
    { label: ucfirst(trans(t, 'menu|wizard.dossier.annualData.step2')), command: () => navigate(urls.form + appUri.dos.edit.civilYear.steps.step2) },
    { label: ucfirst(trans(t, 'menu|wizard.dossier.annualData.step3')), command: () => navigate(urls.form + appUri.dos.edit.civilYear.steps.step3) },
    { label: ucfirst(trans(t, 'menu|wizard.dossier.annualData.step4')), command: () => navigate(urls.form + appUri.dos.edit.civilYear.steps.step4) },
    { label: ucfirst(trans(t, 'menu|wizard.dossier.annualData.step5')), command: () => navigate(urls.form + appUri.dos.edit.civilYear.steps.step5) },
  ];

  const buildTabContent = useCallback(() => {
    switch (activeIndex) {
      case -1:
        return <Loader />

      case 0:
        return <DonneesSocialesDirigeant appState={appState} apim={apim} urls={urls}
                      additionalData={{dossier: iri('dossiers', dossier?.id), year: parseInt(year!)}} />;

      case 1:
        return <RevenuJuridic appState={appState} apim={apim} urls={urls} />;

      case 2:
        return <RevenuFiscal appState={appState} apim={apim} urls={urls} />;

      case 3:
        return <RevenuSocial appState={appState} apim={apim} urls={urls} />;

      case 4:
        return <Contrats appState={appState} apim={apim} urls={urls}
                      additionalData={{dossier: iri('dossiers', dossier?.id), year: parseInt(year!)}} />;

      default:
        return <NotFound asBlock />
    }
  }, [dossier?.id, year, step, activeIndex, dossierSocialData, dossierSocialDataChanged]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (!appState.dossier()?.id) {
      return;
    }

    apim.fetchEntities({
      resourceType: 'dossierSocialData',
      params: [
        {label: 'dossier', value: appState.dossier()?.id},
        {label: 'year', value: appState.civilYear()},
      ],
      cache: false,
      notif: false,
      success: (res: AxiosResponse) => {
        if (!res?.data || (res?.data['hydra:member'] || []).length === 0) {
          return setDossierSocialData(null);
        }

        setDossierSocialData(res.data['hydra:member'][0]);
      }
    } as IRequestParams).then();
  }, [appState.dossier()?.id, appState.civilYear(), dossierSocialDataChanged]); // eslint-disable-line react-hooks/exhaustive-deps

  if (!dossier?.id || !appState.civilYear() || loading || activeIndex < 0) return <PageLoader/>;
  if (notFound || -1 === activeIndex) return <NotFound asBlock/>;
  if (errored) return <Error asBlock/>;

  const stepProgressSize = 100 / wizardItems.length;

  return (
    <>
      <div className={'grid'}>
        <div className={'col'}>
          <ProgressBar value={stepProgressSize + activeIndex * stepProgressSize} showValue={false}/>
        </div>
      </div>
      <Steps model={wizardItems} activeIndex={activeIndex} onSelect={(e) => setActiveIndex(e.index)} readOnly={false}/>
      <div className={'card height-100 fadein animation-duration-500 flex flex-column'}>
        <div className={'a8-page-header flex justify-content-center mb-4'}>
          {loading ? (
            <Skeleton width={'100%'} height={'2rem'} />
          ) : (
            <CivilYear appState={appState} apim={apim} baseUri={appUri.dos.edit.civilYear.uri.replace(':id', id || '_') + '/' + step} step={step}
                       dossierSocialData={dossierSocialData} setDossierSocialDataChanged={setDossierSocialDataChanged}
                       setDossierSocialDataPatchLoading={setDossierSocialDataPatchLoading} dossierSocialDataPatchLoading={dossierSocialDataPatchLoading} />
          )}
        </div>
        {buildTabContent()}
      </div>
    </>
  );
};
