import React from 'react';
import { classNames } from 'primereact/utils';
import { InputTextarea } from 'primereact/inputtextarea';
import { BaseTextField } from './BaseTextField';
import { trans } from 'utilities';
import { useApim } from 'services';

export const TextAreaField = (props: any) => {
  const { fieldKey, control, onFieldChange, errorMessage, rules, label, tooltip, tooltipOptions,
    classes, addon, addonPosition, format, disabled, autoResize, rows, textAddon, description } = props;

  const apim = useApim();
  const { t } = apim.di();

  const renderInput = (field: any, fieldState: any) =>
    <span className={'p-float-label'}>
      <InputTextarea id={field.name} className={classNames('text-ucfirst w-full', {'p-invalid': fieldState.error})}
                     value={field.value || ''} disabled={disabled} autoResize={autoResize || true} rows={rows || 3}
                     onChange={(e) => onFieldChange(field, fieldState, e.target.value, format || 'default')}
                     tooltip={tooltip} tooltipOptions={tooltipOptions || {position: 'top'}}/>
      {(label || field.name) && (
        <label className={'text-ucfirst p-float-label-label'} htmlFor={field.name}>
          <span>{label ?? trans(t, field.name)}</span>
        </label>
      )}
    </span>
  ;

  return <BaseTextField fieldKey={fieldKey} control={control} errorMessage={errorMessage} rules={rules} classes={classes}
                        addon={addon} addonPosition={addonPosition} textAddon={textAddon} renderInput={renderInput} description={description} />
};
