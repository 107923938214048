import React from 'react';
import { classNames } from 'primereact/utils';
import { InputNumber } from 'primereact/inputnumber';
import { BaseTextField } from './BaseTextField';
import { trans } from 'utilities';
import { useApim } from 'services';

export const NumberField = (props: any) => {
  const { fieldKey, control, onFieldChange, errorMessage, rules, label, tooltip, tooltipOptions, description, step,
    classes, addon, addonPosition, format, disabled, mode, currency, minFractionDigits,
    maxFractionDigits, showButtons, min, max, suffix, prefix, defaultValue, textAddon, onBlur } = props;

  const apim = useApim();
  const { t } = apim.di();

  let maxFractionDigitsInner = 2;
  if (maxFractionDigits !== null && maxFractionDigits !== undefined) {
    maxFractionDigitsInner = maxFractionDigits;
  }

  let minFractionDigitsInner = 0;
  if (minFractionDigits !== null && minFractionDigits !== undefined) {
    minFractionDigitsInner = minFractionDigits;
  }

  let stepInner = 1;
  if (step !== null && step !== undefined) {
    stepInner = step;
  }

  const renderInput = (field: any, fieldState: any, addonPosition: string) =>
    <span className={'p-float-label'}>
      <InputNumber id={field.name} inputClassName={classNames('w-full', (addonPosition === null || addonPosition === undefined || addonPosition.length === 0 ? '' : addonPosition === 'before' ? 'border-noround-left' : 'border-noround-right'), (showButtons ? 'border-noround-right' : ''))}
                   className={classNames('text-ucfirst', {'p-invalid': fieldState.error})} maxFractionDigits={maxFractionDigitsInner} minFractionDigits={minFractionDigitsInner}
                   value={field.value ?? defaultValue} disabled={disabled} mode={mode || 'currency'} currency={currency || 'EUR'} aria-invalid={fieldState.error} step={stepInner}
                   onChange={(e) => onFieldChange(field, fieldState, e.value, format || 'number')} tooltip={tooltip} tooltipOptions={tooltipOptions || {position: 'top'}}
                   showButtons={showButtons} min={min} max={max} suffix={suffix} prefix={prefix} onBlur={onBlur} onKeyDown={(e) => {
        if (e.key === '.' && !e.currentTarget.value.includes(',')) e.currentTarget.value = e.currentTarget.value + ',';
      }}/>
      {(label || field.name) && (
        <label className={'text-ucfirst p-float-label-label'} htmlFor={field.name}>
          <span>{label ?? trans(t, field.name)}</span>
        </label>
      )}
    </span>;

  return <BaseTextField fieldKey={fieldKey} control={control} errorMessage={errorMessage} rules={rules} classes={classes}
                        addon={addon} addonPosition={addonPosition} textAddon={textAddon} renderInput={renderInput} description={description} />
};
