import React, { useCallback, useEffect, useState } from 'react';
import { AxiosResponse } from 'axios';

import { DataTable, DataTableExpandedRows } from 'primereact/datatable';
import { Column } from 'primereact/column';

import { trans } from 'utilities';
import { IRequestParams, isClient } from 'services';
import { formatTable, formatXYChart, AMChartPie, AMChartXY, IndicatorSimple, PageLoader, getModuleId, formatPieChart } from 'components';
import { getIndicator } from 'pages';
import { useThemeState } from 'states';

import { isEmpty, replace } from 'lodash';
import appUri from 'config/appUri.json';

export const RevenuEtImposition = (props: any) => {
  const { id, appState, apim, modulesMenu, lastUpdate } = props;
  const { t} = apim.di();
  const themeState = useThemeState();

  const dossier = appState.dossier();
  const [loading, setLoading] = useState<boolean>(false);
  const [data, setData ] = useState<any>(null);
  const [expandedRows, setExpandedRows] = useState<DataTableExpandedRows>();

  // Override Page Header (defaults into ModuleWrapper).
  useEffect(() => {
    appState.setBreadcrumb([
      isClient()
       ? { label: trans(t, 'dossier', 2) }
       : { label: trans(t, 'dossier', 2), to: appUri.dos.list },
      { label: dossier.title, to: replace(appUri.dos.page, ':id', id)},
      { label: trans(t, 'menu|menu.schema_flux.revenuEtImposition') }
    ]);

    appState.setModule(getModuleId(modulesMenu, 'revenus'));
  }, [id]); // eslint-disable-line react-hooks/exhaustive-deps

  const fetchData = useCallback(async () => {
    if (!appState.civilYear()) return null;

    setLoading(true);
    apim.call({
      resourceType: 'dossiers',
      action: 'module',
      id: id + '|' + getModuleId(modulesMenu, 'revenus'),
      method: 'get',
      params: [{label: 'company', value: appState.company()?.id}, {label: 'year', value: appState.civilYear()}],
      cacheMode: isEmpty(lastUpdate) ? 'default' : 'replace',
      setLoading: setLoading,
      success: (res: AxiosResponse) => setData({
        ...res.data,
        ...{
          chart1: formatPieChart(res.data, 'revenuImposition.chart.partRevenuFiscalSurTotal', t),
          chart2: formatPieChart(res.data, 'revenuImposition.chart.ventilationRevenu', t),
          chart3: formatXYChart(res.data, 'revenuImposition.chart.tauxMoyenEtRevenuEvolution', t, {tradCategories: false}),
          table1: formatTable(res.data, 'revenuImposition.table.revenu', t, {method: 'simpleRows'})
      }})
    } as IRequestParams);
  }, [appState.company()?.id, appState.civilYear(), lastUpdate]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    fetchData().then();
  }, [appState.company()?.id, appState.civilYear(), lastUpdate]); // eslint-disable-line react-hooks/exhaustive-deps

  if (loading) return <PageLoader/>;

  const canExpand = (rowData: any) => rowData.tables;

  const rowExpansionTemplate = (rowData: any) => {
    const subData = formatTable(rowData, 'revenuImposition.table.revenuCompagnieDetails', t, {method: 'montant', tradOnlyFirstHeader: true});

    return (
      <div className={'flex flex-row flex-wrap fadein animation-duration-500 a8-subtable'}>
        <div className={'grid p-fluid mt-1 w-full'}>
          <div className={'col-12 px-6'}>
            <h6 className={'text-color-secondary'}>{trans(t, 'table|revenuImposition.table.revenu', 1, rowData.entreprise)}</h6>
            <DataTable value={subData?.data} className={'datatable-responsive'} stripedRows={true}>
              {subData?.headers.map((h: string, i: number) => (
                <Column key={i} header={h} field={'column-' + (i + 1)} align={0 === i ? 'left' : 'center'} bodyStyle={0 === i ? {} : {fontWeight: 600}} />
              ))}
            </DataTable>
          </div>
        </div>
      </div>
    );
  };

  return (
    <div className={'flex flex-row flex-wrap fadein animation-duration-500'}>
      <div className={'flex w-12 xl:w-6 py-4 px-3'}>
        <div className={'flex flex-row flex-grow-1'}>
        {themeState.isMobile() || themeState.isTablet() ? (
          <AMChartPie chartData={data?.chart1} verticalLegend />
        ) : themeState.isNotebook() ? (
          <AMChartPie chartData={data?.chart1} />
        ) : (
          <AMChartPie chartData={data?.chart1} hideLegend />
        )}
        </div>
      </div>

      <div className={'flex w-12 xl:w-6 py-4 px-3'}>
        <div className={'flex flex-row flex-grow-1'}>
          {themeState.isMobile() || themeState.isTablet() ? (
            <AMChartPie chartData={data?.chart2} verticalLegend/>
          ) : themeState.isNotebook() ? (
            <AMChartPie chartData={data?.chart2} />
          ) : (
            <AMChartPie chartData={data?.chart2} hideLegend />
          )}
        </div>
      </div>

      <div className={'flex w-12 xl:w-6 py-4 px-3'}>
        {!themeState.isDesktop() ?(
          <div className={'flex flex-row flex-grow-1 h-30rem'}>
            <AMChartXY chartData={data?.chart3} showCursor extraMax={.1} extraMin={.1} verticalLegend xType={'date'} />
          </div>
        ):(
          <div className={'flex flex-row flex-grow-1'}>
            <AMChartXY chartData={data?.chart3} showCursor extraMax={.1} extraMin={.1} verticalLegend xType={'date'} />
            </div>
        )}
      </div>

      <div className={'flex flex-column w-12 md:w-6 xl:w-3'}>
        <div className={'flex py-4 pl-2 pr-3'}>
          <IndicatorSimple color={'accent4'} dark bgImage title={trans(t, 'chart|revenuImposition.tauxMarginalImposition')} value={getIndicator(data, 'revenuImposition.tauxMarginalImposition').value} options={{symbol: '%'}} />
        </div>
        <div className={'flex py-4 pl-2 pr-3'}>
          <IndicatorSimple color={'primary'} dark bgImage title={trans(t, 'chart|revenuImposition.volumeDisponible')} value={getIndicator(data, 'revenuImposition.volumeDisponible').value} />
        </div>
      </div>

      <div className={'flex flex-column w-12 md:w-6 xl:w-3'}>
        <div className={'flex py-4 pl-2 pr-3'}>
          <IndicatorSimple color={'accent'} dark bgImage title={trans(t, 'chart|revenuImposition.revenuFiscalDeReference')} value={getIndicator(data, 'revenuImposition.revenuFiscalDeReference').value} />
        </div>
        <div className={'flex py-4 pl-2 pr-3'}>
          <IndicatorSimple color={'primary'} dark bgImage title={trans(t, 'chart|revenuImposition.remunerationSocialePreconisee')} value={getIndicator(data, 'revenuImposition.remunerationSocialePreconisee').value} />
        </div>
      </div>

      <div className={'grid p-fluid mt-3 w-full'}>
        <div className={'col-12'} style={{ maxWidth: themeState.isMobile() || themeState.isTablet() ? '80vw' : '100%' }}>
          {data?.table1.data?.length > 0 && (
            <>
              <DataTable value={data?.table1.data} className={'datatable-responsive p-datatable-no-hover'} stripedRows={true}
                         expandedRows={expandedRows} rowExpansionTemplate={rowExpansionTemplate}
                         onRowToggle={(e) => setExpandedRows(e.data as DataTableExpandedRows)}>
                <Column expander={canExpand} style={{ width: '3rem' }} />
                <Column field={'entreprise'} header={trans(t, 'table|revenuImposition.table.revenu.header.entreprise')} />
                <Column field={'revenuJuridique'} header={trans(t, 'table|revenuImposition.table.revenu.header.revenuJuridique')} align={'center'} />
                <Column field={'revenuSocialTns'} header={trans(t, 'table|revenuImposition.table.revenu.header.revenuSocialTns')} align={'center'} />
                <Column field={'revenuSocialSalarie'} header={trans(t, 'table|revenuImposition.table.revenu.header.revenuSocialSalarie')} align={'center'} />
                <Column field={'revenuFiscal'} header={trans(t, 'table|revenuImposition.table.revenu.header.revenuFiscal')} align={'center'} />
                <Column field={'dividendes'} header={trans(t, 'table|revenuImposition.table.revenu.header.dividendes')} align={'center'} />
              </DataTable>
            </>
          )}
        </div>
      </div>
    </div>
  );
};
