import React, { useCallback, useEffect, useState } from 'react';
import { AxiosResponse } from 'axios';

import { trans } from 'utilities';
import { IRequestParams, isClient } from 'services';
import { AMChartXY, getModuleId, IndicatorSimple, PageLoader, formatXYChart} from 'components';
import { getIndicator } from 'pages';

import { isEmpty, replace } from 'lodash';
import appUri from 'config/appUri.json';

export const Reserves = (props: any) => {
  const { id, appState, apim, modulesMenu, lastUpdate } = props;
  const { t } = apim.di();

  const dossier = appState.dossier();
  const [loading, setLoading] = useState<boolean>(false);
  const [data, setData] = useState<any>(null);

  // Override Page Header (defaults into ModuleWrapper then ReservesRouter).
  useEffect(() => {
    appState.setBreadcrumb([
     isClient()
      ? { label: trans(t, 'dossier', 2) }
      : { label: trans(t, 'dossier', 2), to: appUri.dos.list },
     { label: dossier.title, to: replace(appUri.dos.page, ':id', id)},
     { label: trans(t, 'menu|menu.schema_flux.reserves') }
    ]);

    appState.setModule(getModuleId(modulesMenu, 'reserves'));
  }, [id]); // eslint-disable-line react-hooks/exhaustive-deps

  const fetchData = useCallback(async () => {
    if (!appState.exercise()) return null;
    setLoading(true);

    apim.call({
      resourceType: 'dossiers',
      action: 'module',
      id: id + '|' + getModuleId(modulesMenu, 'reserves'),
      method: 'get',
      params: [{label: 'company', value: appState.company()?.id}, {label: 'year', value: appState.exercise()}],
      cacheMode: isEmpty(lastUpdate) ? 'default' : 'replace',
      setLoading: setLoading,
      success: (res: AxiosResponse) => setData({...res.data, ...{
          chart1: formatXYChart(res.data, 'reserves.chart.montantDistribuable', t, {tradCategories: false})
      }})
    } as IRequestParams);
  }, [appState.company()?.id, appState.exercise(), lastUpdate]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    fetchData().then();
  }, [appState.company()?.id, appState.exercise(), lastUpdate]); // eslint-disable-line react-hooks/exhaustive-deps

  if (loading) return <PageLoader />;

  return (
    <div className={'flex flex-column md:flex-row flex-wrap fadein animation-duration-500'}>
      <div className={'flex md:w-4 py-4 px-3'}>
        <IndicatorSimple color={'primary'} dark bgImage title={trans(t, 'chart|reserves.reserves')}
                         value={getIndicator(data, 'reserves.reserves').value}
                         tooltip={getIndicator(data, 'reserves.reserves').options?.tooltip ? trans(t, 'chart|' + getIndicator(data, 'reserves.reserves').options?.tooltip) : null}
                         precision={getIndicator(data, 'reserves.reserves').options?.precision ?? 0}
                         symbol={getIndicator(data, 'reserves.reserves').options?.symbol ?? null} />
      </div>

      <div className={'flex md:w-4 py-4 px-3'}>
        <IndicatorSimple color={'accent'} dark bgImage title={trans(t, 'chart|reserves.autresReserves')}
                         value={getIndicator(data, 'reserves.autresReserves').value}
                         tooltip={getIndicator(data, 'reserves.autresReserves').options?.tooltip ? trans(t, 'chart|' + getIndicator(data, 'reserves.autresReserves').options?.tooltip) : null}
                         precision={getIndicator(data, 'reserves.autresReserves').options?.precision ?? 0}
                         symbol={getIndicator(data, 'reserves.autresReserves').options?.symbol ?? null} />
      </div>

      <div className={'flex md:w-4 py-4 px-3'}>
        <IndicatorSimple color={'accent'} bgImage title={trans(t, 'chart|reserves.montantDistribuable')}
                         value={getIndicator(data, 'reserves.montantDistribuable').value}
                         tooltip={getIndicator(data, 'reserves.montantDistribuable').options?.tooltip ? trans(t, 'chart|' + getIndicator(data, 'reserves.montantDistribuable').options?.tooltip) : null}
                         precision={getIndicator(data, 'reserves.montantDistribuable').options?.precision ?? 0}
                         symbol={getIndicator(data, 'reserves.montantDistribuable').options?.symbol ?? null} />
      </div>

      <div className={'flex md:w-4 py-4 px-3'}>
        <IndicatorSimple color={'accent2'} bgImage title={trans(t, 'chart|reserves.benefices')}
                         value={getIndicator(data, 'reserves.benefices').value}
                         tooltip={getIndicator(data, 'reserves.benefices').options?.tooltip ? trans(t, 'chart|' + getIndicator(data, 'reserves.benefices').options?.tooltip) : null}
                         precision={getIndicator(data, 'reserves.benefices').options?.precision ?? 0}
                         symbol={getIndicator(data, 'reserves.benefices').options?.symbol ?? null}
        />
      </div>

      <div className={'flex md:w-4 py-4 px-3'}>
        <IndicatorSimple color={'primary'} bgImage title={trans(t, 'chart|reserves.partReservesSurChargesAnnuelles')}
                         value={getIndicator(data, 'reserves.partReservesSurChargesAnnuelles').value}
                         percent={getIndicator(data, 'reserves.partReservesSurChargesAnnuelles').badge}
                         tooltip={getIndicator(data, 'reserves.partReservesSurChargesAnnuelles').options?.tooltip ? trans(t, 'chart|' + getIndicator(data, 'reserves.partReservesSurChargesAnnuelles').options?.tooltip) : null}
                         precision={getIndicator(data, 'reserves.partReservesSurChargesAnnuelles').options?.precision ?? 1}
                         symbol={getIndicator(data, 'reserves.partReservesSurChargesAnnuelles').options?.symbol ?? 'mois'} />
      </div>

      <div className={'flex md:w-4 py-4 px-3'}>
        <IndicatorSimple color={'accent3'} bgImage title={trans(t, 'chart|reserves.capitalSocialSurCapitauxPropres')}
                         value={getIndicator(data, 'reserves.capitalSocialSurCapitauxPropres').value}
                         tooltip={getIndicator(data, 'reserves.capitalSocialSurCapitauxPropres').options?.tooltip ? trans(t, 'chart|' + getIndicator(data, 'reserves.capitalSocialSurCapitauxPropres').options?.tooltip) : null}
                         precision={getIndicator(data, 'reserves.capitalSocialSurCapitauxPropres').options?.precision ?? 1}
                         symbol={getIndicator(data, 'reserves.capitalSocialSurCapitauxPropres').options?.symbol ?? '%'} />
      </div>

      <div className={'flex md:w-12 py-4 px-3'}>
        <div className={'flex h-30rem md:h-auto flex-row flex-grow-1'}>
          <AMChartXY chartData={data?.chart1} showCursor min={0} />
        </div>
      </div>
    </div>
  );
};
