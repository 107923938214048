import React, { useEffect } from 'react';
import { Divider } from 'primereact/divider';

import { asDate, escapeValue, trans, ucfirst } from 'utilities';
import { APIListField, CheckboxField, MaskField, NumberField, EditorField, TextField, BoolSelectField } from 'forms';
import { useFormState } from 'states';

import { isDate } from 'lodash';

export const Immatriculation = (props: any) => {
  const { t, control, onFieldChange, errorMessage, resourceType, globalDisabled, formKey, parentKey, getValues, setValue } = props;
  const formState = useFormState();
  const { siren, siret, siret_etab, created, creation, activityStartDate, tvaNumber } = getValues();

  // Sync. tva & siren.
  useEffect(() => {
    if (tvaNumber && siren) {
      const regex =  /^[a-zA-Z]{2}\s[a-zA-Z0-9]{2}/;
      const tvaPrefix = tvaNumber?.substring(0, 5);
      if (regex.test(tvaPrefix)) {
        const updatedTvaNumber = escapeValue(`${tvaPrefix}${siren}`, 'removeSpaces');
        formState.setFieldData(formKey, 'tvaNumber', parentKey, updatedTvaNumber);
        setValue('tvaNumber', updatedTvaNumber);
      }
    }
  }, [tvaNumber, siren]); // eslint-disable-line react-hooks/exhaustive-deps

  // Sync. siret & siren.
  useEffect(() => {
    if (siret && siren) {
      const siretRegex = /^\d{5}$/;
      const siretSuffix = siret_etab ?? siret?.substring(siret.length-5);
      if (siretRegex.test(siretSuffix)) {
        const updatedSiret = escapeValue(`${siren}${siretSuffix}`, 'removeSpaces');
        formState.setFieldData(formKey, 'siret', parentKey, updatedSiret);
        setValue('siret', updatedSiret);
        setValue('siret_etab', siretSuffix);
      }
    }
    if (siret && !siren) {
      if (siret.split('_').length === 1) {
        const _siren: string = escapeValue(siret, 'removeSpaces').substring(0, 9);
        formState.setFieldData(formKey, 'siren', parentKey, _siren);
        setValue('siren', _siren);
      }
    }
  }, [siren, siret_etab]); // eslint-disable-line react-hooks/exhaustive-deps

  // Sync. created|creation & activityStartDate.
  useEffect(() => {
    if (('wf_dossier' === resourceType ? created : creation) && isDate(asDate('wf_dossier' === resourceType ? created : creation)) && !activityStartDate) {
      formState.setFieldData(formKey, 'activityStartDate', parentKey, 'wf_dossier' === resourceType ? created : creation);
      setValue('activityStartDate', 'wf_dossier' === resourceType ? created : creation);
    } else {
      if (!('wf_dossier' === resourceType ? created : creation) && activityStartDate && isDate(asDate(activityStartDate))) {
        formState.setFieldData(formKey, 'wf_dossier' === resourceType ? 'created' : 'creation', parentKey, activityStartDate);
        setValue('wf_dossier' === resourceType ? 'created' : 'creation', activityStartDate);
      }
    }
  }, [created, creation, activityStartDate]); // eslint-disable-line react-hooks/exhaustive-deps

  return <>
    <div className={'flex col-12 flex-wrap p-0'}>
      <TextField fieldKey={'raisonSociale'} control={control} onFieldChange={onFieldChange} errorMessage={errorMessage}
                 rules={{required: trans(t, 'form|requiredField')}} disabled={globalDisabled}
                 label={trans(t, 'ent.raisonSociale')} addon={'building'}/>

      <TextField fieldKey={'nomCommercial'} control={control} onFieldChange={onFieldChange} errorMessage={errorMessage}
                 label={trans(t, 'ent.nomCommercial')} addon={'shopping-bag'} disabled={globalDisabled}/>

      <NumberField fieldKey={'capital'} addon={'euro'} addonPosition={'after'} control={control}
                   onFieldChange={onFieldChange} errorMessage={errorMessage}
                   label={trans(t, 'ent.capital')} disabled={globalDisabled}/>
    </div>

    <div className={'flex col-12 flex-wrap p-0'}>
      <APIListField listKey={'societeTypes'} fieldKey={'type||societeTypes'} control={control}
                    onFieldChange={onFieldChange} errorMessage={errorMessage}
                    label={trans(t, 'ent.formeJuridique')} disabled={globalDisabled} filter={true} />

      <APIListField listKey={'typesTaux'} fieldKey={'typeTaux'} control={control} onFieldChange={onFieldChange}
                    errorMessage={errorMessage}
                    label={trans(t, 'ent.typeTaux')} disabled={globalDisabled}/>

      <MaskField fieldKey={'tvaNumber'} control={control} onFieldChange={onFieldChange} errorMessage={errorMessage}
                 mask={'aa **'}
                 label={trans(t, 'ent.tva')} disabled={globalDisabled}
                 addon={siren?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ')} textAddon={true}
                 addonPosition={'after'}/>
    </div>

    <div className={'flex col-12 flex-wrap p-0'}>
      <MaskField fieldKey={'siren'} control={control} onFieldChange={onFieldChange} errorMessage={errorMessage}
                 rules={{required: trans(t, 'form|requiredField')}} mask={'999 999 999'} disabled={globalDisabled}
                 label={trans(t, 'ent.siren').toUpperCase()}/>

      <MaskField fieldKey={'siret_etab'} control={control} onFieldChange={onFieldChange} errorMessage={errorMessage}
                 mask={'99999'} label={trans(t, 'ent.siret').toUpperCase()} disabled={globalDisabled}
                 addon={siren?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ')} textAddon={true}
                 addonPosition={'before'}/>

      <NumberField fieldKey={'duration'} control={control} onFieldChange={onFieldChange} errorMessage={errorMessage}
                   label={trans(t, 'ent.duration')} addon={'calendar'} mode={'decimal'} disabled={globalDisabled}/>
    </div>

    <div className={'flex col-12 flex-wrap p-0'}>
      <MaskField fieldKey={'wf_dossier' === resourceType ? 'created' : 'creation'} label={trans(t, 'ent.creation')}
                 control={control}
                 onFieldChange={onFieldChange} errorMessage={errorMessage} disabled={globalDisabled} isDate/>

      <MaskField fieldKey={'activityStartDate'} label={trans(t, 'ent.activityStartDate')} control={control}
                 onFieldChange={onFieldChange} errorMessage={errorMessage} disabled={globalDisabled} isDate/>

      <MaskField fieldKey={'wf_dossier' === resourceType ? 'stopped' : 'deleted'} label={trans(t, 'ent.stopped')}
                 control={control}
                 onFieldChange={onFieldChange} errorMessage={errorMessage} disabled={globalDisabled} isDate/>
    </div>

    <div className={'flex col-12 flex-wrap p-0'}>
      {'wf_dossier' !== resourceType && (
        <>
          <MaskField fieldKey={'fiscalYearEnd'} control={control} onFieldChange={onFieldChange} classes={'col-1'}
                     errorMessage={errorMessage} label={trans(t, 'ent.fiscalYearEnd')} mask={'99/99'}
                     disabled={globalDisabled}/>

          <BoolSelectField fieldKey={'fiscalYearEndSameYear'} control={control} onFieldChange={onFieldChange} errorMessage={errorMessage}
                           label={ucfirst(trans(t, 'ent.fiscalYearEndSameYear'))} disabled={globalDisabled} classes={'col-3'}
                           trueLabel={ucfirst(trans(t, 'ent.fiscalYearEndSameYearTrue'))} falseLabel={ucfirst(trans(t, 'ent.fiscalYearEndSameYearFalse'))} />
        </>
      )}

      <MaskField fieldKey={'wf_dossier' === resourceType ? 'firstExerciceFiscalStart' : 'firstFiscalYearStart'}
                 label={trans(t, 'ent.firstExerciceFiscalStart')} control={control}
                 rules={{required: trans(t, 'form|requiredField')}} disabled={globalDisabled}
                 onFieldChange={onFieldChange} errorMessage={errorMessage} isDate/>

      <MaskField fieldKey={'wf_dossier' === resourceType ? 'firstExerciceFiscalEnd' : 'firstFiscalYearEnd'}
                 label={trans(t, 'ent.firstExerciceFiscalEnd')} control={control}
                 rules={{required: trans(t, 'form|requiredField')}} disabled={globalDisabled}
                 onFieldChange={onFieldChange} errorMessage={errorMessage} isDate/>
    </div>

    <div className={'flex col-12 flex-wrap p-0'}>
      <MaskField classes={'col-4'} fieldKey={'rneImmatriculationDate'} label={trans(t, 'ent.rneImmatriculationDate')}
                 control={control}
                 onFieldChange={onFieldChange} errorMessage={errorMessage} disabled={globalDisabled} isDate/>
      <TextField classes={'col-2'} fieldKey={'nafCode'} control={control} onFieldChange={onFieldChange}
                 errorMessage={errorMessage} label={trans(t, 'ent.nafCode')} disabled={globalDisabled}/>
      <TextField classes={'col'} fieldKey={'activity'} control={control} onFieldChange={onFieldChange}
                 errorMessage={errorMessage} label={trans(t, 'ent.activity')} disabled={globalDisabled}/>
    </div>

    <div className={'flex col-12 flex-wrap p-0'}>
      <MaskField fieldKey={'rcsImmatriculationDate'} label={trans(t, 'ent.rcsImmatriculationDate')} control={control}
                 onFieldChange={onFieldChange} errorMessage={errorMessage} disabled={globalDisabled} isDate/>
      <TextField fieldKey={'rcsNumber'} control={control} onFieldChange={onFieldChange} errorMessage={errorMessage}
                 label={trans(t, 'ent.rcsNumber')} disabled={globalDisabled}/>
      <TextField fieldKey={'rcsCity'} control={control} onFieldChange={onFieldChange} errorMessage={errorMessage}
                 label={trans(t, 'ent.rcsCity')} disabled={globalDisabled}/>
    </div>

    <div className={'flex col-12 flex-wrap p-0'}>
      <CheckboxField fieldKey={'isHolding'} control={control} onFieldChange={onFieldChange} errorMessage={errorMessage}
                     label={ucfirst(trans(t, 'holding'))} help={ucfirst(trans(t, 'holdingHelp'))}
                     disabled={globalDisabled}/>
      <CheckboxField fieldKey={'isLocationNue'} control={control} onFieldChange={onFieldChange}
                     errorMessage={errorMessage} label={ucfirst(trans(t, 'locationNue'))}
                     help={ucfirst(trans(t, 'locationNueHelp'))} disabled={globalDisabled}/>
      <CheckboxField fieldKey={'isLocationGerance'} control={control} onFieldChange={onFieldChange}
                     errorMessage={errorMessage} label={ucfirst(trans(t, 'locationGerance'))}
                     help={ucfirst(trans(t, 'locationGeranceHelp'))} disabled={globalDisabled}/>
    </div>

    {'wf_dossier' !== resourceType && (
      <>
        <Divider/>

        <div className={'flex col-12 flex-wrap p-0'}>
          <EditorField classes={'col'} fieldKey={'description'} control={control} onFieldChange={onFieldChange}
                       errorMessage={errorMessage} label={ucfirst(trans(t, 'ent.description'))}
                       help={ucfirst(trans(t, 'ent.descriptionHelp'))} disabled={globalDisabled}/>
        </div>
      </>
    )}
  </>;
};
