import React from 'react';
import { trans, } from 'utilities';
import { APIListField } from 'forms/index';

export const RemunerationDirigeantStatutForm = (props: any) => {
  const { t, control, onFieldChange, errorMessage } = props;

  return (
      <APIListField listKey={'societeTypes'} fieldKey={'type'} control={control} onFieldChange={onFieldChange} errorMessage={errorMessage}
                    label={trans(t, 'ent.formeJuridique')} filter={true} classes={'w-12 py-2'}/>
  )
};
