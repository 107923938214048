import { LicencedOrgChart as OrgChart } from 'components';

export const buildA8MainTemplate = (t: any): OrgChart.template => {
  const color1: string = '#928DFC';
  const color2: string = '#000088';
  const template: OrgChart.template = Object.assign({}, OrgChart.templates.ula);

  template.defs =
    `<style>
        #a8_main_gradient {
            --color-stop-1: #ffffff;
            --color-stop-2: ${color1};
            --opacity-stop: 1;
        }
        .a8_main_name {
            font-size: 18px;
            fill: ${color2};
        }
        .a8_main_title {
            font-size: 14px;
            fill: #555555;
        }
    </style>
    '<linearGradient id="a8_main_gradient" x1="0%" y1="0%" x2="0%" y2="100%">
        <stop offset="0%" stop-color="var(--color-stop-1)" stop-opacity="var(--opacity-stop)" />
        <stop offset="100%" stop-color="var(--color-stop-2)" stop-opacity="var(--opacity-stop)" />
    </linearGradient>`;
  template.size = [300, 120];
  template.field_0 = '<text class="a8_main_name" x="100" y="55" data-width="180" data-text-overflow="ellipsis">{val}</text>';
  template.field_1 = '<text class="a8_main_title" x="100" y="76" data-width="190" data-text-overflow="multiline-2-ellipsis">{val}</text>';
  template.node = '<rect x="0" y="0" height="{h}" width="{w}" fill="url(#a8_main_gradient)" stroke-width="1" stroke="#aeaeae"></rect><line x1="0" y1="0" x2="{w}" y2="0" stroke-width="2" stroke="' + color2 + '"></line>';
  template.nodeMenuButton =
    '<g style="cursor:pointer;" transform="matrix(1,0,0,1,275,105)" data-ctrl-n-menu-id="{id}">'
    + '<rect x="-4" y="-10" fill="#000000" fill-opacity="0" width="22" height="22"></rect>'
    + '<circle cx="0" cy="0" r="2" fill="#ffffff"></circle><circle cx="7" cy="0" r="2" fill="#ffffff"></circle><circle cx="14" cy="0" r="2" fill="#ffffff"></circle>'
    + '</g>';

  return template;
};
