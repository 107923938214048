import React, { useEffect, useState } from 'react';

import { Skeleton } from 'primereact/skeleton';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Checkbox } from 'primereact/checkbox';
import { Button } from 'primereact/button';
import { Message } from 'primereact/message';

import { dialog, iri, trans, ucfirst } from 'utilities';
import { AxiosResponse } from 'axios';
import { IRequestParams } from 'services';
import { PageLoader } from 'components';

import { forEach } from 'lodash';

export const DossierNotifications = (props: any) => {
  const { dossier, apim, userUuid, side } = props;
  const { t } = apim.di();

  const otherLabel = ucfirst(trans(t, 'other'));
  const [lastResetDefaults, setLastResetDefaults] = useState<number>(Date.now());
  const [emailAll, setEmailAll] = useState<boolean>(false);
  const [notificationAll, setNotificationAll] = useState<boolean>(false);
  const [datatableDataChanged, setDatatableDataChanged] = useState<boolean>(false);
  const [datatableLoading, setDatatableLoading] = useState<boolean>(true);
  const [datatableData, setDatatableData] = useState<any[]>([]);
  const [alertsLoading, setAlertsLoading] = useState<boolean>(true);
  const [alerts, setAlerts] = useState<any[]>([]);
  const [alertProfileLoading, setAlertProfileLoading] = useState<boolean>(true);
  const [alertProfile, setAlertProfile] = useState<any>(null);
  const [dossierUserSettingsLoading, setDossierUserSettingsLoading] = useState<boolean>(true);
  const [dossierUserSettings, setDossierUserSettings] = useState<any[]>([]);

  // Load all alerts.
  useEffect(() => {
    let ignore = false;

    apim.fetchEntities({
      resourceType: 'alerts',
      setLoading: setAlertsLoading,
      params: [
        {label: 'expand[]', value: 'alert:read_category'},
        {label: 'expand[]', value: 'alert_category:read'},
        {label: 'itemsPerPage', value: 500}
      ],
      success: (res: AxiosResponse) => {
        if (!ignore) {
          setAlerts([]);

          if (res?.data && (res?.data['hydra:member'] || []).length > 0) {
            setAlerts(res.data['hydra:member']);
          }
        }
      }
    } as IRequestParams).then();

    return () => {
      ignore = true;
    };
  }, [apim]);

  // Load user alert profile.
  useEffect(() => {
    let ignore = false;

    if (userUuid !== null && userUuid !== undefined) {
      // Fetch the user roles.
      setAlertProfileLoading(true);
      apim.fetchEntities({
        resourceType: 'userRoles',
        params: [{label: 'user', value: userUuid}],
        success: (res: AxiosResponse) => {
          if (!ignore) {
            let finalUserRole: any = null;
            if (res?.data && (res?.data['hydra:member'] || []).length > 0) {
              forEach(res?.data['hydra:member'] || [], (userRole: any) => {
                const role: any = {name: userRole.role.name};
                if (userRole.role.name === 'ROLE_SUPER_ADMIN') {
                  role.weight = 1;
                } else if (userRole.role.name === 'ROLE_ADMIN') {
                  role.weight = 2;
                } else if (userRole.role.name === 'ROLE_EXPERT' || userRole.role.name === 'ROLE_EXPERT_ADMIN') {
                  role.weight = 3;
                } else if (userRole.role.name === 'ROLE_CLIENT') {
                  role.weight = 4;
                } else if (userRole.role.name === 'ROLE_CLIENT') {
                  role.weight = 5;
                }

                // Use the one with the lowest weight.
                if (finalUserRole === null || role.weight <= finalUserRole.weight) {
                  finalUserRole = role;
                }
              });
            }

            let finalUserRoleName = 'ROLE_CLIENT';
            if (finalUserRole !== null) {
              finalUserRoleName = finalUserRole.name;
            }

            // Then fetch the alert profile.
            apim.fetchEntities({
              resourceType: 'alertProfiles',
              params: [
                {label: 'role', value: finalUserRoleName},
                {label: 'expand[]', value: 'alert_profile:read_profile_settings'},
                {label: 'expand[]', value: 'alert_profile_setting:read'},
                {label: 'itemsPerPage', value: 500}
              ],
              success: (res2: AxiosResponse) => {
                if (!ignore) {
                  setAlertProfile(null);

                  if (res2?.data && (res2?.data['hydra:member'] || []).length > 0) {
                    setAlertProfile(res2.data['hydra:member'][0]);
                  }

                  setAlertProfileLoading(false);
                }
              }
            } as IRequestParams).then();
          }
        }
      } as IRequestParams).then();
    }

    return () => {
      ignore = true;
    };
  }, [userUuid, apim]);

  // Load user alert settings.
  useEffect(() => {
    let ignore = false;

    if (userUuid !== null && userUuid !== undefined) {
      // Fetch alert dossier user settings.
      setDossierUserSettingsLoading(true);
      apim.fetchEntities({
        resourceType: 'alertDossierUserSettings',
        setLoading: setDossierUserSettingsLoading,
        params: [
          {label: 'dossier', value: dossier.id},
          {label: 'user', value: userUuid},
          {label: 'expand[]', value: 'alert_dossier_user_setting:read_alert'},
          {label: 'expand[]', value: 'alert:read'},
          {label: 'itemsPerPage', value: 500}
        ],
        cache: false,
        success: (res: AxiosResponse) => {
          if (!ignore) {
            setDossierUserSettings([]);

            if (res?.data && (res?.data['hydra:member'] || []).length > 0) {
              setDossierUserSettings(res.data['hydra:member']);
            }
          }
        }
      } as IRequestParams).then();
    }

    return () => {
      ignore = true;
    };
  }, [dossier, userUuid, lastResetDefaults, apim]);

  // Once alertProfile, alerts & dossierUserSettings are loaded,
  // Then we prepare the datatable values.
  useEffect(() => {
    setDatatableLoading(true);

    if (!alertProfileLoading && !alertsLoading && !dossierUserSettingsLoading) {
      let _datatableData: any[] = [];

      forEach(alerts, (alert: any) => {
        let _datatableDataItem: any = {
          current: {
            sendEmail: false,
            sendNotification: false,
          },
          default: {
            sendEmail: false,
            sendNotification: false,
          }
        };

        if (!alert.active) {
          return false;
        }

        _datatableDataItem.alert = {
          id: alert.id,
          code: alert.code,
          label: alert.title,
          description: alert.description,
        };

        if (alert.alertCategory !== undefined) {
          if (!alert.alertCategory.active) {
            return false;
          }

          _datatableDataItem.alertCategory = {
            id: alert.alertCategory.id,
            label: alert.alertCategory.label
          };

        } else {
          _datatableDataItem.alertCategory = {
            id: null,
            label: otherLabel,
          };
        }

        _datatableDataItem.group = _datatableDataItem.alertCategory.label;

        // Find the alert profile related to this alert.
        const alertProfileSettings = alertProfile?.profileSettings || [];

        forEach(alertProfileSettings, (alertProfileSetting: any) => {
          if (alertProfileSetting.alert.id === alert.id) {
            _datatableDataItem.alertProfileSettings = {
              id: alertProfileSetting.id,
              sendEmail: alertProfileSetting.sendEmail,
              sendNotification: alertProfileSetting.sendNotification,
            };

            // Set as current data.
            _datatableDataItem.current.sendEmail = alertProfileSetting.sendEmail;
            _datatableDataItem.current.sendNotification = alertProfileSetting.sendNotification;

            return false;
          }
        });

        // Find the dossier user settings related to this alert.
        forEach(dossierUserSettings, (dossierUserSetting: any) => {
          if (dossierUserSetting.alert.id === alert.id) {
            _datatableDataItem.userSettings = {
              id: dossierUserSetting.id,
              sendEmail: dossierUserSetting.sendEmail,
              sendNotification: dossierUserSetting.sendNotification,
            };

            // Set as current data.
            _datatableDataItem.current.sendEmail = dossierUserSetting.sendEmail;
            _datatableDataItem.current.sendNotification = dossierUserSetting.sendNotification;

            return false;
          }
        });

        _datatableData.push(_datatableDataItem);
      });

      setDatatableData(_datatableData);
      setDatatableLoading(false);
      setDatatableDataChanged(false);
    }
  }, [alertProfileLoading, alertProfile, alertsLoading, alerts, dossierUserSettingsLoading, dossierUserSettings, lastResetDefaults, otherLabel, apim]);

  const sendNotificationBodyTemplate = (rowData: any) => {
    return <Checkbox key={rowData.id + '_sendNotification'} onChange={(e) => setSendNotificationSetting(rowData,!!e.checked)} checked={rowData.current.sendNotification} />
  };

  const sendEmailBodyTemplate = (rowData: any) => {
    return <Checkbox key={rowData.id + '_sendEmail'} onChange={(e) => setSendEmailSetting(rowData, !!e.checked)} checked={rowData.current.sendEmail} />
  };

  const setSendNotificationSetting = (rowData: any, status: boolean) => {
    let _datatableData: any[] = [];
    forEach(datatableData, (datatableRow: any) => {
      if (datatableRow.alert.id === rowData.alert.id) {
        datatableRow.current.sendNotification = status;
        _datatableData.push(datatableRow);
      } else {
        _datatableData.push(datatableRow);
      }
    });
    setDatatableData(_datatableData);
    setDatatableDataChanged(true);
  }

  const setSendEmailSetting = (rowData: any, status: boolean) => {
    let _datatableData: any[] = [];
    forEach(datatableData, (datatableRow: any) => {
      if (datatableRow.alert.id === rowData.alert.id) {
        datatableRow.current.sendEmail = status;
        _datatableData.push(datatableRow);
      } else {
        _datatableData.push(datatableRow);
      }
    });
    setDatatableData(_datatableData);
    setDatatableDataChanged(true);
  }

  const resetChanges = () => {
    let _datatableData: any[] = [];
    forEach(datatableData, (datatableRow: any) => {
      // If no profile and no user settings.
      // Reset the value to the default ones.
      if (datatableRow.alertProfileSettings === undefined
          && datatableRow.userSettings === undefined) {
        datatableRow.current.sendEmail = datatableRow.default.sendEmail;
        datatableRow.current.sendNotification = datatableRow.default.sendNotification;
      }

      // Reset to profile values.
      if (datatableRow.alertProfileSettings !== undefined) {
        datatableRow.current.sendEmail = datatableRow.alertProfileSettings.sendEmail;
        datatableRow.current.sendNotification = datatableRow.alertProfileSettings.sendNotification;
      }

      // Reset to user settings values.
      if (datatableRow.userSettings !== undefined) {
        datatableRow.current.sendEmail = datatableRow.userSettings.sendEmail;
        datatableRow.current.sendNotification = datatableRow.userSettings.sendNotification;
      }

      _datatableData.push(datatableRow);
    });

    setDatatableData(_datatableData);
    setDatatableDataChanged(false);
    setNotificationAll(false);
    setEmailAll(false);
  }

  const resetDefaults = () => {
    dialog(t, {
      message: ucfirst(trans(t, 'system|confirmations.dossier.notifications.resetDefaults.message')),
      icon: 'pi pi-send',
      accept: () => {
        setDatatableLoading(true);
        setDatatableDataChanged(false);
        setDossierUserSettingsLoading(true);
        setNotificationAll(false);
        setEmailAll(false);

        apim.call({
          resourceType: 'alertDossierUserSettings',
          action: 'resetDefaults',
          method: 'post',
          data: {
            user: userUuid,
            dossier: dossier.id
          },
          notifSuccess: {
            summary: trans(t, 'notification|dossier.notifications.resetDefaults.summary'),
            details: trans(t, 'notification|dossier.notifications.resetDefaults.details'),
          }, success: () => {
            setLastResetDefaults(Date.now());
          }
        } as IRequestParams);
      }
    });
  }

  const saveSettings = () => {
    setDatatableLoading(true);
    setDatatableDataChanged(false);
    setDossierUserSettingsLoading(true);

    let alertDossierUserSettings: any[] = [];
    forEach(datatableData, (datatableRow: any) => {
      alertDossierUserSettings.push({
        alert: iri('alerts', datatableRow.alert.id),
        sendNotification: datatableRow.current.sendNotification,
        sendEmail: datatableRow.current.sendEmail
      });
    });

    let data: any = {
      user: userUuid,
      dossier: dossier.id,
      alertDossierUserSettings: alertDossierUserSettings
    };

    if (alertProfile !== null && alertProfile !== undefined) {
      data.profile = alertProfile.id;
    }

    apim.call({
      resourceType: 'alertDossierUserSettings',
      action: 'multiple',
      method: 'post',
      data: data,
      notifSuccess: {
        summary: trans(t, 'notification|dossier.notifications.saveSettings.summary'),
        details: trans(t, 'notification|dossier.notifications.saveSettings.details'),
      }, success: () => {
        setLastResetDefaults(Date.now());
      }
    } as IRequestParams);
  }

  const headerTemplate = (rowData: any) => {
    return (
      <div className={'flex align-items-center gap-2'}>
        <span className={'font-bold'}>{rowData.group}</span>
      </div>
    );
  };

  const header = (
    <div className={'flex flex-wrap align-items-center justify-content-between gap-2'}>
      <span className={'text-xl text-900 font-bold'}>{ucfirst(trans(t, 'notification', 2))}</span>
      <span style={{width: '300px'}} className={'flex flex-wrap align-items-center'}>
        <span style={{marginLeft: '75px'}}>
          <Checkbox key={'sendNotificationAll'} onChange={(e) => toggleNotificationAll(!!e.checked)} checked={notificationAll} />
        </span>
        <span style={{marginLeft: '135px'}}>
          <Checkbox key={'sendEmailAll'} onChange={(e) => toggleEmailAll(!!e.checked)} checked={emailAll} />
        </span>
      </span>
    </div>
  );

  const toggleNotificationAll = (status: boolean) => {
    let _datatableData: any[] = [];
    forEach(datatableData, (datatableRow: any) => {
      datatableRow.current.sendNotification = status;
      _datatableData.push(datatableRow);
    });

    setDatatableData(_datatableData);
    setDatatableDataChanged(true);
    setNotificationAll(status);
  }

  const toggleEmailAll = (status: boolean) => {
    let _datatableData: any[] = [];
    forEach(datatableData, (datatableRow: any) => {
      datatableRow.current.sendEmail = status;
      _datatableData.push(datatableRow);
    });

    setDatatableData(_datatableData);
    setDatatableDataChanged(true);
    setEmailAll(status);
  }

  return (
    <div className={'px-3'}>
      <div className={'flex justify-content-between pb-5'}>
        <div>
          <h2>{ucfirst(trans(t, 'form|dossier.notificationSettings'))}</h2>
          <div>
            {(userUuid !== null && userUuid !== undefined) ? (
              alertProfileLoading ? (
                <Skeleton height={'1.5rem'} width={'12rem'} className={'mb-3'} />
              ) : (
                <h5>{ucfirst(trans(t, 'alertProfile'))} : {(alertProfile !== null && alertProfile !== undefined) ? alertProfile.label : 'N/A'}</h5>
              )
            ) : (
              <h5>{ucfirst(trans(t, 'alertProfile'))} : N/A</h5>
            )}
          </div>
          {side !== undefined ? (
            side === 'owner' ? (
              <p>{ucfirst(trans(t, 'form|dossier.notificationSettingsSummaryOwner'))}</p>
            ) : (
              <p>{ucfirst(trans(t, 'form|dossier.notificationSettingsSummaryClient'))}</p>
            )
          ) : (
            <p>{ucfirst(trans(t, 'form|dossier.notificationSettingsSummaryUser'))}</p>
          )}
        </div>

        <div className={'mt-5'}>
           <span className={'mr-3'}>
             <Button icon={'pi pi-cog'} label={trans(t, 'form|dossier.notifications.settings.applyDefault')} onClick={(e) => resetDefaults()} className={'a8-dossier-notification-settings-default'} />
           </span>
           <span className={'mr-3'}>
             <Button icon={'pi pi-undo'} label={trans(t, 'form|dossier.notifications.settings.resetChanges')} onClick={() => resetChanges()} className={'a8-dossier-notification-settings-reset'} outlined disabled={!datatableDataChanged} />
           </span>
           <span>
             <Button icon={'pi pi-check'} severity={'success'} label={trans(t, 'form|dossier.notifications.settings.save')} onClick={(e) => saveSettings()} className={'a8-dossier-notification-settings-save'} disabled={!datatableDataChanged} />
           </span>
        </div>
      </div>

      {(userUuid !== null && userUuid !== undefined) ? (
        datatableLoading ? (
          <PageLoader />
        ) : (
          <DataTable value={datatableData} header={header} rowGroupMode='subheader' groupRowsBy={'group'} rowGroupHeaderTemplate={headerTemplate} sortMode={'single'} sortField={'group'} sortOrder={-1} stripedRows>
            <Column field={'alert.description'} header={trans(t, 'notificationType')} style={{ minWidth: '200px' }} />
            <Column field='sendNotification' align={'center'} header={trans(t, 'notificationByNotification')} body={sendNotificationBodyTemplate} style={{ maxWidth: '150px', width: '150px' }} />
            <Column field='sendEmail' align={'center'} header={trans(t, 'notificationByEmail')} body={sendEmailBodyTemplate} style={{ maxWidth: '150px', width: '150px' }} />
          </DataTable>
        )
      ) : (
        <div className={'flex justify-content-center'}>
          <Message severity={'info'} className={'p-5 rounded-md'} text={ucfirst(trans(t, 'dossierHasNoClient'))}/>
        </div>
      )}
    </div>
  );
};
