import React from 'react';

import { Fieldset } from 'primereact/fieldset';

import { trans, ucfirst } from 'utilities';
import { APIListField, CheckboxField, StaticListField, TextAreaField, TextField } from 'forms';
import { useThemeState } from 'states';

export const SettingsAlert = (props: any) => {
  const { t, control, onFieldChange, errorMessage, listsOptions } = props;
  const themeState = useThemeState();
// Temporary text area fields, while WYSIWYG field issue is resolved

  return (
    <>
      {themeState.isBiggerDesktop() ?
        <div className={'flex flex-wrap flex-row w-8'}>
          <TextField fieldKey={'code'} control={control} onFieldChange={onFieldChange} errorMessage={errorMessage} disabled label={trans(t, 'code')} classes={'w-1 px-2'}/>
          <TextField fieldKey={'description'} control={control} onFieldChange={onFieldChange} errorMessage={errorMessage} label={trans(t, 'description')} classes={'flex-grow-1 w-4 px-2'}/>
          <APIListField listKey={'notificationTypes'} fieldKey={'type'} control={control} onFieldChange={onFieldChange} errorMessage={errorMessage} classes={'w-2 px-2'} label={trans(t, 'type')} showClear/>
          <StaticListField fieldKey={'alertCategory'} listKey={'alertCategory'} listsOptions={listsOptions} control={control} label={trans(t, 'alertCategory')} optionLabel={'label'} optionValue={'id'} errorMessage={errorMessage} onFieldChange={onFieldChange} classes={'w-4 px-2'}/>
        </div>
        :
        <div className={'flex flex-wrap flex-row w-12'}>
          <TextField fieldKey={'code'} control={control} onFieldChange={onFieldChange} errorMessage={errorMessage} disabled label={trans(t, 'code')} classes={'w-1 px-2'}/>
          <TextField fieldKey={'description'} control={control} onFieldChange={onFieldChange} errorMessage={errorMessage} label={trans(t, 'description')} classes={'flex-grow-1 px-2'}/>
          <APIListField listKey={'notificationTypes'} fieldKey={'type'} control={control} onFieldChange={onFieldChange} errorMessage={errorMessage} classes={'w-2 px-2'} label={trans(t, 'type')} showClear/>
          <StaticListField fieldKey={'alertCategory'} listKey={'alertCategory'} listsOptions={listsOptions} control={control} label={trans(t, 'alertCategory')} optionLabel={'label'} optionValue={'@id'} errorMessage={errorMessage} onFieldChange={onFieldChange} classes={'w-5 px-2'}/>
        </div>
      }

      <div className={'flex flex-wrap flex-row w-12'}>
        <div className={'w-6 p-2'}>
          <Fieldset legend={ucfirst(trans(t, 'alert'))} className={'w-full'}>
            <TextField fieldKey={'title'} control={control} onFieldChange={onFieldChange} errorMessage={errorMessage} label={trans(t, 'title')} classes={'w-full mb-3'}/>
            <TextAreaField fieldKey={'content'} control={control} onFieldChange={onFieldChange} errorMessage={errorMessage} height={'100px'}
                           classes={'w-full'} label={trans(t, 'content')}/>
            {/*<EditorField fieldKey={'content'} control={control} onFieldChange={onFieldChange} errorMessage={errorMessage} label={trans(t, 'content')} classes={'w-full'} height={'100px'} hideLabel/>*/}
          </Fieldset>
        </div>
        <div className={'w-6 p-2'}>
          <Fieldset legend={ucfirst(trans(t, 'email'))} className={'w-full'}>
            <TextField fieldKey={'mailTitle'} control={control} onFieldChange={onFieldChange} errorMessage={errorMessage} label={trans(t, 'mailTitle')} classes={'w-full mb-3'}/>
            <TextAreaField fieldKey={'mailContent'} control={control} onFieldChange={onFieldChange} errorMessage={errorMessage} height={'100px'}
                           classes={'w-full'} label={trans(t, 'mailContent')}/>
            {/*<EditorField fieldKey={'mailContent'} control={control} onFieldChange={onFieldChange} errorMessage={errorMessage} label={trans(t, 'mailContent')} classes={'w-full'} height={'100px'} hideLabel/>*/}
          </Fieldset>
        </div>
      </div>

      <div className={'flex flex-wrap flex-row w-12'}>
        <div className={'w-6 p-2'}>
          <Fieldset legend={ucfirst(trans(t, 'specificAlert'))} className={'w-full'}>
            <TextField fieldKey={'clientTitle'} control={control} onFieldChange={onFieldChange} errorMessage={errorMessage} label={trans(t, 'clientTitle')} classes={'w-full mb-3'}/>
            <TextAreaField fieldKey={'clientContent'} control={control} onFieldChange={onFieldChange} errorMessage={errorMessage} height={'100px'}
                           classes={'w-full'} label={trans(t, 'clientContent')}/>
            {/*<EditorField fieldKey={'clientContent'} control={control} onFieldChange={onFieldChange} errorMessage={errorMessage} label={trans(t, 'clientContent')} classes={'w-full'} height={'100px'} hideLabel/>*/}
          </Fieldset>
        </div>
        <div className={'w-6 p-2'}>
          <Fieldset legend={ucfirst(trans(t, 'specificEmail'))} className={'w-full'}>
            <TextField fieldKey={'clientMailTitle'} control={control} onFieldChange={onFieldChange} errorMessage={errorMessage} label={trans(t, 'clientMailTitle')} classes={'w-full mb-3'}/>
            <TextAreaField fieldKey={'clientMailContent'} control={control} onFieldChange={onFieldChange} errorMessage={errorMessage} height={'100px'}
                           classes={'w-full'} label={trans(t, 'clientMailContent')}/>
            {/*<EditorField fieldKey={'clientMailContent'} control={control} onFieldChange={onFieldChange} errorMessage={errorMessage} label={trans(t, 'clientMailContent')} classes={'w-full'} height={'100px'} hideLabel />*/}
          </Fieldset>
        </div>
      </div>
      <div className={'flex flex-wrap flex-row w-12 p-2'}>
        <CheckboxField fieldKey={'subscriptionConfigurable'} control={control} onFieldChange={onFieldChange} errorMessage={errorMessage} label={trans(t, 'subscriptionConfigurable')} classes={'px-2'}/>
        <CheckboxField fieldKey={'sendToDossierUser'} control={control} onFieldChange={onFieldChange} errorMessage={errorMessage} label={trans(t, 'sendToDossierUser')} classes={'px-2'}/>
        <CheckboxField fieldKey={'sendToDossierExperts'} control={control} onFieldChange={onFieldChange} errorMessage={errorMessage} label={trans(t, 'sendToDossierExperts')} classes={'px-2'}/>
        <CheckboxField fieldKey={'notificationActive'} control={control} onFieldChange={onFieldChange} errorMessage={errorMessage} label={trans(t, 'notificationActive')} classes={'px-2'}/>
        <CheckboxField fieldKey={'active'} control={control} onFieldChange={onFieldChange} errorMessage={errorMessage} label={ucfirst(trans(t, 'active'))} classes={'px-2'}/>
      </div>
    </>
  );
};
