import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { AxiosResponse } from 'axios';
import { Fieldset } from 'primereact/fieldset';
import { Skeleton } from 'primereact/skeleton';
import { Button } from 'primereact/button';

import { dateString, formatListItem, iri, isValidUUID, trans, ucfirst, uuidFromIri } from 'utilities';
import { APIListField, CheckboxField, EditorField, MaskField, UncontrolledNumberField, NumberField, UncontrolledTextField, UncontrolledMaskField } from 'forms';
import { IRequestParams } from 'services';

import appUri from 'config/appUri.json';

export const PatrimoineForm = (props: any) => {
  const { apim, t, control, onFieldChange, errorMessage, getValues, context } = props;
  const { i18n } = useTranslation();
  const { navigate} = apim.di();

  const {
    id = null,
    existenceTestament = null,
  } = getValues();

  // Load patrimoine foyer for given date.
  const [patrimoineFoyerLoading, setPatrimoineFoyerLoading] = useState<boolean>(false);
  const [patrimoineFoyer, setPatrimoineFoyer] = useState<any>(null);
  const [successionDonationOptions, setSuccessionDonationOptions] = useState<any[]>([]);
  const [successionDonationOptionsLoading, setSuccessionDonationOptionsLoading] = useState<boolean>(false);
  const [successionCodeCivilOptions, setSuccessionCodeCivilOptions] = useState<any[]>([]);
  const [successionCodeCivilOptionsLoading, setSuccessionCodeCivilOptionsLoading] = useState<boolean>(false);

  useEffect(() => {
    setPatrimoineFoyerLoading(true);
    apim.fetchEntities({
      resourceType: 'patrimoineFoyers',
      params: [
        {label: 'patrimoine', value: context.id},
        {label: 'expand[]', value: 'patrimoine_foyer:read_donation_summary'},
        {label: 'expand[]', value: 'patrimoine_foyer:read_foyer_spouses'},
        {label: 'expand[]', value: 'patrimoine_foyer:read_foyer_children'},
        {label: 'expand[]', value: 'personne_physique:read_minimal'},
      ],
      cache: false,
      setLoading: setPatrimoineFoyerLoading,
      success: (res: AxiosResponse) => {
        if (res?.data && (res?.data['hydra:member'] ?? []).length > 0) {
          setPatrimoineFoyer(res.data['hydra:member'][0]);
        } else {
          setPatrimoineFoyer(null);
        }
      }
    } as IRequestParams).then();
  }, [context]); // eslint-disable-line react-hooks/exhaustive-deps

  // Load lists.
  useEffect(() => {
    apim.getList('successionDonationOptions', {setter: setSuccessionDonationOptions, setLoading: setSuccessionDonationOptionsLoading} as IRequestParams).then();
    apim.getList('successionCodeCivilOptions', {setter: setSuccessionCodeCivilOptions, setLoading: setSuccessionCodeCivilOptionsLoading} as IRequestParams).then();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  // Load patrimoine succession option values.
  const [patrimoineSuccessionOptionValues, setPatrimoineSuccessionOptionValues] = useState<any>({});
  useEffect(() => {
    loadPatrimoineSuccessionOptionValues();
  }, [patrimoineFoyer]); // eslint-disable-line react-hooks/exhaustive-deps

  // Load personne physique annual data.
  const [personnePhysiqueAnnualDataLoading, setPersonnePhysiqueAnnualDataLoading] = useState<boolean>(false);
  const [personnePhysiqueAnnualDatas, setPersonnePhysiqueAnnualDatas] = useState<any>([]);
  useEffect(() => {
    loadPersonnePhysiqueAnnualDatas();
  }, [patrimoineFoyer]); // eslint-disable-line react-hooks/exhaustive-deps

  /**
   * Load initial patrimoine succession option values.
   */
  const loadPatrimoineSuccessionOptionValues = () => {
    const _patrimoineSuccessionOptionValues: any = {};

    apim.fetchEntities({
      resourceType: 'patrimoineSuccessionOptions',
      params: [{label: 'patrimoine', value: id}],
      success: (res: AxiosResponse) => {
        if (!res?.data || (res?.data['hydra:member'] || []).length === 0) {
          return;
        }

        const patrimoineSuccessionOptions = res.data['hydra:member'];
        patrimoineSuccessionOptions.map((patrimoineSuccessionOption: any) => {
          const successionOption = patrimoineSuccessionOption.successionOption;
          if (isValidUUID(patrimoineFoyer?.foyerSpouses[0]?.id)) {
            const personnePhysiqueId = patrimoineFoyer?.foyerSpouses[0]?.id;
            if (_patrimoineSuccessionOptionValues[personnePhysiqueId] === null
              || _patrimoineSuccessionOptionValues[personnePhysiqueId] === undefined) {
              _patrimoineSuccessionOptionValues[personnePhysiqueId] = {};
            }

            if (_patrimoineSuccessionOptionValues[personnePhysiqueId][successionOption] === null
              || _patrimoineSuccessionOptionValues[personnePhysiqueId][successionOption] === undefined) {
              _patrimoineSuccessionOptionValues[personnePhysiqueId][successionOption] = {
                fields: {},
                total: null
              };
            }

            _patrimoineSuccessionOptionValues[personnePhysiqueId][successionOption]['fields']['succession1erDecesConjoint'] = patrimoineSuccessionOption.succession1erDecesConjoint ?? null;
            _patrimoineSuccessionOptionValues[personnePhysiqueId][successionOption]['fields']['succession1erDecesHeritiers'] = patrimoineSuccessionOption.succession1erDecesHeritiers ?? null;
            _patrimoineSuccessionOptionValues[personnePhysiqueId][successionOption]['fields']['succession2eDecesHeritiers'] = patrimoineSuccessionOption.succession2eDecesHeritiers ?? null;
            _patrimoineSuccessionOptionValues[personnePhysiqueId][successionOption]['total'] = Object.values(_patrimoineSuccessionOptionValues[personnePhysiqueId][successionOption]['fields']).reduce((a: any, b: any) => a + b, 0);
          }

          if (isValidUUID(patrimoineFoyer?.foyerSpouses[1]?.id)) {
            const personnePhysiqueId = patrimoineFoyer?.foyerSpouses[1]?.id;
            if (_patrimoineSuccessionOptionValues[personnePhysiqueId] === null
              || _patrimoineSuccessionOptionValues[personnePhysiqueId] === undefined) {
              _patrimoineSuccessionOptionValues[personnePhysiqueId] = {};
            }

            if (_patrimoineSuccessionOptionValues[personnePhysiqueId][successionOption] === null
              || _patrimoineSuccessionOptionValues[personnePhysiqueId][successionOption] === undefined) {
              _patrimoineSuccessionOptionValues[personnePhysiqueId][successionOption] = {
                fields: {},
                total: null
              };
            }

            _patrimoineSuccessionOptionValues[personnePhysiqueId][successionOption]['fields']['succession1erDecesConjoint'] = patrimoineSuccessionOption.succession1erDecesConjointP2 ?? null;
            _patrimoineSuccessionOptionValues[personnePhysiqueId][successionOption]['fields']['succession1erDecesHeritiers'] = patrimoineSuccessionOption.succession1erDecesHeritiersP2 ?? null;
            _patrimoineSuccessionOptionValues[personnePhysiqueId][successionOption]['fields']['succession2eDecesHeritiers'] = patrimoineSuccessionOption.succession2eDecesHeritiersP2 ?? null;
            _patrimoineSuccessionOptionValues[personnePhysiqueId][successionOption]['total'] = Object.values(_patrimoineSuccessionOptionValues[personnePhysiqueId][successionOption]['fields']).reduce((a: any, b: any) => a + b, 0);
          }

          return null;
        });

        setPatrimoineSuccessionOptionValues(_patrimoineSuccessionOptionValues);
      }} as IRequestParams).then();
  }

  /**
   * Save the patrimoine succession option values for given option.
   *
   * @param option
   */
  const savePatrimoineSuccessionOptionValues = (option: string) => {
    let data: any = {};

    for (const personnePhysiqueId of Object.keys(patrimoineSuccessionOptionValues)) {
      if (patrimoineSuccessionOptionValues[personnePhysiqueId] === null
      || patrimoineSuccessionOptionValues[personnePhysiqueId] === undefined) {
        continue;
      }

      let isSpouse = null;
      if (personnePhysiqueId === patrimoineFoyer?.foyerSpouses[0]?.id) {
        isSpouse = false;
      } else if (personnePhysiqueId === patrimoineFoyer?.foyerSpouses[1]?.id) {
        isSpouse = true;
      }

      for (const opt of Object.keys(patrimoineSuccessionOptionValues[personnePhysiqueId])) {
        if (opt !== option) {
          continue;
        }

        if (patrimoineSuccessionOptionValues[personnePhysiqueId][opt]['fields'] === null
          || patrimoineSuccessionOptionValues[personnePhysiqueId][opt]['fields'] === undefined) {
          continue;
        }

        const fields: any = patrimoineSuccessionOptionValues[personnePhysiqueId][opt]['fields'];
        for (const [field, value] of Object.entries(fields)) {
          data[field + (isSpouse ? 'P2' : '')] = value;
        }
      }
    }

    apim.fetchEntities({
      resourceType: 'patrimoineSuccessionOptions',
      params: [
        {label: 'patrimoine', value: id},
        {label: 'successionOption', value: option}
      ],
      cache: false,
      success: (res: AxiosResponse) => {
        if (!res?.data || (res?.data['hydra:member'] || []).length === 0) {
          // Create
          apim.postEntity({
            resourceType: 'patrimoineSuccessionOptions',
            data: {
              ...data,
              patrimoine: iri('patrimoines', id),
              successionOption: option,
            },
            notif: false
          } as IRequestParams).then();
        } else {
          // Update
          apim.patchEntity({
            resourceType: 'patrimoineSuccessionOptions',
            id: res.data['hydra:member'][0]['id'],
            data: {
              ...data,
            },
            notif: false
          } as IRequestParams).then();
        }
      }} as IRequestParams).then();
  }

  const setPatrimoineSuccessionOptionValue = (personnePhysique: any, option: string, field: string, value: any) => {
    const personnePhysiqueId = personnePhysique.id;

    const _patrimoineSuccessionOptionValues: any = {...patrimoineSuccessionOptionValues};
    if (_patrimoineSuccessionOptionValues[personnePhysiqueId] === null
      || _patrimoineSuccessionOptionValues[personnePhysiqueId] === undefined) {
      _patrimoineSuccessionOptionValues[personnePhysiqueId] = {};
    }

    if (_patrimoineSuccessionOptionValues[personnePhysiqueId][option] === null
      || _patrimoineSuccessionOptionValues[personnePhysiqueId][option] === undefined) {
      _patrimoineSuccessionOptionValues[personnePhysiqueId][option] = {fields: {}};
    }

    _patrimoineSuccessionOptionValues[personnePhysiqueId][option]['fields'][field] = value;
    _patrimoineSuccessionOptionValues[personnePhysiqueId][option]['total'] = Object.values(_patrimoineSuccessionOptionValues[personnePhysiqueId][option]['fields']).reduce((a: any, b: any) => a + b, 0);

    setPatrimoineSuccessionOptionValues(_patrimoineSuccessionOptionValues);
    savePatrimoineSuccessionOptionValues(option);
  };

  const getPatrimoineSuccessionOptionValue = (personnePhysique: any, option: string, field: string) => {
    const personnePhysiqueId = personnePhysique.id;

    if (patrimoineSuccessionOptionValues[personnePhysiqueId] === null
      || patrimoineSuccessionOptionValues[personnePhysiqueId] === undefined) {
      return null;
    }

    if (patrimoineSuccessionOptionValues[personnePhysiqueId][option] === null
      || patrimoineSuccessionOptionValues[personnePhysiqueId][option] === undefined) {
      return null;
    }

    if (field === 'total') {
      return patrimoineSuccessionOptionValues[personnePhysiqueId][option]['total'] ?? null;
    }

    return patrimoineSuccessionOptionValues[personnePhysiqueId][option]['fields'][field] ?? null;
  }

  /**
   * Load initial personne physique annual datas.
   */
  const loadPersonnePhysiqueAnnualDatas = () => {
    if (isValidUUID(patrimoineFoyer?.id)) {
      setPersonnePhysiqueAnnualDataLoading(true);

      const params = [{label: 'year', value: dateString(context.date, 'YYYY')}];
      if (isValidUUID(patrimoineFoyer?.foyerSpouses[0]?.id)) {
        params.push({label: 'personnePhysique[]', value: patrimoineFoyer?.foyerSpouses[0]?.id});
      }

      if (isValidUUID(patrimoineFoyer?.foyerSpouses[1]?.id)) {
        params.push({label: 'personnePhysique[]', value: patrimoineFoyer?.foyerSpouses[1]?.id});
      }

      if (params.length > 0) {
        apim.fetchEntities({
          resourceType: 'personnesPhysiquesAnnualDatas',
          params,
          cache: false,
          setLoading: setPersonnePhysiqueAnnualDataLoading,
          success: (res: AxiosResponse) => {
            if (res?.data && (res?.data['hydra:member'] ?? []).length > 0) {
              setPersonnePhysiqueAnnualDatas(res.data['hydra:member']);
            } else {
              setPersonnePhysiqueAnnualDatas([]);
            }
          }
        } as IRequestParams).then();
      }
    }
  }

  const setPersonnePhysiqueAnnualDataValue = (personnePhysique: any, field: string, value: any, save: boolean) => {
    const personnePhysiqueId = personnePhysique.id;
    const personnePhysiqueIri = iri('personnesPhysiques', personnePhysiqueId);
    const _personnePhysiqueAnnualDatas: any = [];

    let personnePhysiqueAnnualData: any = null;
    if (personnePhysiqueAnnualDatas.length > 0) {
      personnePhysiqueAnnualDatas.map((ppad: any) => {
        if (ppad.personnePhysique === personnePhysiqueIri) {
          personnePhysiqueAnnualData = ppad;
          ppad[field] = value;
        }

        _personnePhysiqueAnnualDatas.push(ppad);
        return null;
      });
    }

    if (personnePhysiqueAnnualData === null || personnePhysiqueAnnualData === undefined) {
      personnePhysiqueAnnualData = {
        personnePhysique: personnePhysiqueIri,
        year: parseInt(dateString(context.date, 'YYYY') as string),
      };

      personnePhysiqueAnnualData[field] = value;
      _personnePhysiqueAnnualDatas.push(personnePhysiqueAnnualData);
    }

    setPersonnePhysiqueAnnualDatas(_personnePhysiqueAnnualDatas);

    if (save) {
      savePersonnePhysiqueAnnualDatas(personnePhysique);
    }
  };

  const getPersonnePhysiqueAnnualDataValue = (personnePhysique: any, field: string) => {
    const personnePhysiqueId = personnePhysique.id;
    const personnePhysiqueIri = iri('personnesPhysiques', personnePhysiqueId);

    let personnePhysiqueAnnualData: any = null;
    if (personnePhysiqueAnnualDatas.length > 0) {
      personnePhysiqueAnnualDatas.map((ppad: any) => {
        if (ppad.personnePhysique === personnePhysiqueIri) {
          personnePhysiqueAnnualData = ppad;
        }

        return null;
      });
    }

    if (personnePhysiqueAnnualData === null || personnePhysiqueAnnualData === undefined) {
      return null;
    }

    return personnePhysiqueAnnualData[field] ?? null;
  }

  /**
   * Save the personne physique annual data for given personne physique.
   *
   * @param personnePhysique
   */
  const savePersonnePhysiqueAnnualDatas = (personnePhysique: any) => {
    const personnePhysiqueId = personnePhysique.id;
    const personnePhysiqueIri = iri('personnesPhysiques', personnePhysiqueId);
    if (personnePhysiqueAnnualDatas.length > 0) {
      personnePhysiqueAnnualDatas.map((ppad: any) => {
        if (ppad.personnePhysique === personnePhysiqueIri) {
          apim.fetchEntities({
            resourceType: 'personnesPhysiquesAnnualDatas',
            params: [
              {label: 'personnePhysique', value: personnePhysique.id},
              {label: 'year', value: parseInt(dateString(context.date, 'YYYY') as string)}
            ],
            cache: false,
            success: (res: AxiosResponse) => {
              if (!res?.data || (res?.data['hydra:member'] || []).length === 0) {
                // Create
                apim.postEntity({
                  resourceType: 'personnesPhysiquesAnnualDatas',
                  data: {
                    ...ppad,
                    personnePhysique: personnePhysiqueIri,
                    year: parseInt(dateString(context.date, 'YYYY') as string),
                  },
                  notif: false
                } as IRequestParams).then();
              } else {
                // Update
                apim.patchEntity({
                  resourceType: 'personnesPhysiquesAnnualDatas',
                  id: res.data['hydra:member'][0]['id'],
                  data: {
                    montantRevenuAnnuelMoyenBrut: ppad.montantRevenuAnnuelMoyenBrut ?? null,
                    montantRevenuAnnuelMoyenNetImposable: ppad.montantRevenuAnnuelMoyenNetImposable ?? null,
                    caisse: ppad.caisse ?? null,
                    dateDebutCarrierePro: ppad.dateDebutCarrierePro ?? null,
                    retraiteNombreAnneesCotisationReference: ppad.retraiteNombreAnneesCotisationReference ?? null,
                    retraiteAgeDepartSouhaite: ppad.retraiteAgeDepartSouhaite ?? null,
                  },
                  notif: false
                } as IRequestParams).then();
              }
            }} as IRequestParams).then();
        }

        return null;
      });
    }
  }

  const displayPrevoyanceRetraiteForm = (personnePhysique: any) => {
    return <Fieldset legend={personnePhysique.fullName} className={'w-full mb-3'} toggleable>
      <h3 className={'m-0 mx-2 mb-4 text-lg'}>{trans(t, 'form|patrimoine.prevoyance.prevoyance')}</h3>
      <div className={'flex flex-row mb-4'}>
        <UncontrolledNumberField classes={'col-3'} mode={'currency'} addon={'euro'} addonPosition={'after'}
                                 label={trans(t, 'form|patrimoine.prevoyance.revenuAnnuelMoyenBrut')}
                                 value={getPersonnePhysiqueAnnualDataValue(personnePhysique, 'montantRevenuAnnuelMoyenBrut')}
                                 onValueChange={(value: any) => setPersonnePhysiqueAnnualDataValue(personnePhysique, 'montantRevenuAnnuelMoyenBrut', value, true)}/>

        <UncontrolledNumberField classes={'col-3'} mode={'currency'} addon={'euro'} addonPosition={'after'}
                                 label={trans(t, 'form|patrimoine.prevoyance.revenuAnnuelMoyenNetImposable')}
                                 value={getPersonnePhysiqueAnnualDataValue(personnePhysique, 'montantRevenuAnnuelMoyenNetImposable')}
                                 onValueChange={(value: any) => setPersonnePhysiqueAnnualDataValue(personnePhysique, 'montantRevenuAnnuelMoyenNetImposable', value, true)}/>

        <UncontrolledTextField classes={'col-3'} label={trans(t, 'form|patrimoine.prevoyance.caisse')}
                               value={getPersonnePhysiqueAnnualDataValue(personnePhysique, 'caisse')}
                               onFieldChange={(value: any) => setPersonnePhysiqueAnnualDataValue(personnePhysique, 'caisse', value, false)}
                               onBlur={(value: any) => setPersonnePhysiqueAnnualDataValue(personnePhysique, 'caisse', value, true)}/>
      </div>

      <h3 className={'m-0 mx-2 mb-4 text-lg'}>{trans(t, 'form|patrimoine.retraite.retraite')}</h3>
      <div className={'flex flex-row mb-4'}>
        <UncontrolledMaskField classes={'col-3'} label={trans(t, 'form|patrimoine.prevoyance.dateDebutCarrierePro')}
                               value={getPersonnePhysiqueAnnualDataValue(personnePhysique, 'dateDebutCarrierePro')}
                               onFieldChange={(value: any) => setPersonnePhysiqueAnnualDataValue(personnePhysique, 'dateDebutCarrierePro', value, false)}
                               onBlur={(value: any) => setPersonnePhysiqueAnnualDataValue(personnePhysique, 'dateDebutCarrierePro', value, true)}/>

        <UncontrolledNumberField classes={'col-3'} mode={'decimal'} label={trans(t, 'form|patrimoine.prevoyance.retraiteNombreAnneesCotisationReference')} showButtons min={1} max={99}
                                 value={getPersonnePhysiqueAnnualDataValue(personnePhysique, 'retraiteNombreAnneesCotisationReference')} suffix={' ' + trans(t, 'year', 2)}
                                 onValueChange={(value: any) => setPersonnePhysiqueAnnualDataValue(personnePhysique, 'retraiteNombreAnneesCotisationReference', value, true)}/>

        <UncontrolledNumberField classes={'col-3'} mode={'decimal'} label={trans(t, 'form|patrimoine.prevoyance.retraiteAgeDepartSouhaite')} showButtons min={1} max={99}
                                 value={getPersonnePhysiqueAnnualDataValue(personnePhysique, 'retraiteAgeDepartSouhaite')} suffix={' ' + trans(t, 'year', 2)}
                                 onValueChange={(value: any) => setPersonnePhysiqueAnnualDataValue(personnePhysique, 'retraiteAgeDepartSouhaite', value, true)}/>
      </div>
    </Fieldset>;
  }

  const displayDecesForm = (personnePhysique: any, isSpouse: boolean) => {
    let options: any = [];
    let optionsTranslations: any = [];

    const donationEntreConjoints: boolean = patrimoineFoyer?.donationEntreConjoints ?? false;
    if (donationEntreConjoints) {
      options = patrimoineFoyer?.successionDonationOptions ?? [];
      optionsTranslations = successionDonationOptions;
    } else {
      options = successionCodeCivilOptions.map((option: any) => option.value);
      optionsTranslations = successionCodeCivilOptions;
    }

    const successionAbattementsResidencePrincipaleUsufruitsField: string = 'successionAbattementsResidencePrincipaleUsufruits' + (isSpouse ? 'P2' : '');
    const successionDeductionsDiversesField: string = 'successionDeductionsDiverses' + (isSpouse ? '' : 'P2');
    const successionAvantagesMatrimoniauxField: string = 'successionAvantagesMatrimoniaux' + (isSpouse ? '' : 'P2');

    return <Fieldset legend={ucfirst(trans(t, 'form|patrimoine.successionEnCasDeDeces') + ' ' + personnePhysique.fullName)} className={'w-full mb-3'} toggleable>
      <h3 className={'m-0 mx-2 mb-4 text-lg'}>{trans(t, 'form|patrimoine.deductions')}</h3>
      <div className={'flex flex-row mb-4'}>
      <NumberField classes={'col-3'} fieldKey={successionAbattementsResidencePrincipaleUsufruitsField} mode={'currency'} control={control} onFieldChange={onFieldChange} errorMessage={errorMessage}
                     addon={'euro'} addonPosition={'after'} label={trans(t, 'form|patrimoine.successionAbattementsResidencePrincipaleUsufruits')}
                     tooltip={trans(t, 'form|patrimoine.successionAbattementsResidencePrincipaleUsufruitsTooltip')}/>

        <NumberField classes={'col-3'} fieldKey={successionDeductionsDiversesField} mode={'currency'} control={control} onFieldChange={onFieldChange} errorMessage={errorMessage}
                     addon={'euro'} addonPosition={'after'} label={trans(t, 'form|patrimoine.successionDeductionsDiverses')}
                     tooltip={trans(t, 'form|patrimoine.successionDeductionsDiversesTooltip')}/>

        <NumberField classes={'col-3'} fieldKey={successionAvantagesMatrimoniauxField} mode={'currency'} control={control} onFieldChange={onFieldChange} errorMessage={errorMessage}
                     addon={'euro'} addonPosition={'after'} label={trans(t, 'form|patrimoine.successionAvantagesMatrimoniaux')}
                     tooltip={trans(t, 'form|patrimoine.successionAvantagesMatrimoniauxTooltip')}/>
      </div>

      <div className={'flex flex-row align-items-center mb-4'}>
        <h3 className={'m-0 mx-2 mb-0 text-lg'}>{trans(t, 'form|patrimoine.donations.donations')}</h3>
        <Button link className={'m-0 mx-2 mb-0 text-base'} size={'small'} onClick={() => navigate(appUri.dos.edit.patrimoine.donations.landing.replace(':id', uuidFromIri(context.dossier)))}>
          {trans(t, 'form|patrimoine.donations.manage')}
        </Button>
      </div>

      <div className={'flex flex-row mb-4'}>
        <UncontrolledNumberField classes={'col-3'} mode={'currency'} disabled={true} addon={'euro'} addonPosition={'after'}
                                 label={trans(t, 'form|patrimoine.successionDonationsAcquises')} value={patrimoineFoyer?.donationSummary[personnePhysique.id]?.acquises ?? null}
                                 tooltip={trans(t, 'form|patrimoine.successionDonationsAcquisesTooltip')}/>

        <UncontrolledNumberField classes={'col-3'} mode={'currency'} disabled={true} addon={'euro'} addonPosition={'after'}
                                 label={trans(t, 'form|patrimoine.successionDonationsRapportables')} value={patrimoineFoyer?.donationSummary[personnePhysique.id]?.rapportables ?? null}
                                 tooltip={trans(t, 'form|patrimoine.successionDonationsRapportablesTooltip')}/>
      </div>

      <h3 className={'m-0 mx-2 mb-2 text-lg'}>1. {trans(t, 'form|patrimoine.1erDeces')}</h3>
      <p className={'m-0 mx-2 mb-4 text-sm'}>
        {trans(t, 'form|patrimoine.1erDecesDescription1')}<br/>
        {trans(t, 'form|patrimoine.1erDecesDescription2')}
      </p>

      {patrimoineFoyerLoading || successionDonationOptionsLoading || successionCodeCivilOptionsLoading ? (
        <Skeleton width={'100%'} height={'8rem'} className={'w-full mb-5'}/>
      ) : (
        options.map((option: string, index: number) => {
          return <div key={index} className={'flex flex-row align-items-center'}>
            <h4 className={'col-3 text-base font-normal'}>
              {ucfirst(trans(t, 'form|option'))}: {formatListItem(option, optionsTranslations)}
            </h4>

            <UncontrolledNumberField classes={'col-3'} mode={'currency'} addon={'euro'} addonPosition={'after'}
                                     label={trans(t, 'form|patrimoine.1erDecesConjoint')} value={getPatrimoineSuccessionOptionValue(personnePhysique, option, 'succession1erDecesConjoint')}
                                     tooltip={trans(t, 'form|patrimoine.1erDecesConjointTooltip')}
                                     onValueChange={(value: any) => setPatrimoineSuccessionOptionValue(personnePhysique, option, 'succession1erDecesConjoint', value)}/>

            <UncontrolledNumberField classes={'col-3'} mode={'currency'} addon={'euro'} addonPosition={'after'}
                                     label={trans(t, 'form|patrimoine.1erDecesHeritiers')} value={getPatrimoineSuccessionOptionValue(personnePhysique, option, 'succession1erDecesHeritiers')}
                                     tooltip={trans(t, 'form|patrimoine.1erDecesHeritiersTooltip')}
                                     onValueChange={(value: any) => setPatrimoineSuccessionOptionValue(personnePhysique, option, 'succession1erDecesHeritiers', value)}/>
          </div>;
        })
      )}

      <h3 className={'m-0 mx-2 mb-2 mt-4 text-lg'}>2. {trans(t, 'form|patrimoine.2eDeces')}</h3>
      <p className={'m-0 mx-2 mb-4 text-sm'}>
        {trans(t, 'form|patrimoine.2eDecesDescription1')}<br/>
        {trans(t, 'form|patrimoine.2eDecesDescription2')}<br/>
        {trans(t, 'form|patrimoine.2eDecesDescription3')}
      </p>

      {patrimoineFoyerLoading || successionDonationOptionsLoading || successionCodeCivilOptionsLoading ? (
        <Skeleton width={'100%'} height={'8rem'} className={'w-full mb-5'}/>
      ) : (
        options.map((option: string, index: number) => {
          return <div key={index} className={'flex flex-row align-items-center'}>
            <h4 className={'col-3 text-base font-normal'}>
              {ucfirst(trans(t, 'form|option'))}: {formatListItem(option, optionsTranslations)}
            </h4>

            <UncontrolledNumberField classes={'col-3'} mode={'currency'} addon={'euro'} addonPosition={'after'}
                                     label={trans(t, 'form|patrimoine.2eDecesHeritiers')} value={getPatrimoineSuccessionOptionValue(personnePhysique, option, 'succession2eDecesHeritiers')}
                                     tooltip={trans(t, 'form|patrimoine.2eDecesHeritiersTooltip')}
                                     onValueChange={(value: any) => setPatrimoineSuccessionOptionValue(personnePhysique, option, 'succession2eDecesHeritiers', value)}/>
          </div>;
        })
      )}

      <h3 className={'m-0 mx-2 mb-2 mt-4 text-lg'}>3. {trans(t, 'form|patrimoine.totalDeuxDeces')}</h3>
      <p className={'m-0 mx-2 mb-4 text-sm'}>
        {trans(t, 'form|patrimoine.totalDeuxDecesDescription')}
      </p>

      {patrimoineFoyerLoading || successionDonationOptionsLoading || successionCodeCivilOptionsLoading ? (
        <Skeleton width={'100%'} height={'8rem'} className={'w-full mb-5'}/>
      ) : (
        options.map((option: string, index: number) => {
          return <div key={index} className={'flex flex-row align-items-center'}>
            <h4 className={'col-3 text-base font-normal'}>
              {ucfirst(trans(t, 'form|option'))}: {formatListItem(option, optionsTranslations)}
            </h4>

            <UncontrolledNumberField classes={'col-3'} mode={'currency'} addon={'euro'} addonPosition={'after'} disabled={true}
                                     label={trans(t, 'total')} value={getPatrimoineSuccessionOptionValue(personnePhysique, option, 'total')}/>
          </div>;
        })
      )}
    </Fieldset>;
  }

  return <>
    <Fieldset legend={ucfirst(trans(t, 'form|patrimoine.general'))} toggleable className={'w-full mb-3'}>
      <div className={'grid'}>
        <div className={'col-3'}>
          <MaskField classes={'w-full'} fieldKey={'date'} control={control} onFieldChange={onFieldChange} rules={{ required: trans(t, 'form|requiredField') }}
                     addon={'calendar'} addonPosition={'after'} label={ucfirst(trans(t, 'form|patrimoine.date'))} errorMessage={errorMessage} isDate />
        </div>

        <div className={'col-3'}>
          <MaskField classes={'w-full'} fieldKey={'lastUpdate'} control={control} onFieldChange={onFieldChange} rules={{ required: trans(t, 'form|requiredField') }}
                     addon={'calendar'} addonPosition={'after'} label={ucfirst(trans(t, 'form|patrimoine.lastUpdate'))} errorMessage={errorMessage} isDate/>
        </div>

        {i18n.language === 'fr-FR' && (
          <div className={'col-12'}>
            <p className={'m-0 text-sm'}><u>Note:</u> si vous changez la date du bilan patrimonial, il vous faudra saisir les informations annuelles manquantes.</p>
            <p className={'m-0 text-sm'}>Par exemple, les dividendes et les soldes des comptes courant d'associés dans les biens de type "Entreprise".</p>
          </div>
        )}
      </div>
    </Fieldset>

    <Fieldset legend={ucfirst(trans(t, 'form|patrimoine.fiscal'))} toggleable className={'w-full mb-3'}>
      <div className={'grid'}>
        <div className={'col-12'}>
          <div className={'grid mt-4'}>
            <div className={'col-3'}>
              <APIListField classes={'w-full'} listKey={'impositionOptionFiscales'} fieldKey={'fiscalOption'} control={control} onFieldChange={onFieldChange} errorMessage={errorMessage}
                            label={trans(t, 'form|patrimoine.fiscalOption')} rules={{required: trans(t, 'form|requiredField')}}/>
            </div>

            {i18n.language === 'fr-FR' && (
              <div className={'col-12'}>
                <p className={'m-0 text-sm'}>Précisez l'option fiscale de l'imposition des dividendes :</p>
                <ul className={'m-0 ml-4 p-0 text-sm'}>
                  <li>Soit au barème de l'impôt sur le revenu (avant 2018).</li>
                  <li>Soit au prélèvement forfaitaire non libératoire.</li>
                  <li>Soit au prélèvement forfaitaire unique.</li>
                </ul>
                <p className={'m-0 mt-2 text-sm'}>Dans ces dernier cas, un crédit d'impôt sera restitué au contribuable si le prélèvement dépasse l'IR à payer.</p>
                <p className={'m-0 text-sm'}>Vous pouvez également opter pour l'exonération ou l'imposition à 22,5% ou 19% pour les PEA.</p>
              </div>
            )}
          </div>
        </div>
      </div>
    </Fieldset>

    <Fieldset legend={ucfirst(trans(t, 'form|patrimoine.prevoyanceRetraite'))} className={'w-full mb-3'} toggleable>
      <>
        {patrimoineFoyerLoading || personnePhysiqueAnnualDataLoading ? (
          <Skeleton width={'100%'} height={'8rem'} className={'w-full mb-5'} />
        ) : (
          <>
            {isValidUUID(patrimoineFoyer?.id) ? (
              <>
                {isValidUUID(patrimoineFoyer?.foyerSpouses[0]?.id) && displayPrevoyanceRetraiteForm(patrimoineFoyer?.foyerSpouses[0])}
                {isValidUUID(patrimoineFoyer?.foyerSpouses[1]?.id) && displayPrevoyanceRetraiteForm(patrimoineFoyer?.foyerSpouses[1])}
              </>
            ) : (
              <p>{trans(t, 'form|patrimoine.prevoyanceRetraiteEmptyMessage')}</p>
            )}
          </>
        )}
      </>
    </Fieldset>

    <Fieldset legend={ucfirst(trans(t, 'form|patrimoine.succession.succession'))} className={'w-full mb-3'} toggleable>
      <>
        <Fieldset legend={ucfirst(trans(t, 'form|patrimoine.testament.testament'))} className={'w-full mb-3'} toggleable>
          <div className={'grid mt-4'}>
            <div className={'col-12'}>
              <CheckboxField classes={'w-full'} fieldKey={'existenceTestament'}
                             control={control} onFieldChange={onFieldChange} errorMessage={errorMessage}
                             label={trans(t, 'form|patrimoine.testament.existenceTestament')} help={trans(t, 'form|patrimoine.testament.existenceTestamentHelp')}/>
            </div>

            {existenceTestament && (
              <div className={'col-12'}>
                <EditorField fieldKey={'contenuTestament'} control={control} onFieldChange={onFieldChange} errorMessage={errorMessage}
                             label={trans(t, 'form|patrimoine.testament.contenuTestament')} classes={'w-full mb-0'} height={'100px'}/>
              </div>
            )}
          </div>
        </Fieldset>

        {patrimoineFoyerLoading || successionDonationOptionsLoading || successionCodeCivilOptionsLoading ? (
          <Skeleton width={'100%'} height={'8rem'} className={'w-full mb-5'} />
        ) : (
          <>
            {isValidUUID(patrimoineFoyer?.foyerSpouses[0]?.id) && displayDecesForm(patrimoineFoyer?.foyerSpouses[0], false)}
            {isValidUUID(patrimoineFoyer?.foyerSpouses[1]?.id) && displayDecesForm(patrimoineFoyer?.foyerSpouses[1], true)}
          </>
        )}
      </>
    </Fieldset>
  </>;
};
