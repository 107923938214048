import { MutableRefObject } from 'react';
import { hookstate, useHookstate, State } from '@hookstate/core';
import axios, { AxiosError, AxiosHeaders, AxiosResponse } from 'axios';
import { NavigateFunction } from 'react-router-dom';
import { TFunction } from 'i18next';

import { isValidUUID, onApiError, onApiSuccess } from 'utilities';
import { ensureTokens, getTokens } from 'services';
import { unState } from 'states';

import { forEach, includes, isEmpty, omit } from 'lodash';
import apimConfig from 'config/apimConfig.json';
import cacheConfig from 'config/cacheConfig.json';

const baseUrl = process.env.REACT_APP_APIM_URL;
const apimKey = process.env.REACT_APP_APIM_KEY;
const debugMode = process.env.REACT_APP_DEBUG_MODE || '0';

interface IApimState {
  // CACHE
  items: any[]; // cached items
  // UTILS
  t: object | null,
  navigate: object | null,
  // TOASTS
  toast: MutableRefObject<any> | null,
  toastError: MutableRefObject<any> | null,
  toastSuccess: MutableRefObject<any> | null,
  // MAINTENANCE
  maintenance: boolean | null,
}

const emptyState: IApimState = {
  // CACHE
  items: [],
  // UTILS
  t: null,
  navigate: null,
  // TOASTS
  toast: null,
  toastError: null,
  toastSuccess: null,
  // MAINTENANCE
  maintenance: null,
} as unknown as IApimState;
const state: State<IApimState> = hookstate(Object.assign({}, emptyState));
const wrapper = (s: State<IApimState>) => ({
  // DI
  setNavigate: (navigate: any) => s.navigate.set(navigate),
  navigate: (): NavigateFunction => s.get({ noproxy: true }).navigate! as NavigateFunction,
  setT: (t: any) => s.t.set(t),
  t: (): TFunction => s.get({ noproxy: true }).t! as TFunction,
  di: () => {
    return {
      navigate: s.get({ noproxy: true }).navigate! as NavigateFunction,
      t: s.get({ noproxy: true }).t! as TFunction,
      toast: s.get({ noproxy: true }).toast!,
      toastError: s.get({ noproxy: true }).toastError!,
      toastSuccess: s.get({ noproxy: true }).toastSuccess!,
    }
  },

  // Methods
  call: (params: IRequestParams) => call(s, params),
  fetchEntities: (params: IRequestParams): Promise<AxiosResponse> => call(s, {...{ method: 'get' }, ...params}),
  fetchEntity: (params: IRequestParams): Promise<AxiosResponse> => call(s, {...{method: 'get',}, ...params}),
  patchEntity: (params: IRequestParams): Promise<AxiosResponse> => call(s, {...{method: 'patch',}, ...params}),
  postEntity: (params: IRequestParams): Promise<AxiosResponse> => call(s, params),
  deleteEntity: (params: IRequestParams): Promise<AxiosResponse> => call(s, {...{method: 'delete',}, ...params}),
  getList: (listKey: string, params: IRequestParams) => call(s, {...{
    resourceType: 'listes',
    action: listKey,
    method: 'get',
  }, ...params}),

  // Utils
  setToast: (toastRef: any, type: string | null = 'default') => {
    if ('error' === type) return s.toastError.set(toastRef);
    if ('success' === type) return s.toastSuccess.set(toastRef);
    s.toast.set(toastRef)
  },
  toast: (type: string | null = 'default') => {
    if ('error' === type) return s.get({ noproxy: true }).toastError!;
    if ('success' === type) return s.get({ noproxy: true }).toastSuccess!;
    return s.get({ noproxy: true }).toast!;
  },
  displayError: (summary: string, details: string | null, life: number | null = 4000) => {
    const toast = s.get({ noproxy: true }).toastError!;
    if (toast && toast.current) {
      toast.current.show({
        severity: 'error',
        summary: summary,
        detail: details,
        life: life
      });
    }
  },
  displaySuccess: (summary: string, details: string | null, life: number | null = 4000) => {
    const toast = s.get({ noproxy: true }).toastSuccess!;
    if (toast && toast.current) {
      toast.current.show({
        severity: 'success',
        summary: summary,
        detail: details,
        life: life
      });
    }
  },

  // Maintenance
  maintenance: (): boolean => !!s.maintenance.get(),
  setMaintenance: (maintenance: boolean) => s.maintenance.set(maintenance),

  // Caching
  // get: (key: string): any | null => getItem(s, key),
  // set: (key: string, value: any, expire: Date | null = null) => setItem(s, key, value, expire),
  // remove: (key: string) => removeKey(s, key),
  // removeCacheUsingId: (id: string) => removeId(s, id),

  state: () => s.get({ noproxy: true }),
});

export const useApim = () => wrapper(useHookstate(state));

export interface IRequestParam {
  label: string,
  value: any | null,
}

export interface IRequestParams {
  resourceType: string,

  method: string | null,                  // get, post (default), patch, delete, put
  uri: string | null,
  id: string | null,
  action: string | null,
  params: IRequestParam[] | null,
  paramType: string | null,
  headers: any | null,
  data: any | null,
  formatter: any | null,                  // a hook to format fetched data
  setter: any | null,                     // shortcut for 'success: (res: AxiosResponse) ........ setWathever( formatted fetched data )
  setErrored: any | null,
  setLoading: any | null,
  setNotFound: any | null,
  setUnauthorized: any | null,
  error: any | null,                      // a hook triggered on 'catch'
  success: any | null,                    // a hook triggered on 'then'
  always: any | null,                     // a hook triggered on 'finally'
  notif: boolean | null,                  // true (default) : handle or not toast displays
  notifError: any,                        // summary (opt) + details (opt) to be used by Toast in case of an error
  notifSuccess: any,                      // summary (opt) + details (opt) to be used by Toast in case of success
  auth: boolean | null,
  async: boolean | null,                  // try to not use this (default false)
  cache: boolean | null,
  cacheMode: string | null,
  redirectOnError: boolean | null,
  aOptions: any | null,
  progress: any | null,
}

const defaultRequestParams: IRequestParams = {
  method: 'post',
  uri: null,
  id: null,
  action: null,
  params: null,
  paramType: 'jsonContent',
  headers: null,
  data: null,
  formatter: null,
  setter: null,
  setErrored: null,
  setLoading: null,
  setNotFound: null,
  setUnauthorized: null,
  error: null,
  success: null,
  always: null,
  notif: apimConfig.default.notif,
  notifError: {},
  notifSuccess: {},
  auth: true,
  async: true,
  cache: true,
  cacheMode: 'default',
  redirectOnError: false,
  aOptions: {},
  progress: null,
} as unknown as IRequestParams;

// CACHING
const setItem = (s: State<IApimState>, key: string, value: any, expire: number | null = null) => {
  if (null === expire) {
    expire = Date.now() + cacheConfig.ttl;
  }

  const newItems = [
    ...unState(s.items.get() ?? []),
    unState({key: key, value: omit(value, ['config', 'headers', 'request']), expire: expire})
  ];

  s.items.set(newItems);
};

const removeKey = (s: State<IApimState>, key: string | null = null) => {
  if (!key) return;

  const newItems = unState((s.items.get() ?? [])).filter((i: any) => i.key !== key);
  s.items.set(newItems);
};

const removeId = (s: State<IApimState>, id: string | null = null) => {
  if (!id) return;

  const newItems = unState((s.items.get() ?? []).filter((i: any) => {
    const iParts: string[] = i.key.split('_');
    let found: boolean = false;
    forEach((iParts[0].split('|')), ((_id: string) => {
      if (_id === id) found = true;
    }));

    return found;
  }));
  s.items.set(newItems);
};

const getItem = (s: State<IApimState>, key: string): any => {
  const cacheItems = s.items.get();

  if (cacheItems.length < 1) return null;

  let items = cacheItems.filter((i) => i.key === key);
  if (0 === items.length) return null;
  const item = items[0];

  // Recursive call if item is another cache key!
  if ('string' === typeof item.value && 1 < item.value.split(cacheConfig.aliasKey).length) return getItem(s, item.value.split(cacheConfig.aliasKey)[1]);

  // Check expiration value & remove item if expired.
  // (let's keep this AFTER alias check, because, we don't need to handle expiration on aliases.)
  if (JSON.stringify(item.expire) < Date.now().toString()) {
    removeKey(s, key);

    return null;
  }

  return unState(item.value);
};

// REQUESTS
export const getSource = () => axios.CancelToken.source();

const headers = (s: State<IApimState>, auth = true, operation: string | null = null, additionalHeaders: any = null) => {
  const headers: any = {'X-AUTH-TOKEN': apimKey};
  const tokens: any = auth ? getTokens() : null;
  const contentTypes = {
    multipart: 'multipart/form-data',
    patch: 'application/merge-patch+json',
    post: 'application/json',
    default: 'application/ld+json'
  };

  if (null !== tokens) headers['Authorization'] = 'Bearer ' + tokens?.accessToken;
  if (
    'multipart' !== operation &&
    'patch' !== operation &&
    'post' !== operation
  ) {
    // We are receiving something.
    headers['Accept'] = contentTypes['default'];
  } else {
    // We are sending something.
    headers['Content-Type'] = contentTypes[operation ?? 'default'];
  }

  if (null !== additionalHeaders) {
    Object.keys(additionalHeaders).forEach((key) => {
      if (
        null !== additionalHeaders[key] &&
        undefined !== additionalHeaders[key] &&
        '' !== additionalHeaders[key]
      ) {
        headers[key] = additionalHeaders[key];
      }
    });
  }

  return headers as AxiosHeaders;
};

const ApiClient = (s: State<IApimState>, auth = true, additionalHeaders = null) => {
  return axios.create({
    baseURL: baseUrl,
    headers: headers(s, auth, null, additionalHeaders)
  });
};

const ApiPatchClient = (s: State<IApimState>, auth = true, additionalHeaders = null) => {
  return axios.create({
    baseURL: baseUrl,
    headers: headers(s, auth, 'patch', additionalHeaders)
  });
};

const ApiClientJsonContent = (s: State<IApimState>, auth = true, additionalHeaders = null) => {
  return axios.create({
    baseURL: baseUrl,
    headers: headers(s, auth, 'post', additionalHeaders)
  });
};

const ApiClientMultipart = (s: State<IApimState>, progress: any = null, additionalHeaders = null) => {
  return progress
    ? axios.create({
        baseURL: baseUrl,
        headers: headers(s, true, 'multipart', additionalHeaders),
        onUploadProgress: progress
      })
    : axios.create({
        baseURL: baseUrl,
        headers: headers(s, true, 'multipart', additionalHeaders)
      });
};

const _then = (s: State<IApimState>, res: AxiosResponse | null, url: string, p: IRequestParams) => {
  // Refresh cache.
  if (res && true === p.cache) {
    setItem(s, buildCacheKey(p, url), res);
  }

  // Handle formatter & setter if any.
  if (res?.data && null !== p.setter) {
    if (res.data['hydra:member']) {
      p.setter(p.formatter ? p.formatter(res.data['hydra:member']) : res.data['hydra:member']);
    } else {
      p.setter(p.formatter ? p.formatter(res.data) : res.data);
    }
  }

  // Handle notification.
  const { notif, notifSuccess } = p;
  if (false !== notif && (
    !isEmpty(notifSuccess) || // we manually passed a message to display during the call
    includes(['put', 'patch', 'delete'], p.method?.toLowerCase()) || // pertinent methods
    includes([201, 204, 205], res?.status) // pertinent code status
  )) {
    const t = s.get({ noproxy: true }).t! as TFunction;
    const toast = s.get({ noproxy: true }).toastSuccess!;
    if (toast && t) onApiSuccess(t, res!, toast, p);
  }

  // Handle callback if any.
  if (null !== p.success) p.success(res);
};

const _catch = (
  s: State<IApimState>,
  url: string,
  clientError: any,
  p: IRequestParams
) => {
  const { error, redirectOnError, notif, notifError } = p;

  // Stop early if request just cancelled.
  if (axios.isCancel(clientError)) {
    if ('1' === debugMode) console.log('Request canceled : ' + url);

    return;
  }

  const parentStatusCode = mapStatusCode(clientError);
  const reponseStatusCode = clientError?.response?.status;

  if (clientError.message === 'Network Error') {
    if ('1' === debugMode) console.log('Network error on : ' + url, clientError);
  }

  if ('1' === debugMode && reponseStatusCode !== undefined) console.log('Error ' + (reponseStatusCode ? '[' + reponseStatusCode + '] ' : '') + 'on : ' + url, clientError);

  // Handle loaders if any.
  if (403 === parentStatusCode && p.setUnauthorized) p.setUnauthorized(true);
  else if (404 === parentStatusCode && p.setNotFound) p.setNotFound(true);
  else if (p.setErrored) p.setErrored(true);

  // Handle redirect if any.
  if (redirectOnError === true && '0' === debugMode) {
    // Redirect on error page if debugMode inactive.
    if (window.location.pathname.lastIndexOf('/erreur', 0) !== 0)
      window.location.replace(parentStatusCode === 404 ? '/introuvable' : '/erreur');
  }

  if (false !== notif) {
    // Handle notification.
    const t = s.get({ noproxy: true }).t! as TFunction;
    const toast = s.get({ noproxy: true }).toastError!;
    if (toast && t) onApiError(t, clientError, toast, notifError);
  }

  // Trigger callback if any.
  if (null !== error) {
    error(clientError);
  }
};

const _finally = (s: State<IApimState>, url: string, p: IRequestParams) => {
  // Cache eviction on related id at the end of PATCH process.
  if (p.method?.toLowerCase() === 'patch' && isValidUUID(p.id)) removeId(s, p.id);

  // Handle "finally" if any then loading setter.
  if (null !== p.always) p.always();
  if (null !== p.setLoading) p.setLoading(false);
};

export const mapStatusCode = (err: AxiosError) => {
  let code = err?.response?.status || 500;

  switch (code) {
    case 401:
    case 402:
    case 403:
    case 407:
    case 511:
      // Unauthorized.
      code = 403;
      break;

    case 404:
    case 410:
      // Not found.
      code = 404;
      break;

    default:
      break;
  }

  return code;
};

const guessFromResourceType = (resourceType: string, key = 'uri'): string | null => {
  const c = apimConfig.resources as any;
  if (undefined === c[resourceType] || !c[resourceType] || null === c[resourceType][key]) {
    if ('1' === debugMode) console.log('Resource [' + resourceType + '] missing from ApimConfig!');

    return null
  }

  return c[resourceType][key];
};

const guessUriFromAction = (resourceType: string, action: string | null = null, id: string | null = null) => {
  const uri = guessFromResourceType(resourceType);

  if (null === uri) return null;
  if (null === action) return null === id ? uri : uri + '/' + id;
  const c = apimConfig.resources as any;
  if (!c[resourceType].actions || null === c[resourceType].actions[action]) return null;

  if ('' === uri) {
    return null === id
      ? c[resourceType].actions[action]
      : id + '/' + c[resourceType].actions[action];
  }

  if (!(id || '').includes('|')) {
    return !id
      ? uri + '/' + c[resourceType].actions[action]
      : uri + '/' + id + '/' + c[resourceType].actions[action];
  }

  // Finally, a particular case when multiple ids.
  const ids = (id || '').split('|');
  const uris = (uri + '/{id}/' + c[resourceType].actions[action]).split('{id}');

  let finalUri = '';
  let lastIndex = 0;
  ids.map((i: string, index: number) => {
    if (uris[index]) {
      finalUri += uris[index];
    }

    if (ids[index]) {
      finalUri += ids[index];
    }

    lastIndex = index;
    return index;
  })

  lastIndex++;
  if (uris[lastIndex]) {
    finalUri += uris[lastIndex];
  }

  if (ids[lastIndex]) {
    finalUri += ids[lastIndex];
  }

  return finalUri;
};

const buildCacheKey = (params: IRequestParams, url: string) => isValidUUID(params.id) ?
  params.id + '_' + (params.method?.toLowerCase() ?? defaultRequestParams.method) + '_' + url:
  (params.method?.toLowerCase() ?? defaultRequestParams.method) + '_' + url;

export const call = async (s: State<IApimState>, params: IRequestParams): Promise<any | null> => {
  // Early exit if no resourceType && no uri specified.
  if (!params.resourceType && !params.uri) return null;

  // Merge given params into default ones (/!\ order matters /!\).
  const p: IRequestParams = {...defaultRequestParams, ...params};

  // Build base URL.
  const uri = p.uri || guessUriFromAction(p.resourceType, p.action, p.id);
  if (null === uri) return null;

  // Build params.
  const url = new URL(baseUrl + (uri[0] === '/' ? '' : '/') + uri);
  (p.params || []).map((param: IRequestParam) => {
    return url.searchParams.append(param.label, param.value);
  });
  let u = url.toString();
  // Handle special cases (PRELOAD HEADERS).
  if (null !== p.headers && undefined !== p.headers['Preload'] && null !== p.headers['Preload']) {
    u += (p.params ? '&' : '?') + p.headers['Preload'];
  }

  // Handle & Try cache first (using full url).
  const cacheKey: string = buildCacheKey(p, u);
  let cached = null;
  if (true === p.cache && p.cacheMode === 'replace' && p.method?.toLowerCase() === 'get') {
    removeKey(s, cacheKey);
  }
  if (true === p.cache && p.cacheMode !== 'replace' && p.method?.toLowerCase() === 'get') {
    cached = getItem(s, cacheKey);
  }

  // Proceed.
  if (true === p.async) {
    // ASYNC
    if (cached) {
      if ('1' === debugMode) console.log('[ASYNC] Cache-Hit : ' + u);

      _then(s, cached, u, {...p, ...{cache: false}});

      return _finally(s, u, p);
    }

    if ('1' === debugMode) console.log('[ASYNC] APIM Call (' + p.method?.toLowerCase() + ') : ' + u);

    // Ensure token.
    if (p.auth !== false) await ensureTokens(ApiClientJsonContent(s, false, p.headers));

    switch (p.method?.toLowerCase()) {
      case 'get':
        return ApiClient(s, p.auth as boolean, p.headers)
          .get(u, p.aOptions)
          .then((res: AxiosResponse) => {
            _then(s, res, u, p);
          })
          .catch((clientError: AxiosError) => {
            _catch(s, u, clientError, p);
          })
          .finally(() => {
            _finally(s, u, p);
          });

      case 'post':
        if ('jsonContent' === p.paramType) {
          return ApiClientJsonContent(s, p.auth as boolean, p.headers)
            .post(u, JSON.stringify(p.data), p.aOptions)
            .then((res: AxiosResponse) => {
              _then(s, res, u, p);
            })
            .catch((clientError: AxiosError) => {
              _catch(s, u, clientError, p);
            })
            .finally(() => {
              _finally(s, u, p);
            });
        } else if ('postParams' === p.paramType) {
          return ApiClientMultipart(s, p.progress, p.headers)
            .post(u, p.data, p.aOptions)
            .then((res: AxiosResponse) => {
              _then(s, res, u, p);
            })
            .catch((clientError: AxiosError) => {
              _catch(s, u, clientError, p);
            })
            .finally(() => {
              _finally(s, u, p);
            });
        }
        break;

      case 'put':
        return ApiClientJsonContent(s, p.auth as boolean, p.headers)
          .put(u, JSON.stringify(p.data), p.aOptions)
          .then((res: AxiosResponse) => {
            _then(s, res, u, p);
          })
          .catch((clientError: AxiosError) => {
            _catch(s, u, clientError, p);
          })
          .finally(() => {
            _finally(s, u, p);
          });

      case 'patch':
        return ApiPatchClient(s, p.auth as boolean, p.headers)
          .patch(u, JSON.stringify(p.data), p.aOptions)
          .then((res: AxiosResponse) => {
            _then(s, res, u, p);
          })
          .catch((clientError: AxiosError) => {
            _catch(s, u, clientError, p);
          })
          .finally(() => {
            _finally(s, u, p);
          });

      case 'delete':
        return ApiClient(s, p.auth as boolean, p.headers)
          .delete(u, p.aOptions)
          .then((res: AxiosResponse) => {
            _then(s, res, u, p);
          })
          .catch((clientError: AxiosError) => {
            _catch(s, u, clientError, p);
          })
          .finally(() => {
            _finally(s, u, p);
          });
    }

    return null;
  }

  // !ASYNC
  if (cached) {
    if ('1' === debugMode) console.log('[ASYNC] Cache-Hit : ' + u);

    _then(s, cached, u, {...p, ...{cache: false}});

    return _finally(s, u, p);
  }
  let response = null;

  try {
    if ('1' === debugMode) console.log('[SYNC] APIM Call (' + p.method?.toLowerCase() + ') : ' + u);

    // Ensure token.
    if (p.auth !== false) await ensureTokens(ApiClientJsonContent(s, false, p.headers));

    switch (p.method?.toLowerCase()) {
      case 'get':
        response = await ApiClient(s, p.auth as boolean, p.headers).get(u, p.aOptions);
        break;

      case 'post':
        if ('jsonContent' === p.paramType) {
          response = await ApiClientJsonContent(s, p.auth as boolean, p.headers).post(u, JSON.stringify(p.data), p.aOptions);
        } else if ('postParams' === p.paramType) {
          response = await ApiClientMultipart(p.progress).post(u, p.data, p.aOptions);
        }
        break;

      case 'put':
        response = await ApiClientJsonContent(s, p.auth as boolean, p.headers).put(u, JSON.stringify(p.data), p.aOptions);
        break;

      case 'patch':
        response = await ApiPatchClient(s, p.auth as boolean, p.headers).patch(u, JSON.stringify(p.data), p.aOptions);
        break;

      case 'delete':
        response = await ApiClient(s, p.auth as boolean, p.headers).delete(u, p.aOptions);
        break;
    }
  } catch (clientError: any | AxiosError) {
    _catch(s, u, clientError, p);
  }

  // Refresh cache.
  if (true === p.cache && null !== response) {
    setItem(s, cacheKey, response);
  }

  _then(s, response, u, {...p, ...{cache: false}});
  _finally(s, u, p); // handle loaders

  return response;
};
