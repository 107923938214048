export interface IUserGroup {
  id: string | null,
  user: string | null,
  role: string | null,
  group: string | null,
  active: boolean | null,
  created: string | null,
  updated: string | null,
  deleted: string | null
}

export const emptyUserGroup: IUserGroup = {
  id: null,
  user: null,
  role: null,
  group: null,
  active: null,
  created: null,
  updated: null,
  deleted: null,
} as unknown as IUserGroup;
