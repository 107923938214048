import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { TFunction } from 'i18next';
import { AxiosResponse } from 'axios';
import { TabMenu } from 'primereact/tabmenu';

import { iri, isValidUUID, trans, ucfirst } from 'utilities';
import { Error, NotFound } from 'pages';
import { PageLoader } from 'components';
import { useAppState } from 'states';
import { IRequestParams, isClient } from 'services';
import { Prevoyance, PrevoyanceObligatoire } from './tabs';
import { PrevoyanceHeader } from './PrevoyanceHeader';

import { includes, forEach, replace } from 'lodash';
import appUri from 'config/appUri.json';

export const PrevoyanceFormRouter = (props: any) => {
  const { apim, dossier, tab } = props;
  const { t, navigate } = apim.di();
  const appState = useAppState();

  const [loading, setLoading] = useState<boolean>(false);
  const [data, setData] = useState<any>(null);
  const [notFound, setNotFound] = useState<boolean>(false);
  const [errored, setErrored] = useState<boolean>(false);

  const [activeIndex, setActiveIndex] = useState<number>(0);
  const tabs: any[] = [];
  forEach(appUri.dos.edit.prevoyance.tabs, (ta: any) => {
    tabs.push({label: trans(t, 'menu|tab.' + ta), command: () => navigate(replace(appUri.dos.edit.prevoyance.uri, ':id', dossier?.id || '_') + '/' + ta)});
  });

  // Validate URL & build tabs.
  const checks = useCallback(() => {
    return isUrlValid(t, dossier?.id, tab);
  }, [dossier?.id, tab]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (!checks()) setNotFound(true);

    let ai = 0;
    forEach(appUri.dos.edit.prevoyance.tabs, ((ta: string, index: number) => {
      if (tab === ta) ai = index;
    }));

    if (ai !== activeIndex) setActiveIndex(ai);
  }, [dossier?.id, tab]); // eslint-disable-line react-hooks/exhaustive-deps

  const buildTabContent = useCallback(() => {
    switch (activeIndex) {
      case 1: return <PrevoyanceObligatoire apim={apim} data={data} dossier={dossier}/>;
      default: return <Prevoyance apim={apim} data={data} dossier={dossier}/>;
    }
  }, [dossier?.id, tab, activeIndex, data?.id]); // eslint-disable-line react-hooks/exhaustive-deps

  const resourceType = useMemo(() => {
    switch (tab) {
      case 'besoins-ressources': return 'prevoyances';
      case 'obligatoire': return 'prevoyancesObligatoires';
    }
  }, [tab]);

  useEffect(() => {
    setLoading(true);

    apim.fetchEntities({
      resourceType: 'prevoyances',
      params: [{label: 'dossier', value: dossier?.id}],
      cache: false,
      setErrored,
      success: (res: AxiosResponse) => {
        if (!res?.data || !res?.data['hydra:member'] || res?.data['hydra:member'].length === 0) {
          // Let's create a prevoyance data for this dossier.
          apim.postEntity({
            resourceType: 'prevoyances',
            data: {
              dossier: iri('dossiers', dossier?.id),
              year: new Date().getFullYear(),
            },
            notif: false,
            setErrored,
            setLoading,
            success: (resD: AxiosResponse) => {
              if (resourceType === 'prevoyancesObligatoires') {
                // Let's create a prevoyance obligatoire for this prevoyance.
                apim.postEntity({
                  resourceType: 'prevoyancesObligatoires',
                  data: { prevoyance: iri('prevoyances', resD?.data?.id) },
                  notif: false,
                  setErrored,
                  setter: setData
                } as IRequestParams).then();
              } else {
                setData(resD?.data);
                if (!isValidUUID(appState.prevoyance()) && isValidUUID(resD?.data?.id)) appState.setPrevoyance(resD?.data?.id);
                appState.setPrevoyances([resD?.data]);
              }
            }
          } as IRequestParams).then();
        } else {
          appState.setPrevoyances(res?.data['hydra:member']);
          if (!isValidUUID(appState.prevoyance())) appState.setPrevoyance(res.data['hydra:member'][0].id);

          apim.fetchEntity({
            resourceType: 'prevoyances',
            id: appState.prevoyance() ?? res.data['hydra:member'][0].id,
            cache: false,
            setErrored,
            setLoading,
            success: (resD: AxiosResponse) => {
              if (resourceType === 'prevoyancesObligatoires') {
                if (isValidUUID(resD?.data?.prevoyanceObligatoire?.id)) {
                  // Use existing prevoyance obligatoire.
                  setData(resD?.data?.prevoyanceObligatoire);
                } else {
                  // Find or create a prevoyance obligatoire.
                  if (resourceType === 'prevoyancesObligatoires') {
                    apim.fetchEntities({
                      resourceType: 'prevoyancesObligatoires',
                      params: [{ label: 'prevoyance', value: iri('prevoyances', resD?.data?.id) }],
                      cache: false,
                      setErrored,
                      success: (resPO: AxiosResponse) => {
                        if (!resPO?.data || !resPO?.data['hydra:member'] || resPO?.data['hydra:member'].length === 0) {
                          apim.postEntity({
                            resourceType: 'prevoyancesObligatoires',
                            data: { prevoyance: iri('prevoyances', resD?.data?.id) },
                            notif: false,
                            setErrored,
                            success: (resD: AxiosResponse) => setData(resD?.data)
                          } as IRequestParams).then();
                        } else {
                          setData(resPO?.data['hydra:member'][0]);
                        }
                      }
                    } as IRequestParams).then();
                  }
                }
              } else {
                setData(resD?.data);
              }
            }
          } as IRequestParams).then();
        }
      }
    } as IRequestParams).then();

  }, [dossier?.id, tab, appState.prevoyance()]); // eslint-disable-line react-hooks/exhaustive-deps

  // Update active index & Page Header.
  useEffect(() => {
    appState.setBreadcrumb([
      isClient()
       ? { label: trans(t, 'dossier', 2) }
       : { label: trans(t, 'dossier', 2), to: appUri.dos.list },
      { label: dossier?.title, to: appUri.dos.page.replace(':id', dossier?.id) },
      { label: trans(t, 'editing'), to: appUri.dos.edit.landing.replace(':id', dossier?.id) },
      { label: ucfirst(trans(t, 'menu|module.prevoyance')), to: appUri.dos.edit.prevoyance.uri.replace(':id', dossier?.id) },
      { label: ucfirst(trans(t, 'menu|tab.' + tab)) }
    ]);
  }, [dossier?.id, tab]); // eslint-disable-line react-hooks/exhaustive-deps

  if (notFound) return <NotFound asBlock/>;
  if (errored) return <Error asBlock/>;
  if (loading) return <PageLoader/>;

  return (
    <div className={'card height-100 fadein animation-duration-500 flex flex-column'}>
      <div className={'a8-page-header flex flex-wrap'}>
        <PrevoyanceHeader apim={apim} appState={appState} setLoading={setLoading} setErrored={setErrored}/>

        <div className={'flex-auto flex p-2 justify-content-end'}>
          {tabs.length > 1 && <TabMenu model={tabs} activeIndex={activeIndex} onTabChange={(e) => setActiveIndex(e.index)}/>}
        </div>
      </div>

      {buildTabContent()}
    </div>
  );
};

const isUrlValid = (t: TFunction, id: string | undefined, tab: string | undefined) => {
  // Ensure valid entity UUID & existing tab.
  return isValidUUID(id) && includes([...[undefined], ...appUri.dos.edit.prevoyance.tabs], tab);
};
