import React, { useCallback, useEffect } from 'react';
import { AxiosResponse } from 'axios';

import { Button } from 'primereact/button';
import { Column } from 'primereact/column';

import { FormWrapper } from 'forms';
import { isValidUUID, iri, montant, trans, triggerFormSubmit } from 'utilities';
import { amountEditor, DatatableWrapper, numberEditor } from 'components';
import { IRequestParams } from 'services';

import appUri from 'config/appUri.json';

export const CPIncapacite = (props: any) => {
  const { apim, urls, additionalData, data, updateData } = props;
  const { t, navigate } = apim.di();

  // Ensure contract data.
  useEffect(() => {
    if (!isValidUUID(data?.id)) return navigate(urls?.form + appUri.dos.edit.contracts.prevoyance.step11);
  }, [data?.id]); // eslint-disable-line react-hooks/exhaustive-deps

  const cancelUri: string = urls?.landing + '/prevoyance/contrats';
  const amountBody = (rowData: any) => {
    if (rowData?.amount === undefined || rowData?.amount === null) {
      return 'N/A';
    }

    return <span>{montant(rowData?.amount)}</span>;
  };
  const startBody = (rowData: any) => {
    if (rowData?.startDay === undefined || rowData?.startDay === null) {
      return 'N/A';
    }

    return <span>{rowData?.startDay + ' ' + trans(t, 'day', rowData?.startDay && rowData?.startDay > 0 ? rowData?.startDay : 1)}</span>;
  };

  const endBody = (rowData: any) => {
    if (rowData?.endDay === undefined || rowData?.endDay === null) {
      return 'N/A';
    }

    return <span>{rowData?.endDay + ' ' + trans(t, 'day', rowData?.endDay ?? 1)}</span>;
  };

  const resourceType: string = 'prevoyanceDailyIndemnities';
  const onNewRow = (_rows: any[], callback: any) => {
    if (_rows.length === 0) {
      apim.postEntity({
        resourceType: resourceType,
        data: {
          startDay: 0,
          endDay: 31,
          amount: 100,
          contractData: iri('prevoyanceContractsData', data?.data?.id)
        },
        success: (res: AxiosResponse) => callback([res.data])
      } as IRequestParams).then();

      return;
    }

    apim.postEntity({
      resourceType: resourceType,
      data: {
        startDay: parseInt(_rows[_rows.length - 1].endDay),
        endDay: parseInt(_rows[_rows.length - 1].endDay) + 365,
        amount: _rows[_rows.length - 1].amount,
        contractData: iri('prevoyanceContractsData', data?.data?.id)
      },
      success: (res: AxiosResponse) => callback([..._rows, ...[res.data]])
    } as IRequestParams).then();
  };

  // Wrap form render into a useCallback to avoid multiple FormWrapper recalls du to form fields updates.
  const renderForm = useCallback(() =>
      <FormWrapper classes={'grid pt-3'} formKeyPrefix={'contrat_cp_incapacite'} resourceType={'prevoyanceContractsData'}
                   data={{...data?.data, ...{type: data?.type}}} additionalData={additionalData} setData={updateData}
                   hideReload cancelLink redirectUri={urls?.form + (data?.type === 'frais_generaux' ? appUri.dos.edit.contracts.prevoyance.step34 : appUri.dos.edit.contracts.prevoyance.step33)} multiple />
    , [data?.data?.id]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div className={'fadein animation-duration-500'}>
      <h5 className={'mx-3 pt-3 flex flex-row align-items-center'}>
        <span className={'mr-2 border-circle bg-primary flex align-items-center justify-content-center'} style={{width: '2rem', height: '2rem'}}><i className={'pi pi-stop-circle'}></i></span>
        {trans(t, 'form|title.contract.prevoyance.step32')}
      </h5>

      {renderForm()}

      <div className={'px-2'}>
        <DatatableWrapper
          resourceType={resourceType} tableKey={'prevoyance-tableau-indemnites'} noFilters noGlobalFilter
          additionalData={{ contractData: iri('prevoyanceContractsData', data?.data?.id) }}
          onNew={onNewRow} params={[{label: 'contractData', value: data?.data?.id}]}
          title={trans(t, data?.type === 'frais_generaux' ? 'table|prevoyanceDailyIndemnitiesFGTitle' : 'table|prevoyanceDailyIndemnitiesTitle')} editMode paginator={false} parentClasses={[]}>
          <Column field={'amount'} header={trans(t, 'table|prevoyance_ij')} align={'center'} alignHeader={'center'} body={amountBody}
                  editor={(options) => amountEditor(options, { label: trans(t, 'table|prevoyance_ij') })} />
          <Column field={'startDay'} header={trans(t, 'table|prevoyance_ij_start')} align={'center'} alignHeader={'center'} body={startBody}
                  editor={(options) => numberEditor(options, { label: trans(t, 'table|prevoyance_ij_start'), suffix: ' ' + trans(t, 'day', 2) })} />
          <Column field={'endDay'} header={trans(t, 'table|prevoyance_ij_end')} align={'center'} alignHeader={'center'} body={endBody}
                  editor={(options) => numberEditor(options, { label: trans(t, 'table|prevoyance_ij_end'), suffix: ' ' + trans(t, 'day', 2) })} />
        </DatatableWrapper>
      </div>

      <div className={'text-right m-2 pt-4'}>
        <Button type={'reset'} className={'text-ucfirst p-button-text p-button-danger mr-5'} label={trans(t, 'close')} onClick={() => navigate(cancelUri)} />
        <span className={'p-buttonset'}>
          <Button type={'submit'} className={'text-ucfirst p-button-text'} label={trans(t, 'back')} icon={'pi pi-arrow-left'} onClick={() => navigate(urls?.form + appUri.dos.edit.contracts.prevoyance.step31)} />
          <Button type={'submit'} className={'text-ucfirst'} label={trans(t, 'save')} icon={'pi pi-arrow-right'} iconPos={'right'} onClick={() => triggerFormSubmit(null)} />
        </span>
      </div>
    </div>
  );
};
