import React from 'react';

import { Column } from 'primereact/column';

import { isValidUUID, trans, ucfirst } from 'utilities';
import { DatatableWrapper, amountCell, companyCell, personCell, validCell, numberEditor, simpleCheckboxEditor } from 'components';
import { isAdmin } from 'services';

import appUri from 'config/appUri.json';

export const RepartitionCapitalDatatable = (props: any) => {
  const { rows, tableKey, title, editMode, params, apim, header, onRefresh } = props;
  const { t } = apim.di();

  const titleBodyTemplate = (rowData: any) => {
    if (isValidUUID(rowData?.personnePhysique?.id)) return personCell(rowData.personnePhysique, {
      url: appUri.cie.per.phy.page.replace(':id', rowData.personnePhysique.id),
      label: trans(t, 'seeDetails')
    });

    if (isValidUUID(rowData?.dirigeantPersonnePhysique?.personnePhysique?.id)) return personCell(rowData.dirigeantPersonnePhysique.personnePhysique, {
      url: appUri.cie.per.phy.page.replace(':id', rowData.dirigeantPersonnePhysique.personnePhysique.id),
      label: trans(t, 'seeDetails')
    });

    if (isValidUUID(rowData?.dirigeantPersonneMorale?.company?.id)) return companyCell(rowData.dirigeantPersonneMorale.company, t);
    if (isValidUUID(rowData?.company?.id)) return companyCell(rowData.company, t);

    return <span>{rowData.name ?? ucfirst(trans(t, 'thirdParty'))}</span>;
  };
  const activeBodyTemplate = (rowData: any) => validCell(rowData?.active);
  const percentPartsBodyTemplate = (rowData: any) => amountCell(rowData.partTitresPleinePropriete, {symbol: '%', precision: 2});
  const percentParts2BodyTemplate = (rowData: any) => amountCell(rowData.partTitresNuePropriete, {symbol: '%', precision: 2});
  const percentParts3BodyTemplate = (rowData: any) => amountCell(rowData.partTitresUsufruit, {symbol: '%', precision: 2});

  if (editMode) {
    // Editable datatable.
    return (
      <>
        <DatatableWrapper resourceType={'associates'} tableKey={tableKey || 'associates'} noFilters noGlobalFilter params={params} editMode={editMode || false}
                          paginator={false} title={title || ucfirst(trans(t, 'beneficiary', 2))} requestHeader={header} onRefresh={onRefresh}
                          rows={rows || []} noAdd noRemove editFields={['active', 'nbTitresPleinePropriete', 'nbTitresNuePropriete', 'nbTitresUsufruit']}>
          <Column field={'nom'} header={trans(t, 'lastName')} body={titleBodyTemplate} />
          <Column field={'nbTitresPleinePropriete'} header={trans(t, 'table|nbTitresPleinePropriete')} align={'center'} alignHeader={'center'}
                  editor={(options) => numberEditor(options, {label: trans(t, 'table|nbTitresPleinePropriete')})} />
          <Column field={'partTitresPleinePropriete'} header={'%'} body={percentPartsBodyTemplate} align={'center'} alignHeader={'center'} />
          <Column field={'nbTitresNuePropriete'} header={trans(t, 'table|nbTitresNuePropriete')} align={'center'} alignHeader={'center'}
                  editor={(options) => numberEditor(options, {label: trans(t, 'table|nbTitresNuePropriete')})} />
          <Column field={'partTitresNuePropriete'} header={'%'} body={percentParts2BodyTemplate} align={'center'} alignHeader={'center'} />
          <Column field={'nbTitresUsufruit'} header={trans(t, 'table|nbTitresUsufruit')} align={'center'} alignHeader={'center'}
                  editor={(options) => numberEditor(options, {label: trans(t, 'table|nbTitresUsufruit')})} />
          <Column field={'partTitresUsufruit'} header={'%'} body={percentParts3BodyTemplate} align={'center'} alignHeader={'center'} />
          {isAdmin() && <Column field={'active'} header={trans(t, 'active')} body={activeBodyTemplate} align={'center'} alignHeader={'center'}
                                editor={(options) => simpleCheckboxEditor(options, {label: trans(t, 'table|active')})} />}
        </DatatableWrapper>
      </>
    );
  }

  // Non editable datatable.
  return (
    <>
      <DatatableWrapper resourceType={'associates'} tableKey={tableKey || 'associates'} noFilters noGlobalFilter params={params}
                        paginator={false} title={title || ucfirst(trans(t, 'beneficiary', 2))}
                        rows={rows || []} noAdd noRemove>
        <Column field={'nom'} header={trans(t, 'lastName')} body={titleBodyTemplate} />
        <Column field={'nbTitresPleinePropriete'} header={trans(t, 'table|nbTitresPleinePropriete')} align={'center'} alignHeader={'center'}/>
        <Column field={'partTitresPleinePropriete'} header={'%'} body={percentPartsBodyTemplate} align={'center'} alignHeader={'center'} />
        <Column field={'nbTitresNuePropriete'} header={trans(t, 'table|nbTitresNuePropriete')} align={'center'} alignHeader={'center'}/>
        <Column field={'partTitresNuePropriete'} header={'%'} body={percentParts2BodyTemplate} align={'center'} alignHeader={'center'} />
        <Column field={'nbTitresUsufruit'} header={trans(t, 'table|nbTitresUsufruit')} align={'center'} alignHeader={'center'}/>
        <Column field={'partTitresUsufruit'} header={'%'} body={percentParts3BodyTemplate} align={'center'} alignHeader={'center'} />
        {isAdmin() && <Column field={'active'} header={trans(t, 'active')} body={activeBodyTemplate} align={'center'} alignHeader={'center'}/>}
      </DatatableWrapper>
    </>
  );
};
