import React, {useEffect, useState} from 'react';

import { Skeleton } from 'primereact/skeleton';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';

import { formatListItem, trans } from 'utilities';
import { IndicatorSimple } from 'components';
import { getIndicator } from 'pages';

import { IRequestParams } from 'services';

export const Invalidite = (props: any) => {
  const { data, apim } = props;
  const { t } = apim.di();

  const [loading, setLoading] = useState<boolean>(false);
  const [dirigeantStatus, setDirigeantStatus] = useState<any[]>([]);

  useEffect(() => {
    apim.getList('clientStatus', {setter: setDirigeantStatus, setLoading} as IRequestParams).then();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const displayStatuses = (statuses: any) => {
    if (statuses === null || statuses === undefined) {
      return 'N/A';
    }

    return <ul className={'p-0 m-0 list-none ' + (statuses.length > 1 ? 'text-base line-height-1' : '')}>
      {statuses.map((status: any, index: number) => (
        <li key={index}>
          {formatListItem(status, dirigeantStatus)}
        </li>
      ))}
    </ul>
  }

  return (
    <>
      <div className={'grid w-full py-0'}>
        <div className={'col-2'}>
          <IndicatorSimple color={'primary'} bgImage title={trans(t, 'chart|prevoyance.incapacite.remunerationCouverte')} value={getIndicator(data, 'prevoyance.incapacite.remunerationCouverte').value}
                           tooltip={getIndicator(data, 'prevoyance.incapacite.remunerationCouverte').options?.tooltip ? trans(t, 'chart|' + getIndicator(data, 'prevoyance.incapacite.remunerationCouverte').options?.tooltip) : null} />
        </div>

        <div className={'col-2'}>
          <IndicatorSimple color={'accent3'} bgImage title={trans(t, 'chart|prevoyance.incapacite.prelevementTotalNetReel')} value={getIndicator(data, 'prevoyance.incapacite.prelevementTotalNetReel').value}
                           tooltip={getIndicator(data, 'prevoyance.incapacite.prelevementTotalNetReel').options?.tooltip ? trans(t, 'chart|' + getIndicator(data, 'prevoyance.incapacite.prelevementTotalNetReel').options?.tooltip) : null} />
        </div>

        <div className={'col'}>
          {loading ? (
            <Skeleton />
          ) : (
            <IndicatorSimple color={'accent2'} bgImage method={displayStatuses} title={trans(t, 'chart|prevoyance.incapacite.status')} value={getIndicator(data, 'prevoyance.incapacite.status').value}
                             tooltip={getIndicator(data, 'prevoyance.incapacite.status').options?.tooltip ? trans(t, 'chart|' + getIndicator(data, 'prevoyance.incapacite.status').options?.tooltip) : null} />
          )}
        </div>
      </div>

      <div className={'grid w-full py-3'}>
        <div className={'col'}>
          <DataTable value={data?.tableInvalidite?.data} className={'datatable-responsive'} stripedRows={true}>
            {(data?.tableInvalidite?.headers || []).map((h: string, i: number) => (
              <Column key={i} header={h} field={'column-' + (i + 1)} align={0 === i ? 'left' : 'right'} bodyStyle={0 === i ? {} : {fontWeight: 600}} alignHeader={0 === i ? 'left' : 'right'} />
            ))}
          </DataTable>
        </div>
      </div>

      <div className={'grid w-full py-0'}>
        <div className={'col'}>
          <IndicatorSimple color={'accent2'} bgImage title={trans(t, 'chart|prevoyance.incapacite.chargesCourantes')} value={getIndicator(data, 'prevoyance.incapacite.chargesCourantes').value}
                           tooltip={getIndicator(data, 'prevoyance.incapacite.chargesCourantes').options?.tooltip ? trans(t, 'chart|' + getIndicator(data, 'prevoyance.incapacite.chargesCourantes').options?.tooltip) : null} />
        </div>

        <div className={'col'}>
          <IndicatorSimple color={'primary'} bgImage title={trans(t, 'chart|prevoyance.incapacite.chargesFixes')} value={getIndicator(data, 'prevoyance.incapacite.chargesFixes').value}
                           tooltip={getIndicator(data, 'prevoyance.incapacite.chargesFixes').options?.tooltip ? trans(t, 'chart|' + getIndicator(data, 'prevoyance.incapacite.chargesFixes').options?.tooltip) : null} />
        </div>

        <div className={'col'}>
          <IndicatorSimple color={'accent3'} bgImage title={trans(t, 'chart|prevoyance.incapacite.ressourcesTravailActif')} value={getIndicator(data, 'prevoyance.incapacite.ressourcesTravailActif').value}
                           tooltip={getIndicator(data, 'prevoyance.incapacite.ressourcesTravailActif').options?.tooltip ? trans(t, 'chart|' + getIndicator(data, 'prevoyance.incapacite.ressourcesTravailActif').options?.tooltip) : null} />
        </div>

        <div className={'col'}>
          <IndicatorSimple color={'accent'} bgImage title={trans(t, 'chart|prevoyance.incapacite.ressourcesCapitalOuTravailPassif')} value={getIndicator(data, 'prevoyance.incapacite.ressourcesCapitalOuTravailPassif').value}
                           tooltip={getIndicator(data, 'prevoyance.incapacite.ressourcesCapitalOuTravailPassif').options?.tooltip ? trans(t, 'chart|' + getIndicator(data, 'prevoyance.incapacite.ressourcesCapitalOuTravailPassif').options?.tooltip) : null} />
        </div>
      </div>
    </>
  );
};
