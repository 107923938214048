import { hookstate, useHookstate, State } from '@hookstate/core';
import { unState } from './index';

import { asDate, isValidUUID } from 'utilities';
import { forEach, indexOf } from 'lodash';
import moment from 'moment';

interface IAppState {
  dossier: any[];
  module: string | null;
  modules: any[];
  company: string | null;
  exercise: string | null;
  civilYear: string | null;
  event: string | null;
  mission: string | null;
  patrimoine: string | null;
  patrimoines: any[];
  prevoyance: string | null;
  prevoyances: any[];
  breadcrumb: any[];
  pageTitle: string | null;
  pageActions: any[];
}

const emptyState: IAppState = {
  dossier: [],
  module: null,
  modules: [],
  company: null,
  exercise: null,
  civilYear: null,
  event: null,
  mission: null,
  patrimoine: null,
  patrimoines: [],
  prevoyance: null,
  prevoyances: [],
  breadcrumb: [],
  pageTitle: null,
  pageActions: []
} as unknown as IAppState;
const state: State<IAppState> = hookstate(Object.assign({}, emptyState));

const getDossier = (s: State<IAppState>) => {
  return s.dossier.get().length > 0 ? s.dossier.get({ noproxy: true })[0] : null;
};

const getCompany = (s: State<IAppState>) => {
  const dossier = getDossier(s);
  if (!dossier || !dossier.companies || dossier.companies.length < 1) return null;
  if (dossier.companies.length === 1) return unState(dossier.companies[0].company);

  const currentCompany = s.company.get();
  if (currentCompany) {
    // Check VERSION first (company edit form).
    const checkVersion: any[] = ([].concat(dossier.companies)).filter((c: any) => c?.company?.latestVersion?.id === currentCompany);
    if (checkVersion.length > 0) return unState(checkVersion[0].company);
    // Then check ROOT company id.
    const checkRoot: any[] = ([].concat(dossier.companies)).filter((c: any) => c?.company?.id === currentCompany);
    if (checkRoot.length > 0) return unState(checkRoot[0].company);
  }

  const check: any[] = ([].concat(dossier.companies)).sort((a: any, b: any) => a?.company?.latestVersion?.raisonSociale > b?.company?.latestVersion?.raisonSociale ? 1 : -1);
  if (check.length === 0) return null;

  // If we didn't find until here, let's re-set the ID.
  s.company.set(check[0].company?.id);

  return unState(check[0].company);
};

const getExercise = (s: State<IAppState>) => {
  const company = getCompany(s);
  if (!company) {
    return null;
  }

  if (!company.exercicesFiscaux || company.exercicesFiscaux.length === 0) {
    return null;
  }

  if (company.exercicesFiscaux.length === 1) {
    return unState(company.exercicesFiscaux[0]);
  }

  let currentExercise: any = s.exercise.get();
  if (currentExercise === null) {
    return unState(company.exercicesFiscaux[0]);
  }

  currentExercise = parseInt(currentExercise);
  const check = company.exercicesFiscaux.filter((companyExerciceFiscal: any) => {
    return companyExerciceFiscal.year === currentExercise;
  });

  if (currentExercise && check.length > 0) {
    return unState(check[0]);
  }

  let exercice: any = null;
  forEach(company.exercicesFiscaux, (companyExerciceFiscal: any) => {
    if (!exercice || companyExerciceFiscal.year > exercice.year) exercice = companyExerciceFiscal;
  });

  return exercice;
};

const getExerciseDurationMonths = (s: State<IAppState>): number|null => {
  let exerciceFiscal: any = getExercise(s);
  if (exerciceFiscal === null) {
    return null;
  }

  return Math.round(moment(asDate(exerciceFiscal.end)).diff(moment(asDate(exerciceFiscal.start)), 'months', true));
};

const resetCurrentExercise = (s: State<IAppState>) => {
  const company = getCompany(s);

  // In this particular case just exit without reset.
  if (!company && s.company.get()) return;

  if (!company || !company.exercicesFiscaux || company.exercicesFiscaux.length < 1) return s.exercise.set(null);
  if (company.exercicesFiscaux.length === 1) return s.exercise.set(company.exercicesFiscaux[0].year.toString());

  // Let's keep the same year if possible.
  const compEF: string[] = ([].concat(company.exercicesFiscaux)).map((ef: any) => ef.year.toString()).sort((a: string, b: string) => a > b ? -1 : 1);
  if (-1 < indexOf(compEF, s.exercise.get())) return;

  // Try to get the current year.
  const check = ([].concat(company.exercicesFiscaux)).filter((cef: any) => cef.year === new Date().getFullYear());
  if (check.length > 0) return s.exercise.set((check[0] as any).year.toString());

  s.exercise.set(compEF[0].toString());
};

const getCivilYear = (s: State<IAppState>) => {
  const dossier = getDossier(s);

  if (!dossier || !dossier.civilYears || dossier.civilYears.length < 1) return new Date().getFullYear().toString();
  if (dossier.civilYears.length === 1) return unState(dossier.civilYears[0]).toString();

  const civilYear = s.civilYear.get();
  return civilYear ?
    civilYear :
    unState([].concat(dossier.civilYears)).sort((a: string, b: string) => a > b ? -1 : 1)[0].toString();
};

const resetCurrentCivilYear = (s: State<IAppState>) => {
  const dossier = getDossier(s);

  if (!dossier || !dossier.civilYears || dossier.civilYears.length < 1) return s.civilYear.set(null);
  if (dossier.civilYears.length === 1) return s.civilYear.set(dossier.civilYears[0]);

  const dosCY: string[] = ([].concat(dossier.civilYears)).map((y: number) => y.toString()).sort((a: string, b: string) => a > b ? -1 : 1);
  // Let's keep the same year if possible.
  if (-1 < indexOf(dosCY, s.civilYear.get())) return;

  // Try to get the current year.
  const check = dosCY.filter((y: string) => y === new Date().getFullYear().toString());
  if (check.length > 0) return s.civilYear.set(check[0]);

  s.civilYear.set(dosCY[0]);
};

const getEvent = (s: State<IAppState>) => {
  const company = getCompany(s);

  if (!company || !company.events || company.events.length < 1) return null;
  if (company.events.length === 1) return unState(company.events[0]);

  const event = s.event.get({ noproxy: true });
  return event ?
    unState(company.events.filter((e: any) => e.id === event)[0]) :
    unState([].concat(company.events)).sort((a: any, b: any) => a.date > b.date ? 1 : -1)[0];
};

const resetCurrentEvent = (s: State<IAppState>) => {
  const company = getCompany(s);

  if (!company || !company.events || company.events.length < 1) return s.event.set(null);
  if (company.events.length === 1) return s.event.set(company.events[0].id);

  s.event.set(([].concat(company.events)).sort((a: any, b: any) => a.date > b.date ? -1 : 1)[0]['id']);
};

const getMission = (s: State<IAppState>) => {
  const dossier = getDossier(s);

  if (!dossier || !dossier.missions || dossier.missions.length < 1) return null;
  if (dossier.missions.length === 1) return dossier.missions[0];

  const mission = s.mission.get({ noproxy: true });
  return mission ?
    unState(dossier.missions.filter((e: any) => e.id === mission)[0]) :
    unState([].concat(dossier.missions)).sort((a: any, b: any) => a.updated > b.updated ? 1 : -1)[0];
};

const addMission = (s: State<IAppState>, mission: any) => {
  const dossier = getDossier(s);

  if (!dossier || !isValidUUID(mission?.id)) return null;

  const _missions: any[] = (dossier.missions ?? []).filter((_m: any) => _m.id !== mission?.id);
  _missions.push(mission);

  s.dossier.set({ ...dossier, missions: _missions });
  s.mission.set(mission.id);
};

const resetCurrentMission = (s: State<IAppState>) => {
  const dossier = getDossier(s);

  if (!dossier || !dossier.missions || dossier.missions.length < 1) return s.mission.set(null);
  if (dossier.missions.length === 1) return s.mission.set(dossier.missions[0].id);
  if (dossier.missions.some((mission: any) => mission.id ===  s.mission.get({ noproxy: true }))) return;

  s.mission.set(([].concat(dossier.missions)).sort((a: any, b: any) => a.updated > b.updated ? -1 : 1)[0]['id']);
};

const wrapper = (s: State<IAppState>) => ({
  dossier: () => getDossier(s),
  setDossier: (dossier: any) => {
    s.dossier.set([dossier]);

    // Do not forget to switch current company too!
    if (!dossier.companies || dossier.companies.length === 0) {
      s.company.set(null);
      s.exercise.set(null);
      s.civilYear.set(null);
      s.event.set(null);
      s.mission.set(null);
      return;
    }

    const check = dossier.companies.filter((c: any) => c.company?.id === s.company.get());
    if (check.length === 0 && dossier.companies.length > 0) {
      // Find the latest updated company.
      let latestUpdatedCompany = dossier.companies[0];
      let latestEvent = dossier.companies[0].company.events?.length > 0 ? dossier.companies[0].company.events[0].date : '0';

      forEach(dossier.companies, (c: any) => {
        forEach(c?.company.events ?? [], (ev: any) => {
          if (ev.date > latestEvent) {
            latestEvent = ev.date;
            latestUpdatedCompany = c;
          }
        });
      });

      s.company.set(latestUpdatedCompany.company?.id);
      // const mainCompany = dossier.companies.filter((mc: any) => mc.main);
      // s.company.set(mainCompany.length > 0 ? mainCompany[0].company?.id : dossier.companies[0].company?.id);
    }

    resetCurrentExercise(s);
    resetCurrentCivilYear(s);
    resetCurrentEvent(s);
    resetCurrentMission(s);
    s.patrimoine.set(null);
    s.patrimoines.set([]);
    s.prevoyance.set(null);
    s.prevoyances.set([]);
  },
  module: () => s.module.get(),
  setModule: (name: string|null) => s.module.set(name),
  modules: () => s.modules.get({ noproxy: true }),
  setModules: (_modules: any[]) => s.modules.set(_modules),
  company: () => getCompany(s),
  setCompany: (id: string) => {
    s.company.set(id);
    resetCurrentExercise(s);
    resetCurrentCivilYear(s);
    resetCurrentEvent(s);
    resetCurrentMission(s);
  },
  exerciseFull: (): string => getExercise(s),
  exerciseDurationMonths: (): number|null => getExerciseDurationMonths(s),
  exercise: (asInt: boolean | null = false) => asInt ? parseInt(s.exercise.get() ?? new Date().getFullYear().toString()) : (s.exercise.get() ?? new Date().getFullYear().toString()),
  setExercise: (year: string) => s.exercise.set(year),
  civilYear: (): string => getCivilYear(s),
  setCivilYear: (year: string) => s.civilYear.set(year),
  event: () => getEvent(s),
  setEvent: (id: string) => s.event.set(id),
  mission: () => getMission(s),
  addMission: (mission: any) => addMission(s, mission),
  setMission: (id: string | null) => s.mission.set(id),
  patrimoine: (asObject: boolean = false): string | any | null => {
    if (!asObject) return s.patrimoine.get();

    const match: any[] = (s.patrimoines.get() ?? []).filter((_p: any) => _p.id === s.patrimoine.get());

    return match.length > 0 ? match[0] : null;
  },
  patrimoines: () => ((s.patrimoines.get({ noproxy: true }) ?? []) as any[]).sort((a: any, b: any) => a.year > b.year ? -1 : 1),
  setPatrimoine: (id: string) => s.patrimoine.set(id),
  setPatrimoines: (p: any[]) => s.patrimoines.set(p),
  prevoyance: (asObject: boolean = false): string | any | null => {
    if (!asObject) return s.prevoyance.get();

    const match: any[] = (s.prevoyances.get() ?? []).filter((_p: any) => _p.id === s.prevoyance.get());

    return match.length > 0 ? match[0] : null;
  },
  prevoyances: () => ((s.prevoyances.get({ noproxy: true }) ?? []) as any[]).sort((a: any, b: any) => a.year > b.year ? -1 : 1),
  setPrevoyance: (id: string) => s.prevoyance.set(id),
  setPrevoyances: (p: any[]) => s.prevoyances.set(p),

  breadcrumb: () => s.breadcrumb.get(),
  setBreadcrumb: (bc: any[]) => s.breadcrumb.set(bc),
  pageTitle: () => s.pageTitle.get(),
  setPageTitle: (title: string) => s.pageTitle.set(title),
  pageActions: () => s.pageActions.get({ noproxy: true }),
  setPageActions: (actions: any[]) => s.pageActions.set(actions),

  state: () => s,
});

export const useAppState = () => wrapper(useHookstate(state));
