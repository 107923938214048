import React, { useRef, useState } from 'react';
import { CSSTransition } from 'react-transition-group';

import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { Sidebar } from 'primereact/sidebar';
import { ProgressSpinner } from 'primereact/progressspinner';
import { Divider } from 'primereact/divider';
import { ScrollPanel } from 'primereact/scrollpanel';

import { trans, ucfirst } from 'utilities';
import { IRequestParams } from 'services';
import { SearchResult } from 'components';

export const AppTopbarSearch = (props: any) => {
  const { searchState, apim } = props;
  const { t } = apim.di();
  const ref = useRef(null);

  const [visibleFullScreen, setVisibleFullScreen] = useState<boolean>(false);
  const [searchResultsLoading, setSearchResultsLoading] = useState<boolean>(false);
  const [searchResults, setSearchResults] = useState<any[]>([]);

  const onInputKeyUp = (event: any) => {
    const key = event.which;

    switch (key) {
      case 27:
        // Escape char : let's just close the panel.
        searchState.toggle();
        return;

      case 13:
        // Enter key : let's trigger the search!
        search();
        searchState.toggle();
        return;
    }

    // Otherwise, let's update search needle.
    searchState.setTerm(event.target.value);
  };

  const search = () => {
    const term = searchState.getTerm();

    setSearchResultsLoading(true);
    setVisibleFullScreen(true)

    apim.call({
      resourceType: 'search',
      method: 'get',
      id: term,
      params: [{label: 'itemsPerPage', value: 50}],
      setLoading: setSearchResultsLoading,
      setter: setSearchResults
    } as IRequestParams).then();
  }

  return (
    <>
      <button id={'search-btn'} className='layout-topbar-action rounded-circle p-link' onClick={() => searchState.toggle()}>
        <i id={'search-icn'} className='pi pi-search fs-large'></i>
      </button>
      <CSSTransition nodeRef={ref} classNames='p-toggleable' timeout={{ enter: 1000, exit: 450 }} in={searchState.isToggle()} unmountOnExit>
        <div ref={ref} className='layout-search-panel p-inputgroup'>
          <span className='p-inputgroup-addon'><i className='pi pi-search'></i></span>
          <InputText id='search-box' type='text' placeholder={trans(t, 'search').toUpperCase()} onKeyUp={onInputKeyUp} />
          <span className='p-inputgroup-addon'>
            <Button
              type='button'
              icon='pi pi-times'
              className='p-button-rounded p-button-text p-button-plain'
              onClick={() => searchState.toggle()}>
            </Button>
          </span>
        </div>
      </CSSTransition>
      <Sidebar content={''} visible={visibleFullScreen} className="w-full md:w-20rem lg:w-30rem" onHide={() => setVisibleFullScreen(false)}>
        {searchResultsLoading ? (
          <div className={'flex justify-content-center mt-8'}>
            <ProgressSpinner />
          </div>
        ) : (
          <>
            <h3>{ucfirst(trans(t, 'searchResult'))}</h3>
            <p className={'mb-3'}>{ucfirst(trans(t, 'yourSearch'))}: "<strong>{searchState.getTerm()}</strong>"</p>
            <Divider />
            <ScrollPanel>
              {searchResults.length === 0 ? (
                <p>{ucfirst(trans(t, 'searchNoResult'))}.</p>
              ) : (
                searchResults.map((searchResult: any, index: number) => (
                  <SearchResult key={index} data={searchResult} apim={apim} searchState={searchState} setVisibleFullScreen={setVisibleFullScreen} />
                ))
              )}
            </ScrollPanel>
          </>
        )}
      </Sidebar>
    </>
  );
};
