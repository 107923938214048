import React from 'react';
import { TriStateCheckbox } from 'primereact/tristatecheckbox';
import { UncontrolledBaseField } from './UncontrolledBaseField';

export const UncontrolledTriStateField = (props: any) => {
  const { fieldKey, value, onFieldChange, label, tooltip, tooltipOptions, description, classes, addon, addonPosition, disabled } = props;

  return (
    <UncontrolledBaseField classes={classes} addon={addon} addonPosition={addonPosition} description={description} >
      <TriStateCheckbox id={fieldKey} className={'text-ucfirst'} value={value} disabled={disabled}
                 onChange={(e) => onFieldChange(e.value)}
                 tooltip={tooltip} tooltipOptions={tooltipOptions || { position: 'top' }} />
      <label className={'text-ucfirst ml-2'} htmlFor={fieldKey}>{label}</label>
    </UncontrolledBaseField>
  );
};
