import { trans } from 'utilities';
import { isExpert, isClient, logout, isImpersonating, cancelImpersonation, userId } from 'services';

import appUri from 'config/appUri.json';
import { replace } from 'lodash';

export const UserMenu = (props: any) => {
  const { apim, userState } = props;
  const { t } = apim.di();
  const id = userId();
  const impersonating: boolean = isImpersonating();

  if (isExpert()) {
    return [
      {
        icon: 'id-card',
        label: trans(t, 'menu|menu.account'),
        to: replace(appUri.usr.page, ':id', id || '_')
      },
      {
        icon: 'user',
        label: trans(t, 'menu|menu.profile'),
        to: replace(appUri.usr.exp.page, ':id', id || '_')
      },
      {
        icon: impersonating ? 'users' : 'power-off',
        label: impersonating ? trans(t, 'menu|menu.cancelImpersonating') : trans(t, 'menu|menu.logout'),
        command: () => {
          if (impersonating) {
            cancelImpersonation(apim, userState);
          } else {
            logout(apim, userState);
          }
        }
      }
    ];
  }

  if (isClient()) {
    return [
      {
        icon: 'id-card',
        label: trans(t, 'menu|menu.account'),
        to: replace(appUri.usr.page, ':id', id || '_')
      },
      {
        icon: 'user',
        label: trans(t, 'menu|menu.profile'),
        to: replace(appUri.usr.dir.page, ':id', id || '_')
      },
      {
        icon: impersonating ? 'users' : 'power-off',
        label: impersonating ? trans(t, 'menu|menu.cancelImpersonating') : trans(t, 'menu|menu.logout'),
        command: () => {
          if (impersonating) {
            cancelImpersonation(apim, userState);
          } else {
            logout(apim, userState);
          }
        }
      }
    ];
  }

  return [
    {
      icon: 'id-card',
      label: trans(t, 'menu|menu.account'),
      to: replace(appUri.usr.page, ':id', id || '_')
    },
    {
      icon: impersonating ? 'users' : 'power-off',
      label: impersonating ? trans(t, 'menu|menu.cancelImpersonating') : trans(t, 'menu|menu.logout'),
      command: () => {
        if (impersonating) {
          cancelImpersonation(apim, userState);
        } else {
          logout(apim, userState);
        }
      }
    }
  ];
};
