import React, { useCallback, useEffect, useState } from 'react';
import { AxiosResponse } from 'axios';
import { Button } from 'primereact/button';

import { IRequestParams } from 'services';
import { PageLoader } from 'components';
import { FormWrapper } from 'forms';
import { trans, triggerFormSubmit } from 'utilities';
import { Error, NotFound } from 'pages';

import appUri from 'config/appUri.json';

export const DonneesSocialesDirigeant = (props: any) => {
  const { appState, apim, urls, additionalData } = props;
  const { t, navigate } = apim.di();

  const [loading, setLoading] = useState<boolean>(false);
  const [notFound, setNotFound] = useState<boolean>(false);
  const [errored, setErrored] = useState<boolean>(false);

  const dossier = appState.dossier();
  const year = appState.civilYear();

  const [dossierSocialData, setDossierSocialData] = useState<any>(null);
  const [keyTmp, setKeyTmp] = useState<string>('');
  const cancelUri: string = urls?.landing + '/entreprise/donnees-sociales-dirigeant?maj=' + Date.now();

  useEffect(() => {
    if (!dossier?.id) return;

    setLoading(true);
    apim.fetchEntities({
      resourceType: 'dossierSocialData',
      params: [
        {label: 'dossier', value: dossier?.id},
        {label: 'year', value: year}
      ],
      cache: false,
      setLoading: setLoading,
      setErrored: setErrored,
      setNotFound: setNotFound,
      success: (res: AxiosResponse) => {
        setKeyTmp('_' + dossier?.id + '_' + year);
        setDossierSocialData(null);

        if (res?.data && (res?.data['hydra:member'] || []).length > 0) {
          setDossierSocialData(res.data['hydra:member'][0]);
        }
      }
    } as IRequestParams).then();
  }, [dossier?.id, year]); // eslint-disable-line react-hooks/exhaustive-deps

  // Wrap form render into a useCallback to avoid multiple FormWrapper recalls du to form fields updates.
  const renderForm = useCallback(() =>
      <FormWrapper classes={'grid p-fluid w-12'} formKeyPrefix={'general'} resourceType={'dossierSocialData'} keyTmp={keyTmp} keepAlive
                   data={dossierSocialData} additionalData={additionalData} hideReload cancelLink multiple redirectUri={urls?.form + appUri.dos.edit.civilYear.steps.step2} />
    , [dossierSocialData?.id, keyTmp]); // eslint-disable-line react-hooks/exhaustive-deps

  if (!dossier?.id || loading) return <PageLoader />;
  if (notFound) return <NotFound asBlock/>;
  if (errored) return <Error asBlock/>;

  return (
    <div className={'flex flex-column xl:flex-row flex-wrap fadein animation-duration-500'}>
      {renderForm()}

      <div className={'w-full text-right m-2 mr-3 flex justify-content-end'}>
        <Button type={'reset'} className={'text-ucfirst p-button-text p-button-danger mr-5'} label={trans(t, 'close')} onClick={() => navigate(cancelUri)}/>
        <Button type={'submit'} className={'text-ucfirst'} label={trans(t, 'save')} icon={'pi pi-arrow-right'} iconPos={'right'} onClick={() => triggerFormSubmit(null)}/>
      </div>
    </div>
  );
};
