export { ContractsFormRouter } from './ContractsFormRouter';

export {
  ContexteForm,
  CGContratForm,
  CGIncapaciteForm,
  CGInvaliditeForm,
  CGOptionForm,
  CPContratForm,
  CPIncapaciteForm,
  CPInvaliditeForm,
  CPDecesForm,
  ValidationForm
} from './tabs';

export { getContractContext } from './ModuleHelper';
