import React from 'react';
import { trans } from 'utilities';
import { APIListField, MaskField, TextField } from 'forms';

export const PersonnePhysiqueForm = (props: any) => {
  const { t, control, onFieldChange, errorMessage } = props;

  return <>
    <APIListField listKey={'civilities'} fieldKey={'civility'} control={control} onFieldChange={onFieldChange} errorMessage={errorMessage} label={trans(t, 'civility')} />
    <TextField fieldKey={'lastName'} control={control} onFieldChange={onFieldChange} label={trans(t, 'lastName')}
               errorMessage={errorMessage} rules={{ required: trans(t, 'form|requiredField') }} />
    <MaskField fieldKey={'birthDate'} control={control} onFieldChange={onFieldChange} label={trans(t, 'birthDate')}
               errorMessage={errorMessage} rules={{ required: trans(t, 'form|requiredField') }} isDate />
    <TextField fieldKey={'email'} control={control} onFieldChange={onFieldChange} errorMessage={errorMessage} label={trans(t, 'email')} addon={'at'} />
    <TextField fieldKey={'phone1'} control={control} onFieldChange={onFieldChange} errorMessage={errorMessage} label={trans(t, 'phone1')} addon={'phone'} />
    <TextField fieldKey={'phone2'} control={control} onFieldChange={onFieldChange} errorMessage={errorMessage} label={trans(t, 'phone2')} addon={'phone'} />
  </>
};
