import React, { useEffect, useMemo, useState } from 'react';
import { Link } from 'react-router-dom';
import { Fieldset } from 'primereact/fieldset';
import { Chip } from 'primereact/chip';

import { Error, NotFound } from 'pages';
import { AddressField, IndicatorSimple, ManagedImage, PageLoader, ProprietairesDatatable, SimpleText, Userpicture } from 'components';
import { IRequestParams, isClient } from 'services';
import { dateString, isValidUUID, trans, ucfirst } from 'utilities';

import appUri from 'config/appUri.json';

export const PatrimoineBien = (props: any) => {
  const { apim, appState, type, id } = props;
  const { t} = apim.di();
  const dossier: any = appState?.dossier();

  const [loading, setLoading] = useState<boolean>(false);
  const [data, setData] = useState<any>();
  const [bank, setBank] = useState<any>();
  const [types, setTypes] = useState<any[]>([]);
  const [familles, setFamilles] = useState<any[]>([]);
  const [notFound, setNotFound] = useState<boolean>(false);
  const [errored, setErrored] = useState<boolean>(false);
  const [companyVersion, setCompanyVersion] = useState<any>(null);

  const getResourceType = useMemo(() => {
    switch (type) {
      case 'bancaire': return 'biensBancaires';
      case 'entreprise': return 'biensEntreprises';
      case 'immobilier': return 'biensImmobiliers';
      default: return 'biensDivers';
    }
  }, [dossier?.id, type, id]); // eslint-disable-line react-hooks/exhaustive-deps

  const getBienType = useMemo(() => ucfirst(trans(t, 'table|patrimoine_bien.' + type))
    , [dossier?.id, type, id]); // eslint-disable-line react-hooks/exhaustive-deps

  const getType = useMemo(() => {
    const match: any = types.filter((_t: any) => _t.value === data?.type);

    return match.length > 0 ? match[0] : null;
  }, [dossier?.id, type, id, types, data]); // eslint-disable-line react-hooks/exhaustive-deps

  const getFamilyList = useMemo(() => {
    switch (type) {
      case 'bancaire': return getType?.value === 'liquidite' ? 'bienBancaireLiquiditeFamilles' : 'bienBancaireValeurMobiliereFamilles';
      case 'immobilier': return getType?.value === 'jouissance' ? 'bienImmobilierJouissanceFamilles' : 'bienImmobilierRapportFamilles';
      default: return null;
    }
  }, [dossier?.id, type, getType, id]); // eslint-disable-line react-hooks/exhaustive-deps

  const getFamily = useMemo(() => {
    const match: any = familles.filter((_f: any) => _f.value === data?.family);

    return match.length > 0 ? match[0].translated : '';
  }, [dossier?.id, type, id, familles, data]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (type !== 'divers') {
      apim.getList('bien' + ucfirst(type) + 'Types', {setter: setTypes} as IRequestParams).then();
    }

    if (getFamilyList) {
      // @TODO bug here with bank bien - the list is always valeurs mobilieres
      apim.getList(getFamilyList, {setter: setFamilles} as IRequestParams).then();
    }
  }, [type, getFamilyList]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => fetchData(), [dossier?.id, id]); // eslint-disable-line react-hooks/exhaustive-deps
  useEffect(() => {
    if (isValidUUID(data?.company?.version?.id)) {
      setCompanyVersion(data?.company?.version);
    } else if (isValidUUID(data?.company?.latestVersion?.id)) {
      setCompanyVersion(data?.company?.latestVersion);
    }
  }, [data]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (isValidUUID(data?.bank)) {
      fetchBank(data?.bank);
    }
  }, [data]); // eslint-disable-line react-hooks/exhaustive-deps

  const fetchData = () => {
    if ((data?.id === id) || !isValidUUID(id)) return;

    setLoading(true);
    apim.fetchEntity({
      resourceType: getResourceType,
      id: id,
      setLoading,
      setErrored,
      setNotFound,
      setter: setData
    } as IRequestParams).then();
  }

  const fetchBank = (bankId: any) => {
    if (!isValidUUID(bankId)) return;

    apim.fetchEntity({
      resourceType: 'banks',
      id: bankId,
      setter: setBank
    } as IRequestParams).then();
  }

  // Override Page Header (defaults into ModuleWrapper, then PatrimoineRouter).
  useEffect(() => {
    appState.setBreadcrumb([
      isClient()
        ? { label: trans(t, 'dossier', 2) }
        : { label: trans(t, 'dossier', 2), to: appUri.dos.list },
      { label: dossier.title, to: appUri.dos.page.replace(':id', dossier?.id) },
      { label: trans(t, 'menu|menu.schema_flux.patrimoine_biens'), to: appUri.dos.page.replace(':id', dossier?.id) + '/patrimoine/biens' },
      { label: data?.label ?? data?.company?.latestVersion?.raisonSociale ?? trans(t, 'bien') }
    ]);
  }, [dossier?.id, id, type, data?.id]); // eslint-disable-line react-hooks/exhaustive-deps

  if (notFound) return <NotFound asBlock/>;
  if (errored) return <Error asBlock/>;
  if (loading) return <PageLoader/>;

  return (
    <div className={'flex flex-column md:flex-row flex-wrap fadein animation-duration-500 mb-5'}>

      <div className={'flex w-12 md:w-5 py-4 pl-2 pr-3'}>
        <IndicatorSimple color={'primary'} dark bgImage title={trans(t, 'form|patrimoine.biens.default.label')} text={data?.label}/>
      </div>

      <div className={'flex w-12 md:w-3 py-4 pl-2 pr-3'}>
        <IndicatorSimple color={'primary'} bgImage title={trans(t, 'form|patrimoine.biens.default.type')} text={getBienType}/>
      </div>

      {type === 'divers' && (
        <div className={'flex w-12 md:w-4 py-4 pl-2 pr-3'}>
          <IndicatorSimple color={'accent4'} bgImage title={trans(t, 'form|patrimoine.biens.default.family')} text={data?.family}/>
        </div>
      )}

      {type !== 'divers' && (
        <div className={'flex w-12 md:w-4 py-4 pl-2 pr-3'}>
          <IndicatorSimple color={'accent4'} bgImage title={trans(t, 'type')} text={getType?.translated}/>
        </div>
      )}
      <Fieldset className={'my-5 w-12'} legend={ucfirst(trans(t, 'general'))} toggleable>
        <div className={'flex flex-column md:flex-row px-3'}>
          {getFamilyList ? (
            <div className={'flex flex-grow-1 py-4 px-3'}>
              <SimpleText title={trans(t, 'form|patrimoine.biens.default.family')} text={getFamily}/>
            </div>
          ) : (
            <>
              {type === 'entreprise' && (
                <div className={'flex flex-grow-1 py-4 px-3'}>
                  <SimpleText title={trans(t, 'form|patrimoine.biens.entreprise.categorieRevenus')} value={data?.categorieRevenus} method={'list'} methodParams={{listKey: 'categoriesRevenus'}}/>
                </div>
              )}
            </>
          )}
          {type === 'bancaire' ? (
            <>
              <div className={'flex flex-grow-1 py-4 px-3'}>
                <SimpleText title={trans(t, 'form|patrimoine.biens.bancaire.bank')} text={bank?.name}/>
              </div>
              <div className={'flex flex-grow-1 py-4 px-3'}>
                <SimpleText title={trans(t, 'form|patrimoine.biens.default.opening')} value={data?.openingDate} method={'date'}/>
              </div>
              <div className={'flex flex-grow-1 py-4 px-3'}>
                <SimpleText title={trans(t, 'form|patrimoine.biens.default.echeance')} value={data?.closingDate} method={'date'}/>
              </div>
            </>
          ) : (
            <div className={'flex flex-grow-1 py-4 px-3'}>
              <SimpleText title={trans(t, 'form|patrimoine.biens.default.date')} value={data?.purchaseDate} method={'date'}/>
            </div>
          )}

          <div className={'flex flex-grow-1 py-4 px-3'}>
            <SimpleText title={trans(t, 'form|patrimoine.biens.default.montant')} value={data?.montantPleinePropriete} />
          </div>

          {data?.montantPleineProprieteImmobilier && (
            <div className={'flex flex-grow-1 py-4 px-3'}>
              <SimpleText title={trans(t, 'form|patrimoine.biens.default.montantPleineProprieteImmobilier')} value={data?.montantPleineProprieteImmobilier} />
            </div>
          )}
          {type === 'bancaire' && (
            <div className={'flex flex-grow-1 py-4 px-3'}>
              <SimpleText title={trans(t, 'form|patrimoine.biens.bancaire.epargne')} value={data?.montantVersementsAnnuels} />
            </div>
          )}
        </div>
      </Fieldset>
      <Fieldset className={'my-5 w-12'} legend={ucfirst(trans(t, 'fiscal'))} toggleable>
        {type === 'bancaire' && (
          <div className={'flex flex-grow-1 py-4 px-3'}>
            <SimpleText title={trans(t, 'form|patrimoine.biens.bancaire.interets')} value={data?.montantInteretsAnnuels} />
          </div>
        )}
        <div className={'flex flex-column md:flex-row px-3'}>
          {type === 'entreprise' && (
            <div className={'flex flex-grow-1 py-4 px-3'}>
              <SimpleText title={trans(t, 'form|patrimoine.biens.entreprise.regimeImposition')} value={data?.regimeImposition} method={'list'} methodParams={{listKey: 'regimesImposition'}}/>
            </div>
          )}
          {type === 'immobilier' && (
            <>
              <div className={'flex flex-grow-1 py-4 px-3'}>
                <SimpleText title={trans(t, 'form|patrimoine.biens.immobilier.montantTaxeFonciere')} value={data?.montantTaxeFonciere} />
              </div>
              <div className={'flex flex-grow-1 py-4 px-3'}>
                <SimpleText title={trans(t, 'form|patrimoine.biens.immobilier.montantTaxeHabitation')} value={data?.montantTaxeHabitation} />
              </div>
              <div className={'flex flex-grow-1 py-4 px-3'}>
                <SimpleText title={trans(t, 'form|patrimoine.biens.immobilier.montantRevenuBrut')} value={data?.montantRevenuBrut} />
              </div>
              <div className={'flex flex-grow-1 py-4 px-3'}>
                <SimpleText title={trans(t, 'form|patrimoine.biens.immobilier.montantRevenuImposable')} value={data?.montantRevenuImposable} />
              </div>
              <div className={'flex flex-grow-1 py-4 px-3'}>
                <SimpleText title={trans(t, 'form|patrimoine.biens.immobilier.montantRevenuBrutN2')} value={data?.montantRevenuBrutN2} />
              </div>
              <div className={'flex flex-grow-1 py-4 px-3'}>
                <SimpleText title={trans(t, 'form|patrimoine.biens.immobilier.montantRevenuImposableN2')} value={data?.montantRevenuImposableN2} />
              </div>
            </>
          )}
          {type === 'divers' && (
            <>
              <div className={'flex flex-grow-1 py-4 px-3'}>
                <SimpleText title={trans(t, 'form|patrimoine.biens.immobilier.montantRevenuBrut')} value={data?.montantRevenuBrut} />
              </div>
              <div className={'flex flex-grow-1 py-4 px-3'}>
                <SimpleText title={trans(t, 'form|patrimoine.biens.immobilier.montantRevenuImposable')} value={data?.montantRevenuImposable} />
              </div>
            </>
          )}
        </div>
      </Fieldset>

      {type === 'immobilier' && (
        <>
          <Fieldset legend={ucfirst(trans(t, 'immobilier'))} className={'w-12'} toggleable>
            <div className={'flex flex-column md:flex-row px-3'}>

              {type === 'immobilier' && isValidUUID(data?.image) && (
                <div className={'flex w-6 md:w-3 py-4 pl-2 pr-3'}>
                  <ManagedImage apim={apim} imageId={data?.image} width={'250px'} height={'250px'} imageStyle={{ borderRadius: '50%', objectFit: 'cover' }} />
                </div>
              )}

              <div className={'flex flex-row flex-wrap w-8 flex-grow-1 align-items-center'}>
                <div className={'flex flex-grow-1 py-4 px-3'}>
                  <SimpleText title={trans(t, 'form|patrimoine.biens.immobilier.classification')} value={data?.classification} method={'list'}
                              methodParams={{listKey: 'bienImmobilierClassifications'}}/>
                </div>
                <div className={'flex flex-grow-1 py-4 px-3'}>
                  <SimpleText title={trans(t, 'form|patrimoine.biens.immobilier.usage')} value={data?.usage} method={'list'}
                              methodParams={{listKey: 'bienImmobilierUsages'}}/>
                </div>
                <div className={'flex flex-grow-1 py-4 px-3'}>
                  <SimpleText title={trans(t, 'form|patrimoine.biens.immobilier.appartementClassification')} value={data?.appartementClassification} method={'list'}
                              methodParams={{listKey: 'bienImmobilierAppartementClassifications'}}/>
                </div>
                <div className={'flex flex-grow-1 py-4 px-3'}>
                  <SimpleText title={trans(t, 'form|patrimoine.biens.immobilier.surface')} text={data?.surface + ' m2'}/>
                </div>

              </div>

            </div>
          </Fieldset>

          <Fieldset legend={ucfirst(trans(t, 'address'))} className={'w-12'} toggleable>
            <div className={'flex flex-column flex-grow-1'}>
              <AddressField address={data}/>
            </div>
          </Fieldset>
        </>
      )}

      {type === 'entreprise' && (
        <Fieldset legend={ucfirst(trans(t, 'company'))} className={'w-full mt-5'} toggleable>
          <div className={'flex flex-column'}>
            {companyVersion?.raisonSociale && (
              <div className={'font-medium'}>{companyVersion?.raisonSociale}</div>
            )}

            {data?.company?.siren?.length > 0 && (
              <div className={'text-sm mt-1'}>{trans(t, 'siren')} : {data.company.siren}</div>
            )}

            <Link className={'block mt-1 text-sm'} to={appUri.cie.cli.page.replace(':id', data?.company?.id)}>
              {trans(t, 'seeDetailsCompany')}
            </Link>
          </div>
        </Fieldset>
      )}

      <div className={'flex w-12 flex-wrap gap-4 py-4 justify-content-center'}>
        <Chip key={'impositionRepartieIfi'}  label={trans(t, 'form|patrimoine.biens.default.ifi')} icon={'pi pi-' + (true === data?.impositionRepartieIfi ? 'check' : 'times')}
              style={{ backgroundColor: true === data?.impositionRepartieIfi ? '#E3F2FD' : ''}}/>
        <Chip key={'avantageMatrimonialPreciput'} label={trans(t, 'form|patrimoine.biens.default.matrimonialAdvantage')} icon={'pi pi-' + (true === data?.avantageMatrimonialPreciput ? 'check' : 'times')}
              style={{ backgroundColor: true === data?.avantageMatrimonialPreciput ? '#E3F2FD' : ''}}/>
        {type === 'immobilier' && (
          <Chip key={'avantageSuccessoralResidence'} label={trans(t, 'form|patrimoine.biens.immobilier.avantageSuccessoralResidence')} icon={'pi pi-' + (true === data?.avantageSuccessoralResidence ? 'check' : 'times')}
                style={{ backgroundColor: true === data?.avantageSuccessoralResidence ? '#E3F2FD' : ''}}/>
        )}
        <Chip key={'transmission'} label={trans(t, 'form|patrimoine.biens.default.transmission')} icon={'pi pi-' + (true === data?.transmission ? 'check' : 'times')}
              style={{ backgroundColor: true === data?.transmission ? '#E3F2FD' : '' }}/>
        <Chip key={'securite'} label={trans(t, 'form|patrimoine.biens.default.securite')} icon={'pi pi-' + (true === data?.securite ? 'check' : 'times')}
              style={{ backgroundColor: true === data?.securite ? '#E3F2FD' : ''}}/>
        {type === 'immobilier' && (
          <Chip key={'lieActivite'} label={trans(t, 'form|patrimoine.biens.default.lieActivite')} icon={'pi pi-' + (true === data?.lieActivite ? 'check' : 'times')}
                style={{ backgroundColor: true === data?.lieActivite ? '#E3F2FD' : ''}}/>
        )}
      </div>

      {data && (
        <Fieldset legend={ucfirst(trans(t, 'property'))} className={'w-full mt-5'} toggleable>
          <ProprietairesDatatable apim={apim} data={data} title={''}/>
        </Fieldset>
      )}

      {data?.description && (
        <Fieldset legend={ucfirst(trans(t, 'form|zone_note'))} className={'w-full mt-5'} toggleable>
          <div className={'a8-bien-description'} dangerouslySetInnerHTML={{__html: data.description}}></div>
        </Fieldset>
      )}

      <div className={'flex flex-row justify-content-end w-full mt-5'}>
        {isValidUUID(data?.author) && (
          <div className={'flex px-3 align-items-center'}>
            <strong className={'titre titre--gray pr-2'}>{ucfirst(trans(t, 'author'))}</strong>
            <div>
              <Userpicture apim={apim} user={data.author} size={'small'}/>
            </div>
          </div>
        )}

        {data?.created && (
          <div className={'flex px-3 align-items-center'}>
            <strong className={'titre titre--gray pr-2'}>{ucfirst(trans(t, 'created.at'))}</strong>
            <div>
              {dateString(data?.created, 'DD/MM/YYYY') + ' ' + trans(t, 'at') + ' ' + dateString(data?.created, 'HH:mm')!.replace(':', 'h')}
            </div>
          </div>
        )}

        {data?.updated && (
          <div className={'flex px-3 align-items-center'}>
            <strong className={'titre titre--gray pr-2'}>{ucfirst(trans(t, 'updated.at'))}</strong>
            <div>
              {dateString(data?.updated, 'DD/MM/YYYY') + ' ' + trans(t, 'at') + ' ' + dateString(data?.updated, 'HH:mm')!.replace(':', 'h')}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};
