import React, { useEffect } from 'react';

import { trans, ucfirst } from 'utilities';
import { useAppState, useUserState } from 'states';
import { IRequestParam, isAdmin, isExpert, useApim } from 'services';
import { UsersClientDatatable } from 'components';

import appUri from 'config/appUri.json';

export const DirigeantsList = () => {
  const apim = useApim();
  const { t } = apim.di();
  const appState = useAppState();
  const userState = useUserState();

  // Update Page Header.
  useEffect(() => {
    appState.setPageTitle(trans(t, 'menu|pages.title.user.clients'));
    appState.setBreadcrumb([{ label: ucfirst(trans(t, 'dirigeant', 2)) }]);

    const newPA: any[] = [];
    if (isAdmin() || isExpert()) {
      newPA.push({ label: trans(t, 'dir.add'), icon: 'plus', to: appUri.usr.dir.add, className:'bg-green-500' });
    }
    appState.setPageActions(newPA);
  }, [userState.id()]); // eslint-disable-line react-hooks/exhaustive-deps

  const params: IRequestParam[] = [];
  ['ROLE_CLIENT'].map((r: string) => {
    return params.push({label: 'roles[]', value: r})
  });

  return <UsersClientDatatable tableKey={'users-clients'} noFilters title={trans(t, 'menu|pages.title.user.clients')} params={params} apim={apim} />;
};
