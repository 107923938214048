import React from 'react';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';

import { default as App } from './App';

import {
  RoutesAccount,
  RoutesDossier,
  RoutesGED,
  RoutesPerson,
  RoutesShortcuts,
  RoutesSettings,
  RoutesNotification,
  RoutesSociete,
  RoutesSystem,
  RoutesUser,
  RoutesDocument
} from 'routes';

import { HomePage, NotFound } from 'pages';

// Configs & locales.
import { locale, addLocale } from 'primereact/api';
import 'moment/locale/fr';
import 'config/i18nConfig';
import primereact_fr from 'assets/locales/fr-FR/primereact.json';

const AppRouter = () => {
  addLocale('fr', primereact_fr.fr);
  locale('fr');

  const routesAccount = RoutesAccount();
  const routesDossier = RoutesDossier();
  const routesGED = RoutesGED();
  const routesPerson = RoutesPerson();
  const routesShortcut = RoutesShortcuts();
  const routesNotification = RoutesNotification();
  const routesSettings = RoutesSettings();
  const routesSociete = RoutesSociete();
  const routesSystem = RoutesSystem();
  const routesUser = RoutesUser();
  const routesDocument = RoutesDocument();

  const router = createBrowserRouter([
    ...routesSystem, ...[
      {
        path: '/',
        element: <App />,
        children: [
          ...routesAccount,
          ...routesDossier,
          ...routesGED,
          ...routesPerson,
          ...routesShortcut,
          ...routesSettings,
          ...routesNotification,
          ...routesSociete,
          ...routesUser,
          ...routesDocument,
          ...[
            {
              path: '/',
              element: <HomePage />
            },
            {
              path: '*',
              element: <NotFound asBlock={true} />
            }
          ]
        ]
      }]
  ]);

  return (<RouterProvider router={router} />);
};

export default AppRouter;
