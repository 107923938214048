import React, { useRef } from 'react';
import { CSSTransition } from 'react-transition-group';

import { classNames } from 'primereact/utils';
import { Tooltip } from 'primereact/tooltip';

import { UserMenu } from 'components';
import { Avatar } from 'primereact/avatar';

export const AppUserMenu = (props: any) => {
  const { apim, userState, themeState } = props;
  const { navigate } = apim.di();

  const inlineMenuRef = useRef(null);
  const inlineMenuClassName = classNames(
    'layout-inline-menu',
    {
      'layout-inline-menu-active': themeState.isInlineMenuActive()
    },
    props.className
  );

  const isSlim = () => themeState.menuMode() === 'slim';
  const menu = UserMenu(props);
  const title: string = userState.title();

  return (
    <div className={inlineMenuClassName} style={props.style}>
      {isSlim() && <Tooltip target={'.avatarTooltip'} />}

      <button
        data-pr-tooltip={title}
        className={classNames('avatarTooltip layout-inline-menu-action p-link flex flex-row align-items-center', { 'p-3 lg:p-1 py-3': props.horizontal, 'p-3': !props.horizontal })}
        onClick={() => props.onInlineMenuClick()}
      >
        <Avatar label={userState.initials()} size={'large'} shape={'circle'} image={userState.pictureUrl()} />
        <span className={'flex flex-column ml-2'}>
          <span className={'font-bold'}>{title}</span>
          <small className={'text-overflow-ellipsis overflow-hidden max-w-10rem'}>{userState.subtitle()}</small>
        </span>
        <i className={'layout-inline-menu-icon pi pi-angle-down ml-auto'}></i>
      </button>

      <CSSTransition nodeRef={inlineMenuRef} classNames={'p-toggleable-content'} timeout={{ enter: 1000, exit: 450 }} in={themeState.isInlineMenuActive()} unmountOnExit>
        <>
          <ul ref={inlineMenuRef} className={'layout-inline-menu-action-panel'}>
            {menu.map((item: any, index) => (
              <li key={index} className={'layout-inline-menu-action-item tooltip'} data-pr-tooltip={item.label}>
                <button
                  className={'flex flex-row align-items-center p-link'}
                  onClick={() => item.to ? navigate(item.to) : item.command()}
                >
                  <i className={'pi pi-fw pi-' + item.icon}></i>
                  <span>{item.label}</span>
                </button>
              </li>
            ))}
          </ul>
          {isSlim() && <Tooltip target={'.tooltip'} />}
        </>
      </CSSTransition>
    </div>
  );
};
