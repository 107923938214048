import React, { useEffect, useMemo, useState } from 'react';
import { Button } from 'primereact/button';
import { Fieldset } from 'primereact/fieldset';

import { isValidUUID, trans, triggerFormSubmit, ucfirst } from 'utilities';
import { FormWrapper } from 'forms';
import { ContractBeneficiairesDatatable, ContractCotisationsDatatable, ContractSouscripteursDatatable, PageLoader } from 'components';
import { IRequestParams } from 'services';

import appUri from 'config/appUri.json';

export const Step2 = (props: any) => {
  const { data, apim, appState, context, dirigeant } = props;
  const { t, navigate} = apim.di();

  const resourceType: string = 'contractsAssuranceVie';
  const contractType: string = 'contractAssuranceVie';
  const contractResourceType: string = 'contractsAssuranceVie';

  const [refresher, setRefresher] = useState<number>(Date.now);
  const [loading, setLoading] = useState<boolean>(false);
  const [banks, setBanks] = useState<any[]>([]);
  // Load the list of banks.
  useEffect(() => {
    setLoading(true);
    apim.fetchEntities({
      resourceType: 'banks',
      params: [{ label: 'order[name]', value: 'asc' }, { label: 'limit', value: 250 }],
      cache: false,
      setter: setBanks,
      setLoading
    } as IRequestParams).then();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const _callback = (formData: any) => {
    // Handle new bank.
    if (formData.bank && !isValidUUID(formData.bank)) {
      apim.postEntity({
        resourceType: 'banks',
        data: { name: formData.bank, active: true },
        notif: false
      } as IRequestParams).then();
    }

    // Handle transfert Fourgous.
    if (data?.type === 'mono_support' && formData?.transfert) {
      apim.patchEntity({
        resourceType,
        id: formData?.id,
        data: { type: 'multi_support' },
        notif: false
      } as IRequestParams).then();
    }

    navigate(redirectUri);
  };

  const redirectUri: string = appUri.dos.edit.patrimoine.contracts.landing.replace(':id', appState?.dossier()?.id);
  const renderForm = useMemo(() =>
      <FormWrapper multiple formKeyPrefix={'contract_assurance_vie_informations'} resourceType={resourceType} classes={'grid p-fluid w-12'} hideReload
                   context={{...context, ...{dirigeant: dirigeant}}} data={data} listsOptions={{ banks: banks }} cancelLink callback={_callback}/>
    , [data?.id, context?.id, banks]); // eslint-disable-line react-hooks/exhaustive-deps
  const renderFormNote = useMemo(() =>
      <FormWrapper multiple formKeyPrefix={'zone_note'} resourceType={resourceType} classes={'grid p-fluid w-12'} hideReload data={data} notif={false}/>
    , [data?.id, context?.id]); // eslint-disable-line react-hooks/exhaustive-deps

  if (loading) return <PageLoader/>;

  return (
    <div className={'a8-wizard wizard-patrimoine-contrat-assurance-vie fadein animation-duration-500'}>
      <h5 className={'mx-3 pt-3 flex flex-row align-items-center'}>
        <span className={'mr-2 border-circle bg-primary flex align-items-center justify-content-center'} style={{width: '2rem', height: '2rem'}}><i className={'pi pi-info'}></i></span>
        {trans(t, 'menu|wizard.patrimoine.contracts.default.step2')}
      </h5>

      {renderForm}

      <Fieldset legend={ucfirst(trans(t, 'souscripteur', 2))} className={'mt-0'} toggleable>
        <ContractSouscripteursDatatable apim={apim} data={data} editMode context={{...context, ...{dirigeant: dirigeant}}} title={''}
                                        contractType={contractType} contractResourceType={contractResourceType} setRefresher={setRefresher}/>
      </Fieldset>

      <Fieldset legend={ucfirst(trans(t, 'historique.versementsRetraits'))} className={'mt-4'} toggleable collapsed>
        <ContractCotisationsDatatable apim={apim} data={data} editMode context={context} title={''} refresher={refresher}
                                      contractType={contractType} contractResourceType={contractResourceType}/>
      </Fieldset>

      <Fieldset legend={ucfirst(trans(t, 'beneficiary', 2))} className={'mt-4'} toggleable>
        <ContractBeneficiairesDatatable apim={apim} data={data} editMode context={{...context, ...{dirigeant: dirigeant}}} title={''}
                                        contractType={contractType} contractResourceType={contractResourceType}/>
      </Fieldset>

      {renderFormNote}

      <div className={'text-right m-2 pt-4'}>
        <Button type={'reset'} className={'text-ucfirst p-button-text p-button-danger mr-5'} label={trans(t, 'close')} onClick={() => navigate(redirectUri)}/>
        <Button type={'submit'} className={'text-ucfirst'} label={trans(t, 'terminate')} icon={'pi pi-check'} onClick={() => triggerFormSubmit(null)}/>
      </div>
    </div>
  );
};
