import React, { useCallback, useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { TFunction } from 'i18next';

import { TabMenu } from 'primereact/tabmenu';

import { isValidUUID, trans, ucfirst } from 'utilities';
import { Error, NotFound } from 'pages';
import { PageLoader } from 'components';
import { IRequestParams, isAdmin, isExpertAdmin } from 'services';
import { ExpertProfile, ExpertDossiers } from './tabs';

import { includes, forEach } from 'lodash';
import appUri from 'config/appUri.json';

export const ExpertFormRouter = (props: any) => {
  const { id, tab, appState, apim, action } = props;
  const { t, navigate } = apim.di();
  const [searchParams] = useSearchParams();
  const societeExpert = searchParams.get('cabinet');

  const [loading, setLoading] = useState<boolean>(false);
  const [data, setData] = useState<any>(null);
  const [notFound, setNotFound] = useState<boolean>(false);
  const [errored, setErrored] = useState<boolean>(false);

  // Let's detect if we are in "edit/add" mode
  // then render the appropriate display (form or module dashboard).
  const editMode = appUri.reservedKeywords.edit === action;
  const addMode = appUri.reservedKeywords.add === action;

  const [activeIndex, setActiveIndex] = useState<number>(0);
  const tabs: any[] = [];
  if (id) {
    const baseUri = appUri.usr.exp.edit.replace(':id', id || '_');
    tabs.push({label: trans(t, 'menu|tab.general'), command: () => navigate(baseUri)});
    if (isAdmin() || isExpertAdmin()) {
      forEach(appUri.usr.exp.tabs, (ta) => {
        tabs.push({label: trans(t, 'menu|tab.' + ta), command: () => navigate(baseUri + '/' + ta)});
      })
    } else {
      forEach(appUri.usr.exp.tabs.filter((_t: string) => _t !== 'dossiers'), (ta) => {
        tabs.push({label: trans(t, 'menu|tab.' + ta), command: () => navigate(baseUri + '/' + ta)});
      })
    };
  }

  // Validate URL & build tabs.
  const checks = useCallback(() => {
    if (addMode) return isAdmin() || isExpertAdmin();

    return isUrlValid(t, id, tab);
  }, [id, tab]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (!checks()) setNotFound(true);

    let ai = 0;
    forEach(appUri.usr.exp.tabs, (ta, index) => {
      if (tab === ta) ai = index + 1;
    });

    if (ai !== activeIndex) setActiveIndex(ai);
  }, [id, tab]); // eslint-disable-line react-hooks/exhaustive-deps

  const buildTabContent = useCallback(() => {
    if (isAdmin() || isExpertAdmin()) {
      switch (activeIndex) {
        case 1:
          return <ExpertDossiers tableKey={'societes-users-experts-dossiers'} title={trans(t, 'menu|pages.title.expertDossiersLists')}
                                 noFilters apim={apim} expert={data} editMode={editMode} />;
        default: return <ExpertProfile data={data} apim={apim} societeExpert={societeExpert} />;
      }
    } else {
      return <ExpertProfile data={data} apim={apim}/>
    }

  }, [id, tab, activeIndex, data]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (addMode) return;

    setLoading(true);
    apim.fetchEntity({
      resourceType: 'users',
      id: id,
      cache: false,
      setter: setData,
      setErrored,
      setLoading,
      setNotFound,
    } as IRequestParams).then();
  }, [id]); // eslint-disable-line react-hooks/exhaustive-deps

  // Update Page Header.
  useEffect(() => {
    appState.setPageTitle((data ? ucfirst(trans(t, 'profile')) + ' ' + (data.firstName + ' ' + data.lastName) : '') + ' | ' + trans(t, 'editing'));
    appState.setPageActions([]);
    appState.setBreadcrumb([
      { label: trans(t, 'expert', 2) },
      { label: ((data ? data.firstName + ' ' + data.lastName : trans(t, 'expert'))), to: appUri.usr.exp.page.replace(':id', data?.id || '_') + '?maj=' + Date.now() },
      { label: trans(t, editMode ? 'editing' : 'adding') }
    ]);
  }, [data]); // eslint-disable-line react-hooks/exhaustive-deps

  if (notFound) return <NotFound asBlock/>;
  if (errored) return <Error asBlock/>;
  if (loading) return <PageLoader/>;

  return (
    <div className={'card height-100 fadein animation-duration-500 flex flex-column'}>
      <div className={'a8-page-header flex flex-wrap'}>
        <div className={'flex-auto flex p-2 justify-content-end'}>
          {tabs.length > 1 && <TabMenu model={tabs} activeIndex={activeIndex} onTabChange={(e) => setActiveIndex(e.index)}/>}
        </div>
      </div>
      {buildTabContent()}
    </div>
  );
};

const isUrlValid = (t: TFunction, id: string | undefined, tab: string | undefined) => {
  // Ensure valid entity UUID & existing tab.
  return isValidUUID(id) && includes([...[undefined], ...appUri.usr.exp.tabs], tab);
};
