import React, { useCallback, useEffect } from 'react';

import { Button } from 'primereact/button';

import { FormWrapper } from 'forms/index';
import { iri, isValidUUID, trans, triggerFormSubmit } from 'utilities';

import appUri from 'config/appUri.json';
import { trimStart } from 'lodash';

export const StatutDirigeant = (props: any) => {
  const { apim, urls, missionCategory, setMissionCategory, cancelUri, hypotheseId, missionId, dossier, appState } = props;
  const { t, navigate } = apim.di();

  useEffect(() => {
    if (isValidUUID(missionId) && appState.mission()?.id !== missionId) {
      appState.setMission(missionId)
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const redirectUri = (trimStart(appUri.dos.page) + appUri.dos.pageMission)
    .replace(':id', dossier?.id)
    .replace(':module', 'missions')
    .replace(':type', 'statut_dirigeant')
    .replace(':mission', missionId)

  const renderForm = useCallback(() =>
      <FormWrapper classes={'grid p-fluid'}  submitClass={'a8-statut_dirigeant-form'} formKeyPrefix={'general_statut_dirigeant'} resourceType={'mission_statut_dirigeant'} setData={setMissionCategory}
                   data={missionCategory} additionalData={{missionHypothesis : iri('missionHypothesis', hypotheseId)}}
                   hideReload cancelLink multiple redirectUri={redirectUri} />
    , [missionCategory?.id,hypotheseId]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      {renderForm()}

      <div className={'text-right m-2 pt-4'}>
        <Button type={'button'} className={'text-ucfirst p-button-text p-button-danger mr-5'} label={trans(t, 'close')} onClick={() => navigate(cancelUri)}/>
        <span className={'p-buttonset'}>
          <Button type={'button'} className={'text-ucfirst p-button-text'} label={trans(t, 'back')} icon={'pi pi-arrow-left'} onClick={() => navigate(urls?.form + '/' + missionId + '/hypothese/' + hypotheseId)}/>
          <Button type={'submit'} className={'text-ucfirst'} label={trans(t, 'terminate')} icon={'pi pi-check'}
                  onClick={() => {triggerFormSubmit('a8-statut_dirigeant-form')}}
          />
        </span>
      </div>
    </>
  );
}
