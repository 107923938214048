import React from 'react';
import { TFunction } from 'i18next';

import { confirmDialog } from 'primereact/confirmdialog';

import { FormWrapper } from 'forms';
import { IRequestParams, userId, impersonate } from 'services';
import { trans, triggerFormSubmit, ucfirst } from 'utilities';

export const dialog = (t: TFunction, p: any) => {
  const { header, message, icon, accept, reject, acceptLabel, rejectLabel, acceptClassName, rejectClassName, className } = p;

  confirmDialog({
    content: '',
    header: header || ucfirst(trans(t, 'system|confirmations.header')),
    message: message || trans(t, 'system|confirmations.default'),
    icon: 'none' === icon ? null : icon || 'pi pi-exclamation-triangle text-orange-700',
    accept: accept,
    reject: reject,
    acceptLabel: acceptLabel || ucfirst(trans(t, 'validate')),
    rejectLabel: rejectLabel || ucfirst(trans(t, 'cancel')),
    acceptClassName: acceptClassName || 'p-button-primary',
    rejectClassName: rejectClassName || 'p-button-text p-button-danger',
    className: className
  });
};

export const addShortcut = (t: TFunction, formState: any, appState: any) => {
  const form =
   <FormWrapper classes={'grid p-fluid'} submitClass={'a8-shortcut-form'} formState={formState}
                hideReload formKeyPrefix={'shortcuts'} resourceType={'shortcuts'} multiple
                data={{
                  user: userId(),
                  name: appState.pageTitle(),
                  uri: window.location.pathname
                }}/>
  ;

  return dialog(t, {
    message: form,
    icon: 'none',
    accept: () => triggerFormSubmit('a8-shortcut-form')
  });
};

export const impersonateDialog = (t: TFunction, apim: any, userState: any, targetUser: any) => {
  return dialog(t, {
    message: trans(t, 'impersonateAs') + ' ' + targetUser.fullName + ' ?',
    icon: 'none',
    accept: () => {
      impersonate(apim, userState, targetUser);
    }
  });
};

export const sendNotification = (apim: any, dossiers: any[]) => {
  const { t } = apim.di();
  const onSubmit = (formData: any) => {
    if (dossiers.length === 1) {
      apim.call({
        resourceType: 'notifications',
        notif: true,
        notifSuccess: {
          summary: trans(t, 'notification|notification.sent.summary'),
          details: trans(t, 'notification|notification.sent.details'),
        },
        data: {
          user: dossiers.toString(),
          author: userId(),
          ...formData
        }
      } as IRequestParams).then();
    } else if (dossiers.length > 1) {
      apim.call({
        resourceType: 'notifications',
        action: 'multiple',
        notif: true,
        notifSuccess: {
          summary: trans(t, 'notification|notification.sent.summary_plural'),
          details: trans(t, 'notification|notification.sent.details_plural'),
        },
        data: {
          users: dossiers,
          notification: {
            ...formData,
            author: userId()
          }
        }
      } as IRequestParams).then();
    } else {
      apim.toast('error').current.show({
        severity: 'error',
        summary: trans(t, 'notification|notification.errorNoUser.summary'),
        detail: trans(t, 'notification|notification.errorNoUser.details'),
        life: 5000
      });
    }
  };

  const form =
    <FormWrapper classes={'grid'} formKeyPrefix={'notifications'} resourceType={'notifications'} multiple
                 submitClass={'a8-notification-form pr-5'} hideReload onSubmit={onSubmit}/>;

  return dialog(t, {
    header: ucfirst(trans(t, 'form|dossier.notificationSend')),
    message: form,
    icon: 'none',
    acceptLabel: ucfirst(trans(t, 'sendNotification')),
    accept: () => triggerFormSubmit('a8-notification-form')
  });

};
