import React, { useCallback, useEffect, useState } from 'react';
import { AxiosResponse } from 'axios';

import { DataTable, DataTableExpandedRows } from 'primereact/datatable';
import { Column } from 'primereact/column';

import { montant, trans} from 'utilities';
import { IRequestParams, isClient } from 'services';
import { formatPieChart, formatTable, AMChartPie, PageLoader, getModuleId } from 'components';
import { useThemeState } from 'states';

import { isEmpty, replace } from 'lodash';
import appUri from 'config/appUri.json';

export const RetraiteFacultative = (props: any) => {
  const { id, appState, apim, modulesMenu, lastUpdate } = props;
  const { t } = apim.di();
  const themeState = useThemeState();

  const dossier = appState.dossier();
  const [loading, setLoading] = useState<boolean>(false);
  const [data, setData] = useState<any>(null);
  const [expandedRows, setExpandedRows] = useState<DataTableExpandedRows>();

  // Override Page Header (defaults into ModuleWrapper, then RetraiteRouter).
  useEffect(() => {
    appState.setBreadcrumb([
     isClient()
      ? { label: trans(t, 'dossier', 2) }
      : { label: trans(t, 'dossier', 2), to: appUri.dos.list },
     { label: dossier.title, to: replace(appUri.dos.page, ':id', id)},
      { label: trans(t, 'mission', 2), to: replace(appUri.dos.missions.history, ':id', id)},
     { label: trans(t, 'menu|menu.schema_flux.retraite_facultative') }
    ]);

    appState.setModule(getModuleId(modulesMenu, 'retraite.facultative'));
  }, [id]); // eslint-disable-line react-hooks/exhaustive-deps

  const overrideTable1 = (dataSource: any) => {
    return {...dataSource, ...{
        data: dataSource.data?.map((contrat: any, i: number) => {
          return {...contrat, ...{
              valeurEnStock: typeof contrat.valeurEnStock === 'number' ? montant(contrat.valeurEnStock, {nullValue: '-'}) : contrat.valeurEnStock,
              versementAnnuel: typeof contrat.versementAnnuel === 'number' ? montant(contrat.versementAnnuel, {nullValue: '-'}) : contrat.versementAnnuel,
              montantRetraiteFacultativeEstimee: typeof contrat.montantRetraiteFacultativeEstimee === 'number' ? montant(contrat.montantRetraiteFacultativeEstimee, {nullValue: '-'}) : contrat.montantRetraiteFacultativeEstimee,
              gainFiscalAnnee: typeof contrat.gainFiscalAnnee === 'number' ? montant(contrat.gainFiscalAnnee, {nullValue: '-'}) : contrat.gainFiscalAnnee,
              gainFiscalCumule: typeof contrat.gainFiscalCumule === 'number' ? montant(contrat.gainFiscalCumule, {nullValue: '-'}) : contrat.gainFiscalCumule,
            }};
        })
      }
    }
  };

  const fetchData = useCallback(async () => {
    if (!appState.mission()) return null;
    setLoading(true);

    apim.call({
      resourceType: 'dossiers',
      action: 'module',
      id: id + '|' + getModuleId(modulesMenu, 'retraite.facultative'),
      method: 'get',
      params: [{label: 'mission', value: appState.mission()?.id}],
      cacheMode: isEmpty(lastUpdate) ? 'default' : 'replace',
      setLoading: setLoading,
      success: (res: AxiosResponse) => setData({...res.data, ...{
          chart1: formatPieChart(res.data, 'missionRetraite.chart.cadresFiscauxContrats', t, {tradCategories: false}),
          chart2: formatPieChart(res.data, 'missionRetraite.chart.valeurStockContrats', t, {tradCategories: false}),
          table1: overrideTable1(formatTable(res.data, 'missionRetraite.table.contrats', t, {method: 'simpleRows'}))
      }})
    } as IRequestParams);
  }, [appState.company()?.id, appState.mission()?.id, lastUpdate]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    fetchData().then();
  }, [appState.company()?.id, appState.mission()?.id, lastUpdate]); // eslint-disable-line react-hooks/exhaustive-deps

  if (loading) return <PageLoader />;

  const canExpand = (rowData: any) => rowData.tables;

  const rowExpansionTemplate = (rowData: any) => {
    const overrideSubTable = (dataSource: any) => {
      return {...dataSource, ...{
          data: dataSource.data?.map((subRow: any, i: number) => {
            return {...subRow, ...{
                reversionAutomatique: trans(t, subRow.reversionAutomatique === true ? 'table|yes' : 'table|no'),
                garantieBonneFin: trans(t, subRow.garantieBonneFin === true ? 'table|yes' : 'table|no'),
                exonerationsCotisations: trans(t, subRow.exonerationsCotisations === true ? 'table|yes' : 'table|no'),
              }};
          })
        }
      }
    };
    const subData = overrideSubTable(formatTable(rowData, 'missionRetraite.table.contrats_subtable', t, {method: 'simpleRows'}));

    return (
      <div className={'flex flex-row flex-wrap fadein animation-duration-500 bg-blue-50'}>
        <div className={'grid p-fluid mt-2 w-full'}>
          <div className={'col-12 px-6'}>
            <h6 className={'text-center text-color-secondary'}>{trans(t, 'table|contratDetail')}</h6>
            <DataTable value={subData.data} className={'datatable-responsive p-datatable-no-hover'} stripedRows={true}>
              <Column field={'tableMortalite'} header={trans(t, 'table|missionRetraite.table.contrats_subtable.header.tableMortalite')} />
              <Column field={'tauxTechnique'} header={trans(t, 'table|missionRetraite.table.contrats_subtable.header.tauxTechnique')} />
              <Column field={'reversionAutomatique'} header={trans(t, 'table|missionRetraite.table.contrats_subtable.header.reversionAutomatique')} />
              <Column field={'garantieBonneFin'} header={trans(t, 'table|missionRetraite.table.contrats_subtable.header.garantieBonneFin')} />
              <Column field={'exonerationsCotisations'} header={trans(t, 'table|missionRetraite.table.contrats_subtable.header.exonerationCotisations')} />
              <Column field={'parametreRemuneration'} header={trans(t, 'table|missionRetraite.table.contrats_subtable.header.parametreRemuneration')} />
            </DataTable>
          </div>
        </div>
      </div>
    );
  };

  return (
    <div className={'flex flex-column flex-wrap fadein animation-duration-500'}>
      <div className={'flex w-12 py-4 px-3'}>
          {themeState.isMobile() ||themeState.isTablet() ? (
          <div className={'flex xl:flex-row flex-column w-12'}>
            <div className={'flex flex-grow-1'}>
              <AMChartPie chartData={data?.chart1} verticalLegend/>
            </div>

            <div className={'flex flex-grow-1'}>
              <AMChartPie chartData={data?.chart2} vertivalLegend />
            </div>
          </div>
        ) : (
          <div className={'flex xl:flex-row flex-column w-12'}>
            <div className={'flex flex-grow-1'}>
              <AMChartPie chartData={data?.chart1} hideLegend/>
            </div>

            <div className={'flex flex-grow-1'}>
              <AMChartPie chartData={data?.chart2} hideLegend />
            </div>
          </div>
        )}
      </div>

      <div className={'grid p-fluid mt-3 w-full'}>
        <div className={'col-12'} style={{ maxWidth : themeState.isMobile() ||themeState.isTablet() ? '80vw' : '100%' }}>
            {data?.table1?.data?.length > 0 && (
              <DataTable value={data?.table1?.data} className={'datatable-responsive p-datatable-no-hover flex-1'} stripedRows={true}
                         expandedRows={expandedRows} rowExpansionTemplate={rowExpansionTemplate}
                         style={{ maxWidth : themeState.isMobile() ||themeState.isTablet() ? '75vw' : '100%' }}
                         onRowToggle={(e) => setExpandedRows(e.data as DataTableExpandedRows)}>
                <Column expander={canExpand} style={{ width: '3rem' }} />
                <Column field={'cadreFiscal'} header={trans(t, 'table|missionRetraite.table.contrats.header.cadreFiscal')} />
                <Column field={'nomContrat'} header={trans(t, 'table|missionRetraite.table.contrats.header.nomContrat')} />
                <Column field={'compagnie'} header={trans(t, 'table|missionRetraite.table.contrats.header.compagnie')} />
                <Column field={'valeurEnStock'} header={trans(t, 'table|missionRetraite.table.contrats.header.valeurEnStock')} />
                <Column field={'versementAnnuel'} header={trans(t, 'table|missionRetraite.table.contrats.header.versementAnnuel')} />
                <Column field={'montantRetraiteFacultativeEstimee'} header={trans(t, 'table|missionRetraite.table.contrats.header.montantRetraiteFacultativeEstimee')} />
                <Column field={'gainFiscalAnnee'} header={trans(t, 'table|missionRetraite.table.contrats.header.gainFiscalAnnee')} />
                <Column field={'gainFiscalCumule'} header={trans(t, 'table|missionRetraite.table.contrats.header.gainFiscalCumule')} />
              </DataTable>
            )}
          </div>
        </div>
    </div>
  );
};
