import React, { useEffect } from 'react';
import { useUserState } from 'states';
import { useLocation } from 'react-router-dom';

const enableTicketCollector = process.env.REACT_APP_ENABLE_TICKET_COLLECTOR || '1';

/**
 * JIRA ticket collector.
 * Loads the JS script for displaying the ticket collector dialog.
 *
 * @constructor
 */
export const TicketCollector = () => {
  const userState = useUserState();
  const location = useLocation();

  /**
   * Customize the trigger button for the JIRA collector.
   * @see HelpMenu.tsx
   */
  (window as any).ATL_JQ_PAGE_PROPS = {
    "triggerFunction": (showCollectorDialog: any) => {
      const ticketCollectorToggle = document.getElementById('a8-ticket-collector-toggle');
      if (ticketCollectorToggle) {
        ticketCollectorToggle.onclick = (e: any) => {
          e.preventDefault();
          showCollectorDialog();
        }
      }
    },
    fieldValues: {
      summary : '',
      description : '',
      fullname: userState.fullName(),
      email: userState.email(),
    },
    environment: () => ({
      path: location.pathname
    })
  };

  useEffect(() => {
    if ('1' === enableTicketCollector) {
      const script = document.createElement('script');
      script.src = "https://saas-production.atlassian.net/s/d41d8cd98f00b204e9800998ecf8427e-T/-3o5b4z/b/4/c95134bc67d3a521bb3f4331beb9b804/_/download/batch/com.atlassian.jira.collector.plugin.jira-issue-collector-plugin:issuecollector/com.atlassian.jira.collector.plugin.jira-issue-collector-plugin:issuecollector.js?locale=fr-FR&collectorId=2c9fc458";
      script.async = true;

      document.body.appendChild(script);

      return () => {
        document.body.removeChild(script);
      }
    }

    return () => {}
  }, [location.pathname]);

  return <></>;
};
