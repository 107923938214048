import { age, dateString, iri, isValidUUID, montant, shortNumber, trans, ucfirst } from 'utilities';
import { forEach } from 'lodash';
import appUri from 'config/appUri.json';

export const getDirigeantData = (dataSource: any) => {
  const test: any = (dataSource.metadata['hydra:member'] ?? []).filter((c: any) => c.key === 'family');
  return (test.length === 0) ? null : test[0].data;
}

export const formatFamilyTree = (dataSource: any, t: any) => {
  const dirigeant = getDirigeantData(dataSource);
  if (!dirigeant) return [];

  const spouseLink = dirigeant.spouses?.length > 0 ? dirigeant.spouses[0] : null;
  const spouse = dirigeant.spouses?.length > 0 ? dirigeant.spouses[0].spouse : null;

  const descLink = trans(t, 'tree|clickToSeeDetails');
  const childrenLeft: any[] = [];   // Children of Main Character
  const childrenRight: any[] = []; // Children of Main Character's partner
  const childrenMid: any[] = [];    // Children of both

  // Family group & main family member.
  const nodes: any[] = [
    {
      tags: ['a8_main'],
      id: dirigeant.id,
      name: (dirigeant.firstName ? dirigeant.firstName + ' ' : '') + dirigeant.lastName,
      title: age(dirigeant.birthDate, 'an', trans(t, 'tree|born')),
      img: ((dirigeant?.latestVersion?.sex !== null && dirigeant?.latestVersion?.sex !== undefined) ?
        (dirigeant?.latestVersion?.sex?.toLowerCase() === 'female'
          ? '/assets/images/avatar/woman.gif'
          : '/assets/images/avatar/husband.gif'
      ) : '/assets/images/avatar/profile.gif'),
      link: appUri.cie.per.phy.page.replace(':id', dirigeant.id)
    },
    {id: 'family', name: trans(t, 'tree|home'), tags: ['family-group', 'group'], description: descLink, img_group: '/assets/images/avatar/home.png'},
  ];

  // Handle children (dirigeant, then spouse) first because they will decide the tree structure :)
  forEach(dirigeant.children || [], ((childData: any) => {
    const child = childData.child;
    if (!child) return;

    const formated: any = {
      tags:['a8_child'],
      id: child.id,
      name: (child.firstName ? child.firstName + ' ' : '') + child.lastName,
      title: age(child.birthDate, 'an', trans(t, 'tree|born')),
      img: '/assets/images/avatar/baby-boy.gif',
      link: appUri.cie.per.phy.page.replace(':id', child.id)
    };

    const childOfBoth = spouse ? (spouse.children || []).filter((bc: any) => bc.child?.id === child.id).length > 0 : false;
    if (childOfBoth) {
      formated.stpid = 'childrenMid';
      childrenMid.push(formated);
    } else {
      formated.stpid = 'childrenLeft';
      childrenLeft.push(formated);
    }
  }));

  if (spouse) {
    forEach(spouse.children || [], ((childData: any) => {
      const child = childData.child;
      if (!child) return;

      const formated: any = {
        tags:['a8_child'],
        id: child.id,
        name: (child.firstName ? child.firstName + ' ' : '') + child.lastName,
        title: age(child.birthDate, 'an', trans(t, 'tree|born')),
        img: '/assets/images/avatar/baby-boy.gif',
        link: appUri.cie.per.phy.page.replace(':id', child.id)
      };

      const childOfBoth = (dirigeant.children || []).filter((bc: any) => bc.child?.id === child.id).length > 0;
      if (!childOfBoth) {
        formated.stpid = 'childrenRight';
        childrenRight.push(formated);
      }
    }));
  }

  if (spouse) {
    nodes.push({id: 'mainBound', tags: ['ig'], stpid: 'family'});

    let spouseLinkLabel = trans(t, 'tree|maritalStatus.unknown');
    let spouseLinkTitle = trans(t, 'tree|maritalRegime.unknown');

    if (spouseLink.maritalStatus !== null && spouseLink.maritalStatus !== undefined) {
      spouseLinkLabel = trans(t, 'tree|maritalStatus.' + spouseLink.maritalStatus.toLowerCase());
      if (spouseLink.maritalDate !== null && spouseLink.maritalDate !== undefined) {
        spouseLinkLabel += ' ' + trans(t, 'tree|on') + ' ' + dateString(spouseLink.maritalDate);
      }
    }

    if (spouseLink.maritalRegime !== null && spouseLink.maritalRegime !== undefined) {
      spouseLinkTitle = trans(t, 'tree|regime', 1, trans(t, 'tree|maritalRegime.' + spouseLink.maritalRegime.toLowerCase()));
    }

    nodes.push({id: 'bound', stpid: 'mainBound', tags:['a8_link'], name: spouseLinkLabel, title: spouseLinkTitle});

    // Move Main (first node) into the right place.
    nodes[0]['pid'] = ['bound'];

    nodes.push({
      tags:['a8_partner'],
      id: spouse.id,
      pid: 'bound',
      name: (spouse.firstName ? spouse.firstName + ' ' : '') + spouse.lastName,
      title: age(spouse.birthDate, 'an', trans(t, 'tree|born')),
      img: ((spouse?.latestVersion?.sex !== null && dirigeant?.latestVersion?.sex !== undefined) ?
        (spouse?.latestVersion?.sex?.toLowerCase() === 'female'
            ? '/assets/images/avatar/woman.gif'
            : '/assets/images/avatar/husband.gif'
        ) : '/assets/images/avatar/profile.gif'),
      link: appUri.cie.per.phy.page.replace(':id', spouse.id)
    });
  } else {
    // Move Main (first node) into the right place.
    nodes[0]['stpid'] = ['family'];
  }

  // Finally, let's move children under the right entity.
  if (childrenMid.length > 0) {
    nodes.push({id: 'childrenMid', pid: 'mainBound', name: trans(t, 'tree|child', 2), tags: ['children-group', 'group'], description: descLink, img_group: '/assets/images/avatar/children.png'});
    childrenMid.map((cm: any) => nodes.push(cm));
  }

  if (childrenLeft.length > 0) {
    nodes.push({id: 'childrenLeft', pid: dirigeant.id, name: trans(t, 'tree|child', 2), tags: ['children-group', 'group'], description: descLink, img_group: '/assets/images/avatar/children.png'});
    childrenLeft.map((cl: any) => nodes.push(cl));
  }

  if (childrenRight.length > 0) {
    nodes.push({id: 'childrenRight', pid: spouse.id, name: trans(t, 'tree|child', 2), tags: ['children-group', 'group'], description: descLink, img_group: '/assets/images/avatar/children.png'});
    childrenRight.map((cr: any) => nodes.push(cr));
  }

  return nodes;
};

export const formatOrgTree = (dataSource: any, t: any, dirigeantStatus: any[] = [], societeTypes: any[] = []) => {
  const dirigeant = getDirigeantData(dataSource);
  if (!dirigeant) return [];
  const companies: any = (dataSource.metadata['hydra:member'] || []).filter((c: any) => c.key === 'companies');
  if (companies.length === 0) return [];
  const nodes: any[] = [];

  const descLink = trans(t, 'tree|clickToSeeDetails');

  const getTags = (beneficiaries: any[]) => {
    switch (beneficiaries.length) {
      case 0:
        return ['a8_company_0'];

      case 1:
        return ['a8_company'];

      case 2:
        return ['a8_company_2'];

      case 3:
        return ['a8_company_3'];

      case 4:
        return ['a8_company_4'];

      case 5:
        return ['a8_company_5'];

      default:
        return ['a8_company_5p'];
    }
  };

  const getImg = (beneficiary: any) => {
    const defaultIcon: string = '/assets/images/avatar/profile.gif';
    if ((beneficiary?.parents || []).length > 0) {
      return '/assets/images/avatar/baby-boy.gif';
    }

    if (beneficiary?.latestVersion?.sex) {
      return 'male' === beneficiary.latestVersion.sex?.toLowerCase() ? '/assets/images/avatar/husband.gif' : '/assets/images/avatar/woman.gif'
    }

    if (beneficiary?.latestVersion?.firstName) {
      return '/assets/images/avatar/profile.gif';
    }

    if (beneficiary?.siren) {
      return '/assets/images/avatar/company.png';
    }

    return defaultIcon;
  };

  const formatAssociate = (company: any, associate: any) => {
    let target: any = null;
    let id: string = '';
    let name: string = trans(t, 'tree|unknown');
    let role: string = trans(t, 'tree|beneficiary');

    if (isValidUUID(associate.personnePhysique?.id)) {
      role = trans(t, 'tree|shareholder');
      id = associate.personnePhysique.id;
      name = associate.personnePhysique.lastName + ' ' + associate.personnePhysique.firstName;
      target = associate.personnePhysique;

      // Filter companies in which the person physique is employed.
      // Only keep the current printed company, get the role of the person in it.
      const personnePhysiqueEmployees = (associate.personnePhysique.companies ?? []).filter((ppe: any) => iri('dossierCompanies', company.id) === ppe.company);
      const personnePhysiqueEmployee = personnePhysiqueEmployees[0];
      const roleInCompany = personnePhysiqueEmployee?.dirigeantStatus;
      if (roleInCompany !== undefined && roleInCompany.length > 0) {
        const match: any = (dirigeantStatus || []).filter((m: any) => roleInCompany.toLowerCase() === m?.value);
        role += ', ' + (match.length > 0 ? match[0].translated : trans(t, 'tree|role.' + roleInCompany));
      }

    } else if (isValidUUID(associate.company?.id)) {
      // A company is the associate.
      role = trans(t, 'tree|shareholder');
      name = associate.company?.version?.raisonSociale;
      id = associate.company?.version?.id;
      target = associate.company;

    } else if (isValidUUID(associate.dirigeantPersonnePhysique?.id)) {
      // A dirigeant personne physique is the associate.
      role = trans(t, 'tree|shareholder');
      id = associate.dirigeantPersonnePhysique.personnePhysique.id;
      target = associate.dirigeantPersonnePhysique.personnePhysique;
      name = target.lastName + ' ' + target.firstName;
      if (associate.dirigeantPersonnePhysique?.dirigeantStatus?.length > 0) {
        const match: any = (dirigeantStatus || []).filter((m: any) => associate.dirigeantPersonnePhysique?.dirigeantStatus?.toLowerCase() === m?.value);
        role += ', ' + (match.length > 0 ? match[0].translated : trans(t, 'tree|role.' + associate.dirigeantPersonnePhysique?.dirigeantStatus));
      }

    } else if (isValidUUID(associate.dirigeantPersonneMorale?.id)) {
      // A dirigeant personne moral is the associate.
      role = trans(t, 'tree|shareholder');
      id = associate.dirigeantPersonneMorale.company?.version?.id;
      target = associate.dirigeantPersonneMorale.company;
      name = associate.dirigeantPersonneMorale.company?.version?.raisonSociale;
      if (associate.dirigeantPersonneMorale?.dirigeantStatus?.length > 0) {
        const match: any = (dirigeantStatus || []).filter((m: any) => associate.dirigeantPersonneMorale?.dirigeantStatus?.toLowerCase() === m?.value);
        role += ', ' + (match.length > 0 ? match[0].translated : trans(t, 'tree|role.' + associate.dirigeantPersonneMorale?.dirigeantStatus));
      }

    } else {
      // A third party is the associate.
      name = associate.name ?? trans(t, 'tree|unknown');
    }

    let type = null;
    let percentage = null;
    let number = null;

    if (associate?.partTitresPleinePropriete > 0) {
      type = 'pp';
      percentage = associate?.partTitresPleinePropriete;
      number = associate?.nbTitresPleinePropriete;

    } else if (associate?.partTitresNuePropriete > 0) {
      type = 'np';
      percentage = associate?.partTitresNuePropriete;
      number = associate?.nbTitresNuePropriete;

    } else if (associate?.partTitresUsufruit > 0) {
      type = 'us';
      percentage = associate?.partTitresUsufruit;
      number = associate?.nbTitresUsufruit;
    }

    return {
      id: id,
      name: name,
      role: role,
      type: type,
      number: number,
      ratio: montant(percentage, {symbol: '%', precision: 2}),
      img: getImg(target)
    }
  };

  forEach(companies[0].data || [], ((company: any) => {
    const companyVersion = company.version;
    if (!companyVersion) return;

    // Do not show disabled/deleted associates if any.
    let associates = (companyVersion.associates ?? [])
      .filter((associate: any) => associate && (associate.active === true))
      .filter((associate: any) => associate && (associate.deleted === undefined || associate.deleted === null))
      .map((a: any) => formatAssociate(company, a));

    const match: any = (societeTypes || []).filter((m: any) => companyVersion.type === m?.value);
    const societeType: string = match.length > 0 ? match[0].translated : companyVersion.type;

    let societeTypeShort: string = '';
    if (societeType === 'OTHER') {
      societeTypeShort = ucfirst(trans(t, 'other'));
    } else {
      const regExp = /\(([^)]+)\)/;
      const matches = regExp.exec(societeType);
      societeTypeShort = (matches !== null && matches.length > 0 && matches[1].length > 0 ? matches[1] : societeType);
    }

    const c: any = {
      tags: getTags(associates),
      id: company.id, // !! not companyLatestVersion.id here
      raisonSociale: companyVersion.raisonSociale,
      dateCloture: companyVersion.fiscalYearEnd ? trans(t, 'tree|clotureAu') + ' ' + companyVersion.fiscalYearEnd : trans(t, 'tree|clotureNA'),
      capital: shortNumber(companyVersion.capital),
      type: societeTypeShort,
      pid: 'family',
      link: appUri.cie.cli.page.replace(':id', company.id),
      expandLink: descLink,
      linkLabel: ''
    };

    forEach(associates, ((_a: any, index: number) => {
      // Let's try to re-link the parent node if needed.
      const parentCompany: any[] = companies[0].data.filter((_c: any) => _c.version?.id === _a.id);
      if (parentCompany.length > 0) {
        c.pid = parentCompany[0].id;
        c.linkLabel = _a.ratio;
      }

      switch (index) {
        case 0:
          c.beneficiaryName = _a.name;
          c.beneficiaryRole = _a.role;
          c.beneficiaryRatio = _a.ratio;
          c.beneficiaryImg = _a.img;
          break;

        case 1:
          c.beneficiary2Name = _a.name;
          c.beneficiary2Role = _a.role;
          c.beneficiary2Ratio = _a.ratio;
          c.beneficiary2Img = _a.img;
          break;

        case 2:
          c.beneficiary3Name = _a.name;
          c.beneficiary3Role = _a.role;
          c.beneficiary3Ratio = _a.ratio;
          c.beneficiary3Img = _a.img;
          break;

        case 3:
          c.beneficiary4Name = _a.name;
          c.beneficiary4Role = _a.role;
          c.beneficiary4Ratio = _a.ratio;
          c.beneficiary4Img = _a.img;
          break;

        case 4:
          c.beneficiary5Name = _a.name;
          c.beneficiary5Role = _a.role;
          c.beneficiary5Ratio = _a.ratio;
          c.beneficiary5Img = _a.img;
          break;

        default: break;
      }
    }));

    if (associates.length > 5) {
      const otherCount = associates.length - 5;
      c.moreBeneficiariesLink = '+' + otherCount + ' ' + trans(t, 'tree|other', otherCount);
    }

    nodes.push(c);
  }));

  return nodes;
}

export const onChartNodeDetailClick = (node: any, navigate: any) => {
  if (!node?.link) return;

  navigate(node.link);
};
