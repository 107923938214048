import React from 'react';

import { Card } from 'primereact/card';
import { Timeline } from 'primereact/timeline';
import { classNames } from 'primereact/utils';

import { dateString } from 'utilities';

export const SocieteTimeline = (props: any) => {
  const { data } = props;
  const randColors = ['#9C27B0', '#673AB7', '#FF9800', '#607D8B'];
  const randIcons = ['cog', 'envelope', 'check', 'calendar'];

  const customizedContent = (item: any) => {
    const defaultTitle: string = (item.title || item.label || '').replace('\n', ' - ');

    return (
      <Card title={defaultTitle} subTitle={dateString(item.date)} className={'a8-timeline'}>
        {item.description && (
          <ul className={'px-5'}>
            {((item.description || '').split('\n') || []).map((event: string, index: number) => (
              <li key={index} style={{listStyle: 'none'}}>{event}</li>
            ))}
          </ul>
        )}
      </Card>
    );
  };

  const customizedMarker = (item: any) => {
    let icon = randIcons[Math.floor(Math.random() * 4)];
    let color = randColors[Math.floor(Math.random() * 4)];

    if (item.icon && item.icon === 'acte') {
      icon = 'calendar';
      color = '#FF9800';
    } else if (item.icon && item.icon === 'bodacc_type_b') {
      icon = 'cog';
      color = '#673AB7';
    } else if (item.icon && item.icon === 'bodacc_type_a') {
      icon = 'dollar';
      color = '#607D8B';
    }

    return <span className={'flex w-2rem h-2rem align-items-center justify-content-center text-white border-circle z-1 shadow-2'} style={{backgroundColor: color}}>
      <i className={classNames('marker-icon', 'pi pi-' + icon)}></i>
    </span>;
  };

  return (
    <>
      {data?.length > 0 && (
        <Timeline
          value={data.sort((a: any, b:any) => a.date > b.date ? -1 : 1)}
          align={'alternate'}
          className={'customized-timeline'}
          marker={customizedMarker}
          content={customizedContent}
        />
      )}
    </>
  );
};
