import React, { useEffect, useState } from 'react';
import { Dropdown } from 'primereact/dropdown';
import { Tooltip } from 'primereact/tooltip';
import { Button } from 'primereact/button';
import { Tag } from 'primereact/tag';

import { AxiosResponse } from 'axios';

import { dateString, trans, ucfirst } from 'utilities';
import { suspendEconomicData, validateEconomicData } from '../annualData';
import { IRequestParams } from 'services';

export const ExerciceFiscal = (props: any) => {
  const { baseUri, appState, apim, step } = props;
  const { t, navigate } = apim.di();

  const [errored, setErrored] = useState<boolean>(false);
  const [economicData, setEconomicData] = useState<any>(null);
  const [economicDataChanged, setEconomicDataChanged] = useState<number>(Date.now());
  const [economicDataPatchLoading, setEconomicDataPatchLoading] = useState<boolean>(false);

  useEffect(() => {
    apim.fetchEntities({
      resourceType: 'economicData',
      params: [{label: 'company', value: appState.company()?.id}, {label: 'fiscalYear', value: appState.exercise()}],
      cache: false,
      setErrored: setErrored,
      success: (res: AxiosResponse) => {
        if (res?.data && (res?.data['hydra:member'] || []).length > 0) {
          setEconomicData(res.data['hydra:member'][0]);
        } else {
          setEconomicData(null);
        }
      }
    } as IRequestParams).then();
  }, [appState.company()?.id, appState.company()?.latestVersion?.id, appState.exercise(), economicDataChanged]); // eslint-disable-line react-hooks/exhaustive-deps

  const defaultEFItemTemplate = (option: any) => option ? (
    <div className={'flex align-items-center'}>
      <div>{option.year} - </div>
      <small className={'pl-2'}>{dateString(option.start) + ' ' + trans(t, 'au') + ' ' + dateString(option.end)}</small>
    </div>
  ) : (<span>{trans(t, 'form|select')}</span>);

  return <div className={'flex-none flex p-2'}>
    {(appState.dossier()?.companies || []).length > 0 && (
      <div className={'flex-none flex pr-3'}>
        <div className={'field p-inputgroup m-0'}>
            <span className={'p-inputgroup-addon'}>
              <i className={'pi pi-building'}></i>
            </span>
          <span className={'p-float-label'}>
              <Dropdown id={'a8-company-select'} value={appState.company()?.latestVersion?.id}
                        options={appState.dossier()?.companies || []} placeholder={trans(t, 'form|select')}
                        optionLabel={'company.latestVersion.raisonSociale'} optionValue={'company.latestVersion.id'}
                        onChange={(e) => {
                          appState.setCompany(e.value);
                          navigate(baseUri.replace(':company', e.value).replace(':year', appState.exercise()));
                        }}/>
              <label htmlFor={'a8-company-select'} className={'text-ucfirst'}>{trans(t, 'entreprise')}</label>
            </span>
        </div>
      </div>
    )}

    {appState.company()?.exercicesFiscaux.length > 0 && (
      <div className={'flex-none flex align-items-center'}>
        <div className={'field p-inputgroup m-0'}>
            <span className={'p-float-label'}>
              <Dropdown id={'a8-exercise-select'} value={appState.exercise(true)} optionLabel={'year'} optionValue={'year'} placeholder={trans(t, 'form|select')}
                        options={(appState.company()?.exercicesFiscaux || []).sort((a: any, b: any) => a.year > b.year ? -1 : 1)} itemTemplate={defaultEFItemTemplate}
                        onChange={(e) => {
                          appState.setExercise(e.value.toString());
                          appState.setCivilYear(e.value.toString()); // Let's keep these 2 synchronized ATM.
                          navigate(baseUri.replace(':company', appState.company()?.id).replace(':year', e.value.toString()) + (step ? '/' + step : ''));
                        }}/>
              <label htmlFor={'a8-exercise-select'} className={'text-ucfirst'}>{trans(t, 'exercise')}</label>
            </span>
          <span className={'p-inputgroup-addon'}>
            <i className={'pi pi-calendar'}></i>
          </span>
        </div>
        {appState.exercise() !== null && appState.exerciseDurationMonths() !== 12 && (
          <Tag severity="info" className={'a8-exercise-duration-months ml-3 min-w-max border-round-xl'} rounded>
            {appState.exerciseDurationMonths() + ' ' + trans(t, 'month_plural')}
          </Tag>
        )}
      </div>
    )}

    <div className={'pl-5'}>
      {!errored ? (
        economicData && economicData.validated ? (
          <span>
          <Tooltip target={'.a8-dossier-suspend-economic-data'} position={'top'} mouseTrack/>
          <Button label={ucfirst(trans(t, 'form|company.exerciceFiscalData.suspendShort'))}
                  icon={'pi pi-' + (economicDataPatchLoading ? 'spinner pi-spin' : 'ban')}
                  severity={'warning'} style={{paddingTop: '12px'}}
                  onClick={() => suspendEconomicData(apim, economicData, setEconomicDataPatchLoading, () => {
                    setEconomicDataChanged(Date.now());
                  })}
                  data-pr-tooltip={ucfirst(trans(t, 'form|company.exerciceFiscalData.suspend'))}
                  data-pr-position={'top'} data-pr-at={'top'} data-pr-my={'top'}
                  className={'a8-dossier-suspend-economic-data'}/>
        </span>
        ) : (
          <span>
          <Tooltip target={'.a8-dossier-validate-economic-data'} position={'top'} mouseTrack/>
          <Button label={ucfirst(trans(t, 'form|company.exerciceFiscalData.validateShort'))}
                  icon={'pi pi-' + (economicDataPatchLoading ? 'spinner pi-spin' : 'check')}
                  severity={'success'} style={{paddingTop: '12px'}}
                  onClick={() => validateEconomicData(apim, economicData, setEconomicDataPatchLoading, () => {
                    setEconomicDataChanged(Date.now());
                  })}
                  data-pr-tooltip={ucfirst(trans(t, 'form|company.exerciceFiscalData.validate'))}
                  data-pr-position={'top'} data-pr-at={'top'} data-pr-my={'top'}
                  className={'a8-dossier-validate-economic-data'}
                  disabled={economicData === null}/>
        </span>
        )
      ) : (
        <></>
      )}
    </div>
  </div>
  ;
};
