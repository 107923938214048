import React, { useState } from 'react';
import { Panel } from 'primereact/panel';
import { PageLoader, PersonnePhysiqueChildren, PersonnePhysiqueParents, PersonnePhysiqueSpouses } from 'components';
import { flatPerson, trans, ucfirst } from 'utilities';

import { omit } from 'lodash';

export const DirigeantFoyer = (props: any) => {
  const { data, apim } = props;
  const { t } = apim.di();

  // Let's flat all fields to root level to simplify nested forms.
  const user: any = data?.user;
  const person = flatPerson(data?.personnePhysique);

  const [parents, setParents] = useState<any>((data?.personnePhysique?.parents || []).map((parent: any) => {
    return {
      ...omit(parent, ['parent']),
      ...{ personnePhysique: parent.parent }
    }
  }));
  const [children, setChildren] = useState<any>((data?.personnePhysique?.children || []).map((child: any) => {
    return {
      ...omit(child, ['child']),
      ...{ personnePhysique: child.child }
    }
  }));
  const [spouses, setSpouses] = useState<any>((data?.personnePhysique?.spouses || []).map((spouse: any) => {
    return {
      ...omit(spouse, ['spouse']),
      ...{ personnePhysique: spouse.spouse }
    }
  }));

  return !user ? (<PageLoader />) : (
    <div className={'grid'}>
          <div className={'col-12 pt-4'}>
            <Panel header={ucfirst(trans(t, 'spouse', spouses?.length))} toggleable>
              <PersonnePhysiqueSpouses rows={spouses} setRows={setSpouses} apim={apim} title={'none'} editMode context={person} />
            </Panel>
          </div>

          <div className={'col-12 pt-4'}>
            <Panel header={ucfirst(trans(t, 'child', children?.length))} toggleable>
              <PersonnePhysiqueChildren rows={children} setRows={setChildren} apim={apim} title={'none'} editMode context={person} />
            </Panel>
          </div>

          <div className={'col-12 pt-4'}>
            <Panel header={ucfirst(trans(t, 'parent', parents?.length))} toggleable>
              <PersonnePhysiqueParents rows={parents} setRows={setParents} apim={apim} title={'none'} editMode context={person} />
            </Panel>
          </div>
    </div>

  );
};
