import { isValidUUID } from 'utilities';

import appUri from 'config/appUri.json';

export const buildTabs = (modulesMenu: any[], module: string, navigate: any) => {
  const subMenu: any[] = modulesMenu.filter((mm: any) => mm.key === module);
  if (!subMenu || subMenu.length < 1) return [];

  return (subMenu[0]['items'] || []).map((sm: any) => {
    return {
      ...sm,
      ...{ command: () => navigate(sm.to) }
    }
  });
};

export const getTabIndex = (tab: string): number => {
  switch (tab) {
    case 'succession':
    case 'deces': // case 'arret':
    case 'donnees-economiques':
    case 'emprunt':
    case 'facultative':
    case 'historique':
      return 1;

    case 'biens':
    case 'contrats': // case 'deces':
    case 'donnees-fiscales':
    case 'negative':
      return 2;

    // case 'contrats':
    case 'donnees-sociales-dirigeant':
      return 3;

    case 'donnees-sociales-collectives':
      return 4;

    default:
      return 0;
  }
};

export const getIndicator = (data: any, key: string) => {
  if (!data?.indicators) return {};

  const check: any = (data?.indicators['hydra:member'] || []).filter((i: any) => i.key === key);
  return check.length > 0 ? check[0] : {};
};

export const getModuleContext = (appState: any, context: any) => {
  const { id, module, tab, type, /*subResource,*/ subResourceId } = context;

  // Let's build baseUri, buildHeader, ... from context (module, tab, ...)
  // defaults to "exercice fiscal / full" context (module + tab)
  let baseUri: string = (appUri.dos.page + appUri.dos.pageExercice)
    .replace(':id', id)
    .replace(':module', module)
    .replace(':tab', tab);
  let buildHeader: string = 'exerciceFiscalHeader';
  let rewritedUri: string = baseUri
    .replace(':company', appState.company()?.id)
    .replace(':year', appState.exercise());
  let editUri: string = appUri.dos.edit.landing.replace(':id', id || '_');

  switch (module) {
    case 'patrimoine':
      buildHeader = isValidUUID(subResourceId) ? '' : 'patrimoineHeader';

      switch (tab) {
        default:
          baseUri = (appUri.dos.page + appUri.dos.pageCivile)
            .replace(':id', id)
            .replace(':module', module)
            .replace(':tab', '')
            .replace('//', '/');
          rewritedUri = baseUri
            .replace(':year', appState.civilYear());
          editUri = appUri.dos.edit.patrimoine.uri
            .replace(':id', id || '_') + '/' + appUri.dos.edit.patrimoine.tabs[0];
          break;

        case 'biens':
          rewritedUri = '';
          editUri = appUri.dos.edit.patrimoine.biens.landing
            .replace(':id', id || '_');
          break;

        case 'contrats':
          rewritedUri = '';
          editUri = appUri.dos.edit.patrimoine.contracts.landing
            .replace(':id', id || '_');
          break;
      }
      break;

    case 'prevoyance':
      buildHeader = tab === 'generale' || 'deces' ? 'prevoyanceHeader' : '';
      rewritedUri = '';

      if (isValidUUID(subResourceId)) {
        editUri = appUri.dos.edit.contracts.uri
          .replace(':id', id || '_')
          .replace(':module', 'prevoyance')
          .replace(':version', subResourceId);
      } else {
        editUri = appUri.dos.edit.prevoyance.uri
          .replace(':id', id || '_') + '/' + appUri.dos.edit.prevoyance.tabs[0];
      }
      break;

    case 'reserves':
      baseUri = (appUri.dos.page + appUri.dos.pageExercice)
        .replace(':id', id)
        .replace(':module', module)
        .replace(':tab', '')
        .replace('//', '/');
      rewritedUri = baseUri
        .replace(':company', appState.company()?.id)
        .replace(':year', appState.exercise());
      editUri = appUri.dos.edit.fiscalYear.uri
        .replace(':id', id || '_')
        .replace(':year', appState.exercise())
        .replace(':company', appState.company()?.id) + appUri.dos.edit.fiscalYear.steps.step7;
      break;

    case 'revenu-et-imposition':
      baseUri = (appUri.dos.page + appUri.dos.pageCivile)
        .replace(':id', id)
        .replace(':module', module)
        .replace(':tab', '')
        .replace('//', '/');
      rewritedUri = baseUri
        .replace(':year', appState.civilYear());
      buildHeader = 'civilYearHeader';
      editUri = appUri.dos.edit.civilYear.uri
        .replace(':id', id || '_')
        .replace(':year', appState.civilYear()) + appUri.dos.edit.civilYear.steps.step2;
      break;

    case 'foyer':
      buildHeader = '';
      rewritedUri = '';
      editUri = appUri.dos.settings.uri.replace(':id', id || '_') + '/foyer';
      break;

    case 'retraite':
      baseUri = (appUri.dos.page + appUri.dos.pageMissionRetraite)
        .replace(':id', id)
        .replace(':module', module)
        .replace(':tab', tab);
      rewritedUri = baseUri
        .replace(':company', appState.company()?.id)
        .replace(':mission', appState.mission()?.id);
      editUri = (appUri.dos.edit.missions.uri + '/' + appState.mission()?.id)
        .replace(':id', id || '_')
        .replace(':module', 'missions')
        .replace(':type', 'retraite')

      buildHeader = 'missionHeader';
      break;

    case 'entreprise':
      editUri = appUri.dos.edit.fiscalYear.uri
        .replace(':id', id || '_')
        .replace(':year', appState.exercise())
        .replace(':company', appState.company()?.id) + appUri.dos.edit.fiscalYear.steps.step1;

      switch (tab) {
        case 'donnees-sociales-dirigeant':
          baseUri = (appUri.dos.page + appUri.dos.pageCivile)
            .replace(':id', id)
            .replace(':module', module)
            .replace(':tab', tab);
          rewritedUri = baseUri
            .replace(':year', appState.civilYear());
          buildHeader = 'civilYearHeader';
          editUri = appUri.dos.edit.civilYear.uri
            .replace(':id', id || '_')
            .replace(':year', appState.civilYear()) + appUri.dos.edit.civilYear.steps.step1;
          break;

        case 'donnees-juridiques':
          baseUri = (appUri.dos.page + appUri.dos.pageCompany)
            .replace(':id', id)
            .replace(':module', module)
            .replace(':tab', tab);
          rewritedUri = baseUri
            .replace(':company', appState.company()?.id)
            .replace(':event', appState.event()?.id);
          buildHeader = 'dossierPHeader';
          break;

        case 'donnees-fiscales':
          editUri = appUri.dos.edit.fiscalYear.uri
            .replace(':id', id || '_')
            .replace(':year', appState.exercise())
            .replace(':company', appState.company()?.id) + appUri.dos.edit.fiscalYear.steps.step2;
          break;

        case 'donnees-sociales-collectives':
          editUri = appUri.dos.edit.fiscalYear.uri
            .replace(':id', id || '_')
            .replace(':year', appState.exercise())
            .replace(':company', appState.company()?.id) + appUri.dos.edit.fiscalYear.steps.step3;
          break;

        default: break;
      }
      break;

    case 'tresorerie':
      switch (tab) {
        case 'generale':
          editUri = appUri.dos.edit.fiscalYear.uri
            .replace(':id', id || '_')
            .replace(':year', appState.exercise())
            .replace(':company', appState.company()?.id) + appUri.dos.edit.fiscalYear.steps.step4;
          break;

        case 'emprunt':
          editUri = appUri.dos.edit.fiscalYear.uri
            .replace(':id', id || '_')
            .replace(':year', appState.exercise())
            .replace(':company', appState.company()?.id) + appUri.dos.edit.fiscalYear.steps.step5;
          break;

        case 'negative':
          editUri = appUri.dos.edit.fiscalYear.uri
            .replace(':id', id || '_')
            .replace(':year', appState.exercise())
            .replace(':company', appState.company()?.id) + appUri.dos.edit.fiscalYear.steps.step6;
          break;

        default: break;
      }
      break;

    case 'missions':
      switch (type) {
        case 'statut_dirigeant':
          baseUri = (appUri.dos.page + appUri.dos.pageMission)
            .replace(':id', id)
            .replace(':type','statut_dirigeant' )
            .replace(':module', 'missions')
            .replace(':mission', appState.mission()?.id);
          rewritedUri = baseUri
            .replace(':company', appState.company()?.id)
            .replace(':mission', appState.mission()?.id);
          editUri = (appUri.dos.edit.missions.uri + '/' + appState.mission()?.id)
            .replace(':id', id || '_')
            .replace(':module', 'missions')
            .replace(':type', 'statut_dirigeant')

          buildHeader = 'missionHeader';
          break;

        case 'remuneration_dirigeant':
          baseUri = (appUri.dos.page + appUri.dos.pageMission)
            .replace(':id', id)
            .replace(':type','remuneration_dirigeant' )
            .replace(':module', 'missions')
            .replace(':mission', appState.mission()?.id);
          rewritedUri = baseUri
            .replace(':company', appState.company()?.id)
            .replace(':mission', appState.mission()?.id);
          editUri = (appUri.dos.edit.missions.uri + '/' + appState.mission()?.id)
            .replace(':id', id || '_')
            .replace(':module', 'missions')
            .replace(':type', 'remuneration_dirigeant')

          buildHeader = 'missionHeader';
          break;

        default:
          buildHeader = '';
          rewritedUri = '';
          baseUri = '';
          editUri = '';
          break;
      }

      switch (tab) {
        case 'nouvelle-demande' :
          baseUri = (appUri.dos.missions.request).replace(':id', id);
          buildHeader = '';
          rewritedUri = '';
          editUri = '';
          break;

        case 'historique' :
          baseUri = (appUri.dos.missions.history).replace(':id', id);
          buildHeader = '';
          rewritedUri = '';
          editUri = '';
          break;
      }
      break;

    default: break;
  }

  return { baseUri, rewritedUri, editUri, header: buildHeader };
}
