import React, { useCallback } from 'react';
import { Button } from 'primereact/button';

import { FormWrapper } from 'forms';
import { trans, triggerFormSubmit } from 'utilities';

import appUri from 'config/appUri.json';

export const Reserves = (props: any) => {
  const { appState, apim, data, additionalData, urls, keyTmp } = props;
  const { t, navigate } = apim.di();
  const cancelUri: string = urls?.landing + '/reserves?maj=' + Date.now();

  // Wrap form render into a useCallback to avoid multiple FormWrapper recalls du to form fields updates.
  const renderForm = useCallback(() =>
      <FormWrapper classes={'grid p-fluid w-12'} formKeyPrefix={'reserves'} resourceType={'reserves'} keyTmp={keyTmp}
                   data={data} additionalData={additionalData} cancelLink redirectUri={cancelUri} multiple />
    , [appState.company()?.id, appState.exercise(), data?.id, keyTmp]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div className={'flex flex-column xl:flex-row flex-wrap fadein animation-duration-500'}>
      {renderForm()}

      <div className={'w-full text-right m-2 mr-3'}>
        <>
          <Button type={'reset'} className={'text-ucfirst p-button-text p-button-danger mr-5'} label={trans(t, 'close')} onClick={() => navigate(cancelUri)} />
          <span className={'p-buttonset'}>
            <Button type={'submit'} className={'text-ucfirst p-button-text'} label={trans(t, 'back')} icon={'pi pi-arrow-left'} onClick={() => navigate(urls?.form + appUri.dos.edit.fiscalYear.steps.step6)} />
            <Button type={'submit'} className={'text-ucfirst'} label={trans(t, 'terminate')} icon={'pi pi-check'} onClick={() => triggerFormSubmit(null)} />
          </span>
        </>
      </div>
    </div>
  );
};
