import React, { useCallback, useEffect, useState } from 'react';
import { AxiosResponse } from 'axios';

import { Button } from 'primereact/button';
import { Fieldset } from 'primereact/fieldset';

import { IRequestParams } from 'services';
import { iri, trans, triggerFormSubmit, ucfirst } from 'utilities';
import { FormWrapper } from 'forms/index';

export const MissionTypesSettings = (props: any) => {
  const { apim, data } = props;
  const { t } = apim.di();

  const [missionTypes, setMissionTypes] = useState<any[]>([])
  const [formData, setFormData] = useState<any[]>([]);

  // fetching mission types then fetching initial data for each mission type
  useEffect(() => {
    if (!data) return;

    apim.fetchEntities({
      resourceType: 'missionTypes',
      setter: setMissionTypes,
      success: (res: AxiosResponse) => {
        res?.data['hydra:member']?.forEach((missionType: any, index: any) => {
          apim.fetchEntities({
            resourceType: 'societeExpertMissionTypes',
            params: [
              { label: 'missionType', value: missionType?.id },
              { label: 'societeExpert', value: data?.id }
            ],
            success: (res: AxiosResponse) => {
              setFormData(prevData => {
                const newData = [...prevData];
                newData[index] = res?.data['hydra:member'][res?.data['hydra:member'].length - 1];
                return newData;
              });
            }
          } as IRequestParams).then();
        });
      }
    } as IRequestParams).then();
  }, [data]); // eslint-disable-line react-hooks/exhaustive-deps

  // render as many forms as mission types and updating form after submitting
  const renderForms = useCallback(() => {

    return (
      <Fieldset legend={ucfirst(trans(t, 'missionType', 2))} toggleable>
        <div className={'flex flex-column ml-4'}>
          <div className='flex w-12 justify-content-end pb-4'>
            <div className='flex justify-content-center w-4'>{trans(t, 'missionTypeSettings.capable')}</div>
            <div className='flex justify-content-center w-4'>{trans(t, 'missionTypeSettings.available')}</div>
          </div>
          {missionTypes.map((missionType: any, index: any) => (
            <div className={'flex flex-row ml-4'} key={'div' + index}>
              <div className={'w-6 pt-3 font-medium medium'} key={'description' + index}>{missionType.description}</div>
              <div className='flex w-full'>
                <FormWrapper key={index} classes={'grid p-fluid w-full'} formKeyPrefix={'dossier_settings'} keepAlive={true}
                             missionType={missionTypes} resourceType={'societeExpertMissionTypes'} cancelLink multiple data={formData[index]}
                             additionalData={{ missionType: missionType?.id, societeExpert: iri('societesExperts', data?.id) }} notif={false} hideReload/>
              </div>
            </div>
          ))}
        </div>
      </Fieldset>
    );
  }, [missionTypes, formData]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      {renderForms()}
      <div className={'w-full text-right mr-8 mt-5'}>
          <Button type={'submit'} className={'text-ucfirst'} label={trans(t, 'save')} icon={'pi pi-check'} iconPos={'right'} onClick={() => triggerFormSubmit()} />
      </div>
    </>
  );
};
