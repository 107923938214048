import React, { useEffect, useState } from 'react';
import { AxiosResponse } from 'axios';
import { Fieldset } from 'primereact/fieldset';

import { isValidUUID, trans, ucfirst } from 'utilities';
import { DossierCompaniesDatatable, PageLoader } from 'components';
import { IRequestParams } from 'services';

export const DirigeantCompanies = (props: any) => {
  const { apim, data } = props;
  const { t } = apim.di();
  const [loading, setLoading] = useState<boolean>(false);
  const [rows, setRows] = useState<any>([]);

  useEffect(() => {
    if (!isValidUUID(data?.personnePhysique?.id)) return;

    setLoading(true);
    apim.call({
      resourceType: 'personnesPhysiques',
      action: 'foyerCompanies',
      id: data?.personnePhysique?.id,
      method: 'get',
      setLoading,
      success: (res: AxiosResponse) => setRows(res?.data?.companies ?? [])
    } as IRequestParams);
  }, [data?.personnePhysique?.id]); // eslint-disable-line react-hooks/exhaustive-deps

  if (loading) return <PageLoader/>;

  return (
    <div className={'flex flex-row flex-wrap fadein animation-duration-500 w-full'}>
      <Fieldset legend={ucfirst(trans(t, 'linkedCompany', rows.length))} toggleable className={'col-12'}>
        <DossierCompaniesDatatable noFilters noGlobalFilter title={'none'} apim={apim} rows={rows ?? []} useVersion={true}/>
      </Fieldset>
    </div>
  );
};
