import React, { useLayoutEffect } from 'react';
import { classNames } from 'primereact/utils';

import * as am5 from '@amcharts/amcharts5';
import * as am5percent from '@amcharts/amcharts5/percent';
import am5themes_Animated from '@amcharts/amcharts5/themes/Animated';
import am5locales_fr_FR from '@amcharts/amcharts5/locales/fr_FR';
import { A8Theme } from './a8Theme';

import { uniqueId } from 'lodash';

// See https://www.amcharts.com/docs/v5/charts/percent-charts/pie-chart/
export const AMChartPie = (props: any) => {
  const { innerRadius, chartData, valueField, labelField, hideLegend, inlineStyle, additionnalClassNames, verticalLegend, alignLabels, seriesCallback } = props;
  const chartId = uniqueId('a8-chart-pie-');

  useLayoutEffect(() => {
    if (!chartData || chartData?.data?.length < 1) return;

    const root = am5.Root.new(chartId);
    root.setThemes([am5themes_Animated.new(root), A8Theme.new(root)]);
    root.numberFormatter.set('numberFormat', '#,###.##');
    root.locale = am5locales_fr_FR;

    // Remove the AMCharts logo.
    // We have a paid licence, so it's fine.
    if (root._logo) root._logo.dispose();

    const chart = root.container.children.push(
      am5percent.PieChart.new(root, {
        layout: root.verticalLayout,
        innerRadius: am5.percent(innerRadius || 60),
      } as any)
    );

    const containerWidth = document.getElementById(chartId)?.offsetWidth

    // Title.
    if (chartData.title) {
      chart.children.unshift(am5.Label.new(root, {
        text: chartData.title,
        textAlign: 'center',
        x: am5.percent(50),
        centerX: am5.percent(50),
        // fontWeight: 'bold',
        fontSize: 18,
        maxWidth:containerWidth,
        oversizedBehavior: 'wrap'
      }));
    }

    // Series.
    const series = chart.series.push(
      am5percent.PieSeries.new(root, {
        name: 'Series',
        valueField: valueField || 'value',
        categoryField: labelField || 'label',
        alignLabels: alignLabels ?? true,
      })
    );

    // Series's slices template.
    // See https://www.amcharts.com/docs/v5/charts/percent-charts/pie-chart/pie-series/#slices
    series.slices.template.setAll({
      // fillOpacity: .95,
      stroke: am5.color(0xffffff),
      strokeWidth: 1,
      tooltipText: '{category} : {valuePercentTotal.formatNumber(\'#.0\')}% ({value})'
    });

    // Series's labels template.
    // See https://www.amcharts.com/docs/v5/charts/percent-charts/pie-chart/pie-series/#configuring-labels
    series.labels.template.setAll({
      textType: 'circular',
      centerX: 0,
      centerY: 0,
      fontSize: 12,
      maxWidth: 100,
      oversizedBehavior: 'wrap'
    });

    // Hide labels for small slices.
    // See https://www.amcharts.com/docs/v5/tutorials/handling-pie-chart-labels-that-dont-fit/#Hide_labels_ticks_for_small_slices
    series.labels.template.adapters.add('y', function(y: any, target: any) {
      let dataItem: any = target.dataItem;
      if (dataItem) {
        let tick = dataItem.get('tick');
        if (tick) {
          if (dataItem.get('valuePercentTotal') < 5) {
            target.set('forceHidden', true);
            tick.set('forceHidden', true);
          }
          else {
            target.set('forceHidden', false);
            tick.set('forceHidden', false);
          }
        }
        return y;
      }
    });

    // Label
    if (chartData.label) {
      root.tooltipContainer.children.push(am5.Label.new(root, {
        x: am5.p50,
        y: am5.percent(56),
        centerX: am5.p50,
        centerY: am5.p50,
        fill: am5.color(0x000000),
        fontSize: 20,
        text: chartData.label
      } as any));
    }

    // Let's hide Label/tick & tooltip if 'labelDisabled' setting [ON].
    series.labels.template.adapters.add('visible', (visible, target) => {
      const settings = ((target.dataItem?.dataContext) as any).settings;
      if (settings) return !settings.labelDisabled;
      return true;
    });

    series.slices.template.adapters.add('tooltipText', (tooltipText, target) => {
      const settings = ((target.dataItem?.dataContext) as any).settings;
      if (settings) return !settings.labelDisabled ? tooltipText : '';
      return tooltipText;
    });

    series.ticks.template.adapters.add('visible', (visible, target) => {
      const settings = ((target.dataItem?.dataContext) as any).settings;
      if (settings) return !settings.labelDisabled;
      return true;
    });

    // Allow override of series.
    if (seriesCallback) {
      seriesCallback(root, chart, series, chartData)
    }

    series.data.setAll(chartData.data);
    series.appear();

    // Add legend
    if (!hideLegend && !verticalLegend) {
      const legend = chart.children.push(am5.Legend.new(root, {
        centerX: am5.percent(50),
        x: am5.percent(50),
        layout: root.horizontalLayout
      }));
      legend.data.setAll(series.dataItems);
    }

    if (!hideLegend && verticalLegend) {
      const legend = chart.children.push(am5.Legend.new(root, {
        layout: root.verticalLayout
      }));

      legend.data.setAll(series.dataItems);
    }

    return () => {
      root.dispose();
    };
  }, [chartData]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div className={'w-full'} style={inlineStyle || null}>
      <div id={chartId} className={classNames('a8-chart', additionnalClassNames)} />
    </div>
  );
};
