import React from 'react';

import { SettingsRouter, StatusRouter } from 'pages';
import appUri from 'config/appUri.json';
import { trimStart } from 'lodash';

export const RoutesSettings = () => {
  return [
    {
      path: trimStart(appUri.set.general) + '/:tab?',
      element: <SettingsRouter />,
    },
    {
      path: trimStart(appUri.status.general),
      element: <StatusRouter />,
    },
    {
      path: trimStart(appUri.status.general) + '/:tab?',
      element: <StatusRouter />,
    },
    {
      path: trimStart(appUri.set.fisc),
      element: <SettingsRouter />,
    },
  ];
};
