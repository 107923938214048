import React, { useMemo } from 'react';
import { Fieldset } from 'primereact/fieldset';

import { trans, ucfirst } from 'utilities';
import { CheckboxField, NumberField } from 'forms';
import { PrevoyanceIndemnitesJournalieresDatatable, PrevoyancePaliersRenteEducationDatatable } from 'components';

export const PrevoyanceObligatoireForm = (props: any) => {
  const { t, control, onFieldChange, errorMessage, getValues, context, apim } = props;
  const { id, decesRenteEducation } = getValues();

  const buildDT = useMemo(() =>
    <PrevoyanceIndemnitesJournalieresDatatable context={context} apim={apim}/>
  , [id]); // eslint-disable-line react-hooks/exhaustive-deps

  const buildDT1 = useMemo(() =>
    <PrevoyancePaliersRenteEducationDatatable context={context} apim={apim}/>
  , [id]); // eslint-disable-line react-hooks/exhaustive-deps

  return <div className={'grid px-3'}>
    <Fieldset legend={ucfirst(trans(t, 'form|prevoyance.incapacite'))} toggleable className={'w-full my-3'}>
      <div className={'grid'}>
        <div className={'col-12'}>
          {buildDT}
        </div>
      </div>
    </Fieldset>

    <Fieldset legend={ucfirst(trans(t, 'form|prevoyance.invalidite'))} toggleable className={'w-full'}>
      <div className={'grid'}>
        <div className={'col-3'}>
          <NumberField classes={'w-full'} fieldKey={'invaliditeTotaleRente'} mode={'currency'}
                       addon={'euro'} addonPosition={'after'} control={control} onFieldChange={onFieldChange} maxFractionDigits={0}
                       errorMessage={errorMessage} label={trans(t, 'form|prevoyance_contrat.invaliditeTotaleRente')} suffix={' ' + trans(t, 'yearly')}/>
        </div>

        <div className={'col-3'}>
          <NumberField classes={'w-full'} fieldKey={'invaliditeTotaleCapital'} mode={'currency'}
                       addon={'euro'} addonPosition={'after'} control={control} onFieldChange={onFieldChange} maxFractionDigits={0}
                       errorMessage={errorMessage} label={trans(t, 'form|prevoyance_contrat.invaliditeTotaleCapital')}/>
        </div>
      </div>
    </Fieldset>

    <Fieldset legend={ucfirst(trans(t, 'form|prevoyance.deces'))} toggleable className={'w-full my-3'}>
      <div className={'grid'}>
        <div className={'col-3'}>
          <NumberField classes={'w-full'} fieldKey={'decesTouteCauseCapital'} mode={'currency'}
                       addon={'euro'} addonPosition={'after'} control={control} onFieldChange={onFieldChange} maxFractionDigits={0}
                       errorMessage={errorMessage} label={trans(t, 'form|prevoyance_contrat.decesTouteCauseCapital')}/>
        </div>

        <div className={'col-3'}>
          <NumberField classes={'w-full'} fieldKey={'decesAccidentelCapital'} mode={'currency'}
                       addon={'euro'} addonPosition={'after'} control={control} onFieldChange={onFieldChange} maxFractionDigits={0}
                       errorMessage={errorMessage} label={trans(t, 'form|prevoyance_contrat.decesAccidentelCapital')} />
        </div>

        <div className={'col-3'}>
          <NumberField classes={'w-full'} fieldKey={'decesDoubleEffet'} mode={'currency'}
                       addon={'euro'} addonPosition={'after'} control={control} onFieldChange={onFieldChange} maxFractionDigits={0}
                       errorMessage={errorMessage} label={trans(t, 'form|prevoyance_contrat.decesDoubleEffet')} />
        </div>

        <div className={'col-3'}>
          <NumberField classes={'w-full'} fieldKey={'decesFraisObseques'} mode={'currency'}
                       addon={'euro'} addonPosition={'after'} control={control} onFieldChange={onFieldChange} maxFractionDigits={0}
                       errorMessage={errorMessage} label={trans(t, 'form|prevoyance_contrat.decesFraisObseques')} />
        </div>

        <div className={'col-3'}>
          <NumberField classes={'w-full'} fieldKey={'decesRenteConjointAnnuelle'} mode={'currency'}
                       addon={'euro'} addonPosition={'after'} control={control} onFieldChange={onFieldChange} maxFractionDigits={0}
                       errorMessage={errorMessage} label={trans(t, 'form|prevoyance_contrat.decesRenteConjointAnnuelle')} suffix={' ' + trans(t, 'yearly')} />
        </div>

        <div className={'col-12'}>
          <CheckboxField classes={'w-full'} fieldKey={'decesRenteEducation'} control={control}
                         onFieldChange={onFieldChange} errorMessage={errorMessage} label={trans(t, 'form|prevoyance_contrat.decesRenteEducation')}/>

          {true === decesRenteEducation && (
            <Fieldset>
              {buildDT1}
            </Fieldset>
          )}
        </div>
      </div>
    </Fieldset>
  </div>;
};
