import React, { useCallback, useEffect, useState } from 'react';
import { AxiosResponse } from 'axios';
import * as am5 from '@amcharts/amcharts5';
import * as am5xy from '@amcharts/amcharts5/xy';

import { TabMenu } from 'primereact/tabmenu';
import { Fieldset } from 'primereact/fieldset';

import { isValidUUID, trans, ucfirst } from 'utilities';
import { IRequestParams, isClient } from 'services';
import { AMChartXY, formatTable, formatXYChart, getModuleId, IndicatorSimple, PageLoader } from 'components';
import { getIndicator } from 'pages';
import { Incapacite } from './tabs/Incapacite';
import { Invalidite } from './tabs/Invalidite';

import { forEach } from 'lodash';
import appUri from 'config/appUri.json';

export const PrevoyanceVueGenerale = (props: any) => {
  const { id, appState, apim, modulesMenu, lastUpdate, themeState, module, tab, subResource } = props;
  const { t } = apim.di();

  const dossier = appState.dossier();
  const [loading, setLoading] = useState<boolean>(false);
  const [data, setData] = useState<any>(null);

  // Override Page Header (defaults into ModuleWrapper, then PrevoyanceRouter).
  useEffect(() => {
    appState.setBreadcrumb([
      isClient()
       ? { label: trans(t, 'dossier', 2) }
       : { label: trans(t, 'dossier', 2), to: appUri.dos.list },
      { label: dossier.title, to: appUri.dos.page.replace(':id', id)},
      { label: trans(t, 'menu|menu.schema_flux.prevoyance_generale') }
    ]);

    appState.setModule(getModuleId(modulesMenu, 'prevoyance.generale'));
  }, [id]); // eslint-disable-line react-hooks/exhaustive-deps

  const fetchData = useCallback(() => {
    if (isValidUUID(appState.prevoyance())) {
      setLoading(true);

      apim.call({
        resourceType: 'dossiers',
        action: 'module',
        id: id + '|' + getModuleId(modulesMenu, 'prevoyance.generale'),
        method: 'get',
        params: [
          {label: 'company', value: appState.company()?.id},
          {label: 'prevoyance', value: appState.prevoyance()}
        ],
        setLoading,
        success: (res: AxiosResponse) => setData({
          ...res.data, ...{
            chartPerso: formatXYChart(res.data, 'prevoyance.chart.perso.general', t, {tradCategories: false}),
            chartPro: formatXYChart(res.data, 'prevoyance.chart.pro.general', t, {tradCategories: false}),
            chartPersoIncapacite: formatXYChart(res.data, 'prevoyance.chart.perso.incapacite', t, {tradCategories: true}),
            chartPersoInvalidite: formatXYChart(res.data, 'prevoyance.chart.perso.invalidite', t, {tradCategories: true}),
            chartPersoDeces: formatXYChart(res.data, 'prevoyance.chart.perso.deces', t, {tradCategories: true}),
            chartProIncapacite: formatXYChart(res.data, 'prevoyance.chart.pro.incapacite', t, {tradCategories: true}),
            chartProInvalidite: formatXYChart(res.data, 'prevoyance.chart.pro.invalidite', t, {tradCategories: true}),
            chartProDeces: formatXYChart(res.data, 'prevoyance.chart.pro.deces', t, {tradCategories: true}),
            tableIncapacite: formatTable(res.data, 'prevoyance.table.incapacite', t, {tradCells: true, tradOnlyFirstHeader: false, autoNr: false}),
            tableInvalidite: formatTable(res.data, 'prevoyance.table.invalidite', t, {tradCells: true, tradOnlyFirstHeader: false, autoNr: false}),
          }
        })
      } as IRequestParams).then();
    }
  }, [appState.company()?.id, appState.prevoyance(), lastUpdate]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    fetchData();
  }, [appState.company()?.id, appState.prevoyance(), lastUpdate]); // eslint-disable-line react-hooks/exhaustive-deps

  const getActiveIndex = useCallback(() => {
    switch (subResource) {
      case 'deces': return 2;
      case 'invalidite': return 1;
      default: return 0;
    }
  }, [subResource]);

  const [activeIndex, setActiveIndex] = useState<number>(getActiveIndex());
  const items = [
    {id: 'incapacite', label: ucfirst(trans(t, 'incapacite')), icon: 'pi pi-fw pi-hourglass'},
    {id: 'invalidite', label: ucfirst(trans(t, 'invalidite')), icon: 'pi pi-fw pi-ban'},
    {id: 'deces', label: ucfirst(trans(t, 'deces')), icon: 'pi pi-fw pi-times'},
  ];

  const colorCallback = (fill: any, target: any) => {
    if (target.dataItem && target.dataItem.get('categoryX') === 'Invalidité') {
      return am5.color('#2543f5');

    } else if (target.dataItem && target.dataItem.get('categoryX') === 'Décès') {
      return am5.color('#bd030a');

    } else if (target.dataItem && target.dataItem.get('categoryX') === 'Besoin non couvert') {
      return am5.color('#bd030a');

    } else if (target.dataItem && target.dataItem.get('categoryX') === 'Contrat') {
      return am5.color('#2543f5');

    } else if (target.dataItem && target.dataItem.get('categoryX') === 'Obligatoire') {
      return am5.color('#d0a20a');
    }

    return fill;
  }

  /**
   * Create a new "besoin" serie line.
   */
  const besoinSerieCallback = (root: am5.Root, chart: am5.SerialChart, xAxis: any, yAxis: any, series: any, seriesOptions: any, chartData: any) => {
    series = chart.series.push(am5xy.LineSeries.new(root, seriesOptions));
    let seriesRangeDataItem = yAxis.makeDataItem({value: 100, endValue: 0});
    let seriesRange = series.createAxisRange(seriesRangeDataItem);
    seriesRange.fills.template.setAll({
      visible: true,
      opacity: 0.3
    });

    seriesRange.fills.template.set("fill", am5.color(0x000000));
    seriesRange.strokes.template.set("stroke", am5.color(0x000000));

    seriesRangeDataItem.get("grid").setAll({
      strokeOpacity: 1,
      visible: true,
      stroke: am5.color(0x000000),
      strokeDasharray: [2, 2]
    })

    seriesRangeDataItem.get("label").setAll({
      location: 0,
      visible: true,
      text: ucfirst(trans(t, 'besoin')),
      inside: true,
      centerX: 0,
      centerY: am5.p100,
      fontSize: 10
    })
  }

  const getContextChart = useCallback((type: string) => {
    let title = null;
    let chartData = null;

    switch (activeIndex) {
      case 2:
        if (type === 'pro') {
          title = data?.chartProDeces?.title;
          chartData = data?.chartProDeces;
        } else {
          title = data?.chartPersoDeces?.title;
          chartData = data?.chartPersoDeces;
        }

        return <>
          <div className={'w-full flex flex-column'}>
            {title !== null && title !== undefined && (
              <h3 className={'flex justify-content-center align-items-center text-xl'}>
                {title}
              </h3>
            )}
            <AMChartXY chartData={chartData} showCursor hideLegend hideTitle verticalLegend={!themeState.isDesktop()} colorCallback={colorCallback} min={0} />
          </div>
        </>;

      case 1:
        if (type === 'pro') {
          title = data?.chartProInvalidite?.title;
          chartData = data?.chartProInvalidite;
        } else {
          title = data?.chartPersoInvalidite?.title;
          chartData = data?.chartPersoInvalidite;
        }

        return <>
          <div className={'w-full flex flex-column'}>
            {title !== null && title !== undefined && (
              <h3 className={'flex justify-content-center align-items-center text-xl'}>
                {title}
              </h3>
            )}
            <AMChartXY chartData={chartData} showCursor hideLegend hideTitle verticalLegend={!themeState.isDesktop()} colorCallback={colorCallback} min={0} />
          </div>
        </>;

      default:
        if (type === 'pro') {
          title = data?.chartProIncapacite?.title;
          chartData = data?.chartProIncapacite;
        } else {
          title = data?.chartPersoIncapacite?.title;
          chartData = data?.chartPersoIncapacite;
        }

        return <>
          <div className={'w-full flex flex-column'}>
            {title !== null && title !== undefined && (
              <h3 className={'flex justify-content-center align-items-center text-xl'}>
                {title}
              </h3>
            )}
            <AMChartXY chartData={chartData} showCursor hideLegend hideTitle verticalLegend={!themeState.isDesktop()} colorCallback={colorCallback} min={0} />
          </div>
        </>;
    }
  }, [data, activeIndex]); // eslint-disable-line react-hooks/exhaustive-deps

  const getContextCouverture = useCallback((type: string) => {
    let _t: string = 'incapacite';
    let _symbol: string = '€';

    switch (activeIndex) {
      case 2: _t = 'deces'; break;
      case 1: _t = 'invalidite'; break;
      default: break;
    }

    const indicator = getIndicator(data, 'prevoyance.' + type + '.' + _t + '.couverture');
    if (_t === 'incapacite') {
      _symbol = '€ / ' + trans(t, 'day');
    } else if (_t === 'invalidite') {
      _symbol = '€ / ' + trans(t, 'year');
    }

    return <IndicatorSimple color={'accent2'} bgImage method={'montant'} symbol={_symbol} title={trans(t, 'chart|prevoyance.' + type + '.' + _t + '.couverture')}
                            value={indicator?.value}
                            tooltip={indicator?.options?.tooltip ? trans(t, 'chart|' + indicator?.options?.tooltip) : null} />;
  }, [data, activeIndex]); // eslint-disable-line react-hooks/exhaustive-deps

  const getContextBesoin = useCallback((type: string) => {
    let _t: string = 'incapacite';
    let _symbol: string = '€';

    switch (activeIndex) {
      case 2: _t = 'deces'; break;
      case 1: _t = 'invalidite'; break;
      default: break;
    }

    const indicator = getIndicator(data, 'prevoyance.' + type + '.' + _t + '.besoin');
    if (_t === 'incapacite') {
      _symbol = '€ / ' + trans(t, 'day');
    } else if (_t === 'invalidite') {
      _symbol = '€ / ' + trans(t, 'year');
    }

    return <IndicatorSimple color={'accent3'} bgImage method={'montant'} symbol={_symbol} title={trans(t, 'chart|prevoyance.' + type + '.' + _t + '.besoin')}
                            value={indicator?.value}
                            tooltip={indicator?.options?.tooltip ? trans(t, 'chart|' + indicator?.options?.tooltip) : null} />;
  }, [data, activeIndex]); // eslint-disable-line react-hooks/exhaustive-deps

  const getContextAutoAssurance = useCallback((type: string) => {
    let _t: string = 'incapacite';
    let _symbol: string = '€';

    switch (activeIndex) {
      case 2: _t = 'deces'; break;
      case 1: _t = 'invalidite'; break;
      default: break;
    }

    const indicator = getIndicator(data, 'prevoyance.' + type + '.' + _t + '.autoassurance');
    if (_t === 'incapacite') {
      _symbol = '€ / ' + trans(t, 'day');
    } else if (_t === 'invalidite') {
      _symbol = '€ / ' + trans(t, 'year');
    }

    return <IndicatorSimple color={'accent6'} bgImage method={'montant'} symbol={_symbol} title={trans(t, 'chart|prevoyance.chart.autoassurance')}
                            value={indicator?.value}
                            tooltip={indicator?.options?.tooltip ? trans(t, 'chart|' + indicator?.options?.tooltip) : null} />;
  }, [data, activeIndex]); // eslint-disable-line react-hooks/exhaustive-deps

  const getDetail = useCallback(() => {
    switch (activeIndex) {
      case 0:
        return <Incapacite dossier={dossier} data={data} apim={apim} themeState={themeState} />;
      case 1:
        return <Invalidite dossier={dossier} data={data} apim={apim} themeState={themeState} />;
      default:
        return null;
    }
  }, [data, activeIndex]); // eslint-disable-line react-hooks/exhaustive-deps

  const displayOptions = (options: any) => {
    const incapaciteRachatPsy = options?.incapaciteRachatPsy ?? false;
    const incapaciteRachatDos = options?.incapaciteRachatDos ?? false;

    return <ul className={'p-0 m-0 list-none text-base line-height-1 text-gray-800'}>
      <li key={'rachat-psy'} className={'mb-2 flex align-items-center'}>{incapaciteRachatPsy ? (<i className={'pi pi-check mr-2'} style={{color: 'green', fontSize: '1rem'}}></i>) : (<i className={'pi pi-times mr-2'} style={{color: 'red', fontSize: '1.2rem'}}></i>)} {trans(t, 'form|prevoyance_contrat.incapaciteRachatPsy')}</li>
      <li key={'rachat-dos'} className={'flex align-items-center'}>{incapaciteRachatDos ? (<i className={'pi pi-check mr-2'} style={{color: 'green', fontSize: '1rem'}}></i>) : (<i className={'pi pi-times mr-2'} style={{color: 'red', fontSize: '1.2rem'}}></i>)} {trans(t, 'form|prevoyance_contrat.incapaciteRachatDos')}</li>
    </ul>
  }

  const displayContractTypes = (contractTypes: any) => {
    let types: any[] = [];
    forEach(contractTypes, ((status: any, index: any) => {
      types.push({
        'key': index,
        'status': status,
      });
    }));

    return <ul className={'p-0 m-0 list-none text-base line-height-1 text-gray-800'}>
      {types.map((type: any, index: number) => (
        <li key={index} className={'mb-2 flex align-items-center'}>
          {type.status ? (
            <i className={'pi pi-check mr-2'} style={{color: 'green', fontSize: '1rem'}}></i>
          ) : (
            <i className={'pi pi-times mr-2'} style={{color: 'red', fontSize: '1rem'}}></i>
          )}

          {trans(t, 'form|prevoyance_contrat.' + type.key)}
        </li>
      ))}
    </ul>
  }

  if (loading) return <PageLoader/>;

  return (
    <div className={'flex flex-column md:flex-row flex-wrap fadein animation-duration-500'}>
      <div className={'flex w-full justify-content-center'}>
        <div className={'flex w-2 py-4 pl-2 pr-3'}>
          <IndicatorSimple color={'primary'} bgImage method={'asIt'} title={trans(t, 'chart|prevoyance.indiceCouverture')} value={getIndicator(data, 'prevoyance.indiceCouverture').value}
                           tooltip={getIndicator(data, 'prevoyance.indiceCouverture').options?.tooltip ? trans(t, 'chart|' + getIndicator(data, 'prevoyance.indiceCouverture').options?.tooltip) : null}/>
        </div>
        <div className={'flex w-2 py-4 pl-2 pr-3'}>
          <IndicatorSimple color={'accent3'} bgImage method={'asIt'} title={trans(t, 'chart|prevoyance.indiceProtection')} value={getIndicator(data, 'prevoyance.indiceProtection').value}
                           tooltip={getIndicator(data, 'prevoyance.indiceProtection').options?.tooltip ? trans(t, 'chart|' + getIndicator(data, 'prevoyance.indiceProtection').options?.tooltip) : null}/>
        </div>
      </div>

      <div className={'grid w-full gap-4 px-3'}>
        <Fieldset className={'col'} legend={trans(t, 'form|prevoyance.couverture.perso')}>
          <h2 className={'flex justify-content-center align-items-center text-xl'}>
            <i className={'pi pi-home mr-5'} style={{fontSize: '2rem', color: 'var(--primary-color)'}}></i>
            {data?.chartPerso.title}
          </h2>

          <div className={'flex'}>
            <div className={'flex h-30rem xl:h-full flex-row flex-grow-1'}>
              <AMChartXY chartData={data?.chartPerso} hideTitle hideLegend showCursor verticalLegend={!themeState.isDesktop()} colorCallback={colorCallback} seriesCallback={besoinSerieCallback} min={0} />
            </div>
          </div>
        </Fieldset>

        <Fieldset className={'col'} legend={trans(t, 'form|prevoyance.couverture.pro')}>
          <h2 className={'flex justify-content-center align-items-center text-xl'}>
            <i className={'pi pi-building mr-5'} style={{fontSize: '2rem', color: 'var(--primary-color)'}}></i>
            {data?.chartPro.title}
          </h2>

          <div className={'flex'}>
            <div className={'flex h-30rem xl:h-full flex-row flex-grow-1'}>
              <AMChartXY chartData={data?.chartPro} hideTitle hideLegend showCursor verticalLegend={!themeState.isDesktop()} colorCallback={colorCallback} seriesCallback={besoinSerieCallback} min={0} />
            </div>
          </div>
        </Fieldset>
      </div>

      <div className={'grid w-full px-3 pt-4'}>
        <div className={'flex w-3 py-4 pl-2 pr-3'}>
          <IndicatorSimple color={'accent2'} bgImage symbol={trans(t, 'day', getIndicator(data, 'prevoyance.franchise').value)} title={trans(t, 'chart|prevoyance.franchise')}
                           value={getIndicator(data, 'prevoyance.franchise').value}
                           tooltip={getIndicator(data, 'prevoyance.franchise').options?.tooltip ? trans(t, 'chart|' + getIndicator(data, 'prevoyance.franchise').options?.tooltip) : null}/>
        </div>

        <div className={'flex w-3 py-4 pl-2 pr-3'}>
          <IndicatorSimple color={'primary'} bgImage method={'bool'} title={trans(t, 'chart|prevoyance.atmp')} value={getIndicator(data, 'prevoyance.atmp').value}
                           tooltip={getIndicator(data, 'prevoyance.atmp').options?.tooltip ? trans(t, 'chart|' + getIndicator(data, 'prevoyance.atmp').options?.tooltip) : null}/>
        </div>

        <div className={'flex w-3 py-4 pl-2 pr-3'}>
          <IndicatorSimple color={'accent3'} bgImage method={displayOptions} title={trans(t, 'chart|prevoyance.options')} value={getIndicator(data, 'prevoyance.options').value}
                           tooltip={getIndicator(data, 'prevoyance.options').options?.tooltip ? trans(t, 'chart|' + getIndicator(data, 'prevoyance.options').options?.tooltip) : null}/>
        </div>

        <div className={'flex w-3 py-4 pl-2 pr-3'}>
          <IndicatorSimple color={'accent'} bgImage method={displayContractTypes} title={trans(t, 'chart|prevoyance.types')} value={getIndicator(data, 'prevoyance.types').value}
                           tooltip={getIndicator(data, 'prevoyance.types').options?.tooltip ? trans(t, 'chart|' + getIndicator(data, 'prevoyance.types').options?.tooltip) : null}/>
        </div>
      </div>

      <div className={'grid w-full px-3 mt-5'}>
        <Fieldset className={'col'} legend={trans(t, 'form|prevoyance.details.title')}>
          <div className={'mb-5'}>
            <TabMenu model={items} activeIndex={activeIndex} onTabChange={(e) => {
              setActiveIndex(e.index);

              // rewrite URL.
              window.history.replaceState(null, '', appUri.dos.page.replace(':id', id) +
                appUri.dos.pageResource
                  .replace(':module?', module)
                  .replace(':tab?', tab)
                  .replace(':subResource?', e.value?.id ?? 'incapacite')
                  .replace('/:subResourceId?', ''));
            }}/>
          </div>

          <div className={'grid w-full gap-4 px-3'} id={'prevoyance-details-wrapper'}>
            <Fieldset className={'col'}>
              <div className={'flex'}>
                {getContextChart('perso')}
              </div>

              <div className={'flex flex-row gap-4 mt-5'}>
                <div className={'col'}>{getContextBesoin('perso')}</div>
                <div className={'col'}>{getContextCouverture('perso')}</div>
                <div className={'col'}>{getContextAutoAssurance('perso')}</div>
              </div>
            </Fieldset>

            <Fieldset className={'col'}>
              <div className={'flex h-30rem xl:h-full flex-row flex-grow-1'}>
                {getContextChart('pro')}
              </div>

              <div className={'flex flex-row gap-4 mt-5'}>
                <div className={'col'}>{getContextBesoin('pro')}</div>
                <div className={'col'}>{getContextCouverture('pro')}</div>
                <div className={'col'}>{getContextAutoAssurance('pro')}</div>
              </div>
            </Fieldset>

            <div className={'mt-5 w-full'}>
              {getDetail()}
            </div>
          </div>
        </Fieldset>
      </div>
    </div>
  );
};
