import React from 'react';
import appUri from 'config/appUri.json';

import { PersonnePhysiqueRouter, PersonnesPhysiquesList } from 'pages';

import { trimStart } from 'lodash';

export const RoutesPerson = () => {
  return [
    {
      path: trimStart(appUri.cie.per.phy.list),
      element: <PersonnesPhysiquesList/>,
    },
    {
      path: trimStart(appUri.cie.per.phy.page) + '/:tab?',
      element: <PersonnePhysiqueRouter />
    },
    {
      path: trimStart(appUri.cie.per.phy.edit) + '/:tab?',
      element: <PersonnePhysiqueRouter action={appUri.reservedKeywords.edit} />,
    },
  ];
};
