import React from 'react';
import { Fieldset } from 'primereact/fieldset';

import { APIListField, MaskField, NumberField, StaticListField, TextField } from 'forms';
import { trans, ucfirst } from 'utilities';

export const DonationForm = (props: any) => {
  const { t, control, onFieldChange, errorMessage, listsOptions } = props;

  return <>
    <Fieldset legend={ucfirst(trans(t, 'general'))} className={'col-12 m-2'} toggleable>
      <div className={'grid w-full'}>
        <div className={'col-3'}>
          <TextField fieldKey={'label'} control={control} onFieldChange={onFieldChange} errorMessage={errorMessage}
                     label={trans(t, 'form|patrimoine.donations.default.label')} classes={'w-full'}/>
        </div>
        <div className={'col-3'}>
          <APIListField listKey={'donationTypes'} fieldKey={'type'} control={control} onFieldChange={onFieldChange} errorMessage={errorMessage}
                        label={trans(t, 'form|patrimoine.donations.default.type')} classes={'w-full'}/>
        </div>
        <div className={'col-3'}>
          <APIListField listKey={'donationExonerationTypes'} fieldKey={'exonerationType'} control={control} onFieldChange={onFieldChange} errorMessage={errorMessage}
                        label={trans(t, 'form|patrimoine.donations.default.exonerationType')} classes={'w-full'}/>
        </div>
        <div className={'col-3'}>
          <MaskField fieldKey={'date'} label={trans(t, 'form|patrimoine.donations.default.date')}
                     control={control} onFieldChange={onFieldChange} errorMessage={errorMessage} isDate classes={'w-full'}/>
        </div>
      </div>

      <div className={'grid w-full'}>
        <div className={'col-3'}>
          <NumberField fieldKey={'montant'} control={control} onFieldChange={onFieldChange} errorMessage={errorMessage}
                       label={trans(t, 'form|patrimoine.donations.default.montant')} addon={'euro'} addonPosition={'after'} classes={'w-full'}/>
        </div>
        <div className={'col-3'}>
          <NumberField fieldKey={'montantAbattementSupplementaire'} control={control} onFieldChange={onFieldChange} errorMessage={errorMessage}
                       label={trans(t, 'form|patrimoine.donations.default.montantAbattementSupplementaire')} addon={'euro'} addonPosition={'after'} classes={'w-full'}/>
        </div>
        <div className={'col-3'}>
          <NumberField fieldKey={'montantBaseTaxable'} control={control} onFieldChange={onFieldChange} errorMessage={errorMessage}
                       label={trans(t, 'form|patrimoine.donations.default.montantBaseTaxable')} addon={'euro'} addonPosition={'after'} classes={'w-full'}
                       tooltip={trans(t, 'form|patrimoine.donations.default.montantBaseTaxableTooltip')}/>
        </div>
        <div className={'col-3'}>
          <NumberField fieldKey={'montantAbattementApplicable'} control={control} onFieldChange={onFieldChange} errorMessage={errorMessage}
                       label={trans(t, 'form|patrimoine.donations.default.montantAbattementApplicable')} addon={'euro'} addonPosition={'after'} classes={'w-full'}
                       tooltip={trans(t, 'form|patrimoine.donations.default.montantAbattementApplicableTooltip')}/>
        </div>
        <div className={'col-3'}>
          <NumberField fieldKey={'montantAbattementUtilise'} control={control} onFieldChange={onFieldChange} errorMessage={errorMessage}
                       label={trans(t, 'form|patrimoine.donations.default.montantAbattementUtilise')} addon={'euro'} addonPosition={'after'} classes={'w-full'}
                       tooltip={trans(t, 'form|patrimoine.donations.default.montantAbattementUtiliseTooltip')}/>
        </div>
        <div className={'col-3'}>
          <NumberField fieldKey={'montantValeurRapportee'} control={control} onFieldChange={onFieldChange} errorMessage={errorMessage}
                       label={trans(t, 'form|patrimoine.donations.default.montantValeurRapportee')} addon={'euro'} addonPosition={'after'} classes={'w-full'}/>
        </div>
        <div className={'col-3'}>
          <StaticListField fieldKey={'personnePhysique'} control={control} onFieldChange={onFieldChange} errorMessage={errorMessage} classes={'w-full'}
                           label={trans(t, 'form|patrimoine.donations.default.quiDonne')} listsOptions={listsOptions} listKey={'personnesPhysiques'} optionLabel={'label'} optionValue={'@id'}/>
        </div>
      </div>
    </Fieldset>
  </>
    ;
};
