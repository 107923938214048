import React, { useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { ProgressBar } from 'primereact/progressbar';
import { Steps } from 'primereact/steps';

import { trans, ucfirst } from 'utilities';
import { Error, NotFound } from 'pages';
import { Loader, PageLoader } from 'components';
import { IRequestParams, useApim } from 'services';
import { useAppState } from 'states';

import { getExerciceFiscalEditContext } from './ModuleHelper';
import { EntrepriseFormRouter, ReservesFormRouter, TresorerieFormRouter } from './tabs/modules';

import appUri from 'config/appUri.json';

export const ExerciceRouterForm = () => {
  const { id, year, company, step } = useParams();
  const apim = useApim();
  const { t, navigate } = apim.di();
  const appState = useAppState();
  const dossier: any = appState.dossier();

  // Update AppState "currents".
  useEffect(() => {
    if (year) {
      appState.setCivilYear(year);
      appState.setExercise(year);
    }
    if (company) appState.setCompany(company);
  }, [year, company]); // eslint-disable-line react-hooks/exhaustive-deps

  const [loading, setLoading] = useState<boolean>(false);
  const [notFound, setNotFound] = useState<boolean>(false);
  const [errored, setErrored] = useState<boolean>(false);

  useEffect(() => {
    if (dossier?.id === id) return;

    // Update AppState Dossier.
    apim.fetchEntity({
      resourceType: 'dossiers',
      id,
      setLoading,
      setErrored,
      setNotFound,
      setter: appState.setDossier,
    } as IRequestParams).then();
  }, [id]); // eslint-disable-line react-hooks/exhaustive-deps

  const [activeIndex, setActiveIndex] = useState<number>(-1);
  const { urls, defaultBC, index } = getExerciceFiscalEditContext(t, appState, { id, year, company, step });

  // Update active index, current company & breadcrumb.
  useEffect(() => {
    setActiveIndex(index);

    appState.setBreadcrumb(defaultBC);
    appState.setPageActions([]);
    appState.setPageTitle(ucfirst(trans(t, 'dossier')) + ' ' + appState.dossier()?.title + ' | ' + trans(t, 'editing'));
  }, [dossier?.id, year, step]); // eslint-disable-line react-hooks/exhaustive-deps

  const wizardItems = [
    { label: ucfirst(trans(t, 'menu|wizard.dossier.exerciceFiscal.step1')), command: () => navigate(urls.form) },
    { label: ucfirst(trans(t, 'menu|wizard.dossier.exerciceFiscal.step2')), command: () => navigate(urls.form + appUri.dos.edit.fiscalYear.steps.step2) },
    { label: ucfirst(trans(t, 'menu|wizard.dossier.exerciceFiscal.step3')), command: () => navigate(urls.form + appUri.dos.edit.fiscalYear.steps.step3) },
    { label: ucfirst(trans(t, 'menu|wizard.dossier.exerciceFiscal.step4')), command: () => navigate(urls.form + appUri.dos.edit.fiscalYear.steps.step4) },
    { label: ucfirst(trans(t, 'menu|wizard.dossier.exerciceFiscal.step5')), command: () => navigate(urls.form + appUri.dos.edit.fiscalYear.steps.step5) },
    { label: ucfirst(trans(t, 'menu|wizard.dossier.exerciceFiscal.step6')), command: () => navigate(urls.form + appUri.dos.edit.fiscalYear.steps.step6) },
    { label: ucfirst(trans(t, 'menu|wizard.dossier.exerciceFiscal.step7')), command: () => navigate(urls.form + appUri.dos.edit.fiscalYear.steps.step7) },
  ];

  const buildTabContent = useCallback(() => {
    switch (activeIndex) {
      case -1:
        return <Loader />
      case 0:
      case 1:
      case 2:
        return <EntrepriseFormRouter appState={appState} apim={apim} step={step} urls={urls}/>;

      case 3:
      case 4:
      case 5:
        return <TresorerieFormRouter appState={appState} apim={apim} step={step} urls={urls}/>;

      case 6:
        return <ReservesFormRouter appState={appState} apim={apim} step={step} urls={urls}/>;

      default:
        return <NotFound asBlock />
    }
  }, [dossier?.id, year, company, step, activeIndex]); // eslint-disable-line react-hooks/exhaustive-deps

  if (!dossier?.id || !appState.company()?.id || !appState.exercise() || loading || (undefined === activeIndex)) return <PageLoader/>;
  if (notFound || -1 === activeIndex) return <NotFound asBlock/>;
  if (errored) return <Error asBlock/>;

  const stepProgressSize = 100 / wizardItems.length;

  return (
    <>
      <div className={'grid'}>
        <div className={'col'}>
          <ProgressBar value={stepProgressSize + activeIndex * stepProgressSize} showValue={false}/>
        </div>
      </div>
      <Steps model={wizardItems} activeIndex={activeIndex} onSelect={(e) => setActiveIndex(e.index)} readOnly={false}/>
      {buildTabContent()}
    </>
  );
};
