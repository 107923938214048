import React, { useCallback, useEffect, useState } from 'react';
import { Column } from 'primereact/column';

import { dialog, isValidUUID, trans, triggerFormReset, triggerFormSubmit, ucfirst, uuidFromIri } from 'utilities';
import { amountCell, amountEditor, companyCell, DatatableWrapper, onPersonneMoraleAddSubmit, onPersonnePhysiqueAddSubmit, personCell } from 'components';
import { IRequestParams } from 'services';
import { FormWrapper } from 'forms';

import appUri from 'config/appUri.json';

export const EconomicDataAccountsDatatable = (props: any) => {
  const { apim, data, context, title, editMode } = props;
  const { t } = apim.di();
  const dossier = isValidUUID(context?.dossier?.id) ? context?.dossier?.id : uuidFromIri(context?.dossier);

  const [rows, setRows] = useState<any[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const resourceType: string = 'economicDataAccounts';

  useEffect(() => {
    if (!isValidUUID(data?.id)) return;

    setLoading(true);
    apim.fetchEntities({
      resourceType,
      cache: false,
      params: [{label: 'economicData', value: data?.id}],
      setLoading,
      setter: setRows
    } as IRequestParams).then();
  }, [data, context]); // eslint-disable-line react-hooks/exhaustive-deps

  const labelBody = (rowData: any) => {
    if (isValidUUID(rowData?.personnePhysique?.id))
      return personCell({...rowData?.personnePhysique, ...{email: null, phone: null}}, {
        url: appUri.cie.per.phy.page.replace(':id', rowData?.personnePhysique?.id),
        label: trans(t, 'seeDetails')
      });

    if (isValidUUID(rowData?.personneMorale?.id))
      return companyCell(rowData?.personneMorale, t);

    return <div>{ucfirst(trans(t,'other'))}</div>;
  };

  const onNew = () => {
    dialog(t, {
      header: trans(t,'table|add.economicDataAccount'),
      icon: 'none',
      message: renderAddForm(),
      accept: () => triggerFormSubmit('a8-form-economic-data-account-add'),
      reject: () => triggerFormReset('a8-form-economic-data-account-reset')
    });
  };

  // Handle submission then refresh table rows.
  const onAddSubmit = useCallback((formData: any) => {
    // Handle PP case.
    if (formData?.type === 'personne_physique') {
      // Avoid duplicates.
      if (rows.filter((_r: any) => _r?.personnePhysique?.id === formData?.personnePhysiqueId).length > 0) {
        setLoading(false);

        return apim.displayError(trans(t, 'form|errors.alreadyExists.summary'), trans(t, 'form|errors.alreadyExists.detail'));
      }

      return onPersonnePhysiqueAddSubmit(apim, {...data, ...{type: 'personne_physique'}}, formData, rows, setRows, resourceType, setLoading);
    }
    // Handle PM case.
    if (formData?.type === 'personne_morale') {
      // Avoid duplicates.
      if (rows.filter((_r: any) => _r?.personneMorale?.id === formData?.companyId).length > 0) {
        setLoading(false);

        return apim.displayError(trans(t, 'form|errors.alreadyExists.summary'), trans(t, 'form|errors.alreadyExists.detail'));
      }

      return onPersonneMoraleAddSubmit(apim, {...data, ...{type: 'personne_morale'}}, formData, rows, setRows, null, setLoading);
    }
  }, [context?.id, data?.id, rows]); // eslint-disable-line react-hooks/exhaustive-deps

  // Wrap form render into a useCallback to avoid multiple FormWrapper recalls du to form fields updates.
  const renderAddForm = useCallback(() =>
      <FormWrapper classes={'grid p-fluid w-12'} resourceType={resourceType} formKeyPrefix={'add_dialog'} cancelLink multiple context={{ ...context, ...{companyId: dossier} }}
                   resetClass={'a8-form-economic-data-account-reset'} submitClass={'a8-form-economic-data-account-add'} onSubmit={onAddSubmit} hideReload/>
    , [context?.id, data?.id, rows]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <DatatableWrapper resourceType={resourceType} tableKey={'economic-data-accounts'} title={title ?? trans(t, 'ent.comptesCourants')}
                      noFilters noGlobalFilter paginator={false} isLoading={loading} editMode={editMode} editFields={['montant']}
                      onNew={onNew} addTitle={trans(t, 'table|add.economicDataAccount')} onRefresh={setRows} rows={rows}>
      <Column field={'type'} header={ucfirst(trans(t, 'proprietaire'))} body={labelBody}/>
      <Column field={'montant'} header={trans(t, 'amount')} align={'center'} alignHeader={'center'}
              body={(rowData: any) => amountCell(rowData?.montant)}
              editor={(options) => amountEditor(options, {label: trans(t, 'amount')})}/>
    </DatatableWrapper>
  );
};
