import React from 'react';
import { Fieldset } from 'primereact/fieldset';

import { APIAutocompleteField, CheckboxField, MaskField, NumberField, TextField } from 'forms';
import { isValidUUID, trans, ucfirst, uuidFromIri } from 'utilities';
import { creditItemTemplate } from '../../credits';

export const BienDiversForm = (props: any) => {
  const { t, control, onFieldChange, errorMessage, context, getValues } = props;
  const dossier = isValidUUID(context?.dossier?.id) ? context?.dossier?.id : uuidFromIri(context?.dossier);

  return <>
    <Fieldset legend={ucfirst(trans(t, 'form|patrimoine.biens.default.credit'))} className={'col-12 m-2'} toggleable>
      <div className={'col-12'}>
        <APIAutocompleteField fieldKey={'creditBiens'} control={control} onFieldChange={onFieldChange} errorMessage={errorMessage} label={trans(t, 'credit', 2)}
                              getValues={getValues} placeholder={ucfirst(trans(t, 'form|select'))} optionKey={'label'} itemTemplate={creditItemTemplate}
                              resourceType={'credits'} params={[{label: 'byDossierRelatives', value: dossier}]} multiple/>
      </div>
    </Fieldset>

    <Fieldset legend={ucfirst(trans(t, 'fiscal'))} className={'col-12 m-2'} toggleable>
      <div className={'grid w-full'}>
        <div className={'col-3'}>
          <NumberField fieldKey={'montantRevenuBrut'} control={control} onFieldChange={onFieldChange} errorMessage={errorMessage}
                       label={trans(t, 'form|patrimoine.biens.divers.montantRevenuBrut')} addon={'euro'} addonPosition={'after'} classes={'w-full'}/>
        </div>
        <div className={'col-3'}>
          <NumberField fieldKey={'montantRevenuImposable'} control={control} onFieldChange={onFieldChange} errorMessage={errorMessage}
                       label={trans(t, 'form|patrimoine.biens.divers.montantRevenuImposable')} addon={'euro'} addonPosition={'after'} classes={'w-full'}/>
        </div>
      </div>
    </Fieldset>
    <Fieldset legend={ucfirst(trans(t, 'general'))} className={'col-12 m-2'} toggleable>
      <div className={'grid w-full'}>
        <div className={'col-3'}>
          <TextField fieldKey={'label'} control={control} onFieldChange={onFieldChange} errorMessage={errorMessage}
                     label={trans(t, 'form|patrimoine.biens.default.label')} classes={'w-full'}/>
        </div>
        <div className={'col-3'}>
          <TextField fieldKey={'family'} control={control} onFieldChange={onFieldChange} errorMessage={errorMessage}
                     label={trans(t, 'form|patrimoine.biens.default.family')} classes={'w-full'}/>
        </div>
        <div className={'col-3'}>
          <MaskField fieldKey={'purchaseDate'} label={trans(t, 'form|patrimoine.biens.default.date')}
                     control={control} onFieldChange={onFieldChange} errorMessage={errorMessage} isDate classes={'w-full'}/>
        </div>
        <div className={'col-3'}>
          <NumberField fieldKey={'montantPleinePropriete'} control={control} onFieldChange={onFieldChange} errorMessage={errorMessage}
                       label={trans(t, 'form|patrimoine.pp.amount')} addon={'euro'} addonPosition={'after'} classes={'w-full'}/>
        </div>
      </div>
      <div className={'grid w-full'}>
        <div className={'col-3'}>
          <CheckboxField fieldKey={'transmission'} control={control} onFieldChange={onFieldChange} errorMessage={errorMessage} classes={'w-full'}
                         label={trans(t, 'form|patrimoine.biens.default.transmission')} help={trans(t, 'form|patrimoine.biens.default.transmissionHelp')} />
        </div>
        <div className={'col-3'}>
          <CheckboxField fieldKey={'securite'} control={control} onFieldChange={onFieldChange} errorMessage={errorMessage} classes={'w-full'}
                         label={trans(t, 'form|patrimoine.biens.default.securite')} help={trans(t, 'form|patrimoine.biens.default.securiteHelp')} />
        </div>
      </div>
    </Fieldset>

    <Fieldset legend={ucfirst(trans(t, 'civil'))} className={'col-12 m-2'} toggleable>
      <div className={'grid w-full'}>
        <div className={'col-3'}>
          <CheckboxField fieldKey={'impositionRepartieIfi'} control={control} onFieldChange={onFieldChange} errorMessage={errorMessage} classes={'w-full'}
                         label={trans(t, 'form|patrimoine.biens.default.ifi')}/>
        </div>
        <div className={'col-3'}>
          <CheckboxField fieldKey={'avantageMatrimonialPreciput'} control={control} onFieldChange={onFieldChange} errorMessage={errorMessage} classes={'w-full'}
                         label={trans(t, 'form|patrimoine.biens.default.matrimonialAdvantage')}/>
        </div>
      </div>
    </Fieldset>
  </>
;
};
