import { LicencedOrgChart as OrgChart } from 'components';

export const buildA8GroupTemplate = () => {
  const color1: string = '#656565';

  // Main group template overrides.
  OrgChart.templates.group.link = '<path stroke-linejoin="round" stroke="#aeaeae" stroke-width="1px" fill="none" d="M{xa},{ya} {xb},{yb} {xc},{yc} L{xd},{yd}" />';
  OrgChart.templates.group.nodeMenuButton = '';
  OrgChart.templates.group.node = '<rect rx="20" ry="20" x="0" y="0" height="{h}" width="{w}" fill="none" stroke-width="1px" stroke="#CBCDFB"></rect>';
  OrgChart.templates.group.field_0 = '<text data-width="230" style="font-size: 18px;" fill="' + color1 + '" x="{cw}" y="30" text-anchor="middle">{val}</text>'; // = group title
  // Click effect.
  OrgChart.templates.group.ripple = {radius: 50, color: "#070AB0"};

  // Invisible group size.
  OrgChart.templates.invisibleGroup.size = [0, 15];

  // Nasty group to handle some bounds.
  OrgChart.templates.group.min = Object.assign({}, OrgChart.templates.group);
  OrgChart.templates.group.min.imgs = "{val}";
  OrgChart.templates.group.min.img_0 = "";
  OrgChart.templates.group.min.description = '<text data-width="230" data-text-overflow="multiline" style="font-size: 14px;" fill="#aeaeae" x="125" y="103" text-anchor="middle">{val}</text>';
};
