import { Trans, useTranslation } from 'react-i18next';
import { TFunction } from 'i18next';

export const HookTrans = (str :string, count = 1, token = '') => {
  const { t } = useTranslation();

  return Atrans(t, (str ?? ''), count, token);
};

export const Atrans = (t: TFunction, str: string, count: number = 1, token: string = '') => {
  const nsSplit: string[] = (str ?? '').split('|');

  if (0 === count) return nsSplit.length > 1 ? t(nsSplit[1] + '_none', {token: token, ns: nsSplit[0]}) : t((str ?? '') + '_none', {token: token});
  if (1 === count) return nsSplit.length > 1 ? t(nsSplit[1], {token: token, ns: nsSplit[0]}) : t((str ?? ''), {token: token});

  return nsSplit.length > 1 ? t(nsSplit[1] + '_plural', {count: count, token: token, ns: nsSplit[0]}) : t((str ?? '') + '_plural', {count: count, token: token});
};

export const AtransDecorated = (str :string, count = 1, classes = '') => {
  const nsSplit: string[] = (str ?? '').split('|');
  let tKey = nsSplit.length > 1 ? str[1] : (str ?? '');

  if (0 === count) tKey = (str ?? '') + '_none';
  if (1 < count) tKey = (str ?? '') + '_plural';

  return nsSplit.length > 1 ? (
    <Trans i18nKey={tKey} ns={nsSplit[0]}  values={{ count: count }}>keep this <strong className={classes}>value here</strong> and this</Trans>
  ) : (
    <Trans i18nKey={tKey} values={{ count: count }}>keep this <strong className={classes}>value here</strong> and this</Trans>
  )
};
