import React from 'react';

import { Column } from 'primereact/column';

import { trans } from 'utilities';
import { DatatableWrapper, dateEditor, dateTimeCell } from 'components';

export const DatesChangesDatatable = (props: any) => {
  const { data, tableKey, title, onRefresh, apim } = props;
  const { t } = apim.di();

  const dateBodyTemplate = (rowData: any) => dateTimeCell(rowData?.date, {subRow: false});
  const closingDateBodyTemplate = (rowData: any) => dateTimeCell(rowData?.closingDate, {subRow: false, format: 'DD MMMM'});

  return (
    <DatatableWrapper
      resourceType={'exercicesFiscauxDateChanges'} tableKey={tableKey || 'exercices-fiscaux-dates'} noFilters noGlobalFilter
      title={title || trans(t, 'table|exercicesFiscauxDateChangesTitle')} editMode rows={data}
      newRowTemplate={{date: new Date(), closingDate: new Date()}} onRefresh={onRefresh} paginator={false} parentClasses={[]} emptyMessage={trans(t, 'table|exercicesFiscauxDateChangesEmpty')}>
      <Column field={'date'} header={trans(t, 'date')} body={dateBodyTemplate} align={'center'} alignHeader={'center'} editor={(options) => dateEditor(options)} />
      <Column field={'closingDate'} header={trans(t, 'ent.fiscalYearEndDate')} body={closingDateBodyTemplate} align={'center'} alignHeader={'center'} editor={(options) => dateEditor(options)} />
    </DatatableWrapper>
  );
};
