import React, { useCallback } from 'react';

import { Button } from 'primereact/button';

import { isAGE, trans, triggerFormSubmit } from 'utilities';
import { FormWrapper } from 'forms';
import { IRequestParams, isAdmin, isExpertAdmin } from 'services';

import { isEmpty, pick } from 'lodash';
import appUri from 'config/appUri.json';

export const Step2 = (props: any) => {
  const { data, apim, baseUrl, cancelUrl, baseDataPaths, header } = props;
  const { t, navigate} = apim.di();

  const next: string = baseUrl + appUri.cie.cli.wizard.step3;
  // Handle cascade updates related to submitted data.
  const onFormSubmit = (formData: any) => {
    if (!data?.id) return;

    const dossierCompany: any = pick(formData, baseDataPaths);

    // Just navigate to next step if empty formData.
    if (isEmpty(dossierCompany) && isEmpty(formData)) return navigate(next);

    formData.clauses = (formData.clauses || []).map((clause: string) => ({ clause }));

    const updateCieData = () => {
      if (!isEmpty(formData)) {
        apim.patchEntity({
          resourceType: 'dossierCompanyData',
          id: data?.id,
          headers: header,
          data: formData,
          notif: false,
          success: () => navigate(next),
        } as IRequestParams).then();
      }
    };

    if (!isEmpty(dossierCompany)) {
      apim.patchEntity({
        resourceType: 'dossierCompanies',
        id: data?.companyId,
        headers: header,
        data: dossierCompany,
        notif: false,
        success: updateCieData
      } as IRequestParams).then();
    } else {
      updateCieData();
    }
  };

  // Wrap form render into a useCallback to avoid multiple FormWrapper recalls du to form fields updates.
  const renderForm = useCallback(() =>
      <FormWrapper data={data} multiple formKeyPrefix={'company'} resourceType={'dossierCompanies'} onSubmit={onFormSubmit} keepAlive
                   classes={'grid p-fluid w-12'} hideReload cancelLink redirectUri={next} globalDisabled={!isAdmin() && !isExpertAdmin() && !isAGE(data?.event)}/>
    , [data?.id, data?.companyId]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div className={'a8-wizard wizard-societe-client card fadein animation-duration-500'}>
      <h5 className={'pt-3'}>{trans(t, 'menu|wizard.company.client.title.step2')}</h5>
      {renderForm()}

      <div className={'text-right mt-5 mr-3'}>
        <>
          <Button type={'button'} className={'text-ucfirst p-button-text p-button-danger mr-5'} label={trans(t, 'close')} onClick={() => navigate(cancelUrl)}/>
          <span className={'p-buttonset'}>
            <Button type={'button'} className={'text-ucfirst p-button-text'} label={trans(t, 'back')} icon={'pi pi-arrow-left'} onClick={() => navigate(baseUrl + appUri.cie.cli.wizard.step1)}/>
            {!isAdmin() && !isExpertAdmin() && !isAGE(data?.event) ? (
              <Button type={'button'} className={'text-ucfirst p-button-text'} label={trans(t, 'next')} icon={'pi pi-arrow-right'} iconPos={'right'} onClick={() => navigate(next)}/>
            ) : (
              <Button type={'button'} className={'text-ucfirst'} label={trans(t, 'save')} icon={'pi pi-arrow-right'} iconPos={'right'} onClick={() => triggerFormSubmit(null)}/>
            )}
          </span>
        </>
      </div>
    </div>
  );
};
