import React from 'react';
import { Fieldset } from 'primereact/fieldset';

import { trans, ucfirst } from 'utilities';
import { APIAutocompleteField, APIListField, CheckboxField, MaskField, TextField } from 'forms';
import { isAdmin } from 'services';

export const DossierSettingsForm = (props: any) => {
  const {t, control, onFieldChange, errorMessage, getValues} = props;

  const usersFormatter = (rows: any[]) => rows.map((r: any) => {
    return {
      ...r,
      ...{fullName: r.firstName + ' ' + r.lastName + (r.email !== undefined && r.email !== null ? ' (' + r.email + ')' : '')}
    }
  });

  const personnesPhysiquesFormatter = (rows: any[]) => rows.map((r: any) => {
    return {
      ...r,
      ...{fullName: r.firstName + ' ' + r.lastName + (r.email !== undefined && r.email !== null ? ' (' + r.email + ')' : '')}
    }
  });

  let expertAutocompleteParams = [
    {label: 'order[firstName]', value: 'asc'},
    {label: 'order[lastName]', value: 'asc'},
    {label: 'role[]', value: 'ROLE_EXPERT'},
    {label: 'role[]', value: 'ROLE_EXPERT_ADMIN'},
    {label: 'sameGroups', value: true},
  ];

  return <>
    <Fieldset legend={ucfirst(trans(t, 'form|dossier.general'))} toggleable className={'mb-3'}>
      <div className={'grid mx-0'}>
        <TextField fieldKey={'label'} control={control} onFieldChange={onFieldChange} errorMessage={errorMessage}
                   rules={{required: trans(t, 'form|requiredField')}}
                   label={trans(t, 'dos.label')}/>

        <TextField fieldKey={'identifier'} control={control} onFieldChange={onFieldChange} errorMessage={errorMessage}
                   rules={{required: trans(t, 'form|requiredField')}}
                   label={trans(t, 'dos.identifier')}/>
      </div>

      <APIListField listKey={'dossierMaturity'} fieldKey={'maturityLevel'} control={control} onFieldChange={onFieldChange} errorMessage={errorMessage}
                    label={trans(t, 'dos.maturityLevel')} showClear/>
    </Fieldset>

    <Fieldset legend={ucfirst(trans(t, 'form|dossier.societeExpert'))} toggleable className={'mb-3'}>
      <div className={'grid mx-0'}>
        <APIAutocompleteField fieldKey={'societeExpert'} control={control} onFieldChange={onFieldChange} errorMessage={errorMessage} label={trans(t, 'dos.societeExpert')}
                              getValues={getValues} placeholder={ucfirst(trans(t, 'form|select_societe_expert'))} optionKey={'raisonSociale'}
                              resourceType={'societesExperts'} params={[{label: 'order[raisonSociale]', value: 'asc'}]} disabled={!isAdmin()} />

        <TextField fieldKey={'internalIdentifier'} control={control} onFieldChange={onFieldChange} errorMessage={errorMessage}
                   label={trans(t, 'dos.internalIdentifier')} tooltip={trans(t, 'dos.internalIdentifierTooltip')}/>
      </div>

      <APIAutocompleteField fieldKey={'manager'} control={control} onFieldChange={onFieldChange} errorMessage={errorMessage} label={trans(t, 'dos.manager')}
                            getValues={getValues} placeholder={ucfirst(trans(t, 'form|select_user'))} optionKey={'fullName'} formatter={usersFormatter}
                            resourceType={'users'} params={expertAutocompleteParams}/>
    </Fieldset>

    <Fieldset legend={ucfirst(trans(t, 'form|dossier.advanced'))} toggleable className={'mb-3'}>
      <MaskField key={'validated'} fieldKey={'validated'} label={ucfirst(trans(t, 'dos.validationDate'))} control={control}
                 onFieldChange={onFieldChange} errorMessage={errorMessage} isDate/>

      <CheckboxField key={'active'} fieldKey={'active'} control={control} onFieldChange={onFieldChange}
                     errorMessage={errorMessage} label={ucfirst(trans(t, 'dos.active'))}/>
    </Fieldset>

    {isAdmin() && (
      <Fieldset legend={ucfirst(trans(t, 'form|dossier.admin'))} toggleable className={'mb-3'}>
        <p className={'mx-3 flex mb-5'}>
          <i className={'pi pi-info-circle mr-2 mt-1 text-primary'}></i> {ucfirst(trans(t, 'form|admin.zone'))}<br />
          {ucfirst(trans(t, 'form|admin.zoneWarning'))}
        </p>

        <APIAutocompleteField fieldKey={'user'} control={control} onFieldChange={onFieldChange} errorMessage={errorMessage} label={trans(t, 'dos.user')}
                              getValues={getValues} placeholder={ucfirst(trans(t, 'form|select_user'))} optionKey={'fullName'} formatter={usersFormatter}
                              resourceType={'users'} params={[{label: 'order[firstName]', value: 'asc'}, {label: 'order[lastName]', value: 'asc'}]}/>

        <APIAutocompleteField fieldKey={'personnePhysique'} control={control} onFieldChange={onFieldChange} errorMessage={errorMessage} label={trans(t, 'dos.personnePhysique')}
                              getValues={getValues} placeholder={ucfirst(trans(t, 'form|select_personne_physique'))} optionKey={'fullName'} formatter={personnesPhysiquesFormatter}
                              resourceType={'personnesPhysiques'} params={[{label: 'order[firstName]', value: 'asc'}, {label: 'order[lastName]', value: 'asc'}]}/>
      </Fieldset>
    )}
  </>;
};
