import React, { useLayoutEffect } from 'react';
import { classNames } from 'primereact/utils';

import * as am5 from '@amcharts/amcharts5';
import * as am5xy from '@amcharts/amcharts5/xy';
import am5themes_Animated from '@amcharts/amcharts5/themes/Animated';
import am5locales_fr_FR from '@amcharts/amcharts5/locales/fr_FR';
import { A8Theme } from './a8Theme';

import { forEach, uniqueId } from 'lodash';
import appConfig from 'config/appConfig.json';

// See https://www.amcharts.com/docs/v5/charts/xy-chart/
export const AMChartXY = (props: any) => {
  const { chartData, xType, timeUnit, xField, yField, labelField, tooltipField,
    maxPrecision, min, extraMin, max, extraMax, hideLegend, hideTitle, hideXLabels,
    hideYLabels, hideTooltips, showCursor, inlineStyle, additionnalClassNames, verticalLegend,
    colorCallback, strokeColorCallback, fillColorCallback, seriesOptionsCallback, seriesCallback } = props;
  const chartId = uniqueId('a8-chart-xy-');

  useLayoutEffect(() => {
    if (!chartData || chartData?.data?.length < 1) return;

    const containerWidth = document.getElementById(chartId)?.offsetWidth;
    const root = am5.Root.new(chartId);

    // Remove the AMCharts logo.
    // We have a paid licence, so it's fine.
    if (root._logo) root._logo.dispose();

    root.setThemes([am5themes_Animated.new(root), A8Theme.new(root)]);
    root.numberFormatter.set('numberFormat', '#,###.##');
    root.locale = am5locales_fr_FR;
    if (xType === 'date') {
      root.timezone = am5.Timezone.new(appConfig.datetime.defaultTimezone);
      root.locale.firstDayOfWeek = 1;
    }

    // Create chart.
    const chart = root.container.children.push(am5xy.XYChart.new(root, {
      panX: false,
      panY: false,
      // wheelX: 'panX',
      // wheelY: 'zoomX',
      // pinchZoomX: true,
      layout: root.verticalLayout,
    } as any));

    // Create chart title.
    if (!hideTitle && chartData.title) {
      chart.children.unshift(am5.Label.new(root, {
        text: chartData.title,
        textAlign: 'center',
        x: am5.percent(50),
        centerX: am5.percent(50),
        fontSize: 18,
        marginBottom: 5,
        maxWidth: containerWidth,
        oversizedBehavior: 'wrap'
      }));
    }

    // Create axes renderers
    let xRenderer = am5xy.AxisRendererX.new(root, {
      minGridDistance: 30,
      minorGridEnabled: true,
      strokeOpacity: 0.1
    } as any) as any;

    xRenderer.labels.template.setAll({
      paddingTop: 10
    });

    xRenderer.grid.template.setAll({
      location: 1
    });

    if (hideXLabels) {
      xRenderer.labels.template.setAll({
        visible: false,
      });
    }

    let yRenderer = am5xy.AxisRendererY.new(root, {
      strokeOpacity: 0.1
    } as any) as any

    if (hideYLabels) {
      yRenderer.labels.template.setAll({
        visible: false,
      });
    }

    // Create axes.
    // https://www.amcharts.com/docs/v5/charts/xy-chart/axes/
    let xAxis: any;
    switch (xType) {
      case 'date':
        xAxis = chart.xAxes.push(am5xy.DateAxis.new(root, {
          renderer: xRenderer,
          baseInterval: {
            timeUnit: timeUnit || 'year',
            count: 1
          },
        } as any));
        break;

      default:
        xAxis = chart.xAxes.push(am5xy.CategoryAxis.new(root, {
          renderer: xRenderer,
          maxDeviation: 0.3,
          categoryField: xField || 'category',
        } as any));
        break;
    }

    // Create the yAxis.
    const yAxis = chart.yAxes.push(am5xy.ValueAxis.new(root, {
      maxDeviation: 0.3,
      min: min !== null ? min : null,
      max: max !== null ? max : null,
      extraMax: extraMax !== null ? extraMax : null,
      extraMin: extraMin !== null ? extraMin : null,
      maxPrecision: maxPrecision !== null ? maxPrecision : null,
      renderer: yRenderer,
    } as any));

    // Create the xAxis.
    let xAxisData = [];

    if (chartData.ranges?.x) {
      // Use the range if defined.
      xAxisData = chartData.ranges?.x;
    } else {
      // Use the series values.
      if (chartData.data.length > 0) {
        for (let i = 0; i < chartData.data.length; i++) {
          const serieValues = chartData.data[i].values;
          if (serieValues.length > 0) {
            for (let j = 0; j < serieValues.length; j++) {
              const serieValue = serieValues[j];
              if (serieValue.type === 'category') {
                let found = false;

                // Make sure we do not add the same category twice
                for (let k = 0; k < xAxisData.length; k++) {
                  if (xAxisData[k].category === serieValue.category) {
                    found = true; break;
                  }
                }

                if (!found) {
                  xAxisData.push(serieValue);
                }

              } else {
                xAxisData.push(serieValue);
              }
            }
          }
        }
      }
    }

    xAxis.data.setAll(xAxisData)

    // Create series
    // https://www.amcharts.com/docs/v5/charts/xy-chart/series/
    forEach(chartData.data, (serie: any) => {
      let series: any;

      let tooltipFieldInner: any = tooltipField || (serie.tooltipField ?? 'tooltip');
      const seriesOptions = {
        name: serie[labelField || 'label'],
        xAxis: xAxis,
        yAxis: yAxis,
        valueYField: yField || 'value',
        sequencedInterpolation: serie.sequencedInterpolation ?? false,
        stacked: serie.stacked ?? false,
      } as any;

      // Show series tooltips.
      if (!hideTooltips) {
        let autoTextColor = true;
        if (serie.tooltipColor) {
          autoTextColor = false;
        }

        seriesOptions.tooltip = am5.Tooltip.new(root, {
          autoTextColor: autoTextColor,
          pointerOrientation: 'horizontal',
          labelText: '{hoverTooltip}',
        } as any) as any;

        if (serie.tooltipColor) {
          seriesOptions.tooltip.label.setAll({
            fill: am5.color(serie.tooltipColor)
          });
        }
      }

      // Allow override of series options.
      if (seriesOptionsCallback) {
        seriesOptionsCallback(seriesOptions)
      }

      // Override per X axis type.
      switch (xType) {
        case 'date':
          seriesOptions.valueXField = xField || 'date';
          break;

        default:
          seriesOptions.categoryXField = xField || 'category';
          break;
      }

      switch (serie.seriesType) {
        case 'LineSeries':
          series = chart.series.push(am5xy.LineSeries.new(root, seriesOptions));
          series.strokes.template.setAll({
            strokeWidth: 1,
          });

          // Bullets.
          series.bullets.push((root: any) => {
            return am5.Bullet.new(root, {
              sprite: am5.Circle.new(root, {
                fill: am5.color('#fff'),
                strokeWidth: 3,
                radius: 5,
                stroke: series.get('fill'),
              } as any) as any
            } as any) as any;
          });
          break;

        default:
          // Columns series.
          series = chart.series.push(am5xy.ColumnSeries.new(root, seriesOptions));
          series.columns.template.setAll({
            fillOpacity: serie.fillOpacity ?? 0.8,
            strokeWidth: serie.strokeWidth ?? 1,
            cornerRadiusTL: serie.cornerRadiusTL ?? 5,
            cornerRadiusTR: serie.cornerRadiusTR ?? 5,
            tooltipY: serie.tooltipY ?? 0,
            stroke: serie.stroke ?? null,
            fill: serie.fill ?? null,
          });

          // Bullets.
          series.bullets.push(() => {
            return am5.Bullet.new(root, {
              locationY: 1,
              sprite: am5.Label.new(root, {
                text: '{' + tooltipFieldInner + '}',
                fill: root.interfaceColors.get('alternativeText'),
                centerY: 0,
                centerX: am5.p50,
                populateText: true
              } as any) as any
            } as any) as any;
          });

          // Relocate labels for small columns.
          // @see https://www.amcharts.com/docs/v5/tutorials/hide-or-relocate-label-bullets-for-small-columns/
          series.columns.template.onPrivate('height', function(height: any, target: any) {
            am5.array.each(target.dataItem.bullets, function(bullet: any) {
              if (height === 0) {
                bullet.set('locationY', 1);
                bullet.get('sprite').set('centerY', am5.percent(100));
                bullet.get('sprite').set('fill', am5.color('#000000'));

              } else if (height > -25 && height < 0) {
                bullet.set('locationY', 0.5);
                bullet.get('sprite').set('centerY', am5.percent(height));
                bullet.get('sprite').set('fill', am5.color('#000000'));

              } else if (height > 0 && height < 25) {
                bullet.set('locationY', 0.5);
                bullet.get('sprite').set('centerY', am5.percent(100 + height));
                bullet.get('sprite').set('fill', am5.color('#000000'));

              } else if (height > 0) {
                bullet.set('locationY', 1);
                bullet.get('sprite').set('centerY', am5.percent(0));
                bullet.get('sprite').set('fill', root.interfaceColors.get('alternativeText'));

              } else if (height < 0) {
                bullet.set('locationY', 1);
                bullet.get('sprite').set('centerY', am5.percent(100));
                bullet.get('sprite').set('fill', root.interfaceColors.get('alternativeText'));
              }
            });
          });

          // Column template adapter for fill color.
          series.columns.template.adapters.add('fill', (fill: any, target: any) => {
            if (colorCallback) {
              return colorCallback(fill, target)
            }

            if (fillColorCallback) {
              return fillColorCallback(fill, target)
            }

            return fill;
          });

          // Column template adapter for stroke color.
          series.columns.template.adapters.add('stroke', (stroke: any, target: any) => {
            if (colorCallback) {
              return colorCallback(stroke, target)
            }

            if (strokeColorCallback) {
              return strokeColorCallback(stroke, target)
            }

            return stroke;
          });
          break;
      }

      // Allow override of series.
      if (seriesCallback) {
        seriesCallback(root, chart, xAxis, yAxis, series, seriesOptions, chartData)
      }

      // Make stuff animate on load
      // https://www.amcharts.com/docs/v5/concepts/animations/
      series.data.setAll(serie.values);
      series.appear(1000);
    });

    // Add legend.
    if (!hideLegend && !verticalLegend) {
      const legend = chart.children.push(am5.Legend.new(root, {
        centerX: am5.percent(20),
        x: am5.percent(20),
        marginTop: hideXLabels ? -15 : 15,
        layout: root.horizontalLayout,
      }))

      legend.labels.template.setAll({
        maxWidth: containerWidth,
        textAlign: 'center',
        oversizedBehavior: 'wrap'
      });

      legend.data.setAll(chart.series.values);
    }

    if (!hideLegend && verticalLegend) {
      const legend = chart.children.push(am5.Legend.new(root, {
        centerX: am5.percent(20),
        x: am5.percent(20),
        marginTop: hideXLabels ? -15 : 15,
        layout: root.verticalLayout,
      }))

      legend.labels.template.setAll({
        maxWidth: containerWidth,
        textAlign: 'left',
        oversizedBehavior: 'wrap'
      });

      legend.data.setAll(chart.series.values);
    }

    // Add cursor
    // https://www.amcharts.com/docs/v5/charts/xy-chart/cursor/
    if (showCursor) {
      const cursor = chart.set('cursor' as any, am5xy.XYCursor.new(root, {
        xAxis: xAxis,
        snapToSeries: chart.series.values // display all series tooltip together or separated
      } as any));
      cursor.lineY.set('visible', false);
      cursor.lineX.set('visible', true);
    }

    return () => {
      root.dispose();
    };
  }, [chartData]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div className={'w-full'} style={inlineStyle || null}>
      <div id={chartId} className={classNames('a8-chart', additionnalClassNames)} />
    </div>
  );
};
