import React, { useCallback, useEffect, useState } from 'react';
import { AxiosResponse } from 'axios';

import { ModuleFormWrapper } from 'forms';
import { iri } from 'utilities';
import { IRequestParams } from 'services';
import { PageLoader } from 'components';
import { Error, NotFound } from 'pages';

import { TresorerieGenerale } from './TresorerieGenerale';
import { TresorerieEtEmprunt } from './TresorerieEtEmprunt';
import { TresorerieNegative } from './TresorerieNegative';

import { replace } from 'lodash';
import appUri from 'config/appUri.json';

export const TresorerieFormRouter = (props: any) => {
  const { appState, apim, step, urls } = props;

  const [loading, setLoading] = useState<boolean>(false);
  const [notFound, setNotFound] = useState<boolean>(false);
  const [errored, setErrored] = useState<boolean>(false);

  const [data, setData] = useState<any>(null);
  const [keyTmp, setKeyTmp] = useState<string>('');

  const buildTabContent = useCallback(() => {
    switch (step) {
      case 'tresorerie-emprunt':
        return <TresorerieEtEmprunt appState={appState} apim={apim} data={data} setData={setData} urls={urls} keyTmp={keyTmp}
                                    additionalData={{company: iri('dossierCompanies', appState.company()?.id), fiscalYear: parseInt(appState.exercise())}}/>;
      case 'tresorerie-negative':
        return <TresorerieNegative appState={appState} apim={apim} data={data} urls={urls} keyTmp={keyTmp}
                                   additionalData={{company: iri('dossierCompanies', appState.company()?.id), fiscalYear: parseInt(appState.exercise())}}/>;
      default:
        return <TresorerieGenerale appState={appState} apim={apim} data={data} urls={urls} keyTmp={keyTmp}
                                   additionalData={{company: iri('dossierCompanies', appState.company()?.id), fiscalYear: parseInt(appState.exercise())}}/>;
    }
  }, [data?.id, step]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    setLoading(true);

    apim.fetchEntities({
      resourceType: 'tresoreries',
      params: [{label: 'company', value: appState.company()?.id}, {label: 'fiscalYear', value: appState.exercise()}],
      cache: false,
      setLoading,
      setErrored,
      success: (res: AxiosResponse) => {
        if (res?.data && (res?.data['hydra:member'] || []).length > 0) {
          setData(res.data['hydra:member'][0]);
          setKeyTmp('');
        } else {
          // Let's create an empty set.
          setLoading(true);
          apim.postEntity({
            resourceType: 'tresoreries',
            data: {
              company: iri('dossierCompanies', appState.company()?.id),
              fiscalYear: appState.exercise(true)
            },
            setLoading,
            setErrored,
            success: (res: AxiosResponse) => {
              if (!res?.data?.id) {
                setData(null);
                setKeyTmp('_' + appState.company()?.id + '_' + appState.exercise());
                setNotFound(true);
                return;
              }

              setData(res.data);
              setKeyTmp('');
            }
          } as IRequestParams).then();
        }
      }
    } as IRequestParams).then();
  }, [appState.dossier()?.id, appState.company()?.id, appState.exercise()]); // eslint-disable-line react-hooks/exhaustive-deps

  if (loading) return <PageLoader/>;
  if (notFound) return <NotFound asBlock/>;
  if (errored) return <Error asBlock/>;

  return <ModuleFormWrapper appState={appState} apim={apim} mode={appUri.reservedKeywords.exerciceFiscal} buildTabContent={buildTabContent}
                            baseUri={replace(appUri.dos.edit.fiscalYear.uri, ':id', appState.dossier()?.id || '_')} step={step}/>
};
