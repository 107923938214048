import React, { useCallback, useEffect, useState } from 'react';
import { AxiosResponse } from 'axios';
import { Checkbox } from 'primereact/checkbox';

import { trans } from 'utilities';
import { IRequestParams, isClient } from 'services';
import { AMChartPie, AMChartXY, formatPieChart, formatXYChart, getModuleId, IndicatorSimple, PageLoader } from 'components';
import { getIndicator } from 'pages';

import { isEmpty, replace } from 'lodash';
import appUri from 'config/appUri.json';
import appConfig from 'config/appConfig.json';

export const DonneesSocialesCollectives = (props: any) => {
  const { id, appState, apim, modulesMenu, lastUpdate } = props;
  const { t } = apim.di();

  const dossier = appState.dossier();
  const [loading, setLoading] = useState<boolean>(false);
  const [data, setData] = useState<any>(null);
  const [chart3Ratio, setChart3Ratio] = useState<boolean>(false);

  // Override Page Header (defaults into ModuleWrapper, then EntrepriseRouter).
  useEffect(() => {
    appState.setBreadcrumb([
     isClient()
      ? { label: trans(t, 'dossier', 2) }
      : { label: trans(t, 'dossier', 2), to: appUri.dos.list },
      { label: dossier.title, to: replace(appUri.dos.page, ':id', id)},
      { label: trans(t, 'menu|menu.schema_flux.entreprise_dsc') }
    ]);

    appState.setModule(getModuleId(modulesMenu, 'donnees.sociales.collectives'));
  }, [id]); // eslint-disable-line react-hooks/exhaustive-deps
  const fetchData = useCallback(async () => {
    if (!appState.exercise()) return null;
    setLoading(true);

    apim.call({
      resourceType: 'dossiers',
      action: 'module',
      id: id + '|' + getModuleId(modulesMenu, 'donnees.sociales.collectives'),
      method: 'get',
      params: [{label: 'company', value: appState.company()?.id}, {label: 'year', value: appState.exercise()}],
      cacheMode: isEmpty(lastUpdate) ? 'default' : 'replace',
      setLoading: setLoading,
      success: (res: AxiosResponse) => setData({...res.data, ...{
          chart1: formatPieChart(res.data, 'socialCollectiveData.chart.repartitionCadresNonCadres', t),
          chart2: formatXYChart(res.data, 'socialCollectiveData.chart.congesRtt', t, {tradCategories: false, method: (val: any) => val + ' ' + trans(t, 'jour', val)}),
          chart3: {
            ratio: formatXYChart(res.data, 'socialCollectiveData.chart.masseSalarialePourcentage', t, {tradCategories: false, method: 'percentage'}),
            value: formatXYChart(res.data, 'socialCollectiveData.chart.masseSalariale', t, {tradCategories: false})
          }
      }})
    } as IRequestParams);
  }, [appState.company()?.id, appState.exercise(), lastUpdate]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    fetchData().then();
  }, [appState.company()?.id, appState.exercise(), lastUpdate]); // eslint-disable-line react-hooks/exhaustive-deps

  if (loading) return <PageLoader />;

  const onChart3RatioChange = (e: any) => setChart3Ratio(e.checked || false);

  return (
    <div className={'flex md:flex-row flex-column flex-wrap fadein animation-duration-500'}>
      <div className={'flex flex-column md:w-4 '}>
        <div className={'flex py-4 pl-2 pr-3'}>
          <IndicatorSimple color={'primary'} dark bgImage
                           title={trans(t, 'chart|socialCollectiveData.masseSalarialeTotaleHorsDirigeant')}
                           value={getIndicator(data, 'socialCollectiveData.masseSalarialeTotaleHorsDirigeant').value}
                           precision={getIndicator(data, 'socialCollectiveData.masseSalarialeTotaleHorsDirigeant').options?.precision ?? 1} />
        </div>
        <div className={'flex py-4 pl-2 pr-3'}>
          <IndicatorSimple color={'accent2'} bgImage
                           title={trans(t, 'chart|socialCollectiveData.masseSalarialeTotaleSurCa')}
                           value={getIndicator(data, 'socialCollectiveData.masseSalarialeTotaleSurCa').value} options={{symbol: '%'}}
                           precision={getIndicator(data, 'socialCollectiveData.masseSalarialeTotaleSurCa').options?.precision ?? 1} />
        </div>
      </div>
      <div className={'flex md:w-4  py-4 px-3'}>
        <div className={'flex flex-row flex-grow-1'}>
          {window.innerWidth < appConfig.breakpoints.tablet ? (
            <div className={'flex h-30rem flex-row flex-grow-1'}>
              <AMChartPie chartData={data?.chart1} verticalLegend/>
            </div>
          ) : window.innerWidth < appConfig.breakpoints.notebook ?  (
            <AMChartPie chartData={data?.chart1} verticalLegend/>
          ) : (
            <AMChartPie chartData={data?.chart1} hideLegend/>
          )}
        </div>
      </div>
      <div className={'flex flex-column md:w-4 '}>
        <div className={'flex py-4 pl-2 pr-3'}>
          <IndicatorSimple color={'accent3'} bgImage title={trans(t, 'chart|socialCollectiveData.coutHoraireMoyen')} value={getIndicator(data, 'socialCollectiveData.coutHoraireMoyen').value} options={{precision: 2}} />
        </div>
        <div className={'flex py-4 pl-2 pr-3'}>
          <IndicatorSimple color={'accent3'} bgImage title={trans(t, 'chart|socialCollectiveData.nbHeuresPayees')} value={getIndicator(data, 'socialCollectiveData.nbHeuresPayees').value} options={{symbol: 'h'}} />
        </div>
      </div>

      <div className={'flex md:w-6 xl:w-4 py-4 px-3'}>
        <div className={'flex flex-row flex-grow-1'}>
          {window.innerWidth < appConfig.breakpoints.tablet ? (
            <div className={'flex h-30rem flex-row flex-grow-1'}>
              <AMChartXY chartData={data?.chart2} showCursor hideXLabels verticalLegend min={0} />
            </div>
          ):(
            <AMChartXY chartData={data?.chart2} showCursor hideXLabels min={0} />
          )}
        </div>
      </div>
      <div className={'flex md:w-6 xl:w-8 py-4 px-3'}>
        <div className={'flex flex-row flex-grow-1'}>
          <div className={'flex align-items-center'} style={{position: 'absolute', zIndex: 10}}>
            <label htmlFor={'chart3Ratio'} className={'mr-2'}>{trans(t, 'chart|afficherEnPourcentageDuCA')}</label>
            <Checkbox inputId={'chart3Ratio'} name={'chart3Ratio'} onChange={onChart3RatioChange} checked={chart3Ratio} />
          </div>
          {window.innerWidth < appConfig.breakpoints.tablet ? (
            <div className={'flex h-30rem flex-row flex-grow-1'}>
              <AMChartXY chartData={chart3Ratio ? data?.chart3?.ratio : data?.chart3?.value} showCursor verticalLegend />
            </div>
          ):(
            <AMChartXY chartData={chart3Ratio ? data?.chart3?.ratio : data?.chart3?.value} showCursor />
          )}
        </div>
      </div>

      <div className={'flex flex-column md:w-12 md:flex-row xl:w-4 xl:flex-column'}>
        <div className={'flex py-4 pl-2 pr-3 md:w-6 xl:w-12'}>
          <IndicatorSimple color={'accent3'} bgImage title={trans(t, 'chart|socialCollectiveData.tauxEtNbHeuresSup')} value={getIndicator(data, 'socialCollectiveData.tauxEtNbHeuresSup').value} options={{symbol: 'h'}} percent={getIndicator(data, 'socialCollectiveData.tauxEtNbHeuresSup').badge} />
        </div>
        <div className={'flex py-4 pl-2 pr-3 md:w-6 xl:w-12'}>
          <IndicatorSimple color={'primary'} bgImage title={trans(t, 'chart|socialCollectiveData.ccnNumero')} text={getIndicator(data, 'socialCollectiveData.ccnNumero').value} />
        </div>
      </div>
      <div className={'flex flex-column md:w-12 md:flex-row xl:w-4 xl:flex-column'}>
        <div className={'flex py-4 pl-2 pr-3 md:w-6 xl:w-12'}>
          <IndicatorSimple color={'accent2'} bgImage title={trans(t, 'chart|socialCollectiveData.tauxAbsenteisme')} value={getIndicator(data, 'socialCollectiveData.tauxAbsenteisme').value} options={{symbol: '%', precision: 1}} />
        </div>
        <div className={'flex py-4 pl-2 pr-3 md:w-6 xl:w-12'}>
          <IndicatorSimple color={'primary'} bgImage title={trans(t, 'chart|socialCollectiveData.prevoyanceComplementaire')} text={getIndicator(data, 'socialCollectiveData.prevoyanceComplementaire').value} class={'text-multiline'} />
        </div>
      </div>
      <div className={'flex flex-column md:flex-row md:w-12 xl:w-4 xl:flex-column'}>
        <div className={'flex py-4 pl-2 pr-3 md:w-6 xl:w-12'}>
          <IndicatorSimple color={'primary'} bgImage title={trans(t, 'chart|socialCollectiveData.ccnNom')} text={getIndicator(data, 'socialCollectiveData.ccnNom').value} class={'text-multiline'} valueTitle={getIndicator(data, 'socialCollectiveData.ccnNom').value} />
        </div>
        <div className={'flex py-4 pl-2 pr-3 md:w-6 xl:w-12'}>
          <IndicatorSimple color={'primary'} bgImage title={trans(t, 'chart|socialCollectiveData.santeComplementaire')} text={getIndicator(data, 'socialCollectiveData.santeComplementaire').value} class={'text-multiline'} />
        </div>
      </div>
    </div>
  );
};
