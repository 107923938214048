import React from 'react';
import { Column } from 'primereact/column';

import { shortNumber, trans } from 'utilities';
import { companyAddressBody, DatatableWrapper, dateTimeCell } from 'components';

import appUri from 'config/appUri.json';

export const DossierCompaniesDatatable = (props: any) => {
  const { tableKey, title, params, lazyConfig, rowUri, noFilters, noGlobalFilter, apim, rows, useVersion } = props;
  const { t } = apim.di();

  const filtersEnabled = (!noFilters || false === noFilters);
  const sortable: boolean = (rows ?? []).length === 0;

  let lazyC: any = {};
  if (useVersion) {
    lazyC = {...{
        sortField: 'latestVersion.raisonSociale',
        sortOrder: 1,
        filters: {
          'latestVersion.nomCommercial': { value: '', matchMode: 'contains' },
          'latestVersion.raisonSociale': { value: '', matchMode: 'contains' },
          'siren': { value: '', matchMode: 'contains' },
          'latestVersion.rcsCity': { value: '', matchMode: 'contains' }
        }
      }, ...lazyConfig};
  } else {
    lazyC = {...{
        sortField: 'version.raisonSociale',
        sortOrder: 1,
        filters: {
          'version.nomCommercial': { value: '', matchMode: 'contains' },
          'version.raisonSociale': { value: '', matchMode: 'contains' },
          'siren': { value: '', matchMode: 'contains' },
          'version.rcsCity': { value: '', matchMode: 'contains' }
        }
      }, ...lazyConfig};
  }

  const capitalBodyTemplate = (rowData: any) => shortNumber(useVersion ? rowData?.version?.capital : rowData?.latestVersion?.capital);

  const lastEventDateBodyTemplate = (rowData: any) => {
    const event = useVersion ? rowData.version?.event : rowData?.latestVersion?.event;

    return <>
      <span className={'block font-medium mb-2'}>{event?.label}</span>
      {dateTimeCell(event?.date, {parse: 'YYYY-MM-DD HH:mm:ss', format: 'DD/MM/YYYY'})}
    </>
  }

  const createdBodyTemplate = (rowData: any) => dateTimeCell(rowData.creation, {parse: 'YYYY-MM-DD HH:mm:ss', format: 'DD/MM/YYYY'});

  if (useVersion) {
    return (
      <DatatableWrapper
        resourceType={'dossierCompanies'} tableKey={tableKey || 'dossier-companies'} params={params} lazyConfig={lazyC} rows={rows}
        rowUri={rowUri || appUri.cie.cli.page} paginator={false} title={title || trans(t, 'menu|pages.title.societe.clients')}
        noFilters={noFilters} noGlobalFilter={noGlobalFilter}>
        <Column field={'version.nomCommercial'} header={trans(t, 'ent.nomCommercial')} sortable={sortable} filter={filtersEnabled} />
        <Column field={'version.raisonSociale'} header={trans(t, 'ent.raisonSociale')} sortable={sortable} filter={filtersEnabled} />
        <Column field={'version.type'} header={trans(t, 'type')} align={'center'} sortable={sortable} filter={filtersEnabled} style={{ width: '80px' }} />
        <Column field={'siren'} header={trans(t, 'ent.siren')} align={'center'} sortable={sortable} filter={filtersEnabled} style={{ width: '80px' }} />
        <Column field={'version.headquarterAddress'} header={trans(t, 'address')} align={'center'} alignHeader={'center'} body={companyAddressBody} />
        <Column field={'version.fiscalYearEnd'} header={trans(t, 'ent.dateCloture')} align={'center'} sortable={sortable} filter={filtersEnabled} style={{ width: '100px' }} />
        <Column field={'version.rcsCity'} header={trans(t, 'ent.rcsNumber')} align={'center'} sortable={sortable} filter={filtersEnabled} style={{ width: '150px' }} />
        <Column field={'version.capital'} header={trans(t, 'ent.capital')} align={'center'} sortable={sortable} body={capitalBodyTemplate} style={{ width: '150px' }} />
        <Column field={'version.event.date'} header={trans(t, 'ent.lastEvent')} sortable={sortable} align={'center'} body={lastEventDateBodyTemplate} style={{ width: '275px' }} />
        <Column field={'creation'} header={trans(t, 'created')} sortable={sortable} align={'center'} body={createdBodyTemplate} style={{ width: '225px' }} />
      </DatatableWrapper>
    );
  }

  return (
    <DatatableWrapper
      resourceType={'dossierCompanies'} tableKey={tableKey || 'dossier-companies'} params={params} lazyConfig={lazyC} rows={rows}
      rowUri={rowUri || appUri.cie.cli.page} paginator={false} title={title || trans(t, 'menu|pages.title.societe.clients')}
      noFilters={noFilters} noGlobalFilter={noGlobalFilter}>
      <Column field={'latestVersion.nomCommercial'} header={trans(t, 'ent.nomCommercial')} sortable={sortable} filter={filtersEnabled} />
      <Column field={'latestVersion.raisonSociale'} header={trans(t, 'ent.raisonSociale')} sortable={sortable} filter={filtersEnabled} />
      <Column field={'latestVersion.type'} header={trans(t, 'type')} sortable={sortable} filter={filtersEnabled} style={{ width: '80px' }} />
      <Column field={'siren'} header={trans(t, 'ent.siren')} align={'center'} sortable={sortable} filter={filtersEnabled} style={{ width: '80px' }} />
      <Column field={'latestVersion.headquarterAddress'} header={trans(t, 'address')} align={'center'} alignHeader={'center'} body={companyAddressBody} />
      <Column field={'latestVersion.fiscalYearEnd'} header={trans(t, 'ent.dateCloture')} align={'center'} sortable={sortable} filter={filtersEnabled} style={{ width: '100px' }} />
      <Column field={'latestVersion.rcsCity'} header={trans(t, 'ent.rcsNumber')} align={'center'} sortable={sortable} filter={filtersEnabled} style={{ width: '150px' }} />
      <Column field={'latestVersion.capital'} header={trans(t, 'ent.capital')} align={'center'} sortable={sortable} body={capitalBodyTemplate} style={{ width: '150px' }} />
      <Column field={'latestVersion.event.date'} header={trans(t, 'ent.lastEvent')} sortable={sortable} align={'center'} body={lastEventDateBodyTemplate} style={{ width: '275px' }} />
      <Column field={'creation'} header={trans(t, 'created')} sortable={sortable} align={'center'} body={createdBodyTemplate} style={{ width: '225px' }} />
    </DatatableWrapper>
  );
};
