import React, { useState } from 'react';

import { Button } from 'primereact/button';
import { Fieldset } from 'primereact/fieldset';

import {
  AssociatesPersonnesPhysiquesDatatable,
  DirigeantsPersonnesPhysiquesDatatable,
  EmployeesPersonnesPhysiquesDatatable
} from 'components';
import { isAGE, isValidUUID, trans, ucfirst } from 'utilities';
import { isAdmin, isExpertAdmin } from 'services';

import { omit } from 'lodash';
import appUri from 'config/appUri.json';

export const Step3 = (props: any) => {
  const { data, setData, apim, baseUrl, cancelUrl, header } = props;
  const { t, navigate} = apim.di();

  // Flat personnePhysique on the same level as dirigeantPersonnePhysique.
  const formatAssociates = (_associates: any[]) => {
    return _associates.map((app: any) => {
      if (isValidUUID(app?.dirigeantPersonnePhysique?.personnePhysique?.id)) return {
        ...omit(app, ['dirigeantPersonnePhysique']),
        ...{
          dirigeantPersonnePhysique: omit(app.dirigeantPersonnePhysique, ['personnePhysique']),
          personnePhysique: app.dirigeantPersonnePhysique.personnePhysique
        }
      }
      else if (isValidUUID(app?.personnePhysique?.id)) return app;

      return false;
    });
  };

  // Wrap these data into states to allow datatables to update them.
  const [dirigeants, setDirigeants] = useState<any[]>((data?.dirigeantPersonnesPhysiques || []).filter((a: any) => isAdmin() ? true : a?.active));
  const [employees, setEmployees] = useState<any[]>((data?.employees || []).filter((a: any) => isAdmin() ? true : a?.active));
  const [associates, setAssociatesState] = useState<any[]>(formatAssociates(data?.associates || []).filter((a: any) => {
    if (isValidUUID(a.personnePhysique?.id)) return isAdmin() ? true : a?.active;
    else if (isValidUUID(a.dirigeantPersonnePhysique?.personnePhysique?.id)) return isAdmin() ? true : a?.active;

    return false;
  }));

  const setAssociates = (_associates: any[]) => {
    setAssociatesState(_associates);
    // Refresh data for further steps.
    setData({
      ...data, ...{
        associates: [...(data?.associates || []).filter((a: any) => {
          if (isValidUUID(a.dirigeantPersonnePhysique?.personnePhysique?.id)) return false;
          else if (isValidUUID(a.personnePhysique?.id)) return false;

          return true;
        }), ...formatAssociates(_associates)]
      }
    });
  };

  return (
    <div className={'a8-wizard wizard-societe-client card fadein animation-duration-500'}>
      <h5 className={'pt-3'}>{trans(t, 'menu|wizard.company.client.title.step3')}</h5>

      <div className={'col-12'}>
        <Fieldset legend={ucfirst(trans(t, 'dirigeant', dirigeants.length))} toggleable>
          <DirigeantsPersonnesPhysiquesDatatable header={header} rows={dirigeants} setRows={setDirigeants} apim={apim} title={'none'} editMode={isAdmin() || isExpertAdmin() || isAGE(data?.event)} context={data}/>
        </Fieldset>
      </div>

      <div className={'col-12'}>
        <Fieldset legend={ucfirst(trans(t, 'employee', employees.length))} toggleable>
          <EmployeesPersonnesPhysiquesDatatable header={header} rows={employees} setRows={setEmployees} apim={apim} title={'none'} editMode context={data}/>
        </Fieldset>
      </div>

      <div className={'col-12'}>
        <Fieldset legend={ucfirst(trans(t, 'associate', associates.length))} toggleable>
          <AssociatesPersonnesPhysiquesDatatable header={header} rows={associates} setRows={setAssociates} apim={apim} title={'none'} editMode={isAdmin() || isExpertAdmin() || isAGE(data?.event)} context={data} dirigeants={dirigeants}/>
        </Fieldset>
      </div>

      <div className={'text-right m-2 mr-3'}>
        <>
          <Button type={'reset'} className={'text-ucfirst p-button-text p-button-danger mr-5'} label={trans(t, 'close')} onClick={() => navigate(cancelUrl)}/>
          <span className={'p-buttonset'}>
            <Button type={'submit'} className={'text-ucfirst p-button-text'} label={trans(t, 'back')} icon={'pi pi-arrow-left'} onClick={() => navigate(baseUrl + appUri.cie.cli.wizard.step2)}/>
            <Button type={'submit'} className={'text-ucfirst p-button-text'} label={trans(t, 'next')} icon={'pi pi-arrow-right'} iconPos={'right'} onClick={() => navigate(baseUrl + appUri.cie.cli.wizard.step4)}/>
          </span>
        </>
      </div>
    </div>
  );
};
