import React, { useCallback, useEffect, useState } from 'react';
import { AxiosResponse } from 'axios';

import { Fieldset } from 'primereact/fieldset';
import { Column } from 'primereact/column';

import { dialog, iri, isValidUUID, trans, triggerFormReset, triggerFormSubmit, ucfirst } from 'utilities';
import { useAppState } from 'states';
import { APIListField, CheckboxField, FormWrapper, NumberField, StaticListField } from 'forms';
import { companyCell, DatatableWrapper, onPersonneMoraleAddSubmit, onPersonnePhysiqueAddSubmit, percentCell, percentEditor, personCell } from 'components';
import { IRequestParams } from 'services';

import appUri from 'config/appUri.json';

export const CPContratForm = (props: any) => {
  const { t, control, onFieldChange, errorMessage, getValues, listsOptions, context, apim } = props;
  const { clauseBeneficiaireStandard, type, versementPrestations } = getValues();
  const appState = useAppState();

  const resourceType: string = 'prevoyanceContractBeneficiairies';

  const [loading, setLoading] = useState<boolean>(false);
  const [types, setTypes] = useState<any[]>([]);
  const [rows, setRows] = useState<any[]>([]);

  useEffect(() => {
    apim.getList('prevoyanceContractBeneficiaireTypes', { setter: setTypes } as IRequestParams).then();
    if (!isValidUUID(context?.data?.id)) return;

    setLoading(true);
    apim.fetchEntities({
      resourceType,
      cache: false,
      params: [
        {label: 'contractData', value: context?.data?.id},
        {label: 'expand[]', value: 'personne_physique:read_minimal'},
        {label: 'expand[]', value: 'dossier_company:read'}
      ],
      setLoading,
      setter: setRows
    } as IRequestParams).then();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const onNew = () => {
    dialog(t, {
      header: trans(t,'form|prevoyanceContractBeneficiairiesAdd'),
      icon: 'none',
      message: renderAddForm(),
      accept: () => triggerFormSubmit('a8-form-pcb-add'),
      reject: () => triggerFormReset('a8-form-pcb-reset')
    });
  };

  // Handle submission then refresh table rows.
  const onAddSubmit = useCallback((formData: any) => {
    const postRow = (data: any) => {
      setLoading(true);
      // Create the new row then refresh rows.
      apim.postEntity({
        resourceType,
        notif: false,
        data,
        setLoading,
        success: (resP: AxiosResponse) => {
          if (isValidUUID(resP?.data?.id)) setRows([...rows, ...[resP.data]]);
        }
      } as IRequestParams).then();
    }

    // Handle PP case.
    if (formData?.type === 'personne_physique') {
      // Avoid duplicates.
      if (rows.filter((_r: any) => _r?.personnePhysique?.id === formData?.personnePhysiqueId).length > 0) {
        setLoading(false);

        return apim.displayError(trans(t, 'form|errors.alreadyExists.summary'), trans(t, 'form|errors.alreadyExists.detail'));
      }

      return onPersonnePhysiqueAddSubmit(apim, {...context, ...{type: 'personne_physique'}}, formData, rows, setRows, 'prevoyanceContractBeneficiairies', setLoading);
    }

    // Handle PM case.
    if (formData?.type === 'personne_morale') {
      // Avoid duplicates.
      if (rows.filter((_r: any) => _r?.personneMorale?.id === formData?.companyId).length > 0) {
        setLoading(false);

        return apim.displayError(trans(t, 'form|errors.alreadyExists.summary'), trans(t, 'form|errors.alreadyExists.detail'));
      }

      return onPersonneMoraleAddSubmit(apim, {...context, ...{type: 'personne_morale'}}, formData, rows, setRows, null, setLoading);
    }

    // Handle other cases avoiding duplicates.
    if (rows.filter((_r: any) => _r?.type === formData?.type).length > 0) {
      setLoading(false);

      return apim.displayError(trans(t, 'form|errors.alreadyExists.summary'), trans(t, 'form|errors.alreadyExists.detail'));
    }

    postRow({
      type: formData?.type ?? 'conjoint',
      contractData: iri('prevoyanceContractsData', context?.data?.id)
    });
  }, [context?.id, rows]); // eslint-disable-line react-hooks/exhaustive-deps

  // Wrap form render into a useCallback to avoid multiple FormWrapper recalls du to form fields updates.
  const renderAddForm = useCallback(() =>
      <FormWrapper classes={'grid'} resourceType={resourceType} formKeyPrefix={'add_dialog'} cancelLink multiple context={{ ...context, ...{ dossierId: appState.dossier()?.id }}}
                   resetClass={'a8-form-pcb-reset'} submitClass={'a8-form-pcb-add'} onSubmit={onAddSubmit} hideReload/>
    , [context?.data?.id, types, rows]); // eslint-disable-line react-hooks/exhaustive-deps

  const labelBody = (rowData: any) => {
    if (isValidUUID(rowData?.personnePhysique?.id))
      return personCell({...rowData?.personnePhysique, ...{email: null, phone: null}}, {
        url: appUri.cie.per.phy.page.replace(':id', rowData?.personnePhysique?.id),
        label: trans(t, 'seeDetails')
      });

    if (isValidUUID(rowData?.personneMorale?.id))
      return companyCell(rowData?.personneMorale, t);

    const match: any[] = (types ?? []).filter((_t: any) => _t.value === rowData?.type);

    return match.length > 0 ? <div>{match[0].translated}</div> : '';
  };

  return <div className={'grid w-full px-3'}>
    <div className={'col-3'}>
      <NumberField classes={'w-full'} fieldKey={'cotisationAnnuelle'} mode={'currency'}
                   addon={'euro'} addonPosition={'after'} control={control} onFieldChange={onFieldChange} maxFractionDigits={0}
                   errorMessage={errorMessage} label={trans(t, 'form|prevoyance_contrat.cotisationAnnuelle')} suffix={' ' + trans(t, 'yearly')}/>
    </div>

    <div className={'col-3'}>
      <NumberField classes={'w-full'} fieldKey={'assietteGarantiesArretTravail'} mode={'currency'}
                   addon={'euro'} addonPosition={'after'} control={control} onFieldChange={onFieldChange} maxFractionDigits={0}
                   errorMessage={errorMessage} label={trans(t, 'form|prevoyance_contrat.assietteGarantiesArretTravail')}/>
    </div>

    <div className={'col-3'}>
      {type !== 'homme_cle' && type !== 'frais_generaux' ? (
        <APIListField classes={'w-full'} listKey={'prevoyanceContractProPerso'} fieldKey={'proPerso'} control={control} onFieldChange={onFieldChange} errorMessage={errorMessage}
                      label={trans(t, 'form|prevoyance_contrat.proPerso')}/>
      ) : (
        <APIListField classes={'w-full'} listKey={'prevoyanceContractProPerso'} fieldKey={'proPerso'} control={control} onFieldChange={onFieldChange} errorMessage={errorMessage}
                      label={trans(t, 'form|prevoyance_contrat.proPerso')} disabled defaultValue={'pro'}/>
      )}
    </div>

    {'au_choix' === versementPrestations && (
      <div className={'col-3'}>
        <StaticListField classes={'w-full'} listKey={'versementsPrestations'} fieldKey={'versementPrestationsChoisi'} control={control}
                         listsOptions={{versementsPrestations: listsOptions.versementsPrestations.filter((b: any) => 'au_choix' !== b.value)}}
                         onFieldChange={onFieldChange} errorMessage={errorMessage} label={trans(t, 'form|prevoyance_contrat.versementPrestations')}/>
      </div>
    )}

    <div className={'grid w-full px-3'}>
      <div className={'col-12'}>
        <CheckboxField classes={'w-full'} fieldKey={'clauseBeneficiaireStandard'} control={control} onFieldChange={onFieldChange} errorMessage={errorMessage}
                       label={trans(t, 'form|prevoyance_contrat.clauseBeneficiaireStandard')}
                       help={trans(t, 'form|prevoyance_contrat.clauseBeneficiaireStandard_help')}/>
      </div>

      {false === clauseBeneficiaireStandard && (
        <>
          <Fieldset className={'col-12'}>
            <DatatableWrapper
              resourceType={resourceType} tableKey={'prevoyance-beneficiaires'} noFilters noGlobalFilter editFields={['percentage']} onNew={onNew} loading={loading}
              rows={(rows || []).sort((a: any, b: any) => a.personnePhysique?.lastName?.localeCompare(b.personnePhysique?.lastName) || a.personnePhysique?.firstName?.localeCompare(b.personnePhysique?.firstName))}
              title={trans(t, 'table|prevoyanceBeneficiairesTitle')} editMode paginator={false} parentClasses={[]}>
              <Column field={'type'} header={ucfirst(trans(t, 'beneficiary'))} body={labelBody}/>
              <Column field={'percentage'} header={trans(t, 'table|percentage')} align={'center'} alignHeader={'center'}
                      body={(rowData: any) => percentCell(rowData?.percentage)}
                      editor={(options) => percentEditor(options, {label: trans(t, 'table|percentage')})}/>
            </DatatableWrapper>
          </Fieldset>
        </>
      )}
    </div>
  </div>;
};
