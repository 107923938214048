import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Button } from 'primereact/button';
import { Column } from 'primereact/column';
import { Tooltip } from 'primereact/tooltip';

import { isValidUUID, trans, ucfirst } from 'utilities';
import { amountCell, apiListCell, DatatableWrapper, dateTimeCell, ManagedImage } from 'components';
import { IRequestParams, isAdmin, isExpert } from 'services';

import appUri from 'config/appUri.json';

export const PatrimoineBiensImmobiliersDatatable = (props: any) => {
  const { apim, appState, context, title, editMode } = props;
  const { t, navigate } = apim.di();

  const [rows, setRows] = useState<any[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [types, setTypes] = useState<any[]>([]);
  const [famillesA, setFamillesA] = useState<any[]>([]);
  const [famillesB, setFamillesB] = useState<any[]>([]);

  useEffect(() => {
    apim.getList('bienImmobilierTypes', {setter: setTypes} as IRequestParams).then();
    apim.getList('bienImmobilierJouissanceFamilles', {setter: setFamillesA} as IRequestParams).then();
    apim.getList('bienImmobilierRapportFamilles', {setter: setFamillesB} as IRequestParams).then();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps
  const resourceType: string = 'patrimoineBiensImmobiliers';

  useEffect(() => {
    setLoading(true);
    apim.fetchEntities({
      resourceType,
      params: [{label: 'patrimoine', value: context?.id}, {label: 'expand[]', value: 'bien_immobilier:read'}],
      cache: editMode === false,
      setter: setRows,
      setLoading,
      formatter: (_arr: any[]) => _arr.map((row: any) => ({...row?.bienImmobilier, ...{id: row?.id, bienImmobilierId: row?.bienImmobilier?.id}}))
    } as IRequestParams).then();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const onNew = () => {
    return (!isValidUUID(appState?.dossier()?.id) || !isValidUUID(context?.id)) ? null :
    navigate(appUri.dos.edit.patrimoine.biens.uri.replace(':id', appState?.dossier()?.id).replace(':type', 'immobilier').replace('/:bienId?', ''));
  };

  const labelBodyTemplate = (rowData: any) =>
    <div className={'flex align-items-center'}>
      {isValidUUID(rowData?.image) && (
        <div className={'flex flex-column mr-2'}>
          <ManagedImage apim={apim} imageId={rowData?.image} classes={'p-avatar p-avatar-circle p-avatar-lg'}/>
        </div>
      )}
      <div className={'flex flex-column'}>
        <div className={'font-medium'}>{rowData?.label}</div>
        <Link className={'block mt-1 text-sm'} to={editMode ?
          appUri.dos.edit.patrimoine.biens.uri
            .replace(':id', appState?.dossier()?.id)
            .replace(':type', 'immobilier')
            .replace(':bienId?', rowData?.bienImmobilierId) + appUri.dos.edit.patrimoine.biens.steps.default.step2 :
          appUri.dos.page.replace(':id', appState?.dossier()?.id) + '/patrimoine/biens/immobilier/' + rowData?.bienImmobilierId
        }>{trans(t, 'seeDetails')}</Link>
      </div>
    </div>
  ;

  const amountBodyTemplate = (rowData: any) => amountCell(rowData?.montantPleinePropriete);

  const editBody = (rowData: any) =>
    <>
      <Button type={'button'} className={'a8-details-btn'} icon={'pi pi-pencil'} rounded text aria-label={ucfirst(trans(t, 'edit'))} onClick={() => {
        navigate(appUri.dos.edit.patrimoine.biens.uri
          .replace(':id', appState?.dossier()?.id)
          .replace(':type', 'immobilier')
          .replace(':bienId?', rowData?.bienImmobilierId) + appUri.dos.edit.patrimoine.biens.steps.default.step2);
      }}
      />
      <Tooltip target={'.a8-details-btn'} position={'left'} content={ucfirst(trans(t, 'edit'))} mouseTrack/>
    </>
  ;

  return (
    <DatatableWrapper resourceType={resourceType} tableKey={'patrimoine-biens-immobiliers'} title={title ?? trans(t, 'table|patrimoine_bien.immobilier')}
                      editMode={editMode} noEdit onNew={onNew} noAdd={!isExpert() && !isAdmin()} noFilters noGlobalFilter paginator={false} rows={rows} isLoading={loading} footer={[
      { field: 'montantPleinePropriete', calculation: 'sum', label: ucfirst(trans(t, 'total')) + ' : ' }]} t={t}>
      <Column field={'label'} header={ucfirst(trans(t, 'table|patrimoine_headers.label'))} body={labelBodyTemplate} />
      <Column field={'type'} header={ucfirst(trans(t, 'table|patrimoine_headers.type'))} style={{ width: '250px' }}
              align={'center'} alignHeader={'center'} body={(rowData: any) => apiListCell(rowData?.type, types)} />
      <Column field={'family'} header={ucfirst(trans(t, 'table|patrimoine_headers.family'))} style={{ width: '250px' }}
              align={'center'} alignHeader={'center'} body={(rowData: any) => apiListCell(rowData?.family, rowData?.type === 'jouissance' ? famillesA : famillesB)} />
      <Column field={'purchaseDate'} header={ucfirst(trans(t, 'table|patrimoine_headers.purchaseDate'))} style={{ width: '250px' }}
              align={'center'} alignHeader={'center'} body={(rowData: any) => dateTimeCell(rowData?.purchaseDate, { format: 'DD/MM/YYYY' })} />
      <Column field={'montantPleinePropriete'} header={ucfirst(trans(t, 'table|patrimoine_headers.valeur'))} style={{ width: '250px' }}
              align={'center'} alignHeader={'center'} body={amountBodyTemplate} />
      {!editMode && (isExpert() || isAdmin()) && (
        <Column body={editBody} align={'center'} style={{ maxWidth: '90px', width: '90px' }} />
      )}
    </DatatableWrapper>
  );
};
